import {ReactComponent as SVGOneSm} from "../imgs/seals/tags-1-sm.svg";
import {ReactComponent as SVGOneMd} from "../imgs/seals/tags-1-md.svg";

import {ReactComponent as SVGTwoSm} from "../imgs/seals/tags-2-sm.svg";
import {ReactComponent as SVGTwoMd} from "../imgs/seals/tags-2-md.svg";

import {ReactComponent as SVGThreeSm} from "../imgs/seals/tags-3-sm.svg";
import {ReactComponent as SVGThreeMd} from "../imgs/seals/tags-3-md.svg";

import ImgTagOneSm from "../imgs/seals/tags-1-img-sm.png";
import ImgTagOneMd from "../imgs/seals/tags-1-img-md.png";

export default function FirstStepsTagHolder({numberType, size}) {

    const switchImageShow = (numberType, size) => {

        if (numberType == 1) {
            if (size == "sm") {
                return <img src={ImgTagOneSm} />
            }
            if (size == "other"){
                return <img style={{width: "203px"}} src={ImgTagOneMd} />
            }
            return <img src={ImgTagOneMd} />
        }
        if (numberType == 2) {
            if (size == "sm") {
                return <SVGTwoSm/>
            }
            if (size == "other"){
                return <SVGTwoMd/>
            }
            return <SVGTwoMd/>
        }
        if (numberType == 3) {
            if (size == "sm") {
                return <SVGThreeSm/>
            }
            if (size == "other"){
                return <SVGThreeMd/>
            }
            return <SVGThreeMd/>
        }

    }
    
    return <div style={{top: 1, borderRadius: "16px 16px 0px 0px"}} className=" w-100 h-100 position-absolute overflow-hidden " >
        <div style={{zIndex: 1, background: "#000000", opacity: "25%"}} className=" w-100 h-100 position-absolute " >
        
        </div>
        <div  style={{zIndex: 2}} className=" w-100 h-100 d-flex justify-content-center align-items-center position-relative" >
            {
                numberType && switchImageShow(numberType, size)
            }
        </div>
    </div>
}