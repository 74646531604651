import { createReducer } from "@reduxjs/toolkit";
import { newInvitationClose, newInvitationCopy, newInvitationCreate, sendDataDetails } from "./InvitationsAction";
import { fetchInvitationTable, fetchInvitationTableExtras, putInvitation } from "./InvitationsSideEffect";

const initialState = {
  invitationTable: {
    data: null,
    error: false,
    loader: false,
  },
  invitationDetail: {
    data: null,
  },
  invitationNewModal: false,
  invitationCopy: false
};

const invitationsReducer = createReducer(initialState, (builder) => {
  builder

  .addCase(sendDataDetails, (state, action) => {
    state.invitationDetail.data = action.payload;
  })

  .addCase(newInvitationCreate, (state, action) => {
    state.invitationNewModal = true;
  })

  .addCase(newInvitationCopy, (state, action) => {
    state.invitationCopy = true;
  })


  .addCase(newInvitationClose, (state, action) => {
    state.invitationNewModal = false;
    state.invitationCopy = false;
  })

  .addCase(putInvitation.fulfilled, (state, action) => {
  
  })
  .addCase(putInvitation.rejected, (state, action) => {
   
  })
  .addCase(putInvitation.pending, (state, action) => {
   
  })

  .addCase(fetchInvitationTableExtras.fulfilled, (state, action) => {
    state.invitationTable.error = false;
    state.invitationTable.loader = false;
  })
  .addCase(fetchInvitationTableExtras.rejected, (state, action) => {
    state.invitationTable.error = true;
    state.invitationTable.loader = false;
  })
  .addCase(fetchInvitationTableExtras.pending, (state, action) => {
    state.invitationTable.error = false;
  })
  
  .addCase(fetchInvitationTable.fulfilled, (state, action) => {
    state.invitationTable.data = action.payload;
    state.invitationTable.error = false;
    state.invitationTable.loader = false;
  })
  .addCase(fetchInvitationTable.rejected, (state, action) => {
    state.invitationTable.error = true;
    state.invitationTable.loader = false;
  })
  .addCase(fetchInvitationTable.pending, (state, action) => {
    state.invitationTable.error = false;
    state.invitationTable.loader = true;
  })

});

export default invitationsReducer;
