import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import {
  fetchAssociateSms,
  fetchAuthenticator,
} from "../../Framework/redux/loginSideEffects";
import { AutoTabProvider } from "react-auto-tab";
import Loader from "../../../common/Share/Loader";
import RegisterAnalytics from "../../Framework/ga/RegisterAnalytics";

export default function LoginConfirm() {
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    const activateInput = document.getElementById("one");
    activateInput.focus();
  }, [])

  const styleRedError = {border: "1px solid red"}

  const loading = useSelector((state) => state.userInfo.loader);

  const [errorHandler, setErrorHandler] = useState(false);
  const [inputs, setInputs] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  useEffect(() => {
    const keyDownHandler = event => {

      if (event.key === 'Enter') {
        event.preventDefault();
        onContinue(event);
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const [toggler, setToggler] = useState(false);
  const [noTime, setNoTime] = useState(false);

  const onReSend = async () => {
    await dispatch(fetchAssociateSms(localStorage.getItem("tel")));
    window.location.reload();
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setNoTime(true);
    } else {
      // Render a countdown
      return (
        <span>
          {minutes < 10 && "0" +  minutes }
          :
          {seconds < 10 && "0" +  seconds }
          {seconds >= 10 && seconds }
        </span>
      );
    }
  };

  const onChange = (evt, onPaste) => {
    if(!onPaste) {
      evt.preventDefault();
    }
    let aux = inputs;
    let name = evt.target.name;
    let value = evt.target.value;
    
    setToggler(false);
    setErrorHandler(false);

    if (name === "one") {
      aux.one = null;
      aux.one = value;
      setInputs(aux);
    }
    if (name === "two") {
      aux.two = value;
      setInputs(aux);
    }
    if (name === "three") {
      aux.three = value;
      setInputs(aux);
    }
    if (name === "four") {
      aux.four = value;
      setInputs(aux);
    }
    notEmpty();
  };

  const onPaste = async (evt) => {
    setToggler(false);
    setErrorHandler(false);
    
    const text = await onClipboard();
    setTimeout(() => {

      let evtObject = {target: {name: "one", value: text.slice(0,1) }}
      onChange(evtObject, true);
      evtObject = {target: {name: "two", value: text.slice(1,2) }}
      onChange(evtObject, true);
      evtObject = {target: {name: "three", value: text.slice(2,3) }}
      onChange(evtObject, true);
      evtObject = {target: {name: "four", value: text.slice(3,4) }}
      onChange(evtObject, true);
    }, 500);
  };

  const onClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      return text;
      
    } catch (error) {
      const one = document.getElementById("one");
      const two = document.getElementById("two");
      const three = document.getElementById("three");
      const four = document.getElementById("four");
      const forceText = `${one.value}${two.value}${three.value}${four.value}`;
      return forceText;
    }
  };

  const notEmpty = () => {
    if (
      inputs.one !== "" &&
      inputs.two !== "" &&
      inputs.three !== "" &&
      inputs.four !== ""
    ) {
      setToggler(true);
      return true;
    }
    return false;
  };

  const onContinue = async (evt) => {
    
    evt.preventDefault();
    if (!notEmpty()) {
      setErrorHandler(true);
      return;
    }
    RegisterAnalytics("resend-code")
    let optCode = inputs.one.concat(
      inputs.two.concat(inputs.three.concat(inputs.four))
    );
    const result = await dispatch(fetchAuthenticator(optCode));
    if (typeof result?.payload === "object") {
      history("/profile");
      return;
    } else {
      setErrorHandler(true);
    }
  };

  const onClickFirstField = () => {
    setInputs({one: "", two: "", three: "", four: ""})
    let idOne = document.getElementById("one");
    let idTwo = document.getElementById("two");
    let idThree = document.getElementById("three");
    let idFour = document.getElementById("four");

    idOne.value = ""
    idTwo.value = ""
    idThree.value = ""
    idFour.value = ""
  };

  return (
    <div id="login-layout" className=" container--padding py-5">
      <div className="">
        <h1 className="mt-3 grayColor s12 museo-sans-500 text-uppercase">
          <FormattedMessage
            id="login.phone.page.title"
            defaultMessage="1. Profile settings"
          />
        </h1>
        <hr></hr>
        <h2 className=" museo-sans-700 s24 w-75 mt-4">
          <FormattedMessage
            id="login.phone.page.two.subtitle"
            defaultMessage="Profile picture"
          />
        </h2>
        <AutoTabProvider
          id="login--confirm-sms"
          className="position-relative mt-5 d-flex"
        >
          <div onClick={onClickFirstField} >
          <input
            onChange={onChange}
            onPaste={onPaste}
            name="one"
            size={1}
            id="one"
            tabIndex={1}
            key={1}
            placeholder="X"
            className="form-control input-borders "
            maxLength={1}
            type="text"
            style={errorHandler ? styleRedError : {}}
            tabbable
          />

          </div>
          <input
            onChange={onChange}
            name="two"
            size={1}
            id="two"
            tabIndex={2}
            key={2}
            placeholder="X"
            className="form-control input-borders  mx-3"
            maxLength={1}
            type="text"
            style={errorHandler ? styleRedError : {}}
            tabbable
          />
          <input
            onChange={onChange}
            name="three"
            size={1}
            id="three"
            tabIndex={3}
            key={3}
            placeholder="X"
            className="form-control input-borders  "
            maxLength={1}
            type="text"
            style={errorHandler ? styleRedError : {}}
            tabbable
          />
          <input
            onChange={onChange}
            name="four"
            id="four"
            size={1}
            tabIndex={4}
            key={4}
            placeholder="X"
            className="form-control input-borders  mx-3"
            maxLength={1}
            type="text"
            style={errorHandler ? styleRedError : {}}
            tabbable
          />
        </AutoTabProvider>
        <>
          <ErrorHandler errorHandler={errorHandler} />
        </>
      </div>
      <div className="align-self-end">
        {toggler ? (
          <button
            type="submit"
            onClick={onContinue}
            className=" position-relative s14 w600 btnLoginWidth  py-2 text-white btnCustom skyBg"
          >
          <Loader loading={loading} />{" "}
            <FormattedMessage
              id="buttons.continue"
              defaultMessage="Redirect: "
            />
          </button>
        ) : !noTime ? (
          <button className="disabled opacity-50 disabledCursor position-relative btnLoginWidth s14 museo-sans-700 px-4 py-2 text-white btnCustom brightGrayBg ">
            <Loader loading={loading} />
            <FormattedMessage
              id="login.phone.two.submit"
              defaultMessage="Redirect: "
            />
            <Countdown date={Date.now() + 60000} renderer={renderer} />
          </button>
        ) : (
          <button 
          onClick={onReSend} 
          className="position-relative btnLoginWidth s14 museo-sans-700 px-4 py-2 text-white btnCustom skyBg">
            <Loader loading={loading} />
            <FormattedMessage
              id="login.phone.two.resend"
              defaultMessage="Redirect: "
              
            />
          </button>
        )}
      </div>
    </div>
  );
}


export function ErrorHandler ({errorHandler}) {
    return (
      <>
        {!errorHandler ? (
          <small className=" text-danger invisible ">
            {" "}
            <FormattedMessage
              id="login.error.two"
              defaultMessage="This input can't be empty or shorter than four digits"
            />{" "}
          </small>
        ) : (
          <div className="mt-3">
            <i className=" text-danger bi bi-x-circle-fill"></i>
            <small className="px-0 text-danger ">
              {" "}
              <FormattedMessage
                id="login.error.pin"
                defaultMessage="This input can't be empty or shorter than four digits"
              />{" "}
            </small>
          </div>
        )}
      </>
    )
}