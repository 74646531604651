import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import ReturnToAndTitle from "../../../Share/ReturnToAndTitle";
import { ReactComponent as SVGRedCross } from "../../../../EDBs/Presentation/img/redcross.svg";
import { ReactComponent as SVGEye } from "../icons/Eye.svg";
import { ReactComponent as SVGNoEye } from "../icons/NoEye.svg";
import { postPersonalDataPassword } from "../../Framework/ConfigurationSideEffect";
import SharePasswordIsError from "../../../Share/SharePasswordValidation";

export default function ConfigurationPassword({
  setModalTexts,
  travelFunction,
}) {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    currentPassword: {
      id: "currentPassword",
      value: "",
      valid: true,
      errorMessage: "",
      showPassword: false,
    },
    newPassword: {
      id: "newPassword",
      value: "",
      valid: true,
      errorMessage: "",
      showPassword: false,
    },
    newPasswordConfirmation: {
      id: "newPasswordConfirmation",
      value: "",
      valid: true,
      errorMessage: "",
      showPassword: false,
    },
  });

  const onChange = (evt, id) => {
    let auxiliar = { ...values };
    if (id == "currentPassword") {
      auxiliar.currentPassword.value = evt.target.value;
    }
    if (id == "newPassword") {
      auxiliar.newPassword.value = evt.target.value;
    }
    if (id == "newPasswordConfirmation") {
      auxiliar.newPasswordConfirmation.value = evt.target.value;
    }
    auxiliar.currentPassword.valid = true;
    auxiliar.currentPassword.errorMessage = "";
    auxiliar.newPassword.valid = true;
    auxiliar.newPassword.errorMessage = "";
    auxiliar.newPasswordConfirmation.valid = true;
    auxiliar.newPasswordConfirmation.errorMessage = "";
    setValues({ ...auxiliar });
  };

  const confirmValidation = () => {
    let auxiliar = { ...values };

    if (
      auxiliar.currentPassword?.value?.length < 1 ||
      auxiliar.newPassword?.value?.length < 1 ||
      auxiliar.newPasswordConfirmation?.value?.length < 1
    ) {
      return;
    }

    let validateNewPassword = SharePasswordIsError(
      auxiliar.newPassword?.value,
      auxiliar.newPasswordConfirmation?.value
    );
    let validateNewPassswordConfirmation = SharePasswordIsError(
      auxiliar.newPasswordConfirmation?.value,
      "",
      1
    );

    if (validateNewPassword.isError) {
      auxiliar.newPassword.valid = false;
      auxiliar.newPassword.errorMessage = (
        <FormattedMessage id={validateNewPassword.messageId} />
      );
    }
    if (validateNewPassswordConfirmation.isError) {
      auxiliar.newPasswordConfirmation.valid = false;
      auxiliar.newPasswordConfirmation.errorMessage = (
        <FormattedMessage id={validateNewPassswordConfirmation.messageId} />
      );
    } else if (validateNewPassword.isError === 3) {
      auxiliar.newPasswordConfirmation.valid = false;
      auxiliar.newPasswordConfirmation.errorMessage = (
        <FormattedMessage id="settings.password.error.types.two" />
      );
    }

    if (
      !auxiliar.newPasswordConfirmation.valid ||
      !auxiliar.newPassword.valid
    ) {
      setValues(auxiliar);
      return;
    }

    onContinue();
  };

  const onNetworkError = (error) => {
    if (error?.payload.includes("401")) {
      let auxiliar = { ...values };
      let auxiliarCurrentPassword = { ...auxiliar.currentPassword };
      auxiliarCurrentPassword.valid = false;
      auxiliarCurrentPassword.errorMessage = (
        <FormattedMessage id="settings.password.error.types.four" />
      );
      auxiliar.currentPassword = auxiliarCurrentPassword;
      setValues(auxiliar);
    }
  };

  const onContinue = async () => {
    const result = await dispatch(
      postPersonalDataPassword({
        requestType: "password",
        newPassword: values.newPassword.value,
        currentPassword: values.currentPassword.value,
      })
    );
    if (result?.payload?.code === 200) {
      const modalData = {
        title: <FormattedMessage id="settings.password.modal.title" />,
        text: <FormattedMessage id="settings.password.modal.p" />,
        status: "confirm",
        show: false,
        bottom: 0,
      };
      setModalTexts(modalData);
      const clickMe = document.getElementById("closeModal");
      clickMe.click();
      return;
    }
    onNetworkError(result);
  };

  const onShowPassword = (id) => {
    let aux = { ...values[id] };
    aux.showPassword = !aux.showPassword;
    setValues({ ...values, [id]: aux });
  };

  const trueOnAllFilled = () => {
    if (
      values.currentPassword?.value !== "" &&
      values.newPassword?.value !== "" &&
      values.newPasswordConfirmation?.value !== ""
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <ReturnToAndTitle
        travelFunction={travelFunction}
        returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
      />
      <div
        style={{ height: "500px" }}
        className=" pt-4 hidden-y-scroll d-flex flex-column gap-4"
      >
        <div className="d-flex flex-column gap-2 museo-sans-700 ">
          <p
            style={{ letterSpacing: "0.24px" }}
            className=" museo-sans-500 s12 text-uppercase grayColor"
          >
            <FormattedMessage id="settings.title.change.password" />
          </p>
          <p className=" s24">
            <FormattedMessage id="settings.title.change.password.subtitle.one" />
          </p>
          <p className=" s14 museo-sans-500 w-75">
            <FormattedMessage id="settings.title.change.password.subtitle.two" />
          </p>
        </div>
        <ConfigurationPasswordInput
          label={"Contraseña actual"}
          onChange={onChange}
          password={values.currentPassword}
          onShowPassword={onShowPassword}
          />
        <div>
          <ConfigurationPasswordInput
            label={"Nueva contraseña"}
            onChange={onChange}
            password={values.newPassword}
            onShowPassword={onShowPassword}
            maxLength={10}
          />
        </div>
        <ConfigurationPasswordInput
          label={"Confirmar contraseña"}
          onChange={onChange}
          password={values.newPasswordConfirmation}
          onShowPassword={onShowPassword}
          maxLength={10}
        />
        <button
          onClick={confirmValidation}
          style={{ width: "308px" }}
          className={` mt-4 museo-sans-700 border-0 ${
            trueOnAllFilled() ? "skyBg" : "brightGrayBg"
          } rounded-2 text-white mb-3 py-2`}
        >
          <FormattedMessage id="buttons.continue" />
        </button>
      </div>
    </>
  );
}

export function ConfigurationPasswordInput({
  label,
  onChange,
  password,
  onShowPassword,
  maxLength
}) {
  return (
    <div>
      <label className=" museo-sans-700 s14 mb-2">
        {label || "Contraseña"}
      </label>
      <div className=" position-relative">
        <input
          onChange={(evt) => onChange(evt, password.id)}
          style={{ width: "308px", height: "40px" }}
          className={` museo-sans-500 s14 ${
            password.valid ? "input-border" : "input-error-border"
          } px-3 py-2`}
          placeholder="••••••••"
          maxLength={maxLength ?? 50}
          type={password.showPassword ? "text" : "password"}
        />
        <div
          onClick={() => onShowPassword(password.id)}
          style={{ top: 8, left: 270 }}
          className=" position-absolute bg-white pointer "
        >
          {password.showPassword ? <SVGNoEye /> : <SVGEye />}
        </div>
      </div>
      {!password.valid && (
        <div className=" mt-1 d-flex gap-1">
          {" "}
          <SVGRedCross />{" "}
          <p className=" museo-sans-500 s12 lightRedColor">
            {password.errorMessage || "Formato Incorrecto"}
          </p>{" "}
        </div>
      )}
    </div>
  );
}
