import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { useRef } from "react";
import { useSelector } from "react-redux";

import imageTwo from "../../imgs/posibleImage02.png";

export default function FirstStepsModalGuidelinesNoKit(params) {
  const CMSData = useSelector(
    (state) => state.firstStepsAllReducer.CMSOne?.data
  );

  const scrollbarRef = useRef(null);

  return (
    <>
      <div
        className="modal fade"
        id="modalFirstStepsGuidelines"
        aria-hidden="true"
        aria-labelledby="modalFirstStepsGuidelines"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "610px" }}
        >
          <div className="modal-content modal-radius">
            <div className=" modal-body p-0">
              <div className="d-flex justify-content-between flex-column" style={{ width: "608px", height: "608px" }}>
                <div>
                  <div className="d-flex align-items-center justify-content-between my-3 py-4 px-4 mx-3">
                    <div className="d-flex">
                      <h5 className="museo-sans-500 s20 m-0">
                        <FormattedMessage id="first.steps.guidelines.program.title" />
                      </h5>
                    </div>
                    <div
                      className=" pointer "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <SVGClose />
                    </div>
                  </div>
                  <hr className="m-0"></hr>
                  <div
                    ref={scrollbarRef}
                    style={{ height: "370px" }}
                    className="d-flex hidden-y-scroll justify-content-center align-items-center py-4"
                  >
                    <div style={{ height: "312px", width: "528px" }}>
                      <p className="museo-sans-700 s20"><FormattedMessage id="first.steps.title" /></p>
                      <div>
                        <img src={imageTwo} />
                      </div>
                      <div>
                        {/*
                          <DashboardFirstStepsModalDetailGraph
                          infoStep={stepsData?.data}
                        />
                          */}
                      </div>
                      {<MeantToArrive index={1} CMSData={CMSData} />}
                      {<MeantToArrive index={2} CMSData={CMSData} />}
                      {<MeantToArrive index={3} CMSData={CMSData} />}
                      <hr />
                      {<MeantToArrive index={4} CMSData={CMSData} />}
                      <hr />
                      {<MeantToArrive index={5} CMSData={CMSData} />}
                    </div>
                  </div>

                </div>
                <div>
                  <ModalWhiteShadow
                    left={"5%"}
                    width={"90%"}
                    bottom={90}
                    scrollbarRef={scrollbarRef}
                  />
                  <div
                    className=" mb-3 pb-4 d-flex flex-column justify-content-center align-items-center"
                  >
                    <button
                      type="button"
                      className="btnLoginWidth museo-sans-700 s14 whiteColor border-0 skyBg text-white button-bootstrap-radius button-padding"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{
                        width: "288px",
                        color: "#5AAFF1",
                      }}
                    >
                      <FormattedMessage id="buttons.understood" />
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function MeantToArrive({ index, CMSData }) {
  const controlLegals = (legals) => {
    if (!legals) {
      return "";
    }

    const splitByN = legals.split("\n");

    return (
      <div className=" d-flex flex-column gap-1 mt-1">
        {splitByN.map((elm) => {
          return <p> {elm} </p>;
        })}
      </div>
    );
  };

  return (
    <>
      <div className=" mt-3 museo-sans-500 s16">
        <p className=" museo-sans-700">
          {index == 1 && <><FormattedMessage id="first.steps.no.kit.subtitle.one" /></>}
          {index == 2 && <><FormattedMessage id="first.steps.no.kit.subtitle.two" /></>}
          {index == 3 && <><FormattedMessage id="first.steps.no.kit.subtitle.three" /></>}
          {index == 5 && (
            <span className="museo-sans-500"><FormattedMessage id="first.steps.no.kit.subtitle.notes" /></span>
          )}
        </p>
        <div>
          {index == 1 && (
            <p>
              <FormattedMessage id="first.steps.no.kit.p.one" />{" "}
              <span className="museo-sans-700"><FormattedMessage id="first.steps.no.kit.p.two" /></span>
              <FormattedMessage id="first.steps.no.kit.p.three" />{" "}
              <span className="museo-sans-700">50%</span><FormattedMessage id="first.steps.no.kit.p.four" />
            </p>
          )}
          {index == 2 && (
            <p>
              <FormattedMessage id="first.steps.no.kit.p.five" />{" "}
              <span className="museo-sans-700">$11.000 PVP</span><FormattedMessage id="first.steps.no.kit.p.six"/>
              <span className="museo-sans-700">75%</span><FormattedMessage id="first.steps.no.kit.p.seven"/>
            </p>
          )}
          {index == 3 && (
            <p>
               <FormattedMessage id="first.steps.no.kit.p.eight" />{" "}
              <span className="museo-sans-700"><FormattedMessage id="first.steps.no.kit.p.nine" /></span>{" "}
              <FormattedMessage id="first.steps.no.kit.p.ten" />{" "}
              <span className="museo-sans-700">
              <FormattedMessage id="first.steps.no.kit.p.eleven" />
              </span>
            </p>
          )}
          {index == 4 && (
            <p>
              <FormattedMessage id="first.steps.no.kit.p.twelve" />{" "}
              <span className="museo-sans-700">
                <FormattedMessage id="first.steps.no.kit.p.third"/>
              </span>{" "}
                <FormattedMessage id="first.steps.no.kit.p.fourteen" />
            </p>
          )}
          {index == 5 && <>{controlLegals(CMSData?.legals)}</>}
        </div>
      </div>
    </>
  );
}
