import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import InvitationsDashboardShowcase from "../../../Invitations/Presentation/pages/InvitationsDashboardShowcase";

export default function DashboardInvitationsContainer({invitationToggler, directorId}) {


    return(
        <>
        {
          (ShareCheckEnvKey("INVITATIONS") && (invitationToggler === "true") ) &&
          <>
          {
            !directorId &&
            <InvitationsDashboardShowcase />
          }
          </>

        }
        </>
    )
}