
import { FormattedMessage } from "react-intl";
import emailPng24 from "../../EDBs/Presentation/img/emailPng24.png";
import { ReactComponent as WhatSapp } from "../../EDBs/Presentation/img/whatsapp24.svg";

export function ShareSocialBallIcon(params) {

    const onClickBall = () => {
      const pointerToClick = document.getElementById(
        params?.iconType == "svg" ? "what-touch" : "mail-touch"
      );
      pointerToClick.click();
    };

    const iconByIconName = () => {
        if (params.iconName == "whatsapp") {
            return <WhatSapp />;
        };
        if (params.iconName == "email") {
            return emailPng24;
        };
    };
  
    return (
      <div
        onClick={() => onClickBall()}
        className={`${params.isColumn ? "flex-column gap-2 pb-1" : "gap-3"} pointer d-flex align-items-center justify-content-between `}
      >
        <div
          className=" d-flex justify-content-center align-items-center "
          style={{
            width: params.width || "60px",
            height: params.height || "60px",
            background: "#5AAFF11A",
            borderRadius: "100px",
            padding: "10px",
          }}
        >
          {params.iconType === "svg" ? (
            <div style={{width: params.iconSize || "24px", height: params.iconSize || "24px"}} className=" svg-force-size" >
                {
                    iconByIconName()
                }
            </div>
          ) : (
            <img style={{width: params.iconSize || "24px", height: params.iconSize || "24px"}} src={iconByIconName()} alt="icon" />
          )}
        </div>
        <p className=" grayColor museo-sans-500">
          <FormattedMessage id={params.messageId} />
        </p>
      </div>
    );
  }