import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../common/Share/FormatCurrency";

export default function DescendantProfilePersonalSales({ data, loading }) {
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  const warning = " goldColor ";

  return !loading ? (
    <div
      style={{ height: "88px" }}
      className=" p-2 d-flex justify-content-between cards-shadow rounded-3 bg-white f9GrayBorder"
    >
      <div className=" m-1 d-flex w-100 text-center  flex-column justify-content-center align-items-center">
        {data && (
          <p
            className={` dm-sans-bold s18 ${
              data.rootSummary.balanceSummary.balance.status == "warning" &&
              warning
            } `}
          >
            {formatAmount(data.rootSummary.balanceSummary.balance.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
          </p>
        )}
        <p className=" museo-sans-500 s14">
          <FormattedMessage id="descendants.leader.modal.showSales.balance" />
        </p>
      </div>
      <div
        style={{
          width: "1px",
          opacity: "10%",
          background: "#171A22",
          height: "64px",
        }}
        className="my-1 "
      ></div>
      <div className=" m-1 d-flex w-100 text-center  flex-column justify-content-center align-items-center">
        {data && (
          <>
            <p className=" dm-sans-bold s18 ">
              {formatAmount(data.rootSummary.balanceSummary.billed.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
            </p>
          </>
        )}
        <p className=" museo-sans-500 s14">
          <FormattedMessage id="personal.sales.bill" />
        </p>
      </div>
    </div>
  ) : (
    <>
      <SkeletonDescProfilePersonalSale />
    </>
  );
}

export function SkeletonDescProfilePersonalSale(params) {
  return (
    <>
      <div
        style={{ height: "88px" }}
        className=" p-2 d-flex justify-content-between cards-shadow rounded-3 bg-white f9GrayBorder"
      >
        <div className=" m-1 d-flex w-100 text-center gap-2  flex-column justify-content-center align-items-center">
          <div
            style={{ height: "16px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
          ></div>
        </div>
        <div
          style={{
            width: "1px",
            opacity: "10%",
            background: "#171A22",
            height: "64px",
          }}
          className="my-1 "
        ></div>
        <div className=" m-1 d-flex w-100 text-center gap-2  flex-column justify-content-center align-items-center">
          <div
            style={{ height: "16px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
          ></div>
        </div>
      </div>
    </>
  );
}
