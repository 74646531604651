import Vimeo from "@u-wave/react-vimeo";
import { ReactComponent as SVGx } from "../svgs/ic-x-overlay.svg";
import { useMediaPredicate } from "react-media-hook";

export default function VimeoControllerAlone({show, close, vimeo}) {

  const onClose = () => {
    close();
  };

  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");

  return (
    show && (
      <div
        style={{ width: "100%", height: "100%", top: "0", left: "0" }}
        className=""
        id="vimeo--displayer"
      >
        <div
          style={{ zIndex: "998" }}
          className="bg-black d-block position-absolute opacity-75 w-100 h-100"
          onClick={onClose}
        ></div>
        <VimeoRedCloseZone biggerThan1921={biggerThan1921} onClose={onClose} />
        <div
          onClick={onClose}
          style={
            biggerThan1921
              ? {
                  zIndex: "1000",
                  top: "42px",
                  right: "42px",
                  width: "42px",
                  height: "42px",
                }
              : {
                  zIndex: "1000",
                  top: "10px",
                  right: "10px",
                  width: "32px",
                  height: "32px",
                }
          }
          className="position-absolute whiteColor pointer svg-force-size "
        >
          <SVGx />
        </div>
        <VimeoHolder reduxStoreVimeo={vimeo} biggerThan1921={biggerThan1921} />
      </div>
    )
  );
}

export function VimeoHolder({ reduxStoreVimeo, biggerThan1921 }) {

  return (
    <div
      id="vimeo--holder"
      className=" shadow position-relative vh-100 d-flex justify-content-center mt-2"
      style={{ zIndex: "999", alignItems: "center" }}
    >
      <div
        style={
          biggerThan1921
            ? { width: "538px", height: "896px" }
            : { width: "338px", height: "696px" }
        }
        className=" position-relative"
      >
        <div
          style={
            biggerThan1921
              ? {
                  width: "508px",
                  height: "961px",
                  left: "15px",
                  justifyItems: "center",
                }
              : { width: "338px", height: "696px" }
          }
          className=" position-absolute "
        >
          <div
            style={
              biggerThan1921
                ? { zIndex: "900", top: "15px" }
                : { zIndex: "900", top: "60px" }
            }
            className=" d-flex justify-content-center  w-100 position-absolute"
          >
            <Vimeo
              speed={true}
              showTitle={true}
              showPortrait={false}
              controls={true}
              autoplay={false}
              showByline={true}
              video={`https://player.vimeo.com/video/${
                reduxStoreVimeo?.id ? reduxStoreVimeo?.id : "658693857"
              }?h=${
                reduxStoreVimeo?.key ? reduxStoreVimeo?.key : "c5b977e224"
              }&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}


export function VimeoRedCloseZone({ onClose, biggerThan1921 }) {

  return (
    <>
      <div
        onClick={onClose}
        style={
          biggerThan1921
            ? { width: "33%", zIndex: "99999" }
            : { width: "26%", zIndex: "99999" }
        }
        className=" position-absolute  h-100 "
      ></div>
      <div
        onClick={onClose}
        style={
          biggerThan1921
            ? { width: "33%", zIndex: "99999", right: "0px" }
            : { width: "26%", zIndex: "99999", right: "0px" }
        }
        className=" position-absolute  h-100 "
      ></div>
    </>
  );
}
