import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchInvitationTable = createAsyncThunk(
    "fetchInvitationTable",
    async ( data , {rejectWithValue}) => {
      try {
        let location = localStorage.getItem("country");
        let clientId = localStorage.getItem("clientId");
        return await customNetServAxios().get(`${location}/incorporations/${clientId}/autoincorporation/invitation/tracking?limit=10&offset=${data?.offSet ? data?.offSet : 0}&sort_by=${data?.order ? `${data?.order}.${data?.dir}` : "inviteDate.desc"}`);
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
  );

  const fetchInvitationTableExtras = createAsyncThunk(
    "fetchInvitationTableExtras",
    async ( data , {rejectWithValue}) => {
      try {
        let location = localStorage.getItem("country");
        let clientId = localStorage.getItem("clientId");
        return await customNetServAxios().get(`${location}/incorporations/${clientId}/autoincorporation/invitation/tracking?limit=10&offset=${data?.offSet ? data?.offSet : 0}&sort_by=${data?.order ? `${data?.order}.${data?.dir}` : "inviteDate.desc"}`);
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
  );

  const postInvitation = createAsyncThunk(
    "postInvitation",
    async ( data , {rejectWithValue}) => {
      try {
        let location = localStorage.getItem("country");
        let clientId = localStorage.getItem("clientId");
        return await customNetServAxios("application/json;charset=UTF-8", false, [400]).post(`${location}/incorporations/${clientId}/autoincorporation/invitation`, data);
      } catch (error) {
          return rejectWithValue(error.message);
      }
    }
  );

  const putInvitation = createAsyncThunk(
    "putInvitation",
    async ( data , {rejectWithValue}) => {
      try {
        let location = localStorage.getItem("country");
        let clientId = localStorage.getItem("clientId");
        return await customNetServAxios(false,false).put(`${location}/incorporations/${clientId}/autoincorporation/invitation/${data}/resend`);
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

export {fetchInvitationTable, fetchInvitationTableExtras, postInvitation, putInvitation} 