import { ReactComponent as EDBIcon } from "../../../common/svgs/ic-edb-md.svg";
import { ReactComponent as ArrowRightSVG } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as BusinessIcon } from "../../../common/svgs/ic-alert-circle-sm.svg";
import { ReactComponent as PersonalIcon } from "../../../common/svgs/ic-calendar-event-md.svg";
import { ReactComponent as BirthdayIcon } from "../../../common/svgs/ic-cake-md.svg";
import { ReactComponent as AssemblyIcon } from "../../../common/svgs/ic-persons-md.svg";

import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import { putEventId, setDetailsModal } from "../../Framework/redux/CalendarActions";

export default function CalendarCard({
  id,
  title,
  type,
  description,
  date,
  relatedData,
  social,
}) {
  const smallerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const smallerThan1024 = useMediaPredicate("(min-width: 1024px)");
  const biggerThan1370 = useMediaPredicate("(min-width: 1370px)");
  const dispatch = useDispatch();

  function calendarCardBgColor(eventType) {
    switch (eventType) {
      case "Assembly":
        return "assemblyBg";
      case "EDB":
        return "EDBBg";
      case "Birthday_client":
        return "birthdayBg";
      case "Birthday_contact":
        return "birthdayBg";
      case "Business":
        return "businessBg";
      case "Personal":
        return "personalBg";
      default:
        return "personalBg"
    }
  };

  function defineCalendarCardIcon(eventType) {
    switch (eventType) {
      case "Assembly":
        return <AssemblyIcon />;
      case "EDB":
        return <EDBIcon />;
      case "Birthday_client":
        return <BirthdayIcon />;
      case "Birthday_contact":
        return <BirthdayIcon />;
      case "Business":
        return <BusinessIcon />;
      case "Personal":
        return <PersonalIcon />;
      default:
        return <PersonalIcon />;
    }
  };

  function dateOrAllDay(type, date) {
    if (type === "Birthday_client" || type === "Birthday_contact") {
      return "Todo el día";
    } else {
      let arrayString = date.split("T");
      let timeString = arrayString[1];
      let arrayTimeString = timeString.split(":");
      let hoursString = arrayTimeString[0];
      let minutesString = arrayTimeString[1];
      let newDate = hoursString + ":" + minutesString;
      return `${newDate}`;
    }
  };

  const onClick = (relatedData) => {
    dispatch(putEventId(relatedData));
  };

  const splitString = (str) => {
    let searchedLength;
    {
      biggerThan1370 ? (searchedLength = 26) : smallerThan1280 ? (searchedLength = 23) : smallerThan1024 ? (searchedLength = 15) : (searchedLength = 25);
    }
    if (str.length > searchedLength) {
      let aux = str.slice(0, searchedLength);
      aux += "...";
      return aux;
    } else {
      return str;
    }
  };

  const onClickProfile = (data) => {
    dispatch(setDetailsModal(data));
  };

  return (
    <div
      className={`${calendarCardBgColor(
        type
      )} rounded-4 mb-3 w-100 event-card p-4 ${
        type === "Business" ? "darkYellowColor" : "bronceColor"
      }`}
    >
      <p className="pb-2 border-bottom museo-sans-700 s10">
        {dateOrAllDay(type, date)}
      </p>
      <div className="d-flex mt-2 bronceStroke align-items-center">
        {defineCalendarCardIcon(type)}
        <p className="ms-2 museo-sans-500 s14">{splitString(title)}</p>
      </div>
      {description ? <p className="museo-sans-500 s12">{description}</p> : null}

      <div className={`${!smallerThan1280 && "d-flex"} mt-3 justify-content-between`}>
        <div
          id={id}
          className=" pointer d-flex bronceStroke align-items-center"
        >
          {type === "Birthday_client" || type === "Birthday_contact" ? (
            <div 
            onClick={() => onClickProfile(relatedData)}
            data-bs-toggle="modal"
            data-bs-target="#modalDetailsBirthday"
            className=" d-flex  bronceStroke " >
              <p className="me-2 museo-sans-700 s12">
                <FormattedMessage id="personal.sale.details.detail.show" />
              </p>
              <ArrowRightSVG />
            </div>
          ) : type === "Business" ? null : (
            <>
              <p
                data-bs-toggle="modal"
                data-bs-target="#modalDetails"
                onClick={() => onClick(relatedData)}
                id={id}
                className="me-2 museo-sans-700 s12"
              >
                <FormattedMessage id="calendar.event.card.view.details" />
              </p>
              <ArrowRightSVG  />
            </>
          )}
        </div>
        {
          // Descomentar si quieren Social Icons. :)
          // <div className="d-flex">{displayIcons()}</div>
        }
      </div>
    </div>
  );
}
