import React from 'react'
import { useSelector } from 'react-redux'

export default function CalendarEventTitle() {

  const titulo = useSelector((state) => state.calendarData.currentDayTitle)
    
  return (
    <div className='calendar-event-title'>
        <p className='museo-sans-500 s20 blackColor user-select-none'>{titulo}</p>
    </div>
  )
}
