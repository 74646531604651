import { useState, useEffect, useRef } from "react";
import StatusAccountTableBody from "./StatusAccountTableBody";

import StatusAccountFilterModal from "./StatusAccountFilterModal";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import {
  fetchAccountStatusDescData,
  fetchAccountStatusDescDataBalance,
  fetchAccountStatusDescendentData,
} from "../../Framework/redux/StatusAccountSideEffect";

import { emptyMyAccountTableDescAction } from "../../Framework/redux/StatusAccountAction";
import { formatAmount } from "../../../common/Share/FormatCurrency";

import { ReactComponent as SVGTriangle } from "../img/Ic-warning.svg";
import { ReactComponent as SVGEmpty } from "../img/ic-search-empty.svg";
import { ReactComponent as SVGSearch } from "../img/ic-search.svg";
import { ReactComponent as FilterIcon } from "../../../common/svgs/filter-sm.svg";

import moment from "moment-timezone";
import processDateData from "../../../common/Share/processDateData";

export default function StatusAccountTableMyDescendant({
  data,
  cultureInfo,
  calendarData,
  fatherLoader,
  fatherSetLoader,
  defaultInit,
  defaultEnd,
}) {
  const [toggler, setToggler] = useState(false);
  const extraRef = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const [offSet, setOffSet] = useState(0);
  const [isEmpty, setIsEmpty] = useState(false);
  const [filters, setFilters] = useState({
    accountType: null,
    filterType: null,
    iniDate: null,
    endDate: null,
    iniPeriod: null,
    endPeriod: null,
  });
  const [id, setId] = useState(null);
  const [notReady, setNotReady] = useState(true);

  const [failed, setFailed] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (extraRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      });
      observer.observe(extraRef.current);
    }

    if (isVisible) {
      let result = callbackExtras();
    }
  }, [isVisible, extraRef.current]);

  const callbackExtras = async () => {
    setLoader(true);

    let result = null;

    if (failed) {
      setIsVisible(false);
      return;
    }

    if (offSet == 0) {
      setOffSet(offSet + 20);
      setIsVisible(false);
      return;
    }

    result = await dispatch(
      fetchAccountStatusDescendentData({
        descendantId: id,
        accountType: filters.accountType,
        filterType: filters.filterType,
        iniDate: filters.iniDate,
        endDate: filters.endDate,
        iniPeriod: filters.iniPeriod,
        endPeriod: filters.endPeriod,
        offSet: offSet,
      })
    );

    if (result?.summary?.length < 20) {
      setIsEmpty(true);
    }

    setOffSet(offSet + 20);
    setLoader(false);
    return result;
  };

  const serviceOnIdChange = async (str) => {
    fatherSetLoader(true);
    dispatch(emptyMyAccountTableDescAction());

    let value = await dispatch(
      fetchAccountStatusDescData({ descendantId: str })
    );

    if (value.payload == undefined) {
      setFailed(true);
      fatherSetLoader(false);
      setIsVisible(false);
      setId(str);
      return;
    } else {
      const isDesc = await dispatch(
        fetchAccountStatusDescendentData({
          descendantId: str,
          accountType: filters.accountType,
          filterType: filters.filterType,
          iniDate: filters.iniDate,
          endDate: filters.endDate,
          iniPeriod: filters.iniPeriod,
          endPeriod: filters.endPeriod,
          offSet: offSet,
          defaultIniDate: defaultInit,
          defaultEndDate: defaultEnd,
        })
      );

      if (isDesc.payload == "error") {
        setFailed(true);
        fatherSetLoader(false);
        setIsVisible(false);
        setId(str);
        return;
      }

      await dispatch(fetchAccountStatusDescDataBalance({ descendantId: str }));
      setOffSet(offSet + 20);
      setId(str);
      setFailed(false);
      fatherSetLoader(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setNotReady(true);
      setOffSet(0);
      const inputSpace = document.getElementById("seekerId");

      if (!inputSpace.value) {
        setId("empty");
        setNotReady(false);
        setIsVisible(false);
        setFailed(true);
        return;
      }

      serviceOnIdChange(inputSpace.value);
      setTimeout(() => {
        setNotReady(false);
      }, 3000);
    }
  };

  const hanldeOnClickSearch = () => {
    setNotReady(true);
    setOffSet(0);
    const inputSpace = document.getElementById("seekerId");
    if (!inputSpace.value) {
      setId("empty");
      setNotReady(false);
      setIsVisible(false);
      setFailed(true);
      return;
    }
    serviceOnIdChange(inputSpace.value);
    setTimeout(() => {
      setNotReady(false);
    }, 3000);
  };

  const filtersSetter = async ({ content, number }) => {
    let activeFilters = filters;
    fatherSetLoader(true);

    if (content.accountType == "currentAccount") {
      setToggler(false);
    } else {
      setToggler(true);
    }

    activeFilters.accountType = content.accountType;
    activeFilters.filterType = content.filterType;

    switch (number) {
      case 3:
        activeFilters.iniDate = content.iniDate;
        activeFilters.endDate = content.endDate;
        break;
      case 4:
        activeFilters.iniPeriod = content.iniPeriod;
        activeFilters.endPeriod = content.endPeriod;
        break;
      default:
        activeFilters.filterType = null;
        break;
    }

    dispatch(emptyMyAccountTableDescAction());

    let result = await dispatch(
      fetchAccountStatusDescendentData({
        descendantId: id,
        accountType: activeFilters.accountType,
        filterType: activeFilters.filterType,
        iniDate: activeFilters.iniDate,
        endDate: activeFilters.endDate,
        iniPeriod: activeFilters.iniPeriod,
        endPeriod: activeFilters.endPeriod,
        offSet: 0,
      })
    );

    setFilters(activeFilters);
    fatherSetLoader(false);
    setOffSet(0);
    setLoader(false);
  };

  const simpleTogglerAccount = () => {
    setToggler(false);
  };

  const simpleTogglerJustCoins = () => {
    setToggler(true);
  };

  const clearAllFilters = () => {
    setFilters({
      accountType: null,
      filterType: null,
      iniDate: null,
      endDate: null,
      iniPeriod: null,
      endPeriod: null,
    });
  };

  const renderCurrentYear = () => {
    let current = "";
    return (current = moment().format("YYYY"));
  };

  const switchDetailMovement = () => {
    switch (filters.filterType) {
      case "lastPeriod":
        return (
          <>
            <FormattedMessage id="dashboard.prev.earning.period" />{" "}
            <FormattedMessage id="later" /> (P
            {calendarData?.currentPeriod > 10
              ? calendarData?.currentPeriod - 1
              : `0${calendarData?.currentPeriod - 1}`}
            )
          </>
        );
      case "lastYear":
        return (
          <span className=" ">
            <FormattedMessage id="year" /> {renderCurrentYear()}
          </span>
        );
      case "dateFilter":
        let arrayOrigin = filters.iniDate.split("-");

        let yearOrigin = arrayOrigin[0];
        let monthOrigin = arrayOrigin[1];
        let datOrigin = arrayOrigin[2];

        let arrayTarget = filters.endDate.split("-");

        let yearTarget = arrayTarget[0];
        let monthTarget = arrayTarget[1];
        let datTarget = arrayTarget[2];

        let pastDate = `${datOrigin}-${monthOrigin}-${yearOrigin}`;
        let postDate = `${datTarget}-${monthTarget}-${yearTarget}`;

        return (
          <span className=" text-uppercase">
            <FormattedMessage id="del" /> {pastDate}{" "}
            <FormattedMessage id="al" /> {postDate}
          </span>
        );
      case "periodFilter":
        return (
          <span className=" text-uppercase">
            <FormattedMessage id="del" />{" "}
            <FormattedMessage id="dashboard.prev.earning.period" />{" "}
            {filters.iniPeriod.slice(2, 4)} <FormattedMessage id="del" />{" "}
            <FormattedMessage id="dashboard.prev.earning.period" />{" "}
            {filters.endPeriod.slice(2, 4)}
          </span>
        );

      default:
        if (calendarData) {
          let pastDateTwo = processDateData(calendarData?.periodStartDate);

          let currentDate = moment().format("DD-MM-YYYY");
          return (
            <span className=" text-uppercase">
              <FormattedMessage id="del" /> {pastDateTwo}{" "}
              <FormattedMessage id="al" /> {currentDate}
            </span>
          );
        }
        break;
    }
  };

  return (
    <div className="bg-white h-100 container--padding">
      <StatusAccountFilterModal
        toggler={toggler}
        filters={filters}
        filtersSetter={filtersSetter}
        toggleAccount={simpleTogglerAccount}
        toggleJustCoins={simpleTogglerJustCoins}
        clearAllFilters={clearAllFilters}
        calendarData={calendarData}
        desc={true}
      />
      <div className="container--padding--card-holder  ">
        <div
          style={{ paddingTop: "42px" }}
          className="d-flex flex-row align-items-start justify-content-between  "
        >
          <div className="d-flex flex-column  museo-sans-700 s12">
            <p>
              <FormattedMessage id="key.consultant" />
            </p>
            <div
              style={{ borderRadius: "8px", height: "40px", width: "443px" }}
              className=" d-flex skyBorder py-2 mt-2 px-3 "
            >
              <input
                id="seekerId"
                onKeyDown={handleKeyDown}
                placeholder="Ej.: 123456"
                className=" input-container input-borders border-0 py-1 w-100 "
              ></input>
              <div
                onClick={hanldeOnClickSearch}
                className=" pointer"
                style={{ width: "16px", height: "16px" }}
              >
                <SVGSearch />
              </div>
            </div>
          </div>
          <div className="d-flex">
            <div
              data-bs-toggle="modal"
              data-bs-target="#modalStatusFilter"
              className=" gap-2 pointer ps-4 pe-4 me-4 d-flex align-items-center justify-content-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week"
            >
              <div className=" position-relative">
                {filters.filterType != null && (
                  <div
                    style={{ top: "0px", right: "-1px" }}
                    className=" position-absolute"
                  >
                    <div
                      className="rounded-circle "
                      style={{
                        width: "8px",
                        height: "8px",
                        backgroundColor: " rgb(240, 110, 105)",
                      }}
                    ></div>
                  </div>
                )}
                <FilterIcon />
              </div>
              <FormattedMessage id="edb.filter.button" />
            </div>
            {/*
                  <div
                style={{ width: "40px", height: "40px", borderRadius: "8px" }}
                className="d-flex flex-column justify-content-evenly align-items-center border-1 skyBorder"
              >
                <div className="py-1 position-relative">
                  <button
                    onClick={togglerMenuOnClick}
                    className=" rounded bg-white p-0 btnThreeDots skyBorder"
                  >
                    <i className="bi s24 skyColor bi-three-dots-vertical"></i>
                  </button>
                  {togglerMenu ? (
                    <>
                      <div
                        style={{
                          borderRadius: "1rem",
                          height: "200px",
                          width: "239px",
                          right: "0px",
                          zIndex: "5",
                        }}
                        className=" d-flex flex-column shadow bg-white position-absolute"
                      >
                        <div className=" h-100 w-100 p-3 d-flex flex-column justify-content-center align-items-center">
                          <p className=" museo-sans-700 s16">
                            <FormattedMessage id="edb.menu.empty.title" />
                          </p>
                          <p className=" text-center museo-sans-500 s14">
                            <FormattedMessage id="edb.menu.empty.p" />
                          </p>
                          <div className=" p-1">
                            <SVGExternal />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
                */}
          </div>
        </div>
      </div>
      {id ? (
        <>
          {notReady ? (
            <>
              <div>
                <div
                  style={{ top: "80px" }}
                  className=" position-relative d-flex flex-column justify-content-center align-items-center"
                >
                  <div className="spinner-border blueColor" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p className="museo-sans-500 s14 mt-2">
                    <FormattedMessage id="loading.more.movements" />
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="container--padding--card-holder">
                <div
                  style={{ marginTop: "30px" }}
                  className="container--padding--card-holder  "
                >
                  {failed ? (
                    <>
                      <div
                        style={{ height: "394px" }}
                        className=" d-flex justify-content-center align-items-center flex-column museo-sans-500"
                      >
                        <div
                          className=" d-flex justify-content-center align-items-center  "
                          style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "50%",
                          }}
                        >
                          <div className=" pb-2">
                            <SVGTriangle />
                          </div>
                        </div>
                        <p className=" s24 ">
                          <FormattedMessage id="status.account.not.found.one" />
                        </p>
                        <p
                          style={{ width: "350px" }}
                          className=" text-center grayColor s14"
                        >
                          <FormattedMessage id="status.account.not.found.two" />
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="d-flex flex-row justify-content-start">
                        <div className=" w-100">
                          <p className=" s20 museo-sans-500">
                            <FormattedMessage id="movements" />
                          </p>
                          <p className=" pb-1 mb-3 dm-sans-medium s14 grayColor text-uppercase ">
                            {switchDetailMovement()}
                          </p>
                          <div
                            style={{
                              background: "#A4A4D726",
                              borderRadius: "8px",
                            }}
                            className=" s14 d-flex  px-3 my-2 py-1"
                          >
                            <div className=" s14 w-50 py-2 museo-sans-500">
                              <p>
                                {data?.dataDesc?.gender == "M" ? (
                                  <>
                                    <FormattedMessage id="level.one.m" />:{" "}
                                  </>
                                ) : (
                                  <>
                                    <FormattedMessage id="level.one.f" />:{" "}
                                  </>
                                )}
                                <span className=" museo-sans-700">
                                  {data?.dataDesc?.name}
                                </span>
                              </p>
                            </div>
                            <div className=" w-100 py-2">
                              <p className=" museo-sans-500">
                                <FormattedMessage id="current.balance" />:{" "}
                                <span className="dm-sans-bold">
                                  {formatAmount(
                                    data?.dataDescBalance?.currentAccount,
                                    cultureInfo?.languageCode,
                                    cultureInfo?.defaultCurrencyCode
                                  )}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <StatusAccountTableBody
                          summary={data?.dataTableDesc?.summary}
                          cultureInfo={cultureInfo}
                          fatherLoader={fatherLoader}
                          descId={id}
                        />
                      </div>
                      {!isEmpty && (
                        <div
                          ref={extraRef}
                          className="mt-4"
                          style={{ height: "30px" }}
                        >
                          {isVisible ? (
                            loader ? (
                              <div
                                style={{ top: "-30px" }}
                                className=" position-relative d-flex flex-column justify-content-center align-items-center"
                              >
                                <div
                                  className="spinner-border blueColor"
                                  role="status"
                                >
                                  <span className="visually-hidden">
                                    Loading...
                                  </span>
                                </div>
                                <p className="museo-sans-500 s14 mt-2">
                                  <FormattedMessage id="loading.more.movements" />
                                </p>
                              </div>
                            ) : (
                              <></>
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className=" container--padding--card-holder">
            <div
              style={{ height: "390px" }}
              className="d-flex flex-column justify-content-center align-items-center w-100 gap-2 "
            >
              <div style={{ width: "80px", height: "80px" }}>
                <SVGEmpty />
              </div>

              <p className=" s24 museo-sans-500 text-center">
                <FormattedMessage id="status.account.seek.consultant.one" />
                <br></br>
                <FormattedMessage id="status.account.seek.consultant.two" />
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
