import React, { useState, useRef, useCallback, useEffect }  from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import ReactToPrint from "react-to-print";

import { ReactComponent as SVGChevronDown } from "../../../../common/svgs/ic-chevron-down-sm.svg";
import { ReactComponent as SVGChevronUp } from "../../../../common/svgs/ic-chevron-up-sm.svg";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGShare } from "../../../../common/svgs/ic-share-md.svg";
import { ReactComponent as SVGPrint } from "../../../../common/svgs/ic-print-md.svg";
import { ReactComponent as SVGNotEDB } from "../../../../common/svgs/ic-pedido-cliente-sm.svg";

import HandleSwissDates from "../../../../common/Share/HandleSwissDate";

import PersonalSalesModalShareOrder from "./PersonalSalesModalShareOrder";
import { formatAmount } from "../../../../common/Share/FormatCurrency";

export default function PersonalSalesModalOrder({ open, socialArray }) {
  const [togglers, setTogglers] = useState({
    togglerOne: false,
    togglerTwo: false,
  });

  const [hideOnPrint, setHideOnPrint] = useState(false);
  const [onShare, setOnShare] = useState(false);

  const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)
  
  const componentRef = useRef();
  
  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  
  const onToggleOne = () => {
    setTogglers({ ...togglers, togglerOne: !togglers.togglerOne });
  };
  
  const data = useSelector(
    (state) => state.performanceData.orderDetailsModalData
    );
    useEffect(() => {
      onClosure();
    }, [data]);

  const onPrint = async () => {
    await setHideOnPrint(true);
    await setTogglers({ togglerOne: true, togglerTwo: true });
  };

  const onClickShare = () => {
    setOnShare(true);
  };

  const onClosure = () => {
    setOnShare(false);
  };

  const afterOnPrint = () => {
    setHideOnPrint(false);
    setTogglers({ togglerOne: false, togglerTwo: false });
  };

  return (
    <>
      <div
        className="modal fade"
        id="modalDetailsOrder"
        aria-hidden="true"
        aria-labelledby="modalDetailsOrder"
        tabIndex="-1"
      >
        <div
          className="modal-dialog position-relative modal-dialog-centered"
          style={{ maxWidth: "610px" }}
        >
          <div className={`modal-content ${onShare && "bg-transparent border-0 "} modal-radius`}>
            <div className=" modal-body p-0">
              <div>
                {onShare && (
                  <PersonalSalesModalShareOrder
                    closeSubModal={onClosure}
                    open={open}
                    socialArray={socialArray}
                    data={data}
                  />
                )}
                <div className={`d-flex align-items-center ${onShare && "opacity-0"} justify-content-between py-4 px-4 mx-3`}>
                  <div className="d-flex flex-column">
                    <div className=" s12 grayColor museo-sans-500 align-items-center d-flex gap-2">
                      <p>
                        <FormattedMessage id="personal.sale.modal.client" />
                      </p>
                      <SVGNotEDB />
                    </div>
                    <h5 className="museo-sans-500 s20 m-0">
                      {data ? data.name : ""}
                    </h5>
                    <div className=" dm-sans-medium s12 py-2 d-flex gap-1">
                      <p>
                        {data ? (
                          <HandleSwissDates date={data?.date} />
                        ) : (
                          "dd-mm-yy"
                        )}
                      </p>
                      <p>|</p>
                      <p>
                        <FormattedMessage id="personal.sale.details.orden.id" /> <span>{data ? data.id : "XXXXX"}</span>
                      </p>
                    </div>
                  </div>
                  <div className=" align-items-center d-flex gap-3 pointer ">
                    <div onClick={onClickShare}>
                      <SVGShare />
                    </div>
                    <ReactToPrint
                      content={reactToPrintContent}
                      removeAfterPrint
                      onBeforeGetContent={onPrint}
                      onAfterPrint={afterOnPrint}
                      trigger={() => {
                        return (
                          <div>
                            <SVGPrint />
                          </div>
                        );
                      }}
                      documentTitle="Details"
                      pageStyle="print"
                    />
                    <div data-bs-dismiss="modal" aria-label="Close">
                      <SVGClose />
                    </div>
                  </div>
                </div>
                <hr className="m-0 "></hr> { /** not this one */ }
                <div
                  style={{ maxHeight: "372px" }}
                  className={` ${onShare && "opacity-0"} hidden-y-scroll d-flex flex-column align-items-center px-3`}
                >
                  <div className="my-4 w-100 px-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="m-0 museo-sans-700 s16">
                        <FormattedMessage id="personal.sale.modal.products.count" />:{" "}
                        <span className="dm-sans-bold">
                          {" "}
                          {data?.products && data.products.length}{" "}
                        </span>
                      </h3>
                      <div
                        onClick={onToggleOne}
                        className=" museo-sans-700 pointer d-flex gap-2 align-items-center s14 skyColor"
                      >
                        <FormattedMessage id="personal.sale.details.products.show" />
                        {togglers.togglerOne ? (
                          <SVGChevronUp />
                        ) : (
                          <SVGChevronDown />
                        )}
                      </div>
                    </div>
                    {togglers.togglerOne && (
                      <>
                        {data.products && (
                          <div className=" my-4">
                            {data?.products &&
                              data.products.map((elm) => {
                                return (
                                  <div className=" my-3 d-flex ">
                                    <div
                                      style={{ width: "80px", height: "80px" }}
                                      className=" rounded-2 border grayBorder position-relative"
                                    >
                                      <div
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          background: "#8889DB",
                                          right: "-12px",
                                          top: "-12px",
                                        }}
                                        className=" rounded-circle dm-sans-medium s12 whiteColor d-flex justify-content-center align-items-center  position-absolute"
                                      >
                                        x{elm.totalItems}
                                      </div>
                                      <img
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                        }}
                                        src={elm.img}
                                        href={elm.img}
                                        alt=""
                                      />
                                    </div>
                                    <div className=" px-4 pe-3 w-100 d-flex justify-content-between ">
                                      <div className=" d-flex flex-column">
                                        <p className=" museo-sans-500 s14">
                                          {elm.title}
                                        </p>
                                        <p className=" dm-sans-medium s12 grayColor ">
                                          {elm.id}
                                        </p>
                                      </div>
                                      <div
                                        style={{ minWidth: "100px" }}
                                        className=" d-flex align-items-end flex-column"
                                      >
                                        <p className=" dm-sans-500 s14 ">
                                          {formatAmount(elm?.totalPrice,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                                        </p>
                                        <div className=" grayColor d-flex s12 justify-content-end  ">
                                          <p className="  museo-sans-500 px-1">
                                            <FormattedMessage id="personal.sale.details.p.u" />:{" "}
                                          </p>
                                          <p className=" dm-sans-500  ">
                                          {formatAmount(elm?.price,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </>
                    )}
                    <div className=" pe-3 my-3 grayColor s14  museo-sans-500 d-flex justify-content-between">
                      <p>
                        <FormattedMessage id="personal.sale.details.products.sub.total" />
                      </p>
                      <p className=" dm-sans-medium">
                      {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                      </p>
                    </div>
                    <hr className=" details--hr-modal " />
                    <div className=" pe-3">
                      <div className=" museo-sans-700 d-flex justify-content-between">
                        <h2 className=" s16">
                          <FormattedMessage id="personal.sale.details.taxes" />
                        </h2>
                      </div>
                      {data &&
                data.totalAmountDetail.map((detail) => {
                  return (
                    <div className=" my-3 grayColor s14  museo-sans-500 d-flex justify-content-between">
                      <p>{detail.description}</p>
                      <p>{formatAmount(detail?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}</p>
                    </div>
                  );
                })}
                    </div>
                    <hr style={{height: "1.5px"}} className=" " />
                    <div className=" pe-3 museo-sans-700 ">
                      <div className=" blackColor s16 my-2 d-flex justify-content-between">
                        <p>
                          <FormattedMessage id="personal.sale.details.sale.total" />
                        </p>
                        <p className=" dm-sans-bold ">
                        {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                        </p>
                      </div>
                      <div className=" s14 my-2 d-flex justify-content-between grayColor">
                        <p>
                        <FormattedMessage id="personal.sale.details.sale.total.pvp" />
                        </p>
                        <p className=" dm-sans-medium">
                        {data && formatAmount(data?.totalPVPAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        // FROM HERE ON, CLONE FOR PRINTING PURPOSES!
      }
      {hideOnPrint && (
        <div ref={componentRef} >
        {onShare && (
          <PersonalSalesModalShareOrder
            closeSubModal={onClosure}
            open={open}
            socialArray={socialArray}
            data={data}
          />
        )}
        <div className={`d-flex align-items-center ${onShare && "opacity-0"} justify-content-between py-4 px-4 mx-3`}>
          <div className="d-flex flex-column">
            <div className=" s12 grayColor museo-sans-500 align-items-center d-flex gap-2">
              <p>
                <FormattedMessage id="personal.sale.modal.client" />
              </p>
              <SVGNotEDB />
            </div>
            <h5 className="museo-sans-700 s20 m-0">
              {data ? data.name : ""}
            </h5>
            <div className=" dm-sans-medium s12 py-2 d-flex gap-1">
              <p>
                {data ? (
                  <HandleSwissDates date={data?.date} />
                ) : (
                  "dd-mm-yy"
                )}
              </p>
              <p>|</p>
              <p>
                <FormattedMessage id="personal.sale.details.orden.id" /> <span>{data ? data.id : "XXXXX"}</span>
              </p>
            </div>
          </div>
          {
            !hideOnPrint &&
            <div className=" align-items-center d-flex gap-3 pointer ">
              <div onClick={onClickShare}>
                <SVGShare />
              </div>
              <ReactToPrint
                content={reactToPrintContent}
                removeAfterPrint
                onBeforeGetContent={onPrint}
                onAfterPrint={afterOnPrint}
                trigger={() => {
                  return (
                    <div>
                      <SVGPrint />
                    </div>
                  );
                }}
                documentTitle="Details"
                pageStyle="print"
              />
              <div data-bs-dismiss="modal" aria-label="Close">
                <SVGClose />
              </div>
            </div>
            
          }
        </div>
        <hr  className=" details--hr-modal m-0"></hr>
        <div
          className={` ${onShare && "opacity-0"} hidden-y-scroll d-flex flex-column align-items-center px-3`}
        >
          <div className="my-4 w-100 px-4">
            <div className="d-flex justify-content-between">
              <h3 className="m-0 museo-sans-700 s16">
                <FormattedMessage id="personal.sale.modal.products.count" />:{" "}
                <span className="dm-sans-bold">
                  {" "}
                  {data?.products && data.products.length}{" "}
                </span>
              </h3>
              {
                !hideOnPrint &&
                <div
                  onClick={onToggleOne}
                  className=" museo-sans-700 pointer d-flex gap-2 align-items-center s14 skyColor"
                >
                  <FormattedMessage id="personal.sale.details.products.show" />
                  {togglers.togglerOne ? (
                    <SVGChevronUp />
                  ) : (
                    <SVGChevronDown />
                  )}
                </div>
              }
            </div>
            {togglers.togglerOne && (
              <>
                {data.products && (
                  <div className=" my-4">
                    {data?.products &&
                      data.products.map((elm) => {
                        return (
                          <div className=" my-3 d-flex ">
                            <div
                              style={{ width: "80px", height: "80px" }}
                              className=" rounded-2 border grayBorder position-relative"
                            >
                              <div
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  background: "#8889DB",
                                  right: "-12px",
                                  top: "-12px",
                                }}
                                className=" rounded-circle dm-sans-medium s12 whiteColor d-flex justify-content-center align-items-center  position-absolute"
                              >
                                x{elm.totalItems}
                              </div>
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                src={elm.img}
                                href={elm.img}
                                alt=""
                              />
                            </div>
                            <div className=" px-4 pe-3 w-100 d-flex justify-content-between ">
                              <div className=" d-flex flex-column">
                                <p className=" museo-sans-500 s16">
                                  {elm.title}
                                </p>
                                <p className=" dm-sans-medium s14 grayColor ">
                                  {elm.id}
                                </p>
                              </div>
                              <div
                                style={{ minWidth: "100px" }}
                                className=" d-flex align-items-end flex-column"
                              >
                                <p className=" dm-sans-500 s14 ">
                                {formatAmount(elm?.totalPrice,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                </p>
                                <div className=" grayColor d-flex s12 justify-content-end  ">
                                  <p className=" museo-sans-500 px-1">
                                    <FormattedMessage id="personal.sale.details.p.u" />:{" "}
                                  </p>
                                  <p className=" dm-sans-500  ">
                                  {formatAmount(elm?.price,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}
              </>
            )}
            <div className=" pe-3 my-3 grayColor s14  museo-sans-500 d-flex justify-content-between">
              <p>
                <FormattedMessage id="personal.sale.details.products.sub.total" />
              </p>
              <p className=" dm-sans-medium">
              {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
              </p>
            </div>
            <hr className="details--hr-modal" />
            <div className=" pe-3">
              <div className=" museo-sans-700 d-flex justify-content-between">
                <h2 className=" s16">
                  <FormattedMessage id="personal.sale.details.taxes" />
                </h2>
              </div>
              {data &&
        data.totalAmountDetail.map((detail) => {
          return (
            <div className=" my-3 grayColor s14  museo-sans-500 d-flex justify-content-between">
              <p>{detail.description}</p>
              <p>
              {formatAmount(detail?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
              </p>
            </div>
          );
        })}
            </div>
            <hr className="details--hr-modal" />
            <div className=" pe-3 museo-sans-700 ">
              <div className=" blackColor s16 my-2 d-flex justify-content-between">
                <p>
                  <FormattedMessage id="personal.sale.details.sale.total" />
                </p>
                <p className=" dm-sans-bold ">
                {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
              <div className=" s14 my-2 d-flex justify-content-between grayColor">
                <p>
                <FormattedMessage id="personal.sale.details.sale.total.pvp" />
                </p>
                <p className=" dm-sans-medium">
                {data && formatAmount(data?.totalPVPAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
}
