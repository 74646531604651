import { useState, useEffect } from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

import { ReactComponent as Red } from "../../../../common/svgs/ic-alert-circle-red.svg";

import { ReactComponent as Gray } from "../../../../common/svgs/ic-alert-circle-grey.svg";

import { ReactComponent as Yellow } from "../../../../common/svgs/ic-alert-circle-yellow.svg";

import { ReactComponent as Green } from "../../../../common/svgs/ic-check-circle.svg";

import { ReactComponent as Purple } from "../../../../common/svgs/ic-req-red.svg";

import { ReactComponent as SVGMail } from "../../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGInstagram } from "../../../../common/svgs/ic-instagram-md.svg";
import { ReactComponent as SVGFacebook } from "../../../../common/svgs/ic-facebook-md.svg";
import { ReactComponent as SVGECommerce } from "../../../../common/svgs/ic-tienda-virtual-md.svg";

import { ReactComponent as SVGRightArrow } from "../../../../common/svgs/ic-arrow-right-sm.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePersonalGroupName,
  clearInsideArray,
  pushInsideArray,
} from "../../../../Descendants/Framework/redux/DescendantsAction";
import ProgressBarParams from "../../../../common/Share/ProgressBarParams";
import ImageCircleHandler from "../../../../common/Share/ImageCircleHandler";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../../common/Share/ReformatLevelByGender";
import checkReduceRequirement from "../../../../common/Share/checkReduceRequirement";

export default function DashboardMemberDetailsLeadersModal({ open }) {
  const memberData = useSelector((state) => state.scoreData.groupTarget);
  const history = useNavigate();
  const dispatch = useDispatch();

  const [toggler, setToggler] = useState(false);
  const [togglerHelper, setTogglerHelper] = useState(false);
  
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  
  useEffect(() => {
    CloseTogglerHelper();
  }, [memberData])

  const OpenTogglerHelper = () => {
    setTogglerHelper(true)
  }

  const CloseTogglerHelper = () => {
    setTogglerHelper(false)
  }

  const addProgressBar = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarParams
            height={10}
            completed={100}
            level={memberData?.profileResume?.level}
            incompleted={false}
            status={memberData.incorporatedSales.status}
            gap={memberData.incorporatedSales.status}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarParams
            height={10}
            completed={0}
            level={memberData?.profileResume?.level}
            incompleted={true}
            status={memberData.incorporatedSales.status}
            gap={memberData.incorporatedSales.status}
          />
        );
      } else {
        aux.push(
          <ProgressBarParams
            height={10}
            completed={100}
            level={memberData?.profileResume?.level}
            incompleted={true}
            status={memberData.incorporatedSales.status}
            gap={memberData.incorporatedSales.status}
          />
        );
      }
    }

    return aux;
  };

  const configExist = (auxId) => {
    let resolve = 0;
    if (memberData) {
      resolve = memberData.profileResume.socialNetwork
        .map((elm) => elm.id)
        .indexOf(auxId);
    }
    return resolve;
  };

  const onProfileDsc = ({ id, name }) => {
    dispatch(changePersonalGroupName({ name: name, clientId: id }));

    history(`/dashboard/descendants/profile/${id}`);
  };

  const onProfileAsc = ({ id, name, uplineId, uplineName }) => {
    dispatch(changePersonalGroupName({ name: name, clientId: id }));
    dispatch(pushInsideArray({ clientId: uplineId, name: uplineName }));

    history(`/dashboard/descendants/profile/asc/${uplineId}`);
  };

  dispatch(clearInsideArray());

  return (
        <div
          className="modal fade"
          id="modalQualificationFirstGenB"
          aria-hidden="true"
          aria-labelledby="modalQualificationFirstGenB"
          tabIndex="-1"
        >
          <DashboardMemberDetailsLeaderHelper close={CloseTogglerHelper} togglerHelper={togglerHelper} />
          {
            !togglerHelper &&
            <div
              style={{ maxWidth: "496px" }}
              className="modal-dialog modal-dialog-centered"
            >
              <div
                style={{ maxWidth: "496px" }}
                className="modal-content modal-radius"
              >
                <div
                  style={{ maxWidth: "496px" }}
                  className=""
                >
                  {memberData && (
                    <div
                      style={{ borderRadius: "16px" }}
                      className=" align-items-center flex-column position-relative donutHeaderBg  d-flex justify-content-center"
                    >
                      <div
                        style={{ top: "10px", right: "10px" }}
                        className=" position-absolute pointer "
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={CloseTogglerHelper}
                      >
                        <SVGClose />
                      </div>
                      <div
                        style={{ marginTop: "28px" }}
                        className=" d-flex align-items-center flex-column"
                      >
                        <div style={{width: "88px", height: "88px" }} className=" outline-profile-picture d-flex justify-content-center align-items-center border-1 rounded-circle border-white" >
                          <ImageCircleHandler
                            width={"80px"}
                            height={"80px"}
                            url={memberData?.profileResume.image}
                            name={memberData?.profileResume?.name}
                            firstName={memberData?.profileResume?.firstName}
                            lastName={memberData?.profileResume?.lastName}
                            size={"26px"}
                            top={0}
                          />
                        </div>
                        <div className="mt-2">
                          <div
                            style={{ alignItems: "center" }}
                            className=" gap-1 d-flex justify-content-center"
                          >
                            <p className=" s16 museo-sans-700 ">
                              {memberData.profileResume.name}
                            </p>
                            <SVGStatusHandler memberData={memberData} />
                          </div>
                          <p className="mt-2 s14 text-center museo-sans-500 ">
                          <ReformatLevelByGender gender={memberData.profileResume?.gender} level={memberData.profileResume?.level} roleDescriptionDefault={memberData.profileResume?.roleDescription} />
                          </p>
                          <div className=" justify-content-center d-flex gap-2 text-center s14 mt-3">
                            <p className=" museo-sans-500 grayColor ">
                              <FormattedMessage id="dashboard.modal.group.details.template.filter" />
                              :
                            </p>
                            <p
                              data-bs-dismiss="modal"
                              aria-label="Close"
                              onClick={() =>
                                onProfileAsc({
                                  id: memberData?.clientId,
                                  name: memberData?.profileResume?.name,
                                  uplineId: memberData?.profileResume?.uplineId,
                                  uplineName: memberData?.profileResume?.uplineName,
                                })
                              }
                              className=" museo-sans-700 skyColor pointer "
                            >
                              {memberData.profileResume.uplineName}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ height: "32px" }} className="w-100">
                        <hr className=" px-5 mx-5" />
                      </div>
                      <div
                        style={{ paddingBottom: "24px" }}
                        className=" w-100 d-flex justify-content-center align-items-center gap-3 px-5 pt-1 px-4"
                      >
                        <div className=" d-flex justify-content-end gap-3">
                          {configExist("email") !== -1 && (
                            <a
                              target="_blank"
                              href={`mailto:${
                                memberData.profileResume.socialNetwork[
                                  configExist("email")
                                ].value
                              }`}
                            >
                              <SVGMail />
                            </a>
                          )}
                          {configExist("whatsapp") !== -1 && (
                            <a
                              target="_blank"
                              href={`https://wa.me/${
                                memberData.profileResume.socialNetwork[
                                  configExist("whatsapp")
                                ].value
                              }`}
                            >
                              <SVGWhatsap />
                            </a>
                          )}
                          {configExist("messenger") !== -1 && (
                            <a
                              target="_blank"
                              href={`https://m.me/${
                                memberData.profileResume.socialNetwork[
                                  configExist("messenger")
                                ].value
                              }`}
                            >
                              <SVGMessenger />
                            </a>
                          )}
                          {configExist("facebook") !== -1 && (
                            <a
                              target="_blank"
                              href={`${
                                memberData.profileResume.socialNetwork[
                                  configExist("facebook")
                                ].value
                              }`}
                            >
                              <SVGFacebook />
                            </a>
                          )}
                          {configExist("instagram") !== -1 && (
                            <a
                              target="_blank"
                              href={`https://www.instagram.com/${
                                memberData.profileResume.socialNetwork[
                                  configExist("instagram")
                                ].value
                              }`}
                            >
                              <SVGInstagram />
                            </a>
                          )}
                          {configExist("tienda_virtual") !== -1 && (
                            <a
                              target="_blank"
                              href={`${
                                memberData.profileResume.socialNetwork[
                                  configExist("tienda_virtual")
                                ].value
                              }`}
                            >
                              <SVGECommerce />
                            </a>
                          )}
                        </div>
                        <div
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() =>
                            onProfileDsc({
                              id: memberData?.clientId,
                              name: memberData?.profileResume?.name,
                            })
                          }
                          className=" pointer align-items-center museo-sans-700 s14 skyColor d-flex gap-2"
                        >
                          <p>
                            <FormattedMessage id="dashboard.modal.group.details.template.show.profile" />
                          </p>
                          <SVGRightArrow />
                        </div>
                      </div>
                      <div className=" d-flex justify-content-center align-items-center gap-1 pb-3 s14 museo-sans-500 w-100">
                        <div>

                          <FormattedMessage id="dashboard.qualifications.title.one.c" />
                        </div>
                        <div
                        style={{width: "16px", height: "16px", top: "-4px"}}
                        className=" pointer svg-force-size position-relative "
                        onClick={OpenTogglerHelper}
                        >
                          <HelpIconNoTarget />

                        </div>
                      </div>
                      <div className=" w-100 mx-3 px-4 d-flex ">
                        {toggler ? (
                          <>
                            <div
                              style={{
                                borderRadius: "16px 16px 0px 0px",
                                width: "183px",
                                height: "38px",
                              }}
                              onClick={() => setToggler(!toggler)}
                              className=" tabs-shadow pointer museo-sans-500 s14 d-flex justify-content-center align-items-center lightGreyBg grayColor "
                            >
                              <FormattedMessage id="level.two.qualified.b" />
                            </div>
                            <div
                              style={{
                                borderRadius: "16px 16px 0px 0px",
                                width: "183px",
                                height: "38px",
                              }}
                              className=" tabs-shadow pointer museo-sans-500 s14 d-flex justify-content-center align-items-center bg-white"
                            >
                              <FormattedMessage id="level.one.not.inclusive" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={{
                                borderRadius: "16px 16px 0px 0px",
                                width: "183px",
                                height: "38px",
                              }}
                              className=" tabs-shadow pointer museo-sans-500 s14 d-flex justify-content-center align-items-center bg-white"
                            >
                              <FormattedMessage id="level.two.qualified.b" />
                            </div>
                            <div
                              style={{
                                borderRadius: "16px 16px 0px 0px",
                                width: "183px",
                                height: "38px",
                              }}
                              onClick={() => setToggler(!toggler)}
                              className=" tabs-shadow pointer museo-sans-500 s14 d-flex justify-content-center align-items-center lightGreyBg grayColor"
                            >
                              <FormattedMessage id="level.one.not.inclusive" />
                            </div>
                          </>
                        )}
                      </div>
                      {
                        !toggler ?
                          <div
                        style={{ borderRadius: "0px 0px 16px 16px" }}
                        className="  w-100 bg-white shadow p-4 "
                      >
                        <p className=" mt-2 px-3 s16 museo-sans-700">
                          <FormattedMessage id="dashboard.modal.group.details.template.qualification" />
                        </p>
                        <div  style={{paddingBottom: "17px"}} className=" pt-3 d-flex flex-column gap-2 px-3">
                          <div style={{height: "16px"}} className=" s14 d-flex align-items-center justify-content-between">
                            <p className=" museo-sans-500">
                              <FormattedMessage id="dashboard.qualification.ul.personal.sale" />
                            </p>
                            <div className="d-flex  dm-sans-medium ">
                              <p className="">
                              {formatAmount(memberData.personalSales.actualAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                              </p>
                              <p className=" grayColor">
                                / {formatAmount(memberData.personalSales.totalAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                              </p>
                            </div>
                          </div>
                          <ProgressBarParams
                            height={10}
                            completed={
                              (memberData.personalSales.actualAmount * 100) /
                              memberData.personalSales.totalAmount
                            }
                            incompleted={
                              memberData.personalSales.actualAmount <=
                              memberData.personalSales.totalAmount
                                ? true
                                : false
                            }
                            gap={memberData.personalSales.status}
                            status={memberData.personalSales.status}
                            level={memberData?.profileResume?.level}
                          />
                        </div>
                        <div  style={{paddingBottom: "17px"}} className=" d-flex gap-2 flex-column px-3">
                          <div style={{height: "16px"}} className=" d-flex align-items-center justify-content-between">
                            <p className=" museo-sans-500 s14">
                              <FormattedMessage id="dashboard.qualification.ul.incorporated" />
                            </p>
                            <div className=" position-relative s14 dm-sans-medium d-flex">
                              <p>
                                {" "}
                                {memberData.incorporatedSales.actualActives.toLocaleString()}
                              </p>
                              <p> </p>
                              { checkReduceRequirement(memberData?.consultantStatus?.reducedRequirements ) ? (
                               
                                  <div className="d-flex">
                                    <p className=" lightPurpleColor ">
                                      {" "}
                                      /{" "}
                                      {memberData.incorporatedSales.totalNeed.toLocaleString()}
                                    </p>
                                    <div
                                      className=" position-relative"
                                      style={{
                                        borderRadius: "50%",
                                        marginLeft: "5px",
                                        width: "18px",
                                        height: "18px",
                                        top: "-3px",
                                      }}
                                    >
                                      <Purple />
                                    </div>
                                  </div>
                              ) : (
                                  <p className="grayColor ">
                                    {" "}
                                    /{" "}
                                    {memberData.incorporatedSales.totalNeed.toLocaleString()}
                                  </p>
                              )}
                            </div>
                          </div>
                          <div className="d-flex gap-3">
                            {addProgressBar(
                              memberData?.incorporatedSales?.actualActives,
                              memberData?.incorporatedSales?.totalNeed,
                              memberData?.incorporatedSales?.status
                            ).map((JSX) => {
                              return JSX;
                            })}
                          </div>
                        </div>
                        <div  style={{paddingBottom: "15px"}} className=" d-flex flex-column gap-2 px-3">
                          <div style={{height: "16px"}} className=" s14 d-flex align-items-center justify-content-between">
                            <p className=" museo-sans-500">
                              <FormattedMessage id="dashboard.qualification.ul.group.sale" />
                            </p>
                            <div className="d-flex  dm-sans-medium ">
                              <p className="">
                              {formatAmount(memberData.groupSales.actualAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                              </p>
                              { checkReduceRequirement(memberData?.consultantStatus?.reducedRequirements) ? (
                                
                                  <div className="d-flex">
                                    <p className=" lightPurpleColor ">
                                      {" "}
                                      / {formatAmount(memberData.groupSales.totalAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                    </p>
                                    <div
                                      className=" position-relative"
                                      style={{
                                        borderRadius: "50%",
                                        marginLeft: "5px",
                                        width: "18px",
                                        height: "18px",
                                        top: "-3px",
                                      }}
                                    >
                                      <Purple />
                                    </div>
                                  </div>
                              ) : (
                                
                                  <p className=" grayColor">
                                    / {formatAmount(memberData.groupSales.totalAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                  </p>
                                
                              )}
                            </div>
                          </div>
                          <ProgressBarParams
                            height={10}
                            completed={
                              (memberData.groupSales.actualAmount * 100) /
                              memberData.groupSales.totalAmount
                            }
                            incompleted={
                              memberData.groupSales.actualAmount <=
                              memberData.groupSales.totalAmount
                                ? true
                                : false
                            }
                            
                            gap={memberData.groupSales.status}
                            status={memberData.groupSales.status}
                            level={memberData?.profileResume?.level}
                          />
                        </div>
                      </div>
                        :
                          <div
                        style={{ borderRadius: "0px 0px 24px 24px" }}
                        className="  w-100 bg-white shadow p-4 "
                      >
                        <div className=" px-3 py-3">
                          <div className=" s14 d-flex align-items-center justify-content-between">
                            <p className=" text-capitalize py-1 museo-sans-500">
                              <FormattedMessage id="dashboard.period.subtitle.one" />
                            </p>
                            <div className="d-flex  dm-sans-medium ">
                              <p className="">
                              {formatAmount(memberData.triperiodicSales.actualAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                              </p>
                              <p className=" grayColor">
                                / {formatAmount(memberData.triperiodicSales.totalAmount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                              </p>
                            </div>
                          </div>
                          <ProgressBarParams
                            height={10}
                            completed={
                              (memberData.triperiodicSales.actualAmount * 100) /
                              memberData.triperiodicSales.totalAmount
                            }
                            incompleted={
                              memberData.triperiodicSales.actualAmount <=
                              memberData.triperiodicSales.totalAmount
                                ? true
                                : false
                            }
                            level={memberData?.profileResume?.level}
                            status={memberData?.triperiodicSales?.status}
                            gap={memberData?.triperiodicSales?.status}
                          />
                        </div>
                        <div>
                          <hr className=" mx-3 mt-2 mb-4 " ></hr>
                        </div>
                        <div className=" px-3 gap-3 s14 museo-sans-500 d-flex flex-column" >
                            {
                              memberData?.triperiodicSales?.periods.map((elm) => {
                                return <>
                                {
                                  elm.state == "actual" ?
                                    <div className="d-flex justify-content-between" >
                                      <p className=" museo-sans-700" >
                                        {elm?.title}
                                      </p>
                                      <p className=" dm-sans-bold" >
                                      {formatAmount(elm.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                      </p>
                                    </div>
                                  :
                                  <div className="d-flex justify-content-between" >
                                      <p>
                                        {elm?.title}
                                      </p>
                                      <p className=" dm-sans-medium" >
                                      {formatAmount(elm.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                                      </p>
                                    </div>
                                }
                                    
                                </>
                              })
                            }
                        </div>
                      </div>
                      }
                      
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
        </div>
  );
}

export function SVGStatusHandler({ memberData }) {
  return (
    <>
      {memberData?.groupSales?.status == "gap" ||
      memberData?.incorporatedSales?.status == "gap" ||
      memberData?.personalSales?.status == "gap" ? (
        <div
          style={{
            borderRadius: "50%",
            marginBottom: "12px",
            width: "18px",
            height: "18px",
          }}
        >
          <Gray />
        </div>
      ) : (
        <>
          { checkReduceRequirement(memberData?.consultantStatus?.reducedRequirements ) ? (
            
              <div
                style={{
                  borderRadius: "50%",
                  marginBottom: "12px",
                  width: "18px",
                  height: "18px",
                }}
              >
                {" "}
                <Purple />{" "}
              </div>
          ) : (
            <>
              {memberData?.groupSales?.status != "normal" &&
                memberData?.incorporatedSales?.status != "normal" &&
                memberData?.personalSales?.status != "normal" && (
                  <>
                    {(memberData?.groupSales?.status == "risk" ||
                      memberData?.incorporatedSales?.status == "risk" ||
                      memberData?.personalSales?.status == "risk") && (
                      <div
                        style={{
                          borderRadius: "50%",
                          marginBottom: "12px",
                          width: "18px",
                          height: "18px",
                        }}
                      >
                        {" "}
                        <Red />{" "}
                      </div>
                    )}
                    {memberData?.groupSales?.status == "completed" &&
                      memberData?.incorporatedSales?.status == "completed" &&
                      memberData?.personalSales?.status == "completed" && (
                        <div
                          style={{
                            borderRadius: "50%",
                            marginBottom: "12px",
                            width: "18px",
                            height: "18px",
                          }}
                        >
                          {" "}
                          <Green />{" "}
                        </div>
                      )}
                    {memberData?.groupSales?.status == "warning" &&
                      memberData?.incorporatedSales?.status == "warning" &&
                      memberData?.personalSales?.status == "warning" && (
                        <div
                          style={{
                            borderRadius: "50%",
                            marginBottom: "12px",
                            width: "18px",
                            height: "18px",
                          }}
                        >
                          {" "}
                          <Yellow />{" "}
                        </div>
                      )}
                  </>
                )}
            </>
          )}
        </>
      )}
    </>
  );
}

export function DashboardMemberDetailsLeaderHelper({close, togglerHelper}) {
  
  return <>
  {
    togglerHelper &&
    <div
    style={{zIndex: 1000}}
      className=" position-absolute d-flex w-100 h-100 justify-content-center  "
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "610px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-700 px-2 s20 m-0">
                    <FormattedMessage
                      id="dashboard.qualifications.modal.c.title"
                      defaultMessage="Buenas Prácticas"
                    /> 
                  </h5>
                </div>
                <div className=" pointer " onClick={close} >
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div style={{ maxHeight: "348px" }} className=" hidden-y-scroll d-flex flex-column align-items-center">
              
                <div className=" d-flex flex-column mt-4 px-5">
                
                  <p className="s16 py-2 museo-sans-500 ">
                    <FormattedMessage id="dashboard.qualifications.modal.c.content.five" />
                    <span className=" museo-sans-700 " >
                    "<FormattedMessage id="dashboard.qualifications.modal.c.content.six" />"
                    </span>
                    <span>
                    <FormattedMessage id="dashboard.qualifications.modal.c.content.four" />
                    </span>
                  </p>
                </div>
              </div>
              <div style={{zIndex: 2}} className=" position-relative w-100 d-flex justify-content-center">
                <button
                  type="button"
                  className=" btnLoginWidth my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                  onClick={close}
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  </>

}
