import imagen1 from "../../../common/img/Swiss-just.png";
import { ReactComponent as SVGExternalLink } from "../../../common/svgs/ic-external-link-sm.svg";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { notificationSharingData } from "../../Framework/redux/OfertsActions";
import { OfertCardDates } from "./dates/OfertsCardDates";
import replaceElementsURLImage from "../../../common/Share/replaceElementURLImage";

export default function OfertsCardHolderBodyNotis({
    setOffersInfoModal,
    windowWidth,
    auxiliarSkeleton,
    dataArray,
    applyToAux
  }) {
    
  const dispatch = useDispatch();

    const dispatchNewInfo = () => {
        const payload = {
            data: dataArray,
            title: `Noti${switchPerValue(dataArray?.applyTo)}`
        }
        dispatch(notificationSharingData(payload));
        const id = document.getElementById("button-sharing-modal")
        id.click();
    };

    const switchPerValue = (type) => {

      if (type == "cliente") {
        return "Clientes"
      };
      if (type == "Consultoras") {
        return "Consultores"
      };
  
      return type
  
    };

    return (
      <>
        
        <div className=" invisible"
        id="close-share-offer"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
            
        </div>
          <div
            style={{ zIndex: 2 }}
            className=" position-relative d-flex flex-column museo-sans-500 h-100 "
          >
            <div
              className="d-flex justify-content-center"
              style={{
                height: "141px",
                width: "288px",
              }}
            >
              {
                !auxiliarSkeleton ?
                <>
                <img
                onClick={() => {
                  dispatchNewInfo();
              }}
                  style={{
                    borderRadius: "16px 16px 0px 0px",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    objectPosition: "top",
                  }}
                  className="pointer brightGrayBg lightui1-shimmer"
                  src={
                      (dataArray?.image?.id && dataArray?.image?.filename_download ) ? `${replaceElementsURLImage( dataArray?.imageUrl , dataArray?.image?.id , dataArray?.element?.image?.filename_download )}` : imagen1 
                  }
                />
                </>
                :
                <>
                  <div style={{
                  borderRadius: "16px 16px 0px 0px",
                  objectFit: "cover",
                  height: "141px",
                  border: "5px #DDDDDD solid",
                  width: "288px",
                  objectPosition: "top",
                }} className=" brightGrayBg lightui1-shimmer" >

                  </div>
                </>
              }
            </div>
            <div
              style={{
                zIndex: 2,
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                height: "220px",
                boxShadow: "0px 8px 15px -10px rgba(0, 0, 0, 0.2)",
              }}
              className={` cards-shadow position-relative d-flex justify-content-between bg-white flex-column ${
                windowWidth <= 1280 ? "px-3 py-4" : "p-4"
              } `}
            >
              <div>
                <div style={{height: "22px"}} className=" mx-2 d-flex justify-content-between" >
                <h5
                  className=" museo-sans-700 s16"
                  style={{
                    marginBottom: "0px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: "38px",
                  }}
                >
                  <FormattedMessage id="noti" /><span className=" text-capitalize" >{switchPerValue(dataArray?.applyTo)} </span> 
                </h5>
                <a target="_blank" href={dataArray?.iPaperLink} className=" d-flex gap-2" >
                  <p className=" skyColor s14 " >
                      <FormattedMessage id="visit" />
                  </p>
                  <div>
                      <SVGExternalLink />
                  </div>

                </a>
                </div>
                <div className="mx-2" >
                  <p className=" museo-sans-500 s14" >
                  {
                      dataArray?.title
                  }
                  </p>
                </div>
                <div className="mx-2"
                  style={{
                    width: "224px",
                    height: "66px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginTop: "10px",
                    paddingTop: "5px",
                  }}
                >
                  <p className=" s14 grayColor ">
                    {dataArray?.subtitle}
                  </p>
                  <p className=" s14 museo-sans-500 grayColor" >
                    {OfertCardDates(
                      dataArray?.dateStartOfPublication,
                      dataArray?.dateEndOfPublication
                    )}
                  </p>
                  <p>

                  </p>
                </div>

              </div>
              <div className="mx-2" style={{ height: "40px" }}>
                <>
                <div className=" position-absolute invisible"
                id="button-sharing-modal"
                data-bs-toggle="modal"
                data-bs-target="#modalNotiSharing"
                 >

                </div>

                  <button
                    style={{ width: "224px", height: "40px" }}
                    className="p-2 skyColor bg-white skyBorder museo-sans-700 s14 rounded-3 w-100 "
                    
                    onClick={() => {
                        dispatchNewInfo();
                    }}
                  >
                    <p><FormattedMessage id="oferts.share.general.button" /> <span className=" text-capitalize" > {switchPerValue(dataArray?.applyTo)}</span></p>
                  </button>
                </>
              </div>
            </div>
            <UnderCards />
          </div>
      </>
    );
  }

  export function UnderCards(params) {
    return <>
    <div
              style={{
                width: "95%",
                height: "100px",
                zIndex: 1,
                borderRadius: "16px",
                bottom: "-8px",
                left: "2%",
              }}
              className=" bg-white cards-shadow position-absolute"
            ></div>{" "}
            <div
              style={{
                width: "85%",
                height: "100px",
                zIndex: 0,
                borderRadius: "16px",
                bottom: "-18px",
                left: "6%",
              }}
              className=" bg-white cards-shadow position-absolute"
            ></div>
    </>
  }