import React from "react";

import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

export default function BusinessPlanModalInfomodalInfoQualification() {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
        <div className="d-flex">
          <HelpIconNoTarget />
          <h5 className="museo-sans-500 px-2 s20 m-0 text-capitalize ">
            <FormattedMessage id="dashboard.modal.qualification.title" />
          </h5>
        </div>
        <div className=" pointer " data-bs-dismiss="modal" aria-label="Close">
          <SVGClose />
        </div>
      </div>
      <hr className="m-0"></hr>
      <div
        style={{ maxHeight: "372px" }}
        className=" hidden-y-scroll d-flex flex-column align-items-center"
      >
        <div className="mt-4 px-5">
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage id="dashboard.modal.p.two" />
          </p>
        </div>
      </div>
      <div className=" w-100 d-flex justify-content-center">
        <button
          type="button"
          className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <FormattedMessage
            id="buttons.understood"
            defaultMessage="Entendido"
          />
        </button>
      </div>
    </div>
  );
}
