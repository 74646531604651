import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

export default function AccountModalHistory () {

    return(
      <div>
      <div className="d-flex justify-content-between p-4 m-3 ">
        <div className="d-flex align-items-center">
          <HelpIconNoTarget />
          <h5 className="museo-sans-500 px-2 s20 m-0">
            <FormattedMessage
              id="account.modal.info.title"
              defaultMessage="Buenas Prácticas"
            /> 
          </h5>
        </div>
        <div
        className="pointer"
        data-bs-dismiss="modal"
        aria-label="Close"
        >
          <SVGClose />
        </div>
      </div>
      <hr className="m-0"></hr>
      <div className="d-flex flex-column align-items-center">
      <div className=" museo-sans-500 mt-4 px-4 mx-3">
          <p className="s16 py-2">
          <FormattedMessage
              id="account.modal.info.p.one"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 museo-sans-700 py-2">
          <FormattedMessage
              id="account.modal.info.ul.title"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2">
          <FormattedMessage
              id="account.modal.info.li.one"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2">
          <FormattedMessage
              id="account.modal.info.li.two"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2">
          <FormattedMessage
              id="account.modal.info.li.three"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2">
          <FormattedMessage
              id="account.modal.info.li.four"
              defaultMessage="Buenas Prácticas"
            />
          </p>
        </div>
        <button
          type="button"
          className=" btnLoginWidth my-5 museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light-text button-bootstrap-radius button-padding"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <FormattedMessage
            id="buttons.understood"
            defaultMessage="Entendido"
          />
        </button>
      </div>
    </div>
    )
}
