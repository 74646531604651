import React from "react";

const STROKE = 1;

const LineChart = ({
  data,
  height,
  ref,
  width,
  horizontalGuides: numberOfHorizontalGuides,
  precision,
  onClickDate,
  graphId,
  background,
  type
}) => {

  let completeReach = data?.length;

  if (data.length != 12) {
    let auxY = 0;
    let auxLabel = "null";

    if (data.length == 1) {
      data = [...data, { y: auxY, x: data[0].x, label: auxLabel}]
      
    }

    while (data.length != 12) {
      let auxX = data.length ;
      data = [...data, { y: auxY, x: auxX, label: auxLabel}]
    }
  }

  const FONT_SIZE = width / 50;
  const maximumXFromData = Math.max(...data.map((e) => e.x));
  const maximumYFromData = Math.max(...data.map((e) => e.y));
  const minYFromData = Math.min(...data.map((e) => e.y));
  const digits =
    parseFloat(maximumYFromData.toString()).toFixed(precision).length + 1;

  const padding = (FONT_SIZE + digits) * 2;
  const chartWidth = width - 90;
  const chartHeight = height - padding - 40;

  const confusioZero = (y, auxDefault) => {
    if ((y == 0) || (minYFromData == y)) {
      return 165
    } else {
      return auxDefault
    }
  }

  const patchVersionFour = (y) => {
    if (y == minYFromData && y == 0) {
      return 170
    } else if (y == maximumYFromData) {
      return 6
    } else {
      return 170 - ((y - minYFromData) / (maximumYFromData - minYFromData) ) * 170
    }
  }

  const points = data
    .map((element) => {
      let x = 0;
      if (element.x == 0) {
        x = (element.x) + padding;
      } else {
        x = (element.x / maximumXFromData) * chartWidth + padding;
      }
      const y = chartHeight - ( element.y / maximumYFromData) * chartHeight + 10;
      if (element.label == "null" ) {
        return ` `;
      } else {
        return `${x},${patchVersionFour(element.y)}`;
      }
    })
    .join(" ");

  const Axis = ({ points }) => (
    <polyline fill="none" stroke="#ccc" strokeWidth="0" points={points} />
  );

  const XAxis = () => (
    <Axis
      points={`${padding},${height - padding} ${width - padding},${
        height - padding
      }`}
    />
  );

  const YAxis = () => (
    <Axis points={`${padding},${padding} ${padding},${height - padding}`} />
  );

  const HorizontalGuides = () => {
    const startX = 25;
    const endX = width - 25;

    return new Array(numberOfHorizontalGuides).fill(0).map((_, index) => {
      
      let yCoordinate = 0
      if (index == 0) {
        yCoordinate = 155;
      } else {
        yCoordinate = 155 - ( 70 * index );
      }

      return (
        <React.Fragment key={index}> 
          <polyline
            fill="none"
            stroke={"#ccc"}
            strokeWidth="1"
            points={`${startX},${yCoordinate} ${endX},${yCoordinate}`}
          />
        </React.Fragment>
      );
    });
  };

  const LabelsXAxis = ({currentId}) => {
    
    const y = 222
    let val = -1;
    let spaceBetween = 60

    return data.map((element, index) => {
      
      val++;
      let x = 0;
      if (element.x == 0) {
        x = (parseInt(element.x)) + padding - FONT_SIZE / 2;
      } else {
        x = (parseInt(element.x) / maximumXFromData) * chartWidth + padding - FONT_SIZE / 2;
      }
      
      return (
        <g className="pointer" >
          <text
            id={val}
            onClick={(evt) => {
              onClickDate(evt.target.id);
            }}
            className=" pointer dm-sans-500 s12 p-1"
            key={index}
            x={parseInt(x)}
            y={parseInt(y)}
            style={{
              zIndex: 10,
              background: "#5AAFF1",
              cursor: "pointer",
            }}
          >
            {( currentId != val && element?.label?.length > 1 ) &&  shapeLabelDate(element.label)}
          </text>
        </g>
      );
    });
  };

  const getCx = (auxX) => {

    let x = 0

    if (auxX == 0) {
      
       x = auxX + padding;
    } else {

       x = (auxX / maximumXFromData) * chartWidth + padding;
    }


    return x;
  };

  const getCy = (auxY) => {
    let y = 0

    if (auxY == 0) {
      y =  confusioZero(auxY, chartHeight - (auxY) + 10 );
    } else {
      y = chartHeight - (auxY / maximumYFromData) * chartHeight + 10;
    }



    return patchVersionFour(auxY) ;
  };

  const shapeLabelDate = (string) => {
    // data[graphId]?.label
    if (string == "null") {
      return null;
    }
    let auxSplitted = string.split("/");
    let auxFirstValue = auxSplitted[0];

    if (auxFirstValue.length < 2) {
      auxFirstValue = `0${auxFirstValue}`;
    }

    let auxReturnedValue = `${auxFirstValue}/${auxSplitted[1]}`
    return auxReturnedValue;
  }


  return (
    <svg viewBox={`0 0 ${width} ${height}`} style={{ overflowX: "scroll" , position: 'relative' }}>
      <XAxis />
      <LabelsXAxis currentId={graphId} />
      <YAxis />
      <HorizontalGuides />
      <g> 
        <polyline
          fill="none"
          stroke={background}
          strokeWidth={3.5}
          points={points}
        />
        {graphId && 
        <>
          <circle
            fill={background}
            stroke="#fff"
            strokeWidth={1.5}
            r={6}
            cx={getCx(data[graphId]?.x)}
            cy={getCy(data[graphId]?.y)}
            />
          <rect
            style={{ opacity: 0.10 }}
            width="35"
            height="380"
            rx="0"
            ry="0"
            transform={`translate(${getCx(data[graphId]?.x) - 14} -160)`}
            fill="#5AAFF1"
            strokeWidth="0"
            />
          <rect
            width="40"
            height="30"
            rx="15"
            ry="15"
            transform={
              `translate(${getCx(data[graphId]?.x) - 14} 202)`
            }
            fill="#5AAFF1"
            strokeWidth="0"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDashoffset="1"
            />
          <text
            dx="0"
            dy="0"
            fontSize="12"
            fontWeight="500"
            transform={
              `translate(${getCx(data[graphId]?.x) - 10} 222)`          
            }
            fill="#fff"
            strokeWidth="0"
            >
            {shapeLabelDate(data[graphId]?.label)}
          </text>
            </>
        
        }
      </g>
    </svg>
  );
};

export default LineChart;
