

export default function InvitationListRowProgressBall({
    colors,
    data,
    index,
    lastData,
  }) {
    const stepData = data[index - 1];
    const nextData = data[index == 4 ? "" : index];
  
    return (
        <div
          style={{ width: "127px", height: "32px" }}
          className=" d-flex align-items-center position-relative"
        >
          <div className="w-100 d-flex align-items-center justify-content-between">
            <>
              {index != 1 ? (
                <div
                  className=" s12"
                  style={{
                    width: "108px",
                    height: "4px",
                    background: colors(
                      stepData?.stageDetail?.state,
                      lastData?.stageDetail?.state
                    ),
                  }}
                ></div>
              ) : (
                <div
                  className="blueBg s12 invisible"
                  style={{ width: "108px", height: "4px" }}
                ></div>
              )}
            </>
            {
              stepData?.stageDetail?.state !== "DISABLED" && 
              <>
                <div
                  className=" position-relative"
                  style={{ top: 2, width: "32px", height: "32px" }}
                >
                  {stepData?.stageDetail?.state === "COMPLETED" &&
                  nextData?.stageDetail?.state !== "COMPLETED" ? (
                    <>
                    {
                      (nextData?.stageDetail?.state === "DISABLED" && lastData?.stageDetail?.state === "COMPLETED" ) ?
                      <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <circle
                          cx="8.21777"
                          cy="8"
                          r="7"
                          fill={colors(
                            stepData?.stageDetail?.state,
                            lastData?.stageDetail?.state
                          )}
                          stroke="white"
                          strokeWidth="2"
                        />
                      </svg>
                      </>
                      :
                      <>
                        <div style={{ top: -2 }} className="position-relative ">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="32"
                            viewBox="0 0 33 32"
                            fill="none"
                          >
                            <circle
                              cx="16.7178"
                              cy="16"
                              r="14"
                              fill={colors(
                                stepData?.stageDetail?.state,
                                lastData?.stageDetail?.state
                              )}
                              stroke="white"
                              stroke-width="4"
                            />
                          </svg>
                        </div>
                      </>
                    }
                    
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                      >
                        <circle
                          cx="8.21777"
                          cy="8"
                          r="7"
                          fill={colors(
                            stepData?.stageDetail?.state,
                            lastData?.stageDetail?.state
                          )}
                          stroke="white"
                          strokeWidth="2"
                        />
                      </svg>
                    </>
                  )}
                </div>
              
              </>
            }
            <>
              {index != 4 ? (
                <div
                  className=" s12"
                  style={{
                    width: "108px",
                    height: "4px",
                    background: colors(
                      nextData?.stageDetail?.state,
                      lastData?.stageDetail?.state
                    ),
                  }}
                ></div>
              ) : (
                <div
                  className="blueBg s12 invisible"
                  style={{ width: "108px", height: "4px" }}
                ></div>
              )}
            </>
          </div>
        </div>
    );
  }
