import { FormattedMessage } from "react-intl";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import { SkeletonAccountBalanceEthic } from "./AccountBalance";
import checkTogglerJustCoins from "../../../common/Share/checkTogglerJustCoins";

export default function AccountBalanceBigger({loading, goTo, renderBalance, periodReducer }) {
  
    return(
        <div className="d-flex flex-column">
        <p className="d-flex museo-sans-500 s20">
          <FormattedMessage id="balance.salary.title" />
        </p>
        <div className="f9GrayBorder position-relative p-4 justify-content-evenly mt-3 d-flex flex-column account--card-shadow bg-white rounded-account-cards" style={{ height: checkTogglerJustCoins() ? "278px" : "187px" }}>
          {loading ? (
            <SkeletonAccountBalanceEthic />
          ) : (
            <>
              {periodReducer.data && (
                <>
                  {renderBalance("current", periodReducer.data.currentAccount, periodReducer.data.currentAccountStatus)}
                  {checkTogglerJustCoins() && (
                    <>
                      <hr />
                      {renderBalance("justCoins", periodReducer.data.justCoins, periodReducer.data.justCoinsStatus)}
                    </>
                  )}
                  {ShareCheckEnvKey("STATUS_ACCOUNT") && (
                    <div>
                      <button onClick={goTo} className="d-flex s14 justify-content-center museo-sans-700 skyColor bg-transparent skyBorder rounded d-flex justify-content-center align-items-center" style={{ width: "224px", height: "40px", marginTop: "24px" }}>
                        <FormattedMessage id="account.balance.button.message" />
                      </button>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    )
}
