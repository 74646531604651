export const multiLangJSONParse = () => {
  const countryJsonMap = {
      ar: require("../../common/lang/es-AR.json"),
      pa: require("../../common/lang/es-PA.json"),
      mx: require("../../common/lang/es-MX.json"),
      cl: require("../../common/lang/es-CL.json"),
      cr: require("../../common/lang/es-CR.json"),
      pe: require("../../common/lang/es-PE.json"),
      uy: require("../../common/lang/es-UY.json"),
      co: require("../../common/lang/es-CO.json"),
  };

  const country = localStorage.getItem("country") || "mx";
  return countryJsonMap[country.toLowerCase()] || countryJsonMap["mx"];
};