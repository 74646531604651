import React from "react";

import { ReactComponent as FacebookIcon } from "../../../common/svgs/ic-facebook-md.svg";
import { ReactComponent as InstagramIcon } from "../../../common/svgs/ic-instagram-md.svg";
import { ReactComponent as WhatsappIcon } from "../../../common/svgs/ic-whatsapp-md.svg";
import { ReactComponent as EmailIcon } from "../../../common/svgs/ic-mail-md.svg";
import { ReactComponent as MessengerIcon } from "../../../common/svgs/ic-messenger-md.svg";
import { ReactComponent as VirtualStoreIcon } from "../../../common/svgs/ic-tienda-virtual-md.svg";

export default function PerformanceSocialIcons({
  socialArray,
}) {
  return <MapSocialNetwork socialArray={socialArray} />;
}

export function MapSocialNetwork({ socialArray }) {
  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };
  
  return (
    <>
      {configExist("whatsapp") !== -1 && (
        <a
          href={`https://wa.me/${socialArray[configExist("whatsapp")]?.value}`}
          target="_blank"
        >
          <WhatsappIcon />
        </a>
      )}
      {configExist("facebook") !== -1 && (
        <a href={`${socialArray[configExist("facebook")]?.value}`} target="_blank">
          <FacebookIcon />
          {
            //eliademorales
          }
        </a>
      )}
      {configExist("instagram") !== -1 && (
        <a href={socialArray[configExist("instagram")]?.value} target="_blank">
          <InstagramIcon />
          {
            // www.instagram.com/eliajust
          }
        </a>
      )}
      {configExist("messenger") !== -1 && (
        <a
          href={`https://m.me/${socialArray[configExist("messenger")]?.value}`}
          target="_blank"
        >
          <MessengerIcon />
        </a>
      )}
      {configExist("email") !== -1 && (
        <>
          {
            socialArray[configExist("email")]?.value == "" ?
            <>
            </>
            :
            <a href={`mailto:${socialArray[configExist("email")]?.value}`} target="_blank">
              <EmailIcon />
            </a>
            
          }
        </>
      )}
      {configExist("tienda_virtual") !== -1 && (
        <a href={socialArray[configExist("tienda_virtual")]?.value} target="_blank">
          <VirtualStoreIcon />
        </a>
      )}
    </>
  );
}
