import bannerOne from "../banner/1440_X_124_-_Consultora.jpg";
import bannerTwo from "../banner/1440 X 124 - Lider de grupo.jpg";
import bannerThree from "../banner/1440_X_124_-_Directora_senior.jpg";

export default function AccountBanner({ profileData, loading }) { 
  
  const bannerChoice = (level) => {
    const banners = {
      "0": bannerOne,
      "1": bannerOne,
      "2": bannerTwo,
      "3": bannerTwo,
      "4": bannerTwo,
    };
    
    return (
      <img
        style={{ height: "124px", width: "100%", overflow: "hidden" }}
        src={banners[level] || bannerThree}
      />
    );
  };

  return (
    <div className="" >
      {
        loading ?
            <div className=" w-100 " style={{height: "124px", maxWidth: "inherit", overflow: "none", background: "linear-gradient(180deg, #DDDDDD 0%, #85848E 100%) 0% 0% no-repeat padding-box" }} >

         </div>
        :
          <div
          className="w-100"
          style={{ height: "124px", maxWidth: "inherit", overflow: "none" }}
          >
            {bannerChoice(profileData.level)}
        </div>
      }
      
    </div>
  );
}
