import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { removeXAmountArray } from "../../../../Descendants/Framework/redux/DescendantsAction";
import HelpIcon from "../../../Share/HelpIcon";
import HelpIconNoTarget from "../../../Share/HelpIconNoTarget";
import { ReactComponent as SVGChevronRight } from "../../../svgs/c-chevron-right-sm.svg";

export default function HeaderTracker() {
  const history = useNavigate();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  let auxAscBreadcrumb = 0;
  let auxAscArray = 0;

  const [toggler, setToggler] = useState(false);

  const edbTitle = useSelector((state) => state.edbDetailsData.data);
  const otherConsultantData = useSelector(
    (state) => state.performanceData.otherConsultantData
  );
  const groupName = useSelector(
    (state) => state.descendantData.dataPersonalGroupName
  );
  const arrayAsc = useSelector((state) => state.descendantData.dataArrayAsc);
  const profileData = useSelector((state) => state.userInfo);
  const clientId = localStorage.getItem("clientId");
  const profileDescData = useSelector(
    (state) => state.descendantData.descendantProfilaData
  );
  const clientDetails = useSelector(
    (state) => state.clientListData.clientDetails
  );

  let edbFormRouteType = localStorage.getItem("formBodyType");

  let tracker = 0;

  if (location?.pathname?.indexOf("/dashboard/earning/period") !== -1) {
    tracker = 1;
  }
  if (location?.pathname?.indexOf("/dashboard/profile") !== -1) {
    tracker = 2;
  }
  if (location?.pathname?.indexOf("/dashboard/ratings") !== -1) {
    tracker = 3;
  }
  if (location?.pathname?.indexOf("/dashboard/calendar") !== -1) {
    tracker = 4;
  }
  if (location?.pathname?.indexOf("/dashboard/goodpractice") !== -1) {
    tracker = 5;
  }
  if (location?.pathname?.indexOf("/dashboard/edbs") !== -1) {
    tracker = 6;
  }
  if (location?.pathname?.indexOf("/dashboard/edbs/details") !== -1) {
    tracker = 7;
    if (location?.pathname?.indexOf("/invitation") !== -1) {
      tracker = 32;
    }
  }
  if (location?.pathname?.indexOf("/dashboard/edbs/list") !== -1) {
    tracker = 10;
  }
  if (
    location?.pathname?.indexOf(
      "/dashboard/edbs/list/" + params.state + "/"
    ) !== -1
  ) {
    tracker = 18;
  }
  if (location?.pathname?.indexOf("/dashboard/businessplan") !== -1) {
    tracker = 8;
  }
  if (location?.pathname?.indexOf("/dashboard/account") !== -1) {
    tracker = 9;
  }
  if (location?.pathname?.indexOf("/dashboard/performance") !== -1) {
    tracker = 11;
  }
  if (location?.pathname?.indexOf("/detailsSales") !== -1) {
    tracker = 12;
  }

  if (location?.pathname?.indexOf("/descendants/personal-group") !== -1) {
    tracker = 13;
  }
  if (location?.pathname?.indexOf("/descendants/dashboard") !== -1) {
    tracker = 14;
  }

  if (location?.pathname?.indexOf("/descendants/profile") !== -1) {
    tracker = 16;
  }
  if (location?.pathname?.indexOf("/descendants/profile/asc") !== -1) {
    tracker = 17;
  }

  if (location?.pathname?.indexOf("/dashboard/otherUserSales") !== -1) {
    tracker = 15;
  }

  if (location?.pathname?.indexOf("/dashboard/weeklydeals") !== -1) {
    tracker = 19;
  }

  if (location?.pathname?.indexOf("/dashboard/firstSteps") !== -1) {
    tracker = 20;
  }
  if (location?.pathname?.indexOf("/dashboard/account/status") !== -1) {
    tracker = 21;
  }
  if (location?.pathname?.indexOf("/dashboard/account/status/details") !== -1) {
    tracker = 22;
  }
  if (location?.pathname?.indexOf("/dashboard/clientlist") !== -1) {
    tracker = 23;
  }
  if (location?.pathname?.indexOf("/dashboard/clientlist/details/") !== -1) {
    tracker = 24;
  }
  if (location?.pathname?.indexOf("/dashboard/clientlist/form/add") !== -1) {
    tracker = 25;
  }
  if (location?.pathname?.indexOf("/dashboard/clientlist/form/update") !== -1) {
    tracker = 26;
  }
  if (location?.pathname?.indexOf("/dashboard/invitations/list") !== -1) {
    tracker = 27;
  }
  if (location?.pathname?.indexOf("/dashboard/invitations/add") !== -1) {
    tracker = 28;
  }
  if (location?.pathname?.indexOf("/dashboard/edbs/form/") !== -1) {
    tracker = 29;
  }
  if (location?.pathname?.indexOf("/dashboard/edbs/form/client") !== -1) {
    tracker = 30;
  }
  if (location?.pathname?.indexOf("/dashboard/edbs/form/client/list") !== -1) {
    tracker = 31;
  }
  if (
    location?.pathname?.indexOf("/dashboard/edbs/form/type/virtual/") !== -1
  ) {
    tracker = 33;
  }
  if (
    location?.pathname?.indexOf("/dashboard/edbs/form/type/present/") !== -1
  ) {
    tracker = 34;
  }
  if (location?.pathname?.indexOf("invitee/details/") !== -1) {
    tracker = 35;
  }
  if (
    location?.pathname?.indexOf("/client/list") !== -1 &&
    location?.pathname?.indexOf("/dashboard/edbs/details/") !== -1
  ) {
    tracker = 36;
  }
  if (location?.pathname?.indexOf("/dashboard/goodpractice/bonds") !== -1) {
    tracker = 37;
  }

  function onAscHeader(index, path) {
    dispatch(removeXAmountArray(index));
    onBreadcrumb(path);
  }

  function onBreadcrumb(path) {
    navigate(path);
  }

  function manageArray() {
    const auxArray = [];
    for (let index = 0; index < arrayAsc.length - 1; index++) {
      auxArray.push(arrayAsc[index]);
    }
    return auxArray;
  };

  // console.log("Header Tracker: ", tracker);

  function trackingRoute(param) {
    switch (param) {
      case 37:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/goodpractice")}
              label="good.practice"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              <FormattedMessage id="descendants.personal.group.tracker.yours" />
            </p>
          </>
        );
      case 36:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <div
              onClick={() => onBreadcrumb(-1)}
              className=" pointer museo-sans-500"
            >
              {edbTitle?.title}
            </div>
            <BreadcrumbSeparator />
            <p className=" museo-sans-700 pointer s16">
              <FormattedMessage id="client.list.header.title" />
            </p>{" "}
          </>
        );
      case 35:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="good.practice"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <div
              onClick={() => onBreadcrumb(-1)}
              className=" pointer museo-sans-500"
            >
              {edbTitle?.title}
            </div>
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              {clientDetails?.data?.firstName} {clientDetails?.data?.lastName}
            </p>
          </>
        );
      case 1:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard")}
              label="nav.label.one.index"
            />
            <BreadcrumbSeparator />
            <FormattedMessage
              id="dashboard.prev.earning.modal.info.title"
              defaultMessage="Tus Ganancias de Períodos Anteriores"
            />
            <HelpIconNoTarget />
          </>
        );
      case 2:
        return (
          <>
            <FormattedMessage id="my.profile" />
            <HelpIconNoTarget />
          </>
        );
      case 3:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard")}
              label="nav.label.one.index"
            />
            <BreadcrumbSeparator />
            <FormattedMessage id="dashboard.score" />
          </>
        );
      case 4:
        return <FormattedMessage id="nav.label.seven.index" />;
      case 5:
        return (
          <>
            <FormattedMessage
              id="good.practice"
              defaultMessage="Buenas Practicas"
            />
            <HelpIcon size="sm" />
          </>
        );
      case 6:
        return (
          <>
            <FormattedMessage
              id="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <HelpIcon size="sm" />
          </>
        );
      case 7:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            {edbTitle?.title}
          </>
        );
      case 32:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title"
              defaultMessage="encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <div
              onClick={() => onBreadcrumb(-1)}
              className=" pointer museo-sans-500"
            >
              {edbTitle?.title}
            </div>
            <BreadcrumbSeparator />
            <p className=" text-capitalize s16 museo-sans-700">
              <FormattedMessage id="invite.assitant" />
            </p>
          </>
        );
      case 8:
        return (
          <>
            <FormattedMessage id="business.plan" />
            <HelpIcon size="sm" />
          </>
        );
      case 9:
        return <FormattedMessage id="nav.label.five.index" />;
      case 10:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard/edbs")}
              label="edb.header.title.two"
              defaultMessage="Tus encuentros de bienestar"
            />
            <BreadcrumbSeparator />
            <FormattedMessage id="all.encounters" />
          </>
        );
      case 11:
        return (
          <>
            <BreadcrumbLink
              onClick={() => onBreadcrumb("/dashboard")}
              label="nav.label.one.index"
            />
            <BreadcrumbSeparator />
            {profileData.userInfo.level > 1 ? (
              <FormattedMessage id="dashboard.modal.qualification.title" />
            ) : (
              <FormattedMessage id="settings.notifications.keyManteinance.two" />
            )}
          </>
        );
      case 12:
        return (
          <h5 className=" museo-sans-700 m-0  s16">
            {" "}
            <span
              onClick={() => onBreadcrumb("/dashboard")}
              className=" pointer museo-sans-500  pe-2"
            >
              <FormattedMessage
                id="nav.label.one.index"
                defaultMessage={"Desempeño"}
              />
            </span>
            <BreadcrumbSeparator />
            <FormattedMessage id="request.detail" />{" "}
          </h5>
        );
      case 15:
        return (
          <h5 className=" museo-sans-700 m-0  s16">
            {" "}
            <BreadcrumbLink
              onClick={() => onBreadcrumb( profileData.userInfo.level > 1 ? "/dashboard/descendants/dashboard" : `/dashboard/descendants/personal-group/${clientId}` )}
              label="descendants.header.title"
              defaultMessage="encuentros de bienestar"
            />
            {
               profileData.userInfo.level > 1 &&
               <>
               <BreadcrumbSeparator />
               <BreadcrumbLink
                 onClick={() =>
                   onBreadcrumb(
                     `/dashboard/descendants/personal-group/${clientId}`
                   )
                 }
                 label="descendants.personal.group.tracker.yours"
               />
               
               </>
            }
            <BreadcrumbSeparator />
            {otherConsultantData?.firstName
              ? `Venta de ${otherConsultantData?.firstName}`
              : null}
          </h5>
        );
      case 13:
        return (
          <>
            {profileData.userInfo.level < 2 ? (
              <div style={{ alignItems: "center" }} className=" d-flex ">
                <h5 className=" museo-sans-700 m-0 s16">
                  {" "}
                  <span className=" d-flex gap-2">
                    {" "}
                    <p className=" s16">
                      <FormattedMessage
                        id="descendants.header.title"
                        defaultMessage="Tu Descendencia"
                      />
                    </p>{" "}
                    <HelpIcon size={"sm"} />
                  </span>{" "}
                </h5>
              </div>
            ) : (
              <div style={{ alignItems: "center" }} className=" d-flex ">
                <h5 className=" museo-sans-500 m-0 s16">
                  {" "}
                  <span className=" d-flex gap-2">
                    {" "}
                    <p
                      className="pointer s16"
                      onClick={() =>
                        onBreadcrumb("/dashboard/descendants/dashboard")
                      }
                    >
                      <FormattedMessage
                        id="descendants.header.title"
                        defaultMessage={"descendencia"}
                      />
                    </p>{" "}
                    <HelpIcon size={"sm"} />
                    <BreadcrumbSeparator />
                    {params.root == clientId ? (
                      <p className=" museo-sans-700 ">
                        <FormattedMessage id="descendants.personal.group.tracker.yours" />
                      </p>
                    ) : (
                      <p className=" museo-sans-700 ">
                        <FormattedMessage id="descendants.personal.group.tracker" />{" "}
                        {groupName?.name}
                      </p>
                    )}
                  </span>{" "}
                </h5>
              </div>
            )}
          </>
        );
      case 14:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex ">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() =>
                    onBreadcrumb("/dashboard/descendants/dashboard")
                  }
                >
                  {profileData?.userInfo?.level > 1 ? (
                    <FormattedMessage
                      id="descendants.header.title"
                      defaultMessage="Tu Descendencia"
                    />
                  ) : (
                    <FormattedMessage
                      id="descendants.header.title.two"
                      defaultMessage="Tu Descendencia"
                    />
                  )}
                </p>{" "}
                <HelpIcon size={"sm"} />
              </span>{" "}
            </h5>
          </div>
        );
      case 16:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex ">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex">
                {" "}
                <BreadcrumbLink
                  onClick={() =>
                    onBreadcrumb( profileData.userInfo.level > 1 ? "/dashboard/descendants/dashboard" :  `/dashboard/descendants/personal-group/${clientId}` )
                  }
                  label="descendants.header.title"
                  defaultMessage="encuentros de bienestar"
                />
                <BreadcrumbSeparator />
                {profileData.userInfo.level > 1 && (
                  <>
                    <BreadcrumbLink
                      onClick={() =>
                        onBreadcrumb(
                          `/dashboard/descendants/personal-group/${clientId}`
                        )
                      }
                      label="descendants.personal.group.tracker.yours"
                      defaultMessage="encuentros de bienestar"
                    />
                    <BreadcrumbSeparator />
                  </>
                )}
                <p className=" museo-sans-700 ">
                  <>
                    {groupName?.name == "" ? (
                      <>{profileDescData?.name}</>
                    ) : (
                      <>{groupName?.name}</>
                    )}
                  </>
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 17:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex ">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex">
                {" "}
                <BreadcrumbLink
                  onClick={() =>
                    onBreadcrumb( profileData.userInfo.level > 1 ? "/dashboard/descendants/dashboard" :  `/dashboard/descendants/personal-group/${clientId}` )
                  }
                  label="descendants.header.title"
                  defaultMessage="encuentros de bienestar"
                />
                <BreadcrumbSeparator />
                {arrayAsc.length < 2 && (
                  <>
                    {profileData.userInfo.level > 2 && (
                  <>
                    <BreadcrumbLink
                      onClick={() =>
                        onBreadcrumb(
                          `/dashboard/descendants/personal-group/${clientId}`
                        )
                      }
                      label="descendants.personal.group.tracker.yours"
                      defaultMessage="encuentros de bienestar"
                    />
                    <BreadcrumbSeparator />
                  </>
                )}
                    <p
                      className=" pointer"
                      onClick={() =>
                        onBreadcrumb(
                          `/dashboard/descendants/profile/${groupName?.clientId}`
                        )
                      }
                    >
                      {groupName?.name}
                    </p>
                  </>
                )}
                {arrayAsc.length > 1 && (
                  <div className=" position-relative ">
                    {toggler && (
                      <div
                        style={{ width: "288px", left: "0px", top: "22px" }}
                        className=" position-absolute bg-white museo-sans-500 s14 rounded-2  "
                      >
                        {
                          profileData.userInfo.level > 1 &&
                          <>
                          <p
                            onClick={() =>
                              onBreadcrumb(
                                `/dashboard/descendants/personal-group/${clientId}`
                              )
                            }
                            className=" pointer px-3 my-1 py-2 d-flex align-items-center"
                            style={{ height: "40px" }}
                          >
                            <FormattedMessage id="descendants.personal.group.tracker.yours" />{" "}
                          </p>
                          <hr className=" m-0 p-0" />
                          
                          </>
                        }
                        <p
                          onClick={() =>
                            onBreadcrumb(
                              `/dashboard/descendants/profile/${groupName.clientId}`
                            )
                          }
                          className=" pointer px-3 my-1 py-2 d-flex align-items-center"
                          style={{ height: "40px" }}
                        >
                          {groupName?.name}
                        </p>

                        {manageArray().map((elm) => {
                          auxAscArray++;
                          auxAscBreadcrumb--;
                          return (
                            <>
                              <hr className=" m-0 p-0" />
                              <p
                                onClick={() =>
                                  onAscHeader(auxAscArray, auxAscBreadcrumb)
                                }
                                className=" pointer px-3 my-1 py-2 d-flex align-items-center"
                                style={{ height: "40px" }}
                              >
                                {elm.name}
                              </p>
                            </>
                          );
                        })}
                      </div>
                    )}
                    <p
                      onClick={() => setToggler(!toggler)}
                      className=" pointer skyColor"
                    >
                      ...
                    </p>
                  </div>
                )}
                <BreadcrumbSeparator />
                {arrayAsc.length < 1 ? (
                  <p
                    onClick={() =>
                      onBreadcrumb(`/dashboard/descendants/dashboard`)
                    }
                  >
                    a
                  </p>
                ) : (
                  <p className=" museo-sans-700 pointer">
                    {arrayAsc[arrayAsc.length - 1].name}
                  </p>
                )}
              </span>{" "}
            </h5>
          </div>
        );
      case 18:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title"
                    defaultMessage={"encuentros de bienestar"}
                  />
                </p>{" "}
                <HelpIcon size={"sm"} />
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <span
              onClick={() =>
                onBreadcrumb("/dashboard/edbs/list/" + params.state)
              }
              className=" pointer svg-stroke-gray museo-sans-500 pe-2 "
            >
              {params.state == "closed" ? (
                <FormattedMessage id="edb.list.table.col.closed" />
              ) : (
                <>
                  {params.state == "dated" ? (
                    <FormattedMessage id="edb.list.table.col.dated" />
                  ) : (
                    <FormattedMessage id="edb.list.table.col.openned" />
                  )}
                </>
              )}
            </span>{" "}
            <BreadcrumbSeparator />
            <span className="  museo-sans-700 s16">
              {edbTitle && edbTitle.title}
            </span>
          </div>
        );

      case 19:
        return (
          <h5 className=" museo-sans-700 m-0 s16">
            {" "}
            <FormattedMessage
              id="dashboard.weekly.deals"
              defaultMessage={"Tus Ganancias de Períodos Anteriores"}
            />{" "}
            <i
              className=" pointer "
              data-bs-toggle="modal"
              data-bs-target="#modalInfo"
            ></i>{" "}
          </h5>
        );
      case 20:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-500 s16">
                  {profileData.userInfo.level < 2 ? (
                    <FormattedMessage
                      id="dashboard.steps.1"
                      defaultMessage={"encuentros de bienestar"}
                    />
                  ) : (
                    <FormattedMessage
                      id="dashboard.steps"
                      defaultMessage={"encuentros de bienestar"}
                    />
                  )}
                </p>{" "}
                <div
                  data-bs-toggle="modal"
                  data-bs-target="#modalFirstOne"
                  className=" pointer d-flex aling-items-center justify-content-center"
                >
                  <HelpIconNoTarget size={"sm"} />
                </div>
              </span>{" "}
            </h5>
          </div>
        );
      case 21:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/account")}
                >
                  <FormattedMessage
                    id="nav.label.five.index"
                    defaultMessage={"encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <p
              onClick={() => onBreadcrumb("/dashboard/account/status")}
              className=" museo-sans-500 pointer s16"
            >
              <FormattedMessage
                id="nav.label.thirty.index"
                defaultMessage={"Tus Ganancias de Períodos Anteriores"}
              />
            </p>
          </div>
        );
      case 22:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/account")}
                >
                  <FormattedMessage
                    id="nav.label.five.index"
                    defaultMessage={"encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <span className=" svg-stroke-gray museo-sans-500 pe-2 ">
              <SVGChevronRight />{" "}
            </span>{" "}
            <p
              onClick={() => onBreadcrumb("/dashboard/account/status")}
              className=" museo-sans-500 pointer s16"
            >
              <FormattedMessage
                id="nav.label.thirty.index"
                defaultMessage={"Tus Ganancias de Períodos Anteriores"}
              />
            </p>
            <BreadcrumbSeparator />
            <p className=" museo-sans-500 pointer s16">
              <FormattedMessage
                id="nav.label.fourty.index"
                defaultMessage={"Tus Ganancias de Períodos Anteriores"}
              />
            </p>
          </div>
        );
      case 23:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-700 s16">
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
              </span>{" "}
            </h5>
          </div>
        );
      case 24:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/clientlist")}
                >
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
                <BreadcrumbSeparator />
                <p className=" museo-sans-700 pointer s16">
                  {clientDetails?.data?.firstName}{" "}
                  {clientDetails?.data?.lastName}
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 25:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/clientlist")}
                >
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
                <BreadcrumbSeparator />
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.new.client" />
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 26:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p
                  className=" museo-sans-500 pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/clientlist")}
                >
                  <FormattedMessage id="client.list.header.title" />
                </p>{" "}
                <BreadcrumbSeparator />
                <p
                  onClick={() =>
                    onBreadcrumb(
                      `/dashboard/clientlist/details/${clientDetails?.data?.contactId}`
                    )
                  }
                  className=" museo-sans-500 pointer s16"
                >
                  {clientDetails?.data?.firstName}{" "}
                  {clientDetails?.data?.lastName}
                </p>
                <BreadcrumbSeparator />
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.edit.personal.data" />
                </p>
              </span>{" "}
            </h5>
          </div>
        );
      case 27:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.invitations" />
                </p>{" "}
              </span>{" "}
            </h5>
          </div>
        );
      case 28:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2 ">
            <h5 className=" m-0  s16">
              {" "}
              <span className=" d-flex gap-2  ">
                {" "}
                <p className=" museo-sans-700 pointer s16">
                  <FormattedMessage id="other.header.tracker.invitations.new" />
                </p>{" "}
              </span>{" "}
            </h5>
          </div>
        );
      case 29:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title"
                    defaultMessage={"Tus encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="edb.form.header.tracker.new.edb" />
            </span>
          </div>
        );
      case 30:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title.two"
                    defaultMessage={"Tus encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <BreadcrumbSeparator />
            <span
              onClick={() =>
                onBreadcrumb(`/dashboard/edbs/form/type/${edbFormRouteType}`)
              }
              className=" pointer museo-sans-500 s16"
            >
              <FormattedMessage id="edb.form.header.tracker.new.edb" />
            </span>
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="edb.form.header.tracker.new.client" />
            </span>
          </div>
        );
      case 31:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex  gap-2">
            <h5 className=" museo-sans-500 m-0 s16">
              {" "}
              <span className=" d-flex gap-2">
                {" "}
                <p
                  className="pointer s16"
                  onClick={() => onBreadcrumb("/dashboard/edbs")}
                >
                  <FormattedMessage
                    id="edb.header.title.two"
                    defaultMessage={"Tus encuentros de bienestar"}
                  />
                </p>{" "}
              </span>{" "}
            </h5>
            <span className=" svg-stroke-gray museo-sans-500">
              <SVGChevronRight />
            </span>
            <span
              onClick={() =>
                onBreadcrumb(`/dashboard/edbs/form/type/${edbFormRouteType}`)
              }
              className=" pointer museo-sans-500 s16"
            >
              <FormattedMessage id="edb.form.header.tracker.new.edb" />
            </span>
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="edb.form.header.tracker.search.host" />
            </span>
          </div>
        );
      case 33:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <BreadcrumbLink
              defaultMessage={"any"}
              label={"edb.header.title"}
              onClick={() => onBreadcrumb("/dashboard/edbs")}
            />
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="header.tracker.edb.form.update.virtual" />
            </span>
          </div>
        );
      case 34:
        return (
          <div style={{ alignItems: "center" }} className=" d-flex gap-2">
            <BreadcrumbLink
              defaultMessage={"any"}
              label={"edb.header.title"}
              onClick={() => onBreadcrumb("/dashboard/edbs")}
            />
            <BreadcrumbSeparator />
            <span className=" museo-sans-700 s16">
              <FormattedMessage id="header.tracker.edb.form.update.present" />
            </span>
          </div>
        );
      default:
        return (
          <h5 className="">
            {" "}
            <span className="s16" style={{ color: "gray" }}>
              No existe
            </span>{" "}
          </h5>
        );
    }
  }

  const BreadcrumbLink = ({ onClick, label, defaultMessage }) => (
    <span onClick={onClick} className="pointer museo-sans-500 pe-2">
      <FormattedMessage id={label} defaultMessage={defaultMessage} />
    </span>
  );

  const BreadcrumbSeparator = () => (
    <span className="svg-stroke-gray museo-sans-500 pe-2">
      <SVGChevronRight />
    </span>
  );

  return (
    <h5 className="museo-sans-700 m-0 s16 d-flex gap-2">
      {trackingRoute(tracker)}
    </h5>
  );
}
