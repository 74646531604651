import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

export default function InvitationsDashboardModalInfo() {
  return (
    <>
      <div
        className="modal fade"
        id="modal-invitations-info"
        aria-hidden="true"
        aria-labelledby="modal-invitations-info"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "592px" }}
        >
          <div className="modal-content modal-radius">
            <div className="modal-body p-0">
              <div>
                <div
                  style={{ marginLeft: "42px", marginRight: "42px" }}
                  className="d-flex justify-content-between pt-4 my-4 "
                >
                  <div className="d-flex align-items-center gap-2">
                    <h5 className="museo-sans-700 s20 m-0">
                      <FormattedMessage id="invitation.navbar.your.invitation" />
                    </h5>
                    <div style={{top: -2}} className=" position-relative" >
                        <HelpIconNoTarget />
                    </div>
                  </div>
                  <div
                    className="pointer position-relative svg-force-size "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ width: "24px", height: "24px", top: -2 }}
                  >
                    <SVGClose />
                  </div>
                </div>
                <hr className="m-0"></hr>
                <div
                  style={{ marginLeft: "42px", marginRight: "42px" }}
                  className="d-flex flex-column gap-2 align-items-center s16 museo-sans-500  "
                >
                  <div className=" d-flex flex-column gap-2 mt-3 pt-1 w-100 museo-sans-500 ">
                    <p className=" ">
                    <FormattedMessage id="invitation.modal.info.message.one" />
                    </p>
                    <p>
                    <FormattedMessage id="invitation.modal.info.message.two" />
                    </p>
                    <div className="mt-4 d-flex justify-content-center">
                      <button
                        type="button"
                        className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <FormattedMessage
                          id="buttons.understood"
                          defaultMessage="Entendido"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
