import PropTypes from 'prop-types';
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ReactComponent as SVGMail } from "../../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGInstagram } from "../../../../common/svgs/ic-instagram-md.svg";
import { ReactComponent as SVGFacebook } from "../../../../common/svgs/ic-facebook-md.svg";
import { ReactComponent as SVGCopy } from "../../../../common/svgs/ic-copy-md.svg";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import { multiLangJSONParse } from "../../../../common/Share/MultiLangJSONParse";


export default function PersonalSalesModalShareOrderEDB({
  closeSubModal,
  open,
  socialArray,
  data,
}) {

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const handleSwissDate = (date) => {
    let longStringArray = date.split("T");
    let longStringDate = longStringArray[0];
    let shotStringArray = longStringDate.split("-");
    let year = shotStringArray[0];
    let month = shotStringArray[1];
    let day = shotStringArray[2];

    return `${day}-${month}-${year.slice(2, 4)}`;
  };

  const sharedDataPhoneText =
    data &&
    `${multiLangJSONParse()["share.message.personal.sale.phone.parse.one"]}${
      data.edbId
    }%0A${handleSwissDate(data.date)}
    ${multiLangJSONParse()["share.message.personal.sale.phone.parse.two"]}${
      data?.totalProducts
    }${
      multiLangJSONParse()["share.message.personal.sale.phone.parse.three"]
    }${formatAmount(
      data?.totalAmount,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    )}`;

  const sharedData =
    data &&
    `${multiLangJSONParse()["share.message.personal.sale.web.parse.one"]}${
      data.edbId
    }\n${handleSwissDate(data.date)}\n${
      multiLangJSONParse()["share.message.personal.sale.web.parse.two"]
    }${data?.totalProducts}\n${
      multiLangJSONParse()["share.message.personal.sale.web.parse.three"]
    }${formatAmount(
      data?.totalAmount,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    )}`;

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(sharedData);
      open();
    } catch (error) {
      unsecuredCopyToClipboard(sharedData);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <>
      {socialArray && (
        <div
          style={{ zIndex: "20" }}
          className=" d-flex justify-content-center align-items-center h-100 position-absolute w-100 rounded-3"
        >
          <div
            style={{ zIndex: "15" }}
            className=" w-100 bg-white rounded-3 position-relative "
          >
            <div className="d-flex align-items-center justify-content-between p-5">
              <div className="d-flex">
                <h5 className="museo-sans-500 s20 m-0">
                  <FormattedMessage id="social.sharedata.title" />
                </h5>
              </div>
              <div onClick={closeSubModal} className=" pointer ">
                <SVGClose />
              </div>
            </div>
            <hr className="m-0"></hr>
            <div className=" p-4 d-flex  mt-0 gap-3">
              {configExist("email") !== -1 && (
                <a
                  href={`mailto:${
                    socialArray[configExist("email")].value
                  }?&body=${encodeURIComponent(sharedData)}`}
                  
                  target="_blank"
                  className="ms-2 bronceStroke grayColor cancelBootstrap"
                >
                  <div
                    style={{ alignItems: "center" }}
                    className=" pointer d-flex flex-column justify-content-center"
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        alignItems: "center",
                      }}
                      className=" bubble-social rounded-circle d-flex justify-content-center"
                    >
                      <SVGMail />
                    </div>
                    <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                      <FormattedMessage id="social.sharedata.link.mail" />
                    </p>
                  </div>
                </a>
              )}
              {configExist("whatsapp") !== -1 && (
                <a
                  href={`https://wa.me/?text=${sharedDataPhoneText}`}
                  target="_blank"
                  className="ms-2 bronceStroke grayColor cancelBootstrap"
                >
                  <div
                    style={{ alignItems: "center" }}
                    className=" pointer d-flex flex-column justify-content-center"
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        alignItems: "center",
                      }}
                      className=" bubble-social rounded-circle d-flex justify-content-center"
                    >
                      <SVGWhatsap />
                    </div>
                    <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                      <FormattedMessage id="social.sharedata.link.wap" />
                    </p>
                  </div>
                </a>
              )}
              {configExist("messenger") !== -1 && (
                <a
                  href={`https://m.me/?text=${sharedDataPhoneText}`}
                  target="_blank"
                  className="ms-2 bronceStroke bronceFill grayColor cancelBootstrap"
                >
                  <div
                    style={{ alignItems: "center" }}
                    className=" pointer d-flex flex-column justify-content-center"
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        alignItems: "center",
                      }}
                      className=" bubble-social rounded-circle d-flex justify-content-center"
                    >
                      <SVGMessenger />
                    </div>
                    <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                      <FormattedMessage id="social.sharedata.link.msg" />
                    </p>
                  </div>
                </a>
              )}
              {configExist("facebook") !== -1 && (
                <a
                  href={`${socialArray[configExist("facebook")].value}`}
                  target="_blank"
                  className="ms-2 bronceStroke bronceFill grayColor cancelBootstrap"
                >
                  <div
                    style={{ alignItems: "center" }}
                    className=" pointer d-flex flex-column justify-content-center"
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        alignItems: "center",
                      }}
                      className=" bubble-social rounded-circle d-flex justify-content-center"
                    >
                      <SVGFacebook />
                    </div>
                    <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                      <FormattedMessage id="social.sharedata.link.facebook" />
                    </p>
                  </div>
                </a>
              )}
              {configExist("instagram") !== -1 && (
                <a
                  href={`https://www.instagram.com/${
                    socialArray[configExist("instagram")].value
                  }`}
                  target="_blank"
                  className="ms-2 bronceStroke bronceFill grayColor cancelBootstrap"
                >
                  <div
                    style={{ alignItems: "center" }}
                    className=" pointer d-flex flex-column justify-content-center"
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        alignItems: "center",
                      }}
                      className=" bubble-social rounded-circle d-flex justify-content-center"
                    >
                      <SVGInstagram />
                    </div>
                    <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                      <FormattedMessage id="social.sharedata.link.instagram" />
                    </p>
                  </div>
                </a>
              )}

              <div
                onClick={doThis}
                style={{ alignItems: "center" }}
                className=" grayColor pointer d-flex flex-column justify-content-center"
              >
                <div
                  onClick={closeSubModal}
                  style={{
                    width: "64px",
                    height: "64px",
                    alignItems: "center",
                  }}
                  className=" bubble-social rounded-circle d-flex justify-content-center"
                >
                  <SVGCopy />
                </div>
                <p className=" w-100 museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                  <FormattedMessage id="social.sharedata.link.copydata" />
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}


PersonalSalesModalShareOrderEDB.propTypes = {
  closeSubModal: PropTypes.func.isRequired,
  open: PropTypes.func.isRequired,
  socialArray: PropTypes.array.isRequired,
  data: PropTypes.shape({
    date: PropTypes.string.isRequired,
    edbId: PropTypes.number.isRequired,
    // Add more prop validations as needed
  }).isRequired,
};