import { FormattedMessage } from "react-intl";
import ShareModalShareBody from "../../../../common/Share/ShareModalShareBody";
import ShareModalStructure from "../../../../common/Share/ShareModalStructure";

export default function ClientListDetailsModalAddCard({ open, url, socialArray, phoneNumber }) {

  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    if (auxId == "whatsapp") {
      resolve = phoneNumber?.length > 0 ? phoneNumber[0]?.number : "";
    }
    return resolve;
  };

  const sharedPhoneData = `*¡Hola! En Just tenemos muchos productos de bienestar que creo podrían serte útiles. Como Consultor Just me gustaría asesorarte de manera más personalizada, para poder recomendarte productos y terapias específicas para tus áreas de interés y necesidades.*\n\n*Te invito a que en menos de 2 minutos y de manera muy ágil y rápida, selecciones las tarjetas que más se asocian a tus necesidades:*\n\n${url}`;

  const sharedData = `¡Hola! En Just tenemos muchos productos de bienestar que creo podrían serte útiles. Como Consultor Just me gustaría asesorarte de manera más personalizada, para poder recomendarte productos y terapias específicas para tus áreas de interés y necesidades.\n\nTe invito a que en menos de 2 minutos y de manera muy ágil y rápida, selecciones las tarjetas que más se asocian a tus necesidades:\n\n${url}`;

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(sharedData);
      open();
    } catch (error) {
      unsecuredCopyToClipboard(sharedData);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      open();
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <ShareModalStructure id={"empty-share-link"} maxWidth={"556px"}>
      {socialArray && (
        <ShareModalShareBody
          sharedDataPhoneText={sharedData}
          sharedData={sharedData}
          socialArray={socialArray}
          configExist={configExist}
          doThis={doThis}
          modalTitle={"clients.details.edb.invite.new.cards"}
          phoneHasTarget={configExist("whatsapp")}
          forceEmailShow={true}
          copyText={
            <p
              style={{ width: "100px" }}
              className=" museo-sans-500 s14 mt-2 p-1 d-flex justify-content-center text-center"
            >
              <FormattedMessage
                id={"clients.details.edb.card.share.button.copy"}
              />
            </p>
          }
        />
      )}
    </ShareModalStructure>
  );
}
