import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGPendingFilled } from "../../SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import { ReactComponent as SVGCompletedFilled } from "../../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGCIncompletedFilled } from "../../../../common/svgs/ic-x-circle-bg.svg";
import { ReactComponent as SVGCBloquedFilled } from "../../SVGs/Bloqueado/ic-item-desbloq-sm-filled-yellow.svg";
import { ReactComponent as SVGBloqGrey } from "../../SVGs/Bloqueado/ic-item-bloq-grey.svg";

import imageOne from "../../imgs/posibleImage03.png";
import imageTwo from "../../imgs/posibleImage04.png";
import imageThree from "../../imgs/posibleImage05.png";
import imageFourNew from "../../imgs/possibleImage06.png";
import imageFour from "../../imgs/posibleImage07.png";

export default function FirstStepsModalHelpTwo() {
  const scrollbarRef = useRef(null);
  const profileData = useSelector((state) => state.userInfo.userInfo);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
        <div className="d-flex">
          <HelpIconNoTarget />
          <h5 className="museo-sans-500 px-2 s20 m-0">
            <FormattedMessage id="first.steps.title.two" />
          </h5>
        </div>
        <div className=" pointer " data-bs-dismiss="modal" aria-label="Close">
          <SVGClose />
        </div>
      </div>
      <hr className="m-0"></hr>
      <div
        className="d-flex flex-column justify-content-between"
        style={{ height: "495px" }}
      >
        <div
          ref={scrollbarRef}
          style={{ maxHeight: "385px" }}
          className=" s16 hidden-y-scroll d-flex flex-column align-items-center px-4"
        >
          <div className="mt-4 d-flex flex-column gap-3 museo-sans-500 s16 mx-3 ">
            <p>
              <FormattedMessage id="first.steps.modal.help.second.steps.p.one.one" />
              <span className="museo-sans-700 ">
                <FormattedMessage id="first.steps.modal.help.second.steps.p.one.two" />
              </span>
            </p>
            <p>
              <FormattedMessage id="first.steps.modal.help.second.steps.p.two.one" />
              <span className="museo-sans-700 ">
                <FormattedMessage id="first.steps.title" />
              </span>
              <FormattedMessage id="first.steps.modal.help.second.steps.p.two.two" />
            </p>
            <div className=" d-flex flex-column gap-0">
              <p className=" museo-sans-700">
                <FormattedMessage id="first.steps.modal.help.first.steps.p.four" />
              </p>
              <p>
                <FormattedMessage id="first.steps.modal.help.second.steps.p.three.one" />
              </p>
            </div>
            <div
              style={{ top: -10 }}
              className=" position-relative d-flex justify-content-center px-0 pe-0"
            >
              <img src={imageOne} />
            </div>
            <p className=" museo-sans-700 s16 ">
              <FormattedMessage id="first.steps.view.more.consultants" />
            </p>
            <div>
              <img src={imageTwo} />
            </div>

            <p>
              <FormattedMessage id="first.steps.modal.help.second.steps.p.four.one" />
              <span className="museo-sans-700 text-lowercase ">
                "
                <FormattedMessage id="first.steps.view.more.consultants" />"
              </span>
              <FormattedMessage id="first.steps.modal.help.second.steps.p.four.two" />
            </p>

            <div>
              <img src={imageThree} />
            </div>

            {profileData.level > 1 && (
              <>
                <p>
                  <FormattedMessage id="first.steps.modal.help.second.steps.p.five.one" />
                </p>
                <p className=" museo-sans-700">
                  <FormattedMessage id="first.steps.modal.help.second.steps.p.five.two" />
                </p>
                <div>
                  <img src={imageFourNew} />
                </div>
              </>
            )}

            <p>
              <FormattedMessage id="first.steps.modal.help.second.steps.p.six.one" />
            </p>

            <RowState state={"pendiente"} />
            <RowState state={"alcanzado"} />
            <RowState state={"no alcanzado"} />
            <RowState state={"bloqueado"} />
            <RowState state={"desbloqueado"} />

            <div className=" d-flex flex-column gap-0">
              <p className=" museo-sans-700">
                <FormattedMessage id="first.steps.details.of.progress" />
              </p>
              <p>
                <FormattedMessage id="first.steps.modal.help.second.steps.p.seven.one" />
              </p>
            </div>

            <div>
              <img src={imageFour} />
            </div>

            <div className=" d-flex flex-column gap-0">
              <p className=" museo-sans-700">
                <FormattedMessage id="first.steps.guidelines.program.title" />
              </p>
              <p>
                <FormattedMessage id="first.steps.modal.help.second.steps.p.eight.one" />
              </p>
            </div>
          </div>
        </div>
        <div>
          <ModalWhiteShadow
            left={"5%"}
            width={"90%"}
            bottom={80}
            scrollbarRef={scrollbarRef}
            aidHide={40}
            height={30}
          />
          <div className=" w-100 d-flex justify-content-center mt-3 mb-4 pb-3">
            <button
              type="button"
              className=" btnLoginWidth museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.understood"
                defaultMessage="Entendido"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RowState({ state }) {
  const switchStatus = (state) => {
    switch (state) {
      case "pendiente":
        return <SVGPendingFilled />;

      case "alcanzado":
        return <SVGCompletedFilled />;

      case "no alcanzado":
        return <SVGCIncompletedFilled />;

      case "bloqueado":
        return <SVGBloqGrey />;

      case "desbloqueado":
        return <SVGCBloquedFilled />;
    }
  };

  return (
    <>
      <div className="d-flex museo-sans-500 flex-column gap-2">
        <div className=" mt-1 d-flex gap-2 align-items-center ">
          <div style={{ width: "22px" }} className=" svg-force-size">
            {switchStatus(state)}
          </div>
          <p className=" s16 museo-sans-700 text-uppercase">{state}</p>
        </div>
        <p>
          {state == "pendiente" && (
            <FormattedMessage id="first.steps.modal.help.second.steps.p.nine.seven" />
          )}
          {state == "alcanzado" && (
            <FormattedMessage id="first.steps.modal.help.second.steps.p.nine.six" />
          )}
          {state == "no alcanzado" && (
            <FormattedMessage id="first.steps.modal.help.second.steps.p.nine.five" />
          )}
          {state == "bloqueado" && (
            <FormattedMessage id="first.steps.modal.help.second.steps.p.nine.four" />
          )}
          {state == "desbloqueado" && (
            <FormattedMessage id="first.steps.modal.help.second.steps.p.nine.three" />
          )}
        </p>
        {state == "bloqueado" && (
          <p>
            <FormattedMessage id="first.steps.modal.help.second.steps.p.nine.one" />
          </p>
        )}
        {state == "desbloqueado" && (
          <p>
            <FormattedMessage id="first.steps.modal.help.second.steps.p.nine.two" />
          </p>
        )}
        <hr />
      </div>
    </>
  );
}

export function RowFirstImageTemplate(params) {
  return (
    <>
      <div className=" shadow">
        <p>
          <FormattedMessage id="first.steps.title.two" />
        </p>
        <div>
          <HelpIconNoTarget />
        </div>
        <p>
          <FormattedMessage id="first.steps.view.more.consultants" />
        </p>
      </div>
    </>
  );
}
