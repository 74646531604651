import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { ReactComponent as SVGBloqued } from "../SVGs/Bloqueado/ic-item-bloq-sm-stroke-black.svg";
import { ReactComponent as SVGReached } from "../SVGs/ReachedCompletedGreen/ic-check-circle-sm-inverse.svg";
import { ReactComponent as SVGFailed } from "../SVGs/NoAlcanzado/ic-x-circle-sm-inverse.svg";
import { ReactComponent as SVGCompleted } from "../SVGs/Completado/ic-item-gift-sm.svg";
import { ReactComponent as SVGpending } from "../SVGs/Pending/ic-item-pending-sm-stroke-black.svg";
import { ReactComponent as SVGUnlocked } from "../SVGs/Bloqueado/ic-item-unlocked-bgwhite.svg";
import {
  fetchFirstStepsDetails
} from "../../Framework/redux/FirstStepsAllActions";
import FirstStepsModalIncorporatedDetails from "./modals/FirstStepsModalIncorporatedDetails";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";

export default function DashboardFirstStepsCardHorizontal({
  boolean,
  biggerThan1025,
  reloadMessage
}) {
  const stepsData = useSelector(
    (state) => state?.firstStepsAllReducer?.firstStepsIncorporatedOnly
  );
  const stepsDataRestOfGroup = useSelector(
    (state) => state?.firstStepsAllReducer?.firstStepsRestOnly
  );
  const stepsDataFirstGen = useSelector(
    (state) => state?.firstStepsAllReducer?.firstGenerationData
  );
  const firstStepsError = useSelector(
    (state) => state.firstStepsAllReducer.firstStepsError
  );

  const history = useNavigate();

 
  const goTofirstSteps = async () => {
    history("firstSteps/incorporatedPersonal");
  };

let primeraGen = [];
let combinedList = [];
let totalConsultant = 0;

if (stepsDataFirstGen) {
  primeraGen = stepsDataFirstGen.firstGeneration?.flatMap(item => item?.personalGroup?.consultantsGroup) || [];
}

if (stepsData?.personalGroup?.consultantsGroup?.length > 0) {
  combinedList = [...stepsData.personalGroup.consultantsGroup];
  totalConsultant += stepsData.personalGroup.consultantsGroup.length;
}

if (stepsDataRestOfGroup?.personalGroup?.consultantsGroup?.length > 0) {
  combinedList = [
    ...combinedList,
    ...stepsDataRestOfGroup.personalGroup.consultantsGroup
  ];
  totalConsultant += stepsDataRestOfGroup.personalGroup.consultantsGroup.length;
}

if (primeraGen.length > 0) {
  combinedList = [...combinedList, ...primeraGen];
  totalConsultant += primeraGen.length;
}

localStorage.setItem("incorp", !!stepsData?.personalGroup?.consultantsGroup);
localStorage.setItem("rest", !!stepsDataRestOfGroup?.personalGroup?.consultantsGroup);
localStorage.setItem("first", !!primeraGen.length);

  return (
    <>
      {combinedList?.length > 0 && (
        <>
          {combinedList && (
              <div className=" mt-3 w-100">
                <div className="d-flex justify-content-between align-items-center museo-sans-500 ">
                  <div className="d-flex align-items-center gap-2">
                    <h4 className="m-0 s20 pointer ">
                      {" "}
                      {boolean ? (
                        <FormattedMessage id="first.steps.title.two" />
                      ) : (
                        <FormattedMessage id="first.steps.title" />
                      )}
                    </h4>
                    <div
                      data-bs-toggle="modal"
                      data-bs-target="#modalFirstOne"
                      className=" pointer"
                    >
                      <HelpIconNoTarget />
                    </div>
                    {
                      firstStepsError ?
                      <>
                      <div
                      className="museo-sans-700 s14 p-0 grayColor border-0 bg-transparent"
                    >
                      <FormattedMessage id="first.steps.view.more.consultants" />
                    </div>
                      </>
                      :
                      <>
                      <button
                      onClick={goTofirstSteps}
                      className="museo-sans-700 s14 p-0 border-0 bg-transparent"
                      style={{ color: "#5AAFF1" }}
                    >
                      <FormattedMessage id="first.steps.view.more.consultants" />
                    </button>
                      </>
                    }
                    
                  </div>
                  <div>
                    {
                      firstStepsError ?
                      <>
                      <p className=" invisible museo-sans-700  grayColor s12">
                      <FormattedMessage id="view.more" />
                    </p>
                      </>
                      :
                      <>
                      <p className=" invisible museo-sans-700 pointer skyColor s12">
                      <FormattedMessage id="view.more" />
                    </p>
                      </>
                    }
                    
                  </div>
                </div>
                <div
                  style={{ padding: "32px", borderRadius: "16px" }}
                  className=" d-flex flex-row cards-shadow bg-white mt-4 w-100"
                >
                  {
                    firstStepsError ? (
                      <>
                        <div className=" w-100">
                          <ShareErrorHandlerServices service={reloadMessage} />
                        </div>
                      </>
                    ) : (
                      // <>

                      // {combinedList ? (
                        <>
                          {combinedList?.length > 0 ? (
                            <>
                              <FirstStepRow data={combinedList[0]} incorp={"true"} />
    
                              {combinedList?.length > 1 && (
                                <FirstStepRow
                                  data={combinedList[1]}
                                  incorp={"true"}
                                />
                              )}
                              {combinedList?.length > 2 && (
                                <>
                                  {biggerThan1025 ? (
                                    <>
                                      <FirstStepRow
                                        data={combinedList[2]}
                                        incorp={"true"}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {combinedList?.length > 2 &&
                                        combinedList?.length < 3 && (
                                          <FirstStepSeeAll
                                            goTofirstSteps={goTofirstSteps}
                                            primeraGen={totalConsultant}
                                            data={totalConsultant}
                                            bigger={biggerThan1025}
                                          />
                                        )}
                                    </>
                                  )}
                                </>
                              )}
    
                              {combinedList?.length > 3 && (
                                <FirstStepSeeAll
                                  goTofirstSteps={goTofirstSteps}
                                  primeraGen={totalConsultant}
                                  data={totalConsultant}
                                  bigger={biggerThan1025}
                                />
                              )}
                            </>
                          ) : (
                            <div className="d-flex justify-content-center w-100"></div>
                          )}
                        </>
                    //   ) : (
                    //     <>
                    //       <EskeletonFirstSteps />
                    //       <EskeletonFirstSteps />
                    //       {biggerThan1025 && (
                    //         <>
                    //           <EskeletonFirstSteps />
                    //         </>
                    //       )}
                    //       <EskeletonFirstStepsSeeAll />
                    //     </>
                    //   )}
                    // </>
                    )
                  }
                
                </div>
              </div>
          )}
        </>
      )}
    </>
  );
}

export function FirstStepRow(data, incorp) {
  const biggerThan1281 = useMediaPredicate("(min-width: 1281px)");

  const dispatch = useDispatch();
  const checkStepStatus = (status, tipoLogro) => {
    switch (status) {
      case "FAILED":
        return (
          <p style={{ color: "#FFFFFF" }}>
            {tipoLogro}:{" "}
            <FormattedMessage id="first.steps.status.not.reached" />
          </p>
        );
      case "PENDING":
        return (
          <p style={{ color: "#171A22" }}>
            {tipoLogro}: <FormattedMessage id="first.steps.status.pending" />
          </p>
        );
      case "BLOCKED":
        return (
          <p style={{ color: "#171A22" }}>
            {tipoLogro}: <FormattedMessage id="first.steps.status.locked" />
          </p>
        );
      case "COMPLETED":
        return (
          <p style={{ color: "#FFFFFF" }}>
            {tipoLogro}: <FormattedMessage id="first.steps.status.completed" />
          </p>
        );
      case "REACHED":
        return (
          <p style={{ color: "#FFFFFF" }}>
            {tipoLogro}: <FormattedMessage id="first.steps.status.reached" />
          </p>
        );
      case "UNLOCKED":
        return (
          <p style={{ color: "#FFFFFF" }}>
            {tipoLogro}: <FormattedMessage id="first.steps.status.unlocked" />
          </p>
        );
    }
  };

  const statusColors = {
    FAILED: "#F06E69",
    PENDING: "#F2D149",
    UNLOCKED: "#F2D149",
    BLOCKED: "#DDDDDD",
    COMPLETED: "#F2D149",
    REACHED: "#16BE7D"
  };
  
  const statusSVGs = {
    FAILED: <SVGFailed />,
    PENDING: <SVGpending />,
    UNLOCKED: <SVGUnlocked />,
    BLOCKED: <SVGBloqued />,
    COMPLETED: <SVGCompleted />,
    REACHED: <SVGReached />
  };
  
  const checkColor = (status) => statusColors[status] || "#FFFFFF";
  const checkSvg = (status) => statusSVGs[status] || null;

  const fetchDetailsData = (data, incorp) => {
    let tempProps = { data: data, incorp: incorp };
    dispatch(fetchFirstStepsDetails(tempProps));
    const controller = document.getElementById(
      `row-modal-controller-${data?.data?.clientId}`
    );
    controller.click();
  };

  const fetchAchievementByStepId = (array, stepId) => {
    if (!array) {
      return undefined;
    }
    let aux = array.find((elm) => elm.stepId == stepId);
    return aux;
  };

  return (
    <div className="d-flex">
      <div className="pointer" onClick={() => fetchDetailsData(data, incorp)}>
        <div
          id={`row-modal-controller-${data?.data?.clientId}`}
          className=" invisible"
          data-bs-toggle="modal"
          data-bs-target="#modalFirstStepsLiderDirector"
        ></div>
        <div
          className="d-flex align-items-center"
          style={{ width: "242px", gap: "16px" }}
        >
          <ImageCircleHandler
            size={"14px"}
            width={"32px"}
            height={"32px"}
            url={data?.data?.profileResume?.image}
            firstName={data?.data?.profileResume?.firstName}
            lastName={data?.data?.profileResume?.lastName}
            name={data?.data?.profileResume?.name}
            top={2}
            styles={{ marginBottom: "15px" }}
          />
          <div className="d-flex flex-column">
            <h5
              className=" s14 museo-sans-700 m-0 d-flex align-items-center"
              style={{ height: "39px", width: "180px" }}
            >
              {data?.data?.profileResume?.name}
            </h5>
            <p className=" grayColor museo-sans-500 s14">
              <FormattedMessage id="header.week" /> {data?.data?.currentWeek}{" "}
              <FormattedMessage id="of" defaultMessage={"de"} />{" "}
              {data?.data?.totalWeeks}
            </p>
          </div>
        </div>
        <div style={{ gap: "6px" }} className=" d-flex pt-2 mt-1 flex-column">
          {fetchAchievementByStepId(data?.data?.steps, 1) && (
            <>
              <div
                style={{
                  width: "242px",
                  background: checkColor(
                    fetchAchievementByStepId(data?.data?.steps, 1)?.stepDetail?.state
                  ),
                  height: "25px",
                  borderRadius: "24px",
                  size: "14px",
                }}
                className="d-flex align-items-center"
              >
                <div
                  className="d-flex align-items-center"
                  style={{ marginLeft: "8px", gap: "5px" }}
                >
                  {checkSvg(fetchAchievementByStepId(data?.data?.steps, 1)?.stepDetail?.state)}
                  <p className="museo-sans-500 s14">
                    {checkStepStatus(
                      fetchAchievementByStepId(data?.data?.steps, 1)?.stepDetail?.state,
                      "Logro 1"
                    )}
                  </p>
                </div>
              </div>
            </>
          )}
          {fetchAchievementByStepId(data?.data?.steps, 2) && (
            <div
              style={{
                width: "242px",
                background: checkColor(fetchAchievementByStepId(data?.data?.steps, 2)?.stepDetail?.state),
                height: "25px",
                borderRadius: "24px",
              }}
              className="d-flex align-items-center"
            >
              <div
                className="d-flex align-items-center"
                style={{ marginLeft: "8px", gap: "5px" }}
              >
                {checkSvg(fetchAchievementByStepId(data?.data?.steps, 2)?.stepDetail?.state)}
                <p className="museo-sans-500 s14">
                  {checkStepStatus(
                    fetchAchievementByStepId(data?.data?.steps, 2).stepDetail?.state,
                    "Logro 2"
                  )}
                </p>
              </div>
            </div>
          )}
          {fetchAchievementByStepId(data?.data?.steps, 3) && (
            <div
              style={{
                width: "242px",
                background: checkColor(fetchAchievementByStepId(data?.data?.steps, 3)?.stepDetail?.state),
                height: "25px",
                borderRadius: "24px",
              }}
              className="d-flex align-items-center"
            >
              <div
                className="d-flex align-items-center"
                style={{ marginLeft: "8px", gap: "5px" }}
              >
                {checkSvg(fetchAchievementByStepId(data?.data?.steps, 3)?.stepDetail?.state)}
                <p className="museo-sans-500 s14">
                  {checkStepStatus(
                    fetchAchievementByStepId(data?.data?.steps, 3)?.stepDetail?.state,
                    "Logro EDB"
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="h-100 brightGrayBg position-relative "
        style={{
          width: "1px",
          marginLeft: !biggerThan1281 ? "16px" : "32px",
          marginRight: !biggerThan1281 ? "16px" : "32px",
        }}
      >
        <p className="invisible">.</p>
      </div>
      <FirstStepsModalIncorporatedDetails infoStep={data?.data} />
    </div>
  );
}
export function FirstStepSeeAll({ data, bigger, goTofirstSteps, primeraGen }) {
  return (
    <div className="d-flex align-items-center">
      <div
        style={{ width: "180px", height: "48px" }}
        className="d-flex flex-row"
      >
        <div
          style={{
            background: "#908F9A",
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            gap: "5px",
          }}
          className="d-flex flex-row align-items-center justify-content-center"
        >
          <div
            style={{
              background: "#FFFFFF",
              borderRadius: "50%",
              width: "6px",
              height: "6px",
            }}
          ></div>
          <div
            style={{
              background: "#FFFFFF",
              borderRadius: "50%",
              width: "6px",
              height: "6px",
            }}
          ></div>
          <div
            style={{
              background: "#FFFFFF",
              borderRadius: "50%",
              width: "6px",
              height: "6px",
            }}
          ></div>
        </div>
        <div style={{ paddingLeft: "8px" }}>
          <div className="museo-sans-700 s16">
            +{bigger ? primeraGen - 3 : primeraGen - 2}{" "}
            <FormattedMessage id="first.steps.consultants" />
          </div>
          <button
            className="museo-sans-700 s14 p-0 border-0 bg-transparent"
            style={{ color: "#5AAFF1" }}
            onClick={() => goTofirstSteps()}
          >
            <FormattedMessage id="view.more" />
          </button>
        </div>
      </div>
    </div>
  );
}
export function EskeletonFirstSteps() {
  return (
    <div className="d-flex">
      <div>
        <div className="d-flex" style={{ width: "242px", gap: "16px" }}>
          <div
            style={{ height: "32px", width: "32px" }}
            className=" rounded-circle brightGrayBg"
          ></div>
          <div className="d-flex flex-column">
            <div
              className=" s16 museo-sans-700 brightGrayBg rounded-3"
              style={{ width: "134px", height: "16px", marginBottom: "4px" }}
            ></div>
            <div
              className=" brightGrayBg rounded-3 museo-sans-500 s12"
              style={{ width: "104px", height: "12px" }}
            ></div>
          </div>
        </div>
        <div style={{ gap: "6px" }} className=" d-flex pt-2 mt-1 flex-column">
          <div
            style={{
              width: "242px",
              height: "25px",
              borderRadius: "24px",
              size: "14px",
            }}
            className="d-flex align-items-center brightGrayBg"
          >
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "8px", gap: "5px" }}
            ></div>
          </div>
          <div
            style={{
              width: "242px",
              height: "25px",
              borderRadius: "24px",
            }}
            className="d-flex align-items-center brightGrayBg"
          >
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "8px", gap: "5px" }}
            ></div>
          </div>
          <div
            style={{
              width: "242px",
              height: "25px",
              borderRadius: "24px",
            }}
            className="d-flex align-items-center brightGrayBg"
          >
            <div
              className="d-flex align-items-center"
              style={{ marginLeft: "8px", gap: "5px" }}
            ></div>
          </div>
        </div>
      </div>
      <div
        className="h-100 brightGrayBg position-relative "
        style={{ width: "1px", marginLeft: "32px", marginRight: "32px" }}
      >
        <p className="invisible">.</p>
      </div>
    </div>
  );
}
export function EskeletonFirstStepsSeeAll() {
  return (
    <div className="d-flex align-items-center">
      <div
        style={{ width: "167px", height: "48px" }}
        className="d-flex flex-row"
      >
        <div
          style={{
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            gap: "5px",
          }}
          className="d-flex flex-row align-items-center justify-content-center brightGrayBg"
        ></div>
        <div style={{ paddingLeft: "8px" }}>
          <div
            className="museo-sans-700 s16 brightGrayBg rounded-3 mt-1"
            style={{ width: "104px", height: "12px" }}
          ></div>
          <div
            className="museo-sans-700 s14 p-0 mt-1 border-0 rounded-3 brightGrayBg"
            style={{ width: "84px", height: "12px" }}
          ></div>
        </div>
      </div>
    </div>
  );
}
