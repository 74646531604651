import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { defineStepNumber } from "../../../Dashboard/Framework/redux/dashboardAction";

import PoliticsModal from "../../../common/termsAndConditions/PoliticsModal";
import PrivacyPolicyContent from "../../../common/termsAndConditions/PrivacyPolicyContent";
import DistributionAgreementContent from "../../../common/termsAndConditions/DistributionAgreementContent";
import RegisterAnalytics from "../../Framework/ga/RegisterAnalytics";


export default function LoginReady({ activateFirstLogin }) {
  const history = useNavigate();
  const dispatch = useDispatch();

  const toDashboard = (evt) => {
    evt.preventDefault();
    RegisterAnalytics("accept-tyc");
    dispatch(defineStepNumber(-1));
    if (localStorage.getItem("firstLogin") === null) {
      localStorage.setItem("firstLogin", "true");
    }
    activateFirstLogin();
    history("/dashboard");
  };

  useEffect(() => {
    if (localStorage.getItem("firstLogin") === null) {
      localStorage.setItem("firstLogin", "true");
    }
    activateFirstLogin();
  }, []);

  return (
    <div id="login-layout" className=" container--padding py-5">
      <div className=" ">
        <h1 className=" invisible mt-3 grayColor s12 museo-sans-500 text-uppercase">
          <FormattedMessage
            id="login.recovery.page.title"
            defaultMessage="1. Validate identity"
          />
        </h1>
        <hr className="invisible"></hr>
        <h2 className="s24 museo-sans-700 mt-4">
          <FormattedMessage
            id="login.ready.page.title"
            defaultMessage="Done! Your account is set."
          />
        </h2>
        <p className="s16 museo-sans-500 my-1">
          <FormattedMessage
            id="login.ready.page.subtitle"
            defaultMessage="Now you will be able to access our web when you want."
          />
        </p>
        <p className="s16 museo-sans-500 my-1 pt-2">
          <FormattedMessage
            id="login.ready.page.comment"
            defaultMessage="Shall we start our tour?"
          />
        </p>
        <hr
          style={{
            marginRight: "30px",
            marginTop: "32px",
            marginBottom: "32px",
          }}
        ></hr>
        <div className=" d-flex my-1 fw-light">
          <p
            className="s14 museo-sans-500 m-0"
            style={{
              color: "#171A22CC",
              display: "inline-block",
              whiteSpace: "normal",
            }}
          >
            <p>
              <FormattedMessage id="login.ready.start.p" />
              <a
                className=" text-info text-decoration-none"
                data-bs-toggle="modal"
                href="#termsAndConditions"
              >
                {" "}
                <FormattedMessage
                  id="login.ready.start.first.link"
                  defaultMessage="Terms and conditions."
                />{" "}
              </a>
              <FormattedMessage id="login.ready.connect.p" />{" "}
              <span
                data-bs-toggle="modal"
                href="#ethicsCode"
                className=" pointer text-info"
              >
                <FormattedMessage
                  id="politics.meta.code"
                  defaultMessage="Ethic code"
                />{" "}
              </span>
              <FormattedMessage id="login.ready.connect.p.swissjust" />.
            </p>
          </p>
        </div>
      </div>
      <div className=" position-relative  align-self-end">
        <button
          className=" btnLoginWidth position-relative s14  museo-sans-700 py-2 text-white btnCustom skyBg "
          onClick={toDashboard}
        >
          <FormattedMessage
            id="buttons.accept.continue"
            defaultMessage="Recover password"
          />
        </button>
      </div>
      <PoliticsModal
        id="privacyPolicy"
        title={<FormattedMessage id="privacy.policy.title" />}
      >
        <PrivacyPolicyContent />
      </PoliticsModal>
      <PoliticsModal
        id="termsAndConditions"
        title={<FormattedMessage id="terms.and.conditions.title" />}
      >
        <DistributionAgreementContent />
      </PoliticsModal>
    </div>
  );
}
