import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import statusColor from "../../../common/Share/statusColor";
import { ReactComponent as SVGRightChev } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as NoOrdersIcon } from '../../../common/svgs/Empty-Pedidos-Ordenes.svg'

export default function StatusAccountTableBody({ fatherLoader, summary, cultureInfo, descId }) {

  const history = useNavigate();

  const statusAmount = (amount) => {

    if (Math.sign(amount) != -1) {
      return "blackColor"
    } else {
      return statusColor("risk")
    }
  }

  const onClickDetails = (row) => {
    if (row?.hasDetail) {
      descId ? history(`details/${row?.documentId}/${descId}`) : history(`details/${row?.documentId}/0`)
    }
  };

  const controlDate = (date) => {
    let auxDateArray = date.split("T");
    let auxDateNumber = auxDateArray[0];

    let arraySpringDate = auxDateNumber.split("-");

    let dataYear = arraySpringDate[0];
    let dataMonth = arraySpringDate[1];
    let dataDay = arraySpringDate[2];

    return `${dataDay}-${dataMonth}-${dataYear.slice(2, 4)}`;
  };

  const formatValueForTable = (string) => {
    let actualValue = string.replace(`${<FormattedMessage id="currency" />}`, "");
    return actualValue;
  };

  return (
    <>

      <div className="container--padding--card-holder pt-2">
        <div className=" pt-4">
          <div className=" w-100 pt-1 museo-sans-500 s12 grayColor text-uppercase status-account--grid-table">
            <p >
              <FormattedMessage id="concept" />
            </p>
            <p >
            <FormattedMessage id="date" />
            </p>
            <p className=" d-flex justify-content-end text-center" >
            <FormattedMessage id="amount" />
            </p>
            <div className=" invisible">
              <p>.</p>
            </div>
          </div>

        </div>
      </div>
      {
        fatherLoader ?
          <StatusAccountTableBodyFatherLoader />
          :
          <div style={{ paddingTop: "40px" }} >

            {summary?.length > 0 ? (
              <>
                {summary.map((row) => {
                  return (
                    <>
                      <div className="container--padding--card-holder ">
                        <div
                          className={` ${row?.hasDetail && "pointer"} `}
                        >
                          <div
                            style={{ borderBottom: "1px #DDDDDD solid", height: "45px", marginBottom: "27px" }}
                            id={row?.documentId}
                            onClick={() => onClickDetails(row)}
                            className=" w-100 status-account--grid-table museo-sans-500 s14 grayColor text-uppercase "
                          >
                            <p className=" blackColor" >{row?.description}</p>
                            <p className=" dm-sans-medium" >{controlDate(row?.date)}</p>
                            <p className={` ${statusAmount(row?.amount)} d-flex justify-content-end text-right dm-sans-medium `} >
                              {formatValueForTable(
                                formatAmount(
                                  row?.amount,
                                  cultureInfo?.languageCode,
                                  cultureInfo?.defaultCurrencyCode
                                )
                              )}
                            </p>
                            <div className=" d-flex justify-content-end pe-4" >
                              {
                                row?.hasDetail ?
                                  <>
                                    <div className="">
                                      <SVGRightChev />
                                    </div>
                                  </>
                                  :
                                  <>
                                    <div className=" invisible">
                                      <p>.</p>
                                    </div>

                                  </>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <>
                <div className="container--padding--card-holder" >
                  <div style={{ height: "342px", gap: "8px" }} className=" d-flex justify-content-center align-items-center flex-column museo-sans-500 " >
                    <div style={{ height: "80px", width: "80px" }} >
                      <NoOrdersIcon />
                    </div>
                    <p className=" s24 " >
                      <FormattedMessage id="status.account.table.empty.title" />
                    </p>
                    <p className=" LinkOpacityHover s14 grayColor" >
                      <FormattedMessage id="status.account.table.empty.text" />
                    </p>

                  </div>
                </div>
              </>
            )}
          </div>

      }

    </>
  );
}

const StatusAccountTableBodyFatherLoader = () => {
  return(
    <div style={{ paddingTop: "40px" }} >
            <div className="container--padding--card-holder" >
              <div className=" d-flex flex-column gap-2" >
                <div style={{ height: "40px" }} className=" w-100" >
                  <div className=" w-100 status-account--grid-table" >
                    <div style={{ width: "230px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div style={{ width: "130px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div className=" d-flex justify-content-end" >
                      <div style={{ width: "60px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div style={{ height: "40px" }} className=" w-100" >
                  <div className=" w-100 status-account--grid-table" >
                    <div style={{ width: "230px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div style={{ width: "130px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div className=" d-flex justify-content-end" >
                      <div style={{ width: "60px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div style={{ height: "40px" }} className=" w-100" >
                  <div className=" w-100 status-account--grid-table" >
                    <div style={{ width: "230px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div style={{ width: "130px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div className=" d-flex justify-content-end" >
                      <div style={{ width: "60px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div style={{ height: "40px" }} className=" w-100" >
                  <div className=" w-100 status-account--grid-table" >
                    <div style={{ width: "230px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div style={{ width: "130px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div className=" d-flex justify-content-end" >
                      <div style={{ width: "60px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div style={{ height: "40px" }} className=" w-100" >
                  <div className=" w-100 status-account--grid-table" >
                    <div style={{ width: "230px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div style={{ width: "130px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div className=" d-flex justify-content-end" >
                      <div style={{ width: "60px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                      </div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div style={{ height: "40px" }} className=" w-100" >
                  <div className=" w-100 status-account--grid-table" >
                    <div style={{ width: "230px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div style={{ width: "130px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                    </div>
                    <div className=" d-flex justify-content-end" >
                      <div style={{ width: "60px", height: "12px", borderRadius: "16px" }} className=" brightGrayBg " >

                      </div>
                    </div>
                  </div>
                </div>
                <hr/>

              </div>
              <hr />

            </div>

          </div>
  )
}