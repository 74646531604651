import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { deleteClient } from "../../../Framework/redux/ClientListSideEffect";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGWarn } from "../../../../common/svgs/ic-x-circle-swade.svg";
import { openNotificationDeletePopUp } from "../../../Framework/redux/ClientListAction";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { useContext } from "react";
import { ModalClipboardContext } from "../../../../common/routes/routes";

export default function ClientListDetailsModalDelete({ contactId }) {
  const dispatch = useDispatch();
  const history = useNavigate();
  
  const { setModalTexts } = useContext(ModalClipboardContext);

  const onActivateSwalOnDelete = async () => {
    const result = await dispatch(deleteClient(contactId));
    if (result.payload.code == 200) {
      history("/dashboard/clientlist");
      const modalData = {
        title: <FormattedMessage id="notification.modal.delete.title.one" />,
        text: <FormattedMessage id="notification.modal.delete.subtitle.one" />,
        status: "confirm",
        show: false,
        bottom: 0,
      };
      setModalTexts(modalData);
      dispatch(openNotificationDeletePopUp());
    }
    const clickMe = document.getElementById("close-details-delete-modal");
    clickMe.click();
    
  };

  return (
    <div>
      <div className="d-flex justify-content-between pt-4 mt-3 px-4 mx-3 ">
        <div className="d-flex invisible align-items-center">
          <HelpIconNoTarget />
          <h5 className="museo-sans-500 px-2 s20 m-0">
            <FormattedMessage
              id="account.modal.info.title"
              defaultMessage="Buenas Prácticas"
            />
          </h5>
        </div>
        <div className="pointer" data-bs-dismiss="modal" aria-label="Close">
          <SVGClose />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center">
        <div className=" mb-2 w-75 d-flex justify-content-center flex-column text-center museo-sans-500 px-4 mx-3">
          <div>
            <SVGWarn />
          </div>
          <p className=" s24 museo-sans-700">
            <FormattedMessage id="notification.modal.delete.title.one" />
          </p>
          <p className=" s16 museo-sans-500 grayColor  ">
            <FormattedMessage id="client.details.modal.delete.message" />
          </p>
        </div>
        <div className=" mt-4 mb-4 pb-3 d-flex flex-column justify-content-center gap-3 align-items-center">
          <button
            type="button"
            className=" btnLoginWidth  museo-sans-700 s14 skyBg whiteColor border-0 lightRedBg button-bootstrap-radius button-padding"
            onClick={onActivateSwalOnDelete}
          >
            <FormattedMessage id="notification.modal.delete.title.one" />
          </button>
          <p
            id="close-details-delete-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
            className=" pointer s14 skyColor museo-sans-700"
          >
            <FormattedMessage id="buttons.cancel" />
          </p>
        </div>
      </div>
    </div>
  );
}