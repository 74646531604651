import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as SVGClose} from "../svgs/ic-x-sm-green.svg"

export default function ModalClipboardDetails ({show, close}) {

    const biggerThan1800 = useMediaPredicate("(min-width: 1799px)");

    const onClickClose = () => {
        close(false);
    }

    return (
    show && 
    <div style={ biggerThan1800 ? {left: "300px"} : {left: "75px"}} className=" shadow mb-3 mx-3 position-fixed py-3 " id="modal--clipboard">
        <i style={{justifySelf: "center"}} className=" greenColor bi bi-check-circle"></i>
        <div>
            <p className=" museo-sans-700 s14 greenColor">
            Código copiado
            </p>
            <p className=" museo-sans-500 s14 greenColor">
            Se ha copiado el código en el portapapeles.
            </p>
        </div>
        <div onClick={onClickClose} style={{justifySelf: "center "}} className="pointer" >
            <SVGClose />
        </div>
    </div>
    )
}