export default function SkeletonAccountGraph(params) {
  return (
    <>
      <div className=" p-3 d-flex gap-2 ">
        <div
          style={{ width: "90px", height: "8px" }}
          className=" rounded-2 brightGrayBg lightui1-shimmer "
        ></div>
        <div
          style={{ width: "55px", height: "8px" }}
          className=" rounded-2 brightGrayBg  lightui1-shimmer"
        ></div>
      </div>
      <div className=" mb-3">
        <div
          style={{ width: "400px", height: "210px" }}
          className=" w-100 brightGrayBg lightui1-shimmer"
        ></div>
      </div>
      <div className=" d-flex flex-column ">
        <SkeletonAccountRow toggle={true} />
        <SkeletonAccountRow toggle={false} />
        <SkeletonAccountRow toggle={false} />
        <SkeletonAccountRow toggle={false} />
        <hr></hr>
        <SkeletonAccountRowTwo />
        <hr></hr>
        <SkeletonAccountRowTwo />
      </div>
    </>
  );
}

export function SkeletonAccountRow({ toggle }) {
  return (
      <div className=" d-flex overflow-hidden  position-relative w-100 py-3 ">
        {toggle && (
          <div
            style={{ top: "0" }}
            className=" position-absolute w-100 h-100 brightGrayBgOpacity20 lightui1-shimmer"
          ></div>
        )}
        <div className=" px-3 d-flex w-100 position-relative justify-content-between">
          <div className=" d-flex gap-2">
            <div
              style={{ height: "12px", width: "12px" }}
              className=" brightGrayBg rounded-circle lightui1-shimmer"
            ></div>
            <div
              style={{ height: "12px", width: "170px" }}
              className=" rounded-2 brightGrayBg lightui1-shimmer"
            ></div>
          </div>
          <div>
            <div
              style={{ height: "12px", width: "55px" }}
              className=" rounded-2 brightGrayBg lightui1-shimmer"
            ></div>
          </div>
        </div>
      </div>
  );
}

export function SkeletonAccountRowTwo() {
  return (
    <div className=" d-flex overflow-hidden  position-relative w-100 py-3 ">
      <div className=" px-3 d-flex w-100 position-relative justify-content-between ">
        <div className=" d-flex gap-2 ">
          <div
            style={{ height: "12px", width: "170px" }}
            className=" rounded-2 brightGrayBg lightui1-shimmer"
          ></div>
        </div>
        <div>
          <div
            style={{ height: "12px", width: "55px" }}
            className=" rounded-2 brightGrayBg lightui1-shimmer"
          ></div>
        </div>
      </div>
    </div>
  );
}
