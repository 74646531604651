import { useCallback, useEffect, useState } from "react";

export default function ModalWhiteShadow({ scrollbarRef, left, bottom, width, aidHide, height , zIndex, reverseModal }) {
  const [toggler, setToggler] = useState(true);

  const handleScrollreverseModal = useCallback(() => {
    let currentPosition = scrollbarRef.current.scrollTop;
    
    // Si el scroll está en el top o muy cerca del top, activamos el modal
    if (currentPosition <= (aidHide ? aidHide : 0)) {
      setToggler(false); // Ocultar cuando estamos al top
    } else {
      setToggler(true);  // Mostrar cuando hacemos scroll hacia abajo
    }
  }, [aidHide, scrollbarRef]);

  const handleScroll = useCallback(() => {
    let maxScrollHeight = scrollbarRef.current.scrollHeight - scrollbarRef.current.clientHeight;
    let currentPosition = scrollbarRef.current.scrollTop;
    if (maxScrollHeight <= currentPosition + (aidHide ? aidHide : 0)) {
        setToggler(false)
    } else {
        setToggler(true)
    }
  }, []);

  useEffect(() => {
    if (reverseModal) {
      const div = scrollbarRef?.current;
      div?.addEventListener("scroll", handleScrollreverseModal);
      return () => div?.removeEventListener("scroll", handleScrollreverseModal); // Limpia el evento al desmontar
      
    }
  }, [handleScrollreverseModal, scrollbarRef]);

  
    useEffect(() => {
      if (!reverseModal) {
        const div = scrollbarRef?.current;
        div?.addEventListener("scroll", handleScroll);
        
      }
    }, [handleScroll]);
    
    return (
      <>
        {toggler ? (
          <div
            style={{
              height: height ? height : "40px",
              left: left ? left : "5%",
              bottom: bottom ? bottom : "100px",
              overflowX: "hidden",
              width: width ? width : "90%",
              zIndex: zIndex ? zIndex : 5,
              transform: reverseModal ?  "rotate(180deg" : ""
            }}
            className={` d-flex position-absolute ${reverseModal ? "shadow-effect-reverse" : "shadow-effect"}`}
          ></div>
        ) : (
          <></>
        )}
      </>
    );

}
