import React from 'react'

import {ReactComponent as NoEDBIcon} from '../../../common/svgs/ic-edbs.svg'

export default function EDBEmpty({title, description}) {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center no-edbs-width'>
        <div className='rounded-circle no-edbs-icon'>
            <NoEDBIcon />
        </div>
        <p className='museo-sans-500 s24'>{title}</p>
        <p className='museo-sans-500 s14 grayColor text-center'>{description}</p>
    </div>
  )
}
