import moment from "moment-timezone";
import { FormattedMessage } from "react-intl";
import { getCountriesTimeZone } from "../../../common/Share/getCountriesTimeZone";

export default function FirstStepsGuidelinesDate({ initDate, endDate }) {
  const countryCode = localStorage.getItem("country");

  const changeData = (initDate) => {
    let momentIniDateDay = moment(initDate).format("DD");
    let monthIni = moment(initDate).format("MM");
    let momentIniDateYear = moment(initDate).format("YYYY");

    if (monthIni < 10) {
      monthIni = monthIni.slice(1);
    }

    return (
      <>
        <span className="text-capitalize">
          <FormattedMessage id="del" />{" "}
        </span>
        <span> </span>
        <>{momentIniDateDay}</>
        <>
          {" "}
          <FormattedMessage id={`month.of.${monthIni}`} />{" "}
        </>
        <span className="">
          <FormattedMessage id="del" />{" "}
        </span>
        <>{momentIniDateYear}</>
      </>
    );
  };

  const changeDataEnd = (endDate) => {
    let momentEndDateDay = moment(endDate).format("DD");
    let monthEnd = moment(endDate).format("MM");
    let momentEndDateYear = moment(endDate).format("YYYY");

    if (monthEnd < 10) {
      monthEnd = monthEnd.slice(1);
    }

    return (
      <>
        {" "}
        <FormattedMessage id="al" /> <span>{momentEndDateDay}</span>
              {" "}
              <FormattedMessage id={`month.of.${monthEnd}`} />{" "}
        <>
              <FormattedMessage id="del" /> {momentEndDateYear}
           
        </>
      </>
    );
  };

  return (
    <span>
      {changeData(initDate)}
      {endDate != null ? <>{changeDataEnd(endDate)}</> : " "}
    </span>
  );
}
