export default function SkeletonGoodPracticesImportant() {
  return (
    <div
        style={{
          borderRadius: "1rem",
        }}
        className=" position-relative gg-cards--importance bg-white f9GrayBorder desc-cards-shadow d-flex flex-column justify-content-between gap-3 p-4"
      >
        <div
          style={{ height: "8px" }}
          className="lightui1-shimmer w-25 rounded-3 brightGrayBg  "
        ></div>
        <div
          style={{ height: "38px", width: "38px" }}
          className="lightui1-shimmer rounded-circle brightGrayBg  "
        ></div>
        <div className=" d-flex flex-column gap-2 mb-3" >
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-100 rounded-3 brightGrayBg  "
          ></div>
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-100 rounded-3 brightGrayBg  "
          ></div>
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-25 rounded-3 brightGrayBg  "
          ></div>
        </div>
        <div
          style={{ height: "8px" }}
          className="lightui1-shimmer mt-4 w-50 rounded-3 brightGrayBg  "
        ></div>
      </div>
  );
}
