import replaceElementsURL from "../../../common/Share/replaceElementURL";
import { ReactComponent as SVGDefalt } from "../imgs/default/logo.svg";

const OfertsImageHolder = ({ url, id, filename, short }) => {
  const styles = {
    short: {
      borderRadius: "1%",
      height: "90px",
      width: "70px",
      position: "absolute",
      right: 7,
      bottom: 0,
    },
    long: {
      borderRadius: "1%",
      height: "176px",
      width: "130px",
      position: "absolute",
      right: 5,
      bottom: 0,
    },
  };

  const defaultStyles = {
    short: {
      position: "absolute",
      display: "flex",
      borderRadius: "1%",
      height: "90px",
      width: "70px",
      right: 20,
      top: 80,
    },
    long: {
      position: "absolute",
      display: "flex",
      justifyContent: "flex-end",
      borderRadius: "1%",
      height: "126px",
      width: "130px",
      right: 15,
      top: 345,
    },
  };

  return (
    <>
      {url === "" || url === undefined || id === undefined ? (
        <div style={defaultStyles[short ? "short" : "long"]}>
          <SVGDefalt />
        </div>
      ) : (
        <img
          style={styles[short ? "short" : "long"]}
          src={`${replaceElementsURL(url, id, filename)}`}
        />
      )}
    </>
  );
};

export default OfertsImageHolder;
