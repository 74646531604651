import { FormattedMessage } from "react-intl";
import InvitationsModalShareLink from "./modals/InvitationsModalShareLink";

export default function InvitationsAddShare({form, onListClick, link}) {
    
    return(<>
        <InvitationsModalShareLink firstName={form?.firstName?.value} lastName={form?.lastName?.value} data={link} email={form.email.value} phone={`${form.phone.area}${form.phone.body}`} />
        <div style={{ marginTop: "147px" }} className="w-100 d-flex flex-column justify-content-center gap-3 align-items-center text-center museo-sans-500">
        <h3 className=" skyColor museo-sans-700 " >
            <FormattedMessage id="invitation.create.share.title" />{form?.firstName?.value}
        </h3>
        <p className=" s14 w-50" >
            <FormattedMessage id="invitation.create.share.msg.one" />
        </p>
        <div className="d-flex gap-3 flex-column mt-5" >
            <button 
            data-bs-toggle="modal"
            data-bs-target="#modalShare"
            style={{ width: "434px", height: "50px" }}
                className=" s16 border-0 rounded-16 s16  museo-sans-700 skyBg text-white ">
                <FormattedMessage id="invitation.create.button.msg.one" />
            </button>
            <p onClick={onListClick} className=" skyColor museo-sans-700 s14 pointer" >
                <FormattedMessage id="invitation.create.button.msg.two" />
            </p>

        </div>
    </div>
    </>)
}