import { FormattedMessage } from "react-intl";
import {ReactComponent as SVGCheck} from "../../../common/svgs/ic-check-circle.svg"

export default function BusinessPlanCardLevelPrevious({onClick, level, actualLevel, array}) {

  const selectedCard = {background: "#ECF5FE", borderRadius: "16px", boxShadow: "3px 3px 15px #A4A4D733", border: " 1px solid #D3EAFF", color: "#5AAFF1"}; 
  const notSelectedCard = { borderRadius: "16px", color: "#908F9A"};

  const selectedP = {color: "#5AAFF1", width: "200px"};
  const notSelectedP = {color: "#908F9A", width: "200px"};

    return (
        <>
           <div
          style={{ width: "24px" }}
          className=" me-3 position-relative pt-3 d-flex gap-1 flex-column align-items-center"
        >
          <div className="" >
            <SVGCheck />
          </div>
          <div className=" h-100">
            <div
              style={{ width: "3px" }}
              className=" rounded-5 h-100 lightGreenBg "
            ></div>
          </div>
        </div>
        <div className=" bp--card-width d-flex flex-column" style={{ justifySelf: "left" }} >
        <div style={level < actualLevel && level ? selectedCard : notSelectedCard } className={`pointer mb-4 hover-on-mouse bp--card-p  museo-sans-500 d-flex flex-column gap-3  `} >
                <p className=" s12 grayColor" > <FormattedMessage id="business.plan.card.late.title" /> ({actualLevel -1})</p>
                {
                  array.map((elem) => {
                    if (elem.id < parseInt(actualLevel)) {
                      return <p key={`${elem?.id}-05`} onClick={() => onClick(elem.id)} style={parseInt(level) === parseInt(elem.id) ? selectedP : notSelectedP }  className=" s14">
                        {elem.desc}
                      </p>
                    }
                  })
                }
            </div>
        </div>
        </>
    )
}