import React from 'react'

import {ReactComponent as NoOrdersIcon} from '../../../common/svgs/Empty-Pedidos-Ordenes.svg'

export default function PersonalSalesEmpty({title, description}) {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center no-edbs-width'>
        <div className='rounded-circle'>
            <NoOrdersIcon />
        </div>
        <p className='museo-sans-500 s24 blackColor'>{title}</p>
        <p className='museo-sans-500 s14 grayColor text-center'>{description}</p>
    </div>
  )
}