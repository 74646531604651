import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import BusinessPlanCardEarningPerLevelContent from "./BusinessPlanCardEarningPerLevelContent";
import BusinessPlanCardEarningPerLevelDescription from "./BusinessPlanCardEarningPerLevelDescription";

export default function BusinessPlanCardearningPerLevel({
  data,
  level,
  cultureInfo,
}) {
  
  const returnNewJSX = () => {
    return (
      <p className=" text-uppercase lightGreenColor s16 museo-sans-700 ">
        <FormattedMessage id="new" defaultMessage="Nuevo" />
      </p>
    );
  };

  return (
    <>
      {data && (
        <>
          {level === 1 ? (
            <div className=" d-flex flex-column pb-3 ">
              <h3 className=" mb-2 d-flex m-0 museo-sans-700 s16">
                <spam
                  style={{ width: "60px", textAlign: "right" }}
                  className="dm-sans-bold"
                >
                  {data?.earnings[0]?.valueDescription}
                </spam>
                <spam className="invisible px-2 mx-1 "></spam>
                <spam className="bp-earning--p-width">
                  <FormattedMessage id="business.plan.earning.personal.sale" />
                </spam>
              </h3>
              <div className=" d-flex flex-column gap-2 grayColor s14 museo-sans-500">
                {data?.earnings[0].details.map((elm) => {
                  return (
                    <div key={elm?.id} className=" d-flex ">
                      <spam
                        style={{ width: "60px", textAlign: "right" }}
                        className=" dm-sans-medium"
                      >
                        {elm.valueDescription}
                      </spam>
                      <spam className="invisible px-2 mx-1 "></spam>
                      <spam className="bp-earning--p-width">
                        {elm.itemDescription}
                      </spam>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <>
              {data.earnings.map((elm) => {
                return (
                  <div key={`${elm.id}-02`} className=" gap-2 d-flex flex-column pb-3 ">
                    <BusinessPlanCardEarningPerLevelContent
                      returnNewJSX={returnNewJSX}
                      elm={elm}
                    />
                    <BusinessPlanCardEarningPerLevelDescription elm={elm} returnNewJSX={returnNewJSX} formatAmount={formatAmount} cultureInfo={cultureInfo} />
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </>
  );
}
