import Vimeo from "@u-wave/react-vimeo";
import { useDispatch, useSelector } from "react-redux";
import { closeVimeo, openVimeo } from "../redux/commonActions";
import { ReactComponent as SVGx } from "../svgs/ic-x-overlay.svg";

import { ReactComponent as SVGFilledRightChev } from "../svgs/ic-slide-right.svg";
import { ReactComponent as SVGFilledLeftChev } from "../svgs/ic-slide-left.svg";
import "./VimeoController.css";
import { useMediaPredicate } from "react-media-hook";

export default function VimeoController() {
  const reduxStoreVimeo = useSelector((state) => state.common.vimeo);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeVimeo());
  };

  const findPrevAndPost = () => {
    let found = reduxStoreVimeo?.array?.indexOf(
      reduxStoreVimeo?.array?.find((elm) => elm.id == reduxStoreVimeo.id)
    );

    let left = found - 1;

    if (left == -1) {
      left = reduxStoreVimeo.array.length - 1;
    }

    let right = found + 1;

    if (right >= reduxStoreVimeo.array.length) {
      right = 0;
    }

    let object = { left: left, right: right };

    return object;
  };
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");

  return (
    reduxStoreVimeo.loaded && (
      <div
        style={{ width: "100%", height: "100%", top: "0", left: "0" }}
        className=""
        id="vimeo--displayer"
      >
        <div
          style={{ zIndex: "998" }}
          className="bg-black d-block position-absolute opacity-75 w-100 h-100"
          onClick={onClose}
        ></div>
        <VimeoRedCloseZone onClose={onClose} />
        <VimeoBtnController reduxStoreVimeo={reduxStoreVimeo} />
        <div
          onClick={onClose}
          style={
            biggerThan1921
              ? {
                  zIndex: "1000",
                  top: "42px",
                  right: "42px",
                  width: "42px",
                  height: "42px",
                }
              : {
                  zIndex: "1000",
                  top: "10px",
                  right: "10px",
                  width: "32px",
                  height: "32px",
                }
          }
          className="position-absolute whiteColor pointer svg-force-size "
        >
          <SVGx />
        </div>
        <VimeoHolderAsider
          resultObject={findPrevAndPost()}
          reduxStoreVimeo={reduxStoreVimeo}
        />
        <VimeoHolder reduxStoreVimeo={reduxStoreVimeo} />
      </div>
    )
  );
}

export function VimeoHolder({ reduxStoreVimeo }) {
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");


  return (
    <div
      id="vimeo--holder"
      className=" shadow position-relative vh-100 d-flex justify-content-center mt-2"
      style={{ zIndex: "999", alignItems: "center" }}
    >
      <div
        style={
          biggerThan1921
            ? { width: "538px", height: "896px" }
            : { width: "338px", height: "696px" }
        }
        className=" position-relative"
      >
        <div
          style={
            biggerThan1921
              ? {
                  width: "508px",
                  height: "961px",
                  left: "15px",
                  justifyItems: "center",
                }
              : { width: "338px", height: "696px" }
          }
          className=" position-absolute "
        >
          <div
            style={
              biggerThan1921
                ? { zIndex: "900", top: "15px" }
                : { zIndex: "900", top: "60px" }
            }
            className=" d-flex justify-content-center  w-100 position-absolute"
          >
            <Vimeo
              speed={true}
              showTitle={true}
              showPortrait={false}
              controls={true}
              autoplay={false}
              showByline={true}
              video={`https://player.vimeo.com/video/${
                reduxStoreVimeo?.id ? reduxStoreVimeo?.id : "658693857"
              }?h=${
                reduxStoreVimeo?.key ? reduxStoreVimeo?.key : "c5b977e224"
              }&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`}
            />
          </div>
        </div>
      </div>
      
    </div>
  );
}

export function VimeoHolderAsider({ reduxStoreVimeo, resultObject }) {
  const findId = (array, index) => {
    return array[index].id;
  };

  const findKey = (array, index) => {
    return array[index].key;
  };
  
  const onVoid = (array, index) => {
    return (array[index]?.id == "") || (array[index]?.key == "");
  };

  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");

  return (
    <div
      style={biggerThan1921 ? { top: "25%" } : { top: "30%" }}
      className=" w-100 d-flex justify-content-center  position-absolute "
    >
      <div className=" position-relative  ">
        <div id="vimeo-asider" className=" position-relative  ">
          {
            (onVoid(
              reduxStoreVimeo.array,
              resultObject.left
            ) ) &&
            <div className=" invisible" style={{width: "345px"}} >

              </div>
          }
          <Vimeo
            speed={true}
            showTitle={true}
            showPortrait={false}
            controls={false}
            autoplay={false}
            showByline={true}
            video={`https://player.vimeo.com/video/${findId(
              reduxStoreVimeo.array,
              resultObject.left
            )}?h=${findKey(
              reduxStoreVimeo.array,
              resultObject.left
            )}&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`}
          />
        </div>
      </div>
      {biggerThan1921 ? (
          <div style={{ width: "700px" }}></div>
      ) : (
          <div style={{ width: "450px" }}></div>
      )}

      <div className=" position-relative ">
        <div id="vimeo-asider" className=" position-relative ">
          <Vimeo
            speed={true}
            showTitle={true}
            showPortrait={false}
            controls={false}
            autoplay={false}
            showByline={true}
            video={`https://player.vimeo.com/video/${findId(
              reduxStoreVimeo.array,
              resultObject.right
            )}?h=${findKey(
              reduxStoreVimeo.array,
              resultObject.right
            )}&controls=false&autoplay=true&playsinline=false&allowfullscreen=true&portrait=false`}
          />
        </div>
      </div>
    </div>
  );
}

export function VimeoRedCloseZone({ onClose }) {
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");

  return (
    <>
      <div
        onClick={onClose}
        style={
          biggerThan1921
            ? { width: "33%", zIndex: "99999" }
            : { width: "26%", zIndex: "99999" }
        }
        className=" position-absolute  h-100 "
      ></div>
      <div
        onClick={onClose}
        style={
          biggerThan1921
            ? { width: "33%", zIndex: "99999", right: "0px" }
            : { width: "26%", zIndex: "99999", right: "0px" }
        }
        className=" position-absolute  h-100 "
      ></div>
    </>
  );
}

export function VimeoBtnController({ reduxStoreVimeo }) {
  const dispatch = useDispatch();

  const onClick = (direction, string) => {
    let found = reduxStoreVimeo.array.indexOf(
      reduxStoreVimeo.array.find((elm) => elm.id == reduxStoreVimeo.id)
    );

    let aux = null;

    if (found == 0 && string == "reduce") {
      aux = reduxStoreVimeo.array[reduxStoreVimeo.array.length - 1];
    }

    if (found >= reduxStoreVimeo.array.length - 1 && string == "add") {
      aux = reduxStoreVimeo.array[0];
    }

    if (found != 0 && found <= reduxStoreVimeo.array.length - 2) {
      aux = reduxStoreVimeo.array[found + direction];
    }

    if (found == 0 && string == "add") {
      aux = reduxStoreVimeo.array[found + direction];
    }

    dispatch(closeVimeo());

    setTimeout(() => {
      dispatch(openVimeo({ id: aux.id, key: aux.key }));
    }, 100);
  };
  const biggerThan1921 = useMediaPredicate("(min-width: 1800px)");

  return (
    <div
      style={{ top: "50%", height: "36px", right: "11px" }}
      className=" w-100 position-absolute "
    >
      <div className="position-relative d-flex justify-content-center  container--padding w-100 ">
        {biggerThan1921 ? (
            <div
              onClick={() => onClick(-1, "reduce")}
              style={{ zIndex: 1250, width: "42px", height: "42px" }}
              className="position-relative pointer svg-force-size"
            >
              <SVGFilledLeftChev />
            </div>
        ) : (
            <div
              onClick={() => onClick(-1, "reduce")}
              style={{ zIndex: 1250, left: "12px" }}
              className="position-relative pointer"
            >
              <SVGFilledLeftChev />
            </div>
        )}

        <div
          style={biggerThan1921 ? { width: "550px" } : { width: "400px" }}
        ></div>
        {biggerThan1921 ? (
            <div
              onClick={() => onClick(+1, "add")}
              style={{
                zIndex: 1550,
                width: "42px",
                height: "42px",
                left: "16px",
              }}
              className="position-relative pointer svg-force-size"
            >
              <SVGFilledRightChev />
            </div>
        ) : (
            <div
              onClick={() => onClick(+1, "add")}
              style={{ zIndex: 1550, left: "12px" }}
              className="position-relative pointer"
            >
              <SVGFilledRightChev />
            </div>
        )}
      </div>
    </div>
  );
}
