import { FormattedMessage } from "react-intl";

export default function InvitationsDetailsErrorMessage ({stages, details}) {

    
    const showMessage = () => {
        if (stages[3]?.stageDetail?.status != "normal") {
            return  <div style={{ background: "#FCE9E8"}} className=" museo-sans-500 s12 d-flex align-items-center rounded-8 lightRedColor p-3 w-100" >
                <FormattedMessage id="invitation.error.message.one" /> 
            </div>
      
          }
          if (stages[2]?.stageDetail?.status != "normal") {
            return <div style={{ background: "#FCE9E8"}} className=" museo-sans-500 s12 d-flex align-items-center rounded-8 lightRedColor p-3 w-100" >
                <FormattedMessage id="invitation.error.message.two" />
            </div>
          }
          if (stages[1]?.stageDetail?.status != "normal") {
            return <div style={{ background: "#FCE9E8"}} className=" museo-sans-500 s12 d-flex align-items-center rounded-8 lightRedColor p-3 w-100" >
             <FormattedMessage id="invitation.error.message.three" />
            </div>
          }
          if (stages[0]?.stageDetail?.status != "normal") {
            return <div style={{ background: "#FCE9E8"}} className=" museo-sans-500 s12 d-flex align-items-center rounded-8 lightRedColor p-3 w-100" >
            <FormattedMessage id="invitation.error.message.four" />
            </div>
          }
    };


    if (!Array.isArray(stages)) {
        return ""
    }

    return(
        <>
            {
                details ?
                <>
                    <div className="my-1 py-2">
                    {
                        showMessage()
                    }
                    </div>
                </>
                :
                <>  
                    {
                        showMessage()
                    }
                </>
            }
        </>
    )
  }