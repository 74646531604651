import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGMail } from "../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGInstagram } from "../../../common/svgs/ic-instagram-md.svg";
import { ReactComponent as SVGFacebook } from "../../../common/svgs/ic-facebook-md.svg";
import { ReactComponent as SVGEcommerce } from "../../../common/svgs/ic-tienda-virtual-md.svg";

import { ReactComponent as Red } from "../../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as Yellow } from "../../../common/svgs/ic-alert-circle-yellow.svg";
import { ReactComponent as Green } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";

import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg"
import { useNavigate } from "react-router-dom";
import { pushInsideArray } from "../../Framework/redux/DescendantsAction";
import { useDispatch } from "react-redux";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";

export default function DescendantAscendentProfileHeader({ loader, data, score, root }) {

    const history = useNavigate();
    const dispatch = useDispatch();

    const onClickLink = async (root) => {
      await dispatch(pushInsideArray({clientId: root, name: data?.uplineName }))
        history(`/dashboard/descendants/profile/asc/${root}`)
    };

  return (
    <>
      {!loader ? (
        <div id="account-header" style={{height: "20px"}} className="d-flex justify-content-center w-100">
          <div className=" d-flex justify-content-center position-relative">
            <div
              style={{
                top:"-60px",
                width: "160px",
                height: "160px",
                alignItems: "center",
              }}
              className=" outline-profile-picture position-absolute border-1 rounded-circle bg-transparent d-flex justify-content-center "
            >
              <ImageCircleHandler width={"150px"} height={"150px"} url={data.image} name={data?.name} firstName={data?.firstName} lastName={data?.lastName} size={"40px"} top={2} />
              
            </div>
            <div className="mt-5 pt-5 d-flex flex-column text-center" >
                <div style={{height: "8px"}} className="py-1 invisible" >
                    
                    .
                </div>
                <p className="s30 museo-sans-700" >
                    {data.name}
                </p>
                <div className="s24 pb-4 museo-sans-700 d-flex gap-2 justify-content-center text" >
                    <p>
                      <ReformatLevelByGender gender={data?.gender} level={data?.level} roleDescriptionDefault={data?.roleDescription} />
                    </p>
                    <div style={{width: "16px" , height: "16px"}} >
                {score?.consultantStatus?.checkMarkStatus === "reduced" && <Purple />}
                {score?.consultantStatus?.checkMarkStatus === "risk" && <Red />}
                {score?.consultantStatus?.checkMarkStatus === "completed" && <Green />}
                {score?.consultantStatus?.checkMarkStatus === "warning"&& (<Yellow />)}
            </div>
                </div>
                <div className=" d-flex flex-column align-content-center" >
                <div className="s14 museo-sans-500 pb-2 d-flex justify-content-center">
                <p>ID {root ? root : localStorage.getItem("clientId")} |</p>
                <p className=" invisible">a</p>
                <p className=" text-capitalize ">
                  {" "}
                  <FormattedMessage id="account.header.region" />{" "}
                  {data.region}
                </p>
              </div>
              {
                data?.uplineName ?
                <div className=" gap-1 s14 d-flex align-items-center justify-content-center">
                        <p className=" grayColor museo-sans-500" >
                            <FormattedMessage id="performance.user.tooltip.ascendant" />
                        </p>
                        {
                          data?.uplineId == 1 ?
                            <div className="  d-flex align-items-center " >
                        <p className=" pe-1 grayColor museo-sans-700" >
                            <FormattedMessage id="no.asc" />
                        </p>

                            </div>
                          :
                            <div onClick={() => onClickLink(data?.uplineId)} className=" pointer d-flex align-items-center " >
                            <p className=" pe-1 skyColor museo-sans-700" >
                                {data?.uplineName}
                            </p>
                                <SVGRightArrow />

                                </div>
                        }
                </div>
                :
                <>
                </>
              }
              <hr style={{width: "377px"}} ></hr>
              <div className="d-flex justify-content-center gap-4 " >
              {
                    data.socialNetwork &&
                    <MapSocialNetwork socialArray={data?.socialNetwork} />
                }
              </div>
                </div>
            </div>
          </div>
        </div>
      ) :
      (
        
        <div id="account-header" style={{height: "20px"}} className="d-flex justify-content-center w-100">
          <div className=" d-flex justify-content-center position-relative">
            <div
              style={{
                top:"-60px",
                width: "160px",
                height: "160px",
                alignItems: "center",
              }}
              className=" outline-profile-picture position-absolute border-1 rounded-circle bg-transparent d-flex justify-content-center "
            >
              <ImageCircleHandler width={"150px"} height={"150px"}  size={"40px"} top={2} />
              
            </div>
            <div className="mt-5 pt-5 d-flex flex-column text-center" >
                <div style={{height: "8px"}} className="py-1 invisible" >
                    
                    .
                </div>
                <div style={{height: "24px"}}  className=" mb-1 s30 brightGrayBg brightGrayColor rounded-5 museo-sans-700" >
                  <p className=" invisible" >
                      {data.name}
                  </p>
                </div>
                <div style={{height: "16px"}}  className=" my-1 s30 brightGrayBg brightGrayColor rounded-5 museo-sans-700" >
                    <p className=" invisible" >
                        <ReformatLevelByGender gender={data?.gender} level={data?.level} roleDescriptionDefault={data?.roleDescription} />
                    </p>
                    <div className=" invisible" style={{width: "16px" , height: "16px"}} >
                    {score?.consultantStatus?.checkMarkStatus === "reduced" && <Purple />}
                {score?.consultantStatus?.checkMarkStatus === "risk" && <Red />}
                {score?.consultantStatus?.checkMarkStatus === "completed" && <Green />}
                {score?.consultantStatus?.checkMarkStatus === "warning"  && (
                    <Yellow />
                    )}
            </div>
                </div>
                <div className=" d-flex flex-column align-content-center" >
                <div className="s14 museo-sans-500 pb-2 d-flex justify-content-center">
                  <span className="d-flex justify-content-center  brightGrayBg brightGrayColor rounded-5" >
                    <p className="" >ID {root ? root : localStorage.getItem("clientId")} |</p>
                    <p className=" invisible">a</p>
                    <p className=" text-capitalize ">
                      {" "}
                      <FormattedMessage id="account.header.region" />{" "}
                    {data.region}
                    </p>

                  </span>
              </div>
              {
                data?.uplineName ?
                <div className=" gap-1 s14  brightGrayBg brightGrayColor rounded-5 d-flex align-items-center justify-content-center">
                        <p className=" invisible grayColor museo-sans-500" >
                            <FormattedMessage id="performance.user.tooltip.ascendant" />
                        </p>
                        {
                          data?.uplineId == 1 ?
                            <div className=" invisible  d-flex align-items-center " >
                        <p className=" pe-1 grayColor museo-sans-700" >
                            <FormattedMessage id="no.asc" />
                        </p>

                            </div>
                          :
                            <div onClick={() => onClickLink(data?.uplineId)} className=" invisible pointer d-flex align-items-center " >
                            <p className=" pe-1 skyColor museo-sans-700" >
                                {data?.uplineName}
                            </p>
                                <SVGRightArrow />

                                </div>
                        }
                </div>
                :
                <>
                </>
              }
              <hr style={{width: "377px"}} ></hr>
              <div className="d-flex justify-content-center gap-4 " >
                <div style={{width: "32px", height: "32px"}}  className=" rounded-circle brightGrayBg " >

                </div>
                <div style={{width: "32px", height: "32px"}}  className=" rounded-circle brightGrayBg " >

                </div>
                <div style={{width: "32px", height: "32px"}}  className=" rounded-circle brightGrayBg " >

                </div>
                <div style={{width: "32px", height: "32px"}}  className=" rounded-circle brightGrayBg " >

                </div>
                <div style={{width: "32px", height: "32px"}}  className=" rounded-circle brightGrayBg " >

                </div>
              </div>
                </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export function MapSocialNetwork({ socialArray }) {

  const sharedDataPhoneText = ""
    
  const sharedData = ""

  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  return (
    <>
      {configExist("email") !== -1 && (
        <a
          href={`mailto:${
            socialArray[configExist("email")].value
          }?&body=`}
          
          target="_blank"
          className=" grayColor cancelBootstrap"
        >

              <SVGMail />
        </a>
      )}
      {configExist("whatsapp") !== -1 && (
        <a
          href={`https://wa.me/${
            socialArray[configExist("whatsapp")].value
          }?text=`}
          target="_blank"
          className=" bronceStroke grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGWhatsap />
          </div>
        </a>
      )}
      {configExist("messenger") !== -1 && (
        <a
          href={`https://m.me/${
            socialArray[configExist("messenger")].value
          }?text=`}
          target="_blank"
          className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGMessenger />
          </div>
        </a>
      )}
      {configExist("facebook") !== -1 && (
        <a
          href={`${socialArray[configExist("facebook")].value}`}
          target="_blank"
          className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGFacebook />
          </div>
        </a>
      )}
      {configExist("instagram") !== -1 && (
        <a
          href={`https://www.instagram.com/${
            socialArray[configExist("instagram")].value
          }`}
          target="_blank"
          className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGInstagram />
          </div>
        </a>
      )}
      {configExist("tienda_virtual") !== -1 && (
        <a
        href={`${socialArray[configExist("tienda_virtual")].value}`}
        target="_blank"
        className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGEcommerce />
          </div>
        </a>
      )}
    </>
  );
}
