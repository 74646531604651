import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as TriangleWarningIcon } from "../../../common/svgs/ic-triangle-warning-sm.svg";
import GroupIncorpDescendantUserTooltip from "./GroupIncorpDescendantUserTooltip";
import GroupIncorpDescendantBilledTooltip from "./GroupIncorpDescendantBilledTooltip";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";

export default function GroupSalesRequestRow({ data }) {
  const [headerTogglerBtn, setHeaderTogglerBtn] = useState(false);
  const [billedToggler, setBilledToggler] = useState(false);
  const [tooltipOffset, setTooltipOffset] = useState(0);
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const togglePlusModal = (evt) => {
    evt.preventDefault();
    setHeaderTogglerBtn(!headerTogglerBtn);
  };

  const ifWarningIsActive = (actual, status) => {
    return actual < 0 || status === "warning";
  };

  const handleMouseEnter = () => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const tooltipHeight = tooltipRef.current.offsetHeight;
    const containerHeight = containerRect.height;

    const spaceBelow = window.innerHeight - containerRect.bottom;
    const spaceAbove = containerRect.top;

    if (spaceBelow >= tooltipHeight) {
      setTooltipOffset(containerHeight);
    } else if (spaceAbove >= tooltipHeight) {
      setTooltipOffset(-tooltipHeight);
    }
  };

  return (
    <div className="d-flex s12 museo-sans-500 border-bottom border-1 justify-content-between align-items-center row-height hover-pointer group-sales-row-height">
      <div className="h-100 d-flex dm-sans-medium s14 blackColor performance-name-request-status-width">
        <div
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
          className="d-flex align-items-center position-relative tooltip-container"
          onClick={togglePlusModal}
        >
          <ImageCircleHandler
            name={data?.profileResume?.name}
            size={"10px"}
            width={"32px"}
            url={data?.profileResume?.image}
            firstName={data?.profileResume?.firstName}
            lastName={data?.profileResume?.lastName}
          />
          <div className="d-flex flex-column ms-2">
            <p className="museo-sans-700 s14 blackColor">
              {data?.profileResume?.lastName +
                " " +
                data?.profileResume?.firstName}
            </p>
            <p className="museo-sans-500 s14 grayColor">
              <ReformatLevelByGender
                gender={data?.profileResume?.gender}
                level={data?.profileResume?.level}
                roleDescriptionDefault={data?.profileResume?.roleDescription}
              />
            </p>
          </div>
          <GroupIncorpDescendantUserTooltip
            toggler={setHeaderTogglerBtn}
            tooltipRef={tooltipRef}
            top={tooltipOffset}
            userInfo={data}
          />
        </div>
      </div>

      <div
        className={`dm-sans-medium s14 d-flex justify-content-center performance-not-sent-width ${
          data?.orderStatusAmounts[0]?.amount === 0
            ? "brightGrayColor"
            : "grayColor"
        }`}
      >
        {formatAmount(
          data?.orderStatusAmounts[0]?.amount,
          cultureInfo?.languageCode,
          cultureInfo?.defaultCurrencyCode
        )}
      </div>

      <div
        className={`dm-sans-medium s14 d-flex justify-content-center performance-to-invoice-width ${
          data?.orderStatusAmounts[1]?.amount === 0
            ? "brightGrayColor"
            : "grayColor"
        }`}
      >
        {formatAmount(
          data?.orderStatusAmounts[1]?.amount,
          cultureInfo?.languageCode,
          cultureInfo?.defaultCurrencyCode
        )}
      </div>

      <div className="dm-sans-medium s14 button-hover-svg-light-stroke d-flex justify-content-center blackColor performance-billed-width">
        {formatAmount(
          data?.balanceSummary?.billed?.amount,
          cultureInfo?.languageCode,
          cultureInfo?.defaultCurrencyCode
        )}
      </div>

      <div
        className={`dm-sans-medium s14 d-flex justify-content-center performance-balance-width ${
          ifWarningIsActive(
            data?.balanceSummary?.balance?.amount,
            data?.balanceSummary?.balance?.status
          )
            ? "darkYellowColor"
            : "blackColor"
        }`}
      >
        <p className="me-1">
          {formatAmount(
            data?.balanceSummary?.balance?.amount,
            cultureInfo?.languageCode,
            cultureInfo?.defaultCurrencyCode
          )}
        </p>
        {ifWarningIsActive(
          data?.balanceSummary?.balance?.amount,
          data?.balanceSummary?.balance?.status
        ) ? (
          <div className="position-relative bill-tooltip-container">
            <TriangleWarningIcon />
            <GroupIncorpDescendantBilledTooltip
              value={formatAmount(
                data?.balanceSummary?.balance?.amount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
              toggler={setBilledToggler}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
