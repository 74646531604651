import { useSelector, useDispatch } from "react-redux";
import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import { ReactComponent as CloseIcon } from "../../../../../common/svgs/ic-x-sm.svg";
import { defineStepNumber } from "../../../../Framework/redux/dashboardAction";

import { ReactComponent as SVGBell } from "../../../../../common/svgs/ic-bell-lg.svg";

export default function FifthStepModal({ removeBlur }) {
  const biggerThan1920 = useMediaPredicate("(min-width: 1799px)");
  const biggerThan1900 = useMediaPredicate("(min-width: 1900px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.userInfo.userInfo);

  const styles = {
    s4: { right: "4%", top: "100px", width: "355px", height: "205px" },
    s3: { right: "2.6%", top: "100px", width: "355px", height: "205px" },
    s2: { right: "53px", top: "100px", width: "355px", height: "205px" },
    s1: { right: "34px", top: "100px", width: "355px", height: "205px" },
  };
  
  const getStyle = () => {
    if (biggerThan1900) return styles.s4;
    if (biggerThan1920) return styles.s3;
    if (biggerThan1280) return styles.s2;
    return styles.s1;
  };

  const onCancelBlur = () => {
    removeBlur();
    dispatch(defineStepNumber(20));
    localStorage.removeItem("firstLogin");
  };

  const onCancelOnlyBlur = () => {
    removeBlur();
    dispatch(defineStepNumber(20));
  };

  return (
    <div
      className="modal fade vh-100 vw-100"
      id="fifthStepModal"
      data-bs-backdrop="static"
      aria-hidden="true"
      aria-labelledby="fifthStepModal"
      tabIndex="-1"
    >
      <DoppelgangerGroupGen />
      <div
        className="modal-dialog m-0 position-fixed"
        style={
            getStyle()
        }
      >
        <div
          className="modal-content h-100"
          style={{ padding: "25px", borderRadius: "15px" }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="museo-sans-500 s12 grayColor">
                {profileData.level < 2 ? "PASO 4 DE 4" : "PASO 5 DE 5"}
              </p>
              <div
                className=" pointer "
                data-bs-dismiss="modal"
                onClick={onCancelOnlyBlur}
                aria-label="Close"
              >
                <CloseIcon />
              </div>
            </div>
            <p className="museo-sans-700 s20 blackColor">
              <FormattedMessage id="on.boarding.fifth.title.one" />
            </p>
            <div className="mt-2" style={{ lineHeight: "18px" }}>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ width: "272px" }}
              >
                <FormattedMessage id="on.boarding.fifth.p.one" />
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-auto">
            <a
              onClick={() => onCancelBlur()}
              data-bs-dismiss="modal"
              aria-label="Close"
              role="button"
              className=" invisible museo-sans-700 s14 skyColor button-hover-light-text"
            >
              <FormattedMessage id="buttons.not.show.ever" />
            </a>
            <div
              onClick={() => onCancelBlur()}
              className="d-flex hover-pointer align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
              data-bs-target="#exampleModalToggle2"
              data-bs-toggle="modal"
            >
              <p className=" text-capitalize museo-sans-700 s14 skyColor button-hover-light-text">
                <FormattedMessage id="buttons.understood" />
              </p>
            </div>
          </div>
        </div>
        <div
          style={{ top: "-20px", left: "300px", transform: "rotate(90deg)" }}
          className=" modal-arrow "
        ></div>
      </div>
    </div>
  );
}

export function DoppelgangerGroupGen(params) {
  const biggerThan1920 = useMediaPredicate("(min-width: 1799px)");

  const biggerThan1900 = useMediaPredicate("(min-width: 1900px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");

  const headerData = useSelector((state) => state?.headerData);

  const baseStyles = {
    top: "10px",
    right: "-11px",
    width: "60px",
    height: "60px",
  };
  
  const styles = {
    s4: { ...baseStyles, right: "4%" },
    s3: { ...baseStyles, right: "2.6%" },
    s2: { ...baseStyles, right: "53px" },
    s1: { ...baseStyles, right: "34px" },
  };
  
  const getStyle = (biggerThan1900, biggerThan1920, biggerThan1280) => {
    if (biggerThan1900) return styles.s4;
    if (biggerThan1920) return styles.s3;
    if (biggerThan1280) return styles.s2;
    return styles.s1;
  };
  
  return (
    <div className="h-100 w-100 position-absolute container--padding">
      <div className="position-relative">
        <div
          style={getStyle(biggerThan1900, biggerThan1920, biggerThan1280)}
          className="position-absolute whiteBg rounded-3 p-1"
        >
          <div className="whiteBg position-absolute w-100 h-100 pulsing"></div>
          <div className="position-relative d-flex justify-content-center align-items-center h-100">
            <div>
              <div className="m-0 position-absolute dm-sans-medium s14 text-white text-center" id="hover-counter" style={{ background: "#f06e69", right: "0px" }}>
                {headerData?.badgeCounter?.badges > 99 ? "99+" : headerData?.badgeCounter?.badges}
              </div>
              <div style={{ cursor: "pointer" }} className="pointer">
                <SVGBell />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
