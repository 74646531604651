

export default function EDBDetailsIconLock({ data }) {


  const styleChange = (position) => {
      switch (position) {
        case 1:
          return {right: "64%",
      top: "-8px",}
      
        default:
          return {right: "32%",
      top: "-8px",}
      }

  }


  const lockOne = () => {

    return (
      data ? (
        data.accumulatedAmount >=
        data.hostProgressLevel[0].hostTargetAmount ? (
          <>
            <div
              style={{
                width: "25px",
                height: "25px",
              }}
              className=" rounded-circle lightGreenBg d-flex justify-content-center  "
            >
              <i className=" common-cursor bi bi-check-lg whiteColor"></i>
            </div>
            <p className="s12 greenColor dm-sans-medium ">
              {data ? data.hostProgressLevel[0].percentage + "%" : "10%"}
            </p>
          </>
        ) : (
          <>
            <div
              style={{
                width: "25px",
                height: "25px",
                right: "10px",
                top: "-2px",
              }}
              className=" rounded-circle brightGrayBg d-flex justify-content-center  "
            >
              <i className=" common-cursor bi bi-lock-fill grayColor"></i>
            </div>
            <p className="s12 grayColor dm-sans-medium ">
              {data ? data.hostProgressLevel[0].percentage + "%" : "10%"}
            </p>
          </>
        )
      ) : (
        <>
          <div
            style={{
              width: "25px",
              height: "25px",
              right: "10px",
              top: "-2px",
            }}
            className=" rounded-circle lightGreenBg d-flex justify-content-center  "
            >
              <i className=" common-cursor bi bi-check-lg whiteColor"></i>
          </div>
          <p className="s12 greenColor dm-sans-medium ">
            {data ? data.hostProgressLevel[0].percentage + "%" : "10%"}
          </p>
        </>
      )
    )
  }

  const lockTwo = () => {
    
    return (
      data ? (
        data.accumulatedAmount >=
        data.hostProgressLevel[1].hostTargetAmount ? (
          <>
            <div
              style={{
                width: "25px",
                height: "25px",
              }}
              className=" rounded-circle lightGreenBg d-flex justify-content-center  "
            >
              <i className=" common-cursor bi bi-check-lg whiteColor"></i>
            </div>
            <p className="s12 grayColor dm-sans-medium ">
              {data ? data.hostProgressLevel[1].percentage + "%" : "15%"}
            </p>
          </>
        ) : (
          <>
            <div
              style={{
                width: "25px",
                height: "25px",
                right: "0px",
                top: "-2px",
              }}
              className=" rounded-circle brightGrayBg d-flex justify-content-center  "
            >
              <i className=" common-cursor bi bi-lock-fill grayColor"></i>
            </div>
            <p className="s12 grayColor dm-sans-medium ">
            {data ? data.hostProgressLevel[1].percentage + "%" : "15%"}
            </p>
          </>
        )
      ) : (
        <>
          <div
            style={{
              width: "25px",
              height: "25px",
              right: "0px",
              top: "-2px",
            }}
            className=" rounded-circle brightGrayBg d-flex justify-content-center  "
          >
            <i className=" common-cursor bi bi-lock-fill grayColor"></i>
          </div>
          <p className="s12 grayColor dm-sans-medium ">
              {data ? data.hostProgressLevel[1].percentage + "%" : "15%"}
          </p>
        </>
      )
    )
  }

  const lockThree = () => {
    
    return (
      data ? (
        data.accumulatedAmount >=
        data.hostProgressLevel[2].hostTargetAmount ? (
          <>
            <div
              style={{
                width: "25px",
                height: "25px",
              }}
              className=" rounded-circle lightGreenBg d-flex justify-content-center  "
            >
              <i className=" common-cursor bi bi-check-lg whiteColor"></i>
            </div>
            <p className="s12 grayColor dm-sans-medium ">
              {data ? data.hostProgressLevel[2].percentage + "%" : "20%"}
            </p>
          </>
        ) : (
          <>
            <div
              style={{
                width: "25px",
                height: "25px",
                right: "0px",
                top: "-2px",
              }}
              className=" rounded-circle brightGrayBg d-flex justify-content-center  "
            >
              <i className=" common-cursor bi bi-lock-fill grayColor"></i>
            </div>
            <p className="s12 grayColor dm-sans-medium ">
              {data ? data.hostProgressLevel[2].percentage + "%" : "20%"}
            </p>
          </>
        )
      ) : (
        <>
          <div
            style={{
              width: "25px",
              height: "25px",
              right: "0px",
              top: "-2px",
            }}
            className=" rounded-circle brightGrayBg d-flex justify-content-center  "
          >
            <i className="bi bi-lock-fill grayColor"></i>
          </div>
          <p className="s12 grayColor dm-sans-medium ">
            {data ? data.hostProgressLevel[2].percentage + "%" : "20%"}
          </p>
        </>
      )
    )
  }

  return (
    <>
    {data ? 
    <>
      <div
        style={styleChange(1)}
        className=" dm-sans-medium position-absolute d-flex flex-column justify-content-center"
      >
        {lockOne()}
      </div>
      <div
        style={styleChange(2)}
        className=" dm-sans-medium position-absolute d-flex flex-column justify-content-center"
      >
        {lockTwo()}
      </div>
      <div
        style={{
          right: "0px",
          top: "-8px",
        }}
        className=" dm-sans-medium position-absolute d-flex flex-column justify-content-center"
      >
        {lockThree()}
      </div>
    </>
      :
        null
    }
      
    </>
  );
}
