import React from 'react'

import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

import qualifiers from '../../../common/img/performanceImages/tooltip-qualifiers.png'
import details from '../../../common/img/performanceImages/tooltip-details2x.png'
import HelpIconNoTarget from '../../../common/Share/HelpIconNoTarget';
import ModalWhiteShadow from '../../../common/Share/ModalWhiteShadow';
import { useRef } from 'react';

export default function QualifiedGroupsModal({cantidadNecesaria, rol}) {

  const scrollbarRef = useRef(null); 

  return (
    <div
      className="modal fade"
      id="qualifiedGroupsModal"
      aria-hidden="true"
      aria-labelledby="modalInfo"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered help-modal-content"
      >
        <div className="modal-content modal-width modal-radius">
          <div className="modal-body p-0 help-modal-content">
            <div className='h-100'>

              <div className="d-flex justify-content-between align-items-center ps-5 pe-5 help-modal-header-footer">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.title"
                      defaultMessage="Primer párrafo"
                    />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>

              <hr className="m-0"></hr>

              <div ref={scrollbarRef} className="d-flex flex-column align-items-center remove-scrollbar help-modal-info-container">

                <div className="mt-4 px-5">
                  <p className="museo-sans-700 s16 py-2 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.1"
                      defaultMessage="Primer párrafo"
                    />
                  </p>
                  <p className="museo-sans-500 s16 py-2 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.2"
                      defaultMessage="Para que"
                    />{" "}
                    {rol}{", "}<FormattedMessage
                      id="qualified.groups.modal.3"
                      defaultMessage="Para que"
                    />{" "}
                    {cantidadNecesaria}{" "}<FormattedMessage
                      id="qualified.groups.modal.4"
                      defaultMessage="Para que"
                    /> 
                  </p>

                  <p className="museo-sans-500 s16 py-2 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.5"
                      defaultMessage="Primer párrafo"
                    />
                  </p>

                  <p className="museo-sans-700 s16 py-2 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.6"
                      defaultMessage="Primer párrafo"
                    />
                  </p>

                    <img src={qualifiers} alt="Imagen de los calificadores" className='mt-2 mb-3'/>

                  <p className="museo-sans-500 s16 py-2 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.7"
                      defaultMessage="Primer párrafo"
                    />
                  </p>

                  <p className="museo-sans-700 s16 pt-2 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.8"
                      defaultMessage="Primer párrafo"
                    />
                  </p>
                  <p className="museo-sans-700 s16 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.9"
                      defaultMessage="Primer párrafo"
                    />
                  </p>
                  <p className="museo-sans-700 s16 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.10"
                      defaultMessage="Primer párrafo"
                    />
                  </p>

                  <p className="museo-sans-500 s16 py-2 blackColor">
                    <FormattedMessage
                      id="qualified.groups.modal.11"
                      defaultMessage="Primer párrafo"
                    />
                  </p>
                  

                  <img src={details} alt="Imagen de tooltip" className='mt-2 mb-2 w-100'/>
                </div>

              </div>
              <ModalWhiteShadow bottom={"75px"} scrollbarRef={scrollbarRef} />
              <div className='d-flex justify-content-center align-items-center help-modal-header-footer'>
                    <button
                        type="button"
                        className=" btnLoginWidth my-5 museo-sans-700 s14 skyBg whiteColor button-border-none button-hover-light button-bootstrap-radius button-padding help-modal-ok-button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                    <FormattedMessage
                        id="buttons.understood"
                        defaultMessage="Entendido"
                    />
                    </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
