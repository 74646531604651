import React from 'react'
import "../termsAndConditions/politicsModal.css"

import { ReactComponent as SVGClose } from '../../common/svgs/ic-x-lg.svg'

export default function PoliticsModal({ id, title, children }) {

    return (
        <div className="modal fade" id={id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div style={{width: "912px"}} className="modal-dialog modal-dialog-centered modal-dialog-scrollable politics-modal-dialog-styles">
                <div className="modal-content position-relative politics-modal-content-styles">
                    <div style={{height: "112px"}} className="modal-header border-bottom-0 lightWhiteBg modal-header-padding museo-sans-500  s20">
                        <h5 className="modal-title museo-sans-500 s20" id="exampleModalLabel">{title}</h5>
                        <div className="pointer " data-bs-dismiss="modal"aria-label="Close">
                            <SVGClose />
                        </div> 
                    </div>
                    <div className='position-absolute shadow-effect'></div>
                    <div className="modal-body remove-scrollbar modal-body-margin-padding s14 museo-sans-500 cancel-hr--margin ">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}
