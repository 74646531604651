export default function ShareModalStructure(params) {

  return (
      <div
        className="modal fade"
        id={`${params.id}${params.idNumber ? `-${params.idNumber}` : ""}`}
        aria-hidden="true"
        aria-labelledby={`${params.id}${
          params?.idNumber ? `-${params.idNumber}` : ""
        }`}
        tabIndex="-1"
      >
        <div
          id="close-automatic"
          data-bs-dismiss="modal" 
          aria-label="Close"
          className="invisible position-absolute "
        >

        </div>
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: params?.maxWidth || "592px" }}
        >
          <div className="modal-content modal-radius">
            <div
              style={{ minHeight: params?.minHeight || "" }}
              className="modal-body d-flex flex-column p-0"
            >
              {params?.children}
            </div>
          </div>
        </div>
      </div>
  );
}
