export default function ShareKeyGenerator() {

    const generateKey = () => {
        const timestamp = new Date().getTime();
        const randomValue = Math.floor(Math.random() * 1000000);
        return `ID_${timestamp}_${randomValue}`;
    };

    return generateKey();

}