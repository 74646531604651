import React from 'react'

import '../Config/Presentation/pages/Configuration.css'

import { FormattedMessage } from 'react-intl';

export default function ReturnTo({travelFunction, returnTo}) {
  return (
    <div className='d-flex align-items-center museo-sans-700 s14 text-primary hover-pointer' onClick={() => travelFunction(returnTo.value)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
        </svg>
        <p className='mb-0 ps-2'>
            <FormattedMessage
            id={"buttons.back"}
            />
        </p>
    </div>
  )
}
