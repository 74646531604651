import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as CloseIcon } from "../../../../../common/svgs/ic-x-sm.svg";
import { ReactComponent as ArrowRight } from "../../../../../common/svgs/ic-arrow-right-sm.svg";
import { defineStepNumber } from "../../../../Framework/redux/dashboardAction";
import ProgressBarParams from "../../../../../common/Share/ProgressBarParams";

export default function FourthStepModal({ removeBlur }) {
  const biggerThan1920 = useMediaPredicate("(min-width: 1920px)");
  const biggerThan1800 = useMediaPredicate("(min-width: 1800px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.userInfo.userInfo);

  const onCancelBlur = () => {
    removeBlur();

    localStorage.removeItem("firstLogin");
    dispatch(defineStepNumber(20));
  };

  const onCancelOnlyBlur = () => {
    removeBlur();
    dispatch(defineStepNumber(20));
  };

  const baseStyles = {
    width: "355px",
    height: "205px",
  };
  
  const styles = {
    s3: { ...baseStyles, right: "42%", top: "42%" },
    s3A: { ...baseStyles, right: "42%", top: "45%" },
    s2: { ...baseStyles, right: "50%", top: "455px" },
    s1: { ...baseStyles, right: "500px", top: "460px" },
  };
  
  const getStyle = (biggerThan1920, biggerThan1800, biggerThan1280) => {
    if (biggerThan1920) return styles.s3;
    if (biggerThan1800) return styles.s3A;
    if (biggerThan1280) return styles.s2;
    return styles.s1;
  };

  return (
    <div
      className="modal fade vh-100 vw-100"
      id="fourthStepModal"
      data-bs-backdrop="static"
      aria-hidden="true"
      aria-labelledby="fourthStepModal"
      tabIndex="-1"
    >
      <DoppelgangerGroupGen />
      <div
        id="module-four--modal"
        className="modal-dialog m-0 position-fixed"
        style={getStyle(biggerThan1920, biggerThan1800, biggerThan1280)}
      >
        <div
          className="modal-content h-100"
          style={{ padding: "25px", borderRadius: "15px" }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="museo-sans-500 s12 grayColor">
                {profileData.level < 2 ? "PASO 2 DE 4" : "PASO 4 DE 5"}
              </p>
              <div
                className=" pointer "
                data-bs-dismiss="modal"
                onClick={() => onCancelOnlyBlur()}
                aria-label="Close"
              >
                <CloseIcon />
              </div>
            </div>
            <p className="museo-sans-700 s20 blackColor">
              <FormattedMessage id="on.boarding.fourth.title.one" />
            </p>
            <div className="mt-2" style={{ lineHeight: "18px" }}>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ width: "272px" }}
              >
                <FormattedMessage id="on.boarding.fourth.p.one" />
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-auto">
            <a
              onClick={() => onCancelBlur()}
              data-bs-dismiss="modal"
              aria-label="Close"
              role="button"
              className="museo-sans-700 s14 skyColor button-hover-light-text"
            >
              <FormattedMessage id="buttons.not.show.ever" />
            </a>
            <div
              className="d-flex hover-pointer align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
              data-bs-target="#fifthStepModal"
              data-bs-toggle="modal"
            >
              <p className=" text-capitalize museo-sans-700 s14 skyColor button-hover-light-text">
                <FormattedMessage id="buttons.follow" />
              </p>
              <ArrowRight />
            </div>
          </div>
        </div>
        <div className=" modal-arrow modal-arrow-rotate "></div>
      </div>
    </div>
  );
}

export function DoppelgangerGroupGen() {

  const groupData = useSelector((state) => state.scoreData.groups);

  return (
    <div className=" h-100 w-100 position-absolute container--padding ">
      <div className=" position-relative">
        <div
          id="module-four--group-sale"
          className=" position-absolute whiteBg  rounded-3 p-1  "
        >
          <div
            style={{ top: "0px", left: "0px" }}
            className=" position-absolute w-100 h-100 whiteBg pulsing  "
          ></div>
          <div className=" position-relative d-flex justify-content-center align-items-center h-100  ">
            {groupData.array && (
              <div
                id={groupData.array[0]?.clientId}
                data-bs-toggle="modal"
                data-bs-target="#modalQualificationFirstGenA"
                style={{ width: "62px" }}
                className=" blackColor pointer text-center d-flex flex-column"
              >
                <div className="d-flex flex-column gap-2">
                  <ProgressBarParams height={6}
                    level={groupData.array[0]?.profileResume?.level}
                    status={"risk"}
                    incompleted={true}
                    completed={
                      50
                    }
                  />
                  <div className="d-flex gap-1">
                    <ProgressBarParams height={6}
                      completed={100}
                      incompleted={true}
                    />
                    <ProgressBarParams height={6}
                      completed={100}
                      incompleted={true}
                    />
                    <ProgressBarParams height={6}
                      completed={100}
                      incompleted={true}
                    />
                    <ProgressBarParams height={6}
                      completed={0}
                      incompleted={true}
                    />
                  </div>
                  <ProgressBarParams height={6}
                    level={groupData.array[0]?.profileResume?.level}
                    status={"risk"}
                    incompleted={true}
                    completed={
                      50
                    }
                  />
                </div>
                <p
                  style={{ maxHeight: "36px" }}
                  className=" py-1 s12 museo-sans-500 overflow-hidden "
                >
                  Mariana Perez
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
