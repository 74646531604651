import { FormattedMessage } from "react-intl";

export default function ReformatLevelByGender ({gender, level, roleDescriptionDefault}) {

    const levelKeyMap = {
        "1": "one",
        "2": "two",
        "3": "three",
        "4": "four",
        "5": "five",
        "6": "six",
        "7": "seven",
        "8": "eight",
        "9": "nine",
        "10": "ten",
        "11": "eleven",
        "12": "twelve",
      };

      const genderKeyMap = {
        "1": "true",
        "2": "false",
        "3": "false",
        "4": "true",
        "5": "false",
        "6": "true",
        "7": "true",
        "8": "true",
      };

    const switchByLevelGender = (gender, level) => {
        const messageKey = `level.${levelKeyMap[level]}${genderKeyMap[level] === "true" ? `.${gender?.toLowerCase()}` : ""}`
        
        return <FormattedMessage id={messageKey} />;
    };

    return switchByLevelGender(gender, level == "0" ? "1" : level ) || roleDescriptionDefault;
}