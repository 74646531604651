

export default function ShareCenterControlPage(params) {
    return(
        <div style={{background: params?.pageBackground ?? ""}}  >
            <div className="container--padding--card-holder container--padding">
                <div className="">
                    <div className="w-100">
                        {params?.children}
                    </div>
                </div>
            </div>
        </div>
    )
}