import { ShareRadio } from "../../../common/Share/ShareRadio";

export default function EDBsFormRouter({isPut, changeRoute, type}) {

  const onChangeValue = (replace) => {
    if (isPut) {
      return;
    }
    changeRoute(replace);
    localStorage.setItem("formBodyType", replace);
  };

  const arrayRadios = [
    {
      labelId: "edb.types.present",
      disabled: false,
      active: type == "present",
      onClick: () => onChangeValue("present"),
    },
    {
      labelId: "edb.types.virtual",
      disabled: false,
      active: type != "present",
      onClick: () => onChangeValue("virtual"),
    },
  ];
  

  return (
    <div>
      <div className="my-2">
        <div className="d-flex gap-3 py-2 my-1">
            <ShareRadio gap={"8px"} arrayRadios={arrayRadios} />
        </div>
      </div>
    </div>
  );
}