import { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../../common/Share/Loader";

import { ReactComponent as SVGLogin } from "../img/login-icon.svg";
import {ReactComponent as SVGSearch } from "../../../common/svgs/search/ic-search.svg"
import {ReactComponent as SVGTriangle } from "../../../common/svgs/error/circle-alert.svg"
import { useNavigate } from "react-router-dom";
import { fetchclientWithDirectorId } from "../../Framework/redux/loginSideEffects";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";

export default function LoginSearch(params) {
  const [user, setUser] = useState("");
  const [error, setError] = useState(false);
  const [userPerm, setUserPerm] = useState("")
  const directoraInfo = useSelector((state) => state.userInfo.directorInfo );
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const loader = useSelector((state) => state.userInfo.loader );
  const intl = useIntl();
  const history = useNavigate();
  const dispatch = useDispatch();

  const onChangeValue = (evt) => {
    const text = evt.target.value
    setError(false);
    setUser(text);
    setError(false);
    setUserPerm("");
  };

  const onSubmit = async () => {
    if (user.trim() === "") {
        return
    };

    const response = await dispatch(fetchclientWithDirectorId({clientId: user}));
    if (response?.payload?.response?.status) {
      setError(true)
      return
    };
    setUserPerm(user);
  };

  const onDashboard = () => {
    history("/dashboard")
  }

  useEffect(() => {
    let clientId = localStorage.getItem("clientId")
    setUserPerm(clientId);
  }, [userInfo])


  useEffect(() => {
    if (!directoraInfo?.data) {
      history("/")
    }
    setUserPerm("");
  }, [])

  return (
    <div
      id="login-start"
      className=" d-flex flex-column justify-content-center align-items-center container--padding"
    >
      <div className=" text-center ">
        <div
          style={{ width: "100px", height: "66px", marginBottom: "31px" }}
          className=" d-flex w-100 svg-force-size"
        >
          <SVGLogin />
        </div>
        <h1 className=" s40 museo-sans-700">
          <FormattedMessage id="login.welcome" defaultMessage="Welcome!" />
        </h1>
        <h2 className=" museo-sans-700 s16"> {directoraInfo?.data?.name} </h2>
      </div>
      <div id="login-form" className="">
        <div className="form-group position-relative my-2">
          <label className=" museo-sans-700 s12">
            <FormattedMessage id="sale.director.search.label.one" />
          </label>
          <input
            onChange={onChangeValue}
            type="text"
            autoComplete={false}
            id="account"
            className={` dm-sans-500 form-control input-borders blackColor py-2 my-2 s14 ${
              error ? "redBorder" : ""
            } `}
            placeholder={intl.formatMessage({
              id: "login.placeholder.label.one",
              defaultMessage: "Example: 24536",
            })}
          ></input>
          <div
            onClick={onSubmit}
            style={{ right: 8, bottom: 8, width: "24px", height: "24px" }}
            className=" pointer svg-force-size position-absolute "
          >
            <SVGSearch />
          </div>
        </div>
        <p
          style={{ width: "360px" }}
          className=" w-100 text-center museo-sans-500 s14 grayColor "
        >
          <FormattedMessage id="sale.director.search.advise.one" />
        </p>
        <div
          style={{ height: "200px" }}
          className="d-flex flex-column position-relative align-items-center justify-content-center"
        >
          
          <>
            {loader ? (
              <>
                <div className="w-100 d-flex flex-column justify-content-center align-items-center gap-2 " >
            <div style={{width: "72px", height: "72px"}} className=" rounded-circle lightui1-shimmer">

            </div>
            <div style={{width: "130px"}} className="lightui1-shimmer rounded-3 s14 ">
                <p className=" invisible" >
                  Maria 
                </p>
            </div>
            <div style={{width: "240px"}} className="lightui1-shimmer rounded-3 s14 ">
                <p className=" invisible" >
                  Mari
                </p>
            </div>
          </div>
              </>
            ) : (
              <>
                {userPerm ? (
                  <div className="d-flex flex-column align-items-center museo-sans-500">
                    <div>
                      <ImageCircleHandler
                        size={32}
                        width={"78px"}
                        height={"78px"}
                        url={userInfo?.image}
                        firstName={userInfo?.firstName}
                        lastName={userInfo?.lastName}
                        name={userInfo?.name}
                      />
                    </div>
                    <div className="mt-1 s14">{userInfo?.name}</div>
                    <div className="d-flex s12 gap-1 mt-1">
                      <p>ID {userPerm}</p>
                      <div>|</div>
                      <p>{userInfo?.roleDescription}</p>
                    </div>
                  </div>
                ) : (
                  <>
                    {error ? (
                      <>
                        <div
                          style={{ width: "72px", height: "72px" }}
                          className=" svg-force-size"
                        >
                          <SVGTriangle />
                        </div>
                        <div className=" museo-sans-500 text-center ">
                          <p className="museo-sans-500 text-center mt-1 ">
                            <FormattedMessage id="sale.director.search.error.title.one" />
                          </p>
                          <p className=" s12 grayColor ">
                          <FormattedMessage id="sale.director.search.error.message.one" />
                          </p>
                          <p className=" s12 grayColor ">
                          <FormattedMessage id="sale.director.search.error.message.two" />
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <p className="museo-sans-500 text-center mt-1 ">
                          <FormattedMessage id="sale.director.search.not.selected.title" />
                          </p>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        </div>
        <div id="login--btn-holder" className="s14   text-center mt-4">
          <button
            disabled={userPerm ? false : true}
            style={{ borderRadius: "0.5rem" }}
            className={` ${
              userPerm ? "skyBg" : "brightGrayBg"
            } border-0 w-100 position-relative my-3 museo-sans-700 s14 btnLoginWidth p-2 text-white  `}
            onClick={userPerm ? onDashboard : ""}
          >
            {
              loader ?
              <>
                <Loader loading={loader} />{" "}
                <p className=" invisible" >
                  <FormattedMessage id="login.button" defaultMessage="Login" />{" "}
                </p>
              </>
              :
              <>
                <FormattedMessage id="login.button" defaultMessage="Login" />{" "}
              </>
            }
          </button>
        </div>
      </div>
    </div>
  );
}
