import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";

export default function InvitationListRowProgressStep({ restOfData, stepData, index }) {

  return (
      <div
        style={{  width: "127px", height: "32px" }}
        className="position-relative mt-2 "
      >
        <div className=" w-100 h-100 text-center position-relative d-flex flex-column justify-content-center">
          {
            index === 0 ?
            <>
            <p style={{width: "139px", top: -3, left: -5 }} className=" position-absolute  museo-sans-700 s14">
                {stepData?.stageDetail?.state !== "COMPLETED" ? (
                  <>
                    {stepData?.stageDetail?.state !== "DISABLED" ? (
                      <>
                        <span className=" grayColor">
                          {stepData?.stageDescription}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className=" opacity20 grayColor">
                          {stepData?.stageDescription}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>{stepData?.stageDescription}</>
                )}
            </p>
            <p className=" invisible  museo-sans-700 s14">
                {stepData?.stageDetail?.state !== "COMPLETED" ? (
                  <>
                    {stepData?.stageDetail?.state !== "DISABLED" ? (
                      <>
                        <span className=" grayColor">
                        <>{"AAAAAA"}</>
                        </span>
                      </>
                    ) : (
                      <>
                        <span className=" opacity20 grayColor">
                        <>{"AAAAAA"}</>
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>{"AAAAAA"}</>
                )}
            </p>
            </>
            :
              <p className="  museo-sans-700 s14">
                {stepData?.stageDetail?.state !== "COMPLETED" ? (
                  <>
                    {stepData?.stageDetail?.state !== "DISABLED" ? (
                      <>
                        <span className=" grayColor">
                          {stepData?.stageDescription}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className=" opacity20 grayColor">
                          {stepData?.stageDescription}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  <>{stepData?.stageDescription}</>
                )}
              </p>
          }
          {stepData?.stageDetail?.startDate || stepData?.stageDetail?.state === "DISABLED" ? (
            <>
              {
                stepData?.stageDetail?.state === "DISABLED" ?
                <div className=" d-flex position-relative" >
                  <p  className="  museo-sans-700 s12 goldBoldColor ">
                    <FormattedMessage id="invitation.no.kit.msg" />
                  </p>
                  <div style={{right: -20, zIndex: 2, top: -0, width: "16px", height: "16px"}} className=" pointer svg-force-size  position-absolute " >
                    <div 
                    data-bs-toggle="modal"
                    data-bs-target={`#incorporated-${restOfData?.id}`}
                    style={{ top: -5, width: "14px", height: "14px"}} className="svg-force-size position-relative" >
                      <HelpIconNoTarget />
                    </div>
                  </div>
                </div>
                :
                  <p style={ stepData?.stageDetail?.status === "normal" ? { } : { color: "#F06E69"}} className=" museo-sans-500 s12 ">
                    {stepData?.stageDetail?.startDate}
                  </p>
              }
            </>
          ) : (
              <p className=" invisible museo-sans-500 s12 ">EMPTY</p>
          )}
        </div>
      </div>
  );
}