import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { multiLangJSONParse } from "../../../../common/Share/MultiLangJSONParse";
import capFirstLetter from "../../../../common/Share/ShareCapFirstLetter";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { capitalizeString } from "../../../../common/utils/stringUtilities";

export default function InvitationsListTableModal({ id }) {
  const dataDetails = useSelector(
    (state) => state.invitationData?.invitationDetail?.data
  );

  const configExist = (auxId, clientData) => {
    let resolve = -1;
    if (clientData) {
      resolve = clientData && clientData.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const onVoidHide = (value, name) => {
    
    return (
      <div className="d-flex py-2 gap-1 ">
        <p className=" ">
          {
            name == "clave" && (
              <FormattedMessage id="invitation.modal.list.list.one" />
            )
          }
          {name == "firstname" && (
            <FormattedMessage id="personal.sale.details.list.two" />
          )}
          {name == "lastname" && (
            <FormattedMessage id="invitation.modal.list.list.two.lastname" />
          )}
          {name == "birthDate" && (
            <FormattedMessage id="invitation.modal.list.list.three" />
          )}
        </p>{" "}
        :<p className=" museo-sans-700">{onNames(capitalizeString(`${value || " "}`, name))}</p>
      </div>
    );
  };


  const onNames = (value, name) => {
    if (name == "firstname" || name == "lastname") {
      return capitalizeString(value);
    }
    return value;
  };

  return (
    <>
      <div
        className="modal fade"
        id={`modal-details-${id}`}
        aria-hidden="true"
        aria-labelledby={`modal-details-${id}`}
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "592px" }}
        >
          <div className="modal-content modal-radius">
            <div className="modal-body p-0">
              <div>
                <div
                  style={{ marginLeft: "42px", marginRight: "42px" }}
                  className="d-flex justify-content-between pt-4 my-4 "
                >
                  <div className="d-flex align-items-center">
                    <h5 className="museo-sans-700 s20 m-0">
                      <FormattedMessage id="invitation.modal.list.title.one" />
                    </h5>
                  </div>
                  <div
                    className="pointer position-relative svg-force-size "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{ width: "24px", height: "24px", top: -2 }}
                  >
                    <SVGClose />
                  </div>
                </div>
                <hr className="m-0"></hr>
                <div
                  style={{ marginLeft: "42px", marginRight: "42px" }}
                  className="d-flex flex-column gap-2 align-items-center s16 museo-sans-500  "
                >
                  <div className=" d-flex flex-column gap-2 mt-3 pt-1 w-100 museo-sans-500 ">
                    <div>
                      {onVoidHide(
                        dataDetails?.inviteeInfo?.clientProfile?.firstName,
                        "firstname"
                        )}
                      {onVoidHide(
                        dataDetails?.inviteeInfo?.clientProfile?.lastName,
                        "lastname"
                        )}
                        {
                          onVoidHide(dataDetails?.inviteeInfo?.clientProfile?.clientId, "clave")
                        }
                      {onVoidHide(
                        dataDetails?.inviteeInfo?.clientProfile?.birthDate,
                        "birthDate"
                      )}
                    </div>
                    <div className="pt-1">
                      {dataDetails?.inviteeInfo?.clientProfile
                        ?.socialNetwork && (
                        <>
                          <p className=" py-2 museo-sans-700">
                            <FormattedMessage id="invitation.modal.list.contact.data" />
                          </p>
                            <div className="d-flex py-2 gap-1 ">
                              <p className=" ">
                                <FormattedMessage id="settings.user.data.email" />
                                :
                              </p>{" "}
                              <a
                                className=" skyColor museo-sans-700 pointer "
                                href={`mailto:${
                                  dataDetails?.inviteeInfo?.clientProfile
                                    ?.socialNetwork[
                                    configExist(
                                      "email",
                                      dataDetails?.inviteeInfo?.clientProfile
                                        ?.socialNetwork
                                    )
                                  ]?.value
                                }`}
                                target="_blank"
                              >
                                {
                                  dataDetails?.inviteeInfo?.clientProfile
                                    ?.socialNetwork[
                                    configExist(
                                      "email",
                                      dataDetails?.inviteeInfo?.clientProfile
                                        ?.socialNetwork
                                    )
                                  ]?.value
                                }
                              </a>
                            </div>
                            

                              <div className="d-flex py-2 gap-1 ">
                                <p className=" ">
                                  <FormattedMessage id="phone.share" />:
                                </p>{" "}
                                <p className="  museo-sans-700">
                                  {
                                    dataDetails?.inviteeInfo?.clientProfile
                                      ?.socialNetwork[
                                      configExist(
                                        "whatsapp",
                                        dataDetails?.inviteeInfo?.clientProfile
                                          ?.socialNetwork
                                      )
                                    ]?.value
                                  }
                                </p>
                              </div>
                           
                        </>
                      )}
                    </div>
                    <hr />
                    <div className="d-flex flex-column py-2">
                      <p className=" museo-sans-700 pb-2">
                        <FormattedMessage id="invitation.data.title" />
                      </p>
                      <div className=" museo-sans-500">
                        {
                          dataDetails?.inviteeInfo?.firstName &&
                          <p className=" text-capitalize">
                            <FormattedMessage id="personal.sale.details.list.two" />:{" "}
                            {capitalizeString(
                              `${dataDetails?.inviteeInfo?.firstName}`
                            )}
                          </p>

                        }
                          <p>
                            <FormattedMessage id="invitation.reference" />:{" "}
                            { capFirstLetter(dataDetails?.inviteeInfo?.reference || "")}
                          </p>
                      </div>
                    </div>
                    <div className="py-3 s14 grayColor mb-3">
                      <p className=" py-2 my-1 s14 museo-sans-500 text-center">
                        <FormattedMessage id="invitation.modal.list.last.message" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
