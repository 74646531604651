import React from "react"

import ImageCircleHandler from "../../../../common/Share/ImageCircleHandler";

export default function ClientListRowSkeleton() {
  return (
    <div className=" w-100 py-4 border-bottom d-flex align-items-center museo-sans-500 s14 justify-content-between pe-4">
      <div className="d-flex align-items-center" style={{ height:  40 }}>
        <ImageCircleHandler
          fontFamily={'museo-sans-500'}
          firstName={' '}
          lastName={" "}
          width={32}
          height={32}
          size={14}
          styles={{
            marginRight: 22
          }}
        />
        <div className="lightui1-shimmer rounded-3" style={{ height: 20, width: 180 }} />
      </div>
      <div className="lightui1-shimmer rounded-3" style={{ width: 12, height: 20 }} />
    </div>
  )
}