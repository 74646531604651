import { useDispatch, useSelector } from "react-redux";
import AccountBanner from "../components/AccountBanner";
import "./Account.css";

import { useEffect, useState } from "react";
import {
  fetchBalance,
  fetchMetrics,
} from "../../Framework/redux/AccountSideEffect";
import AccountPlan from "../components/AccountPlan";
import AccountGraph from "../components/AccountGraph";
import AccountBalance from "../components/AccountBalance";
import AccountHeader from "../components/AccountHeader";
import GoodPracticeModalPlan from "../../../GoodPractice/Presentation/components/GoodPracticeModalPlan";
import AccountModalHistory from "../components/AccountModalHistory";
import AccountModalShare from "../components/AccountModalShare";
import { initArrays, putArrId } from "../../Framework/redux/AccountAction";
import ModalClipboard from "../../../common/Share/ModalClipboard";
import {
  getQualifiedGroup,
  getScoreComparison,
} from "../../../BusinessPlan/Framework/redux/BusinessSideEffect";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";

export default function Account() {
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const periodReducer = useSelector((state) => state.periodBalanceData);
  const comparingData = useSelector((state) => state.businessData.data);
  const qualifiedGroups = useSelector(
    (state) => state.businessData.qualifiedGroups
  );
  const profileLevel = useSelector((state) => state.userInfo.userInfo.level);

  const [graphToggler, setGraphToggler] = useState({
    toggleOne: true,
    toggleTwo: false,
    toggleThree: false,
    toggleFour: false,
  });
  const [copyToggler, setCopyToggler] = useState(false);

  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  const comparisonByLevel = (level) => {
    if (level) {
      return parseInt(level) < 2 ? 2 : parseInt(level) + 1;
    } else {
      return 8;
    }
  };

  const onStartUp = async () => {
    setLoader(true);
    let levelToCompare = profileLevel;
    levelToCompare++;
    const root = localStorage.getItem("clientId");

    try {
      dispatch(
        getScoreComparison(
          profileLevel != 0
            ? { level: comparisonByLevel(profileLevel) }
            : { level: 2 }
        )
      );
      dispatch(
        getQualifiedGroup(
          profileLevel != 0
            ? { level: comparisonByLevel(profileLevel) }
            : { level: 2 }
        )
      );
      dispatch(fetchBalance({ root: root.id }));
      let resolved = await dispatch(fetchMetrics({ root: root.id }));
      dispatch(initArrays());
      if (resolved?.payload?.metrics?.length == 1) {
        dispatch(putArrId("0"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const onClickToggle = (pointer) => {
    setGraphToggler({
      toggleOne: pointer === 1,
      toggleTwo: pointer === 2,
      toggleThree: pointer === 3,
      toggleFour: pointer === 4,
    });
  };

  const onClickDate = (id) => {
    dispatch(putArrId(id));
  };

  const onCloseCopyModal = () => {
    setCopyToggler(false);
  };

  const onOpenCopyModal = () => {
    setCopyToggler(true);
  };

  useEffect(() => {
    onStartUp();
  }, [profileLevel]);

  const directorId = localStorage.getItem("directorId");

  return (
    <div className="">
      <div
        id="account-container"
        style={{ minHeight: "100vh" }}
        className="blackColor "
      >
        <ModalClipboard show={copyToggler} close={onCloseCopyModal} />
        <ShareModalStructure id={"modalInfoPlan"} maxWidth={"600px"}>
          <GoodPracticeModalPlan />
        </ShareModalStructure>
        <ShareModalStructure id={"modalHistory"} maxWidth={"600px"}>
          <AccountModalHistory />
        </ShareModalStructure>
        <ShareModalStructure id={"modalShare"} maxWidth={"556px"}>
          <AccountModalShare
            socialArray={profileData.socialNetwork}
            user={profileData}
            open={onOpenCopyModal}
          />
        </ShareModalStructure>

        <div className=" w-100">
          <AccountBanner profileData={profileData} loading={loader} />
          <div className="container--padding--card-holder">
            <AccountHeader profileData={profileData} loading={loader} />
          </div>
        </div>
        <div className="container--padding--card-holder">
          <div className=" container--padding container--no-padding">
            <div id="account-body" className=" w-100 py-4 mt-5">
              <AccountPlan
                scoreQualifiedGroups={qualifiedGroups}
                scoreData={comparingData}
                profileData={profileData}
                account={true}
                status={comparingData?.consultantStatus?.checkMarkStatus}
                loading={loader}
                directorId={directorId}
              />
              <AccountGraph
                profileData={profileData}
                onClickToggle={onClickToggle}
                graphToggler={graphToggler}
                periodReducer={periodReducer}
                loading={loader}
                onClickDate={onClickDate}
              />
              <AccountBalance periodReducer={periodReducer} loading={loader} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
