import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Webcam from "react-webcam";
import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGCamera } from "../../../common/svgs/camera/ic-camera-sm.svg";

export default function LoginProfileCameraModal({ closeCamera, selectedForCapture}) {

  const [arrayPhotos, setArrayPhotos] = useState([]);
  const [componentKey, setComponentKey] = useState(Math.random());
  const [chooseImage, setChooseImage] = useState()

  const handleCapture = (screenshot) => {
    let auxArray = arrayPhotos;
    auxArray.push(screenshot);
    setArrayPhotos(auxArray);
    setComponentKey(Math.random());
  };

  return (
    <div style={{left: "0px", top: "0px", zIndex: 3}} className=" w-100 h-100 position-absolute d-flex justify-content-center align-items-center " >
      <div style={{left: "0px", zIndex: 2}} className=" opacity-50 position-absolute w-100 h-100 bg-black " >

      </div>
      <div style={{width: "1016px", height: "666px", borderRadius: "15px", zIndex: 4}} className=" position-relative bg-white overflow-hidden " >
        <div className=" brightGrayBgOpacity20 w-100 d-flex justify-content-between align-items-center px-4 " style={{height: "112px"}} >
          <p className=" museo-sans-500 px-3  s20" >
            <FormattedMessage id="buttons.select.photo" />
          </p>
          <div onClick={closeCamera} className=" pointer px-3" >
            <SVGClose />
          </div>
        </div>
        <div className="d-flex  position-relative" >
          <div  className=" d-flex flex-column align-items-center py-3 px-4 m-3" >
            <div style={{width: "632px", height:"402px"}} className=" position-relative " >
              <Webcam audio={false}
              height={402}
              width={602}
              forceScreenshotSourceSize={true}
              screenshotQuality={1}
              screenshotFormat="jpeg/png"
              >
                 {({ getScreenshot }) => (
                    <button
                        style={{bottom: -84, width: "56px", height: "56px", left: "45.5%"}}
                        className=" position-absolute museo-sans-700 s14 rounded-circle bg-transparent border-0 "
                        onClick={() => {
                        const imageSrc = getScreenshot();
                        handleCapture(imageSrc);
                        }}
                    >
                    </button>
                )}

                </Webcam>
            </div>
            <div style={{width: "56px", height: "56px", marginTop: "28px"}} className=" d-flex justify-content-center align-items-center  text-white rounded-circle skyBg" >
              <div style={{width: "24px", height: "24px"}} className=" svg-force-size" >
                <SVGCamera />
              </div>
            </div>
          </div>
          <div style={{width: "1px", height: "554px"}} className=" grayBg " >
            <p className=" invisible" >
              .
            </p>
          </div>
          <div className=" d-flex flex-column w-100" >
            <div>

            </div>
            <div style={{paddingTop: "30px"}} className=" d-flex flex-column pb-4 mb-3 px-4 mx-3" >
              <div style={{height: "402px"}} className=" d-flex flex-column align-items-center gap-2 hidden-y-scroll px-2" >
                {
                  arrayPhotos.length > 0 &&
                  <ImagesArray array={arrayPhotos} chosenSRC={chooseImage} key={componentKey} setChooseImage={setChooseImage}  />
                }
              </div>
              <div className=" pt-4 mt-3" >
              {
                chooseImage != null ?
                  <button onClick={() => selectedForCapture(chooseImage)} style={{height: "40px", borderRadius: "8px"}} className=" border-0  w-100 skyBg text-white museo-sans-700 s14 " >
                <FormattedMessage id="buttons.select" />
              </button>
                :
                  <button style={{height: "40px", borderRadius: "8px"}} className=" border-0  w-100 brightGrayBg text-white museo-sans-700 s14 " >
                <FormattedMessage id="buttons.select" />
              </button>
              }
              
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export function ImagesArray({array, key, setChooseImage, chosenSRC }) {

  const selectedDiv = (evt) => {
    setChooseImage(evt.target.src)
  }

  return <>
    {
      array.map((img) => {
        return <div key={key} style={{borderRadius: "2px"}} className={chosenSRC == img ? "login-profile--card-selected" : "login-profile--card--not-selected "} >
            <img onClick={selectedDiv} className="w-100 pointer " style={{ height: "138px", borderRadius: "2px"}}  src={img} />
        </div> 
      })
    }
  </>
} 