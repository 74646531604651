import React from 'react'

import circuloAssembly from "../../../common/img/circulo_Assembly.png"
import circuloBirthday from "../../../common/img/circulo_Birthday.png"
import circuloBusiness from "../../../common/img/circulo_Business.png"
import circuloEDB from "../../../common/img/circulo_EDB.png"
import circuloPersonal from "../../../common/img/circulo_Personal.png"

import { useMediaPredicate } from 'react-media-hook'

export default function CalendarEventResume({eventData}) {

    const smallerThan1280 = useMediaPredicate("(max-width: 1280px)");

    const biggerThan1370 = useMediaPredicate("(min-width: 1370px)");

    function defineCalendarCardIcon(eventType){
        switch(eventType){
            case "Assembly":
                return <img className='calendar-dot' src={circuloAssembly} alt="" />
            case "EDB":
                return <img className='calendar-dot' src={circuloEDB} alt="" />
            case "Birthday_client":
                return <img className='calendar-dot' src={circuloBirthday} alt="" />
            case "Birthday_contact":
                return <img className='calendar-dot' src={circuloBirthday} alt="" />
            case "Business":
                return <img className='calendar-dot' src={circuloBusiness} alt="" />
            case "Personal":
                return <img className='calendar-dot' src={circuloPersonal} alt="" />
        }
    }

    const splitString = (str) => {
        let searchedLength
        {biggerThan1370 ? searchedLength = 18 : smallerThan1280 ? searchedLength = 8 : searchedLength = 12}
        if (str.length > searchedLength) {
            let aux = str.slice(0,searchedLength);
            aux += "...";
            return aux;
        } else {
            return str;
        }
    }


  return (
    <div className='d-flex museo-sans-500 s8'>
        <div>{defineCalendarCardIcon(eventData.type)}</div>
        <p className='ms-1'>{splitString(eventData.title)}</p>
    </div>
  )
}

