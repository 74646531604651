import { FormattedMessage } from "react-intl"
import { useMediaPredicate } from "react-media-hook";
import { useNavigate } from "react-router-dom";

import { ReactComponent as HistoryIcon } from "../../../common/svgs/ic-history-sm.svg";

import { ReactComponent as SVGEmpty } from "../../../common/svgs/Empty-Pedidos-Ordenes.svg";

export default function PersonalSalesDetailsEmpty() {
    
    const history = useNavigate();
    
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const smallerThan1280 = biggerThan1280;

    const onPeriodClick = () => {
        history(-1);
      };

    return <div>
    <div className=" my-4 pb-3 d-flex justify-content-between">
      <div className="d-flex flex-wrap justify-items-center align-items-center">
        <button className="pointer-events-none brightGrayBorder grayColor rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button">
          <FormattedMessage id="personal.sales.all" defaultMessage="Todos" />{" "}
          ({0})
        </button>
        <button className="pointer-events-none brightGrayBorder grayColor rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button">
          <FormattedMessage
            id="personal.sales.bill"
            defaultMessage="Facturados"
          />{" "}
          ({0})
        </button>
        <button className="pointer-events-none brightGrayBorder grayColor rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button">
          <FormattedMessage
            id="personal.sales.pending"
            defaultMessage="A facturar"
          />{" "}
          ({0})
        </button>
        <button className="pointer-events-none brightGrayBorder grayColor rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button">
          <FormattedMessage
            id="personal.sales.request"
            defaultMessage="Sin enviar"
          />{" "}
          ({0})
        </button>
      </div>
      <div className="d-flex align-items-center">
        <button
          data-bs-toggle="modal"
          data-bs-target="#weekSalesModal"
          className={`${
            !smallerThan1280 ? "ps-2 pe-2 me-3" : "ps-4 pe-4 me-4"
          } museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}
        >
          <FormattedMessage
            id="performance.week.detail.button"
            defaultMessage="Detalle semana"
          />
        </button>
        <button
          onClick={onPeriodClick}
          className={`${
            !smallerThan1280 ? "ps-2 pe-2 me-3" : "ps-4 pe-4 me-4"
          } d-flex justify-content-evenly align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}
        >
          <HistoryIcon />
          <p className="ms-1">
            <FormattedMessage
              id="personal.sales.history"
              defaultMessage="Ver historia"
            />
          </p>
        </button>
      </div>
    </div>
    <div className=" mb-4 pb-2 museo-sans-500 text-uppercase s12 grayColor grid--order-details ">
      <p>
        <FormattedMessage id="personal.sales.header.row.order" />
      </p>
      <p>
        <FormattedMessage id="dashboard.modal.group.details.template.filter.followup" />
         </p>
      <p>
        <FormattedMessage id="personal.sale.details.list.three" />
         </p>
      <p>
        <FormattedMessage id="personal.sale.details.list.four" />
         </p>
      <p>
        <FormattedMessage id="personal.sale.details.list.five" />
      </p>
      <p className=" invisible">. </p>
    </div>
    <div
      style={{ height: "50vh" }}
      className=" museo-sans-500 w-100 d-flex flex-column justify-content-center align-items-center"
    >
      <SVGEmpty />
      <p className=" s24 ">
        <FormattedMessage id="personsal.sale.empty.title" />
      </p>
      <p className=" grayColor s14 ">
      <FormattedMessage id="personsal.sale.empty.subtitle" />
      </p>
    </div>
  </div>
}