export default function ReformatAmountsForGraph({ amount, profileData }) {
  const controlLanguageCode = (defaultCurrencyCode) => {
    if (defaultCurrencyCode == "USD") {
      return "";
    } else {
      let auxGroupingSeperator = ".";

      return auxGroupingSeperator;
    }
  };

  const splitOnLanguageCodeVariable = (amount) => {
    let toString = amount.toString();

    if (profileData.userInfo?.cultureInfo?.defaultCurrencyCode == "USD") {
      return amount;
    } else {
      let auxGroupSale = toString.split(
        controlLanguageCode(
          profileData.userInfo?.cultureInfo?.defaultCurrencyCode
        )
      );
      let splitted = auxGroupSale[0];
      return +splitted;
    }
  };

  return amount && splitOnLanguageCodeVariable(amount);
}
