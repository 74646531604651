import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import CustomSelect from "../../../../../common/Share/CustomSelect";
import { months } from "../../../constants";
import { useParams } from "react-router-dom";

export default function ClientListFormInputDate({control,label, setValue, name }) {

  const params = useParams()
  const isUpdate = params?.operation == 'update'

  const [monthSelected,setMonthSelected] = useState(null)
  const [defaultMonth,setDefaultMonth] = useState(null)
  const [defaultDay,setDefaultDay] = useState(null)
  const [isDirty,setIsDirty] = useState(false)
  const [allDays,setAllDays] = useState(null)
  const birthday = useSelector(state => state.clientListData.clientDetails?.data?.birthday);
  const allmonths = months;

  const resetDays = ()=>{
    setDefaultDay([{value:'',label:'Dia'}])
    setAllDays([{value:'',label:'Dia'}])
    setValue(name,null)
  }

  function addZeroLeft(numero) {
    return numero < 10 ? `0${numero}` : numero.toString();
  }

  const generateAllDaysfromMonths = async (days)=>{
    resetDays()
    if(days !== ''){
      let arrayDays = await Array.from({ length:days  }, (_, i) => ({value:addZeroLeft(i+1),label:addZeroLeft(i+1)}));
      await arrayDays.unshift({value:'',label:'Dia'})
      setAllDays(arrayDays)
    }
  }

  const handleSelectDayChange = (newValue) =>{
    if(newValue.value === '') {
      setValue(name,'')
    }else{
      setValue(name,`${monthSelected.value}-${newValue.value}`) }
  }

  const setAsyncBirthday = async ()=>{
    if (birthday) {
      let [month, day] = birthday.split("-").map(i => ({ label: i.trim(), value: i.trim() }));
      // Resto del código
      setDefaultMonth(months[Number(month.value)])
      setMonthSelected(months[Number(month.value)])
      generateAllDaysfromMonths(months[Number(month.value)].days)
      setDefaultDay(day)  
    }
  }

  useEffect(()=>{
      generateAllDaysfromMonths(monthSelected?.days)
  },[monthSelected])

  useEffect(()=>{
    if(typeof birthday === 'string' && isUpdate){
      setAsyncBirthday()
      setIsDirty(true)
    }

  },[birthday])

  return (
    <fieldset className="d-flex flex-column gap-2"> 
      <label className="museo-sans-500 s14">{label}</label>
      <div className='d-flex gap-3'>
      {isDirty && isUpdate?
      <>
        <Controller key={defaultMonth?.value} control={control} name={'monthUpdate'}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CustomSelect onChange={setMonthSelected}  options={allmonths} defaultValue={defaultMonth} isDisabled={false} width={'214px'}  />
          )}
        />
        {monthSelected && 
          <Controller key={monthSelected?.label}  control={control} name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CustomSelect onChange={e=>handleSelectDayChange(e)}  options={allDays} defaultValue={defaultDay} isDisabled={!monthSelected?.value}  width={'130px'} />
            )}
          />
        }
      </>
      :          
      <>
        <Controller key={defaultMonth?.value} control={control} name={'month'}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CustomSelect onChange={setMonthSelected}  options={allmonths} defaultValue={months[0]} isDisabled={false} width={'214px'} />
          )}
        />
        <Controller key={monthSelected?.value}  control={control} name={name}
          render={({ field: { onChange, onBlur, value, ref } }) => (
          <CustomSelect onChange={e=>handleSelectDayChange(e)}  options={allDays} defaultValue={[{value:'',label:'Dia'}]} isDisabled={!monthSelected?.value}  width={'130px'} />
          )}
        />
      </>
      }
      </div>
    </fieldset>
  );
}