import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ModalClipboard from "../../../common/Share/ModalClipboard";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import { fetchEDBDetails } from "../../../EDBs/Framework/redux/EDBDetailsSideEffect";
import {
  getClientDetails,
  getClientDetailsLinks,
  getContactCards,
} from "../../Framework/redux/ClientListSideEffect";
import ClientListDetailsBanner from "../components/ClientListDetailsBanner";
import ClientListDetailsContent from "../components/ClientListDetailsContent";
import ClientListDetailsHeader from "../components/ClientListDetailsHeader";
import ClientListDetailsModalDelete from "../components/modals/ClientListDetailsModalDelete";
import ClientListDetailsModalHost from "../components/modals/ClientListDetailsModalHost";
import ClientListDetailsModalShare from "../components/modals/ClientListDetailsModalShare";

export default function ClientListDetails({ isEmpty }) {
  const contactId = useParams();
  const dispatch = useDispatch();
  const locations = useLocation();
  const divRefJumpTop = useRef(null);
  const clientDetails = useSelector(
    (state) => state.clientListData.clientDetails
  );
  const clientDetailsCards = useSelector(
    (state) => state.clientListData.clientDetailsCards
  );
  const clientDetailsLinks = useSelector(
    (state) => state.clientListData.clientDetailsLinks
  );
  const profileData = useSelector((state) => state.userInfo);
  const edbData = useSelector((state) => state.edbDetailsData.data);
  const [copyToggler, setCopyToggler] = useState(false);

  const onStartUp = async () => {
    await dispatch(getClientDetails(contactId?.clientId ?? contactId.id));
    await dispatch(getClientDetailsLinks(contactId?.clientId ?? contactId.id));
    if (contactId?.clientId) {
      await dispatch(fetchEDBDetails(contactId?.id));
    }
    if (!locations?.pathname.includes("/edbs")) {
      await dispatch(getContactCards(contactId?.id));
    }
  };

  const openClipboard = () => {
    setCopyToggler(true);
  };

  const closeClipboard = () => {
    setCopyToggler(false);
  };

  useEffect(() => {
    onStartUp();
  }, [dispatch]);

  useEffect(() => {
    const myDiv = document.getElementById("my-div-private");
    myDiv.scrollTop = -1000;
  }, []);

  return (
    <>
      <div
        id="my-div-private"
        style={{ top: -100 }}
        className=" position-absolute invisible "
      ></div>

      <div>
        <ClientListDetailsBanner
          divRefJumpTop={divRefJumpTop}
          loading={clientDetails?.loader}
        />
        <ShareModalStructure id={"modalHosting"} maxWidth={"496px"}>
          <ClientListDetailsModalHost data={clientDetails?.data} buttonData={clientDetailsLinks} />
        </ShareModalStructure>
        <ClientListDetailsModalShare
          socialArray={profileData.userInfo.socialNetwork}
          open={openClipboard}
          data={clientDetails?.data}
        />
        <ShareModalStructure id={"modalDelete"} maxWidth={"600px"}>
          <ClientListDetailsModalDelete
            contactId={contactId?.clientId ?? contactId.id}
            onStartUp={onStartUp}
          />
        </ShareModalStructure>
        <div className="container--padding">
          <ModalClipboard show={copyToggler} close={closeClipboard} />
          <div className=" container--padding--card-holder ">
            <div className="mb-5 pb-4">
              <div className=" my-4 position-relative w-100 ">
                <ClientListDetailsHeader
                  buttonData={clientDetailsLinks}
                  loading={clientDetails?.loader}
                  data={clientDetails?.data}
                  socialArray={clientDetails?.data?.socialNetworks}
                />
                <ClientListDetailsContent
                  loading={clientDetails?.loader}
                  data={clientDetails?.data}
                  socialArray={clientDetails?.data?.socialNetworks}
                  open={openClipboard}
                  edbData={edbData}
                  contactCards={clientDetailsCards?.data}
                  contactCardsLoader={clientDetailsCards?.loader}
                  isEmpty={isEmpty}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
