import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import { getEventsForSpecificDate } from "../../Framework/redux/CalendarSideEffects";
import { changeEventSectionTitle } from "../../Framework/redux/CalendarActions";

import { ReactComponent as SVGCalendar } from "../../../common/svgs/ic-calendar-blue-md.svg";

import CalendarCard from "./CalendarCard";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export default function CalendarEventSection() {
  const eventsArray = useSelector((state) => state.calendarData.events);
  const loadingEvents = useSelector(
    (state) => state.calendarData.loadingEvents
  );
  const dispatch = useDispatch();

  function todaysRequest() {
    const dateObjectForStart = new Date();

    const dayForStart = dateObjectForStart.getDate();
    const monthForStart = dateObjectForStart.getMonth();
    const yearForStart = dateObjectForStart.getFullYear();

    const dateForFirstServiceCall = `${yearForStart}-${String(
      monthForStart + 1
    ).padStart(2, "0")}-${String(dayForStart).padStart(2, "0")}`;

    dispatch(changeEventSectionTitle("Tus eventos de hoy"));

    dispatch(getEventsForSpecificDate(dateForFirstServiceCall));
  }

  function displayEventCards() {
    return eventsArray.map((element) => {
      return (
        <CalendarCard
          key={ShareKeyGenerator()}
          id={element.id}
          title={element.title}
          type={element.type}
          description={element.description}
          date={element.date}
          social={element.relatedData.socialNetwork}
          relatedData={element.relatedData}
        />
      );
    });
  }

  useEffect(() => {
    todaysRequest();
  }, [dispatch]);

  return (
    <div className="remove-scrollbar position-relative calendar-event-section">
      {loadingEvents ? (
          <Skeleton />
      ) : eventsArray.length > 0 ? (
        displayEventCards()
      ) : (
        <p className="text-center s20 museo-sans-500 user-select-none" style={{ paddingTop: "151px",paddingLeft: "35px" }}>
          <div className=" mb-2 d-flex justify-content-center w-100">
            <div
              style={{ background: "#456ECE12", width: "80px", height: "80px" }}
              className=" d-flex justify-content-center align-items-center rounded-circle  "
            >
              <div
                style={{ width: "30px", height: "30px"}}
                className=" d-flex justify-content-center align-items-center svg-force-size"
              >
                <SVGCalendar></SVGCalendar>
              </div>
            </div>
          </div>
          <p style={{fontSize: "24px"}}>
            <FormattedMessage id="calendar.event.section.no.events" />
            </p>
          <div className=" d-flex justify-content-center  my-2">
            <p className=" museo-sans-500 s14 grayColor w-75 ">
              <FormattedMessage id="calendar.empty.state.p" />
            </p>
          </div>
        </p>
      )}
    </div>
  );
}

export function Skeleton(params) {
  return <div className=" d-flex flex-column gap-3  " >
      <SkeletonCard height={"150px"} />
      <SkeletonCard bottom={true} height={"100px"} />
      <SkeletonCard height={"200px"} />
      <SkeletonCard height={"150px"} />
  </div>
}

export function SkeletonCard({height, bottom}) {
  return(
    <div
            style={{ height: height }}
            className=" d-flex flex-column justify-content-between gap-3 p-3 rounded-4 brightGrayBg lightui1-shimmer w-100"
          >
            <div className="d-flex flex-column gap-3 lightui1-shimmer" >
              <div style={{ height: "8px" }} className=" w-50 rounded-3 d-flex lightui1-shimmer ">
                <div
                  style={{ height: "8px" }}
                  className=" w-75 rounded-3 bg-white lightui1-shimmer"
                ></div>
                <div
                  style={{ height: "8px" }}
                  className=" w-25 rounded-3 lightui1-shimmer"
                ></div>
              </div>
              <div style={{ height: "1px" }} className=" w-100 bg-white lightui1-shimmer"></div>
              <div className="d-flex gap-2 lightui1-shimmer" >
                <div style={{width: "16px", height: "16px"}} className="bg-white rounded-circle lightui1-shimmer " >

                </div>
                <div style={{height: "16px"}} className=" w-75 bg-white rounded-3 lightui1-shimmer"  >

                </div>
              </div>

            </div>
            {
              !bottom &&
              <div>
              <div style={{ height: "8px" }} className=" w-50 rounded-3 d-flex lightui1-shimmer">
                  <div
                    style={{ height: "8px" }}
                    className=" w-75 rounded-3 bg-white lightui1-shimmer"
                  ></div>
                  <div
                    style={{ height: "8px" }}
                    className=" w-25 rounded-3 lightui1-shimmer"
                  ></div>
                </div>
              </div>
            }
    </div>
  )
}