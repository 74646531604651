import { ReactComponent as SVGAR } from "../../common/svgs/flags/ar.svg";
import { ReactComponent as SVGCL } from "../../common/svgs/flags/cl.svg";
import { ReactComponent as SVGCO } from "../../common/svgs/flags/co.svg";
import { ReactComponent as SVGCR } from "../../common/svgs/flags/cr.svg";
import { ReactComponent as SVGMX } from "../../common/svgs/flags/mx.svg";
import { ReactComponent as SVGPA } from "../../common/svgs/flags/pa.svg";
import { ReactComponent as SVGPE } from "../../common/svgs/flags/pe.svg";
import { ReactComponent as SVGUY } from "../../common/svgs/flags/uy.svg";

export default function ShareFlagIcons({code, height}) {

  const arrayFlags = {
    ar: <SVGAR />,
    cl: <SVGCL />,
    co: <SVGCO />,
    cr: <SVGCR />,
    mx: <SVGMX />,
    pa: <SVGPA />,
    pe: <SVGPE />,
    uy: <SVGUY />,
  };
  
  return(<div style={{height: height || "16px"}} className=" svg-force-size " >
    {arrayFlags[code] || arrayFlags["ar"]}
  </div>)

}
