import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DescendantLine from "./DescendantLine";
import { ReactComponent as SVGFilledDownChev } from "../../../common/svgs/ic-chevron-filled-down.svg";
import { ReactComponent as SVGFilledRightChev } from "../../../common/svgs/ic-slide-right.svg";
import { ReactComponent as SVGRightChev } from "../../../common/svgs/ic-chevron-right-md.svg";
import DescendantsSublist from "./DescendantsSublist";
import {
  changePersonalGroupName,
  defineDescendantModalUserInfo,
  defineModalUserSalesTab,
} from "../../Framework/redux/DescendantsAction";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";
import { ShareQualificationStatusDesc } from "../../../common/Share/ShareAddProgressBar";

export default function DescendantsSublistRow({ index, end, gen, user }) {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const history = useNavigate();
  const onCollapse = () => {
    setToggle(!toggle);
  };

  const returnQualificationStatus = (boolean, level, data) => {
    return ShareQualificationStatusDesc(boolean, level, data);
  };

  const sortColors = (color) => {
    if (color === "completed") {
      return { color: "#16BE7D" };
    }
    if (color === "warning") {
      return { color: "#DCB632" };
    }
    if (color === "risk") {
      return { color: "#F06E69" };
    }
    if (color === "normal" || color === "gap") {
      return { color: "#DDDDDD" };
    }
  };

  const onClickPersonalGroup = (root) => {
    dispatch(
      changePersonalGroupName({
        name: user?.profileResume?.name,
        clientId: root,
      })
    );
    history(`/dashboard/descendants/personal-group/${root}`);
  };

  function changeModalInfo(salesModal) {
    dispatch(
      defineDescendantModalUserInfo({
        id: user.clientId,
        name: user.profileResume.name,
        firstName: user.profileResume.firstName,
        lastName: user.profileResume.lastName,
        level: user.profileResume.level,
        gender: user.profileResume.gender,
        picture: user.profileResume.image,
        roleDescription: user.profileResume.roleDescription,
        uplineName: user.profileResume.uplineName,
        uplineId: user.profileResume.uplineId,
        socialNetwork: user.profileResume.socialNetwork,
        currencyCode: user.performance.currencyCode,
        personalSalesActual: user.performance.personalSales.actualAmount,
        personalSalesTotal: user.performance.personalSales.totalAmount,
        personalSalesStatus: user.performance.personalSales.status,
        incorporatedSalesActual:
          user.performance.incorporatedSales.actualActives,
        incorporatedSalesTotal: user.performance.incorporatedSales.totalNeed,
        incorporatedSalesStatus: user.performance.incorporatedSales.status,
        groupSalesActual: user.performance.groupSales.actualAmount,
        groupSalesTotal: user.performance.groupSales.totalAmount,
        groupSalesStatus: user.performance.groupSales.status,
        triperiodicSalesActual: user.performance.triperiodicSales.actualAmount,
        triperiodicSalesTotal: user.performance.triperiodicSales.totalAmount,
        triperiodicSalesStatus: user.performance.triperiodicSales.status,
        triperiodicSalesPeriods: user.performance.triperiodicSales.periods,
        billedAmount: user.balanceSummary.billed.amount,
        billedStatus: user.balanceSummary.billed.status,
        balanceAmount: user.balanceSummary.balance.amount,
        balanceStatus: user.balanceSummary.balance.status,
        toInvoiceAmount: user.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "toInvoice"
        ).amount,
        toInvoiceStatus: user.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "toInvoice"
        ).status,
        notSentAmount: user.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "notSent"
        ).amount,
        notSentStatus: user.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "notSent"
        ).status,
      })
    );
    dispatch(defineModalUserSalesTab(salesModal));
  }

  return (
    <div className=" position-relative desc--grid-collapse w-100 ">
      <DescendantLine
        gen={gen}
        index={index}
        end={index == end}
        desc={user?.hasDescendants}
      />

      <div
        className={` position-relative desc--grid-tree gap-4 w-100 ${
          toggle ? " mt-4 " : " my-4 "
        }  `}
        style={!toggle ? { height: "42px" } : { height: "auto" }}
      >
        {user?.hasDescendants ? (
          <div
            onClick={user?.hasDescendants && onCollapse}
            className=" pointer d-flex align-items-center gap-2"
          >
            {user?.hasDescendants ? (
              <>
                {toggle ? (
                  <div
                    onClick={onCollapse}
                    style={{
                      width: "16px",
                      paddingBottom: "5px",
                      paddingLeft: "2px",
                      transform: "rotate(90deg)",
                    }}
                    className="  svg-force-size pointer"
                  >
                    <SVGFilledRightChev />
                  </div>
                ) : (
                  <div
                    onClick={onCollapse}
                    style={{ width: "16px" }}
                    className=" svg-force-size pointer"
                  >
                    <SVGFilledRightChev />
                  </div>
                )}
              </>
            ) : (
              <div className=" invisible">
                <SVGFilledDownChev />
              </div>
            )}
            {user?.isPersonalIncorporated ? (
              <div
                style={{
                  height: "42px",
                  width: "42px",
                  border: "solid 1.5px #456ECE",
                  left: "-2px",
                }}
                className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
              >
                <ImageCircleHandler
                  name={user?.profileResume?.name}
                  width={"36px"}
                  height={"36px"}
                  url={user?.profileResume?.image}
                  firstName={user?.profileResume?.firstName}
                  lastName={user?.profileResume?.lastName}
                  top={2}
                />
              </div>
            ) : (
              <div
                style={{
                  height: "40px",
                  width: "40px",
                  border: "solid 0px #456ECE",
                  left: "-2px",
                }}
                className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
              >
                <ImageCircleHandler
                  name={user?.profileResume?.name}
                  width={"36px"}
                  height={"36px"}
                  url={user?.profileResume?.image}
                  firstName={user?.profileResume?.firstName}
                  lastName={user?.profileResume?.lastName}
                  top={2}
                />
              </div>
            )}
            <div
              style={{ left: "-4px" }}
              className=" position-relative blackColor d-flex flex-column"
            >
              <p className=" s14 museo-sans-700">{user?.profileResume?.name}</p>
              <div className="s12 d-flex grayColor gap-1 ">
                <p className="text-uppercase ">
                  {gen}° <FormattedMessage id="gen" />
                </p>
                <p>|</p>
                <p>
                  <ReformatLevelByGender
                    gender={user?.profileResume?.gender}
                    level={user?.profileResume?.level}
                    roleDescriptionDefault={
                      user?.profileResume?.roleDescription
                    }
                  />
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="  d-flex align-items-center gap-2">
            {user?.hasDescendants ? (
              <>
                {toggle ? (
                  <div onClick={onCollapse} className="pointer">
                    <SVGFilledDownChev />
                  </div>
                ) : (
                  <div onClick={onCollapse} className="pointer">
                    <SVGFilledRightChev />
                  </div>
                )}
              </>
            ) : (
              <div className=" invisible">
                <SVGFilledDownChev />
              </div>
            )}
            {user?.isPersonalIncorporated ? (
              <div
                style={{
                  height: "42px",
                  width: "42px",
                  border: "solid 1.5px #456ECE",
                  left: "-2px",
                }}
                className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
              >
                <ImageCircleHandler
                  name={user?.profileResume?.name}
                  width={"36px"}
                  height={"36px"}
                  url={user?.profileResume?.image}
                  firstName={user?.profileResume?.firstName}
                  lastName={user?.profileResume?.lastName}
                  top={2}
                />
              </div>
            ) : (
              <div
                style={{
                  height: "40px",
                  width: "40px",
                  border: "solid 0px #456ECE",
                  left: "-2px",
                }}
                className=" position-relative d-flex justify-content-center align-items-center rounded-circle overflow-hidden"
              >
                <ImageCircleHandler
                  name={user?.profileResume?.name}
                  width={"36px"}
                  height={"36px"}
                  url={user?.profileResume?.image}
                  firstName={user?.profileResume?.firstName}
                  lastName={user?.profileResume?.lastName}
                  top={2}
                />
              </div>
            )}
            <div className=" blackColor d-flex flex-column">
              <p className=" s14 museo-sans-700">{user?.profileResume?.name}</p>
              <div className="s12 d-flex grayColor gap-1 ">
                <p className="text-uppercase ">
                  {gen}° <FormattedMessage id="gen" />
                </p>
                <p>|</p>
                <p>
                  <ReformatLevelByGender
                    gender={user?.profileResume?.gender}
                    level={user?.profileResume?.level}
                    roleDescriptionDefault={
                      user?.profileResume?.roleDescription
                    }
                  />
                </p>
              </div>
            </div>
          </div>
        )}
        <div
          onClick={() => onClickPersonalGroup(user?.clientId)}
          className=" pointer museo-sans-700 s14 align-items-center d-flex skyColor justify-content-center"
        >
          <FormattedMessage id="show" />
        </div>
        <div
          data-bs-toggle="modal"
          data-bs-target="#descendantConsultantInfo"
          onClick={() => changeModalInfo(false)}
          className=" pointer d-flex justify-content-end align-items-center gap-2"
        >
          <p
            style={sortColors(user?.performance?.groupSales?.status)}
            className=" s14 dm-sans-medium "
          >
            {formatAmount(
              user?.performance?.groupSales?.actualAmount,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )}
          </p>
          <SVGRightChev />
        </div>
        <div
          data-bs-toggle="modal"
          data-bs-target="#descendantConsultantInfo"
          onClick={() => changeModalInfo(true)}
          className=" pointer d-flex justify-content-center align-items-center gap-2"
        >
          {user &&
            returnQualificationStatus(
              user.isPersonalIncorporated,
              user.profileResume?.level,
              user.performance
            )}
          <SVGRightChev />
        </div>
        {toggle && (
          <div className=" w-100 desc---grid-overwhole ">
            <div>
              <DescendantsSublist
                gen={gen + 1}
                id={user.clientId}
                level={user?.profileResume?.level}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
