import React from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SVGRedTrash } from "../../../svgs/ic-trash-outline-md.svg";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getBadgeCounter, getNotifications, patchNotfication } from "../../Framework/HeaderSideEffect";

export default function HeaderModalDelete() {
  const dispatch = useDispatch();
  const notificationId = useSelector((state) => state.headerData.notificationId);

  const deleteNotif = async () => {
    await dispatch(patchNotfication({notificationId: notificationId, newStatus: "delete"}));
    await dispatch(getNotifications());
    await dispatch(getBadgeCounter());
  }

  return (
    <div className="modal fade" id="modalNotifDelete" aria-hidden="true" aria-labelledby="modalNotifDelete" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "400px"}}>
            <div className="modal-content modal-radius">
                
                <div className="modal-body">
                    <i 
                        className="bi bi-x-lg position-absolute" 
                        style={{top: "15px", right: "15px"}} 
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></i>
                    
                    <div>
                        <div className='d-flex flex-column align-items-center'>
                            <div className=" d-flex  justify-content-center rounded-circle" style={{ alignItems: "center", width: "92px", height: "92px"}} >
                                <div style={{width: "42px", height: "42px"}}>
                                    <SVGRedTrash />
                                </div>
                            </div>
                            <div className='d-flex flex-column align-items-center mb-4'>
                                <h5 className='museo-sans-700 s24'>
                                    <FormattedMessage id="notification.modal.delete.title" defaultMessage="Cerrar sesión" /></h5>
                                <p className='museo-sans-500 s16 grayColor'><FormattedMessage id="notification.modal.delete.subtitle" defaultMessage="¿Seguro deseas salir del sistema?" /></p>
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-center'>
                            <button onClick={deleteNotif} type="button" className="w-75 mb-3 museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-border-none button-bootstrap-radius button-padding" data-bs-dismiss="modal" aria-label="Close"> 
                            <FormattedMessage id="buttons.yes" defaultMessage="si" /></button>
                            <button type="button" className="w-75 mb-3 museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-bootstrap-radius button-padding" data-bs-dismiss="modal" aria-label="Close"><FormattedMessage id="buttons.no" defaultMessage="no" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}
