import { useDispatch } from "react-redux";
import { defineDescendantModalUserInfo, defineModalUserSalesTab } from "../../Framework/redux/DescendantsAction";

export default function DescedantsDashboardChangeModalInfo(salesModal, data) {

    const dispatch = useDispatch();

    dispatch(
      defineDescendantModalUserInfo({
        id: data.clientId,
        name: data.profileResume.name,
        firstName: data.profileResume.firstName,
        lastName: data.profileResume.lastName,
        gender: data.profileResume.gender,
        level: data.profileResume.level,
        picture: data.profileResume.image,
        roleDescription: data.profileResume.roleDescription,
        uplineName: data.profileResume.uplineName,
        uplineId: data.profileResume.uplineId,
        socialNetwork: data.profileResume.socialNetwork,
        currencyCode: data.performance.currencyCode,
        personalSalesActual: data.performance.personalSales.actualAmount,
        personalSalesTotal: data.performance.personalSales.totalAmount,
        personalSalesStatus: data.performance.personalSales.status,
        incorporatedSalesActual:
          data.performance.incorporatedSales.actualActives,
        incorporatedSalesTotal: data.performance.incorporatedSales.totalNeed,
        incorporatedSalesStatus: data.performance.incorporatedSales.status,
        groupSalesActual: data.performance.groupSales.actualAmount,
        groupSalesTotal: data.performance.groupSales.totalAmount,
        groupSalesStatus: data.performance.groupSales.status,
        triperiodicSalesActual: data.performance.triperiodicSales.actualAmount,
        triperiodicSalesTotal: data.performance.triperiodicSales.totalAmount,
        triperiodicSalesStatus: data.performance.triperiodicSales.status,
        triperiodicSalesPeriods: data.performance.triperiodicSales.periods,
        billedAmount: data.balanceSummary.billed.amount,
        billedStatus: data.balanceSummary.billed.status,
        balanceAmount: data.balanceSummary.balance.amount,
        balanceStatus: data.balanceSummary.balance.status,
        toInvoiceAmount: data.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "toInvoice"
        ).amount,
        toInvoiceStatus: data.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "toInvoice"
        ).status,
        notSentAmount: data.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "notSent"
        ).amount,
        notSentStatus: data.balanceSummary.orderStatusAmounts.find(
          (element) => element.state === "notSent"
        ).status,
      })
    );
    dispatch(defineModalUserSalesTab(salesModal));
  };