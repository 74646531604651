import ReactGA from "react-ga4";

 // GTM-KHNRHM8  -- NEW KEY
 // UA-262410627-1 -- OLD KEY
 const TRACKING_ID = "G-DXQT8T2P9V"; // OUR_TRACKING_ID
 ReactGA.initialize(TRACKING_ID);

const UseAnalyticsEventTracker = (strCategory, strLabel) => {

  ReactGA.event({category: strCategory, action: strLabel, label: strLabel});

  // }
  // return eventTracker;
}
export default UseAnalyticsEventTracker;