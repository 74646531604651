// /period-earnings/{clientId}/balance
import { createAsyncThunk } from "@reduxjs/toolkit"; 
import customNetServAxios from "../../../common/services/NetSrv";

const fetchBalance = createAsyncThunk(
    'fetchImportant',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            
            return await customNetServAxios().get(`${location}/period-earnings/${clientId}/balance`);
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

const fetchMetrics = createAsyncThunk(
    'fetchMetrics',
    async ({root}, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let clientId = localStorage.getItem("clientId");
            return await customNetServAxios().get(`${location}/period-earnings/${root || clientId}/history/metrics?historicPeriods=13`);
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

// /period-earnings/{clientId}/history/metrics

export { fetchBalance, fetchMetrics }