import React from "react";

export default function FormSkeleton() {
  return (
    <>
      <SkeletonRowTextTitle />

      <hr />
      <div  className="mb-4 pb-3 d-flex flex-wrap"  >
        <SkeletonRowInput/>
        <SkeletonRowInput/>
        <SkeletonRowInput/>
        <SkeletonRowInput/>
      </div>

      <SkeletonRowTextTitle />
      <hr />
      <div  className="mb-4 pb-3 d-flex flex-wrap"  >
        <SkeletonRowInput/>
        <SkeletonRowInput/>
        <SkeletonRowInput/>
        <SkeletonRowInput/>
      </div>
      <hr />
      <div style={{height: "300px"}} className=" rounded-3 w-100 lightui1-shimmer" >
        <p className="invisible" >
          .
        </p>

      </div>
      <div style={{height: "40px"}} className=" my-4 rounded-3 w-25 lightui1-shimmer" >
        <p className="invisible" >
          .
        </p>

      </div>

    </>
  );
}

export function SkeletonRowInput(params) {
  return (
    <div className="w-50 mb-3" >
      <div style={{height: "20px"}} className="mb-3" >
        <div style={{height: "20px"}} className="lightui1-shimmer rounded-3 w-25">
          <p  style={{height: "20px"}} className=" invisible  museo-sans-500 s14">Datos personales</p>
        </div>
      </div>
      <div style={{ width: "360px", height: "40px" }}>
        <div className="lightui1-shimmer s14 d-flex justify-content-center align-items-center rounded-2 input-border w-100 h-100 p-2"></div>
      </div>
    
    </div>
  );
}

export function SkeletonRowTextTitle(params) {
  return (
    <div className="lightui1-shimmer rounded-3 w-25">
      <h3 className=" invisible  museo-sans-500 s20">Datos personales</h3>
    </div>
  );
}
