import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import {
    getMeetings
} from "./dashboardSideEffect";

const initialState = {
  meetingArray: {},
  loader: false,
};

const meetingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getMeetings.fulfilled, (state, action) => {
      state.meetingArray = action.payload;
      state.loader = false;
    })

    .addMatcher(
      
      isAnyOf(
        
        getMeetings.pending,
      ),
      (state, action) => {
        state.loader = true;
      }
    )
    .addMatcher(
      isAnyOf(
        getMeetings.rejected,
      ),
      (state, action) => {
        state.loader = false;
      }
    );
});

export default meetingReducer;
