
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../common/Share/Loader";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export default function LoginPinRecovery() {
  const loading = useSelector((state) => state.userInfo.loader);
  const history = useNavigate();

  const handlePassRecovery = (evt) => {
    evt.preventDefault();
    history("/login");
  };


  return (
    <div id="login-layout" className=" container--padding py-5">
      <div className="">
        <h1 className="mt-3 grayColor s12 museo-sans-500  text-uppercase">
        <FormattedMessage
              id="login.recovery.page.title"
              defaultMessage="1. Validate identity"
              />
          </h1>
        <hr></hr>
        <h2 className="s24 museo-sans-700 mt-4">
        <FormattedMessage
              id="login.page.pin.recovery"
              defaultMessage="PIN Recovery"
              />
          </h2>
        <p className="s14 museo-sans-500 w-75">
        <FormattedMessage
              id="login.page.pin.desc"
              defaultMessage="Write down your consultant number which you are registered with"
              />
          </p>
      </div>
      <div className="  align-self-end">
        <Loader loading={loading} />
        <button className="s14 museo-sans-700 w-50 py-2 text-white btnCustom skyBg" onClick={handlePassRecovery}>
        <FormattedMessage
              id="buttons.continue"
              defaultMessage="Recover password"
              />
          </button>
      </div>
    </div>
  );
}
