
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LastUpdate from "../../../common/Share/LastUpdate";

import { ReactComponent as SVGCrossError } from "../../../common/svgs/ic-x-circle-sm-blue.svg";
import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-chevron-right-md.svg";

import {
  fetchDescFirstGen,
  fetchDescIncorp,
} from "../../Framework/redux/DescendantsSideEffect";
import DescendantsList from "../components/DescendantsList";
import DescendantModalInfo from "../components/modal/DescendantModalInfo";
import "./Descendants.css";
import {
  changePersonalGroupName,
} from "../../Framework/redux/DescendantsAction";
import DescendantsConsultantInfoModal from "../components/DescendantsConsultantInfoModal";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import SkeletonDescendentRow from "../../../common/Share/SkeletonDescendentRow";
import SkeletonDescendentHeader from "../../../common/Share/SkeletonDescendentHeader";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import DescedantsDashboardChangeModalInfo from "../components/DescendantsDashboardChangeModalInfo";
import { ShareQualificationStatusDesc } from "../../../common/Share/ShareAddProgressBar";

function DescendantsDashboard() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const profileData = useSelector((state) => state.userInfo.userInfo);
  const yearperiod = useSelector((state) => state?.headerData?.period?.currentYearPeriod);
  const dataFirstGen = useSelector((state) => state.descendantData.dataFirstGen);
  const dataIncorp = useSelector((state) => state.descendantData.dataIncorp);
  const loading = useSelector((state) => state.descendantData.loader);
  const [loader, setLoader] = useState(true);
  const [offSet , setOffSet] = useState(0);
  const [value, setValue] = useState(null);
  const [isVisible, setIsVisible] = useState()

  const onStartUp = async () => {
    setLoader(true);
    let root = localStorage.getItem("clientId");
    if (profileData.level < 3) {
      await dispatch(fetchDescIncorp({ root: root }));
    }
    await dispatch(fetchDescFirstGen({ root: root }));

    setTimeout(() => {
        setLoader(false);
    }, 1000);
  };

  const setIsVisibleChild = (value) => {
    setIsVisible(value);
  };

  const setIsOffsetChild = (value) => {
    setOffSet(value);
  };

  const sortColors = (color) => {
    if (color === "completed") {
      return { color: "#16BE7D" };
    }
    if (color === "warning") {
      return { color: "#DCB632" };
    }
    if (color === "risk") {
      return { color: "#F06E69" };
    }
    if (color === "normal" || color === "gap") {
      return { color: "#DDDDDD" };
    }
  };

  const returnQualificationStatus = (boolean, level, data) => {
    return ShareQualificationStatusDesc(boolean, level, data);
  };

  const onClickPersonalGroup = () => {
    let root = "";

    if (dataIncorp) {
      root = dataIncorp?.rootClientId;
    }
    if (dataFirstGen) {
      root = dataFirstGen?.rootClientId;
    }

    dispatch(
      changePersonalGroupName({ name: profileData?.name, clientId: root })
    );

    history(`/dashboard/descendants/personal-group/${root}`);
  };

  useEffect(() => {
    onStartUp();
  }, [yearperiod, profileData]);

  const generationTracker = 1;

  return (
    <div className=" desc--dashboard-bg container--padding ">
      <DescendantsConsultantInfoModal value={value} setValue={setValue} />
      <DescendantModalInfo />
      <div className=" container--padding--card-holder">
        <div>
          <div className=" w-100 desc--personal-update d-flex justify-content-between mt-4 py-3 ">
            <div></div>
            <LastUpdate />
          </div>
          <div
            style={{ height: "107px" }}
            className=" px-3 gap-4 desc--grid-tree position-relative mb-5 f9GrayBorder w-100 bg-white  desc-cards-shadow rounded-3 py-3 "
          >
            {loader ? (
                  <SkeletonDescendentHeader />
            ) : (
              <>
                <div className=" mx-3 museo-sans-500 w-100 d-flex align-items-center gap-3 ">
                  <ImageCircleHandler
                    width={"58px"}
                    height={"58px"}
                    name={profileData?.name}
                    url={profileData.image}
                    firstName={profileData?.firstName}
                    lastName={profileData?.lastName}
                    top={2}
                  />

                  <div className="p-2">
                    <h5 className=" museo-sans-700 s20">
                      <FormattedMessage id="you" />
                      {
                        // {profileData?.name ? profileData?.name : "Name"}
                      }
                    </h5>
                    <p className=" grayColor s14">
                    <ReformatLevelByGender gender={profileData?.gender} level={profileData?.level} roleDescriptionDefault={profileData?.roleDescription} />
                    </p>
                  </div>
                </div>

                <div className=" position-relative">
                  <button
                    style={{
                      width: "214px",
                      height: "40px",
                      right: "16px",
                      top: "25%",
                    }}
                    onClick={() => onClickPersonalGroup()}
                    className="d-flex position-absolute justify-content-center align-items-center s14 museo-sans-700 skyColor bg-transparent skyBorder rounded "
                  >
                    <p className="">
                      <FormattedMessage id="view.personal.group" /> &nbsp; ({dataFirstGen?.rootPersonalGroupCount})
                    </p>
                  </button>
                </div>

                <div
                  style={{ justifySelf: "center" }}
                  onClick={() => DescedantsDashboardChangeModalInfo(false, dataFirstGen)}
                  className=" d-flex align-items-center w-100  justify-content-between"
                  data-bs-toggle="modal"
                  data-bs-target="#descendantConsultantInfo"
                >
                  <div className=" d-flex flex-column gap-2">
                    <h5 className="s14 museo-sans-500 m-0 ">
                      <FormattedMessage id="nav.label.one.c.b" />
                    </h5>
                    <label
                      className="m-0 s14 dm-sans-medium d-flex justify-content-end"
                      style={sortColors(
                        dataFirstGen?.rootSummary?.performance?.groupSales
                          ?.status
                      )}
                    >
                      {
                        dataFirstGen?.rootSummary?.profileResume.level > 1 ?
                        <>
                        {formatAmount(dataFirstGen?.rootSummary?.performance?.groupSales?.actualAmount, profileData.cultureInfo?.languageCode, profileData.cultureInfo?.defaultCurrencyCode)}
                        
                        </>
                        :
                        <>
                        {formatAmount(dataFirstGen?.rootSummary?.balanceSummary?.billed?.amount, profileData?.cultureInfo?.languageCode, profileData?.cultureInfo?.defaultCurrencyCode)}
                        
                        </>
                      }
                    </label>
                  </div>
                  <SVGRightArrow />
                </div>

                <div
                  style={{ justifySelf: "center" }}
                  onClick={() => DescedantsDashboardChangeModalInfo(true, dataFirstGen)}
                  className=" d-flex gap-2 align-items-center"
                  data-bs-toggle="modal"
                  data-bs-target="#descendantConsultantInfo"
                >
                  {dataFirstGen &&
                    returnQualificationStatus(
                      true,
                      dataFirstGen.rootSummary.profileResume?.level,
                      dataFirstGen.rootSummary.performance
                    )}
                  <SVGRightArrow />
                </div>
              </>
            )}
          </div>
          {profileData.level > 2 && (
            <>
              <div className=" w-100 position-relative d-flex flex-column">
                {loader ? (
                    <Skeleton />
                ) : (
                  <>
                    <h4 className="positionTitle s20 museo-sans-700 m-0">
                      <FormattedMessage
                        id="title.groups.calificated"
                        defaultMessage="Buenas Prácticas"
                      />
                    </h4>
                    <div className=" w-100 py-3 ">
                      <div className=" text-uppercase desc--grid-tree gap-4 pe-3 s12 museo-sans-500 grayColor ">
                        <p className="">
                          <FormattedMessage id="edb.details.list.name" />
                        </p>
                        <p className=" d-flex justify-content-center ">
                          <FormattedMessage id="descendants.personal.group" />
                        </p>
                        <p className=" d-flex justify-content-center ">
                          <FormattedMessage id="nav.label.one.c" />
                        </p>
                        <p className=" d-flex justify-content-center ">
                          <FormattedMessage id="descendants.qualification" />
                        </p>
                      </div>{" "}
                      {dataFirstGen || dataIncorp ? (
                        <DescendantsList
                          gen={generationTracker}
                          isVisible={isVisible}
                          loading={loading}
                          setIsVisible={setIsVisibleChild}
                          setOffSet={setIsOffsetChild}
                          offSet={offSet}
                          profileData={profileData}
                          groups={
                            dataFirstGen && profileData.level > 2
                              ? dataFirstGen?.firstGenerationGroup
                              : dataIncorp?.incorporated
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
          {profileData.level == 2 && (
            <>
              <div className=" w-100 py-4 d-flex align-items-center flex-column justify-content-center w-100 gap-2 ">
                <div
                  style={{
                    border: " 1px solid #456ECE",
                    height: "24px",
                    width: "24px",
                  }}
                  className=" svg-force-size d-flex  rounded-circle  "
                >
                  <SVGCrossError />
                </div>
                <p className=" blueColor museo-sans-500 s16">
                  <FormattedMessage id="desc.msg.level.2.no.group" />
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default DescendantsDashboard;

export function Skeleton() {
  return (
    <>
        <div>
          <h4 className="positionTitle s20 museo-sans-700 m-0">
            <FormattedMessage
              id="title.groups.calificated"
              defaultMessage="Buenas Prácticas"
            />
          </h4>
          <div className=" w-100 whiteLightBg py-3 ">
            <div className=" text-uppercase desc--grid-tree gap-4 pe-3 s12 museo-sans-500 grayColor ">
              <p className="">
                <FormattedMessage id="edb.details.list.name" />
              </p>
              <p className=" d-flex justify-content-center ">
                <FormattedMessage id="descendants.personal.group" />
              </p>
              <p className=" d-flex justify-content-center ">
                <FormattedMessage id="nav.label.one.c" />
              </p>
              <p className=" d-flex justify-content-center ">
                <FormattedMessage id="descendants.qualification" />
              </p>
            </div>
          </div>
        </div>
        <div className=" my-3">
          <SkeletonDescendentRow />
        </div>
        <div className=" my-3">
          <SkeletonDescendentRow />
        </div>
        <div className=" my-3">
          <SkeletonDescendentRow />
        </div>
        <div className=" my-3">
          <SkeletonDescendentRow />
        </div>
        <div className=" my-3">
          <SkeletonDescendentRow />
        </div>
        <div className=" my-3">
          <SkeletonDescendentRow />
        </div>
    </>
  );
}
