import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { fetchEDBDetails } from "./EDBDetailsSideEffect";


const initialState = {
  data: null,
  loader: false,
};

const EDBDetailsReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(fetchEDBDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loader = false;
    })

    .addMatcher(
      isAnyOf(
        fetchEDBDetails.pending,
      ),
      (state, action) => {
        state.loader = true;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchEDBDetails.rejected,
      ),
      (state, action) => {
        state.loader = false;
      }
    );
});

export default EDBDetailsReducer;