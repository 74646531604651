import { FormattedMessage } from "react-intl";

export default function DistributionAgreementContentUY(params) {
    return (
        <>
          <p className={` museo-sans-500 s16 mb-4 ${!params.showTitle && "mt-4"}`}>
            <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.2" />
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.1" />
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.2" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.3" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.4" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.5" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
              </u>
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.14" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.15" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.16" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.17" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.18" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.19" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.20" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.21" />
          </p>
          <p className="invisible mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="ethics.code.text.immigration.laws.1" />
              </u>
            </b>
            <FormattedMessage id="ethics.code.text.immigration.laws.1" />
          </p>
        </>
      );
}