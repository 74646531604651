import moment from "moment";
import { FormattedMessage } from "react-intl";
import { multiLangJSONParse } from "../../../../common/Share/MultiLangJSONParse";
import getTimeZoneByCountry from "../../../../common/Share/formatDateMoment";
import { getCountriesTimeZone } from "../../../../common/Share/getCountriesTimeZone";

export default function firstStepsAdjustDate({reached, completed, limit, command, extra}) {
    const adjustDateMoment = (reached, completed, limit, command, extra) => {

        const countryCode = localStorage.getItem("country")
        const actualDateMoment = moment();

        if (command == "fetchRedeemDate") {
            
            let redeemDay = moment(extra).format("DD");
            let redeemMonth = moment(extra).format("MM");

            return <>
            {`${redeemDay} `} <FormattedMessage id={`month.of.${+redeemMonth}`} />
        </>
        };

        if (command == "fetchRedeemDistance") {
            let extraMoment = moment.tz(moment(extra), getCountriesTimeZone(countryCode) )
            return extraMoment.diff(actualDateMoment, 'hours');
        }

        if (!limit) {
            return ""
        };
        
        const limitMoment = moment.tz(moment(limit), getCountriesTimeZone(countryCode));

        if (command == "isItToday") {
            let limitDay = moment(limit).tz(getCountriesTimeZone(countryCode)).format("DD");
            
            if (limitDay == moment().format("DD") ) {
                return true
                
            } else {
                return false
            }

        };
        
        if (command == "fetchLimit") {
            let limitDay = moment(limit).tz(getCountriesTimeZone(countryCode)).format("DD");
            let limitMonth = moment(limit).tz(getCountriesTimeZone(countryCode)).format("MM");
            
            return <>
                {`${limitDay} `} <FormattedMessage id={`month.of.${+limitMonth}`} />
            </>
        };

        if (command == "fetchLimitWithYear") {
            let limitDay = moment.tz(moment(limit), getCountriesTimeZone(countryCode)).format("DD");
            let limitMonth = moment.tz(moment(limit), getCountriesTimeZone(countryCode)).format("MM");
            let limitYear = moment.tz(moment(limit), getCountriesTimeZone(countryCode)).format("YYYY");
            
            return <>
                {`${limitDay} `} <FormattedMessage id={`month.of.${+limitMonth}`} />  {`${limitYear} `}
            </>
        };
        
        if (command == "fetchDayOfWeekLimit") {
            let indexOfTheWeek = limitMoment.day();

            switch (indexOfTheWeek) {
                case 0:
                    
                    return multiLangJSONParse()["sunday"];
                case 1:
                    
                    return multiLangJSONParse()["monday"];
                case 2:
                    
                    return multiLangJSONParse()["tuesday"];
                case 3:
                    
                    return multiLangJSONParse()["wednesday"]; 
                case 4:
                    
                    return multiLangJSONParse()["thrusday"]; 
                case 5:
                    
                    return multiLangJSONParse()["friday"];
                case 6:
                    
                    return multiLangJSONParse()["saturday"];
            }
        };

        if (command == "fetchReach") {

            if(!reached) {
                return "";
            }

            let completedDay = moment(reached).tz(getCountriesTimeZone(countryCode)).format("DD");
            let completedMonth = moment(reached).tz(getCountriesTimeZone(countryCode)).format("MM");
            let completedYear = moment(reached).tz(getCountriesTimeZone(countryCode)).format("YYYY");

            return <>
                {`${completedDay} `} <FormattedMessage id={`month.of.${+completedMonth}`} /> {completedYear}
            </>
        };

        if (command == "remainingDays") {
            return limitMoment.diff(actualDateMoment, 'days');
        };

        if (command == "fetchLimitDayOnDays") {
            return limitMoment.diff(actualDateMoment, 'days');
        };

        if (command == "fetchIfFailed") {
            let time = limitMoment.diff(actualDateMoment, 'hours');
            if (time > 0) {
                return true
            } else {
                return false
            }
        };


        return limitMoment.diff(actualDateMoment, 'hours');

    };
    return adjustDateMoment((reached), (completed), (limit), command, (extra))
}

export function dateFormat(dateStart, dateEnd, index) {
    const country = localStorage.getItem("country");
    const timeZone = getTimeZoneByCountry(country);
  
    const fechaActual = new Date().toLocaleString("en-US", { timeZone });
  
    let idElm;
  
    const fechaInicio = new Date(dateStart).toLocaleString("en-US", { timeZone });
    const fechaFin = new Date(dateEnd).toLocaleString("en-US", { timeZone });
    const fechaActualFormateada = new Date().toLocaleString("en-US", {
      timeZone,
    });
  
    const estaEnRango = isWithinRange(
      fechaActualFormateada,
      fechaInicio,
      fechaFin
    );
  
    if (estaEnRango) {
      idElm = index;
      return { estaEnRango, idElm };
    }
  
    return { estaEnRango, idElm };
  
    function isWithinRange(date, startDate, endDate) {
      const dateObj = new Date(date);
      const startObj = new Date(startDate);
      const endObj = new Date(endDate);
  
      return dateObj >= startObj && dateObj <= endObj;
    }
}