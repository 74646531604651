import { createAction } from '@reduxjs/toolkit';

const fetchFirstStepsDetails = createAction('clearExtras');
const fetchFirstStepsCardDetail = createAction('fetchFirstStepsCardDetail');
const cleanFilter = createAction("cleanFilter");
const cleanFirstGenerationData = createAction("cleanFirstGenerationData");
const toogleIsFirstGenerationActive = createAction("toogleIsFirstGenerationActive");
const activateFirstGeneration = createAction("activateFirstGeneration");
const filteringData = createAction("filteringData");
const cleanIncorporatedAndRestOfGroupData = createAction("cleanIncorporatedAndRestOfGroupData");
const firstStepsActiveError = createAction("firstStepsActiveError");
const isExtraFetchGenerationTrue = createAction("isExtraFetchGenerationTrue")
const isExtraFetchGenerationFalse = createAction("isExtraFetchGenerationFalse")

export  {fetchFirstStepsDetails, isExtraFetchGenerationFalse,isExtraFetchGenerationTrue,firstStepsActiveError,cleanFilter,filteringData, activateFirstGeneration, fetchFirstStepsCardDetail,cleanFirstGenerationData,toogleIsFirstGenerationActive,cleanIncorporatedAndRestOfGroupData} 