import { mexicoSchema } from "./mexicoSchema"
import { argentinaSchema } from "./argentinaSchema"
import { uySchema } from "./uySchema"

export const schemas = {
  "ar": argentinaSchema,
  "mx": mexicoSchema,
  "cl": mexicoSchema,
  "co": mexicoSchema,
  "cr": mexicoSchema,
  "pa": mexicoSchema,
  "pe": mexicoSchema,
  "uy": mexicoSchema,
}