import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput"
import ClientListFormSelect from "../elements/ClientListFormSelect"

export default function CostaRica({ watch, ...props }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')

  return (
    <>
     
      <ClientListFormSelect
        placeholder={"Seleccionar una Provincia"}
        label={"Provincia (opcional)"}
        isUpdate={isUpdate}
        name="province"
        father={true}
        watch={watch}
        {...props}
        level={1}
      />
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          placeholder={"Seleccionar un Cantón"}
          label={"Cantón (opcional)"}
          isUpdate={isUpdate}
          name="canton"
          father={watch('province')}
          watch={watch}
          {...props}
          level={2}
        />

      </div>
      <ClientListFormSelect
        placeholder={"Seleccionar Distrito"}
        label={"Distrito (opcional)"}
        isUpdate={isUpdate}
        name="district"
        father={watch('canton')}
        watch={watch}
        {...props}
        level={3}
        last
      />
        <div className=" px-3 pe-0">
       <ClientListFormOneColumnInput
        placeholder={"Ej.: Calle 28, Rio Oro"}
        label={"Dirección (opcional)"}
        name="street"
        {...props}
      />

      </div>
      <ClientListFormOneColumnInput
        placeholder={"Ej.: Condominio Villamont Casa 16"}
        label={"Señas (opcional)"}
        name="signs"
        {...props}
      />

    </>
  )
}