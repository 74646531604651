import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { notificationId, notificationDetail, addSlider, setCurrentWeek, activeErrorNotification, activeErrorCalendar } from "./HeaderAction";

import {
  fetchExtLinks,
    getPeriod,
    getNotifications,
    getBadgeCounter,
    patchNotfication
} from "./HeaderSideEffect";

const initialState = {
  period: {},
  periodError: false,
  extLinks: null,
  notifications: [],
  notificationsError: false,
  notificationId: null,
  notificationDetail: null,
  notificationExtra: null,
  notificationSlider: 1,
  currentWeek: 1,
  badgeCounter: 0,
  loader: false,
};

const headerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.loader = false;
      state.notificationsError = false;
    })
    .addCase(getNotifications.rejected, (state, action) => {
      state.notificationsError = true;
      state.loader = false;
    })
    .addCase(getPeriod.fulfilled, (state, action) => {
      state.period = action.payload;
      state.loader = false;
      state.periodError = false;
    })
    .addCase(activeErrorNotification, (state, action) => {
      state.notificationsError = true;
    })
    .addCase(activeErrorCalendar, (state, action) => {
      state.periodError = true;
    })
    .addCase(fetchExtLinks.fulfilled , (state, action) => {
      state.extLinks = action.payload;
      state.loader = false;
    })

    .addCase(getBadgeCounter.fulfilled, (state, action) => {
      state.badgeCounter = action.payload;
    })

    .addCase(setCurrentWeek, (state, action) => {
      state.currentWeek = action.payload;
    })

    .addCase(notificationId, (state, action) => {
      state.notificationId = action.payload;
    } )

    .addCase(notificationDetail, (state, action) => {
      let array = state.notifications;
      let result = array.notifications.find(elm => elm.key === action.payload);
      state.notificationDetail = result;
      if (result.extras) {
        state.notificationExtra = JSON.parse(result?.extras?.replace('}"', "}")?.replace('"{', "{"))
      } else {
        state.notificationExtra = null;
      }
      state.notificationSlider = 1;
    } )

    .addCase(addSlider , (state, action) => {
      state.notificationSlider += 1
    } )

    .addCase(patchNotfication.fulfilled, (state,action) => {
      state.loader = false;
    })

    .addMatcher(
      
      isAnyOf(
        
        getPeriod.pending,
        fetchExtLinks.pending,
        getBadgeCounter.pending,
        getNotifications.pending,
        patchNotfication.pending
      ),
      (state, action) => {
        state.loader = false;
      }
    )
    .addMatcher(
      isAnyOf(
        getPeriod.rejected,
        fetchExtLinks.rejected,
        getBadgeCounter.rejected,
        patchNotfication.rejected
      ),
      (state, action) => {
        state.loader = false;
      }
    )

});

export default headerReducer;
