import ReturnToAndTitle from '../../../Share/ReturnToAndTitle';
import ContinueButton from './ContinueButton';

import { useMediaPredicate } from 'react-media-hook'

export default function   UserDataConfirmNewStreetAddress({travelFunction}) {

    const biggerThan992 = useMediaPredicate("(min-width: 992px)");
  return (
    <>
        <ReturnToAndTitle
          travelFunction={travelFunction}
          returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
          title={"settings.user.data.street.title"}
        />
        <div>
          <img
            src={
              biggerThan992
                ? "https://via.placeholder.com/500x200"
                : "https://via.placeholder.com/250x200"
            }
            alt="Imagen de google maps"
          />
        </div>
        <div className="d-flex mt-4 align-items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-geo-alt-fill text-primary"
            viewBox="0 0 16 16"
          >
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
          </svg>
          <p className="mb-0 ps-2">Bulnes 626, Buenos Aires</p>
        </div>
        <ContinueButton
          continueFunction={travelFunction}
          whereTo={1.9}
          text={"Confirmar"}
        />
      </>
  )
}
