import React, { useRef } from "react";

import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ModalWhiteShadow from "../../../common/Share/ModalWhiteShadow";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

export default function EDBDetailsModalPlan({ data, cultureInfo }) {
  const scrollbarRef = useRef(null);

  return (
    <div>
      <div className=" d-flex justify-content-between mx-3 p-4 my-3 align-items-center">
        <div className="d-flex">
          <HelpIconNoTarget />
          <h5 className="museo-sans-500 px-2 s20 m-0">
            <FormattedMessage
              id="edb.details.modal.title"
              defaultMessage="Entendido"
            />
          </h5>
        </div>
        <div
          className=" pointer "
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <SVGClose />
        </div>
      </div>
      <hr className="m-0"></hr>
      <div className="d-flex flex-column align-items-center">
        <div
          ref={scrollbarRef}
          style={{ maxHeight: "328px", overflowY: "scroll" }}
          className=" edb-noscroll mt-4 px-4 mx-3  "
        >
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage
              id="edb.details.modal.p.one"
              defaultMessage="Entendido"
            />
          </p>
          <p className="s16 py-2 museo-sans-700">
            <FormattedMessage
              id="edb.details.modal.ul.explain"
              defaultMessage="Entendido"
            />
          </p>
          <div>
            <p className="s16 py-2 museo-sans-700">
              <FormattedMessage
                id="edb.details.modal.li.level.one"
                defaultMessage="Entendido"
              />
            </p>
          </div>
          <div className="py-1">
            <p className="s16  museo-sans-500">
              <FormattedMessage
                id="edb.details.modal.li.saleEdb"
                defaultMessage="Entendido"
              />
              {data && (
                <>
                  {formatAmount(
                    data.hostProgressLevel[0].hostTargetAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
              a
              {data && (
                <>
                  {formatAmount(
                    data.hostProgressLevel[0].hostLevelLimitAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
            <p className="s16  museo-sans-500">
              {data && data.hostProgressLevel[0].percentage}%{" "}
              <FormattedMessage
                id="edb.details.modal.li.totalSale"
                defaultMessage="Entendido"
              />
            </p>
          </div>
          <div className="py-1">
            <p className="s16 py-2 museo-sans-700">
              <FormattedMessage
                id="edb.details.modal.li.level.two"
                defaultMessage="Entendido"
              />
            </p>
          </div>
          <div>
            <p className="s16  museo-sans-500">
              <FormattedMessage
                id="edb.details.modal.li.saleEdb"
                defaultMessage="Entendido"
              />
              {data && (
                <>
                  {formatAmount(
                    data.hostProgressLevel[1].hostTargetAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
              a
              {data && (
                <>
                  {formatAmount(
                    data.hostProgressLevel[1].hostLevelLimitAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
            <p className="s16  museo-sans-500">
              {data && data.hostProgressLevel[1].percentage}%{" "}
              <FormattedMessage
                id="edb.details.modal.li.totalSale"
                defaultMessage="Entendido"
              />
            </p>
          </div>
          <div className="py-1">
            <p className="s16 py-2 museo-sans-700">
              <FormattedMessage
                id="edb.details.modal.li.level.three"
                defaultMessage="Entendido"
              />
            </p>
          </div>
          <div>
            <p className="s16  museo-sans-500">
              <FormattedMessage
                id="edb.details.modal.li.saleEdb"
                defaultMessage="Entendido"
              />{" "}
              {data && (
                <>
                  {formatAmount(
                    data.hostProgressLevel[2].hostTargetAmount,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </>
              )}
            </p>
            <p className="s16  museo-sans-500">
              {data && data.hostProgressLevel[2].percentage}%{" "}
              <FormattedMessage
                id="edb.details.modal.li.totalSale"
                defaultMessage="Entendido"
              />
            </p>
          </div>
        </div>
        <ModalWhiteShadow
          left={"5%"}
          width={"90%"}
          scrollbarRef={scrollbarRef}
          aidHide={150}
        />
        <button
          type="button"
          className=" btnLoginWidth my-5 museo-sans-700 s14 skyBg skyBorder whiteColor button-hover-light-text button-bootstrap-radius button-padding"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <FormattedMessage
            id="buttons.understood"
            defaultMessage="Entendido"
          />
        </button>
      </div>
    </div>
  );
}
