import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { openVimeo } from "../../../common/redux/commonActions";
import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as SVGVideo } from "../../../common/svgs/ic-video-sm.svg";

export default function GoodPracticesECommerceCard({ cardData }) {
  const sortColors = (color) => {
    if (color === "completed") {
      return { color: "#16BE7D" };
    }
    if (color === "warning") {
      return { color: "#DCB632" };
    }
    if (color === "risk") {
      return { color: "#F06E69" };
    }
    if (color === "normal") {
      return { color: "#DDDDDD" };
    }
  };

  useEffect(() => {

  }, []) 

  const dispatch = useDispatch();

  const onClick = () => {
    if (cardData.links[0]?.type === "deep_link") {
      return;
    }
    let values = cardData.links[0];
    dispatch(openVimeo({ id: values.id, key: values.key}));
  };

  return (
    <div
      style={{
        borderRadius: "1rem",
      }}
      className=" position-relative gg-cards--ecommerce bg-white f9GrayBorder desc-cards-shadow d-flex flex-column justify-content-between gap-3 p-4"
    >
      <div className=" d-flex flex-column gap-3" >
        <div style={{ alignItems: "center", height: "31px" }} className="d-flex">
          {cardData?.title?.type === "ratio" ? (
            <>
              <h4
                style={sortColors(cardData.title.items[0].status)}
                className="m-0 s26 dm-sans-bold "
              >
                {cardData.title.items[0].value}
              </h4>
              <span
                style={sortColors(cardData.title.items[1].status)}
                className="m-0 s26 dm-sans-bold "
              >
                /{cardData.title.items[1].value}
              </span>
            </>
          ) : (
            <h4
              style={sortColors(cardData.title.items[0].status)}
              className="m-0 s26 dm-sans-bold "
            >
              {cardData.title.items[0].value}
            </h4>
          )}
        </div>
        <p className=" s14 museo-sans-500 ">{cardData.description}</p>
      </div>
      <div onClick={onClick} className=" position-relative skyColor d-flex pointer museo-sans-700 s14">
        <div className="d-flex align-items-center gap-1" >
          <p className=" gap-1" >
            {cardData.links[0].title}
            {" "}
              {cardData.links[0]?.type === "deep_link" ? (
                <SVGRightArrow />
              ) : (
                <SVGVideo />
              )}

          </p>
        </div>
      </div>
    </div>
  );
}
