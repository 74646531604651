import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SVGCloseGreen } from "../svgs/ic-x-sm-green.svg";
import { ReactComponent as SVGCloseRed} from "../svgs/ic-x-sm.svg"

export default function ShareModalClipboard({ data, close }) {

  const history = useNavigate();
  const [onView, setOnView] = useState(false);

  const styles ={
    1: {width: "392px", height: "74px", color: "#62221F", backgroundColor: "#FEF0F0"},
    2: {width: "392px", height: "74px"}
  };

  const onWebMovement = () => {
    const closeModalClick = document.getElementById("modal--clipboard--close");
    if (onView && closeModalClick) {
      closeModalClick.click();
      setOnView(false);
    };
    if (!onView && closeModalClick) {
      setOnView(true);
    };
  };

  useEffect(() => {
    onWebMovement();
  }, [history])


  return (
    data.show && (
      <div className="" >
        <div style={{ bottom: data.bottom ? data.bottom : 0, left: data.left ? data.left : 0, zIndex: 1000}} className=" p-3 spacing--modal greenColor position-fixed" >
        <div style={data.status === "confirm" ? styles[0] : styles[1] } className=" shadow  py-3 " id="modal--clipboard">
          <i
            style={{ justifySelf: "center" }}
            className="  bi bi-check-circle"
          ></i>
          <div>
            <p className=" museo-sans-700 s14 ">
              {data.title ?? "Datos copiados"}
            </p>
            <p className=" museo-sans-500 s14 ">
              {data.text ?? "Se han copiado los datos en el portapapeles."}
            </p>
          </div>
          <div
          id="modal--clipboard--close"
            onClick={close}
            style={{ justifySelf: "center " }}
            className="pointer"
          >
            {
              data.status === "confirm" ?
              <SVGCloseGreen />
              :
              <SVGCloseRed />
            }
          </div>
        </div>
      </div>
      </div>
    )
  );
}
