import { useDispatch, useSelector } from 'react-redux';
import { changePeriodForButton } from '../../Framework/redux/performanceActions';
import { useNavigate, useParams } from 'react-router-dom';

import { getBilledOrders } from '../../Framework/redux/performanceSideEffects';
import { FormattedMessage } from 'react-intl';
import { multiLangGetCountry } from '../../../common/Share/MultiLangGetCountry';
import { formatAmount } from '../../../common/Share/FormatCurrency';

export default function PersonalSalesPeriod({data, toggler}) {

    let { consultantId } = useParams();
    let { currentPeriod } = useParams();
    
    const dispatch = useDispatch()
    const history = useNavigate();
    const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)
    function callForPeriod(periodNumber){
        history(`/dashboard/performance/personalSales/period/${data.period}`)
        //dispatch(getUnsendedOrders({yearPeriod: periodNumber, consultantId: consultantId ? consultantId : null }))
        //dispatch(getPendingOrders({yearPeriod: periodNumber, consultantId: consultantId ? consultantId : null }))
        dispatch(getBilledOrders({yearPeriod: periodNumber, consultantId: consultantId ? consultantId : null }))
        toggler();
        dispatch(changePeriodForButton(data.period))

    }

    function defineDates(start, end){
        const startDay = String(new Date(start).toLocaleDateString(multiLangGetCountry(), {day: "numeric"})).padStart(2, "0")
        const startMonth = String(new Date(start).toLocaleDateString(multiLangGetCountry(), {month: "short"}))

        const endDay = String(new Date(end).toLocaleDateString(multiLangGetCountry(), {day: "numeric"})).padStart(2, "0")
        const endMonth = String(new Date(end).toLocaleDateString(multiLangGetCountry(), {month: "short"}))
        const endYear = String(new Date(end).getFullYear())

        return startDay.concat(" ", startMonth, " ", "/" ," ", endDay, " ", endMonth, " ", endYear)
    }

  return (
    <div onClick={() => callForPeriod(data.yearPeriod)} className='ps-2 pe-2 pt-3 pb-3 border-bottom hover-pointer'>
        <p className='grayColor museo-sans-500 s12 pb-3'>{defineDates(data?.periodStartDate, data?.periodEndDate)}</p>
        <div className='d-flex justify-content-between'>
            <p className={`museo-sans-500 s16 ${currentPeriod == data?.period && "skyColor"}`}><FormattedMessage id="personal.sales.period.button.label" defaultMessage="Período" /> {data.period}</p>
            <p className={`s16 ${currentPeriod == data?.period ? "dm-sans-bold" : "dm-sans-medium"}`}>
            {formatAmount(data?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
            </p>
        </div>
    </div>
  )
}
