import { FormattedMessage } from "react-intl";
import {ReactComponent as ChevronRightSVG} from '../../common/svgs/ic-chevron-right-md.svg'

export default function SkeletonEDBRowList({noWidth}) {

    const gridTemplate = {display: "grid", gap: "2%", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 0.2fr" }
    
    return(
      <div style={gridTemplate} className=" w-100 ps-3 pe-2 s12 museo-sans-500 grayColor text-uppercase justify-content-between align-items-center row-height">
            <div className="lightui1-shimmer brightGrayBg rounded-3 " style={ !noWidth ? {  width: "40%", height: "8px" } : {width: "auto", height: "12px"}}>
                <p className=" invisible " >
              <FormattedMessage id="edb.list.table.time" defaultMessage="" />

                </p>
            </div>
            <div className="lightui1-shimmer brightGrayBg rounded-3 " style={ !noWidth ? { width: "40%", height: "8px" } : {width: "auto", height: "12px"}}> 
                <p className=" invisible " >
              <FormattedMessage id="edb.list.table.theme" defaultMessage="" />

                </p>
            </div>
            <div className="lightui1-shimmer brightGrayBg rounded-3 " style={ !noWidth ? { width: "40%", height: "8px" } : {width: "auto", height: "12px"}}>
                <p className=" invisible " >
              <FormattedMessage id="edb.list.table.host" defaultMessage="" />

                </p>
            </div>
            <div className="lightui1-shimmer brightGrayBg rounded-3 " style={ !noWidth ? { width: "40%", height: "8px" } : {width: "70%", height: "12px"}}>
                <p className=" invisible " >
              <FormattedMessage id="edb.list.table.type" defaultMessage="" />

                </p>
            </div>
            <div className="lightui1-shimmer brightGrayBg rounded-3 " style={ !noWidth ? { width: "40%", height: "8px" } : {width: "70%", height: "12px"} }>
                <p className=" invisible " >
              <FormattedMessage id="edb.list.table.status" defaultMessage="" />

                </p>
            </div>
            <div className="lightui1-shimmer brightGrayBg rounded-3 " style={ !noWidth ? { width: "40%", height: "8px" } : {width: "70%", height: "12px"}}>
                <p className=" invisible " >
              <FormattedMessage id="edb.list.table.guest" defaultMessage="" />

                </p>
            </div>
            <div className=" rounded-3 " style={ !noWidth ? { width: "5%" } : {width: "auto"} }>
                {
                    noWidth &&
                    <ChevronRightSVG />
                }
            </div>
        </div>
    )
}