import React from "react";

import { FormattedMessage } from "react-intl";

export default function TermsAndConditionsContent({ showTitle }) {
  return (
    <>
      {showTitle && (
        <p className="museo-sans-700 s20 mb-4">
          <FormattedMessage id="politics.meta.terms" />
        </p>
      )}
      <p
        className={`grayColor mb-2 museo-sans-500 s12 ${!showTitle && "mt-4"}`}
      >
        <FormattedMessage id="terms.and.conditions.current.version" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.current.limitation" />
      </p>

      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.1" />
    
        <span className="museo-sans-700 s16">

        <FormattedMessage
          id="terms.and.conditions.description.1b"
          defaultMessage="lo que sigue"
        />
        </span>
      
        <FormattedMessage
          id="terms.and.conditions.description.1c"
          defaultMessage="lo que sigue"
        />
      
        <span className="museo-sans-700 s16">
          
        <FormattedMessage
          id="terms.and.conditions.description.1d"
          defaultMessage="lo que sigue"
        />
        </span>
      
        <FormattedMessage
          id="terms.and.conditions.description.1e"
          defaultMessage="lo que sigue"
        />
        
        <a href="https://www.justperu.com/" target="_blank">

        <FormattedMessage
          id="terms.and.conditions.description.1f"
          defaultMessage="lo que sigue"
        />
        </a>
        
        <FormattedMessage
          id="terms.and.conditions.description.1g"
          defaultMessage="lo que sigue"
        />
       
        <span className="museo-sans-700 s16">

        <FormattedMessage
          id="terms.and.conditions.description.1h"
          defaultMessage="lo que sigue"
        />
        </span>
      </p>

      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.2" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.3" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.4" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.5" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.7" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.8" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.9" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.10" />
      </p>
      <p className="museo-sans-700 s16">
        <FormattedMessage id="terms.and.conditions.description.11" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.12" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.13" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.15" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.16" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.17" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.18" />
      </p>
      <div>

      <p className="museo-sans-500 s16 mb-4">
        <span className="museo-sans-700 s16 ">
        
        <FormattedMessage id="terms.and.conditions.description.point" />
        
        <FormattedMessage id="terms.and.conditions.description.19" />
      
        </span>
        <FormattedMessage id="terms.and.conditions.description.20" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
      <span className="museo-sans-700 s16">
      <FormattedMessage id="terms.and.conditions.description.point" />
      

        <FormattedMessage id="terms.and.conditions.description.21" />
      
      </span>
        <FormattedMessage id="terms.and.conditions.description.22" />
      </p>
      </div>
      <p className="museo-sans-500 s16 ">
      <FormattedMessage id="terms.and.conditions.description.point" />
 
        <FormattedMessage id="terms.and.conditions.description.23" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
      <FormattedMessage id="terms.and.conditions.description.point" />
 
        <FormattedMessage id="terms.and.conditions.description.24" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.25" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.26" />
        <a href="mailto:servicioalcliente@justperu.com" >
          
        <FormattedMessage id="terms.and.conditions.description.27" />
        </a>
        <FormattedMessage id="terms.and.conditions.description.28" />
      </p>
      <div>

      </div>
      <p className="museo-sans-700 s16">
        <FormattedMessage id="terms.and.conditions.description.29" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.31" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.32" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.33" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.34" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.35" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.36" />
      </p>
      <p className="museo-sans-500 s16 mb-4 ">
        <FormattedMessage id="terms.and.conditions.description.36" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.37" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.38" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.39" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.40" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.41" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.42" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.43" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.44" />
        <a href="https://www.justperu.com/page/privacy" target="_blank" >

        <FormattedMessage id="terms.and.conditions.description.45" />
        </a>
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.46" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.47" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.48" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.49" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.50" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.51" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.52" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.53" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.54" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.55" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.56" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.57" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.57" />
      </p>
      <p className="museo-sans-700 s16 mb-4 ">
        <FormattedMessage id="terms.and.conditions.description.58" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.59" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.60" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.61" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.62" />
      
        <a href="mailto:servicioalcliente@justperu.com" >
          
          <FormattedMessage id="terms.and.conditions.description.27" />
          </a>
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.64" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.65" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.66" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.67" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.68" />
      </p>
      <p className="museo-sans-500 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.69" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.70" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.71" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.72" />
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.73" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.74" />
        <a href="mailto:servicioalcliente@justperu.com">
          
          <FormattedMessage id="terms.and.conditions.description.27" />
          </a>
      </p>
      <p className="museo-sans-700 s16 ">
        <FormattedMessage id="terms.and.conditions.description.76" />
      </p>
      <p className="museo-sans-700 s16 mb-4">
        <FormattedMessage id="terms.and.conditions.description.77" />
      </p>
    </>
  );
}
