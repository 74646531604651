import ProgressBarParams from "./ProgressBarParams";

import { ReactComponent as SVGCircleYellow } from "../../common/svgs/ic-alert-circle-sm.svg";
import { ReactComponent as SVGCircleRed } from "../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as SVGCircleGreen } from "../../common/svgs/ic-check-circle-sm.svg";
import { ReactComponent as Purple } from "../../common/svgs/ic-req-red.svg";
import checkReduceRequirement from "./checkReduceRequirement";

export function ShareAddProgressBar(actual, total, level, status) {
    let aux = [];
    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);
    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarParams
           height={6}
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarParams
           height={6}
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else {
        aux.push(
          <ProgressBarParams
           height={6}
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      }
    }
    return aux;
}

export function ShareQualificationStatusDesc (boolean, level, data) {
  const responseJSXbyStatus = {
    completed: <SVGCircleGreen />,
    risk: <SVGCircleRed />,
    pending: <SVGCircleYellow />,
    warning: <SVGCircleYellow />,
    normal: <SVGCircleYellow />,
    gap: <SVGCircleYellow />,
  }

  const responseToggleByStatus = {
    completed: false,
    risk: false,
    pending: false,
    warning: false,
    normal: true,
    gap: true,
  }

    if (level < 2) {

      return(
        <div style={{width: "38px"}} >

        <div
            style={{ width: "24px", height: "24px", visibility: responseToggleByStatus[data.triperiodicSales.status] ? "hidden" : "visible" }}
            className="svg-force-size  desc--svg-adjustment"
          >
            {
              responseJSXbyStatus[data.triperiodicSales.status]
            }
          </div>
        </div>
      )
    } else {
      return (
        <div
          style={{ width: "38px" }}
          className=" position-relative d-flex justify-content-center flex-column gap-2 "
        >
          <div>
            <ProgressBarParams
             height={6}
              completed={
                (data.personalSales.actualAmount * 100) /
                data.personalSales.totalAmount
              }
              incompleted={
                data.personalSales.actualAmount <
                data.personalSales.totalAmount
              }
              gap={data.personalSales.status}
              status={data.personalSales.status}
              level={level}
            />
          </div>
          <div className="d-flex gap-1">
            {ShareAddProgressBar(
              data.incorporatedSales.actualActives,
              data.incorporatedSales.totalNeed,
              level,
              data.incorporatedSales.status
            )}
          </div>
          <div>
            <ProgressBarParams
             height={6}
              completed={
                (data.groupSales.actualAmount * 100) /
                data.groupSales.totalAmount
              }
              incompleted={
                data.groupSales.actualAmount < data.groupSales.totalAmount 
              }
              gap={data.groupSales.status}
              status={data.groupSales.status}
              level={level}
            />
          </div>
          {
            checkReduceRequirement(data.consultantStatus?.reducedRequirements ) &&
            <>
              <div style={{width: "12px", height: "12px", right: "-6px", border: "1px white solid", bottom: "-6px"}} className=" rounded-circle bg-white position-absolute svg-force-size " >
                <div style={{top: "-8px", height: "12px"}} className=" position-relative" >
                  <Purple/>

                </div>
              </div>
            </>
          }
        </div>
      );
    }
  };