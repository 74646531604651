import { createReducer } from "@reduxjs/toolkit";
import { checkLevel, clearAllCards, clearClientDetailsForm, closeNotificationDeletePopUp, closePostUpdate, openNotificationDeletePopUp, openPostUpdate, resetSucessToast } from "./ClientListAction";
import {
  getClientDetails,
  getClientList,
  getClientDetailsLinks,
  deleteClient,
  getTerritoryByLevel,
  postClient,
  putClient,
  getContactCards
} from "./ClientListSideEffect";

const initialState = {
  levelFromComponent: 1,
  clientTable: {
    data: null,
    error: false,
    loader: false,
  },
  clientDetails: {
    data: null,
    error: false,
    loader: false,
    success: null
  },
  clientDetailsCards: {
    data: null,
    error: false,
    loader: false,
  },
  clientDetailsLinks: {
    data: null,
    error: false,
    loader: false,
  },
  clientFormTerritory: {
    data: {
      levelOne: null,
      levelTwo: null,
      levelThree: null,
      levelFour: null,
    },
    error: false,
    loader: false,
  },
  actionDelete: false,
  actionPostOrUpdate: false,
};

const clientListReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(clearAllCards, (state, actions) => {
    state.clientDetailsCards.data = null;
    state.clientDetailsCards.error = false;
    state.clientDetailsCards.loader = false;
  })
  .addCase(getContactCards.fulfilled, (state, actions) => {
    state.clientDetailsCards.data = actions.payload;
    state.clientDetailsCards.error = false;
    state.clientDetailsCards.loader = false;
  })
  .addCase(getContactCards.pending, (state, actions) => {
    state.clientDetailsCards.data = actions.payload;
    state.clientDetailsCards.error = false;
    state.clientDetailsCards.loader = true;
  })
  .addCase(getContactCards.rejected, (state, actions) => {
    state.clientDetailsCards.data = actions.payload;
    state.clientDetailsCards.error = true;
    state.clientDetailsCards.loader = false;
  })
  .addCase(getClientList.fulfilled, (state, actions) => {
    state.clientTable.data = actions.payload;
    state.clientTable.error = false;
    state.clientTable.loader = false;
  })
  .addCase(getClientList.pending, (state, actions) => {
    state.clientTable.error = false;
    state.clientTable.loader = true;
  })
  .addCase(getClientList.rejected, (state, actions) => {
    state.clientTable.error = true;
    state.clientTable.loader = false;
  })
  
  .addCase(checkLevel, (state,action) => {
    state.levelFromComponent = action.payload;
  })
  .addCase(closePostUpdate, (state, actions) => {
    state.actionPostOrUpdate = false;
  })

  .addCase(openPostUpdate, (state, actions) => {
    state.actionPostOrUpdate = true;
  })

  .addCase(openNotificationDeletePopUp, (state, actions) => {
    state.actionDelete = true
  })

  .addCase(closeNotificationDeletePopUp, (state, actions) => {
    state.actionDelete = false
  })

  .addCase(deleteClient.fulfilled, (state, actions) => {
    //state.clientDetails.data = null;
  })
  .addCase(clearClientDetailsForm, (state, actions) => {
    state.clientDetails.data = null;
  })
  .addCase(getTerritoryByLevel.fulfilled, (state, actions) => {
    state.clientFormTerritory.loader = true;

    let level = actions.payload.level;
    
    if (level == 1) {
      state.clientFormTerritory.data = {...initialState.clientFormTerritory.data, levelOne : actions.payload.data}
    }
    if (level == 2) {
      state.clientFormTerritory.data = {
        ...initialState.clientFormTerritory.data,
        levelOne : state.clientFormTerritory.data.levelOne,
        levelTwo : actions.payload.data
      }
    }
    if (level == 3) {
      state.clientFormTerritory.data.levelThree = actions.payload.data
      state.clientFormTerritory.data.levelFour = null
    }
    if (level == 4) {
      state.clientFormTerritory.data.levelFour = actions.payload.data
    }
    
    state.clientFormTerritory.loader = false;
    state.clientFormTerritory.error = false;

  })
  .addCase(getTerritoryByLevel.pending, (state, actions) => {
    state.clientFormTerritory.loader = true;
    state.clientFormTerritory.error = false;
  })
  .addCase(getTerritoryByLevel.rejected, (state, actions) => {
    state.clientFormTerritory.loader = false;
    state.clientFormTerritory.error = true;
  })
  
  .addCase(getClientDetailsLinks.fulfilled, (state, actions) => {
    state.clientDetailsLinks.data = actions.payload;
    state.clientDetailsLinks.error = false;
    state.clientDetailsLinks.loader = false;
  })
  .addCase(getClientDetailsLinks.pending, (state, actions) => {
    state.clientDetailsLinks.error = false;
    state.clientDetailsLinks.loader = true;
  })
  .addCase(getClientDetailsLinks.rejected, (state, actions) => {
    state.clientDetailsLinks.error = true;
    state.clientDetailsLinks.loader = false;
  })

  .addCase(getClientDetails.fulfilled, (state, actions) => {
    state.clientDetails.data = actions.payload;
    state.clientDetails.error = false;
    state.clientDetails.loader = false;
  })
  .addCase(getClientDetails.pending, (state, actions) => {
    state.clientDetails.error = false;
    state.clientDetails.loader = true;
  })
  .addCase(getClientDetails.rejected, (state, actions) => {
    state.clientDetails.error = true;
    state.clientDetails.loader = false;
  })

  .addCase(postClient.fulfilled, (state, actions) => {
    state.clientDetails.data = null;
    state.clientDetails.error = false;
    state.clientDetails.loader = false;
    state.clientDetails.success = {...actions.payload, type: 'created'}
  })
  .addCase(postClient.pending, (state, actions) => {
    state.clientDetails.error = false;
    state.clientDetails.loader = true;
  })
  .addCase(postClient.rejected, (state, actions) => {
    state.clientDetails.error = true;
    state.clientDetails.loader = false;
  })

  .addCase(putClient.fulfilled, (state, actions) => {
    state.clientDetails.data = null;
    state.clientDetails.error = false;
    state.clientDetails.loader = false;
    state.clientDetails.success = {...actions.payload, type: 'updated'};
  })
  .addCase(putClient.pending, (state, actions) => {
    state.clientDetails.error = false;
    state.clientDetails.loader = true;
  })
  .addCase(putClient.rejected, (state, actions) => {
    state.clientDetails.error = true;
    state.clientDetails.loader = false;
  })

  .addCase(resetSucessToast, (state, actions) => {
    state.clientDetails.success = null;
    state.actionPostOrUpdate = false;
  })

});

export default clientListReducer;
