import { FormattedMessage } from "react-intl";

import { ReactComponent as LogoFooter } from "../../common/svgs/Logo-Just-Footer.svg";
import ShareFlagIcons from "../Share/ShareFlagIcons";

import "./footer.css";

export default function Footer({ ref }) {

  const location = localStorage.getItem("country");
  
  return (
    <footer
      ref={ref}
      style={{ zIndex: 1 }}
      className=" position-relative museo-sans-500 s12 grayColor  shadow bg-white px-5 mt-auto"
    >
      <div className="d-flex py-3">
        <div
          style={{ width: "200px", height: "45px"}}
          className=" svg-force-size p-0 d-flex justify-content-end align-self-center center align-items-center"
        >
          <LogoFooter />
          <div
            style={{
              height: "100%",
              width: "1px",
              background: "#DDDDDD",
              marginLeft: "24px",
              marginRight: "24px",
            }}
          >
            <p className="invisible">.</p>
          </div>
          <div style={{marginRight: "48px"}}>
            <ShareFlagIcons code={location} height={"24px"} />
          </div>
        </div>
        <div className=" mx-3 px-2 d-flex w-100 justify-content-between">
          <div className="d-flex">
            <div className=" d-flex flex-column gap-2 pe-3 ">
              {location == "pe" ? null : (
                  <div
                    className=""
                    data-bs-toggle="modal"
                    href="#distributionAgreement"
                    role="button"
                  >
                    <p>
                      <FormattedMessage
                        id="politics.meta.distribute"
                        defaultMessage="Distribution agreement"
                      />
                    </p>
                  </div>
              )}

              <div
                className=""
                data-bs-toggle="modal"
                href="#ethicsCode"
                role="button"
              >
                <p>
                  <FormattedMessage
                    id="politics.meta.code"
                    defaultMessage="Ethic code"
                  />
                </p>
              </div>
            </div>
            { location !== "ar" &&
              <div
                className=""
                data-bs-toggle="modal"
                href="#termsAndConditions"
                role="button"
              >
                <div className="">
                  <p>
                    <FormattedMessage
                      id="politics.meta.terms"
                      defaultMessage="Terms and conditions"
                    />
                  </p>
                </div>
              </div>
            }
          </div>
          
          <div className="d-flex gap-2 flex-column align-items-end justify-content-end">
            <div className=" justify-content-end">
              <FormattedMessage id="version" defaultMessage={"Versión"} />:
              1.6.7
            </div>
            <div className="justify-content-end ">
              &copy; 2022.{" "}
              <FormattedMessage
                id="politics.meta.rights"
                defaultMessage="All rights reserved"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
