import React from 'react'

import {ReactComponent as VirtualTypeSVG} from '../../../common/svgs/ic-edb-virtual-sm.svg'
import {ReactComponent as PresencialTypeSVG} from '../../../common/svgs/ic-edb-presencial-sm.svg'
import {ReactComponent as TriangleWarningSVG} from '../../../common/svgs/ic-triangle-warning-sm.svg'
import {ReactComponent as CheckCircleSVG} from '../../../common/svgs/ic-check-circle-sm.svg'
import {ReactComponent as XCircleSVG} from '../../../common/svgs/ic-x-circle-sm.svg'
import {ReactComponent as ChevronRightSVG} from '../../../common/svgs/ic-chevron-right-md.svg'

import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { formatAmount } from '../../../common/Share/FormatCurrency'
import { useSelector } from 'react-redux'
import ShareGetDate from '../../../common/Share/ShareGetDate'

export default function EDBClosedRow({id, date, title, hostName, edbType, accumulatedAmount, amountStatus}) {

    const history = useNavigate();
    
    const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)

    function viewDetail() {
        history(`${id}`)
    }

    function edbStatusColor(status) {
        switch(status) {
            case "normal":
                return "blackColor"
            case "warning":
                return "darkYellowColor"
            case "completed":
                return "lightGreenColor"
            case "risk":
                return "redColor"
        }
    }

    function edbStatusIcon(status) {
        switch(status) {
            case "normal":
                return null
            case "warning":
                return <TriangleWarningSVG />
            case "completed":
                return <CheckCircleSVG />
            case "risk":
                return <XCircleSVG />
        }
    }

    const splitString = (str) => {
        if (str.length > 30) {
            let aux = str.slice(0,30);
            aux += "...";
            return aux;
        } else {
            return str;
        }
    }
  
  return ( 
        <div style={{height: "48px"}} className=' mt-4 pb-4 d-flex ps-3 pe-2 s12 museo-sans-500 border-bottom border-1 justify-content-between align-items-center row-height hover-pointer' onClick={viewDetail}>
            <div style={{width:"14%"}} className='dm-sans-medium s14 blackColor'>{ShareGetDate(date)}</div>
            <div style={{width:"21%"}} className='grayColor museo-sans-500 s14'>{splitString(title)}</div>
            <div style={{width:"20%"}} className='grayColor museo-sans-500 s14'>{hostName}</div>
            <div style={{width:"10%"}} className='text-capitalize grayColor museo-sans-500 s14 d-flex align-items-center'>
                {edbType}
                <div className=' brightGrayColor control--svg-filler-fill-gray control--svg-filler-gray ms-2 pb-1'>
                    {edbType === "virtual" ? <VirtualTypeSVG /> : <PresencialTypeSVG />}
                </div>
                
            </div>
            <div style={{width:"10%"}} className={`museo-sans-500 s14 grayColor`}><FormattedMessage id="edb.open.list.status.closed" defaultMessage="Cerrado" /></div>
            <div style={{width:"10%"}} className={`d-flex align-items-center hover-controller position-relative dm-sans-medium s14 ${edbStatusColor(amountStatus)}`} >
            {formatAmount(accumulatedAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                <div className='ms-2 pb-1'>
                    {edbStatusIcon(amountStatus)}
                </div>
                {
                amountStatus == "warning" &&
                <div style={{background: "#FFFDF5", border: "#F2D1491A 1px solid", width: "250px", minHeight: "50px", top: 30, right: 30, zIndex: 1}} className=' hover-target darkYellowColor rounded-3 shadow position-absolute  p-3 flex-column gap-2 museo-sans-500 s12 ' >
                    <p>
                        <FormattedMessage id='dashboard.modal.status.level.closed.one' />
                    </p> 
                    <p>
                        <FormattedMessage id='dashboard.modal.status.level.closed.two' />
                    </p>
                </div>
            }
            </div>
            <div style={{width:"5%"}} className='skyColor button-hover-svg-light-stroke'><ChevronRightSVG /></div>
        </div>
  )
}