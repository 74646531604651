export const levels = {
    1: 'levelOne',
    2: 'levelTwo',
    3: 'levelThree',
    4: 'levelFour'
}

export const months = [
    { label: 'Selecionar un mes', value: '', days: undefined },
    { label: 'Enero', value: '01', days: 31 },
    { label: 'Febrero', value: '02', days: 28 }, // Sin considerar año bisiesto
    { label: 'Marzo', value: '03', days: 31 },
    { label: 'Abril', value: '04', days: 30 },
    { label: 'Mayo', value: '05', days: 31 },
    { label: 'Junio', value: '06', days: 30 },
    { label: 'Julio', value: '07', days: 31 },
    { label: 'Agosto', value: '08', days: 31 },
    { label: 'Septiembre', value: '09', days: 30 },
    { label: 'Octubre', value: '10', days: 31 },
    { label: 'Noviembre', value: '11', days: 30 },
    { label: 'Diciembre', value: '12', days: 31 }
  ];