import React from 'react'

import './Configuration.css'
import TermsAndConditionsContent from '../../../termsAndConditions/TermsAndConditionsContent'

export default function ConfigTermsAndConditions() {
  return (
    <>
        <div className='position-absolute shadow-effect-config'></div>
        <div className="position-relative h-100 overflow-auto remove-scrollbar config-section-container">
            <TermsAndConditionsContent showTitle={true}/>
        </div>
    </>
  )
}
