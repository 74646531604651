import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { useDispatch, useSelector } from "react-redux";

import "./performance.css";

import { ReactComponent as HelpIcon } from "../../../common/svgs/ic-help-circle-md.svg";
import PersonalSales from "./../components/PersonalSales";
import GroupSales from "./GroupSales";
import IncorporationsSales from "./IncorporationsSales";
import DescendantSales from "./DescendantSales";
import QualifiedGroups from "./QualifiedGroups";
import {
  defineFiltersApplied,
  resetFilters,
} from "../../Framework/redux/performanceActions";
import DashboardQualificationPotentialLeaderModal from "../../../Dashboard/Presentation/components/Modals/DashboardQualificationPotentialLeaderModal";

export default function Performance() {
  const dispatch = useDispatch();

  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");

  const profileData = useSelector((state) => state.userInfo);

  const history = useNavigate();

  let { listType } = useParams();

  useEffect(() => {
    let elemento = document.getElementById(listType);
    if (elemento) {
      elemento.click();
    }
    if (!elemento) {
      history("/dashboard");
    }
  }, [listType]);

  const [value, setValue] = useState(null);

  function switchConfig(param) {
    const componentsMap = {
      1: <PersonalSales />,
      2: <GroupSales />,
      3: <IncorporationsSales />,
      4: <DescendantSales />,
      5: <QualifiedGroups />,
    };
    
    return componentsMap[param]
  };

  function flapAndUrl(flapNumber, route) {
    dispatch(defineFiltersApplied(false));
    dispatch(resetFilters());
    setValue(flapNumber);

    if (listType !== route) {
      history(`../performance/${route}`);
    }
  };

  return (
    <div className="mt-5">
      <DashboardQualificationPotentialLeaderModal />
      <div className="container--padding--card-holder" >
        <div>
          <div className={` w-100 container--padding container--no-padding d-flex align-items-end mt-3`}>
            {profileData.userInfo.level < 2 ? (
              <>
                <button
                  onClick={() => flapAndUrl(1, "personalSales")}
                  id="personalSales"
                  type="button"
                  className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                    value === 1 ? "bronceBg blackColor" : "lightGreyBg  grayColor"
                  }`}
                >
                  {!biggerThan1280 ? "Vta. personal" : "Venta personal"}
                </button>
                <button
                  onClick={() => flapAndUrl(3, "personalIncorporated")}
                  
                  id="personalIncorporated"
                  type="button"
                  className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                    value === 3 ? "bronceBg blackColor" : "lightGreyBg  grayColor"
                  }`}
                >
                  {!biggerThan1280
                    ? "Incorp. Personales c/ Vta."
                    : "Incorporados Personales c/ ventas"}{" "}
                  {value == 3 && (
                    <a
                      href="#"
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#performanceHelpModal"
                    >
                      <HelpIcon />
                    </a>
                  )}
                </button>
              </>
            ) : (
              <>
                {profileData.userInfo.level < 3 ? (
                  <>
                    <button
                      onClick={() => flapAndUrl(1, "personalSales")}
                      id="personalSales"
                      type="button"
                      className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                        value === 1
                          ? "bronceBg blackColor"
                          : "lightGreyBg grayColor"
                      }`}
                    >
                      {!biggerThan1280 ? "Vta. personal" : "Venta personal"}
                    </button>
                    
                    <button
                      onClick={() => flapAndUrl(3, "personalIncorporated")}
                      style={{boxShadow: "none"}}
                      id="personalIncorporated"
                      type="button"
                      className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                        value === 3
                          ? "bronceBg blackColor"
                          : "lightGreyBg grayColor"
                      }`}
                    >
                      {!biggerThan1280
                        ? "Incorp. Personales c/ Vta."
                        : "Incorporados Personales c/ ventas"}{" "}
                      {value == 3 && (
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#performanceHelpModal"
                        >
                          <HelpIcon />
                        </a>
                      )}
                    </button>
                    <button
                      onClick={() => flapAndUrl(2, "groupSales")}
                      id="groupSales"
                      type="button"
                      className={`p-1 ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                        value === 2
                          ? "bronceBg blackColor"
                          : "lightGreyBg grayColor"
                      }`}
                    >
                      {!biggerThan1280 ? "Vta. grupal" : "Venta grupal"}{" "}
                      {value == 2 && (
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#performanceHelpModal"
                        >
                          <HelpIcon />
                        </a>
                      )}
                    </button>
                    <button
                        onClick={() => flapAndUrl(5, "qualifiedGroups")}
                        id="qualifiedGroups"
                        type="button"
                        className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                          value === 5
                            ? "bronceBg blackColor"
                            : "lightGreyBg grayColor"
                        }`}
                      >
                        <FormattedMessage id="potential.leaders" />
                        {value == 5 && (
                          <a
                            href="#"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#potentialLeader"
                          >
                            <HelpIcon />
                          </a>
                        )}
                    </button>
                  </>
                ) : (
                  <>
                  {
                    profileData.userInfo.level < 6 ?
                    <>
                      <button
                      onClick={() => flapAndUrl(1, "personalSales")}
                      id="personalSales"
                      type="button"
                      className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                        value === 1
                          ? "bronceBg blackColor"
                          : "lightGreyBg grayColor"
                      }`}
                    >
                      {!biggerThan1280 ? "Vta. personal" : "Venta personal"}
                    </button>
                    
                    <button
                      onClick={() => flapAndUrl(3, "personalIncorporated")}
                      style={{boxShadow: "none"}}
                      id="personalIncorporated"
                      type="button"
                      className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                        value === 3
                          ? "bronceBg blackColor"
                          : "lightGreyBg grayColor"
                      }`}
                    >
                      {!biggerThan1280
                        ? "Incorp. Personales c/ Vta."
                        : "Incorporados Personales c/ ventas"}{" "}
                      {value == 3 && (
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#performanceHelpModal"
                        >
                          <HelpIcon />
                        </a>
                      )}
                    </button>
                    <button
                      onClick={() => flapAndUrl(2, "groupSales")}
                      id="groupSales"
                      type="button"
                      className={`p-1 ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                        value === 2
                          ? "bronceBg blackColor"
                          : "lightGreyBg grayColor"
                      }`}
                    >
                      {!biggerThan1280 ? "Vta. grupal" : "Venta grupal"}{" "}
                      {value == 2 && (
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#performanceHelpModal"
                        >
                          <HelpIcon />
                        </a>
                      )}
                    </button>
                    <button
                      onClick={() => flapAndUrl(5, "qualifiedGroups")}
                      id="qualifiedGroups"
                      type="button"
                      className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                        value === 5
                          ? "bronceBg blackColor"
                          : "lightGreyBg grayColor"
                      }`}
                    >
                      <FormattedMessage id="nav.label.one.e" />
                      {value == 5 && (
                        <a
                          href="#"
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#qualifiedGroupsModal"
                        >
                          <HelpIcon />
                        </a>
                      )}
                    </button>
                    </>
                    :
                    <>
                      <button
                        onClick={() => flapAndUrl(1, "personalSales")}
                        id="personalSales"
                        type="button"
                        className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                          value === 1
                            ? "bronceBg blackColor"
                            : "lightGreyBg grayColor"
                        }`}
                      >
                        {!biggerThan1280 ? "Vta. personal" : "Venta personal"}
                      </button>
                      
                      <button
                        onClick={() => flapAndUrl(3, "personalIncorporated")}
                        id="personalIncorporated"
                        type="button"
                        style={{boxShadow: "none"}}
                        className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                          value === 3
                            ? "bronceBg blackColor"
                            : "lightGreyBg grayColor"
                        }`}
                      >
                        {!biggerThan1280
                          ? "Incorp. Personales c/ Vta."
                          : "Incorporados Personales c/ ventas"}{" "}
                        {value == 3 && (
                          <a
                            href="#"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#performanceHelpModal"
                          >
                            <HelpIcon />
                          </a>
                        )}
                      </button>
                      <button
                        onClick={() => flapAndUrl(2, "groupSales")}
                        id="groupSales"
                        type="button"
                        className={`p-1 ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                          value === 2
                            ? "bronceBg blackColor"
                            : "lightGreyBg grayColor"
                        }`}
                      >
                        {!biggerThan1280 ? "Vta. grupal" : "Venta grupal"}{" "}
                        {value == 2 && (
                          <a
                            href="#"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#performanceHelpModal"
                          >
                            <HelpIcon />
                          </a>
                        )}
                      </button>
                      <button
                        onClick={() => flapAndUrl(4, "descendantSales")}
                        id="descendantSales"
                        type="button"
                        className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                          value === 4
                            ? "bronceBg blackColor"
                            : "lightGreyBg grayColor"
                        }`}
                      >
                        {/*<FormattedMessage id='edb.list.table.col.closed'/>*/}
                        {!biggerThan1280
                          ? "Vta. descendencia"
                          : "Venta descendencia"}{" "}
                        {value == 4 && (
                          <a
                            href="#"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#performanceHelpModal"
                          >
                            <HelpIcon />
                          </a>
                        )}
                      </button>
                      <button
                        onClick={() => flapAndUrl(5, "qualifiedGroups")}
                        id="qualifiedGroups"
                        type="button"
                        className={`p-1 menu-shadow ps-4 pe-4 s14 performance-flap-button museo-sans-500 s14 ${
                          value === 5
                            ? "bronceBg blackColor"
                            : "lightGreyBg grayColor"
                        }`}
                      >
                        <FormattedMessage id="nav.label.one.e" />
                        {value == 5 && (
                          <a
                            href="#"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#qualifiedGroupsModal"
                          >
                            <HelpIcon />
                          </a>
                        )}
                      </button>

                    </>
                  }
                  </>
                )}
              </>
            )}
          </div>

        </div>
      </div>
      <div className="container--padding--card-holder" >
        <div className="w-100" >
          <div className=" w-100 container--padding pt-3 pb-4 position-relative bronceBg flap-content-container">
            {switchConfig(value)}
          </div>
        </div>
      </div>
    </div>
  );
}
