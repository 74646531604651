import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import Radius from "../../../common/Share/Radius";
import ShareComponentInput from "../../../common/Share/ShareComponentInput";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import { ShareRadio } from "../../../common/Share/ShareRadio";
import { ErrorMessage } from "../pages/EDBsForm";
import EDBsFormRouter from "./EDBsFormRouter";
import EDBModalVirtual from "./modals/EDBModalVirtual";

export default function EDBsFormVirtual() {
  const [isPrivate, setIsPrivate] = useState(false);
  const [virtual, setVirtual] = useState({
    url: "",
    password: "",
    streaming: "",
  });
  const [linkIsValid, setLinkIsValid] = useState(false);
  const [streamingIsValid, setStreamingIsValid] = useState(true);
  const errorToggler = useSelector(
    (state) => state.edbFormData.errorTogger.value
  );

  const onVirtualChange = () => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));

    const updatedConference = { url: virtual.url, password: virtual.password };
    const updatedStreamingURL = { url: virtual.streaming };
    const updatedIsPublic = !isPrivate;

    const updatedAuxFormBody = {
      ...auxFormBody,
      conference: updatedConference,
      streaming: updatedStreamingURL,
      public: updatedIsPublic,
    };

    localStorage.setItem("formBody", JSON.stringify(updatedAuxFormBody));
  };

  const onPrivateChange = (value) => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    setIsPrivate(value);
    const updatedIsPublic = !value;
    const updatedAuxFormBody = { ...auxFormBody, public: updatedIsPublic };

    localStorage.setItem("formBody", JSON.stringify(updatedAuxFormBody));
  };

  const onChangeByName = (name, any, value) => {
    const updatedVirtual = { ...virtual, [name]: value };
    setVirtual(updatedVirtual);
    linkIsErrorIf(name, value);
  };

  const linkIsErrorIf = (name, value) => {
    // Validation Link - Web Site
    const regex = /^(https:\/\/|http:\/\/)(www\.)?([\w-]+(\.[\w-]+)+)(\/[\w- ;,./?%&=-]*)?$/i
    if (name == "url") {
      if (value?.trim() == "") {
        setLinkIsValid(false);
      } else {
        setLinkIsValid(regex.test(value));
      }
    } else {
      if (value?.trim() != "") {
        setStreamingIsValid(regex.test(value));
      } else {
        setStreamingIsValid(true);
      }
    }
  };

  useEffect(() => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    if (!auxFormBody) {
      return;
    }
    const { conference } = auxFormBody;
    const { streaming } = auxFormBody;

    if (!streaming || !conference) {
      return;
    }

    const updatedStreamingURL = streaming;
    const updatedConference = { ...conference };

    setVirtual({
      url: updatedConference?.url,
      password: updatedConference?.password,
      streaming: updatedStreamingURL?.url,
    });
    setIsPrivate(!auxFormBody.public);
  }, []);

  useEffect(() => {
    onVirtualChange();
  }, [virtual]);

  return (
    <div className="d-flex flex-column gap-3 s14">
      <ShareModalStructure maxWidth="496px" id={"modalVirtual"}>
        <EDBModalVirtual />
      </ShareModalStructure>
      <div className="d-flex gap-3">
        <div className=" w-50 d-flex flex-column gap-2 ">
          <ShareComponentInput
            errorToggler={errorToggler && !linkIsValid}
            name={"url"}
            onChange={onChangeByName}
            inputDate={false}
            value={virtual.url}
            helpIcon={"#modalVirtual"}
            label={<FormattedMessage id="edb.form.virtual.input.one" />}
            placeholder={
              multiLangJSONParse()["edb.form.virtual.input.placeholder.one"]
            }
          />
          <ErrorMessage
            messageId={virtual?.url?.trim() == "" ? "" : "client.form.validation.phone"}
            errorToggler={errorToggler && !linkIsValid}
          />
        </div>
        <div className=" w-50 ">
          <ShareComponentInput
            name={"password"}
            onChange={onChangeByName}
            inputDate={false}
            value={virtual.password}
            helpIcon={""}
            label={<FormattedMessage id="edb.form.virtual.input.two" />}
            placeholder={
              multiLangJSONParse()["edb.form.virtual.input.placeholder.two"]
            }
          />
        </div>
      </div>
      <div className="d-flex gap-3">
        <div className=" w-50 ">
          <ShareComponentInput
            errorToggler={virtual.streaming?.trim() && (errorToggler && !streamingIsValid)}
            name={"streaming"}
            onChange={onChangeByName}
            inputDate={false}
            value={virtual.streaming}
            helpIcon={""}
            label={<FormattedMessage id="edb.form.virtual.input.three" />}
            placeholder={
              multiLangJSONParse()["edb.form.virtual.input.placeholder.three"]
            }
          />
          {
            virtual.streaming?.trim() != "" && ( errorToggler && !streamingIsValid) &&
            <div className="mt-2">
            <ErrorMessage
                messageId={"client.form.validation.phone"}
                errorToggler={errorToggler && !streamingIsValid}
            />
            </div>

          }
        </div>
        <div className=" w-50 invisible ">
          <ShareComponentInput
            name={"form.date.name"}
            onChange={"handleFormChange"}
            inputDate={false}
            value={""}
            helpIcon={""}
            label={"form.date.label"}
          />
        </div>
      </div>
      <EDBsFormVirtualPrivateContainer isPrivate={isPrivate} onPrivateChange={onPrivateChange} />
    </div>
  );
}

export function EDBsFormVirtualPrivateContainer({ isPrivate, onPrivateChange }) {

  const arrayRadios = [
    {
      labelId: "edb.form.virtual.privacy.public",
      disabled: false,
      active: !isPrivate,
      onClick: () => onPrivateChange(false),
    },
    {
      labelId: "edb.form.virtual.privacy.private",
      disabled: false,
      active: isPrivate,
      onClick: () => onPrivateChange(true),
    },
  ];

  return(
    <div>
        <div className="my-2">
          <p className=" museo-sans-700 s14">
            <FormattedMessage id="edb.form.virtual.privacy.text" />
          </p>
          <div style={{gap: "32px"}} className="d-flex  pe-3 py-2 my-1 ">
          <ShareRadio gap={"8px"} arrayRadios={arrayRadios} />
          </div>
          <p className=" s14 museo-sans-500 grayColor">
            <FormattedMessage id="edb.form.virtual.privacy.help.text" />
          </p>
        </div>
      </div>
  )
}