const fetchCMSIfStepIdExist = (CMSData, planId) => {
  if (!CMSData || !planId) {
    return "";
  }

  let aux = "";
  CMSData.stepId.map((elm) => {
    if (elm?.stepId == planId) {
      aux = elm;
    }
  });

  return aux;
};

const replaceURLString = (string, file, id) => {
  if (!string || !file || !id) {
    return;
  }

  let auxiliar = string;

  auxiliar = auxiliar.replace("{image.id}", id);

  auxiliar = auxiliar.replace("{image.filename_download}", file);

  return auxiliar;
};

export { fetchCMSIfStepIdExist, replaceURLString };
