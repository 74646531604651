import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

export default function BusinessPlanModalInfo() {
  const scrollbarRef = useRef(null);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
        <div className="d-flex">
          <HelpIconNoTarget />
          <h5 className="museo-sans-500 px-2 s20 m-0">
            <FormattedMessage id="good.practice.modal.plan.title" />
          </h5>
        </div>
        <div className=" pointer " data-bs-dismiss="modal" aria-label="Close">
          <SVGClose />
        </div>
      </div>
      <hr className="m-0"></hr>
      <div
        ref={scrollbarRef}
        style={{ maxHeight: "372px" }}
        className=" hidden-y-scroll d-flex flex-column align-items-center"
      >
        <div className="mt-4 d-flex flex-column gap-3 museo-sans-500 s16 px-5">
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage
              id="good.practice.modal.plan.p.one"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage
              id="good.practice.modal.plan.p.two"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2 museo-sans-700">
            <FormattedMessage
              id="good.practice.modal.plan.ul.title"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage
              id="good.practice.modal.plan.li.one"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage
              id="good.practice.modal.plan.li.two"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage
              id="good.practice.modal.plan.li.three"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2 museo-sans-500">
            <FormattedMessage
              id="good.practice.modal.plan.li.four"
              defaultMessage="Buenas Prácticas"
            />
          </p>
        </div>
      </div>
      <ModalWhiteShadow left={"5%"} width={"90%"} scrollbarRef={scrollbarRef} />
      <div className=" w-100 d-flex justify-content-center">
        <button
          type="button"
          className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <FormattedMessage
            id="buttons.understood"
            defaultMessage="Entendido"
          />
        </button>
      </div>
    </div>
  );
}
