import { FormattedMessage } from "react-intl";

export default function DistributionAgreementContentPA(params) {
    return (
        <>
          <p className={` museo-sans-500 s16 mb-4 ${!params.showTitle && "mt-4"}`}>
            <b>1.</b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
          </p>
          <p className=" museo-sans-500 s16">
            <b>a.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.1" />
          </p>
          <p className=" museo-sans-500 s16">
            <b>b.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.2" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>c.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>2.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.2" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>3.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.3" />
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.solicitant.4" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.5" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>4.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>5.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
          </p>

          <p className="mb-2 museo-sans-500 s16">
            <b>6.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>7.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>8.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>9.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>10.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>11.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>12.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>13.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>14.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.14" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>15.</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.15" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.16" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.17" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.18" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.19" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.20" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.21" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.22" />
              ____________
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.23" />
              ________
            </b>
          </p>
          <p className="invisible mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="ethics.code.text.immigration.laws.1" />
              </u>
            </b>
            <FormattedMessage id="ethics.code.text.immigration.laws.1" />
          </p>
        </>
      );
}