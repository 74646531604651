import { ReactComponent as SVGClose} from "../svgs/ic-x-sm.svg"

export default function ModalPassChange ({show, close, data}) {

    return (
    show &&
    <div style={{ left: "5%", bottom: "5%"}} className=" shadow m-3 position-absolute greenColor py-3 " id="modal--clipboard">
        <i style={{justifySelf: "center"}} className=" greenColor bi bi-check-circle"></i>
        <div>
            <p className=" museo-sans-700 s14 ">
                Cambiaste tu contraseña
            </p>
            <p style={{maxWidth: "296px"}} className=" museo-sans-500 s14 ">
                Los cambios en tu contraseña se han guardadocon éxito
            </p>
        </div>
        <div onClick={close} style={{justifySelf: "center "}} className="pointer" >
            <SVGClose />
        </div>
    </div>)
}
