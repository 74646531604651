import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { formatAmount } from "../../../common/Share/FormatCurrency";

import { ReactComponent as SVGChevronRight } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as SVGCheckGreen } from "../../../Dashboard/Presentation/SVGs/checkGreen.svg";
import { ReactComponent as SVGCrossRed } from "../../../Dashboard/Presentation/SVGs/crossRed.svg";
import { ReactComponent as SVGGap } from "../../SVGs/warning.svg";
import { ReactComponent as SVGGrayCheck } from "../../SVGs/GapCheck.svg";
import { ReactComponent as SVGGreen } from "../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGFailed } from "../../../FirstSteps/Presentation/SVGs/NoAlcanzado/ic-x-circle-sm.svg";
import { ReactComponent as SVGIncomplete } from "../../../FirstSteps/Presentation/SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import OriginRoadModalOthers from "../components/OriginRoadModalOthers";
import { fetchCAO } from "../../Framework/redux/OriginRoadSideEffect";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";

export default function OriginRoadDashboardShowcase({S1}) {
  const country = localStorage.getItem("country").toUpperCase()
  const dispatch = useDispatch();
  const gender = useSelector(
    (state) => state.userInfo?.userInfo?.gender
  );
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const CAOData = useSelector((state) => state.CAOData.cao);

  const [toggler, setToggler] = useState({
    completed: true,
    risk: true,
    gap: true,
  });

  const onStartUp = async () => {
    await dispatch(fetchCAO());
  };

  const onControlStatus = (status, type) => {
    const arrayStatusSVGs = {
      REACHED: <SVGGreen />,
      ONGOING: <SVGIncomplete />,
      RISK_SALE: <SVGFailed />,
      RISK_GOALS: <SVGFailed />,
      REVERSING: <SVGFailed />,
      GAME_OVER: <SVGFailed />,
      GAP: <SVGGrayCheck />,
    };

    return arrayStatusSVGs[status];
  };

  const onLinkClick = () => {
    const clickMe = document.getElementById("cao-link");
    clickMe.click();
  };

  const objectColor = {
    "completed": "greenColor",
    "warning": "darkYellowColor",
    "risk": "lightRedColor",
    "normal": "",
    "gap": ""
  };

  const objectLink = {
    under: `https://appcontents-pub.s3.amazonaws.com/${country}/Bases_Viaje_Suiza_2025_Consultoras_${country}.pdf`,
    above: `https://appcontents-pub.s3.amazonaws.com/${country}/Bases_Viaje_Suiza_2025_Lideres_${country}.pdf`
  };

  const objectLevelDescription = {
    0: <FormattedMessage id={`level.one.${gender == "F" ? "f" : "m" }`} />,
    1: <FormattedMessage id={`level.one.${gender == "F" ? "f" : "m" }`} />,
    2: <FormattedMessage id="level.two" />,
    3: <FormattedMessage id="level.three" />,
    4: <FormattedMessage id={`level.four.${gender == "F" ? "f" : "m" }`} />,
    5: <FormattedMessage id="level.five" />,
    6: <FormattedMessage id={`level.six.${gender == "F" ? "f" : "m" }`} />,
    7: <FormattedMessage id={`level.seven.${gender == "F" ? "f" : "m" }`} />,
    8: <FormattedMessage id={`level.eight.${gender == "F" ? "f" : "m" }`} />,
  };

  useEffect(() => {
    onStartUp();
  }, []);

  return (
    <>
      {CAOData?.data?.caoActive && (
        <div className="w-100 museo-sans-500">
          <ShareModalStructure minHeight={"618px"} id={"otherGoals"}>
            <OriginRoadModalOthers
              roleDescription={objectLevelDescription[CAOData?.data?.caoLevel]}
              level={CAOData?.data?.caoLevel}
              data={CAOData?.data}
              cultureInfo={cultureInfo}
            />
          </ShareModalStructure>
          <div className="d-flex mb-3 align-items-center gap-3">
            <p className=" s20">
              <FormattedMessage id="origin.road.title" />
            </p>

            <p onClick={onLinkClick} className=" museo-sans-700 s14 pointer skyColor">
              <FormattedMessage id="origin.road.link" />
            </p>
            <a target="_blank" id="cao-link" href={`${CAOData.data?.caoLevel < 2 ? objectLink["under"] : objectLink["above"]}`} className=" invisible" >

            </a>
          </div>
          <div className=" s14 d-flex bg-white flex-column rounded-16 tabs-shadow p-4">
            {CAOData.loader ? (
              <SkeletonBody />
            ) : (
              <>
                <div className="d-flex flex-column ">
                  {CAOData.data?.caoState !== "GAME_OVER" && (
                    <>
                      <div className="d-flex gap-1">
                        <p className=" museo-sans-700 s16">
                          <FormattedMessage id="origin.road.actual.goal" />:
                        </p>
                        <p className=" dm-sans-500 s16">
                          {" "}
                          {CAOData.data?.currentGoal}{" "}
                        </p>
                        <div
                          style={{ width: "16px" }}
                          className=" svg-force-size"
                        >
                          {onControlStatus(CAOData.data?.caoState)}
                        </div>
                      </div>
                      <div>
                        <hr className="my-3" />
                      </div>
                    </>
                  )}
                </div>
                <div>
                  {CAOData.data?.caoState !== "GAME_OVER" && (
                    <div className="w-100 d-flex justify-content-between gap-5">
                      <div className="w-100 gap-2">
                        <div className={` d-flex justify-content-between ${S1 ? " " : "flex-column"} `}>
                          <p>
                            {CAOData.data?.caoLevel < 2 ? (
                              <FormattedMessage id="origin.road.task.one.b" />
                            ) : (
                              <FormattedMessage id="origin.road.task.one.a" />
                            )}
                          </p>
                          <div className={` d-flex gap-1 dm-sans-medium ${S1 ? " " : " justify-content-end "} `} >
                            <p>
                              {formatAmount(
                                CAOData.data?.incrementalSale?.actualAmount,
                                cultureInfo?.languageCode,
                                cultureInfo?.defaultCurrencyCode
                              )}
                            </p>
                            <p className=" grayColor">
                              /{" "}
                              {formatAmount(
                                CAOData.data?.incrementalSale?.totalAmount,
                                cultureInfo?.languageCode,
                                cultureInfo?.defaultCurrencyCode
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="w-100">
                          <ProgressBarParams
                          height={12}
                            status={CAOData.data?.incrementalSale?.status}
                            gap={
                              CAOData.data?.incrementalSale?.status === "gap"
                            }
                            incompleted={
                              CAOData.data?.incrementalSale?.status !==
                              "completed"
                            }
                            completed={
                              (CAOData.data?.incrementalSale?.actualAmount /
                                CAOData.data?.incrementalSale?.totalAmount) *
                              100
                            }
                          />
                        </div>
                      </div>
                      <div style={{ width: "2px" }} className=" brightGrayBg  ">
                        <p className=" invisible">.</p>
                      </div>
                      <div className="w-100 gap-2">
                        <div className="d-flex justify-content-between">
                          <p>
                            {CAOData.data?.caoLevel < 2 ? (
                              <FormattedMessage id="origin.road.task.two.b" />
                            ) : (
                              <FormattedMessage id="origin.road.task.two.a" />
                            )}
                          </p>
                          <div className="d-flex gap-1 dm-sans-medium ">
                            <p
                              className={` dm-sans-bold ${objectColor[CAOData.data?.incrementalActives?.status]} `}
                            >
                              {CAOData.data?.incrementalActives?.actual}
                            </p>
                            <p className=" grayColor">
                              / {CAOData.data?.incrementalActives?.need}
                            </p>
                          </div>
                        </div>
                        <div className="w-100 invisible">
                          <ProgressBarParams
                          height={12}
                            status={"warning"}
                            gap={true}
                            incompleted={true}
                            completed={(100000 / 200000) * 100}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <OriginRoadHelpMessage
                    toggler={toggler}
                    level={CAOData.data?.caoLevel}
                    state={CAOData.data?.caoState}
                  />
                  {
                    CAOData.data?.caoState !== "GAME_OVER" ?
                    <div>
                    <hr className="my-3" />
                  </div>
                  :
                  <>
                    {
                      toggler.completed &&
                      <div>
                      <hr className="my-3" />
                    </div>
                    }
                  </>
                    

                  }
                </div>
                <div className=" w-100">
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#otherGoals"
                    className="donutHeaderBg pointer w-100 h-100 d-flex justify-content-between rounded-8 p-3 "
                  >
                    <p>
                      <FormattedMessage id="origin.road.reached" />
                    </p>
                    <div className="d-flex dm-sans-medium ">
                      <div className="d-flex gap-1">
                        <p>{CAOData.data?.processedGoals}</p>
                        <p className=" grayColor">
                          /{CAOData.data?.totalGoals}
                        </p>
                      </div>
                      <div
                        style={{ height: "16px", top: -2 }}
                        className=" position-relative svg-force-size"
                      >
                        <SVGChevronRight />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function SkeletonBody() {
  return (
    <div>
      <div className="w-25 lightui1-shimmer rounded-16 ">
        <p className="invisible">Meta Actual:</p>
      </div>
      <hr className=" my-3" />
      <div className="gap-2">
        <div className=" d-flex justify-content-between gap-5 ">
          <div className=" d-flex flex-column gap-2 w-100">
            <div className="d-flex justify-content-between w-100">
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
              <div className="w-50"></div>
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
            </div>
            <div
              style={{ height: "14px" }}
              className="lightui1-shimmer rounded-16 w-100"
            >
              <p className="invisible">Meta Actual:</p>
            </div>
          </div>
          <div style={{ width: "2px" }} className=" brightGrayBg  ">
            <p className=" invisible">.</p>
          </div>
          <div className=" w-100">
            <div className="d-flex justify-content-between w-100">
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
              <div className="w-50"></div>
              <div
                style={{ height: "14px" }}
                className="lightui1-shimmer rounded-16 w-50"
              >
                <p className="invisible">Meta Actual:</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className=" my-3" />
      <div className=" lightui1-shimmer w-100 rounded-16 ">
        <p className="invisible">Meta Actual:</p>
      </div>
    </div>
  );
}

export function OriginRoadHelpMessage({
  toggler,
  level,
  state,
}) {
  
  const objectKeysOfValidStates = {
    ONGOING: false,
    REACHED: { messageId: "", state: "completed" },
    RISK_SALE: {
      messageId:
        level < 2
          ? "origin.road.help.message.risk.sale.1"
          : "origin.road.help.message.risk.sale.2",
      state: "risk",
    },
    RISK_GOALS: {
      messageId: "origin.road.help.message.risk.goals",
      state: "risk",
    },
    REVERSING: {
      messageId: "origin.road.help.message.risk.reversing",
      state: "risk",
    },
    GAP: { messageId: "", state: "gap" },
    GAME_OVER: {
      messageId: "origin.road.help.message.risk.game.over",
      state: "risk",
    },
  };

  const showMessageStatusOnState = (state) => {
    return (
      <>
        {toggler?.completed &&
          objectKeysOfValidStates[state]?.state === "completed" && (
            <div
              style={{ marginTop: "20px" }}
              className="p-3 gap-2 d-flex align-items-center justify-content-between h-100 position-relative greenModalBg greenColor rounded-8 "
            >
              <div className="d-flex align-items-center gap-2">
                <div
                  style={{
                    border: "2px green solid",
                    width: "32px",
                    height: "32px",
                  }}
                  className="d-flex justify-content-center align-items-center rounded-circle"
                >
                  <div className=" svg-force-size">
                    <SVGCheckGreen />
                  </div>
                </div>
                <p className=" s14">
                  <FormattedMessage id="origin.road.help.message.completed" />
                </p>
              </div>
            </div>
          )}
        {toggler?.risk && objectKeysOfValidStates[state]?.state === "risk" && (
          <div
            style={{
              marginTop: "20px",
              color: "#62221F",
              background: "#FEF0F0",
            }}
            className="p-3 gap-2 d-flex align-items-center h-100 position-relative justify-content-between  rounded-8 "
          >
            <div className="d-flex align-items-center gap-2">
              <div className=" svg-force-size">
                <SVGCrossRed />
              </div>
              <p className=" s14">
                <FormattedMessage
                  id={`${objectKeysOfValidStates[state]?.messageId}`}
                />
              </p>
            </div>
            
          </div>
        )}
        {toggler?.gap && objectKeysOfValidStates[state]?.state === "gap" && (
          <div
            style={{
              marginTop: "20px",
              color: "#4D4D4D",
              background: "#FEFBEC",
            }}
            className="p-3 gap-2 d-flex align-items-center h-100 position-relative  rounded-8 "
          >
            <div className="d-flex w-100 align-items-center gap-2">
              <div className=" svg-force-size">
                <SVGGap />
              </div>
              <p className=" s14">
                <FormattedMessage id="origin.road.help.message.gap" />
              </p>
            </div>
          </div>
        )}
      </>
    );
  };

  return <>{showMessageStatusOnState(state)}</>;
}
