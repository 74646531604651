import { FormattedMessage } from "react-intl";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import { SkeletonAccountBalanceEthic } from "./AccountBalance";
import checkTogglerJustCoins from "../../../common/Share/checkTogglerJustCoins";

export default function AccountBalanceSmaller({loading, goTo, renderBalance, periodReducer }) {
  
    return(
        <div>
        <div className="d-flex flex-row align-items-center" style={{ gap: "25px" }}>
          <p className="d-flex museo-sans-500 s20">
            <FormattedMessage id="balance.salary.title" />
          </p>
          {loading ? (
            <div style={{ height: "12px" }} className="rounded-3 brightGrayBg w-25"></div>
          ) : (
            <>
              {ShareCheckEnvKey("STATUS_ACCOUNT") && (
                <div onClick={goTo}>
                  <a className="pointer s14 skyColor museo-sans-700">
                    <FormattedMessage id="account.balance.button.message" />
                  </a>
                </div>
              )}
            </>
          )}
        </div>
        <div className="f9GrayBorder position-relative align-items-center justify-content-evenly mt-3 d-flex account--card-shadow bg-white rounded-account-cards" style={checkTogglerJustCoins() ? { width: "auto", height: "96px" } : { width: "276px", height: "96px" }}>
          {loading ? (
            <SkeletonAccountBalanceEthic />
          ) : (
            <>
              {periodReducer.data && (
                <>
                  <div style={checkTogglerJustCoins() ? { width: "auto" } : { width: "80%" }} className="d-flex py-4 justify-content-between flex-column text-center">
                    {renderBalance("current", periodReducer.data.currentAccount, periodReducer.data.currentAccountStatus)}
                  </div>
                  {checkTogglerJustCoins() && (
                    <>
                      <div style={{ width: "1px", height: "56px" }} className="brightGrayBg"></div>
                      <div className="d-flex py-4 justify-content-between flex-column text-center">
                        {renderBalance("justCoins", periodReducer.data.justCoins, periodReducer.data.justCoinsStatus)}
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    )
}
