import { FormattedMessage } from "react-intl";

export default function DistributionAgreementContentCL(params) {
    return (
        <>
          <p className={`museo-sans-500 s16 mb-4 ${!params.showTitle && "mt-4"}`}>
            <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
            <b>
              <FormattedMessage id="distribution.agreement.text.contract.parts.2" />
            </b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.3" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.1" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.title.declarations" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.2" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.title.declarations.swiss" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.1" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.2" />
          </p>

          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.2" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.3" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.3" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.4" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.4" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.5" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.5" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.6" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.7" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.8" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.9" />
              </u>
            </b>
            <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
            </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
          </p>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.10" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.11" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.12" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.13" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.14" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.14" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.15" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.15" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.16" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.16" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.17" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.18" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.19" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.20" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.21" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.17" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.22" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.18" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.23" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.24" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.25" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.26" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.27" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.28" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.19" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.29" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.20" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.30" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.31" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.21" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.32" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.22" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.33" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.23" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.34" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="distribution.clauses.24" />
              </u>
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.35" />
          </p>
          <p className="invisible mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="ethics.code.text.immigration.laws.1" />
              </u>
            </b>
            <FormattedMessage id="ethics.code.text.immigration.laws.1" />
          </p>
        </>
      );
}