import React from "react";
import { useMediaPredicate } from "react-media-hook";

export const InputComponent = React.forwardRef((props, ref) => {
  const biggerThan992 = useMediaPredicate("(min-width: 992px)");

  return (
    <div className={`w-100 ${biggerThan992 && "h-25"}`}>
      {props.label ? (
        <label htmlFor={props.id} className="form-label">
          {props.label}
        </label>
      ) : null}
      <input
        type={props.type}
        className={`form-control input-radius ${
          biggerThan992 ? "w-50" : "w-100"
        }`}
        id={props.id}
        placeholder={props.placeholder}
      />
    </div>
  );
});
