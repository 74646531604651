import { useRef, useState, forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import ReturnToAndTitle from "../../../Share/ReturnToAndTitle";
import { postPersonalDataValidationPhoneNumberCode } from "../../Framework/ConfigurationSideEffect";
import { ConfigurePin } from "./ConfigChangeSuccessful";

export default function UserDataPhoneNumberVerifyCode({
  travelFunction,
  setModalTexts,
  savedValues,
}) {
  const [inputs, setInputs] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const [toggler, setToggler] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);

  const onChange = (evt) => {
    evt.preventDefault()
    let aux = { ...inputs };
    if (isNaN(evt.target.value)) {
      return
    }
    if (!isNaN(evt.target.value)) {
      aux[evt.target.name] = evt.target.value;
      setInputs(aux);
  
      const allFieldsFilled = Object.values(aux).every((value) => value !== "");
      if (allFieldsFilled) {
        setToggler(true);
      } else {
        setToggler(false);
      }
      setError(false);
      return
    }
  };

  const onContinue = () => {
    const modalData = {
      title: <FormattedMessage id="settings.phone.modal.title" />,
      text: <FormattedMessage id="settings.phone.modal.p" />,
      status: "confirm",
      show: false,
      bottom: 0,
    };
    setModalTexts(modalData);
    const clickMe = document.getElementById("closeModal");
    clickMe.click();
  };

  const validateCode = async () => {
    const data = {
      ...savedValues,
      userOtpCode: `${inputs.one}${inputs.two}${inputs.three}${inputs.four}`,
    };
    const result = await dispatch(
      postPersonalDataValidationPhoneNumberCode(data)
    );
    if (result?.error || result?.payload?.code === 121) {
      setError(true);
      return;
    } else {
      onContinue();
      return;
    }
  };

  const onPasteValue = (pastedText) => {
    const pasteArray = pastedText.split("");
    const pasteArrayLength = pasteArray.length;
    if (pasteArrayLength === 4) {
      setInputs({
        one: pasteArray[0],
        two: pasteArray[1],
        three: pasteArray[2],
        four: pasteArray[3],
      });
      setToggler(true);
      return;
    }
  };


  const onVoid = () => {};

  return (
    <div className="d-flex flex-column gap-4" >
      <ReturnToAndTitle
        travelFunction={travelFunction}
        returnTo={{ value: 1.4, whereTo: "buttons.returnToMyData" }}
      />
      <div className="d-flex flex-column gap-2 museo-sans-700 ">
        <p
          style={{ letterSpacing: "0.24px" }}
          className=" museo-sans-500 s12 text-uppercase grayColor"
        >
          <FormattedMessage id="settings.title.change.phone" />
        </p>
        <p className=" s24">
          <FormattedMessage id="settings.title.change.phone.subtitle.two" />
          <p>
            <FormattedMessage id="settings.title.change.phone.subtitle.two.sub.two" />
          </p>
        </p>
      </div>
      <div id="login--confirm-sms" className="position-relative d-flex">
        <ConfigurePin
          onChange={onChange}
          nextRef={refTwo}
          ref={refOne}
          placeholder={"0"}
          name={"one"}
          onPasteValue={onPasteValue}
          values={inputs}
        />
        <ConfigurePin
          onChange={onChange}
          nextRef={refThree}
          ref={refTwo}
          placeholder={"0"}
          name={"two"}
          values={inputs}
        />
        <ConfigurePin
          onChange={onChange}
          nextRef={refFour}
          ref={refThree}
          placeholder={"0"}
          name={"three"}
          values={inputs}
        />
        <ConfigurePin
          onChange={onChange}
          nextRef={null}
          ref={refFour}
          placeholder={"0"}
          name={"four"}
          values={inputs}
        />
      </div>
      {error && (
        <p className=" my-2 w-50 museo-sans-500 s12 lightRedColor">
          <FormattedMessage id="settings.error.pin" />
        </p>
      )}
      <button
        onClick={toggler ? validateCode : onVoid}
        style={{ width: "308px", height: "40px" }}
        className={` mt-4 museo-sans-700 border-0 ${
          toggler ? "skyBg" : "brightGrayBg"
        } rounded-2 text-white`}
      >
        <FormattedMessage id="settings.validate.code" />
      </button>
    </div>
  );
}