import React from "react";
import { FormattedMessage } from "react-intl";
import ShareErrorHandlerServices from "../../../../Share/ShareErrorHandlerServices";
import HeaderProgress from "../HeaderProgress";

const HeaderColumnTwo = ({
  isDashboard,
  directorId,
  onClickCenterProgress,
  onStartUp,
  headerData,
  getDate,
  profileData,
}) => {

  return (
    <>
      {headerData?.periodError ? (
        <ShareErrorHandlerServices service={onStartUp} small={true} />
      ) : (
        <>
          {!isDashboard && (
            <div
              onClick={directorId ? "" : onClickCenterProgress}
              id="header--center"
              className=" pointer museo-sans-500"
            >
              <HeaderProgress user={profileData} />
              <div
                style={{ height: "fit-content" }}
                className=" mt-2 py-1 s12 align-items-center"
                id="header--progressdesc"
              >
                <p className="px-1">
                  {headerData
                    ? getDate(headerData.period.periodStartDate)
                    : null}{" "}
                </p>
                <p style={{ justifySelf: "center" }}>
                  <FormattedMessage id="header.periods" defaultMessage="New" />{" "}
                  {headerData ? headerData.period.currentPeriod : null} /{" "}
                  <FormattedMessage id="header.week" defaultMessage="New" />{" "}
                  {headerData ? headerData.period.currentWeek : null}
                </p>
                <p style={{ justifySelf: "flex-end" }} className="px-1">
                  {headerData ? getDate(headerData.period.periodEndDate) : null}
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default HeaderColumnTwo;
