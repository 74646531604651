import React from "react";

import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

export default function EDBModal() { 
  return (
    <div>
              <div className="d-flex justify-content-between m-4 p-3  align-items-center">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0">
                    <FormattedMessage
                      id="edb.header.title"
                      defaultMessage="encuentros de bienestar"
                    />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className="d-flex flex-column align-items-center">
                <div className="mt-4 mx-4 px-3">
                  <p className="museo-sans-500 s16 py-2">
                    <FormattedMessage
                      id="edb.info.modal.1"
                      defaultMessage="edbs"
                    />
                  </p>
                  <p className="museo-sans-700 s16 py-2">
                    <FormattedMessage
                      id="edb.info.modal.open.edbs"
                      defaultMessage="edbs"
                    />
                  </p>
                  <p className="museo-sans-500 s16 py-2">
                    <FormattedMessage
                      id="edb.info.modal.open.edbs.info"
                      defaultMessage="edbs"
                    />
                  </p>

                  <p className="museo-sans-700 s16 py-2">
                    <FormattedMessage
                      id="edb.info.modal.dated.edbs"
                      defaultMessage="edbs"
                    />
                  </p>
                  <p className="museo-sans-500 s16 py-2">
                    <FormattedMessage
                      id="edb.info.modal.dated.edbs.info"
                      defaultMessage="edbs"
                    />
                  </p>

                </div>
                <div className="py-3" >

                <button
                  type="button"
                  className=" btnLoginWidth my-4 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                    />
                </button>
                    </div>
              </div>
            </div>
  );
}
