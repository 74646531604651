import { FormattedMessage } from "react-intl";

export default function StatusAccountDetailsClientInfo({
  dataSummaryByIdData,
}) {


  const checkIfItIsPUP = (type) => {
    return (type !== "WEB" && type !== "PERSONAL" && type !== "CLIENTE_FINAL");
  };
  

  return (
    <div
      className="d-flex flex-column w-100 "
      style={{
        paddingTop: "40px",
        gap: "32px",
      }}
    >
      <div
        style={{
          padding: "32px",
          background: "#F9F9FD",
        }}
        className="f9GrayBorder position-relative mb-4  rounded-3 museo-sans-500 cards-shadow"
      >
        <div className="d-flex flex-column">
          <div>
            <p className="museo-sans-700 s16">
              <FormattedMessage id="Información del cliente" />
            </p>
          </div>
          <StatusAccountDetailsInfoStructure data={dataSummaryByIdData?.clientInfo} />
        </div>
        {dataSummaryByIdData?.transactionSummary?.trackingInfo && (
          <>
            <hr></hr>
            <StatusAccountDetailsInfoStructure data={dataSummaryByIdData?.deliveryInfo} isTypePUP={checkIfItIsPUP(dataSummaryByIdData?.deliveryInfo?.deliveryType)} />
          </>
        )}
      </div>
    </div>
  );
}

export function StatusAccountDetailsInfoStructure({data, isTypePUP }) {

  const capitalizeFirstLetter = (text) => {
    if (!text || typeof text !== "string") {
      return "";
    }

    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return(
    <div className="d-flex flex-row" style={{ marginTop: "32px" }}>
                <div
                  style={{ marginRight: "78px", gap: "35px" }}
                  className="d-flex flex-column"
                >
                  <p
                    className="museo-sans-500 s12"
                    style={{ color: "#908F9A" }}
                  >
                    <FormattedMessage id="NOMBRE Y APELLIDO" />
                  </p>
                  <p
                    className="museo-sans-500 s14"
                    style={{ color: "#171A22", width: "132px" }}
                  >
                    {data?.name}
                  </p>
                </div>
                <div
                  className="d-flex flex-column"
                  style={{ marginRight: "115px", gap: "32px" }}
                >
                  <p
                    className="museo-sans-500 s12"
                    style={{ color: "#908F9A" }}
                  >
                    <FormattedMessage id="TELÉFONO" />
                  </p>
                  <p
                    className="museo-sans-500 s14"
                    style={{ color: "#908F9A", width: "94px" }}
                  >
                    {data?.phone}
                  </p>
                </div>
                <div className="d-flex flex-column" style={{ gap: "32px" }}>
                  <p
                    className="museo-sans-500 grayColor text-uppercase s12"
                  >
                    {
                      isTypePUP ?
                      <FormattedMessage id="status.account.pup.title" />
                      :
                      <FormattedMessage id="DIRECCIÓN" />

                    }
                  </p>
                  <div className="grayColor dm-sans-medium s14 " >
                    {
                      isTypePUP &&
                      <>
                      <div className="d-flex gap-1" >
                        <p>
                          <FormattedMessage id="status.account.unique.code" />:
                        </p>
                        <p>
                          {
                            data?.pupCode
                          }
                        </p>
                      </div>
                      <p>
                      {
                            data?.pupReference
                          }
                      </p>
                      </>

                    }
                    <p
                      className="dm-sans-medium s14"
                      style={{ color: "#908F9A", width: "393px" }}
                    >
                      {capitalizeFirstLetter(
                        data?.addressData[0]
                          ?.description
                      )}
                    </p>
                    <p
                      style={{ width: "393px" }}
                    >
                      {capitalizeFirstLetter(
                        data?.addressData[1]
                          ?.description
                      )}
                      , &nbsp;
                      {capitalizeFirstLetter(
                        data?.addressData[2]
                          ?.description
                      )}
                      ,&nbsp;
                      {capitalizeFirstLetter(
                        data?.addressData[3]
                          ?.description
                      )}
                      ,&nbsp;
                      {capitalizeFirstLetter(
                        data?.addressData[4]
                          ?.description
                      )}
                    </p>
                  </div>
                </div>
              </div>
  )
}