import React from 'react'
import { useState } from 'react'
import { ReactComponent as SVGDown } from "../../common/svgs/ic-chevron-down-md.svg"
import { ReactComponent as SVGUp } from "../../common/svgs/ic-chevron-up-md.svg"

export default function EthicsCodeCollapseButton({title, hrefValue}) {

    const [chevronDown, setChevronDown] = useState(true)

  return (
    <a data-bs-toggle="collapse" href={`#${hrefValue}`} role="button" aria-expanded="false" aria-controls={hrefValue} onClick={() => setChevronDown(!chevronDown)}>
        <div style={{height: "75px"}} className='d-flex justify-content-between align-items-center pe-3 pt-3 pb-3'>
            <p className='museo-sans-700 s16 blackColor'>{title}</p>
            {chevronDown ? 

            <SVGDown />

            : 
            <SVGUp />
            }
        </div>
    </a>
  )
}
