import { useMediaPredicate } from "react-media-hook";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import DashboardFirstStepsCardHorizontal from "../components/DashboardFirstStepsCardHorizontal";
import FirstStepsPersonalStepsContainer from "../components/FirstStepsPersonalStepsContainer";

export default function FirstStepsDashboardShowcase({
  profileData,
  loader,
  reloadMessage,
}) {
  const biggerThan1025 = useMediaPredicate("(min-width: 1025px)");

  return (
    <>
      {loader ? (
        <div></div>
      ) : (
        <>
          {ShareCheckEnvKey("FTT") && (
            <>
              {profileData?.userInfo?.level > 1 ? (
                <DashboardFirstStepsCardHorizontal
                  reloadMessage={reloadMessage}
                  biggerThan1025={biggerThan1025}
                />
              ) : (
                <>
                  <FirstStepsPersonalStepsContainer
                    reloadMessage={reloadMessage}
                    biggerThan1025={biggerThan1025}
                  />
                  <DashboardFirstStepsCardHorizontal
                    reloadMessage={reloadMessage}
                    boolean={true}
                    biggerThan1025={biggerThan1025}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export function SkeletonCard() {
  return (
    <div className="d-flex w-100 flex-column justify-content-between gap-2">
      <div className="cards-Offers w-100 cards-shadow edb-card-radius d-flex flex-column bronceBg h-100 ">
        <div
          style={{ height: "190px", borderRadius: "16px 16px 0px 0px" }}
          className=" lightui1-shimmer w-100 brightGrayBg  "
        ></div>
        <div className=" d-flex flex-column justify-content-between p-3 h-100">
          <div
            style={{ height: "12px" }}
            className=" lightui1-shimmer w-50 mt-3 rounded-3 brightGrayBg  "
          ></div>
          <div
            style={{ height: "22px" }}
            className=" lightui1-shimmer w-25 mt-4 rounded-3 brightGrayBg  "
          ></div>
          <div
            style={{ height: "18px" }}
            className=" lightui1-shimmer w-100 mt-3 rounded-3 brightGrayBg  "
          ></div>
          <div
            style={{ height: "18px" }}
            className=" lightui1-shimmer w-50 mt-1 rounded-3 brightGrayBg  "
          ></div>

          <div
            style={{ height: "14px" }}
            className=" lightui1-shimmer w-100 mt-5 rounded-3 brightGrayBg  "
          ></div>
          <div
            style={{ height: "14px" }}
            className=" lightui1-shimmer w-100 mt-2 rounded-3 brightGrayBg  "
          ></div>

          <div className=" mt-4 pt-3 d-flex flex-column gap-2">
            <div
              style={{ height: "8px" }}
              className=" lightui1-shimmer w-100 rounded-3 brightGrayBg  "
            ></div>
            <div
              style={{ height: "8px" }}
              className=" lightui1-shimmer w-100 rounded-3 brightGrayBg  "
            ></div>
            <div
              style={{ height: "8px" }}
              className=" lightui1-shimmer w-50   rounded-3 brightGrayBg  "
            ></div>
          </div>
          <div className=" mt-5 pb-3 d-flex flex-column gap-2">
            <div
              style={{ height: "40px", width: "244px" }}
              className=" lightui1-shimmer w-100 rounded-3 brightGrayBg  "
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
