import { ReactComponent as SVGOferts0to24 } from "../imgs/oferts0to24.svg";
import { ReactComponent as SVGOferts24to54 } from "../imgs/oferts25to54.svg";
import { ReactComponent as SVGOferts55to75 } from "../imgs/oferts55to75.svg";

import { ReactComponent as SVGOfertsDescAndGift } from "../imgs/ofertsDescAndGift.svg";
import { ReactComponent as SVGOfertsDescAndGiftLila } from "../imgs/ofertsDescAndGiftLila.svg";
import { ReactComponent as SVGOfertsDescAndGiftPink } from "../imgs/ofertsDescAndGiftPink.svg";
import { ReactComponent as SVGOfertsGiftJustCoins } from "../imgs/GiftJustcoins.svg";
import { ReactComponent as SVGOfertsJustCoins } from "../imgs/DescuentoJustcoins.svg";
import { ReactComponent as SVGOfertsJustCoinsLila } from "../imgs/DescuentoJustcoinsLila.svg";
import { ReactComponent as SVGOfertsJustCoinsPink } from "../imgs/DescuentoJustcoinsPink.svg";
import { ReactComponent as SVGOfertsGift } from "../imgs/ofertsGift.svg";

import { ReactComponent as SVGQuotaBlue } from "../imgs/newBadgeTypesSVGs/quotaBlue.svg";
import { ReactComponent as SVGQuotaPink } from "../imgs/newBadgeTypesSVGs/quotaPink.svg";
import { ReactComponent as SVGQuotaIndigo } from "../imgs/newBadgeTypesSVGs/quotaIndigo.svg";
import { ReactComponent as SVGQuotaLabel } from "../imgs/newBadgeTypesSVGs/quotaLabel.svg";
import { ReactComponent as SVGEDBsLabel } from "../imgs/newBadgeTypesSVGs/edbsLabel.svg";
import { ReactComponent as SVGShipping} from "../imgs/newBadgeTypesSVGs/shipping.svg";
import { ReactComponent as SVGFree} from "../imgs/newBadgeTypesSVGs/free.svg";

const OfertsDiscountsHolder = ({ number, status, instalmentsNumber }) => {


  const checkTogglerParragraph = (number, status) => {
    return (
      number && (status.includes("Descuento") || status.includes("Oferta"))
    );
  };

  const checkOfferStatus = ({ number, status }) => {

    switch (status) {
      case "EDBs":
        return (
            <div
            style={{ left: "4px", top: "4px" }}
            className=" position-absolute text-white dm-sans-bold s16 text-center text-uppercase d-flex justify-content-center align-items-center "
          >
            <SVGEDBsLabel />
          </div>
        );
      case "X cuotas sin intereses":
        return (
            <div
            style={{ left: "4px", top: "4px", zIndex: 5 }}
            className=" position-absolute text-white dm-sans-bold s16 text-center text-uppercase d-flex justify-content-center align-items-center "
          >
            <div style={{ zIndex: 6}} className=" position-relative" >
              {
                instalmentsNumber < 4 &&
                <SVGQuotaBlue />
              }
              {
                (instalmentsNumber > 3 && instalmentsNumber < 7  ) &&
                <SVGQuotaPink />
              }
            {
                (instalmentsNumber > 6 ) &&
                <SVGQuotaIndigo />
              }
            </div>
            <div style={{ zIndex: 7}} className=" position-absolute" >
              {instalmentsNumber}
            </div>
            <div style={{left: "8%"}} className=" position-absolute" > 
              <SVGQuotaLabel/>
            </div>
          </div>
        );
      case "Envío Gratis":
        return (
            <div
            style={{ left: "4px", top: "4px" }}
            className=" position-absolute text-white dm-sans-bold s16 text-center text-uppercase d-flex justify-content-center align-items-center "
          >
            <SVGShipping/>
            <div style={{top: "28px", left: "8px"}} className=" position-absolute" >
              <SVGFree/>

            </div>
          </div>
        );
      case "Oferta":
        return (
          <>
            <div
              style={{
                borderRadius: "50%",
                height: "39px",
                width: "46px",
                position: "absolute",
                left: 16,
                top: 16,
              }}
            >
              {number <= 33 ? (
                <>
                  <SVGOferts0to24 />
                </>
              ) : (
                <>
                  {number <= 66 ? (
                    <>
                      <SVGOferts24to54 />
                    </>
                  ) : (
                    <>
                      {number <= 100 && (
                        <>
                          <SVGOferts55to75 />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </>
        );
      case "Regalo":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            <SVGOfertsGift />
          </div>
        );
      case "Oferta + Regalo":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            {number <= 33 ? (
              <>
                <SVGOfertsDescAndGift />
              </>
            ) : (
              <>
                {number <= 66 ? (
                  <>
                    <SVGOfertsDescAndGiftLila />
                  </>
                ) : (
                  <>
                    {number <= 100 && (
                      <>
                        <SVGOfertsDescAndGiftPink />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      case "Descuento + Justcoins":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            {number <= 33 ? (
              <>
                <SVGOfertsJustCoins />
              </>
            ) : (
              <>
                {number <= 66 ? (
                  <>
                    <SVGOfertsJustCoinsLila />
                  </>
                ) : (
                  <>
                    {number <= 100 && (
                      <>
                        <SVGOfertsJustCoinsPink />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      case "Justcoins":
        return (
          <div
            style={{
              borderRadius: "50%",
              height: "39px",
              width: "46px",
              position: "absolute",
              left: 16,
              top: 16,
            }}
          >
            <SVGOfertsGiftJustCoins />
          </div>
        );
      default:
        return;
    }
  };

  return (
    <>
      {checkOfferStatus({ number, status })}
      {checkTogglerParragraph(number, status) && (
        <>
          <p
            className="dm-sans-bold s16"
            style={{
              position: "absolute",
              left: 37,
              top: 32,
              color: "white",
            }}
          >
            {number || 10}%
          </p>
          <p
            className="dm-sans-bold s16"
            style={{
              position: "absolute",
              left: 31,
              top: 49,
              color: "white",
            }}
          >
            DESC.
          </p>
        </>
      )}
    </>
  );
};

export default OfertsDiscountsHolder;
