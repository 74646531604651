import { FormattedMessage } from "react-intl";

export default function LoginErrorHandler({ string, type }) {
  const onStringInput = (string, type) => {
    if (string.trim() === "" && type === "user") {
      return (
        <div className="d-flex gap-1 align-items-center " >
          <i className="text-danger bi bi-x-circle-fill"></i>
          <small className=" s12 museo-sans-500 text-danger ">
            <FormattedMessage id="login.error.one" defaultMessage="Este campo es obligatorio para ingresar" />
          </small>
        </div >
      );
    }
    if (!string && type === "country") {
      return (
        <div className="d-flex gap-1 align-items-center">
          <i className="text-danger bi bi-x-circle-fill"></i>
            <small className=" s12 museo-sans-500  text-danger ">
              <FormattedMessage id="login.error.country.one" defaultMessage="Por favor selecciona tu país para ingresar" />
            </small>
        </div>
      );
    }
    if (string?.trim() === "" && type === "password") {
      return (
        <div className="d-flex gap-1 align-items-center">
          <i className="text-danger bi bi-x-circle-fill"></i>
          <small className=" s12 museo-sans-500 text-danger ">
            <FormattedMessage id="login.error.one" defaultMessage="Este campo es obligatorio para ingresar" />
          </small>
        </div>
      );
    }
  };

  return onStringInput(string, type);
}
