import React from 'react'

import './Configuration.css'
import PrivacyPolicyContent from './../../../termsAndConditions/PrivacyPolicyContent';


export default function ConfigPrivacyPolicy() {
  return (
    <>
        <div className='position-absolute shadow-effect-config'></div>
        <div className="position-relative w-100 h-100 ps-lg-4 pe-lg-4 overflow-auto remove-scrollbar">
            <PrivacyPolicyContent />
        </div>
    </>
  )
}
