import React from "react";

import { FormattedMessage } from "react-intl";
import DistributionAgreementContentAR from "./components/DistributionAgreementContentAR";
import DistributionAgreementContentCL from "./components/DistributionAgreementContentCL";
import DistributionAgreementContentCO from "./components/DistributionAgreementContentCO";
import DistributionAgreementContentCR from "./components/DistributionAgreementContentCR";
import DistributionAgreementContentMX from "./components/DistributionAgreementContentMX";
import DistributionAgreementContentPA from "./components/DistributionAgreementContentPA";
import DistributionAgreementContentUY from "./components/DistributionAgreementContentUY";

export default function DistributionAgreementContent({ showTitle }) {
  const clientCountry = localStorage.getItem("country");

  const countryComponents = {
    mx: DistributionAgreementContentMX,
    cl: DistributionAgreementContentCL,
    uy: DistributionAgreementContentUY,
    pa: DistributionAgreementContentPA,
    co: DistributionAgreementContentCO,
    cr: DistributionAgreementContentCR,
    ar: DistributionAgreementContentAR,
  };

  const DefaultContent = ({showTitle}) => {
    return (
      <>
        <p
          className={`grayColor museo-sans-500 s16 mb-4 ${
            !showTitle && "mt-4"
          }`}
        >
          <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
          <b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.2" />
          </b>
          <FormattedMessage id="distribution.agreement.text.contract.parts.3" />
          <b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.4" />
          </b>
          <FormattedMessage id="distribution.agreement.text.contract.parts.5" />
        </p>

        <p className="mb-2 museo-sans-700 s16">
          <FormattedMessage id="distribution.agreement.title.declarations" />
        </p>

        <p className="mb-2 museo-sans-700 s16">
          <FormattedMessage id="distribution.agreement.title.declarations.swiss" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.swiss.1" />
        </p>
        <p className="mb-4 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.swiss.2" />
        </p>

        <p className="mb-2 museo-sans-700 s16">
          <FormattedMessage id="distribution.agreement.title.declarations.solicitant" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.solicitant.1" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.solicitant.2" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.solicitant.3" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.solicitant.4" />
        </p>
        <p className="mb-4 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.solicitant.5" />
        </p>

        <p className="mb-2 museo-sans-700 s16">
          <FormattedMessage id="distribution.agreement.title.declarations.clauses" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.5" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
        </p>
        <p className="mb-2 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
        </p>
        <p className="mb-4 museo-sans-500 s16">
          <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
        </p>
        <p className="invisible mb-4 museo-sans-500 s16">
          <b>
            <u>
              <FormattedMessage id="ethics.code.text.immigration.laws.1" />
            </u>
          </b>
          <FormattedMessage id="ethics.code.text.immigration.laws.1" />
        </p>
      </>
    );
  }

  const CountryComponent = countryComponents[clientCountry] || DefaultContent;

  return (
    <>
      {showTitle && (
        <p className="museo-sans-700 s20 mb-4">
          <FormattedMessage id="politics.meta.distribute" />
        </p>
      )}
      <CountryComponent showTitle={showTitle} />
      <div className=" invisible museo-sans-500 s16 " >
        <p className=" " >
          This text here is just to make the div visible, so there is an empty space.
        </p>

      </div>
    </>
  );
}
