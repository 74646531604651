import React from "react";

export default function ClientListHeaderSkeleton() {
  return (
    <div className=" w-100 d-flex justify-content-between mb-2 mt-2 align-items-center">
      <div className="w-25">
        <div
          className="lightui1-shimmer rounded-3"
          style={{ width: 392, height: 40, borderColor: '#8889DB' }}
        />
      </div>
      <div className=" btnCustom  skyColor museo-sans-700 s14 py-2 lightui1-shimmer" style={{ width: 184, height: 40 }} />
    </div>
  )
}