import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import FirstStepsDashboardShowcase from "../../../FirstSteps/Presentation/pages/FirstStepsDashboardShowcase";

export default function DashboardFirstStepsContainer({callServiceFirstSteeps, profileData, country, loader }) {
    
    return(
        <>
            {
              ShareCheckEnvKey("FTT") &&
              <FirstStepsDashboardShowcase reloadMessage={callServiceFirstSteeps} profileData={profileData} country={country} loader={loader} />
            }
            </>
    )
}