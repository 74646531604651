import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";
import InvitationListRowProgressStep from "./InvitationListRowProgreessStep";
import InvitationListRowProgressBall from "./InvitationListRowProgressBall";

export default function InvitationsListTableRowProgress({ restOfData, data }) {
  const chooseColorByParams = (state, lastStep) => {
    const arrayColors = {
      "PENDING": "#CED2FF",
      "DISABLED": "#CED2FF",
      "COMPLETED": "#456ECE",
    };

    return lastStep === "COMPLETED" ? "#16BE7D" : arrayColors[state];
  };

  return (
    <>
      {data?.length > 0 && (
        <div className="w-100 d-flex flex-column gap-1 align-items-center">
          <div className=" position-relative d-flex w-100 align-items-center justify-content-end">
            {data && (
              <>
                <InvitationListRowProgressBall
                  lastData={data[3]}
                  colors={chooseColorByParams}
                  data={data}
                  index={1}
                />
                <InvitationListRowProgressBall
                  lastData={data[3]}
                  colors={chooseColorByParams}
                  data={data}
                  index={2}
                />
                <InvitationListRowProgressBall
                  lastData={data[3]}
                  colors={chooseColorByParams}
                  data={data}
                  index={3}
                />
                <InvitationListRowProgressBall
                  lastData={data[3]}
                  colors={chooseColorByParams}
                  data={data}
                  index={4}
                />
              </>
            )}
          </div>
          <div className="d-flex w-100 justify-content-end">
            {data?.length > 0 &&
              data.map((elm, index) => {
                return (
                  <>
                    <InvitationListRowProgressStep
                      colors={chooseColorByParams}
                      stepData={elm}
                      index={index}
                      restOfData={restOfData}
                      key={ShareKeyGenerator()}
                    />
                  </>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
