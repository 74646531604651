import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom"
import { ReactComponent as SVGCross } from "../../../common/svgs/ic-x-circle-sm.svg"

export default function LoginConsultantError(params) {

    const history = useNavigate();

    return <>
        <div className=" container--padding museo-sans-500 d-flex justify-content-center align-items-center flex-column  " >
            <div style={{width: "392px"}} className="d-flex justify-content-center align-items-center flex-column text-center " >
                <div style={{width: "100px", height: "100px"}} className=" svg-force-size  mb-4" >
                    <SVGCross />
                </div>
                <div className=" mb-3" >
                    <h2 className=" museo-sans-700 s24" >
                        <FormattedMessage id="login.pageerror.title" defaultMessage={"Tu clave ha sido dada de baja por no llegar al monto triperiódico."} />
                        
                    </h2>
                </div>
                <div className="mb-2" >
                    <p className=" grayColor s16 " >
                    <FormattedMessage id="login.pageerror.p" defaultMessage={"Para volver a ingresar vas a tener que volver a realizar el proceso de registro con tu nueva clave."} />
                        
                        
                    </p>
                </div>
                <div className=" mt-5 w-100" >
                    <button onClick={() => { history("/login") }} style={{borderRadius: "8px", height: "40px"}}  className=" museo-sans-700 s14 w-100 skyBg text-white border-0 w-100" >
                        
                        <FormattedMessage id="login.pageerror.btn" defaultMessage={"Volver al Login"} />
                    </button>
                </div>

            </div>
        </div>
    </>
}