import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import OfertsModalImageCardSharing from "./OfertsModalImageCardSharing";
import OfertsModalImageCardContent from "./OfertsModalImageCardContent";
import OfertsDiscountsHolder from "../OfertsDiscountsHolder";
import OfertsImageHolder from "../OfertsImageHolder";
import { notificationSharingData } from "../../../Framework/redux/OfertsActions";
import replaceElementsURL from "../../../../common/Share/replaceElementURL";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

export default function OfertsModalImageCard({
  modalRef,
  onOpenCopyModal,
  element,
}) {
  const dispatch = useDispatch();
  const [toggler, setToggler] = useState(false);
  const modalOutSideClickRef = useRef(null);
  const scrollbarRef = useRef(null);

  const profileData = useSelector((state) => state.userInfo.userInfo);
  const dataSharing = useSelector((state) => state.ofertsData.dataSharing);
  const socialArray = profileData?.socialNetwork;

  const closeModal = () => {
    setToggler(false);
  };

  const setTogglerFalse = () => {
    setToggler(false);
  };

  const handleScroll = () => {
    scrollbarRef.current.scrollTop = 0;
  };

  const openShare = () => {
    setToggler(true);
  };

  const regex = /\n/g;
  const matches = element?.description?.match(regex);

  if (matches) {
    const additionalChars = matches?.length * 42;
  }

  const closeModalAll = () => {
    dispatch(notificationSharingData(null));
    const modal = document.getElementById("close-all-modal");
    modal.click();
  };

  const switchPerValue = (type) => {
    if (type == "cliente") {
      return "Clientes";
    }
    if (type == "consultora") {
      return "Consultores";
    }

    return "";
  };

  const toggleDiscountHolder = (badgeType, discountPercentage) => {
    if (
      badgeType === "Regalo" ||
      badgeType === "Justcoins" ||
      badgeType === "EDBs" ||
      badgeType === "X cuotas sin intereses" ||
      badgeType === "Envío Gratis" ||
      discountPercentage > 0
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    closeModal();

    const handleOutsideClick = (event) => {
      if (event.target.id === "modalOffer") {
        const modal = document.getElementById("modalOffer");
        modal.classList.add("hiding");
        handleScroll();
        setTimeout(() => {
          closeModal();
          modal.classList.remove("hiding");
        }, 300);
      }
    };
    const modal = document.getElementById("modalOffer");
    modal.addEventListener("click", handleOutsideClick);

    return () => {
      modal.removeEventListener("click", handleOutsideClick);
    };
  }, [dataSharing]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalOutSideClickRef.current &&
        !modalOutSideClickRef.current.contains(event.target)
      ) {
        setToggler(true);
        handleScroll();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalOutSideClickRef]);

  return (
    <div
      className="modal fade "
      id="modalOffer"
      aria-hidden="true"
      aria-labelledby="modalOffersShare"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered "
        style={{ maxWidth: "807px" }}
      >
        {toggler == false && (
          <div
            className="modal-content modal-radius"
            style={{ maxHeight: "475px" }}
          >
            <div
              className="d-flex "
              style={{
                padding: "32px 0px 32px 32px",
                height: "475px",
                gap: "32px",
              }}
            >
              <div
                style={{
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "359px",
                  overflow: "hidden",
                  minWidth: "359px",
                }}
                className="position-relative"
              >
                <img
                  style={{
                    borderRadius: "15px",
                  }}
                  src={
                    dataSharing?.data
                      ? `${replaceElementsURL(
                          dataSharing.data.imageBaseURL,
                          dataSharing.data.saleImage?.id,
                          dataSharing.data.saleImage?.filename_download
                        )}`
                      : ""
                  }
                />
                {toggleDiscountHolder(
                  dataSharing?.data?.badgeType,
                  dataSharing?.data?.discountPercentage
                ) && (
                  <OfertsDiscountsHolder
                    instalmentsNumber={dataSharing?.data?.instalmentsNumber}
                    number={dataSharing?.data?.discountPercentage}
                    status={dataSharing?.data?.badgeType}
                  />
                )}

                {dataSharing?.data?.badgeType != "Regalo" && (
                  <OfertsImageHolder
                    url={dataSharing?.data?.imageBaseURL}
                    filename={
                      dataSharing?.data?.productImage?.filename_download
                    }
                    id={dataSharing?.data?.productImage?.id}
                    short={false}
                  />
                )}
              </div>
              <div
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-all-modal"
                className=" position-absolute invisible"
              >
                close
              </div>
              <div
                className="w-100 d-flex flex-column position-relative"
                style={{ overflow: "hidden" }}
              >
                <div
                  onClick={closeModalAll}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ right: "24px", top: "-8px" }}
                  className="pointer position-absolute"
                >
                  <SVGClose />
                </div>
                <div
                  style={{
                    width: "328px",
                    height: "411px",
                  }}
                  className="d-flex flex-column justify-content-between h-100"
                >
                  <OfertsModalImageCardContent
                    closeModalAll={closeModalAll}
                    dataSharingController={dataSharing}
                    element={element}
                    replaceElementsURL={replaceElementsURL}
                    newScrollbarRef={scrollbarRef}
                  />
                  <div
                    style={{ right: "14px", top: "357px", height: "12px" }}
                    className=" d-flex position-absolute shadow-effect-two
                  "
                  ></div>
                  <button
                    className={
                      "w-100 skyBg text-white rounded-3 skyBorder d-flex justify-content-center align-items-center"
                    }
                    style={{ height: "40px" }}
                    onClick={openShare}
                  >
                    <p className="museo-sans-700 s14">
                      {" "}
                      <FormattedMessage id="oferts.share.general.button" />{" "}
                      <span className="text-capitalize">
                        {" "}
                        {switchPerValue(dataSharing?.data?.applyTo)}{" "}
                      </span>
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {toggler == true && (
          <OfertsModalImageCardSharing
            open={onOpenCopyModal}
            socialArray={socialArray}
            setToggler={setTogglerFalse}
            modalOutSideClickRef={modalOutSideClickRef}
          />
        )}
      </div>
    </div>
  );
}
