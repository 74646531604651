import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { ReactComponent as SVGChevArrowDown } from "../../../../common/svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGChevArrowUp } from "../../../../common/svgs/ic-chevron-up-md.svg";
import { ReactComponent as SVGMedal } from "../../../../common/svgs/ic-medal-dollar-lg.svg";
import { ReactComponent as SVGTriangle } from "../../../../common/svgs/ic-triangle-warning-lg.svg";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import { useSelector } from "react-redux";

export default function GraphListPeriods({ profileData, earningData }) {
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  return (
    <div className=" position-relative ">
      {
        earningData?.justCoins ?
        <>
      <h4 className="s20 m-0 text-capitalize museo-sans-700">
        
        <FormattedMessage id="dashboard.prev.earning.graph.credits" />
      </h4>
      <JustCoins earningData={earningData} />
      <hr className="my-4"></hr>
        
        </>
        :
        <>
         <h4 className="s20 m-0 text-capitalize museo-sans-700">
        
        <FormattedMessage id="dashboard.prev.earning.graph.credits" />
      </h4>
      <JustCoins earningData={earningData} />
      <hr className="my-4"></hr>
        </>
      }
      <h4 className=" s20 m-0 text-capitalize museo-sans-700">
      <FormattedMessage id="dashboard.prev.earning.graph.sales" />
      </h4>
      <div className=" my-4">
        <>
          {profileData.userInfo.level > "3" ? (
            <>
              <GraphListRow data={earningData} index={0} level={profileData.userInfo.level} />
              <hr />
              <GraphListRow data={earningData} index={1} level={profileData.userInfo.level} />
              <hr />
              <GraphListRow
                data={earningData}
                index={2}
                level={profileData.userInfo.level}
              />
              {profileData.userInfo.level > "5" && (
                <>
                  <hr />
                  <GraphListRow data={earningData} index={3} level={profileData.userInfo.level} />
                </>
              )}
            </>
          ) : (
            <>
              <GraphListRow data={earningData} index={0} level={profileData.userInfo.level} />
              <hr />
              <GraphListRow data={earningData} index={1} level={profileData.userInfo.level} />
            </>
          )}
        </>
      </div>
      <hr className="my-4"></hr>
      <div className=" my-4">
        <div className=" d-flex justify-content-between position-relative">
          <div className=" gap-3 align-items-center d-flex">
            <div
              style={{ width: "10px", height: "10px" }}
              className={` invisible goldBg rounded-circle `}
            ></div>
            <p className=" s16 museo-sans-500">
              <FormattedMessage id="dashboard.prev.earning.graph.base.commision" />
            </p>
          </div>
          <p className=" px-1 dm-sans-medium s16 position-absolute" style={{right
          :"150px"}}>
            {
              earningData?.baseEarnings <= 0 ? "-" : "+"
            }
          </p>
          <div className="d-flex align-items-center ">
            <p className=" dm-sans-medium s16">
              {formatAmount(earningData?.baseEarnings < 0 ? earningData?.baseEarnings*-1 : earningData?.baseEarnings, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
            </p>
            <div className=" invisible control--svg-filler-sky pointer">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
        <div className=" p-3">
          <div
            style={{ background: "#FEFBEC" }}
            className=" rounded-2 p-3 gap-3 d-flex goldBoldColor"
          >
            <div>
              <SVGTriangle />
            </div>
            <p className=" s14 museo-sans-500">
              <FormattedMessage id="dashboard.prev.earning.graph.base.commision.explain" />
            </p>
          </div>
        </div>
      </div>
      <hr className="my-4"></hr>
      <TaxesList earningData={earningData} />
      <hr className="my-4"></hr>
      <div className=" my-4">
        <div className=" d-flex justify-content-between position-relative">
          <div className=" gap-3 align-items-center d-flex">
            <div
              style={{ width: "10px", height: "10px" }}
              className={` invisible goldBg rounded-circle `}
            ></div>
            <p className=" s16 museo-sans-500">
              <FormattedMessage id="dashboard.prev.earning.graph.total.commision" />
            </p>
          </div>
          <p className=" px-1 dm-sans-medium s16 position-absolute" style={{right
          :"150px"}}>
            {
              earningData?.totalEarnings <= 0 ? "-" : "+"
            }
          </p>
          <div className="d-flex align-items-center ">
            <p className=" px-1 dm-sans-medium s16">
              {formatAmount( earningData?.totalEarnings < 0 ? earningData?.totalEarnings*-1 : earningData?.totalEarnings, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
            </p>
            <div className=" invisible control--svg-filler-sky pointer">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
}

export function JustCoins({earningData}) {
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  return(
    <div className=" my-4">
        <div className=" d-flex justify-content-between position-relative">
        <p className=" px-1 dm-sans-medium s16 position-absolute" style={{right
      :"150px"}}>
            {
              earningData?.justCoins <= 0 ? "-" : "+"
            }
          </p>
          <div className=" gap-3 align-items-center d-flex">
            <div
              style={{ width: "10px", height: "10px" }}
              className={` goldBg rounded-circle `}
            ></div>
            <p className=" s16 museo-sans-500">
              <FormattedMessage id="dashboard.prev.earning.graph.just.coins" defaultMessage={"JUST COINS"} />
            </p>
          </div>
          <div className="d-flex align-items-center ">
            <p className=" px-1 dm-sans-medium s16">
            {formatAmount(earningData?.justCoins, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
            </p>
            <div className=" invisible control--svg-filler-sky pointer">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
      </div>
  )
}

export function TaxesList({ earningData }) {
  const [toggle, setToggle] = useState(false);
  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  const onClick = () => {
    setToggle(!toggle);
  };
  
  return (
      <div className=" my-4">
        <div onClick={onClick} className=" pointer mb-4 d-flex justify-content-between position-relative">
          <>
           
            <p className=" px-1 dm-sans-medium s16 position-absolute" style={{right
              :"150px"}}>
                    {
                      earningData?.taxes?.amount <= 0 ? "-" : "+"
                    }
                  </p>
     
          </>
      
          <div className=" gap-3 align-items-center d-flex">
            <div
              style={{ width: "10px", height: "10px" }}
              className={` invisible goldBg rounded-circle `}
            ></div>
            <p className=" s16 museo-sans-700 text-uppercase " >
              <FormattedMessage id="personal.sale.details.taxes.tax"  />
            </p>
          </div>

          <div className="d-flex align-items-center ">

            <p className=" px-1 dm-sans-medium s16">
              {formatAmount(earningData?.taxes?.amount < 0 ? earningData?.taxes?.amount*-1 : earningData?.taxes?.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
            </p>
            <div className=" control--svg-filler-sky pointer">
              <SVGChevArrowDown />
            </div>
          </div>
        </div>
        {toggle && (
          <div className={` rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 ${biggerThan1000 ? "s14" : "s12"}  `}>
            <div className=" graph-listing text-uppercase  align-items-center grayColor">
              <p>
                <FormattedMessage id="taxes.type" />
              </p>
              <p className=" invisible graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.two" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.two.short" />
                }
              </p>
              <p className=" invisible graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.three" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.three.short" />
                }
              </p>
              <p className="graph-listing-row">
                <FormattedMessage id="dashboard.graph.dropmenu.four" />
              </p>
            </div>
            {earningData.taxesDetail.map((tax) => {
              return (
                <>
                  <hr className=" my-2 mx-0"></hr>
                  <div className="d-flex justify-content-between  graph-listing dm-sans-medium  align-items-center grayColor position-relative">
                    <p className=" museo-sans-500  blackColor">{ tax.description }</p>
                    <p className=" invisible graph-listing-row"> {formatAmount(0, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}</p>
                    <>
                      <p className="px-1 dm-sans-medium s16 position-absolute blackColor "style={{right
              :"150px"}}>
                        {
                          tax.amount <= 0 ? "-" : "+"
                        }
                      </p>
                    </>
                    <p style={{minWidth: "100px"}} className=" graph-listing-row blackColor">
                      {formatAmount(tax.amount < 0 ? tax.amount*-1 : tax.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                      </p>
                  </div>
                </>
              );
            })}
          </div>
        )}
      </div>
  );
}

export function GraphListRow({ data, index, level }) {
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);

  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");

  const switchColor = (index) => {
    switch (index) {
      case 0:
        return  "labelOneBg";
      case 1:
        return "labelTwoBg";
      case 2:
        return "labelThreeBg";
      case 3:
        return "labelFourBg";
    }
  };

  const switchArray = (index) => {
    switch (index) {
      case 0:
        return data.byPersonalSales;
      case 1:
        return data.byPersonalGroup;
      case 2:
        return data.byDescendant;
      case 3:
        return data.bondExecutiveDirector;
    }
  };

  const switchTitle = (index) => {
    switch (index) {
      case 0:
        return "Venta Personal";
      case 1:
        if (level > 1) {

          return "Grupo Personal";
        } else {
          if (biggerThan1000) {
            
            return <FormattedMessage id="business.plan.earning.incorporated.personal" />
          } else {

            return <FormattedMessage id="business.plan.earning.incorporated.personal.short" />
          }
        }
      case 2:
        return "Descendencia";
      case 3:
        return "BONO DIRECTORA";
    }
  };

  const switchDropDownMenu = (index) => {
    switch (index) {
      case 0:
        return (
          toggle && (
            <div className={` rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 ${biggerThan1000 ? "s14" : "s12"} `} >
              <div className=" graph-listing  text-uppercase  align-items-center grayColor">
                <p>
                  <FormattedMessage id="dashboard.graph.dropmenu.one" />
                </p>
                <p className=" px-1 graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.two" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.two.short" />
                }
                </p>
                <p className="graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.three" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.three.short" />
                }
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.four" />
                </p>
              </div>
              <hr className=" hr-list my-2 mx-0"></hr>
              <div className=" graph-listing s14  dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.one" />
                </p>
                <p className="graph-listing-row">
                  {formatAmount(switchArray(index)?.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
                <p className="graph-listing-row">
                  %{" "}
                  {switchArray(
                    index
                  ).instantEarnings.percentage.toLocaleString()}
                </p>
                <p className=" graph-listing-row blackColor">
                {formatAmount(switchArray(index)?.instantEarnings.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                
                </p>
              </div>
              <hr className=" hr-list my-2 mx-0"></hr>
              <div className=" s14 graph-listing  dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.two" />
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(switchArray(index).totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  ).personalSaleBond.percentage.toLocaleString()}
                </p>
                <p className=" graph-listing-row blackColor">
                 
                  {formatAmount(switchArray(index).personalSaleBond.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
              {
                level > "1" &&
                <>
                  <hr className=" hr-list my-2 mx-0"></hr>
              <div  className=" s14 graph-listing dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.three" />
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(switchArray(index)?.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  )?.leaderGroupBond?.percentage?.toLocaleString()}
                </p>
                <p className=" graph-listing-row blackColor">
                  {formatAmount(switchArray(index)?.leaderGroupBond?.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
                </>
              }
            </div>
          )
        );
      case 1:
        return (
          toggle && (
            <div className={` rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 ${biggerThan1000 ? "s14" : "s12"} `}>
              <div className=" graph-listing  text-uppercase  align-items-center grayColor">
                <p>
                  <FormattedMessage id="dashboard.graph.dropmenu.one" />
                </p>
                <p className="px-1 graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.two" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.two.short" />
                }
                </p>
                <p className="graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.three" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.three.short" />
                }
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.four" />
                </p>
              </div>
              
              {
                switchArray(
                  index
                )?.personalIncorporated &&
                <>
                <hr className=" hr-list my-2 mx-0"></hr>
                <div className=" s14 graph-listing dm-sans-medium  align-items-center grayColor ">
                  <p className=" museo-sans-500  blackColor">
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.four" />
                  </p>
                  <p className=" graph-listing-row ">
                   { switchArray(index)?.personalIncorporated?.totalSale !== 0 ? 
                    formatAmount(switchArray(index)?.personalIncorporated?.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode) : "Sin ventas"}
                  </p>
                  <p className=" graph-listing-row ">
                    %{" "}
                    {(switchArray(
                      index
                    )?.personalIncorporated?.percentage?.toLocaleString() ? switchArray(
                      index
                    )?.personalIncorporated?.percentage?.toLocaleString() : 0)}
                  </p>
                  <p className=" graph-listing-row  blackColor">
                    {(switchArray(index)?.personalIncorporated?.amount ) ? formatAmount(switchArray(index)?.personalIncorporated?.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode) : 0 }
                  </p>
                </div>
                </>
              }
              {
                level < "2" &&
                <>
                  <hr className=" hr-list my-2 mx-0"></hr>
              <div  className=" s14 graph-listing dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.five" />
                </p>
                <p className=" graph-listing-row ">
                  {
                    switchArray(index) != null ?
                    <>
                    
                      {formatAmount(switchArray(index)?.restOfGroup?.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </>
                    :
                    <>
                    <FormattedMessage id="currency" />
                    {" "}
                      0
                    </>
                  }
                </p>
                <p className=" graph-listing-row ">
                {
                    switchArray(index) != null ?
                    <>
                    % {switchArray(index)?.restOfGroup?.percentage?.toLocaleString()}
                    
                    </>
                    :
                    <>
                     % 0
                    </>
                  }
                </p>
                <p className=" graph-listing-row  blackColor">
                {
                    switchArray(index) != null ?
                    <>
                    {formatAmount(switchArray(index)?.restOfGroup?.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    
                    </>
                    :
                    <>
                    <FormattedMessage id="currency" />
                    {" "}
                      0
                    </>
                  }
                </p>
              </div>
                </>
              }
            </div>
          )
        );
      case 2:
        return (
          toggle && (
            <div className={` rounded-2 d-flex flex-column justify-content-center gap-2 f9GrayBg py-3 w-100 museo-sans-500 ${biggerThan1000 ? "s14" : "s12"} `}>
              <div  className=" graph-listing  text-uppercase  align-items-center grayColor">
                <p>
                  <FormattedMessage id="dashboard.graph.dropmenu.one" />
                </p>
                <p className="px-1 graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.two" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.two.short" />
                }
                </p>
                <p className="graph-listing-row">
                {
                  biggerThan1000 ?

                  <FormattedMessage id="dashboard.graph.dropmenu.three" />
                  :
                  <FormattedMessage id="dashboard.graph.dropmenu.three.short" />
                }
                </p>
                <p className="graph-listing-row">
                  <FormattedMessage id="dashboard.graph.dropmenu.four" />
                </p>
              </div>
              <hr className=" hr-list my-2 mx-0"></hr>
              <div className=" s14 graph-listing  dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  {
                    biggerThan1000 ?
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.six" />
                    :
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.six.short" />
                  }
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(switchArray(index).firstGeneration.personalIncorporated.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  ).firstGeneration.personalIncorporated.percentage.toLocaleString()}
                </p>
                <p className=" graph-listing-row blackColor">
                  {formatAmount(switchArray(index).firstGeneration.personalIncorporated.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
              </div>
              <hr className=" hr-list my-2 mx-0"></hr>
              <div className=" s14 graph-listing  dm-sans-medium  align-items-center grayColor ">
                <p className=" museo-sans-500  blackColor">
                  <FormattedMessage id="dashboard.graph.dropmenu.sublevel.seven" />
                </p>
                <p className=" graph-listing-row ">
                  {formatAmount(switchArray(index).firstGeneration.restOfGroup.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                </p>
                <p className=" graph-listing-row ">
                  %{" "}
                  {switchArray(
                    index
                  ).firstGeneration.restOfGroup.percentage.toLocaleString()}
                </p>
                <p className=" graph-listing-row  blackColor">
                  {formatAmount(switchArray(index).firstGeneration.restOfGroup.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  
                </p>
              </div>
              {level > 3 && (
                <>
                  <hr className=" hr-list my-2 mx-0"></hr>
                  <div className=" s14 graph-listing dm-sans-medium  align-items-center grayColor ">
                    <p className=" museo-sans-500  blackColor">
                    {
                    biggerThan1000 ?
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.eight" />
                    :
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.eight.short" />
                  }
                    </p>
                    <p className=" graph-listing-row ">
                      {formatAmount(switchArray(index).secondGeneration.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </p>
                    <p className=" graph-listing-row ">
                      %{" "}
                      {switchArray(
                        index
                      ).secondGeneration.percentage.toLocaleString()}
                    </p>
                    <p className=" graph-listing-row blackColor">
                      {formatAmount(switchArray(index).secondGeneration.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </p>
                  </div>
                </>
              )}
              {level > 4 && (
                <>
                  <hr className=" hr-list my-2 mx-0"></hr>
                  <div className=" s14 graph-listing dm-sans-medium  align-items-center grayColor ">
                    <p className=" museo-sans-500  blackColor">
                    {
                    biggerThan1000 ?
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.nine" />
                    :
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.nine.short" />
                  }
                    </p>
                    <p className=" graph-listing-row ">
                      {formatAmount(switchArray(index).thirdGeneration.totalSale, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </p>
                    <p className=" graph-listing-row ">
                      %{" "}
                      {switchArray(
                        index
                      ).thirdGeneration.percentage.toLocaleString()}
                    </p>
                    <p className=" graph-listing-row blackColor">
                      {formatAmount(switchArray(index).thirdGeneration.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                    </p>
                  </div>
                </>
              )}
            </div>
          )
        );
      case 3:
        return (
          toggle && (
            <div  className=" f9GrayBg p-3">
              <div
                style={{ background: "#ECF0FA" }}
                className=" rounded-2 p-3 gap-3 d-flex blueColor"
              >
                <div>
                  <SVGMedal />
                </div>
                <p className=" s14 museo-sans-500">
                  <FormattedMessage id="dashboard.graph.label.bonus" />
                </p>
              </div>
            </div>
          )
        );
    }
  };

  const swithcSubtitle = (index) => {
    switch (index) {
      case 0:
        return (
          <p className=" s14 museo-sans-500  ">
            {
              biggerThan1000 ?
              <FormattedMessage id="dashboard.graph.sublabel.one" />
              :
              <FormattedMessage id="dashboard.graph.sublabel.one.short" />
            }
            {" "}
            ({switchArray(index)?.earningPercentage}%)
          </p>
        );
      case 1:
        return (
          <p className=" s14 museo-sans-500 ">
           {
              biggerThan1000 ?
              <FormattedMessage id="dashboard.graph.sublabel.two" />
              :
              <FormattedMessage id="dashboard.graph.sublabel.two.short" />
            }
          </p>
        );
      case 2:
        return (
          <p className=" s14 museo-sans-500 ">
            {
              biggerThan1000 ?
              <FormattedMessage id="dashboard.graph.sublabel.three" />
              :
              <FormattedMessage id="dashboard.graph.sublabel.three.short" />
            }
          </p>
        );
    }
  };

  const onClick = () => {
    setToggle(!toggle);
  };

  const [toggle, setToggle] = useState(false);

  return (
    <>
      <div onClick={onClick} className=" pointer mb-4 d-flex justify-content-between position-relative ">
        <>
          {
            !toggle ? 
            <p className=" px-1 dm-sans-medium s16 position-absolute" style={{right
              :"150px"}}>
                <>
                {
                  switchArray(index)?.amount === 0 ?
                  null
                  :
                  <>
                    {
                      switchArray(index)?.amount <= 0 || switchArray(index)?.amount === undefined ? "-" : "+"
                    }
                  </>
                }
                </>
               
            </p>
            :
            null
          }
        </>
        <div className=" gap-3 align-items-center d-flex ">
          <div
            style={{ width: "10px", height: "10px", minWidth: "10px" }}
            className={`${switchColor(index)} rounded-circle `}
          ></div>

          <div style={{minWidth: "fit-content"}}  className=" align-items-center gap-1 d-flex">
            {toggle ? (
              <p style={{minWidth: "fit-content"}} className=" s16 museo-sans-700 text-uppercase">
                {switchTitle(index)}
              </p>
            ) : (
              <p style={{minWidth: "fit-content"}} className=" s16 museo-sans-500 text-uppercase">
                {switchTitle(index)}
              </p>
            )}
          </div>

        </div>
        <div  className="d-flex align-items-center ">

          {
            biggerThan1000 ?

          <p className=" s14 museo-sans-500 px-3 ">
            {toggle && swithcSubtitle(index)}
          </p>
            :
          <p className=" s12 museo-sans-500 px-3 ">
            {toggle && swithcSubtitle(index)}
          </p>

          }
          <p style={{minWidth: "fit-content"}} className={` px-1 dm-sans-medium s16`}>
            {switchArray(index)?.amount === 0 || switchArray(index)?.amount === undefined
              ? "Sin Ventas"
              : formatAmount(switchArray(index)?.amount < 0 ? switchArray(index)?.amount*-1 : switchArray(index)?.amount, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)
            } 
          </p>
          <>
            {toggle && (
              <div
                
                className=" control--svg-filler-sky pointer"
              >
                <SVGChevArrowUp />
              </div>
            )}
            {!toggle && (
              <div
                
                className=" control--svg-filler-sky pointer"
              >
                <SVGChevArrowDown />
              </div>
            )}
          </>
        </div>
      </div>
      {switchDropDownMenu(index)}
    </>
  );
}