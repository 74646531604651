import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export default function LoginPinCheck() {
    const history = useNavigate();

    const onContinue = (evt) => {
        evt.preventDefault();
        history("/profile")
    }
  
    return (
        <div id="login-layout" className=" container--padding py-5">
        <div className="">
          <h1 className="mt-3 s12 museo-sans-500 grayColor text-uppercase">
            <FormattedMessage
              id="login.pin.page.title"
              defaultMessage="1. Profile settings"
            />
          </h1>
          <hr></hr>
              <h2 className="s24 museo-sans-700 mt-4">
                <FormattedMessage
                  id="login.pin.page.subtitle.2"
                  defaultMessage="Profile picture"
                />
              </h2>
              <p className="s16 w-75 museo-sans-500">
                <FormattedMessage
                  id="login.pin.page.description.2"
                  defaultMessage="Before starting the website, please upload a profile picture that shows your face."
                />
              </p>
              <div id="login--confirm-sms" className="position-relative mt-5 d-flex">
              <InputPIN name={"one"} />
              <InputPIN name={"two"} />
              <InputPIN name={"three"} />
              <InputPIN name={"four"} />
        </div>
          <div className="mt-3 redColor">
                <p className="err"> <i className="bi bi-x-circle-fill"></i> Error por mandar PIN diferentes. Desaparece de no ser así.</p>
          </div>

        </div>
        <div className="align-self-end justify-items-center position-relative">

          <button
            onClick={onContinue}
            className="position-relative mb-3 s14 museo-sans-700 px-4 py-2 text-white btnCustom skyBg"
          >
            <FormattedMessage id="buttons.continue" defaultMessage="Continue" />
          </button>
          
          
        </div>
      </div>
    );
  }

  export function InputPIN({name})  {

    const objectListMargin = {one: "", three:"", two: "mx-3", four: "mx-3"};

    return(
      <input
            
            name={name}
            placeholder="X"
            className={`form-control input-borders ${objectListMargin[name]} `}
            maxLength={1}
            type="text"
          />
    )
  }