import { useEffect } from "react";
import { useRef, forwardRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import ReturnToAndTitle from "../../../Share/ReturnToAndTitle";
import { postPersonalDataValidationEmailCode } from "../../Framework/ConfigurationSideEffect";

export default function ConfigChangeSuccessful({
  travelFunction,
  title,
  setModalTexts,
  savedValues,
}) {
  const [inputs, setInputs] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  const [toggler, setToggler] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);

  const onChange = (evt) => {
    let aux = { ...inputs };
    if (isNaN(evt.target.value)) {
      return;
    }
    aux[evt.target.name] = evt.target.value;
    setInputs(aux);

    const allFieldsFilled = Object.values(aux).every((value) => value !== "");
    if (allFieldsFilled) {
      setToggler(true);
    } else {
      setToggler(false);
    }
    setError(false);
  };

  const onContinue = () => {
    const modalData = {
      title: "Cambiaste tu dirección de E-mail ",
      text: "Los cambios en tu dirección de E-mail se han guardado con éxito.",
      status: "confirm",
      show: false,
      bottom: 0,
    };
    setModalTexts(modalData);
    const clickMe = document.getElementById("closeModal");
    clickMe.click();
  };

  const validateCode = async () => {
    const data = {
      ...savedValues,
      userOtpCode: `${inputs.one}${inputs.two}${inputs.three}${inputs.four}`,
    };
    const result = await dispatch(postPersonalDataValidationEmailCode(data));
    if (result?.error || result?.payload?.code === 121) {
      setError(true);
      return;
    }
    onContinue();
  };

  const onPasteValue = (pastedText) => {
    const pasteArray = pastedText.split("");
    const pasteArrayLength = pasteArray.length;
    if (pasteArrayLength === 4) {
      setInputs({
        one: pasteArray[0],
        two: pasteArray[1],
        three: pasteArray[2],
        four: pasteArray[3],
      });
      setToggler(true);
      return;
    }
  };

  const onVoid = () => {};

  return (
    <div className="d-flex flex-column gap-4">
      <ReturnToAndTitle
        travelFunction={travelFunction}
        returnTo={{ value: 1.2, whereTo: "buttons.returnToMyData" }}
        title={title}
      ></ReturnToAndTitle>
      <p className=" s12 text-uppercase  grayColor museo-sans-500">
        <FormattedMessage id="settings.title.change.email" />
      </p>
      <p className="s24 museo-sans-700">
        <FormattedMessage id="settings.title.change.email.subtitle.two" />
        <p>
          <FormattedMessage id="settings.title.change.email.subtitle.two.sub.two" />
        </p>
      </p>
      <div id="login--confirm-sms" className="position-relative d-flex">
        <ConfigurePin
          onChange={onChange}
          nextRef={refTwo}
          ref={refOne}
          placeholder={"0"}
          name={"one"}
          values={inputs}
          onPasteValue={onPasteValue}
        />
        <ConfigurePin
          onChange={onChange}
          nextRef={refThree}
          ref={refTwo}
          placeholder={"0"}
          values={inputs}
          name={"two"}
        />
        <ConfigurePin
          onChange={onChange}
          nextRef={refFour}
          ref={refThree}
          placeholder={"0"}
          values={inputs}
          name={"three"}
        />
        <ConfigurePin
          onChange={onChange}
          nextRef={null}
          ref={refFour}
          placeholder={"0"}
          values={inputs}
          name={"four"}
        />
      </div>
      {error && (
        <p className=" my-2 w-50 museo-sans-500 s12 lightRedColor">
          <FormattedMessage id="settings.error.pin" />
        </p>
      )}
      <button
        onClick={toggler ? validateCode : onVoid}
        style={{ width: "308px", height: "40px" }}
        className={` mt-4 museo-sans-700 border-0 ${
          toggler ? "skyBg" : "brightGrayBg"
        } rounded-2 text-white`}
      >
        <FormattedMessage id="settings.validate.code" />
      </button>
    </div>
  );
}

export const ConfigurePin = forwardRef(
  ({ name, placeholder, onChange, nextRef, onPasteValue, values }, ref) => {
    const objectListMargin = { one: "", three: "", two: "mx-3", four: "mx-3" };

    const handleChange = (evt) => {
      onChange(evt);
      if (evt.target.value.length === 1 && nextRef && nextRef.current) {
        nextRef.current.value = "";
        nextRef.current.focus();
      }
    };

    useEffect(() => {
      const input = document.getElementById("pin-one");
    input.addEventListener("paste", function (event) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData("text");
      onPasteValue(pastedData);
    });
    }, [] )

    return (
      <input
        id={`pin-${name}`}
        ref={ref}
        name={name}
        value={values[name]}
        onChange={handleChange}
        placeholder={placeholder || "X"}
        className={`form-control input-borders ${objectListMargin[name]} `}
        maxLength={1}
        type="text"
      />
    );
  }
);
