import {ReactComponent as SVGHelpMdIcon} from "../svgs/helpIcons/ic-help-circle-md.svg"
import {ReactComponent as SVGHelpSmIcon} from "../svgs/helpIcons/ic-help-circle-sm.svg"

export default function HelpIcon({size}) {
    return <>
        {
            size == "sm" ?
            <div
                className="d-flex pointer"
              data-bs-toggle="modal"
              data-bs-target="#modalInfo"
            >
                <SVGHelpSmIcon />
            </div>
            :
            <div
            className="d-flex pointer"
              data-bs-toggle="modal"
              data-bs-target="#modalInfo"
            >
                <SVGHelpMdIcon />
            </div>
        }
    </>    
}