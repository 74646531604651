import { createReducer } from "@reduxjs/toolkit";

import { modifyProfilePicture, modifyMultiLanguage, reSetMultiLanguage, openVimeo, closeVimeo, setArrayVimeo, clearArrayVimeo } from "./commonActions";

import { updateProfilePicture, logOut, fetchPerformance } from "./commonSideEffects";

import LangArg from "../lang/es-AR.json";
import LangMx from "../lang/es-MX.json";
import LangCr from "../lang/es-CR.json";
import LangPe from "../lang/es-PE.json";
import LangCl from "../lang/es-CL.json";
import LangUy from "../lang/es-UY.json";
import LangPa from "../lang/es-PA.json";
import LangCo from "../lang/es-CO.json";

const initialState = {
  lang: {
    code: "ar",
    folder: "",
  },
  vimeo: {
    loaded: false,
    id: "",
    key: "",
    title: "",
    array: null
  },
  data: {
    profilePicture: "https://via.placeholder.com/60",
    name: "Guadalupe Peyrallo",
    positionAndNumber: "Consultora #15786",
    emailAddress: "email@email.com",
    phoneNumber: "1122334455",
    streetAddress: "Av Forest 234 CABA",
    role: "Consultora",
  },
  performanceScore: {

  },
  notifications: {
    periodClosure: false,
    keyManteinance: false,
    newIncorporations: true,
    firstSteps: true,
    newPeriod: false,
    desktopAlerts: false,
  },
  password: "",
  urls: {
    downloadLocation: '',
    uploadLocation: ''
  },
  loading: false,
  loggingOut: false,
};


const commonReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchPerformance.pending, (state, action) => {
          state.loading = true;
        })
        .addCase(fetchPerformance.fulfilled, (state, action) => {
          state.performanceScore = action.payload;
        })
        .addCase(fetchPerformance.rejected, (state, action) => {
          state.loading = false;
        })
        .addCase(modifyProfilePicture, (state, action) => {
            state.data.profilePicture = action.payload;
        })
        .addCase(modifyMultiLanguage, (state, action) => {
          state.lang.code = action.payload.code;
          state.lang.folder = action.payload.folder;
        })
        .addCase(openVimeo, (state, action) => {
          state.vimeo.loaded = true;
          state.vimeo.id = action.payload.id;
          state.vimeo.key = action.payload.key;
          state.vimeo.title = action.payload.title;
        })
        .addCase(closeVimeo, (state, action) => {
          state.vimeo.loaded = false;
          state.vimeo.id = "";
          state.vimeo.key = "";
          state.vimeo.title = "";
        })
        .addCase(setArrayVimeo, (state, action) => {
          state.vimeo.array = action.payload;
        })
        .addCase(clearArrayVimeo, (state, action) => {
          state.vimeo.array = null;
        })
        .addCase(reSetMultiLanguage, (state, action) => {
          switch (action.payload) {
            case "ar":
              state.lang.folder = LangArg;
              return
      
            case "mx":
              state.lang.folder = LangMx;
              return
              case "cl":
                state.lang.folder = LangCl;
                return
            case "cr":
              state.lang.folder = LangCr;
              return

            case "pe":
              state.lang.folder = LangPe;
              return
            
              case "uy":
                state.lang.folder = LangUy;
                return

                case "pa":
                  state.lang.folder = LangPa;
                  return
                  case "co":
                    state.lang.folder = LangCo;
                    return
            default:
              state.lang.folder = LangMx;
          }
        })
        .addCase(updateProfilePicture.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(updateProfilePicture.fulfilled, (state, action) => {
            state.loading = false;
        })
        .addCase(updateProfilePicture.rejected, (state, action) => {
            state.loading = false;
        })
        .addCase(logOut.pending, (state, action) => {
            state.loggingOut = true
        })
        .addCase(logOut.fulfilled, (state, action) => {
            state.loggingOut = false
        })
        .addCase(logOut.rejected, (state, action) => {
            state.loggingOut = false
        })
})


export default commonReducer;