import { useSelector } from "react-redux";
import { ReactComponent as SVGCross } from "../../../../common/svgs/ic-x-lg.svg";
import ShareModalShareBody from "../../../../common/Share/ShareModalShareBody";
import { FormattedMessage } from "react-intl";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import { useRef } from "react";

export default function ClientListDetailsModalEDBCard({ open, data }) {
  const link = "www.google.com";

  const user = useSelector((state) => state.userInfo.userInfo);
  const scrollbarRef = useRef(null);
  const socialArray = useSelector(
    (state) => state.userInfo.userInfo?.socialNetwork
  );

  const configExist = (auxId, message) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const textOne = `Hola, estuve viendo las Tarjetas de Necesidad que seleccionaste al registrate en el Encuentro de Necesidad que vas a asistir, y me parece que estos productos pueden interesarte para cubrir tus necesidades de Cuidados frecuentes`;
  const textTwo = `Me encantaría poder contarte un poco más sobre estos productos, incluso podrías obtenerlos gratis!`;
  const textThree = `Si te interesa, estoy disponible para contarte más`;

  const sharedDataPhoneText =
    socialArray &&
    `${textOne}%20${data?.relatedProducts.map((prod) => {
      return `%0A%0A_${prod}`;
    })} %0A%0A${textTwo}%20%0A${textThree}`;

  const sharedData =
    socialArray &&
    `${textOne}${data?.relatedProducts.map((prod) => {
      return `\n\n👉${prod}`;
    })} \n\n${textTwo}\n${textThree}`;

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(sharedData);
      open();
    } catch (error) {
      unsecuredCopyToClipboard(sharedData);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      open();
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <div className="d-flex flex-column">
      <div
        style={{ height: "108px" }}
        className="w-100 p-4 d-flex justify-content-between align-items-center gap-2 "
      >
        <div style={{ width: "48px" }} className="h-100">
          <img src={`${data?.img}`} className="w-100" />
        </div>
        <div className="w-100 px-1 museo-sans-700 s20 blackColor">
          <p>{data?.cardName}</p>
        </div>
        <div
          className="pointer svg-force-size "
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <SVGCross />
        </div>
      </div>
      <div>
        <hr className="m-0" />
      </div>
      <div style={{ height: "281px" }} className="w-100 p-4">
        <div className="px-2 h-100 w-100 d-flex justify-content-between gap-4 ">
          <div className=" h-100 w-100 d-flex flex-column gap-4 ">
            <p className="s12 grayColor">
              <FormattedMessage id="client.details.edb.cards.modal.title.one" />
            </p>
            <div
              ref={scrollbarRef}
              className=" hidden-y-scroll museo-sans-500 blackColor s14"
            >
              {data?.relatedProducts?.map((product, index) => {
                return (
                  <ClientListDetailsModalEDBCardRowList
                    lastIndex={data?.relatedProducts?.length}
                    index={index}
                    product={product}
                  />
                );
              })}
            </div>
            <ModalWhiteShadow
              left={"4%"}
              width={"44%"}
              bottom={"2%"}
              aidHide={40}
              scrollbarRef={scrollbarRef}
            />
          </div>
          <div style={{ width: "1px" }} className="h-100 brightGrayBg "></div>
          <div className=" blackColor h-100 w-100">
            {socialArray && (
              <ShareModalShareBody
                sharedDataPhoneText={sharedData}
                sharedData={sharedData}
                socialArray={socialArray}
                configExist={configExist}
                doThis={doThis}
                integratedComponente={true}
                copyText={
                  <p
                    style={{ width: "100px" }}
                    className=" museo-sans-500 s14 mt-2 p-1 d-flex justify-content-center text-center"
                  >
                    <FormattedMessage
                      id={"clients.details.edb.card.share.button.copy"}
                    />
                  </p>
                }
                modalTitle={"clients.details.edb.card.share.title"}
              />
            )}
          </div>
        </div>
      </div>
      <a
        id="mail-touch"
        href={`mailto:?&subject=?&body=${encodeURIComponent(link)}`}
        target="_blank"
        className="ms-2 invisible bronceStroke grayColor cancelBootstrap"
      >
        <p>Invisible!</p>
      </a>
      <a
        id="what-touch"
        href={`https://wa.me/?text=${encodeURIComponent(link)}`}
        target="_blank"
        className="ms-2 invisible bronceStroke grayColor cancelBootstrap"
      >
        <p>Invisible!</p>
      </a>
    </div>
  );
}

export function ClientListDetailsModalEDBCardRowList({
  product,
  index,
  lastIndex,
}) {
  return (
    <div
      style={{ gap: "12px", marginBottom: "12px" }}
      className="d-flex flex-column"
    >
      <p>{product}</p>
      {index !== lastIndex - 1 && <hr className=" m-0" />}
    </div>
  );
}
