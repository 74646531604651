import { FormattedMessage } from "react-intl";

import { useRef } from "react";

import { formatAmount } from "../../../../common/Share/FormatCurrency";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";
import HandleSwissDates from "../../../../common/Share/HandleSwissDate";
import { useSelector } from "react-redux";
import ShareKeyGenerator from "../../../../common/Share/ShareKeyGenerator";

export default function PersonalSaleModalCredit() {
  const scrollbarRef = useRef(null);
  const data = useSelector((state) => state.performanceData.creditNotes);

  return (
    <>
      <div
        className="modal fade"
        id="modalInfoCredit"
        aria-hidden="true"
        aria-labelledby="modalInfoCredit"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "608px" }}
        >
          <div
            style={{ minHeight: "425px", maxHeight: "608px" }}
            className="modal-content modal-radius"
          >
            <div className=" d-flex justify-content-between mx-3 p-4 my-3 align-items-center">
              <div className="d-flex">
                <h5 className="museo-sans-500 s20 m-0">
                  <FormattedMessage id="performance.modal.credit.notes.title" />
                </h5>
              </div>
              <div
                className=" pointer "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <SVGClose />
              </div>

            </div>
            <hr className="m-0"></hr>
            {
              data &&
              <div className=" h-100 museo-sans-500 d-flex flex-column align-items-center">
                <div
                  ref={scrollbarRef}
                  style={{ maxHeight: "373px", overflowY: "scroll" }}
                  className=" edb-noscroll px-4 mx-3  "
                >
                  <p className="s16 my-3 py-3  museo-sans-500 grayColor ">
                    <FormattedMessage id="performance.modal.credit.notes.subtitle" />
                  </p>
                  <div
                    style={{ minHeight: "64px" }}
                    className=" "
                  >
                    <div className=" mb-3 pb-3 grayColor museo-sans-500 grid-credit-table w-100 s12 ">
                      <p>
                          <FormattedMessage id="performance.modal.credit.notes.header.notes" />
                      </p>
                      <p className=" text-uppercase grid-self-justify-center  ">
                          <FormattedMessage id="date" />
                      </p>
                      <p style={{paddingRight: "23px"}} className=" text-uppercase grid-self-justify-end ">
                          <FormattedMessage id="personal.sale.details.list.five" />
                      </p>
                    </div>
                    <div className=" d-flex flex-column gap-4  ">
                      
                      {
                          data &&
                          data.map((element, index) => {
                              return <>
                                  <PersonalSalesModalCreditRow key={ShareKeyGenerator()} element={element} index={index} lengthReduced={data?.length - 1} />
                              
                              </>
                          })
                      }
                    </div>

                    <div></div>
                  </div>
                </div>
                {
                  data?.length > 3 &&
                  <ModalWhiteShadow scrollbarRef={scrollbarRef} width={"88%"} left={35} />
                }
                <div className=" w-100 h-100 d-flex justify-content-center my-4 py-3  ">
                  <button
                    type="button"
                    className=" btnLoginWidth museo-sans-700 s14 skyBg skyBorder whiteColor button-hover-light-text button-bootstrap-radius button-padding"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <FormattedMessage
                      id="buttons.understood"
                      defaultMessage="Entendido"
                    />
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

export function PersonalSalesModalCreditRow({element, index, lengthReduced}) {

    const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
    
    return <div style={{ height: "42px" }} className=" ">
    <div
      style={{ height: "42px" }}
      className=" museo-sans-500 s14 grid-credit-table w-100"
    >
      <p className=" blackColor">
        <FormattedMessage id={"performance.modal.credit.notes.row.p"} /> {element.id}
      </p>
      <p className=" grid-self-justify-center grayColor dm-sans-medium ">
        <HandleSwissDates date={element.date} />
      </p>
      <p className=" grid-self-justify-end blackColor dm-sans-medium pe-1 me-3 ">
        {
            formatAmount(
                element.amount,
                cultureInfo?.languageCode, 
                cultureInfo?.defaultCurrencyCode
            )
        }
      </p>
    </div>
    {
        lengthReduced != index &&
        <hr className=" m-0" ></hr>
    }
  </div>
}