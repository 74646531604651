import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { changeGroupTarget, defineStepNumber, activeErrors } from "./dashboardAction";

import {
    getScore , getGroup, getPotentialComparisonLevel
} from "./dashboardSideEffect";
import { activateOnBoarding, deactivateOnBoarding } from "./onBoardingAction";

const initialState = {
  personalSales: {},
  incorporatedSales: {},
  groupSales: {},
  descentSales: {},
  groups: {
    totalNeed: null,
    actualQualified: null,
    array: [],
  },
  groupTarget: null,
  consultantStatus: {},
  generalDataArray: null,
  loader: false,
  stepNumber: 0,
  onBoarding: false,
  scoreDataError: false,
  groupsDataError: false,
  potentialDataError: false,
  potentialLeaderScore: null
};

const scoreReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getPotentialComparisonLevel.fulfilled, ( state, action) => {
      state.potentialLeaderScore = action.payload;
    })
    .addCase(activeErrors, (state, action) => {
      state.scoreDataError = true
      state.groupsDataError = true
      state.potentialDataError = true
    })
    .addCase(getScore.fulfilled, (state, action) => {
      state.personalSales = action.payload.personalSales;

      state.incorporatedSales = action.payload.incorporatedSales;

      state.groupSales = action.payload.groupSales;

      state.descentSales = action.payload.descentSales;

      state.consultantStatus = action.payload.consultantStatus;

      state.generalDataArray = action.payload;
      state.loader = false;
      state.scoreDataError = false;
    })

    .addCase(changeGroupTarget, (state, action) => {
      state.groupTarget = action.payload
      state.loader = false;
    })

    .addCase(activateOnBoarding, (state, action) => {
      state.onBoarding = true;
    } )

    .addCase(deactivateOnBoarding, (state, action) => {
      state.onBoarding = false;
    } )

    .addCase(defineStepNumber, (state, action) => {
        state.stepNumber = action.payload
        state.loader = false;
      })

    .addCase(getGroup.fulfilled, (state, action) => {

        state.groups.totalNeed = action.payload.totalNeed;
        state.groups.actualQualified = action.payload.actualQualified;

        state.groups.array = action.payload.groups;
        state.groupsDataError = false;
        state.loader = false;
      })

      .addCase(getGroup.rejected , (state, action) => {
          state.loader = false;
          state.groupsDataError = true;
      } )
  

    .addMatcher(
      
      isAnyOf(
        
        getScore.pending,
        getGroup.pending
      ),
      (state, action) => {
        state.loader = true;
      }
    )
    .addMatcher(
      isAnyOf(
        getScore.rejected,
      ),
      (state, action) => {
        state.loader = false;
        state.scoreDataError = true;
      }
    )

    /*.addMatcher(
        isAllOf(
            getScore.fulfilled,
            getGroup.fulfilled
        ),
        (state, action) => {
            state.personalSales = action.payload.personalSales;

            state.incorporatedSales = action.payload.incorporatedSales;

            state.groupSales = action.payload.groupSales;

            state.descentSales = action.payload.descentSales;

            state.groups.totalNeed = action.payload.totalNeed;
            state.groups.actualQualified = action.payload.actualQualified;

            state.groups.array = action.payload.groups;

            state.loader = false;
        }
    );*/
});

export default scoreReducer;
