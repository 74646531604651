import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import firstStepsAdjustDate from "./moments/firstStepsAdjustDate";

export function FirstStepsHelpMessage({
  index,
  data,
  extraData,
  redeemEndDate,
  outsider,
  amount,
}) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const blueState = "backgroundShallowBlue blueColor";
  const warningState = "warningBg darkYellowColor";
  const redState = "lightRedBgOpacity20 lightRedColor";

  const measureHoursTillReach = (data, command, boolean, extra) => {
    let value = prepareRequestForDate(data, command, extra);

    if (value > 72) {
      return "blue";
    }
    if (value > 24 && !boolean) {
      return "warning";
    }

    return "red";
  };

  const controlStateColors = (state) => {
    if (state == "blue") {
      return blueState;
    }
    if (state == "warning") {
      return warningState;
    }
    return redState;
  };

  const prepareRequestForDate = (data, command, extra) => {
    let result = firstStepsAdjustDate({
      reached: data?.stepDetail?.reachedDate,
      completed: data?.stepDetail?.completedDate,
      limit: data?.stepDetail?.limitDate,
      command: command,
      extra: extra,
    });
    return result;
  };

  return (
    <>
      {data?.stepDetail?.state != "FAILED" && (
        <>
          {index == 1 &&
            prepareRequestForDate(data, null, null) > 0 &&
            data?.stepDetail?.state != "REACHED" && (
              <div
                style={{ borderRadius: "8px" }}
                className={`museo-sans-500 mt-2 w-100  s14 px-3 py-2 ${controlStateColors(
                  measureHoursTillReach(
                    data,
                    null,
                    data?.stepDetail?.state != "REACHED"
                  )
                )} `}
              >
                <>
                  {measureHoursTillReach(data, null, true) == "blue" && (
                    <>
                      <FormattedMessage
                        id={`first.steps.component.help.message.p.one${
                          outsider ? ".outsider" : ""
                        }`}
                      />{" "}
                      {formatAmount(
                        data?.stepDetail?.stepAmount -
                          data?.stepDetail?.currentAmount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}{" "}
                      <FormattedMessage id="in" />{" "}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays")}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays") ==
                      1 ? (
                        <FormattedMessage id="first.steps.component.help.message.p.subOne.day" />
                      ) : (
                        <FormattedMessage id="first.steps.component.help.message.p.subOne.days" />
                      )}
                      {
                        true ?
                        <>
                          <FormattedMessage  id="first.steps.component.help.message.p.subOne" values={{ pct: amount }} />
                        </>
                        :
                        <> 
                          <FormattedMessage id="first.steps.component.prise.message.p.subOne.gift" />
                        </>
                      }
                    </>
                  )}
                  {measureHoursTillReach(data, null, true) == "red" && (
                    <>
                      <FormattedMessage
                        id={`first.steps.component.help.message.p.two${
                          outsider ? ".outsider" : ""
                        }`}
                      />{" "}
                      {formatAmount(
                        data?.stepDetail?.stepAmount -
                          data?.stepDetail?.currentAmount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}{" "}
                      <FormattedMessage id="in" />{" "}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays")}{" "}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays") ==
                      1 ? (
                        <FormattedMessage id="first.steps.component.help.message.p.subTwo.single" />
                      ) : (
                        <FormattedMessage id="first.steps.component.help.message.p.subTwo" />
                      )}
                    </>
                  )}
                </>
              </div>
            )}
          {index == 1 &&
            data?.stepDetail?.state == "REACHED" &&
            prepareRequestForDate(data, "fetchRedeemDistance", redeemEndDate) >=
              0 && (
              <div
                style={{ borderRadius: "8px" }}
                className={`museo-sans-500 mt-2 w-100  s14 px-3 py-2 ${controlStateColors(
                  measureHoursTillReach(
                    data,
                    "fetchRedeemDistance",
                    data?.stepDetail?.state != "REACHED",
                    redeemEndDate
                  )
                )} `}
              >
                <>
                  {prepareRequestForDate(
                    data,
                    "fetchRedeemDistance",
                    redeemEndDate
                  ) > 74 && (
                    <>
                      <FormattedMessage
                        id={`first.steps.component.prise.message.p.one${
                          outsider ? ".outsider" : ""
                        }`}
                      />{" "}
                      {prepareRequestForDate(
                        data,
                        "fetchRedeemDate",
                        redeemEndDate
                      )}{" "}
                        <FormattedMessage
                          id="first.steps.component.prise.message.p.subOne.50"
                          values={{ pct: amount }}
                        />
                    </>
                  )}
                  {prepareRequestForDate(
                    data,
                    "fetchRedeemDistance",
                    redeemEndDate
                  ) > 24 &&
                    prepareRequestForDate(
                      data,
                      "fetchRedeemDistance",
                      redeemEndDate
                    ) <= 74 && (
                      <>
                          <FormattedMessage
                            id="first.steps.component.prise.message.p.subTwo.50"
                            values={{ pct: amount }}
                          />
                      </>
                    )}
                  {prepareRequestForDate(
                    data,
                    "fetchRedeemDistance",
                    redeemEndDate
                  ) > 0 &&
                    prepareRequestForDate(
                      data,
                      "fetchRedeemDistance",
                      redeemEndDate
                    ) <= 24 && (
                      <>
                          <FormattedMessage
                            id="first.steps.component.prise.message.p.subThree.50"
                            values={{ pct: amount }}
                          />
                      </>
                    )}
                </>
              </div>
            )}
          {index == 2 &&
            prepareRequestForDate(data, null, null) > 0 &&
            data?.stepDetail?.state != "REACHED" && (
              <div
                style={{ borderRadius: "8px" }}
                className={`museo-sans-500 mt-2 w-100  s14 px-3 py-2 ${controlStateColors(
                  measureHoursTillReach(
                    data,
                    null,
                    data?.stepDetail?.state != "REACHED"
                  )
                )} `}
              >
                <>
                  {measureHoursTillReach(data, null, true) == "blue" && (
                    <>
                      <FormattedMessage
                        id={`first.steps.component.help.message.p.one${
                          outsider ? ".outsider" : ""
                        }`}
                      />{" "}
                      {formatAmount(
                        data?.stepDetail?.stepAmount -
                          data?.stepDetail?.currentAmount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}{" "}
                      <FormattedMessage id="in" />{" "}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays")}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays") ==
                      1 ? (
                        <FormattedMessage id="first.steps.component.help.message.p.subOne.day" />
                      ) : (
                        <FormattedMessage id="first.steps.component.help.message.p.subOne.days" />
                      )}
                      {
                        true ?
                        <>
                          <FormattedMessage values={{ pct: amount }} id="first.steps.component.help.message.p.subOne.75" />
                        </>
                        :
                        <> 
                          <FormattedMessage id="first.steps.component.prise.message.p.subOne.gift" />
                        </>
                      }
                    </>
                  )}
                  {measureHoursTillReach(data, null, true) == "red" && (
                    <>
                      <FormattedMessage
                        id={`first.steps.component.help.message.p.two${
                          outsider ? ".outsider" : ""
                        }`}
                      />{" "}
                      {formatAmount(
                        data?.stepDetail?.stepAmount -
                          data?.stepDetail?.currentAmount,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}{" "}
                      <FormattedMessage id="in" />{" "}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays")}{" "}
                      {prepareRequestForDate(data, "fetchLimitDayOnDays") ==
                      1 ? (
                        <FormattedMessage id="first.steps.component.help.message.p.subTwo.single" />
                      ) : (
                        <FormattedMessage id="first.steps.component.help.message.p.subTwo" />
                      )}
                    </>
                  )}
                </>
              </div>
            )}
          {index == 2 &&
            data?.stepDetail?.state == "REACHED" &&
            prepareRequestForDate(data, "fetchRedeemDistance", redeemEndDate) >=
              0 && (
              <div
                style={{ borderRadius: "8px" }}
                className={`museo-sans-500 mt-2 w-100  s14 px-3 py-2 ${controlStateColors(
                  measureHoursTillReach(
                    data,
                    "fetchRedeemDistance",
                    data?.stepDetail?.state != "REACHED",
                    redeemEndDate
                  )
                )} `}
              >
                <>
                  {prepareRequestForDate(
                    data,
                    "fetchRedeemDistance",
                    redeemEndDate
                  ) > 74 && (
                    <>
                      
                      <FormattedMessage
                        id={`first.steps.component.prise.message.p.one${
                          outsider ? ".outsider" : ""
                        }`}
                      />{" "}
                      {prepareRequestForDate(
                        data,
                        "fetchRedeemDate",
                        redeemEndDate
                      )}{" "}
                      {
                        true ?
                        <>
                          <FormattedMessage
                            id="first.steps.component.prise.message.p.subOne.75"
                            values={{ pct: amount }}
                          />{" "}
                        
                        </>
                        :
                        <>
                        <FormattedMessage id="first.steps.component.prise.message.p.subOne.gift" />
                        </>
                      }
                    </>
                  )}
                  {prepareRequestForDate(
                    data,
                    "fetchRedeemDistance",
                    redeemEndDate
                  ) > 24 &&
                    prepareRequestForDate(
                      data,
                      "fetchRedeemDistance",
                      redeemEndDate
                    ) <= 74 && (
                      <>
                          <FormattedMessage
                          id={`first.steps.component.prise.message.p.subTwo.75${
                            outsider ? ".outsider" : ""
                          }`}
                          values={{ pct: amount }}
                        />
                      </>
                    )}
                  {prepareRequestForDate(
                    data,
                    "fetchRedeemDistance",
                    redeemEndDate
                  ) > 0 &&
                    prepareRequestForDate(
                      data,
                      "fetchRedeemDistance",
                      redeemEndDate
                    ) <= 24 && (
                      <>
                      <>
                          <FormattedMessage
                          id={`first.steps.component.prise.message.p.subThree.75${
                            outsider ? ".outsider" : ""
                          }`}
                          values={{ pct: amount }}
                        />
                      </>
                        <FormattedMessage
                          id={`first.steps.component.prise.message.p.subThree.75${
                            outsider ? ".outsider" : ""
                          }`}
                          values={{ pct: amount }}
                        />{" "}
                      </>
                    )}
                </>
              </div>
            )}
          {index == 3 &&
            (prepareRequestForDate(data, null, null) > 0 ||
              data?.stepDetail?.state == "REACHED") &&
            prepareRequestForDate(data, "fetchRedeemDistance", redeemEndDate) >
              0 && (
              <>
                {data?.stepDetail?.state == "REACHED" ? (
                  <div
                    style={{ borderRadius: "8px" }}
                    className={`mt-2 museo-sans-500 w-100 s14 px-3 py-2 ${blueState} `}
                  >
                    <FormattedMessage
                      id={`first.steps.component.prise.message.p.one${
                        outsider ? ".outsider" : ""
                      }`}
                    />
                    {prepareRequestForDate(
                      data,
                      "fetchRedeemDate",
                      redeemEndDate
                    )}
                    <FormattedMessage id="first.steps.component.prise.message.p.subOne.gift" />
                  </div>
                ) : (
                  <>
                    {data?.stepDetail?.currentEDB >=
                      data?.stepDetail?.requiredEDBs && (
                      <>
                        <div
                          style={{ borderRadius: "8px" }}
                          className={`mt-2 museo-sans-500 w-100 s14 px-3 py-2 ${warningState} `}
                        >
                          <FormattedMessage id="first.steps.component.help.message.p.edb.one" />
                          {formatAmount(
                            extraData?.stepDetail?.stepAmount -
                              extraData?.stepDetail?.currentAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                          <FormattedMessage id="first.steps.component.help.message.p.edb.two" />
                          {data?.stepDetail?.stepDependency}
                        </div>
                      </>
                    )}
                    {extraData?.stepDetail?.state == "REACHED" && (
                      <>
                        <div
                          style={{ borderRadius: "8px" }}
                          className={`mt-2 museo-sans-500 w-100 s14 px-3 py-2 ${warningState} `}
                        >
                          <FormattedMessage
                            id={`first.steps.component.help.message.p.edb.unlocked.one${
                              outsider ? ".outsider" : ""
                            }`}
                          />
                        </div>
                      </>
                    )}
                    {!(
                      data?.stepDetail?.currentEDB >=
                      data?.stepDetail?.requiredEDBs
                    ) &&
                      !(extraData?.stepDetail?.state == "REACHED") && (
                        <>
                          <div
                            style={{ borderRadius: "8px" }}
                            className={`mt-2 museo-sans-500 w-100 s14 px-3 py-2 ${blueState} `}
                          >
                            <FormattedMessage
                              id={`first.steps.component.help.message.p.edb.unlocked.two${
                                outsider ? ".outsider" : ""
                              }`}
                            />{" "}
                            {data?.stepDetail?.stepDependency}
                            <FormattedMessage id="first.steps.component.help.message.p.edb.unlocked.three" />
                          </div>
                        </>
                      )}
                  </>
                )}
              </>
            )}
        </>
      )}
    </>
  );
}
