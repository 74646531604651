import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGPendingFilled } from "../../SVGs/Pending/ic-item-pending-sm-filled-yellow.svg";
import { ReactComponent as SVGCompletedFilled } from "../../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as SVGCIncompletedFilled } from "../../../../common/svgs/ic-x-circle-bg.svg";
import { ReactComponent as SVGCBloquedFilled } from "../../SVGs/Bloqueado/ic-item-desbloq-sm-filled-gray.svg";
import ProgressBarParams from "../../../../common/Share/ProgressBarParams";

export default function FirstStepsModalBeta() {
  const scrollbarRef = useRef(null);

  return (
      <div
        className="modal fade"
        id="modalFirstStepsBeta"
        aria-hidden="true"
        aria-labelledby="modalFirstStepsBeta"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "610px" }}
        >
          <div className="modal-content modal-radius">
            <div className=" modal-body p-0">
              <div>
                <div className="d-flex align-items-center justify-content-between my-3 py-4 px-4 mx-3">
                  <div className="d-flex">
                    <h5 className="museo-sans-500 s20 m-0">
                      <FormattedMessage id="first.steps.beta.modal.title" />
                    </h5>
                  </div>
                  <div
                    className=" pointer "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <SVGClose />
                  </div>
                </div>
                <hr className="m-0"></hr>
                <div
                  ref={scrollbarRef}
                  style={{ maxHeight: "372px" }}
                  className=" s16 hidden-y-scroll d-flex flex-column align-items-center"
                >
                  <div className=" d-flex flex-column museo-sans-500 s16 mx-3 px-4 gap-3 mt-4 " >
                    <p>
                    <FormattedMessage id="first.steps.beta.modal.p.one" />
                    </p>
                  <p>
                  <FormattedMessage id="first.steps.beta.modal.p.two" /><a target="_blank" href="mailto:oficinavirtual@swissjust.com" className=" text-decoration-underline skyColor museo-sans-700" >oficinavirtual@swissjust.com</a>
                  <FormattedMessage id="first.steps.beta.modal.p.three" />

                  </p>
                  </div>
                </div>
                
                <div className=" mt-4 w-100 d-flex justify-content-center">
                  <button
                    type="button"
                    className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <FormattedMessage
                      id="buttons.understood"
                      defaultMessage="Entendido"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export function RowFirstImageTemplate(params) {

  return <>
  <div className=" shadow" >
    <p>
      <FormattedMessage id="first.steps.title.two" />
    </p>
    <div>
      <HelpIconNoTarget />
    </div>
    <p>
      <FormattedMessage id="first.steps.view.more.consultants" />
    </p>
  </div>
  </>
  
}

export function RowContainerTemplate({ status }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const switchContent = (status) => {
    switch (status) {
      case "completed":
        return "Alcanzado en semana 2";
        
      case "warning":
        return "Pendiente";
        
      case "gap":
        return "Bloqueado";
        
      case "risk":
        return "No Alcanzado";
    }
  };

  const switchTitle = (status) => {
    switch (status) {
      case "completed":
        return "Alcanzado";
      case "warning":
        return "Pendiente";
      case "gap":
        return "Bloqueado";
      case "risk":
        return "No Alcanzado";
    }
  };

  const switchSVGs = (status) => {
    switch (status) {
        case "completed":
          return <SVGCompletedFilled />
          
        case "warning":
          return <SVGPendingFilled />
          
        case "gap":
          return < SVGCBloquedFilled />
          
        case "risk":
          return < SVGCIncompletedFilled />;
      }
  }

  return (
    <>
      <div
        style={{ height: "120px" }}
        className=" mb-2 shadow w-100 s12 p-3 px-4 rounded-4"
      >
        <p className=" museo-sans-700 s16">{switchTitle(status)}</p>
        <hr />
        <div className=" s12 d-flex justify-content-between">
          <div className=" d-flex gap-1 museo-sans-500 ">
            <div
              style={{ height: "14px", width: "14px", top: -2 }}
              className=" position-relative svg-force-size"
            >
              {
                switchSVGs(status)
              }
            </div>
            <p>{switchContent(status)}</p>
          </div>
          <div className="d-flex justify-content-evenly dm-sans-medium gap-1">
            <div>
              {formatAmount(
                4000,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}{" "}
            </div>
            <div className=" grayColor">
              /{" "}
              {formatAmount(
                5500,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </div>
          </div>
        </div>
        <div className=" mt-1">
          <ProgressBarParams
            completed={status == "completed" ? 100 : 60}
            incompleted={status != "completed" }
            gap={status == "gap"}
            status={status}
            height={10}
          />
        </div>
      </div>
    </>
  );
}
