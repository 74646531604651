export default function replaceElementsURLImage (url, id, productName) {
    let aux = url;
    if (!aux) {
      return "";
    }
    aux = aux.replace("?key=card", "");
    aux = aux.replace("{image.id}", id);
    aux = aux.replace("{image.filename_download}", productName);
    
    return aux;
  };