export default function statusColor(status, styleByValue) {
  if (styleByValue) {
    switch (status) {
      case "completed":
        return "#16BE7D";
      case "warning":
        return "#DCB632";
      case "risk":
        return "#F06E69";
      default:
        return "#171A22";
    }
  } else {
    switch (status) {
      case "normal":
        return "blackColor";
      case "warning":
        return "darkYellowColor";
      case "completed":
        return "lightGreenColor";
      case "risk":
        return "lightRedColor";
      default:
        return "#171A22";
    }
  }
}
