import { FormattedMessage } from "react-intl";
import ReformatLevelByGender from "../../../../Share/ReformatLevelByGender";
import ShareErrorHandlerServices from "../../../../Share/ShareErrorHandlerServices";
import HeaderTracker from "../HeaderTracker";

import { ReactComponent as Red } from "../../../../svgs/ic-alert-circle-red.svg";

import { ReactComponent as Gray } from "../../../../svgs/ic-alert-circle-grey.svg";

import { ReactComponent as Yellow } from "../../../../svgs/ic-alert-circle-yellow.svg";

import { ReactComponent as Green } from "../../../../svgs/ic-check-circle.svg";

import { ReactComponent as Purple } from "../../../../svgs/ic-req-red.svg";

import { ReactComponent as SVGChevLeft } from "../../../../svgs/ic-chevron-left-md.svg";

function HeaderColumnOne({
  profileData,
  status,
  periodGap,
  isDashboard,
  fetchProfile,
  profileError,
  onReturnClick,
}) {

  const hasStatus = () => {
    if (status.checkMarkStatus !== "normal" || periodGap) {
      return true; 
    } else {
      return false;
    }
  };

  const objectListSVG = {
    reduced: <Purple />,
    risk: <Red />,
    completed: <Green />,
    warning: <Yellow />,
  };
  

  return (
    <>
      {profileError ? (
        <ShareErrorHandlerServices service={fetchProfile} small={true} />
      ) : (
        <>
          {!isDashboard ? (
            
            <div
                  data-bs-toggle={`${hasStatus ? "modal" : ""}`}
                  data-bs-target={`${hasStatus ? "#modalStatus" : ""}`}
                  id="header--title"
                  className={` d-flex gap-2 container--padding-left align-items-center ${hasStatus ? "pointer" : ""}`}
                >
                  <h2 className=" museo-sans-700 s16  m-0 ">
                    <ReformatLevelByGender
                      gender={profileData?.gender}
                      level={profileData?.level}
                      roleDescriptionDefault={profileData?.roleDescription}
                    />
                  </h2>
                  {periodGap ? (
                    
                      <div
                        style={{
                          borderRadius: "50%",
                          marginBottom: "12px",
                          width: "18px",
                          height: "18px",
                        }}
                      >
                        <Gray />
                      </div>
                    
                  ) : (
                    <>
                      {(status.checkMarkStatus !== "normal" ||
                        !status.checkMarkStatus) && (
                        <div
                          style={{
                            borderRadius: "50%",
                            marginBottom: "6px",
                            width: "18px",
                            height: "18px",
                          }}
                        >
                          {
                            objectListSVG[status.checkMarkStatus]
                          }
                        </div>
                      )}
                    </>
                  )}
                </div>
              
            
          ) : (
            <div id="header--title" className="container--padding-left">
              <div
                onClick={onReturnClick}
                className=" pointer d-flex museo-sans-500 "
              >
                <SVGChevLeft />
                <p className=" px-1 pe-0 skyColor museo-sans-700 s16 ">
                  <FormattedMessage id="buttons.back" />
                </p>
                <span
                  style={{
                    width: "0px",
                    height: "24px",
                    border: "1px solid #DDD",
                  }}
                  className=" mx-3"
                ></span>
              </div>
              <HeaderTracker />
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HeaderColumnOne;
