import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../../common/Share/FormatCurrency";

import PropTypes from 'prop-types';

export default function DashboardKeyModal({scoreData}) {

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );


  return ( 
    <div
      className="modal fade"
      id="modalKey"
      aria-hidden="true"
      aria-labelledby="modalKey"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "600px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex align-items-center justify-content-between py-4 my-3 p-5">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0">
                  <FormattedMessage id="dashboard.modal.key.title" />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className="d-flex flex-column align-items-center">
                <div className="mt-4 px-5">
                  
                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage id="dashboard.modal.key.p.one" />
                  {formatAmount(
                            scoreData?.triperiodicSales?.totalAmount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                  <FormattedMessage id="dashboard.modal.key.p.two" />
                  </p>
                </div>
                <button
                  type="button"
                  className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  
}

DashboardKeyModal.propTypes = {
  scoreData: PropTypes.object.isRequired,
};