import { multiLangJSONParse } from "./MultiLangJSONParse";

export default function ShareGetDate(string) {
  if (!string) {
    return;
  }

  const offset = 0;

  const [datePart, timePart] = string.split("T");
  const [year, month, day] = datePart.split("-");
  const [time, timezone] = timePart.split("-");

  let [hours, minutes, seconds] = time.split(":");
  hours = parseInt(hours) + offset;

  if (hours < 0) {
    hours += 24;
    day = parseInt(day) - 1;
  } else if (hours >= 24) {
    hours -= 24;
    day = parseInt(day) + 1;
  }

  const momentTime = `${hours.toString().padStart(2, "0")}:${minutes}`;
  const momentDay = day.toString().padStart(2, "0");
  const momentMonth = month.toString().padStart(2, "0");

  const ShareNameMonthLong = (month) => {
    const monthNames = [
      multiLangJSONParse()["month.1"],
      multiLangJSONParse()["month.2"],
      multiLangJSONParse()["month.3"],
      multiLangJSONParse()["month.4"],
      multiLangJSONParse()["month.5"],
      multiLangJSONParse()["month.6"],
      multiLangJSONParse()["month.7"],
      multiLangJSONParse()["month.8"],
      multiLangJSONParse()["month.9"],
      multiLangJSONParse()["month.10"],
      multiLangJSONParse()["month.11"],
      multiLangJSONParse()["month.12"]
    ];
    return monthNames[parseInt(month) - 1];
  };

  const resolve = `${momentDay} ${ShareNameMonthLong(
    momentMonth
  )} - ${momentTime}hs`;
  return resolve;
}
