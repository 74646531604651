import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

import Typ17 from "../../imgs/Just-popup_0002_Requisitos-reducidos.png";
import Typ6n7 from "../../imgs/Just-popup_0004_Baja-de-nivel-efectiva.png";
import Typ20 from "../../imgs/Just-popup_0006_Falta-de-venta.png";
import Typ19 from "../../imgs/Just-popup_0001_Precalificacion.png";
import Typ5 from "../../imgs/Just-popup_0005_Periodo-de-gracia.png";
import Typ48 from "../../imgs/Just-popup_0000_Ganancia-periodos-anteriores.png";
import Typ4 from "../../imgs/Just-popup_0003_Suba-de-nivel-precalificado.png";
import Typ3Lider from "../../imgs/Just-cambio-de-nivel_0001_Lider-de-Grupo.png";
import Typ3Directora from "../../imgs/Just-cambio-de-nivel_0002_Directora.png";
import Typ29 from "../../imgs/Just-popup_0007_EDB-Confirmado.png";

import AlertModalContent from "./AlertModalContent";
import "./Alert.css";
import { patchNotfication } from "../../../common/Header/Framework/HeaderSideEffect";
import AlertModalSwitchButtonHolder from "./AlertModalSwitchButtonHolder";

export default function AlertModal() {
  const notificationDetail = useSelector(
    (state) => state.headerData.notificationDetail
  );
  const slidesData = useSelector((state) => state.headerData.notificationExtra);

  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    onAccept();
    notificationDetail && patchNotification();
  }, [notificationDetail]);

  const [slides, setSlides] = useState(1);

  const onChangeSlide = (value) => {
    setSlides(value);
  };

  const getImage = (type) => {
    const imageStyles = {
      29: { width: "143px", height: "184px", src: Typ29 },
      17: { width: "143px", height: "184px", src: Typ17 },
      48: { width: "143px", height: "184px", src: Typ48 },
      6: { width: "184px", height: "167px", src: Typ6n7 },
      3: {
        src: parseInt(slidesData?.newLevelId) > 5 ? Typ3Directora : Typ3Lider,
        width: "210px",
        height: "218px",
      },
      4: { width: "270px", height: "190px", src: Typ4 },
      7: { width: "184px", height: "167px", src: Typ6n7 },
      20: { width: "184px", height: "141px", src: Typ20 },
      19: { width: "184px", height: "184px", src: Typ19 },
      5: { width: "184px", height: "175px", src: Typ5 },
    };

    const { width, height, src } = imageStyles[type];

    return <img style={{ width, height }} src={src}></img>;
  };

  const patchNotification = async () => {
    await dispatch(
      patchNotfication({
        notificationId: notificationDetail.key,
        newStatus: "read",
      })
    );
  };

  const onAccept = (moveTo) => {
    setSlides(1);
    if (moveTo) {
      history(moveTo)
    }
  };

  return (
    notificationDetail && (
      <>
        <div className="d-flex align-items-center justify-content-between p-0">
          <div className="d-flex"></div>
          <div
            className=" p-3 pointer "
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <SVGClose />
          </div>
        </div>
        <div style={{flexGrow: 1}} className="h-100 d-flex flex-column justify-content-between align-items-center ">
          <AlertModalContent
            details={notificationDetail}
            data={slidesData}
            switchImage={getImage}
            slides={slides}
          />

          <AlertModalSwitchButtonHolder
            onAccept={onAccept}
            type={notificationDetail.type}
            setSlides={onChangeSlide}
            slides={slides}
            slidesData={slidesData}
          />
        </div>
      </>
    )
  );
}
