import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { calendarCurrentPeriod } from "../../../Calendar/Framework/redux/CalendarSideEffects";
import "../pages/StatusAccount.css"

export default function StatusAccount(params) {

    const calendarDataNeeded = useSelector((state) => state.calendarData.currentPeriodInfo);
    
    const dispatch = useDispatch();

    const onStartUp = async () => {
        if (!calendarDataNeeded?.periodStartDate) {
            dispatch(calendarCurrentPeriod());
        }
    };


    useEffect(() => {
        onStartUp()
      }, [])

    return <>
    {
        <Outlet />
    }
    </>
}