import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";

import { ReactComponent as SVGCross } from "../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";
import { ReactComponent as SVGTriangle } from "../../../common/svgs/ic-triangle-warning-lg.svg";
import { getGroup } from "../../Framework/redux/dashboardSideEffect";
import { changeGroupTarget } from "../../Framework/redux/dashboardAction";

import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import StyledMultiLang from "../../../common/Share/StyledMultiLang";
import DashboardTriperiodSale from "./DashboardTriperiodSale";
import DashboardQualificationGroups from "./DashboardQualificationGroups";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";

export default function DashboardQualification({
  reduced,
  level,
  status,
  gap,
  descendant,
  scoreData,
  groupsData,
  desc,
  potentialLeader,
  scoreDataPotentialLeader,
  root,
  succesLeaderMessage,
  profileDataLoader,
  setCloseComplete,
  closeComplete
}) {

  const loading = useSelector((state) => state.scoreData.loader);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [toggler, setToggler] = useState(false);
  const [tab, setTab] = useState(false);

  const potentialLeaderCards = useSelector(
    (state) => state.performanceData.potentialLeaders
  );
  const errorScoreData = useSelector(
    (state) => state.scoreData?.scoreDataError
  );

  const startUp = async () => {
    await dispatch(getGroup());
  };

  const onClickDetails = (id, type) => {
    let found = null;

    if (type == "potential") {
      found = potentialLeaderCards?.groups.find((x) => x.clientId === id);
    } else {
      if (desc) {
        found = groupsData.groups.find((x) => x.clientId === id);
      } else {
        found = groupsData.array.find((x) => x.clientId === id);
      }
    }
    dispatch(changeGroupTarget(found));
  };

  const addProgressBar = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={gap}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={gap}
          />
        );
      } else {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={gap}
          />
        );
      }
    }

    return aux;
  };

  const addProgressBarTemplate = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarParams
          height={6}
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={gap}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarParams
          height={6}
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={gap}
          />
        );
      } else {
        aux.push(
          <ProgressBarParams
          height={6}
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={gap}
          />
        );
      }
    }

    return aux;
  };

  const onHistory = (route) => {
    history(route);
  };

  const onTabChange = (boolean) => {
    setToggler(boolean);
    setTab(boolean);
  };

  useEffect(() => {
    !root && startUp();
  }, []);

  const borderCompletedStyle = {
    borderRadius: "0px 16px 16px 16px",
    zIndex: 1,
  };

  const borderNotCompletedStyle = { borderRadius: "16px", zIndex: 1 };

  return !loading ? (
    <>
    <div className="  position-relative" >
        {level < 2 && succesLeaderMessage && (
            <div
              style={{ zIndex: 3, top: 25, background: "#E7F5F0", marginBottom: 20 }}
              className=" position-absolute p-3 d-flex gap-3 w-100 mt-3 rounded-2 justify-content-between "
            >
              <p className=" s14 museo-sans-500  greenColor">
                <StyledMultiLang
                  loading={profileDataLoader}
                  id="dashboard.consultant.post"
                  def={
                    "¡Sigue así! ¡Estás Precalificando a Líder de Grupo! Si al finalizar el período vuelves a alcanzar las calificaciones de Líder de Grupo, obtendrás el título y comisionarás como Líder de Grupo. ¡Muchos éxitos!"
                  }
                />
              </p>
              <div
                onClick={() => setCloseComplete(false)}
                style={{ width: "16px" }}
                className=" greenColor pointer svg-force-color svg-force-size "
              >
                <SVGCross />
              </div>
            </div>
          )}
        </div>
      {toggler ? (
          <div className=" position-relative">
            <div
              className=" position-absolute "
              style={{ width: "200px", height: "30px", top: 50 }}
            >
              <div
                onClick={() => onTabChange(false)}
                style={{
                  width: "184px",
                  height: "38px",
                  top: !closeComplete ? -10  : 70,
                  borderRadius: "16px 16px 0px 0px ",
                  zIndex: 0,
                }}
                className={`${
                  tab ? "lightGreyBg grayColor " : "bg-white"
                } tabs-shadow pointer d-flex justify-content-center align-items-center py-2 position-absolute tabs-top`}
              >
                <p className=" museo-sans-500 s14 ">
                  <FormattedMessage
                    id="level.two.qualified"
                    defaultMessage={"Líder de Grupo"}
                  />
                </p>
              </div>
              <div
                onClick={() => onTabChange(true)}
                style={{
                  width: "184px",
                  height: "38px",
                  top: !closeComplete ? -10 : 70,
                  borderRadius: "16px 16px 0px 0px ",
                  left: 184,
                  zIndex: 0,
                }}
                className={` ${
                  tab ? "bg-white" : "lightGreyBg grayColor "
                } tabs-shadow pointer d-flex justify-content-center align-items-center py-2  position-absolute tabs-top`}
              >
                <p className=" museo-sans-500 s14 ">
                  <FormattedMessage
                    id="level.one.not.inclusive"
                    defaultMessage={"Consultora"}
                  />
                </p>
              </div>
            </div>
            <DashboardTriperiodSale
              status={status}
              level={level}
              gap={gap}
              scoreData={scoreData}
              potentialLeader={potentialLeader}
              succesLeaderMessage={succesLeaderMessage}
              profileDataLoader={profileDataLoader}
              setCloseComplete={setCloseComplete}
              closeComplete={closeComplete}
            />
          </div>
      ) : (
        <div>
          <div className=" museo-sans-500 d-flex gap-2 align-items-center mb-4 ">
            <h3 className=" m-0 s20">
              {descendant ? (
                "Calificaciones"
              ) : (
                <>
                  {level == 1 ? (
                      <StyledMultiLang
                        loading={loading}
                        id="dashboard.qualifications.title.one.b"
                        def={"Tus Calificaciones"}
                      />
                  ) : (
                      <StyledMultiLang
                        loading={loading}
                        id="dashboard.qualifications.title.one"
                        def={"Tus Calificaciones"}
                      />
                  )}
                </>
              )}
            </h3>
            {!descendant && (
              <>
                {level == 1 ? (
                    <i
                      data-bs-toggle="modal"
                      data-bs-target="#modalQualificationSecond"
                      className="pointer"
                    >
                      <HelpIconNoTarget />
                    </i>
                ) : (
                  
                    <i
                      data-bs-toggle="modal"
                      data-bs-target="#modalQualificationn"
                      className="pointer"
                    >
                      <HelpIconNoTarget />
                    </i>
                )}
              </>
            )}
            {!descendant && (
              <>
                {errorScoreData ? (
                    <p className=" px-3 s14 grayColor museo-sans-700">
                      
                        <StyledMultiLang
                          loading={loading}
                          id="dashboard.qualifications.show.more"
                          def={"Ver todas tus calificaciones"}
                        />
                    </p>
                ) : (
                    <Link
                      className="text-decoration-none"
                      to="/dashboard/performance/personalSales"
                    >
                      <p className=" px-3 s14 skyColor museo-sans-700">
                          <StyledMultiLang
                            loading={loading}
                            id="dashboard.qualifications.show.more"
                            def={"Ver todas tus calificaciones"}
                          />
                      </p>
                    </Link>
                )}
              </>
            )}
          </div>
          {!descendant && (
            <>
              {reduced && (
                <div
                  style={{ background: "#FCEFFD" }}
                  className=" p-3 rounded-2 align-items-center tabs-shadow mb-4 d-flex gap-3 w-100"
                >
                  <div style={{ width: "32px" }}>
                    <Purple />
                  </div>
                  <p
                    style={{ color: "#D18DEF" }}
                    className="s14 museo-sans-500"
                  >
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.notice.label"
                      def={"Ver todas tus calificaciones"}
                    />
                    "
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.notice.label.two"
                      def={"Ver todas tus calificaciones"}
                    />
                    "{" "}
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.notice.label.three"
                      def={"Ver todas tus calificaciones"}
                    />
                  </p>
                </div>
              )}
            </>
          )}
          {level < 2 && succesLeaderMessage && (
            <div
              style={{ background: "#E7F5F0", marginBottom: 20 }}
              className=" invisible p-3 d-flex gap-3 w-100 mt-3"
            >
              <p className=" s14 museo-sans-500 greenColor">
                <StyledMultiLang
                  loading={profileDataLoader}
                  id="dashboard.consultant.post"
                  def={
                    "¡Sigue así! ¡Estás Precalificando a Líder de Grupo! Si al finalizar el período vuelves a alcanzar las calificaciones de Líder de Grupo, obtendrás el título y comisionarás como Líder de Grupo. ¡Muchos éxitos!"
                  }
                />
              </p>
              <div
                onClick={() => setCloseComplete(false)}
                style={{ width: "16px" }}
                className=" greenColor pointer svg-force-color svg-force-size "
              >
                <SVGCross />
              </div>
            </div>
          )}
          {level < 2 && (
            <div
              className=" position-relative "
              style={{ width: "200px", height: "20px" }}
            >
              <div
                onClick={() => onTabChange(false)}
                style={{
                  width: "184px",
                  height: "38px",
                  top: -9,
                  borderRadius: "16px 16px 0px 0px ",
                  zIndex: 0,
                }}
                className={`${
                  toggler ? "lightGreyBg grayColor " : "bg-white"
                } tabs-shadow pointer d-flex justify-content-center align-items-center py-2   position-absolute`}
              >
                <p className=" museo-sans-500 s14 ">
                  <FormattedMessage
                    id="level.two.qualified"
                    defaultMessage={"Líder de Grupo"}
                  />
                </p>
              </div>
              <div
                onClick={() => onTabChange(true)}
                style={{
                  width: "184px",
                  height: "38px",
                  top: -9,
                  borderRadius: "16px 16px 0px 0px ",
                  left: 184,
                  zIndex: 0,
                }}
                className={` ${
                  toggler ? "bg-white" : "lightGreyBg grayColor "
                } tabs-shadow pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
              >
                <p className=" museo-sans-500 s14 ">
                  <FormattedMessage
                    id="level.one.not.inclusive"
                    defaultMessage={"Consultora"}
                  />
                </p>
              </div>
            </div>
          )}
          <div
            style={
              level < 2 && potentialLeader
                ? borderCompletedStyle
                : borderNotCompletedStyle
            }
            className=" position-relative w-100 bg-white tabs-shadow py-0"
          >
            {!descendant && (
              <div className=" py-4 my-2 px-4 mx-2">
                <>
                  {gap ? (
                    <div
                      style={{ background: "#FEFBEC" }}
                      className=" align-items-center gap-3 p-3 d-flex museo-sans-500 s14 rounded-2 p-2 goldBoldColor w-100"
                    >
                      <SVGTriangle />
                      <p style={{ width: "650px", color: "#DCB632" }}>
                        <StyledMultiLang
                          loading={loading}
                          id="dashboard.qualifications.gap.label"
                        />
                      </p>
                    </div>
                  ) : (
                    <div
                      style={{ background: "#ECF0FA" }}
                      className=" museo-sans-500 s14 text-center rounded-2 p-2 blueColor w-100"
                    >
                      <StyledMultiLang
                        loading={loading}
                        id="dashboard.qualifications.nogap.label"
                      />
                    </div>
                  )}
                </>
              </div>
            )}
            {descendant && (
              <div className=" py-4 my-2 px-4 mx-2">
                <div
                  style={{ background: "#ECF0FA" }}
                  className=" museo-sans-500 s14 text-center rounded-2 p-2 blueColor w-100"
                >
                  <p style={{ height: "17px" }}>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.nogap.label"
                    />
                  </p>
                </div>
              </div>
            )}
            <DashboardQualificationGroups
              scoreDataPotentialLeader={scoreDataPotentialLeader}
              potentialLeaderCards={potentialLeaderCards}
              descendant={descendant}
              level={level}
              loading={loading}
              addProgressBar={addProgressBar}
              addProgressBarTemplate={addProgressBarTemplate}
              scoreData={scoreData}
              status={status}
              gap={gap}
              onHistory={onHistory}
              reduced={reduced}
              groupsData={groupsData}
              onClickDetails={onClickDetails}
              root={root}
            />
          </div>
        </div>
      )}
    </>
  ) : (
        <SkeletonDashboardQualification
          descendant={descendant}
          level={level}
          status={status}
          reduced={reduced}
          toggler={toggler}
          borderCompletedStyle={borderCompletedStyle}
          borderNotCompletedStyle={borderNotCompletedStyle}
          loading={loading}
          potentialLeader={potentialLeader}
          succesLeaderMessage={succesLeaderMessage}
          profileDataLoader={profileDataLoader}
          setCloseComplete={setCloseComplete}
        />
  );
}

export function SkeletonDashboardQualification({
  descendant,
  level,
  reduced,
  toggler,
  borderCompletedStyle,
  borderNotCompletedStyle,
  loading,
  potentialLeader,
}) {
  return (
    <>
      <div>
        <div className=" museo-sans-500 d-flex gap-2 align-items-center mb-4 ">
          <h3 className=" m-0 s20">
            {descendant ? (
              "Calificaciones"
            ) : (
              <>
                {level == 1 ? (
                  <>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.title.one.b"
                      def={"Tus Calificaciones"}
                    />
                  </>
                ) : (
                  <StyledMultiLang
                    loading={loading}
                    id="dashboard.qualifications.title.one"
                    def={"Tus Calificaciones"}
                  />
                )}
              </>
            )}
          </h3>
          {!descendant && (
            <>
              {level == 1 ? (
                <>
                  <i
                    data-bs-toggle="modal"
                    data-bs-target="#modalQualificationSecond"
                    className="pointer"
                  >
                    <HelpIconNoTarget />
                  </i>
                </>
              ) : (
                <>
                  <i
                    data-bs-toggle="modal"
                    data-bs-target="#modalQualificationn"
                    className="pointer"
                  >
                    <HelpIconNoTarget />
                  </i>
                </>
              )}
            </>
          )}
          {!descendant && (
            <>
              <Link
                className="text-decoration-none"
                to="/dashboard/performance/personalSales"
              >
                <p className=" px-3 s14 skyColor museo-sans-700">
                  <>
                    <StyledMultiLang
                      loading={loading}
                      id="dashboard.qualifications.show.more"
                      def={"Ver todas tus calificaciones"}
                    />
                  </>
                </p>
              </Link>
              <br />
            </>
          )}
        </div>
        {!descendant && (
          <>
            {reduced && (
              <div
                style={{ background: "#FCEFFD" }}
                className=" p-3 rounded-2 align-items-center tabs-shadow mb-4 d-flex gap-3 w-100"
              >
                <div style={{ width: "32px" }}>
                  <Purple />
                </div>
                <p style={{ color: "#D18DEF" }} className="s14 museo-sans-500">
                  <StyledMultiLang
                    loading={loading}
                    id="dashboard.qualifications.notice.label"
                    def={"Ver todas tus calificaciones"}
                  />
                  "
                  <StyledMultiLang
                    loading={loading}
                    id="dashboard.qualifications.notice.label.two"
                    def={"Ver todas tus calificaciones"}
                  />
                  "{" "}
                  <StyledMultiLang
                    loading={loading}
                    id="dashboard.qualifications.notice.label.three"
                    def={"Ver todas tus calificaciones"}
                  />
                </p>
              </div>
            )}
          </>
        )}
        {level < 2 && (
          <div
            className=" position-relative "
            style={{ width: "200px", height: "30px" }}
          >
            <div
              style={{
                width: "184px",
                height: "38px",
                top: -9,
                borderRadius: "16px 16px 0px 0px ",
                zIndex: 0,
              }}
              className={`${
                toggler ? "lightWhiteBg" : "bg-white"
              } tabs-shadow pointer d-flex justify-content-center align-items-center py-2   position-absolute`}
            >
              <p className=" museo-sans-500 s14 ">
                <FormattedMessage
                  id="level.two.qualified"
                  defaultMessage={"Líder de Grupo"}
                />
              </p>
            </div>
            <div
              style={{
                width: "184px",
                height: "38px",
                top: -9,
                borderRadius: "16px 16px 0px 0px ",
                left: 184,
                zIndex: 0,
              }}
              className={` ${
                toggler ? "bg-white" : "lightWhiteBg"
              } tabs-shadow pointer d-flex justify-content-center align-items-center py-2  position-absolute`}
            >
              <p className=" museo-sans-500 s14 ">
                <FormattedMessage
                  id="level.one.not.inclusive"
                  defaultMessage={"Consultora"}
                />
              </p>
            </div>
          </div>
        )}
        <div
          style={
            level < 2 && potentialLeader
              ? borderCompletedStyle
              : borderNotCompletedStyle
          }
          className=" position-relative w-100 bg-white f9GrayBorder tabs-shadow p-4"
        >
          <div
            style={{ height: "32px" }}
            className="lightui1-shimmer w-100 d-flex justify-content-center lightGreyBg rounded-3 align-items-center   "
          >
            <div
              style={{ height: "12px", width: "440px" }}
              className=" rounded-3 brightGrayBg   "
            ></div>
          </div>
          <div style={{ gap: "32px" }} className=" mt-4 pt-2 d-flex ">
            <div
              style={{ height: "341px" }}
              className=" d-flex justify-content-between flex-column w-100 "
            >
              <div
                style={{ height: "16px", width: "129px" }}
                className="lightui1-shimmer brightGrayBg rounded-3 "
              ></div>
              <div className="d-flex gap-4 flex-column">
                <SkeletonDashboardQualificationLeftRow />
                <SkeletonDashboardQualificationLeftRow />
                <SkeletonDashboardQualificationLeftRow />
                <SkeletonDashboardQualificationLeftRow />
              </div>
              <div
                style={{ width: "130px", height: "8px" }}
                className="lightui1-shimmer brightGrayBg rounded-3"
              ></div>
            </div>
            <div style={{ width: "1px" }} className="h-100 brightGrayBg  ">
              <p className=" invisible">.</p>
            </div>
            <div className=" d-flex flex-column justify-content-between w-100 ">
              <div>
                <div className="  d-flex justify-content-between ">
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer w-75 brightGrayBg rounded-3 "
                  ></div>
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer invisible w-25 brightGrayBg rounded-3 "
                  ></div>
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer invisible w-25 brightGrayBg rounded-3 "
                  ></div>
                  <div
                    style={{ height: "16px" }}
                    className="lightui1-shimmer w-25 brightGrayBg rounded-3 "
                  ></div>
                </div>
                <div
                  style={{ gap: "43px" }}
                  className="d-flex flex-wrap mt-4 pt-2 "
                >
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                  <SkeletonDashboardQualificationRight />
                </div>
              </div>
              <div
                style={{ width: "130px", height: "8px" }}
                className="lightui1-shimmer brightGrayBg rounded-3"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function SkeletonDashboardQualificationLeftRow(params) {
  return (
    <>
      <div className=" gap-3 d-flex flex-column">
        <div className=" d-flex  justify-content-between">
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer rounded-3 w-100 brightGrayBg "
          ></div>
          <div style={{ height: "12px" }} className=" rounded-3 w-100  "></div>
          <div className=" w-100 d-flex gap-1 ">
            <div
              style={{ height: "8px" }}
              className="lightui1-shimmer rounded-3 w-100 brightGrayBg "
            ></div>
            <div
              style={{ height: "8px" }}
              className="lightui1-shimmer rounded-3 w-100 brightGrayBg "
            ></div>
          </div>
        </div>
        <div
          style={{ height: "12px" }}
          className="lightui1-shimmer mt-1 w-100 rounded-3 brightGrayBg "
        ></div>
      </div>
    </>
  );
}

export function SkeletonDashboardQualificationRight(params) {
  return (
    <>
      <div
        style={{ width: "62px" }}
        className=" d-flex flex-column align-items-center
     gap-2 position-relative"
      >
        <div
          style={{ height: "6px" }}
          className="lightui1-shimmer rounded-3 brightGrayBg w-100 "
        ></div>
        <div style={{ gap: "2px" }} className=" w-100 d-flex ">
          <div
            style={{ height: "6px" }}
            className="lightui1-shimmer rounded-3 brightGrayBg w-100 "
          ></div>
          <div
            style={{ height: "6px" }}
            className="lightui1-shimmer rounded-3 brightGrayBg w-100 "
          ></div>
          <div
            style={{ height: "6px" }}
            className="lightui1-shimmer rounded-3 brightGrayBg w-100 "
          ></div>
          <div
            style={{ height: "6px" }}
            className="lightui1-shimmer rounded-3 brightGrayBg w-100 "
          ></div>
        </div>
        <div
          style={{ height: "6px" }}
          className="lightui1-shimmer rounded-3 brightGrayBg w-100 "
        ></div>
        <div
          style={{ height: "6px", width: "21px" }}
          className="lightui1-shimmer rounded-3 brightGrayBg  "
        ></div>
        <div
          style={{ height: "6px", width: "31px" }}
          className="lightui1-shimmer rounded-3 brightGrayBg  "
        ></div>
      </div>
    </>
  );
}
