import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGCalendarRed } from "../../img/redCalendar.svg";
import { ReactComponent as SVGRedTriangle } from "../../img/triangleFillRed.svg";

export default function EDBModalDelete(params) {

    const onStringValue = (string) => {
        if (!string) {
            return "none"
        }
        let newString = string.toLowerCase();
        return newString.charAt(0).toUpperCase() + newString.slice(1);
    }
    
    return(
        <div className="museo-sans-500 text-center" >
            <div className=" d-flex gap-4 flex-column align-items-center " >
                <div className="lightRedBgOpacity10 mt-4 rounded-circle d-flex justify-content-center align-items-center " style={{width: "100px", height: "100px"}} >
                    <div className="  svg-force-size" >
                        {
                            params?.inviteeInfo ? <SVGRedTriangle/> : <SVGCalendarRed/>
                        }
                    </div>

                </div>
                <div style={{gap: "10px"}} className="d-flex flex-column" >
                    <p className=" s16 museo-sans-700" >
                        {
                            params?.inviteeInfo ? <FormattedMessage id="edb.form.modal.delete.two.title" /> : <FormattedMessage id="edb.form.modal.delete.one.title" />
                        }
                    </p>
                    <p style={{width: "332px"}} className=" s14 grayColor " > 
                    {
                            params?.inviteeInfo ? <>
                                <FormattedMessage id="edb.form.modal.delete.two.p.a" />
                                {params?.inviteeInfo?.name}
                                <FormattedMessage id="edb.form.modal.delete.two.p.b" />
                            </>  
                            : 
                            <FormattedMessage values={{name: onStringValue(params?.data?.title) }} id="edb.form.modal.delete.one.p" />
                        }
                        
                    </p>

                </div>
                <div className="d-flex flex-column w-100 " >
                <hr className="m-0" />
                    <p
                    data-bs-dismiss="modal"
                    aria-label="Close" 
                      onClick={() => params.onConfirm(params?.inviteeInfo?.id)} style={{height: "56px"}} className=" pointer d-flex align-items-center justify-content-center lightRedColor " >
                        {
                            params?.inviteeInfo ? <FormattedMessage id="buttons.delete" /> : <FormattedMessage id="buttons.delete.event" />
                        }
                        
                    </p>
                    <hr className="m-0" />
                    <p
                    data-bs-dismiss="modal"
                    aria-label="Close" 
                    style={{height: "56px"}} className=" pointer d-flex align-items-center justify-content-center skyColor" >
                        <FormattedMessage id="buttons.cancel" />
                    </p>
                </div>
            </div>
            
        </div>
    )
}