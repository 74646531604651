import { ReactComponent as SVGClose } from "../svgs/ic-x-sm-green.svg";

export default function ModalClipboard({ show, close, title, text, bottom }) {
  return (
    show && (
      <div style={{bottom: bottom ? bottom : 0, zIndex: 1000}} className="position-fixed m-3" >
        <div style={{width: "392px", height: "74px"}} className=" shadow  py-3 " id="modal--clipboard">
          <i
            style={{ justifySelf: "center" }}
            className=" greenColor bi bi-check-circle"
          ></i>
          <div>
            <p className=" museo-sans-700 s14 greenColor">
              {title ? title : "Datos copiados"}
            </p>
            <p className=" museo-sans-500 s14 greenColor">
              {text ? text : "Se han copiado los datos en el portapapeles."}
            </p>
          </div>
          <div
            onClick={close}
            style={{ justifySelf: "center " }}
            className="pointer"
          >
            <SVGClose />
          </div>
        </div>

      </div>
    )
  );
}
