export default function AlertModalSlide({slides, data}) {

  return (
    <>
    {
        data &&
        <div className=" w-100 d-flex justify-content-center gap-2 align-items-center">
      <div
        style={{ width: "8px", height: "8px" }}
        className={`rounded-circle border-2 ${
          slides === 1 ? "skyBg" : "brightGrayBg"
        } `}
      ></div>
      <div
        style={{ width: "8px", height: "8px" }}
        className={`rounded-circle border-2 ${
          slides === 2 ? "skyBg" : "brightGrayBg"
        } `}
      ></div>
      {
        data.slidesData.slide2_1 &&
        <div
          style={{ width: "8px", height: "8px" }}
          className={`rounded-circle border-2 ${
            slides === 3 ? "skyBg" : "brightGrayBg"
          } `}
        ></div>
      }
    </div>
    }
    </>
    
  );
}
