import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import DetailsHasShipment from "../../../common/Share/DetailsHasShipment";
import { formatAmount } from "../../../common/Share/FormatCurrency";

import { ReactComponent as SVGArrowUp } from "../../../common/svgs/ic-chevron-up-sm.svg";
import { ReactComponent as SVGArrowDown } from "../../../common/svgs/ic-chevron-down-sm.svg";

export default function StatusAccountDetailsBillInfo({dataSummaryByIdData}) {
    const [togglerProduct, setTogglerProduct] = useState(false);
    const [togglerTaxes, setTogglerTaxes] = useState(true);
    const [closeClipModal, setCloseClipModal] = useState(false);
    const cultureInfo = useSelector(
      (state) => state.userInfo.userInfo.cultureInfo
    );
  
    const onCloseCopyModal = (boolean) => {
      setCloseClipModal(boolean ? boolean : false);
    };
  
    const changeTogglerProduct = () => {
      setTogglerProduct(!togglerProduct);
    };
  
    const changeTogglerTaxes = () => {
      setTogglerTaxes(!togglerTaxes);
    };
    
    const formatDate = (date) => {
      if (date) {
        let dateSplit = date?.split("T");
        let splitedArray = dateSplit[0]?.split("-");
        let finalDate =
          splitedArray[2] + "-" + splitedArray[1] + "-" + splitedArray[0];
        return finalDate;
      }
    };

    return(
        <div
              className="d-flex flex-column w-100"
              style={{ paddingTop: "40px", gap: "32px" }}
            >
              <div
                style={{ padding: "32px" }}
                className="f9GrayBorder position-relative mb-4 bg-white rounded-3 museo-sans-500 cards-shadow"
              >
                <div
                  style={{
                    height: "117px",
                  }}
                  className="d-flex flex-column"
                >
                  <div>
                    <p className="dm-sans-medium s12">
                      {formatDate(
                        dataSummaryByIdData?.transactionSummary?.date
                      )}
                    </p>
                  </div>
                  <div style={{ marginTop: "8px", marginBottom: "17px" }}>
                    <p className="museo-sans-700 s24">
                      <FormattedMessage id="personal.sale.details.id.bill.no.number" /> n°{" "}
                      {dataSummaryByIdData?.transactionSummary?.invoiceNumber}
                    </p>
                  </div>
                  <div>
                    <p
                      className="museo-sans-500 s12 d-flex flex-row"
                      style={{ gap: "4px" }}
                    >
                      <FormattedMessage id="TRANSACCIÓN:" />
                      {" "}
                      <p
                        className="museo-sans-500"
                        style={{ color: "#908F9A" }}
                      >
                        {
                          dataSummaryByIdData?.transactionSummary
                            ?.transactionNumber
                        }
                      </p>{" "}
                      <p className="museo-sans-500"><FormattedMessage id="| ORDEN N°:" /> </p>
                      <p
                        className="dm-sans-medium"
                        style={{ color: "#908F9A" }}
                      >
                        {dataSummaryByIdData?.transactionSummary?.orderNumber}
                      </p>
                    </p>
                  </div>
                </div>
                <hr></hr>
                <DetailsHasShipment
                  show={closeClipModal}
                  close={onCloseCopyModal}
                  elm={dataSummaryByIdData}
                />

                <div
                  style={{
                    marginTop: !dataSummaryByIdData?.transactionSummary
                      ?.trackingInfo
                      ? "24px"
                      : "0px",
                  }}
                  className="d-flex flex-column"
                >
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex flex-row gap-1">
                      <p className="museo-sans-700 s16"><FormattedMessage id="Productos:"/> </p>
                      <p className="dm-sans-bold s16">
                        {dataSummaryByIdData?.transactionSummary.totalProducts}
                      </p>
                    </div>
                    <button
                      onClick={changeTogglerProduct}
                      className="skyColor border-0 bg-transparent museo-sans-700 s14"
                    >
                      <FormattedMessage id="Ver productos" /> {" "}
                      {!togglerProduct ? <SVGArrowDown /> : <SVGArrowUp />}{" "}
                    </button>
                  </div>
                  {togglerProduct && (
                    <>
                      {dataSummaryByIdData?.transactionSummary?.products.map(
                        (elm) => {
                          return (
                            <div
                              className="d-flex flex-row align-items-center justify-content-between pe-3"
                              style={{
                                marginTop: "17px",
                                marginBottom: "24px",

                                height: "88px",
                              }}
                            >
                              <div
                                className="d-flex flex-row align-items-center"
                                style={{ gap: "24px" }}
                              >
                                <div className=" my-2 d-flex ">
                                  <div
                                    className=" rounded-2 border grayBorder position-relative d-flex justify-content-center align-items-center"
                                    style={{ width: "80px", height: "80px" }}
                                  >
                                    <img
                                      className="p-1 bg-white"
                                      style={{
                                        width: "76px",
                                        height: "76px",
                                        objectFit: "scale-down",
                                      }}
                                      src={elm?.img}
                                    />
                                    <div
                                      style={{
                                        width: "24px",
                                        height: "24px",
                                        background: "#8889DB",
                                        top: -10,
                                        right: -10,
                                      }}
                                      className="rounded-5  position-absolute d-flex justify-content-center align-items-center"
                                    >
                                      <p
                                        className="dm-sans-medium s12"
                                        style={{ color: "white" }}
                                      >
                                        x{elm?.totalItems}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="d-flex flex-column"
                                  style={{ gap: "8px" }}
                                >
                                  <p className="museo-snans-500 s14">
                                    {elm?.title}
                                  </p>
                                  <p
                                    className="dm-sans-medium s12"
                                    style={{ color: "#908F9A" }}
                                  >
                                    {elm?.id}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="d-flex flex-column align-items-end pe-3"
                                style={{ gap: "8px" }}
                              >
                                <p className="dm-sans-medium s14">
                                  {formatAmount(
                                    elm?.totalPrice,
                                    cultureInfo?.languageCode,
                                    cultureInfo?.defaultCurrencyCode
                                  )}
                                </p>
                                <div className="d-flex flex-row">
                                  <p
                                    className="museo-sans-500 s12"
                                    style={{ color: "#908F9A" }}
                                  >
                                    <FormattedMessage id="PU:" />
                                  </p>
                                  <p className="dm-sans-medium s12">
                                    {formatAmount(
                                      elm?.price,
                                      cultureInfo?.languageCode,
                                      cultureInfo?.defaultCurrencyCode
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                     
                    </>
                  )}
                   {dataSummaryByIdData?.transactionSummary.totalAmountDetail.map(
                        (elm) => {
                          return (
                            <>
                              {elm.description !=
                              "Subtotal Productos" ? null : (
                                <div style={{paddingTop: "16px"}} className="d-flex flex-row align-items-center justify-content-between pe-4">
                                  <p
                                    className="museo-sans-500 s14"
                                    style={{ color: "#908F9A" }}
                                  >
                                    {elm?.description}
                                  </p>
                                  <p
                                    className="dm-sans-medium s14"
                                    style={{ color: "#908F9A" }}
                                  >
                                    {formatAmount(
                                      elm?.amount,
                                      cultureInfo?.languageCode,
                                      cultureInfo?.defaultCurrencyCode
                                    )}
                                  </p>
                                </div>
                              )}
                            </>
                          );
                        }
                      )}
                  <hr></hr>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <p className="museo-sans-700 s16">
                      <FormattedMessage id="Impuestos y Retenciones" />
                    </p>
                    <button
                      onClick={changeTogglerTaxes}
                      className="skyColor border-0 bg-transparent museo-sans-700 s14"
                    >
                      <FormattedMessage id="Ver detalle" /> {" "}
                      {!togglerTaxes ? <SVGArrowDown /> : <SVGArrowUp />}{" "}
                    </button>
                  </div>
                  {togglerTaxes && (
                    <>
                      <div
                        className="d-flex flex-column align-items-center justify-content-between pe-3 "
                        style={{
                          marginTop: "17px",
                          marginBottom: "24px",

                          gap: "8px",
                        }}
                      >
                        {dataSummaryByIdData?.transactionSummary.totalAmountDetail.map(
                          (elm) => {
                            return (
                              <>
                                {elm.description ==
                                "Subtotal Productos" ? null : (
                                  <div className="d-flex flex-row justify-content-between align-items-center w-100">
                                    <p
                                      className="museo-sans-500 s14"
                                      style={{ color: "#908F9A" }}
                                    >
                                      {elm?.description}
                                    </p>
                                    <p
                                      className="dm-sans-medium s14 pe-3 "
                                      style={{ color: "#908F9A" }}
                                    >
                                      {formatAmount(
                                        elm?.amount,
                                        cultureInfo?.languageCode,
                                        cultureInfo?.defaultCurrencyCode
                                      )}
                                    </p>
                                  </div>
                                )}
                              </>
                            );
                          }
                        )}
                      </div>
                    
                    </>
                  )}
                </div>
                <hr></hr>
                      <div
                        className="d-flex flex-column align-items-center justify-content-between pe-3"
                        style={{
                          marginTop: "17px",
                          marginBottom: "24px",

                          gap: "8px",
                        }}
                      >
                        <div className="d-flex flex-row justify-content-between align-items-center w-100 pe-3">
                          <p
                            className="museo-sans-700 s16"
                            style={{ color: "#171A22" }}
                          >
                            <FormattedMessage id="Total Compra" />
                          </p>
                          <p
                            className="dm-sans-bold s16"
                            style={{ color: "#171A22" }}
                          >
                            {formatAmount(
                              dataSummaryByIdData?.transactionSummary
                                .totalAmount,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </p>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center w-100 pe-3">
                          <p
                            className="museo-sans-500 s14"
                            style={{ color: "#908F9A" }}
                          >
                            <FormattedMessage id="Total PVP" />
                          </p>
                          <p
                            className="dm-sans-medium s14"
                            style={{ color: "#908F9A" }}
                          >
                            {formatAmount(
                              dataSummaryByIdData?.transactionSummary
                                .totalPVPAmount,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </p>
                        </div>
                      </div>
              </div>
            </div>
    )
}