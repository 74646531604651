import "../Config/Presentation/pages/Configuration.css";

import { useMediaPredicate } from "react-media-hook";

import ReturnTo from "./ReturnTo";

export default function ReturnToAndTitle({
  children,
  travelFunction,
  returnTo,
  title,
}) {
  const biggerThan992 = useMediaPredicate("(min-width: 992px)");

  return (
    <>
      {returnTo && (
        <ReturnTo travelFunction={travelFunction} returnTo={returnTo} />
      )}
      {title && (
        <div className={`${biggerThan992 && "w-50"} museo-sans-700 `}>
          {children}
        </div>
      )}
    </>
  );
}
