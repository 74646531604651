import React from 'react'

import {ReactComponent as VirtualTypeSVG} from '../../../common/svgs/ic-edb-virtual-sm.svg'
import {ReactComponent as PresencialTypeSVG} from '../../../common/svgs/ic-edb-presencial-sm.svg'
import {ReactComponent as ChevronRightSVG} from '../../../common/svgs/ic-chevron-right-md.svg'

import { useNavigate } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
import { multiLangJSONParse } from '../../../common/Share/MultiLangJSONParse';
import ShareGetDate from '../../../common/Share/ShareGetDate';

export default function EDBDatedRow({id, date, title, hostName, edbType, edbState, daysToOpen, edbStatus, totalRegistered, registeredStatus}) {

    const history = useNavigate();

    function viewDetail() {
        history(`${id}`)
    }

    function edbStatusColor(status) {
        switch(status) {
            case "normal":
                return "blackColor";
            case "warning":
                return "darkYellowColor";
            case "completed":
                return "lightGreenColor";
            case "risk":
                return "redColor";
        }
    }

    const splitString = (str) => {
        if (str.length > 30) {
            let aux = str.slice(0,30);
            aux += "...";
            return aux;
        } else {
            return str;
        }
    }

  return (
    <div style={{height: "48px"}} className=' mt-4 pb-4 d-flex ps-3 pe-2 s12 museo-sans-500 border-bottom border-1 justify-content-between align-items-center row-height hover-pointer' onClick={viewDetail}>
        <div style={{width:"14%"}} className='dm-sans-medium s14'>{ShareGetDate(date)}</div>
        <div style={{width:"21%"}} className='grayColor museo-sans-500 s14'>{splitString(title)}</div>
        <div style={{width:"20%"}} className='grayColor museo-sans-500 s14'>{hostName}</div>
        <div style={{width:"10%"}} className='text-capitalize grayColor museo-sans-500 s14 d-flex align-items-center'>
            {edbType}
            <div className=' brightGrayColor control--svg-filler-fill-gray control--svg-filler-gray  ms-2 pb-1'>
                {edbType === "virtual" ? <VirtualTypeSVG /> : <PresencialTypeSVG />}
            </div>
            
        </div>
        <div style={{width:"10%"}} className={`museo-sans-500 s14 ${edbStatusColor(edbStatus)}`}>{daysToOpen > 1 ? "Faltan" : "Falta"} {daysToOpen} {daysToOpen > 1 ? "días" : "día"}</div>{/*edbStatus */}
        <div style={{width:"10%"}} className={`museo-sans-500 s14 ${edbStatusColor(registeredStatus)}`} >{totalRegistered} {totalRegistered > 1 ? "Invitadas" : "Invitada"}</div>{/*registeredStatus */}
        <div style={{width:"5%"}} className='skyColor button-hover-svg-light-stroke'><ChevronRightSVG /></div>
    </div>
  )
}
