import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput";
import ClientListFormSelect from "../elements/ClientListFormSelect";

export default function Mexico({ watch, ...props }) {
  const params = useParams();
  const isUpdate = !!(params?.operation == "update");

  return (
    <>
      <ClientListFormOneColumnInput
        placeholder={"Ej.: Calle"}
        label={"Dirección (opcional)"}
        name="street"
        maxLength={85}
        {...props}
      />
      <div className=" px-3 pe-0">
        <ClientListFormOneColumnInput
          placeholder={"Ej.: 1300"}
          label={"Número exterior (opcional)"}
          name="externalNumber"
          type="number"
          maxLength={15}
          {...props}
        />
      </div>

      <ClientListFormOneColumnInput
        placeholder={"Ej.: 3100"}
        label={"Número interior (opcional)"}
        name="internalNumber"
        type="number"
        maxLength={15}
        {...props}
      />
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          placeholder="Seleccionar un estado"
          label={"Estado (opcional)"}
          father={true}
          isUpdate={isUpdate}
          name="state"
          watch={watch}
          {...props}
          level={1}
        />
      </div>

      <ClientListFormSelect
        placeholder="Seleccionar un municipio"
        label={"Municipio (opcional)"}
        father={watch("state")}
        isUpdate={isUpdate}
        name="municipality"
        watch={watch}
        {...props}
        level={2}
      />
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          placeholder="Seleccionar una colonia"
          label={"Colonia (opcional)"}
          father={watch("municipality")}
          isUpdate={isUpdate}
          name="locality"
          watch={watch}
          {...props}
          level={3}
        />
      </div>
      <ClientListFormSelect
        placeholder="Seleccionar un codigo postal"
        label={"Codigo postal (opcional)"}
        father={watch("locality")}
        isUpdate={isUpdate}
        name="postalCode"
        watch={watch}
        {...props}
        level={4}
        last
      />
      <div className=" px-3 pe-0">
        <ClientListFormOneColumnInput
          placeholder={"Ej.: Referencia"}
          label={"Referencia (opcional)"}
          name="reference"
          type="number"
          maxLength={15}
          {...props}
        />
      </div>
    </>
  );
}
