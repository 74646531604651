import LastUpdate from "../../../common/Share/LastUpdate";

import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as SVGCircleYellow } from "../../../common/svgs/ic-alert-circle-sm.svg";
import { ReactComponent as SVGCircleGreen } from "../../../common/svgs/ic-check-circle-sm.svg";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  fetchDescFirstGen,
  fetchDescIncorp,
  fetchDescPersonalGroup,
} from "../../Framework/redux/DescendantsSideEffect";
import {
  defineDescendantModalUserInfo,
  defineModalUserSalesTab,
} from "../../Framework/redux/DescendantsAction";
import DashboardKeyModal from "../../../Dashboard/Presentation/components/Modals/DashboardKeyModal";
import DescendantModalInfo from "../components/modal/DescendantModalInfo";
import DescendantsConsultantInfoModal from "../components/DescendantsConsultantInfoModal";
import DescendantsPersonalList from "../components/DescendantsPersonalList";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";
import { ReactComponent as SVGCrossError } from "../../../common/svgs/ic-x-circle-sm-blue.svg";
import SkeletonDescendentHeader from "../../../common/Share/SkeletonDescendentHeader";
import SkeletonDescendentRow from "../../../common/Share/SkeletonDescendentRow";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import statusColor from "../../../common/Share/statusColor";
import checkReduceRequirement from "../../../common/Share/checkReduceRequirement";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";
import { ShareQualificationStatusDesc } from "../../../common/Share/ShareAddProgressBar";

export default function DescendantPersonalGroup() {
  const dataPersonalGroup = useSelector(
    (state) => state.descendantData.dataPersonalGroup
  );
  const dataIncorp = useSelector((state) => state.descendantData.dataIncorp);
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const dataFirstGen = useSelector(
    (state) => state?.descendantData?.dataFirstGen
  );
  const [skeleton, setSkeleton] = useState(true);
  const [offSet, setOffSet] = useState(0);
  const [isVisible, setIsVisible] = useState();

  const setIsVisibleChild = (value) => {
    setIsVisible(value);
  };

  const setIsOffsetChild = (value) => {
    setOffSet(value);
  };

  const loading = useSelector((state) => state.descendantData.loader);

  const params = useParams();

  const dispatch = useDispatch();

  const yourClientId = localStorage.getItem("clientId");

  const addProgressBar = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarParams
            height={6}
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarParams
            height={6}
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      } else {
        aux.push(
          <ProgressBarParams
            height={6}
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={status}
          />
        );
      }
    }
    return aux;
  };

  const onStartUp = async () => {
    await dispatch(fetchDescFirstGen({ root: params.root }));
    setSkeleton(false);
  };

  useEffect(() => {
    if (dataFirstGen?.rootSummary?.profileResume?.level < 2) {
      dispatch(fetchDescIncorp({ root: params.root }));
    } else {
      dispatch(fetchDescPersonalGroup({ root: params.root }));
    }
  }, [dataFirstGen]);

  useEffect(() => {
    onStartUp();
  }, []);

  const sortColors = (color) => {
    return { color: statusColor(color, true) };
  };

  function changeModalInfo(salesModal) {
    dispatch(
      defineDescendantModalUserInfo({
        id: dataFirstGen.rootSummary.clientId,
        name: dataFirstGen.rootSummary.profileResume.name,
        firstName: dataFirstGen.rootSummary.profileResume.firstName,
        lastName: dataFirstGen.rootSummary.profileResume.lastName,
        gender: dataFirstGen.rootSummary.profileResume.gender,
        level: dataFirstGen.rootSummary.profileResume.level,
        picture: dataFirstGen.rootSummary.profileResume.image,
        roleDescription: dataFirstGen.rootSummary.profileResume.roleDescription,
        uplineName: dataFirstGen.rootSummary.profileResume.uplineName,
        uplineId: dataFirstGen.rootSummary.profileResume.uplineId,
        socialNetwork: dataFirstGen.rootSummary.profileResume.socialNetwork,
        currencyCode: dataFirstGen.rootSummary.performance.currencyCode,
        personalSalesActual:
          dataFirstGen.rootSummary.performance.personalSales.actualAmount,
        personalSalesTotal:
          dataFirstGen.rootSummary.performance.personalSales.totalAmount,
        personalSalesStatus:
          dataFirstGen.rootSummary.performance.personalSales.status,
        incorporatedSalesActual:
          dataFirstGen.rootSummary.performance.incorporatedSales.actualActives,
        incorporatedSalesTotal:
          dataFirstGen.rootSummary.performance.incorporatedSales.totalNeed,
        incorporatedSalesStatus:
          dataFirstGen.rootSummary.performance.incorporatedSales.status,
        groupSalesActual:
          dataFirstGen.rootSummary.performance.groupSales.actualAmount,
        groupSalesTotal:
          dataFirstGen.rootSummary.performance.groupSales.totalAmount,
        groupSalesStatus:
          dataFirstGen.rootSummary.performance.groupSales.status,
        triperiodicSalesActual:
          dataFirstGen.rootSummary.performance.triperiodicSales.actualAmount,
        triperiodicSalesTotal:
          dataFirstGen.rootSummary.performance.triperiodicSales.totalAmount,
        triperiodicSalesStatus:
          dataFirstGen.rootSummary.performance.triperiodicSales.status,
        triperiodicSalesPeriods:
          dataFirstGen.rootSummary.performance.triperiodicSales.periods,
        billedAmount: dataFirstGen.rootSummary.balanceSummary.billed.amount,
        billedStatus: dataFirstGen.rootSummary.balanceSummary.billed.status,
        balanceAmount: dataFirstGen.rootSummary.balanceSummary.balance.amount,
        balanceStatus: dataFirstGen.rootSummary.balanceSummary.balance.status,
        toInvoiceAmount:
          dataFirstGen.rootSummary.balanceSummary.orderStatusAmounts.find(
            (element) => element.state === "toInvoice"
          ).amount,
        toInvoiceStatus:
          dataFirstGen.rootSummary.balanceSummary.orderStatusAmounts.find(
            (element) => element.state === "toInvoice"
          ).status,
        notSentAmount:
          dataFirstGen.rootSummary.balanceSummary.orderStatusAmounts.find(
            (element) => element.state === "notSent"
          ).amount,
        notSentStatus:
          dataFirstGen.rootSummary.balanceSummary.orderStatusAmounts.find(
            (element) => element.state === "notSent"
          ).status,
      })
    );
    dispatch(defineModalUserSalesTab(salesModal));
  }

  const returnQualificationStatus = (boolean, level, data) => {
    return ShareQualificationStatusDesc(boolean, level, data);
  };

  return (
    <div className=" pb-5 container--padding descPersonalBg">
      <DescendantsConsultantInfoModal />
      <DashboardKeyModal />
      <DescendantModalInfo />
      <div className=" container--padding--card-holder">
        <div>
          <div className=" w-100 desc--personal-update d-flex justify-content-between mt-4 py-3">
            <div></div>
            <LastUpdate />
          </div>
          <div className=" w-100 position-relative mb-5 pt-4 rounded-3 f9GrayBorder cards-shadow bg-white">
            <div className=" pe-3">
              <div className=" position-relative desc--grid-tree px-3 mx-4">
                {skeleton ? (
                      <SkeletonDescendentHeader type={"personal"} />
                ) : (
                  <>
                    <div className=" museo-sans-500 w-100 d-flex align-items-center gap-3 ">
                      <ImageCircleHandler
                        name={dataFirstGen?.name}
                        width={"58px"}
                        height={"58px"}
                        url={dataFirstGen?.rootSummary?.profileResume?.image}
                        firstName={
                          dataFirstGen?.rootSummary?.profileResume?.firstName
                        }
                        lastName={
                          dataFirstGen?.rootSummary?.profileResume?.lastName
                        }
                      />
                      <div className="p-2">
                        <h5 className=" museo-sans-700 s20">
                          {yourClientId == dataPersonalGroup?.rootClientId ? (
                            <FormattedMessage id="you" />
                          ) : (
                            dataFirstGen?.rootSummary?.profileResume?.name
                          )}
                        </h5>
                        <p className=" grayColor s14">
                          {dataFirstGen ? (
                            <ReformatLevelByGender
                              gender={
                                dataFirstGen.rootSummary.profileResume.gender
                              }
                              level={
                                dataFirstGen.rootSummary.profileResume.level
                              }
                              roleDescriptionDefault={
                                dataFirstGen.rootSummary.profileResume
                                  .roleDescription
                              }
                            />
                          ) : (
                            "Role"
                          )}
                        </p>
                      </div>
                    </div>

                    <div className=" invisible position-relative">
                      <button
                        style={{
                          width: "184px",
                          height: "40px",
                          right: "16px",
                          top: "25%",
                        }}
                        className="d-flex position-absolute justify-content-center align-items-center s14 museo-sans-700 skyColor bg-transparent skyBorder rounded "
                      >
                        <p className="">
                          <FormattedMessage id="view.personal.group" />
                        </p>
                      </button>
                    </div>

                    <div
                      style={{ alignSelf: "center" }}
                      className="w-100 h-100 position-relative"
                    >
                      <div
                        style={{ top: "15%", left: "-32px" }}
                        onClick={() => changeModalInfo(false)}
                        className=" position-absolute d-flex align-items-center w-100 justify-content-between"
                        data-bs-toggle="modal"
                        data-bs-target="#descendantConsultantInfo"
                      >
                        <div className=" d-flex flex-column gap-2">
                          <h5 className="s14 museo-sans-500 m-0 ">
                            <FormattedMessage id="nav.label.one.c.b" />
                          </h5>
                          <label
                            className="m-0 s14 dm-sans-medium d-flex justify-content-end "
                            style={sortColors(
                              dataFirstGen?.rootSummary?.performance?.groupSales
                                ?.status
                            )}
                          >
                            {formatAmount(
                              dataFirstGen?.rootSummary?.performance?.groupSales
                                ?.actualAmount,
                              profileData.cultureInfo?.languageCode,
                              profileData.cultureInfo?.defaultCurrencyCode
                            )}
                          </label>
                        </div>
                        <SVGRightArrow />
                      </div>
                    </div>
                    <div
                      style={{ justifySelf: "center" }}
                      onClick={() => changeModalInfo(true)}
                      className="  d-flex gap-2 align-items-center"
                      data-bs-toggle="modal"
                      data-bs-target="#descendantConsultantInfo"
                    >
                      {dataFirstGen &&
                        returnQualificationStatus(
                          true,
                          dataFirstGen.rootSummary.profileResume?.level,
                          dataFirstGen.rootSummary.performance
                        )}
                      <SVGRightArrow />
                    </div>
                  </>
                )}
              </div>
            </div>
            <>
              {skeleton ? (
                <>
                  <hr className=" invisible brightGrayColor px-3 mx-4"></hr>
                </>
              ) : (
                <>
                  {(dataPersonalGroup?.personalGroup?.length != 0 &&
                    dataFirstGen?.rootSummary?.profileResume?.level > 1) ||
                  (dataFirstGen?.rootSummary?.profileResume?.level < 2 &&
                    dataIncorp?.incorporated.length != 0) ? (
                    <>
                      <hr className=" brightGrayColor px-3 mx-4"></hr>
                      <div className=" px-5 w-100  py-3 ">
                        <div
                          style={{ overflowY: "scroll", maxHeight: "600px" }}
                          className="hidden-y-scroll text-uppercase pe-3 desc--grid-tree gap-4 s12 museo-sans-500 grayColor "
                        >
                          <p className="px-4">
                            <FormattedMessage id="edb.details.list.name" />
                          </p>
                          <p className=" invisible d-flex justify-content-center ">
                            <FormattedMessage id="descendants.personal.group" />
                          </p>
                          <p className=" d-flex justify-content-center ">
                            <FormattedMessage id="nav.label.one.a" />
                          </p>
                          <p className=" d-flex justify-content-center ">
                            <FormattedMessage id="descendants.qualification" />
                          </p>
                        </div>
                        {skeleton ? (
                          <>
                            <Skeleton />
                          </>
                        ) : (
                          <>
                            {dataFirstGen?.rootSummary?.profileResume?.level <
                            2 ? (
                                <DescendantsPersonalList
                                  gen={1}
                                  isVisible={isVisible}
                                  loading={loading}
                                  setIsVisible={setIsVisibleChild}
                                  setOffSet={setIsOffsetChild}
                                  offSet={offSet}
                                  root={params?.root}
                                  profileData={profileData}
                                  groups={dataIncorp && dataIncorp.incorporated}
                                />
                            ) : (
                                <DescendantsPersonalList
                                  gen={null}
                                  loading={loading}
                                  setIsVisible={setIsVisibleChild}
                                  setOffSet={setIsOffsetChild}
                                  offSet={offSet}
                                  root={params?.root}
                                  isVisible={isVisible}
                                  profileData={profileData}
                                  groups={
                                    dataPersonalGroup &&
                                    dataPersonalGroup.personalGroup
                                  }
                                />
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                      <hr className=" invisible brightGrayColor px-3 mx-4"></hr>
                  )}
                </>
              )}
            </>
          </div>

          {dataPersonalGroup?.personalGroup?.length == 0 &&
            dataFirstGen?.rootSummary?.profileResume?.level > 2 && (
              <>
                {!skeleton && (
                  <div className=" w-100 py-4 d-flex align-items-center flex-column justify-content-center w-100 gap-2 ">
                  <div
                    style={{
                      border: " 1px solid #456ECE",
                      height: "24px",
                      width: "24px",
                    }}
                    className=" svg-force-size d-flex  rounded-circle  "
                  >
                    <SVGCrossError />
                  </div>
                  <p className=" blueColor museo-sans-500 s16">
                    <FormattedMessage id="dashboard.desc.null" />
                  </p>
                </div>
                )}
              </>
            )}
          {dataFirstGen?.rootSummary?.profileResume?.level < 2 &&
            dataIncorp?.incorporated?.length == 0 && (
              <>
                {!skeleton && (
                  <div className=" w-100 py-4 d-flex align-items-center flex-column justify-content-center w-100 gap-2 ">
                  <div
                    style={{
                      border: " 1px solid #456ECE",
                      height: "24px",
                      width: "24px",
                    }}
                    className=" svg-force-size d-flex  rounded-circle  "
                  >
                    <SVGCrossError />
                  </div>
                  <p className=" blueColor museo-sans-500 s16">
                    <FormattedMessage id="dashboard.desc.null" />
                  </p>
                </div>
                )}
              </>
            )}
          {dataPersonalGroup?.personalGroup?.length < 3 &&
            dataIncorp?.incorporated.length < 3 && (
              <div className="invisible" style={{ height: "150px" }}>
                a
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export function Skeleton(params) {
  return (
    <>
      <div className=" my-3">
        <SkeletonDescendentRow type={"personal"} />
      </div>
      <div className=" my-3">
        <SkeletonDescendentRow type={"personal"} />
      </div>
      <div className=" my-3">
        <SkeletonDescendentRow type={"personal"} />
      </div>
      <div className=" my-3">
        <SkeletonDescendentRow type={"personal"} />
      </div>
      <div className=" my-3">
        <SkeletonDescendentRow type={"personal"} />
      </div>
      <div className=" my-3">
        <SkeletonDescendentRow type={"personal"} />
      </div>
    </>
  );
}
