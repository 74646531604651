import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { clearExtras } from "../../Framework/redux/DescendantsAction";

export default function Descendants() {

    const dispatch = useDispatch();
    const history = useNavigate();

    useEffect(() => {
        dispatch(clearExtras());
    }, [history])

    return <Outlet/>
    
}