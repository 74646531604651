import React from 'react'

import { FormattedMessage } from 'react-intl'

export default function PrivacyPolicyContent() {
  return (
    <>
        <p className='museo-sans-700 s20 mb-4'><FormattedMessage id="politics.meta.privacy" /></p>
        <p className='grayColor mb-2 museo-sans-500 s12'><FormattedMessage id="privacy.policy.current.version" /></p>
        <p className='museo-sans-700 s16 mb-4'><FormattedMessage id="privacy.policy.rights.title" /></p>
        <p className='museo-sans-500 s16 mb-4'><FormattedMessage id="privacy.policy.rights.description.1" /></p>
        <p className='museo-sans-500 s16 mb-4'><FormattedMessage id="privacy.policy.rights.description.2" /></p>
        <p className='museo-sans-500 s16 mb-4'><FormattedMessage id="privacy.policy.rights.description.3" /></p>
        <p className='museo-sans-500 s16 mb-4'><FormattedMessage id="privacy.policy.rights.description.4" /></p>
        <p className='museo-sans-500 s16 mb-4'><FormattedMessage id="privacy.policy.rights.description.5" /></p>
    </>
  )
}
