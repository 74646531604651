import React from 'react'

import '../Config/Presentation/pages/Configuration.css'

import { FormattedMessage } from 'react-intl';
import {ReactComponent as ArrowLeftSVG} from '../svgs/arrow-left-sm.svg'

export default function ReturnButton({travelFunction, travelFunctionValue}) {
  return (
    <div className='d-flex align-items-center pb-3 hover-pointer skyColor button-hover-light-text button-hover-svg-light-stroke width-fit-content' onClick={() => travelFunction(travelFunctionValue)}>
        <ArrowLeftSVG />
        <p className='mb-0 ps-2 museo-sans-700 s14'>
            <FormattedMessage
            id="buttons.back"
            defaultMessage="Volver"
            />
            {/*Volver*/}
        </p>
    </div>
  )
}
