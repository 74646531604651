import LastUpdate from "../../../common/Share/LastUpdate";

export default function DashboardUpdate({ periodError, isDashboard }) {

  return (
    <>
      {periodError ? (
        <div className={`${isDashboard ? "" : "mb-4" }`}>
          <p className="invisible">x</p>
        </div>
      ) : (
        <LastUpdate isDashboard={isDashboard} />
      )}
    </>
  );
}
