export default function SharePhoneMaxLengthByCountry() {
    const country = localStorage.getItem('country');

    const maxLengthObject = {
        "ar": 10,
        "cr": 8,
        "cl": 9,
        "co": 10,
        "uy": 8,
        "mx": 10,
        "pe": 9,
        "pa": 8,
    }

    return maxLengthObject[country] ?? 10;
    
}