import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import ImageOne from "../img/chase.jpg";
import ImageTwo from "../img/motoki.jpg";
import ImageThree from "../img/pexels.jpg";
import ImageFour from "../img/vero.jpg";
import './loginBackImage.css'

export default function LoginBackImage() {

  return (
    <div className="carousel-login-wrapper">
      <Carousel
          swipeable={false}
          autoPlay
          transitionTime={2000}
          interval={9000}
          showArrows={false}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop
          animationHandler={"fade"}
      >
          <img src={ImageOne} alt="Login-first-image"/>

          <img src={ImageTwo} alt="Login-second-image"/>

          <img src={ImageThree} alt="Login-third-image"/>

          <img src={ImageFour} alt="Login-fourth-image"/>
      </Carousel>
    </div>
  );
}
