import React from 'react'

import { FormattedMessage } from 'react-intl'
import EthicsCodeCollapseButton from './EthicsCodeCollapseButton';
import EthicsCodeCollapseContainer from './EthicsCodeCollapseContainer';


import introduction from './imgs/introduction.jpg'
import satisfactionWarranty from './imgs/satisfactionWarranty.png'
import productPricing from '../img/ethicsCodeImages/imagenescde-03.jpg'
import wellBeingEncounters1 from './imgs/wellBeingEncounters1.jpg'
import wellBeingEncounters2 from './imgs/wellBeingEncounters2.jpg'
import logoBrandUsage1 from '../img/ethicsCodeImages/imagenescde-06.png'
import logoBrandUsage2 from './imgs/logoBrandUsage2.jpg'
import logoBrandUsage3 from '../img/ethicsCodeImages/imagenescde-08.png'
import logoBrandUsage4 from '../img/ethicsCodeImages/imagenescde-09.png'
import socialNetworkMarketing1 from '../img/ethicsCodeImages/imagenescde-10.jpg'
import publicityAndCommunication from './imgs/publicityAndCommunication.jpg'
import socialNetworkMarketing2 from './imgs/socialNetworkMarketing2.png'
import googleAdsPromotion from './imgs/googleAdsPromotion.png'

export default function EthicsCodeContent() {
  return ( 
    <>
        <div className='mt-3'>
            <div className='d-flex justify-content-between pe-3 pt-3 pb-3'>
                <p className=' text-uppercase museo-sans-500 s12 grayColor'>Versión vigente 19/11/2020</p>
            </div>
        </div>
        <hr></hr>
        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.introduction"/>} hrefValue="introductionCollapse" />
            <EthicsCodeCollapseContainer collapseId="introductionCollapse">
                <img src={introduction} alt="" className='mt-3 mb-3 w-100'/>
                <p><FormattedMessage id="ethics.code.text.introduction"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.1"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.2"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.3"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.4"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.5"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.6"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.7"/></p>
                <p><FormattedMessage id="ethics.code.text.introduction.8"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.satisfaction.warranty"/>} hrefValue="satisfactionWarranty" />
            <EthicsCodeCollapseContainer collapseId="satisfactionWarranty">
                <p><FormattedMessage id="ethics.code.text.satisfaction.warranty.1"/></p>
                <div  className="d-flex w-100 justify-content-center" >
                <img style={{width: "200px", height: "200px"}} src={satisfactionWarranty} alt="" className='mt-3 mb-3 '/>
                </div>
                <p><FormattedMessage id="ethics.code.text.satisfaction.warranty.2"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.product.pricing"/>} hrefValue="productPricing" />
            <EthicsCodeCollapseContainer collapseId="productPricing">
                <p><FormattedMessage id="ethics.code.text.product.pricing"/></p>
                <div className=' w-100 d-flex  justify-content-center ' >
                    <img style={{height: "400px", width: "300px"}} src={productPricing} alt="" className=' d-flex justify-content-center mt-3 mb-3 '/>
                </div>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.incorporations"/>} hrefValue="incorporationsCollpase" />
            <EthicsCodeCollapseContainer collapseId="incorporationsCollpase">
                <p><FormattedMessage id="ethics.code.text.incorporations"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.benefit.promises"/>} hrefValue="benefitPromises" />
            <EthicsCodeCollapseContainer collapseId="benefitPromises">
                <p><FormattedMessage id="ethics.code.text.benefit.promises"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.wellbeing.encounters"/>} hrefValue="wellbeingEncounters" />
            <EthicsCodeCollapseContainer collapseId="wellbeingEncounters">
                <p><FormattedMessage id="ethics.code.text.wellbeing.encounters.1"/></p>
                <p><FormattedMessage id="ethics.code.text.wellbeing.encounters.2"/></p>
                <img src={wellBeingEncounters1} alt="" className='mt-3 mb-3 w-100'/>
                <img src={wellBeingEncounters2} alt="" className='mt-3 mb-3 w-100'/>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>
        
        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.consultor.relationship"/>} hrefValue="consultorRelationship" />
            <EthicsCodeCollapseContainer collapseId="consultorRelationship">
                <p><FormattedMessage id="ethics.code.text.consultor.relationship"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.earnings"/>} hrefValue="earningsCollapse" />
            <EthicsCodeCollapseContainer collapseId="earningsCollapse">
                <p><FormattedMessage id="ethics.code.text.earnings"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.logo.and.brand.usage"/>} hrefValue="brandAndLogoUsage" />
            <EthicsCodeCollapseContainer collapseId="brandAndLogoUsage">
                <p><FormattedMessage id="ethics.code.text.logo.and.brand.usage.1"/></p>
                <p><FormattedMessage id="ethics.code.text.logo.and.brand.usage.2"/></p>
                <p><FormattedMessage id="ethics.code.text.logo.and.brand.usage.3"/></p>
                <p><FormattedMessage id="ethics.code.text.logo.and.brand.usage.4"/></p>
                <p><FormattedMessage id="ethics.code.text.logo.and.brand.usage.5"/></p>
                <p><FormattedMessage id="ethics.code.text.logo.and.brand.usage.6"/></p>
                <img src={logoBrandUsage1} alt="" className='mt-3 mb-3 w-100'/>
                <img src={logoBrandUsage2} alt="" className='mt-3 mb-3 w-100'/>
                <p><FormattedMessage id="ethics.code.text.logo.and.brand.usage.7"/></p>
                <img src={logoBrandUsage3} alt="" className='mt-3 mb-3 w-100'/>
                <img src={logoBrandUsage4} alt="" className='mt-3 mb-3 w-100'/>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.consultor.image.usage"/>} hrefValue="consultorImageUsage" />
            <EthicsCodeCollapseContainer collapseId="consultorImageUsage">
                <p><FormattedMessage id="ethics.code.text.earnings"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.social.network.marketing"/>} hrefValue="socialNetworkMarketing" />
            <EthicsCodeCollapseContainer collapseId="socialNetworkMarketing">
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.1"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.2"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.3"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.4"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.5"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.6"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.7"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.8"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.9"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.10"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.11"/></p>
                <img src={socialNetworkMarketing1}  alt="" className='mt-3 mb-3 w-100'/>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.12"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.13"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.14"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.15"/></p>
                <p><FormattedMessage id="ethics.code.text.social.network.marketing.16"/></p>
                <div style={{width: "300px", marginLeft: "250px", transform: "rotate(25deg)"}} className='d-flex  '  >
                    <img src={socialNetworkMarketing2} alt="" className='mt-3 mb-3 w-100'/>
                </div>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.google.ads.promotion"/>} hrefValue="googleAdsPromotion" />
            <EthicsCodeCollapseContainer collapseId="googleAdsPromotion">
                <p><FormattedMessage id="ethics.code.text.google.ads.promotion.1"/></p>
                <p><FormattedMessage id="ethics.code.text.google.ads.promotion.2"/></p>
                <img src={googleAdsPromotion} alt="" className='mt-3 mb-3 w-100'/>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.publicity.and.communications"/>} hrefValue="publicityAndCommunications" />
            <EthicsCodeCollapseContainer collapseId="publicityAndCommunications">
                <p><FormattedMessage id="ethics.code.text.publicity.and.communications.1"/></p>
                <img src={publicityAndCommunication} alt="" className='mt-3 mb-3 w-100'/>
                <p><FormattedMessage id="ethics.code.text.publicity.and.communications.2"/></p>
                <p><FormattedMessage id="ethics.code.text.publicity.and.communications.3"/></p>
                <p><FormattedMessage id="ethics.code.text.publicity.and.communications.4"/></p>
                <p><FormattedMessage id="ethics.code.text.publicity.and.communications.5"/></p>
                <p><FormattedMessage id="ethics.code.text.publicity.and.communications.6"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.stationery.and.forms"/>} hrefValue="stationeryAndForms" />
            <EthicsCodeCollapseContainer collapseId="stationeryAndForms">
                <p><FormattedMessage id="ethics.code.text.stationery.and.forms.1"/></p>
                <p><FormattedMessage id="ethics.code.text.stationery.and.forms.2"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.corporate.meetings"/>} hrefValue="corporateMeetings" />
            <EthicsCodeCollapseContainer collapseId="corporateMeetings">
                <p><FormattedMessage id="ethics.code.text.corporate.meetings"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.training"/>} hrefValue="trainingCollapse" />
            <EthicsCodeCollapseContainer collapseId="trainingCollapse">
                <p><FormattedMessage id="ethics.code.text.training"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>
        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.other.business.promotion"/>} hrefValue="otherBusinessPromotion" />
            <EthicsCodeCollapseContainer collapseId="otherBusinessPromotion">
                <p><FormattedMessage id="ethics.code.text.other.business.promotion.1"/></p>
                <p><FormattedMessage id="ethics.code.text.other.business.promotion.2"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>
        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.stores"/>} hrefValue="storesCollapse" />
            <EthicsCodeCollapseContainer collapseId="storesCollapse">
                <p><FormattedMessage id="ethics.code.text.stores.1"/></p>
                <p><FormattedMessage id="ethics.code.text.stores.2"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.exhibitions.and.shopping.centers"/>} hrefValue="exhibitonsCollapse" />
            <EthicsCodeCollapseContainer collapseId="exhibitonsCollapse">
                <p><FormattedMessage id="ethics.code.text.exhibitions.and.shopping.centers"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.relabeling.prohibition"/>} hrefValue="relabelingProhibition" />
            <EthicsCodeCollapseContainer collapseId="relabelingProhibition">
                <p><FormattedMessage id="ethics.code.text.relabeling.prohibition"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.purchases"/>} hrefValue="purchasesCollapse" />
            <EthicsCodeCollapseContainer collapseId="purchasesCollapse">
                <p><FormattedMessage id="ethics.code.text.purchases"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.international.distribution"/>} hrefValue="internationalDistribution" />
            <EthicsCodeCollapseContainer collapseId="internationalDistribution">
                <p><FormattedMessage id="ethics.code.text.international.distribution"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>
        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.immigration.laws"/>} hrefValue="immigrationLaws" />
            <EthicsCodeCollapseContainer collapseId="immigrationLaws">
                <p><FormattedMessage id="ethics.code.text.immigration.laws.1"/></p>
                <p><FormattedMessage id="ethics.code.text.immigration.laws.2"/></p>
                <p><FormattedMessage id="ethics.code.text.immigration.laws.3"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        <hr></hr>

        <div className=''>
            <EthicsCodeCollapseButton title={<FormattedMessage id="ethics.code.title.pyramidal.system"/>} hrefValue="pyramidalSystem" />
            <EthicsCodeCollapseContainer collapseId="pyramidalSystem">
                <p><FormattedMessage id="ethics.code.text.pyramidal.system.1"/></p>
                <p><FormattedMessage id="ethics.code.text.pyramidal.system.2"/></p>
                <p><FormattedMessage id="ethics.code.text.pyramidal.system.3"/></p>
                <p><FormattedMessage id="ethics.code.text.pyramidal.system.4"/></p>
                <p><FormattedMessage id="ethics.code.text.pyramidal.system.5"/></p>
            </EthicsCodeCollapseContainer>
        </div>
        
    </>
    
    
  )
}
