import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import GroupTaskImg from "../../../common/Share/GroupTaskImg";
import { ReactComponent as SVGChevronRight } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as SVGChevronUp } from "../../../common/svgs/ic-chevron-up-md.svg";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";

export default function AlertModalContent({
  details,
  slides,
  data,
  switchImage,
}) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const switchContent = (slides, idRole) => {
    switch (idRole) {
      case 2:
        return (
          <>
            {details.type == 4 ? (
              <>
                {slides === 2 ? (
                  <ContentTwoDetailsFourSliderTwo
                    data={data}
                    cultureInfo={cultureInfo}
                  />
                ) : (
                  <ContentTwoDetailsFourSliderOne
                    data={data}
                    cultureInfo={cultureInfo}
                  />
                )}
              </>
            ) : (
              <>
                {slides === 2 ? (
                  <ContentTwoDetailsOneSliderTwo
                    cultureInfo={cultureInfo}
                    data={data}
                  />
                ) : (
                  <ContentTwoDetailsOneSliderOne
                    cultureInfo={cultureInfo}
                    data={data}
                  />
                )}
              </>
            )}
          </>
        );
      case 3:
        return (
          <>
            {slides === 2 ? (
              <div className="d-flex px-5 flex-column text-center">
                <h2 className=" py-3 museo-sans-700 s24 ">
                  <FormattedMessage id="notification.alert.qualification.title" />
                </h2>
                <p className=" py-2 museo-sans-500 s16 grayColor">
                  <FormattedMessage id="moda.alert.3.2" />
                </p>
                <div className="d-flex flex-column py-4 px-3 lightGreenColor">
                  <div className=" d-flex justify-content-between ">
                    <p className=" s12 museo-sans-500 blackColor ">
                      <FormattedMessage id="notification.alert.qualification.subtitle.one" />
                    </p>
                    <SVGChevronUp />
                  </div>
                  <hr />
                  <div className=" d-flex gap-2 justify-content-center ">
                    <div className=" d-flex flex-column text-center justify-content-center">
                      <GroupTaskImg completed={true} />
                      <p>Mariana Perez</p>
                    </div>
                    <div className=" d-flex flex-column text-center justify-content-center">
                      <GroupTaskImg completed={true} />
                      <p>Fátima Gutierrez</p>
                    </div>
                    <div className=" d-flex flex-column text-center justify-content-center">
                      <GroupTaskImg completed={true} />
                      <p>Fátima Gutierrez</p>
                    </div>
                  </div>
                </div>
                <p className=" py-3 grayColor s16 museo-sans-500">
                  <FormattedMessage id="moda.alert.3.3" />
                </p>
              </div>
            ) : (
              <div className="d-flex px-5 flex-column text-center">
                <h2 className=" py-3 museo-sans-700 s24 ">
                  <FormattedMessage id="notification.alert.earning.title" />
                </h2>
                <p className=" py-2 museo-sans-500 s16 grayColor">
                  <FormattedMessage id="notification.alert.earning.content.one" />
                </p>
                <div className="d-flex flex-column py-3 gap-0 lightGreenColor">
                  <h2 className=" m-0 s34 dm-sans-bold ">
                    {data.slidesData.slide2_1.toLocaleString()}%
                  </h2>
                  <p className=" s16 museo-sans-500">
                    <FormattedMessage id="notification.alert.earning.label.three" />
                  </p>
                </div>
                <p className=" py-3 grayColor s16 museo-sans-500">
                  <FormattedMessage id="notification.alert.earning.content.two" />
                </p>
              </div>
            )}
          </>
        );
      case 4:
        return (
          <div className="d-flex flex-column text-center">
            <h2 className=" py-3 museo-sans-700 s24 ">
              <FormattedMessage id="notification.alert.earning.title" />
            </h2>
            <p className=" py-2 museo-sans-500 s16 grayColor">
              <FormattedMessage id="notification.alert.earning.content.one" />
            </p>
            <div className="d-flex flex-column py-3 gap-0 lightGreenColor">
              <h2 className=" m-0 s34 dm-sans-bold ">
                {data.slidesData.slide2_1.toLocaleString()}%
              </h2>
              <p className=" s16 museo-sans-500">
                <FormattedMessage id="notification.alert.earning.label.four" />
              </p>
            </div>
            <p className=" py-3 grayColor s16 museo-sans-500">
              <FormattedMessage id="notification.alert.earning.content.two" />
            </p>
          </div>
        );
      case 5:
        return (
          <div className="d-flex flex-column text-center px-5">
            <h2 className=" py-3 museo-sans-700 s24 ">
              <FormattedMessage id="notification.alert.earning.title" />
            </h2>
            <p className=" py-2 museo-sans-500 s16 grayColor">
              <FormattedMessage id="notification.alert.earning.content.one" />
            </p>
            <div className="d-flex flex-column py-3 gap-0 lightGreenColor">
              <h2 className=" m-0 s34 dm-sans-bold ">
                {data?.slidesData.slide2_1
                  ? data?.slidesData.slide2_1?.toLocaleString()
                  : data?.slidesData.slide1_1?.toLocaleString()}
                %
              </h2>
              <p className=" s16 museo-sans-500">
                <FormattedMessage id="notification.alert.earning.label.five" />
              </p>
            </div>
            <p className=" py-3 grayColor s16 museo-sans-500">
              <FormattedMessage id="notification.alert.earning.content.two" />
            </p>
          </div>
        );
      case 6:
        return (
          <>
            {slides === 2 ? (
              <div className="d-flex px-5 flex-column text-center">
                <h2 className=" py-3 museo-sans-700 s24 ">
                  <FormattedMessage id="notification.alert.qualification.title" />
                </h2>
                <p className=" py-2 museo-sans-500 s16 grayColor">
                  <FormattedMessage id="notification.alert.qualification.content.one" />
                </p>
                <div className="d-flex flex-column py-4 px-3 lightGreenColor">
                  <div className=" d-flex align-items-center justify-content-between">
                    <p className=" museo-sans-500 s14 blackColor ">
                      <FormattedMessage id="nav.label.one.d" />
                    </p>
                    <div className=" d-flex align-items-center py-1 justify-content-between">
                      <div className=" d-flex">
                        <p className=" blackColor dm-sans-medium s14">
                          {formatAmount(
                            3456000,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        <p className=" dm-sans-medium s14 grayColor">
                          /{" "}
                          {formatAmount(
                            data.slidesData.slide1_1,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                      </div>
                      <SVGChevronRight />
                    </div>
                  </div>
                  <ProgressBarParams
                    height={12}
                    completed={100}
                    gap={false}
                    status={"normal"}
                    incompleted={false}
                  />
                </div>
                <p className=" py-3 grayColor s16 museo-sans-500">
                  <FormattedMessage id="notification.alert.qualification.content.three" />
                </p>
              </div>
            ) : (
              <div className="d-flex flex-column text-center ">
                <h2 className=" py-3 museo-sans-700 s24 ">
                  <FormattedMessage id="notification.alert.earning.title" />
                </h2>
                <p className=" py-2 museo-sans-500 s16 grayColor">
                  <FormattedMessage id="notification.alert.earning.content.one" />
                </p>
                <div className="d-flex flex-column py-3 gap-0 lightGreenColor">
                  <h2 className=" m-0 s34 dm-sans-bold ">
                    {formatAmount(
                      data.slidesData.slide2_1,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </h2>
                  <p className=" s16 museo-sans-500">
                    <FormattedMessage id="notification.alert.earning.label.six" />
                  </p>
                </div>
                <p className=" py-3 grayColor s16 museo-sans-500">
                  <FormattedMessage id="notification.alert.earning.content.two" />
                </p>
              </div>
            )}
          </>
        );
      case 7:
      case 8:
        return (
          <div className=" px-5 d-flex flex-column text-center">
            <h2 className=" py-3 museo-sans-700 s24 ">
              <FormattedMessage id="notification.alert.earning.title" />
            </h2>
            <p className=" py-2 museo-sans-500 s16 grayColor">
              <FormattedMessage id="notification.alert.earning.content.one" />
            </p>
            <div className="d-flex flex-column py-3 gap-0 lightGreenColor">
              <h2 className=" m-0 s34 dm-sans-bold ">
                {formatAmount(
                  data.slidesData.slide1_1,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </h2>
              <p className=" s16 museo-sans-500">
                <FormattedMessage id="notification.alert.earning.label.six" />
              </p>
            </div>
            <p className=" py-3 grayColor s16 museo-sans-500">
              <FormattedMessage id="notification.alert.earning.content.two" />
            </p>
          </div>
        );
    }
  };

  const checkIfStatements = (data, slides) => {
    if (data) {
      if (slides === 1) {
        return 1;
      } else {
        return 2;
      }
    } else {
      return 3;
    }
  };

  return (
    <>
      {(checkIfStatements(data, slides) === 1 ||
        checkIfStatements(data, slides) === 3) && (
        <div className=" text-center px-5">
          {switchImage(details.type)}
          <p className=" mt-3 s24 museo-sans-700 blackColor ">
            {details.title}
          </p>
          <p className=" s16 museo-sans-500 grayColor">{details.content}</p>
          <p></p>
        </div>
      )}
      {checkIfStatements(data, slides) === 2 && (
        <>{switchContent(slides, parseInt(data.newLevelId))}</>
      )}
    </>
  );
}

export function ContentTwoDetailsFourSliderTwo({ cultureInfo, data }) {
  return (
    <div className="d-flex flex-column px-5 text-center">
      <p className=" museo-sans-700 s24 pb-3 ">
        <FormattedMessage id="alert.type.four.slide.one.title" />
      </p>
      <p className=" museo-sans-500 grayColor s16 ">
        <FormattedMessage id="alert.type.four.slide.one.content" />
      </p>
      <div className=" mt-3 pt-2 d-flex flex-column gap-3">
        <div>
          <p className=" dm-sans-bold s34 lightGreenColor  ">
            {formatAmount(
              data?.slidesData?.slide1_1,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )}
          </p>
          <p className=" lightGreenColor museo-sans-500 s16 ">
            <FormattedMessage id="business.plan.qualification.not.new.personal" />
          </p>
        </div>
        <div>
          <p className=" dm-sans-bold s34 lightGreenColor  ">
            {formatAmount(
              data?.slidesData?.slide1_2,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )}
          </p>
          <p className=" lightGreenColor museo-sans-500 s16 ">
            <FormattedMessage id="business.plan.qualification.not.new.group" />
          </p>
        </div>
        <div className=" d-flex align-items-center  flex-column w-100">
          <p className=" dm-sans-bold s34 lightGreenColor  ">
            {formatAmount(
              data?.slidesData?.slide1_3,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )}
          </p>
          <p className=" w-50 lightGreenColor museo-sans-500 s16 ">
            <FormattedMessage id="business.plan.qualification.not.new.incorporated" />
          </p>
        </div>
      </div>
    </div>
  );
}

export function ContentTwoDetailsFourSliderOne({ cultureInfo, data }) {
  const [toggler, setToggler] = useState(false);
  return (
    <div className=" position-relative d-flex flex-column px-5 text-center">
      <p
        style={{ top: "-47px", left: "85px" }}
        className=" position-absolute museo-sans-700 s24 "
      >
        <FormattedMessage id="alert.type.four.slide.two.title" />
      </p>
      <p className=" museo-sans-500 grayColor s16 ">
        <FormattedMessage id="alert.type.four.slide.two.content" />
      </p>
      <div className=" mt-3 pt-3 w-100 h-100">
        {toggler ? (
          <div className=" d-flex ">
            <div
              style={{
                width: "184px",
                height: "38px",
                borderRadius: "16px 16px 0px 0px",
              }}
              onClick={() => {
                setToggler(false);
              }}
              className=" pointer d-flex align-items-center s14  justify-content-center tabs-shadow museo-sans-500 grayColor brightGrayBg "
            >
              <FormattedMessage id="level.two.qualified" />
            </div>
            <div
              style={{
                width: "184px",
                height: "38px",
                borderRadius: "16px 16px 0px 0px",
              }}
              className=" pointer d-flex align-items-center s14  justify-content-center tabs-shadow museo-sans-500 bg-white"
            >
              <FormattedMessage id="level.one.not.inclusive" />
            </div>
          </div>
        ) : (
          <div className=" d-flex ">
            <div
              style={{
                width: "184px",
                height: "38px",
                borderRadius: "16px 16px 0px 0px",
              }}
              className=" pointer d-flex align-items-center s14  justify-content-center tabs-shadow museo-sans-500 bg-white"
            >
              <FormattedMessage id="level.two.qualified" />
            </div>
            <div
              style={{
                width: "184px",
                height: "38px",
                borderRadius: "16px 16px 0px 0px",
              }}
              onClick={() => {
                setToggler(true);
              }}
              className=" pointer d-flex align-items-center s14  justify-content-center tabs-shadow museo-sans-500 grayColor brightGrayBg "
            >
              <FormattedMessage id="level.one.not.inclusive" />
            </div>
          </div>
        )}
        {toggler ? (
          <div
            style={{
              borderRadius: "0px 16px 16px 16px",
              textAlign: "left",
            }}
            className=" cards-shadow position-relative bg-white w-100 p-3"
          >
            <p className=" museo-sans-700 s16 text-uppercase pt-2">
              <FormattedMessage id="descendants.consultant.modal.keyMaintenance.threePeriodSale" />
            </p>
            <div style={{ marginBottom: "24px" }} className=" mt-3">
              <div className=" d-flex justify-content-between s14 ">
                <p className=" museo-sans-500 ">
                  <FormattedMessage id="descendants.consultant.modal.keyMaintenance.threePeriodSale" />
                </p>
                <div className=" d-flex  dm-sans-medium  ">
                  <p>
                    {formatAmount(
                      230764,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                  <p className=" grayColor">
                    /{" "}
                    {formatAmount(
                      230764,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams
                  height={12}
                  completed={100}
                  incompleted={false}
                />
              </div>
            </div>
            <div className=" pb-2 ">
              <p className=" museo-sans-700 s16 text-uppercase pt-2">
                <FormattedMessage id="dashboard.period.subtitle.two" />
              </p>
              <div className=" d-flex flex-column gap-3">
                <div className=" pt-3 d-flex justify-content-between s14 ">
                  <p className=" museo-sans-700 ">
                    <FormattedMessage id="dashboard.prev.earning.period" /> 3{" "}
                    <FormattedMessage id="descendants.user.info.modal.current.period" />
                  </p>
                  <div className=" d-flex  dm-sans-bold  ">
                    <p>
                      {formatAmount(
                        230764,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                  </div>
                </div>
                <div className=" d-flex justify-content-between s14 ">
                  <p className=" museo-sans-500 ">
                    <FormattedMessage id="dashboard.prev.earning.period" /> 2
                  </p>
                  <div className=" d-flex  dm-sans-medium  ">
                    <p>
                      {formatAmount(
                        230764,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                  </div>
                </div>
                <div className=" d-flex justify-content-between s14 ">
                  <p className=" museo-sans-500 ">
                    <FormattedMessage id="dashboard.prev.earning.period" /> 1
                  </p>
                  <div className=" d-flex  dm-sans-medium  ">
                    <p>
                      {formatAmount(
                        230764,
                        cultureInfo?.languageCode,
                        cultureInfo?.defaultCurrencyCode
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              borderRadius: "0px 16px 16px 16px",
              textAlign: "left",
            }}
            className=" cards-shadow position-relative bg-white w-100 p-3"
          >
            <p className=" museo-sans-700 s16 pt-2">
              <FormattedMessage id="personal.sales.full.title" />
            </p>
            <div style={{ marginBottom: "41px" }} className=" mt-3">
              <div className=" d-flex justify-content-between s14 ">
                <p className=" museo-sans-500 ">
                  <FormattedMessage id="dashboard.qualification.ul.personal.sale" />
                </p>
                <div className=" d-flex  dm-sans-medium  ">
                  <p>
                    {formatAmount(
                      230764,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                  <p className=" grayColor">
                    /{" "}
                    {formatAmount(
                      230764,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams
                  height={12}
                  completed={100}
                  incompleted={false}
                />
              </div>
            </div>
            <div style={{ marginBottom: "41px" }} className="">
              <div className=" d-flex justify-content-between s14 ">
                <p className=" museo-sans-500 ">
                  <FormattedMessage id="incorporations.sales.help.modal.title.short" />
                </p>
                <div className=" d-flex  dm-sans-medium  ">
                  <p>4</p>
                  <p className=" grayColor">/ 4</p>
                </div>
              </div>
              <div className="d-flex gap-1">
                <ProgressBarParams
                  height={12}
                  completed={100}
                  incompleted={false}
                />
                <ProgressBarParams
                  height={12}
                  completed={100}
                  incompleted={false}
                />
                <ProgressBarParams
                  height={12}
                  completed={100}
                  incompleted={false}
                />
                <ProgressBarParams
                  height={12}
                  completed={100}
                  incompleted={false}
                />
              </div>
            </div>
            <div className=" pb-2 ">
              <div className=" d-flex justify-content-between s14 ">
                <p className=" museo-sans-500 ">
                  <FormattedMessage id="group.sales.help.modal.title" />
                </p>
                <div className=" d-flex  dm-sans-medium  ">
                  <p>
                    {formatAmount(
                      230764,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                  <p className=" grayColor">
                    /{" "}
                    {formatAmount(
                      230764,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams
                  height={12}
                  completed={100}
                  incompleted={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function ContentTwoDetailsOneSliderTwo({ cultureInfo, data }) {
  return (
    <div className="d-flex flex-column px-5 text-center">
      <h2 className=" m-0 py-2 museo-sans-700 s24 ">
        <FormattedMessage id="notification.alert.title.dashboard" />
      </h2>
      <p className=" py-2 museo-sans-500 s16 grayColor">
        <FormattedMessage id="notification.qualification.title.leadergroup" />
      </p>
      <div className=" museo-sans-500 s14 d-flex flex-column px-3 py-3 gap-5 lightGreenColor">
        <div>
          <div className=" d-flex justify-content-between align-items-center">
            <p className="blackColor">
              <FormattedMessage id="nav.label.one.a" />
            </p>
            <div className=" d-flex align-items-center ">
              <div className=" dm-sans-bold d-flex ">
                <p className=" blackColor ">
                  {formatAmount(
                    230764,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
                <p className=" grayColor">
                  /{" "}
                  {formatAmount(
                    data.slidesData.slide2_1,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
              <SVGChevronRight />
            </div>
          </div>
          <div>
            <ProgressBarParams
              height={12}
              completed={100}
              incompleted={false}
              status={"normal"}
              gap={false}
            />
          </div>
        </div>
        <div>
          <div className=" d-flex justify-content-between align-items-center">
            <p className=" blackColor">
              <span className=" text-capitalize">
                <FormattedMessage id="nav.label.one.b" />{" "}
              </span>
              <FormattedMessage id="nav.label.one.b.extra" />
            </p>
            <div className=" d-flex align-items-center ">
              <div className=" dm-sans-bold d-flex ">
                <p className=" blackColor ">4</p>
                <p className=" grayColor">
                  / {parseInt(data.slidesData.slide2_2).toLocaleString()}
                </p>
              </div>
              <SVGChevronRight />
            </div>
          </div>
          <div>
            <ProgressBarParams
              height={12}
              completed={100}
              incompleted={false}
              status={"normal"}
              gap={false}
            />
          </div>
        </div>
        <div>
          <div className=" d-flex justify-content-between align-items-center">
            <p className="blackColor text-capitalize">
              <FormattedMessage id="nav.label.one.c" />
            </p>
            <div className=" d-flex align-items-center ">
              <div className=" dm-sans-bold d-flex ">
                <p className=" blackColor ">
                  {formatAmount(
                    351764,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}{" "}
                </p>
                <p className=" grayColor">
                  /{" "}
                  {formatAmount(
                    data.slidesData.slide2_3,
                    cultureInfo?.languageCode,
                    cultureInfo?.defaultCurrencyCode
                  )}
                </p>
              </div>
              <SVGChevronRight />
            </div>
          </div>
          <div>
            <ProgressBarParams
              height={12}
              completed={100}
              incompleted={false}
              status={"normal"}
              gap={false}
            />
          </div>
        </div>
      </div>
      <p className=" invisible museo-sans-500 s16 grayColor">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
        cursus diam sit amet neque elementum.
      </p>
    </div>
  );
}

export function ContentTwoDetailsOneSliderOne({ data }) {
  return (
    <div className="d-flex flex-column px-5 text-center">
      <h2 className=" py-3 museo-sans-700 s24 ">
        <FormattedMessage id="notification.alert.earning.title" />
      </h2>
      <p className=" py-2 museo-sans-500 s16 grayColor">
        <FormattedMessage id="notification.alert.earning.content" />
      </p>
      <div className=" museo-sans-500 s14 d-flex flex-column px-3 py-3 gap-5 lightGreenColor">
        <div>
          <p className=" dm-sans-bold s34">{data.slidesData.slide2_1}%</p>
          <p>
            <FormattedMessage id="notification.alert.earning.label.one" />
          </p>
        </div>
        <div>
          <p className=" dm-sans-bold s34">{data.slidesData.slide2_2}%</p>
          <p>
            <FormattedMessage id="notification.alert.earning.label.two" />
          </p>
        </div>
      </div>
      <p className=" py-3 grayColor s16 museo-sans-500">
        <FormattedMessage id="notification.alert.earning.content.two" />
      </p>
    </div>
  );
}
