import CalendarEventSection from "./CalendarEventSection";

export default function CalendarSkeleton({
  toggler,
  smallerThan1280,
  daysInMonth,
  stringFirstDayOfMonth,
}) {
  return (
    <div
      style={{ zIndex: 10 }}
      className={` ${
        toggler ? "" : " invisible "
      } bg-white d-flex position-absolute calendar-and-events-container  `}
    >
      <div className="overflow-scroll remove-scrollbar  me-3 calendar-container">
        <div className="w-100 d-flex flex-column">
          <div className="mb-3 d-flex">
            <div className="weekday-blank-space"></div>
            <div className="d-flex weekday-container">
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </div>
          </div>

          <div className={`d-flex ${smallerThan1280 && "mb-3"}`}>
            <div className="d-flex flex-column weekday-blank-space">
              {daysInMonth === 28 && stringFirstDayOfMonth === "lunes" ? (
                <>
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                </>
              ) : (daysInMonth === 28 && stringFirstDayOfMonth !== "lunes") ||
                daysInMonth === 29 ||
                (daysInMonth === 31 &&
                  stringFirstDayOfMonth !== "sábado" &&
                  stringFirstDayOfMonth !== "domingo") ||
                (daysInMonth === 30 && stringFirstDayOfMonth !== "domingo") ? (
                <>
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                </>
              ) : (
                <>
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                  <SkeletonWeekCalendar />
                </>
              )}
            </div>

            <div className="d-flex flex-wrap calendar-day-container">
              <SkeletonWeek firstHalf={true} />
              <SkeletonWeek secondHalf={true} />
              <SkeletonWeek />
              <SkeletonWeek />
              <SkeletonWeek />
            </div>
          </div>
        </div>
      </div>
      {<CalendarEventSection />}
    </div>
  );
}

export function SkeletonWeekCalendar(params) {
  return (
    <div className=" calendar-week-number  ">
      <p
        style={{ height: "8px", width: "fit-content" }}
        className="rounded-3 whiteColor  brightGrayBg "
      >
        <span className=" invisible">LUN</span>
      </p>
    </div>
  );
}

export function SkeletonRow(params) {
  return (
    <div style={{ height: "6px" }} className=" calendar-weekday-width  ">
      <p
        style={{ height: "8px", width: "fit-content" }}
        className="rounded-3 whiteColor  brightGrayBg "
      >
        <span className=" invisible">LUN</span>
      </p>
    </div>
  );
}

export function SkeletonWeek({ firstHalf, secondHalf }) {
  return (
    <>
      {firstHalf && (
        <>
          <SkeletonDayCalendar />
          <SkeletonDayCalendar />
          <SkeletonDayCalendar />
          <SkeletonDayCalendar
            border={true}
            listTwo={true}
            paintedWeek={true}
            selected={true}
          />
          <SkeletonDayCalendar paintedWeek={true} />
          <SkeletonDayCalendar paintedWeek={true} />
          <SkeletonDayCalendar paintedWeek={true} />
        </>
      )}
      {secondHalf && (
        <>
          <SkeletonDayCalendar listOne={true} paintedWeek={true} />
          <SkeletonDayCalendar paintedWeek={true} />
          <SkeletonDayCalendar paintedWeek={true} />
          <SkeletonDayCalendar border={true} />
          <SkeletonDayCalendar />
          <SkeletonDayCalendar listOne={true} />
          <SkeletonDayCalendar />
        </>
      )}
      {!firstHalf && !secondHalf && (
        <>
          <SkeletonDayCalendar />
          <SkeletonDayCalendar listOne={true} />
          <SkeletonDayCalendar />
          <SkeletonDayCalendar border={true} listOne={true} />
          <SkeletonDayCalendar />
          <SkeletonDayCalendar listOne={true} />
          <SkeletonDayCalendar />
        </>
      )}
    </>
  );
}

export function SkeletonDayCalendar({
  listOne,
  listTwo,
  selected,
  paintedWeek,
  border,
}) {
  return (
    <div
      style={paintedWeek && { background: "#F8F8F8" }}
      className={` ${
        border && "calendar-blue-left-border"
      } calendar-borders ps-2 pe-2 calendar-day`}
    >
      <div
        className={`calendar-number-container mt-2 dm-sans-medium s14 grayColor`}
      >
        {selected ? (
          <div
            style={{ width: "26px", height: "26px" }}
            className=" brightGrayBg rounded-circle lightui1-shimmer"
          ></div>
        ) : (
          <div
            style={{ width: "20px", height: "20px" }}
            className=" brightGrayBg rounded-circle lightui1-shimmer"
          ></div>
        )}
      </div>
      {listOne && (
        <div className="d-flex align-items-center gap-1 mt-2">
          <div
            style={{ width: "8px", height: "8px" }}
            className=" brightGrayBg rounded-circle lightui1-shimmer"
          ></div>
          <div
            style={{ height: "4px" }}
            className="lightui1-shimmer brightGrayBg w-50"
          ></div>
        </div>
      )}
      {listTwo && (
        <>
          <div className="d-flex align-items-center gap-1 mt-2">
            <div
              style={{ width: "8px", height: "8px" }}
              className=" brightGrayBg rounded-circle lightui1-shimmer"
            ></div>
            <div
              style={{ height: "4px" }}
              className="lightui1-shimmer brightGrayBg w-50"
            ></div>
          </div>
          <div className="d-flex align-items-center gap-1 mt-2">
            <div
              style={{ width: "8px", height: "8px" }}
              className=" brightGrayBg rounded-circle lightui1-shimmer"
            ></div>
            <div
              style={{ height: "4px" }}
              className="lightui1-shimmer brightGrayBg w-50"
            ></div>
          </div>
          <div className="d-flex align-items-center gap-1 mt-2">
            <div
              style={{ width: "8px", height: "8px" }}
              className=" brightGrayBg rounded-circle lightui1-shimmer"
            ></div>
            <div
              style={{ height: "4px" }}
              className="lightui1-shimmer brightGrayBg w-50"
            ></div>
          </div>
          <div className="d-flex align-items-center gap-1 mt-2">
            <div
              style={{ height: "4px" }}
              className="lightui1-shimmer brightGrayBg w-25"
            ></div>
          </div>
        </>
      )}
    </div>
  );
}
