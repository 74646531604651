export default function ClientListFormTextArea({
  label,
  placeholder,
  hasSvg,
  inputValue,
  inputChanger,
  name,
  register
}) {
  return (
    <div className="d-flex w-100 flex-column gap-2">
      <div>
        <p className=" museo-sans-500 s14">{label}</p>
      </div>
      <div className="w-100" style={{ height: "171px" }}>
        <div className=" museo-sans-500 s14 d-flex justify-content-center align-items-center rounded-2 input-border w-100 h-100 p-2">
          <textarea
            style={{ resize: "none" }}
            placeholder={placeholder}
            className=" no-outline-input border-0 m-1 h-100 w-100"
            maxLength={1000}
            {...register(name)}
          />
        </div>
      </div>
    </div>
  );
}