import React from 'react';
import { useNavigate } from "react-router-dom";

import { ReactComponent as LogOutSVG } from '../../../svgs/log-out.svg';
import { ReactComponent as SVGClose } from "../../../svgs/ic-x-lg.svg";
import { FormattedMessage } from "react-intl";
import { useDispatch } from 'react-redux';
import { logOut } from '../../../redux/commonSideEffects';
import { resetFilters, resetFirstSteps, resetOffersAndNotices } from '../../../../EDBs/Framework/redux/EDBsActions';
import { cleanIncorporatedAndRestOfGroupData } from '../../../../FirstSteps/Framework/redux/FirstStepsAllActions';

export default function LogOut() { 
    const history = useNavigate();
    const dispatch = useDispatch()

    function logOutFunction() {
        history("/");
        dispatch(logOut());
        dispatch(resetFilters());
        dispatch(resetOffersAndNotices());
        dispatch(resetFirstSteps());
        dispatch(cleanIncorporatedAndRestOfGroupData());
    }

  return (
    <div className="modal fade" id="logOutModal" aria-hidden="true" aria-labelledby="logOutModalLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered" style={{maxWidth: "496px"}}>
            <div className="modal-content modal-radius">
                
                <div style={{height: "367px"}} className="modal-body d-flex align-items-center justify-content-center">
                    <i 
                        className="position-absolute" 
                        style={{top: "15px", right: "15px"}} 
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <SVGClose></SVGClose>
                    </i>
                    
                    <div>
                        <div className='d-flex flex-column align-items-center'>
                            <LogOutSVG />
                            <div className=' mt-3 d-flex flex-column justify-content-center align-items-center'>
                                <h5 className='museo-sans-700 s24'>
                                    <FormattedMessage id="log.out.title" defaultMessage="Cerrar sesión" /></h5>
                                <p className='museo-sans-500 s16 grayColor'><FormattedMessage id="log.out.subtitle" defaultMessage="¿Seguro deseas salir del sistema?" /></p>
                            </div>
                        </div>
                        <div className=' mt-4 d-flex flex-column align-items-center'>
                            <button style={{width: "288px", height: "40px"}} type="button" className=" mb-3 museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-border-none button-bootstrap-radius button-padding" data-bs-dismiss="modal" aria-label="Close" onClick={logOutFunction}> 
                            <FormattedMessage id="buttons.yes" defaultMessage="Cerrar sesión" /></button>
                            <button style={{width: "288px", height: "40px"}} type="button" className=" mb-3 museo-sans-700 s14 skyColor skyBorder button-bootstrap-radius button-padding bg-white" data-bs-dismiss="modal" aria-label="Close"><FormattedMessage id="buttons.no" defaultMessage="Cerrar sesión" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}