import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

export default function ClientListDetailsModalOrigen() {
  const JSXRow = ({ level }) => {
    return (
      <div className="d-flex gap-1">
        <p className=" s14 museo-sans-500">
          <spam className="me-1 museo-sans-700">
            <FormattedMessage id={`client.details.edb.origin.type.${level}`} />:
          </spam>
          <FormattedMessage
            id={`client.details.edb.origin.type.${level}.explained`}
          />
        </p>
      </div>
    );
  };

  return (
    <div style={{ padding: "44px" }} className=" museo-sans-500">
      <div className="d-flex gap-2 s24 museo-sans-700">
        <div
          style={{ width: "24px", height: "24px", top: "-2px" }}
          className=" position-relative svg-force-size"
        >
          <HelpIconNoTarget />
        </div>
        <p>
          <FormattedMessage id="client.details.edb.origen.title" />
        </p>
      </div>
      <div className="d-flex flex-column gap-1 mt-2 pt-1 mb-4 pb-3">
        <p className=" s14 museo-sans-500">
          <FormattedMessage id="client.details.edb.origin.modal.body.one" />
        </p>
        <p className=" s14 museo-sans-500">
          <FormattedMessage id="client.details.edb.origin.modal.body.two" />
        </p>
        <JSXRow level={"one"} />
        <JSXRow level={"two"} />
        <JSXRow level={"three"} />
      </div>
      <div className="w-100 d-flex justify-content-center">
        <button
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ width: "288px" }}
          className=" rounded-2 py-2 bg-white border-1 skyBorder skyColor s14 museo-sans-700"
        >
          <FormattedMessage id="buttons.understood" />
        </button>
      </div>
    </div>
  );
}
