export default function SharePasswordIsError(passwordValue, passwordAuxiliar, checkFor) {

    const regex = /^[a-zA-Z0-9.@ áéíóúÁÉÍÓÚñÑüÜ_-]*$/;

    const conditions = {
        1: {conditional: !passwordValue || passwordValue?.length < 4, messageId: "settings.password.error.types.one"},
        2: {conditional: !regex.test(passwordValue), messageId: "settings.password.error.types.three"},
        3: {conditional: passwordValue !== passwordAuxiliar, messageId: "settings.password.error.types.two"}
    };
    
    if (!checkFor) {
        for (let i = 1; i <= 3; i++) {
            if (conditions[i].conditional) {
                return { isError: true, messageId: conditions[i].messageId };
            };
        };
        return { isError: false, messageId: "" };
    }
    
    if (conditions[checkFor].conditional) {
        return { isError: true, messageId: conditions[checkFor].messageId };
    };

    return { isError: false, messageId: "" };
    
}