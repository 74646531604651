export default function processDateData(date) {
  let arrayOriginOne = date.split("T");
  let sectorOne = arrayOriginOne[0];
  let arrayOriginTwo = sectorOne.split("-");

  let yearOriginTwo = arrayOriginTwo[0];
  let monthOriginTwo = arrayOriginTwo[1];
  let datOriginTwo = arrayOriginTwo[2];
  
  let pastDateTwo = `${datOriginTwo}-${monthOriginTwo}-${yearOriginTwo}`;

  return pastDateTwo ;
}
