import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const createURLEncoded = (requestType, data) => {

  const byType = {
    mobile_registered: {username: data.number, password: data.password, type: requestType, notificationId: ""},
    authenticator: {type: requestType, authenticatorCode: data.authenticatorCode, userOtpCode: data.optCode, notificationId: ""},
    mobile: {username: data.number, password: data.password, type: requestType, notificationId: ""},
    email: {username: data.number, type: requestType, source: "mobile"},
    associate: {phoneNumber: data.phone, authenticatorType: "sms"},
    access: {username: data.number, password: data.password, type: requestType},
    changePassword: {username: data.number, password: data.password, userOtpCode: data.pin},
  }

  let urlencoded = new URLSearchParams();

  for (const key in byType[requestType]) {
    urlencoded.append(key, byType[requestType][key]);
  }
  return urlencoded;
}

const fetchAuthLogin = createAsyncThunk(
  "fetchAuthLogin",
  async ({ number, password }, {rejectWithValue}) => {
    try {
      localStorage.setItem("clientId", number.trim());
      let location = localStorage.getItem("country");
      let data = {number, password};
      let urlencoded = createURLEncoded("mobile_registered", data);
      return await customNetServAxios().post(`${location}/security/auth/token`, urlencoded);
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
);

const fetchAuthenticator = createAsyncThunk(
  "fetchAuthenticator",
  async (optCode, {rejectWithValue, getState}) => {
    try {
      const { userProfile } = getState().userInfo
      let location = localStorage.getItem("country");
      let authenticatorCode = localStorage.getItem("authenticatorCode");
      let data = {optCode, userProfile, authenticatorCode};
      let urlencoded = createURLEncoded("authenticator", data);
      return await customNetServAxios().post(`${location}/security/auth/token`, urlencoded);
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
);

const fetchAuthRegister = createAsyncThunk(
  "fetchAuthRegister",
  async ({ number, password }, {rejectWithValue}) => {
    try {
      localStorage.setItem("clientId", number.trim());
      let location = localStorage.getItem("country");
      let urlencoded = createURLEncoded("mobile", {number, password});
      localStorage.setItem("token", "blank");
      const result = await customNetServAxios(false, false, [400, 401], false, true).post(`${location}/security/auth/token`, urlencoded);
      localStorage.removeItem("token");
      return result
    } catch (error) {
      localStorage.removeItem("token");
        return rejectWithValue(error.message);
    }
  }
);

const resolveChangePassword = createAsyncThunk(
  "resolveChangePassword",
  async (password, {rejectWithValue}) => {
    try {
      let location = localStorage.getItem("country");
      let userOtpCode = localStorage.getItem("pin");
      let number = localStorage.getItem("clientId");
      let urlencoded = createURLEncoded("changePassword", {number, password, pin: userOtpCode});
      return await customNetServAxios().post(
        `${location}/security/auth/credentials/change-password`,
        urlencoded);
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
);

const fetchPassRecovery = createAsyncThunk(
  "fetchPassRecovery",
  async (number, {rejectWithValue}) => {
    try {
      let location = localStorage.getItem("country");
      let urlencoded = createURLEncoded("email", {number});
      localStorage.setItem("clientId", number.trim());
      localStorage.setItem("token", "blank");
      const result = await customNetServAxios(false, false, [400, 401]).post(
        `${location}/security/auth/recovery`,
        urlencoded
      );
      localStorage.removeItem("token");
      return result;
    } catch (error) {
      localStorage.removeItem("token");
        return rejectWithValue(error.message);
    }
  }
);

const fetchAssociateSms = createAsyncThunk(
  "fetchAssociateSms",
  async (phone, {rejectWithValue}) => {
    try {
      let location = localStorage.getItem("country");
      let urlencoded = createURLEncoded("associate", {phone});
      return await customNetServAxios().post(
        `${location}/security/auth/associate`,
        urlencoded
      );
    } catch (error) {
        return rejectWithValue(error.message);
    }
  }
);

const fetchUserProfile = createAsyncThunk(
  "fetchUserProfile",
  async (number, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      const response = await customNetServAxios().get(`${location}/profile/${clientId}`);
      let currencyCode = response.cultureInfo.defaultCurrencyCode; 
      let languageCode = response.cultureInfo.languageCode;

      localStorage.setItem("currencyCode", currencyCode)
      localStorage.setItem("languageCode", languageCode)
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchDirectorProfile = createAsyncThunk(
  "fetchDirectorProfile",
  async (data, {rejectWithValue}) => {
    try {
      let location = localStorage.getItem("country");
      let urlencoded = createURLEncoded("access", data);
      localStorage.setItem("directorId", data.number);
      localStorage.setItem("token", "blank");
      let result = await customNetServAxios(false, false, [400, 401]).post(`${location}/security/auth/director/token`, urlencoded);
      localStorage.removeItem("token");
      return result;
    } catch (error) {
      localStorage.removeItem("token");
      return rejectWithValue(error);
    }
  }
)

const fetchclientWithDirectorId = createAsyncThunk(
  "fetchclientWithDirectorId",
  async (data, {rejectWithValue}) => {
    try {
      let location = localStorage.getItem("country");
      let directorId = localStorage.getItem("directorId");
      localStorage.setItem("clientId", data?.clientId.trim());
      return await customNetServAxios().get(`${location}/sales-directors/${directorId}/client/${data?.clientId}`);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export {
  fetchAuthRegister,
  fetchDirectorProfile,
  fetchPassRecovery,
  fetchAuthLogin,
  fetchAssociateSms,
  fetchUserProfile,
  fetchAuthenticator,
  resolveChangePassword,
  fetchclientWithDirectorId
};
