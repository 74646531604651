import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { changeLevel } from "../../Framework/redux/BusinessAction";
import {
  getBussinessPlan,
  getQualifiedGroup,
  getScoreComparison,
} from "../../Framework/redux/BusinessSideEffect";
import BusinessPlanCardEarning from "../components/BusinessPlanCardEarning";
import BusinessPlanCardLevelActual from "../components/BusinessPlanCardLevelActual";
import BusinessPlanCardLevelFuture from "../components/BusinessPlanCardLevelFuture";
import BusinessPlanCardLevelNext from "../components/BusinessPlanCardLevelNext";
import BusinessPlanCardLevelPrevious from "../components/BusinessPlanCardLevelPrevious";
import { ReactComponent as SVGCheckGray } from "../../../common/svgs/ic-check-circle-gray.svg";
import BusinessPlanCardQualification from "../components/BusinessPlanCardQualification";
import BusinessPlanModalInfo from "../components/Modals/BusinessPlanModalInfo";
import BusinessPlanModalInfoQualification from "../components/Modals/BusinessPlanModalInfoQualification";
import "./BusinessPlan.css";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";

export default function BusinessPlan() {
  let profileLevel = useSelector((state) => state.userInfo.userInfo.level);
  const data = useSelector((state) => state.businessData.data);
  const qualifiedGroups = useSelector(
    (state) => state.businessData.qualifiedGroups
  );
  const earningData = useSelector((state) => state.businessData.earningData);
  const comparedLevel = useSelector((state) => state.businessData.level);
  const [nextLevel, setNextLevel] = useState(false);

  const [loader, setLoader] = useState(true);

  const array = [
    { id: 1, desc: <FormattedMessage id="level.one" /> },
    { id: 2, desc: <FormattedMessage id="level.two" /> },
    { id: 3, desc: <FormattedMessage id="level.three" /> },
    { id: 4, desc: <FormattedMessage id="level.four" /> },
    { id: 5, desc: <FormattedMessage id="level.five" /> },
    { id: 6, desc: <FormattedMessage id="level.six" /> },
    { id: 7, desc: <FormattedMessage id="level.seven" /> },
    { id: 8, desc: <FormattedMessage id="level.eight" /> },
  ];

  const dispatch = useDispatch();

  const onClick = async (level) => {
    setLoader(true);
    setNextLevel(false);
    dispatch(changeLevel(level));
    dispatch(getScoreComparison({ level: level }));
    dispatch(getQualifiedGroup({ level: level }));
    dispatch(getBussinessPlan({ level: level }));
    setLoader(false);
  };

  const onStartUp = async () => {
    setLoader(true);
    try {
      await dispatch(
        getScoreComparison(
          profileLevel ? { level: profileLevel } : { level: 1 }
        )
      );
      await dispatch(
        getQualifiedGroup(profileLevel ? { level: profileLevel } : { level: 1 })
      );
      await dispatch(
        getBussinessPlan(profileLevel ? { level: profileLevel } : { level: 1 })
      );
    } catch (error) {
      console.log(error);
    }
    if (profileLevel) {
      dispatch(changeLevel(profileLevel));
    }
    setLoader(false);
  };

  const toggleNextLevel = () => {
    setNextLevel(true);
  };

  useEffect(() => {
    onStartUp();
    if (profileLevel == 0) {
      profileLevel = 1;
    }
  }, [profileLevel]);

  return (
    <div
      style={{ height: "80vh" }}
      className="py-4 bg-white container--padding container--padding--card-holder   "
    >
      <div>
        <div className="w-100 mb-5 bg-white d-flex gap-4">
          <ShareModalStructure maxWidth={"610px"} id={"modalInfo"} >
            <BusinessPlanModalInfo />
          </ShareModalStructure>
          <ShareModalStructure maxWidth={"610px"} id={"modalInfoQualification"} >
            <BusinessPlanModalInfoQualification />
          </ShareModalStructure>
          {profileLevel ? (
              <div
                id="business-plan--level-grid"
                className=" position-relative "
              >
                {profileLevel > "1" && (
                  <BusinessPlanCardLevelPrevious
                    onClick={onClick}
                    actualLevel={profileLevel}
                    level={comparedLevel}
                    array={array}
                  />
                )}
                <BusinessPlanCardLevelActual
                  onClick={onClick}
                  actualLevel={profileLevel}
                  level={comparedLevel}
                  array={array}
                />
                {profileLevel !== "8" && (
                  <BusinessPlanCardLevelNext
                    onClick={onClick}
                    actualLevel={profileLevel}
                    level={comparedLevel}
                    array={array}
                    toggleNextLevel={toggleNextLevel}
                  />
                )}
                {profileLevel < "7" && (
                  <BusinessPlanCardLevelFuture
                    onClick={onClick}
                    actualLevel={profileLevel}
                    level={comparedLevel}
                    array={array}
                  />
                )}
              </div>
          ) : (
            <div id="business-plan--level-grid" className=" position-relative ">
              <Skeleton />
            </div>
          )}
          <BusinessPlanCardQualification
            businessPlan={earningData}
            loading={loader}
            groups={qualifiedGroups}
            data={data}
            newToggle={nextLevel}
            level={comparedLevel || profileLevel}
          />
          <BusinessPlanCardEarning
            data={earningData}
            loading={loader}
            newToggle={nextLevel}
            level={comparedLevel || profileLevel}
          />
        </div>
      </div>
    </div>
  );
}

export function Skeleton(params) {
  return (
    <>
      <SkeletonRow first={true} />
      <SkeletonRow background={true} />
      <SkeletonRow more={true} />
    </>
  );
}

export function SkeletonRow({ more, background, first }) {
  return (
    <>
      <div className=" mb-1 gap-1 d-flex flex-column ">
        {first ? (
            <div
              style={{ width: "24px", height: "24px" }}
              className=" svg-force-size lightui1-shimmer"
            >
              <SVGCheckGray />
            </div>
        ) : (
            <div
              className=" grayBorder border-3 border rounded-circle lightui1-shimmer"
              style={{ width: "16px", height: "16px" }}
            ></div>
        )}
        <div className="d-flex w-100 h-100 justify-content-center ">
          {!more && (
              <div
                style={{ width: "2px" }}
                className=" rounded-3 brightGrayBg h-100 lightui1-shimmer"
              ></div>
          )}
        </div>
      </div>
      <div className=" p-3 w-100 gap-3 d-flex flex-column ">
        <div
          style={{ height: "12px" }}
          className=" w-75 rounded-3 brightGrayBg lightui1-shimmer"
        ></div>
        {!more && (
            <div
              style={{ height: "6px" }}
              className=" mb-3 w-75 rounded-3 brightGrayBg lightui1-shimmer"
            ></div>
        )}
        {more && (
          <>
            <div
              style={{ height: "6px" }}
              className=" w-75 rounded-3 brightGrayBg lightui1-shimmer"
            ></div>
            <div
              style={{ height: "6px" }}
              className=" w-50 rounded-3 brightGrayBg lightui1-shimmer"
            ></div>
            <div
              style={{ height: "6px" }}
              className=" w-75 rounded-3 brightGrayBg lightui1-shimmer"
            ></div>
            <div
              style={{ height: "6px" }}
              className=" w-25 rounded-3 brightGrayBg lightui1-shimmer"
            ></div>
            <div
              style={{ height: "6px" }}
              className=" w-75 rounded-3 brightGrayBg lightui1-shimmer"
            ></div>
            <div
              style={{ height: "6px" }}
              className=" w-50 rounded-3 brightGrayBg lightui1-shimmer"
            ></div>
          </>
        )}
      </div>
    </>
  );
}
