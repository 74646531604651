import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { useContext } from "react";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import { ReactComponent as SVGChevron } from "../../../common/svgs/ic-chevron-right-md.svg";
import { getEDBCards } from "../../../EDBs/Framework/redux/EDBFormSideEffect";
import ClientListDetailsModalEDBCard from "./modals/ClientListDetailsModalEDBCard";
import { ReactComponent as SVGCross } from "../img/emptyIcon.svg";
import ClientListDetailsModalAddCard from "./modals/ClientListDetailsModalAddCard";
import { ModalClipboardContext } from "../../../common/routes/routes";

export default function ClientListDetailsContainerCards({
  open,
  data,
  edbData,
  isEmpty,
  searchByIdValue,
}) {
  const dispatch = useDispatch();
  const cardsData = useSelector((state) => state.edbFormData.cards);
  const params = useParams();
  const onStartUp = async () => {
    if (!isEmpty) {
      let data = { edbId: params.id, attendeeId: params.clientId };
      await dispatch(getEDBCards(data));
    }
  };

  useEffect(() => {
    onStartUp();
  }, []);

  return (
    <div className="d-flex flex-column museo-sans-500 ">
      {isEmpty ? (
        <ClientListDetailsContainerCardsEmpty
          hasURL={data?.invitationLinkCards}
          firstName={data?.firstName}
          data={data}
        />
      ) : (
        <>
          <div className="d-flex gap-4 grayColor ">
            <div className="">
              <p className=" s12">
                <FormattedMessage id="client.details.edb.cards.register" />:{" "}
                {searchByIdValue()}
              </p>
              <p className=" s14">
                <FormattedMessage id="client.details.edb.cards.subtitle.one" />{" "}
                {data?.firstName}{" "}
                <FormattedMessage id="client.details.edb.cards.subtitle.two" />
              </p>
            </div>
          </div>
          <div
            style={{ gap: "23px" }}
            className="s14 mt-4 museo-sans-700 skyColor flex-wrap d-flex "
          >
            {cardsData?.data?.cards?.map((card) => {
              return (
                <ClientListDetailsContainerCardsElement
                  data={card}
                  open={open}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export function ClientListDetailsContainerCardsEmpty({
  firstName,
  hasURL,
  isEdb,
  data,
}) {
  const { setModalTexts } = useContext(ModalClipboardContext);

  const onOpenClipboard = () => {
    const modalData = {
      title: <FormattedMessage id={"client.details.edb.empty.shared.title"} />,
      text: <FormattedMessage id={"client.details.edb.empty.shared.p"} />,
      status: "confirm",
      show: false,
      bottom: 0,
    };
    setModalTexts(modalData);
  };

  return (
    <div className=" mt-4 px-2 ">
      <ClientListDetailsModalAddCard
        phoneNumber={data?.phoneNumbers}
        socialArray={data?.socialNetworks}
        open={onOpenClipboard}
        url={hasURL}
      />
      <div
        style={{
          width: "391px",
          height: "180px",
          boxShadow: "10px 10px 25px 0px rgba(164, 164, 215, 0.20)",
        }}
        className="p-4 bg-white blueColor museo-sans-700 rounded-3 text-center h-100 flex-column d-flex "
      >
        <div>
          <div>
            <SVGCross />
          </div>
          <p className=" s16">
            {firstName}{" "}
            <FormattedMessage id="client.details.edb.cards.empty.title" />
          </p>
          <hr className={`${hasURL ? "" : "invisible"} my-4`} />
        </div>
        <p
          data-bs-toggle="modal"
          data-bs-target={`#empty-share-link`}
          className={` ${hasURL ? "" : "invisible"} skyColor pointer s14 `}
        >
          <FormattedMessage id="client.details.edb.cards.empty.button" />
        </p>
      </div>
    </div>
  );
}

export function ClientListDetailsContainerCardsElement({ open, data }) {
  return (
    <>
      <ShareModalStructure
        maxWidth={"851px"}
        idNumber={data?.idCms}
        id={"edb-card-id"}
      >
        <ClientListDetailsModalEDBCard data={data} open={open} />
      </ShareModalStructure>

      <div
        style={{
          width: "199px",
          boxShadow: "3px 3px 15px rgba(164, 164, 215, 0.15)",
          borderRadius: "16px",
        }}
        className="d-flex flex-column bg-white"
      >
        <div
          style={{ height: "240px", borderRadius: "16px 16px 0px 0px" }}
          className="w-100 overflow-hidden position-relative "
        >
          <img
            style={{ right: "8px", top: "-12px" }}
            className=" position-relative "
            src={`${data?.img}`}
          ></img>
        </div>
        <div
          style={{ borderRadius: "0px 0px 16px 16px" }}
          className="p-1 w-100"
        >
          <div
            data-bs-toggle="modal"
            data-bs-target={`#edb-card-id-${data?.idCms}`}
            style={{ height: "58px" }}
            className="w-100 skyColor museo-sans-700 d-flex align-items-center justify-content-between p-2 pointer"
          >
            <p>
              <FormattedMessage id="client.details.edb.cards.show.details" />
            </p>
            <div>
              <SVGChevron />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
