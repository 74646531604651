import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput"
import ClientListFormSelect from "../elements/ClientListFormSelect"

export default function Argentina({ watch, ...props }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')

  return(
    <>
      {/* <ClientListFormOneColumnInput
        placeholder={"Ej.: Oficina"}
        label={"Tipo de dirección *"}
        name="adressType"
        register={register}
      /> */}
      <ClientListFormOneColumnInput
        placeholder={"Ej.: Av. Rivadavia 2345"}
        label={"Dirección (opcional)"}
        name="street"
        {...props}
      />
      {/* <ClientListFormOneColumnInput
        placeholder={"Ej.: 1A"}
        label={"Piso/Depto (opcional)"}
        name="apartmentFloor"
        register={register}
      /> */}
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          label={"Provincia (opcional)"}
          placeholder={"Seleccionar una Provincia"}
          father={true}
          name="province"
          isUpdate={isUpdate}
          watch={watch}
          {...props}
          level={1}
        />

      </div>
      <ClientListFormSelect
        label={"Localidad (opcional)"}
        placeholder={"Seleccionar una Localidad"}
        father={watch('province')}
        name="locality"
        isUpdate={isUpdate}
        watch={watch}
        {...props}
        level={2}
      />
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          placeholder={"Seleccionar un Código postal"}
          label={"Código postal (opcional)"}
          father={watch('locality')}
          name="postalCode"
          isUpdate={isUpdate}
          watch={watch}
          {...props}
          level={3}
          last
        />
      </div>
    </>
  )
}