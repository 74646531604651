import { FormattedMessage } from "react-intl";

export default function StyledMultiLang({loading, id, def}) {
    
    return <>
    {
        loading ?
        <div style={{width: "fit"}} className=" position-relative" >
            <div className="lightui1-shimmer w-100 brightGrayBg rounded-3 h-100 position-absolute" >

            </div>
            <p className=" text-white " >
                {def ? def : "Cargando"}
            </p>
        </div>
        :
        <FormattedMessage id={id} defaultMessage={def ? def : "Cargando"} />
    }
    </>
}