import { multiLangJSONParse } from "./MultiLangJSONParse";

export default function ShareNameShortMonth (number) {

    const monthKeyMap = {
        "01": "one",
        "02": "two",
        "03": "three",
        "04": "four",
        "05": "five",
        "06": "six",
        "07": "seven",
        "08": "eight",
        "09": "nine",
        "10": "ten",
        "11": "eleven",
        "12": "twelve",
      };
    
    
      const monthKey = `month.short.${monthKeyMap[number]}`;
    const monthsJson = multiLangJSONParse();
    return monthsJson[monthKey] || "NAN";
  };