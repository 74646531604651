import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import { ReactComponent as SVGChevronDown } from "../../../../common/svgs/ic-chevron-down-sm.svg";
import { ReactComponent as SVGChevronUp } from "../../../../common/svgs/ic-chevron-up-sm.svg";
import { formatAmount } from "../../../../common/Share/FormatCurrency";


export default function PersonalSalesModalOrderEDBRow({ hideOnPrint, attend }) {
  const [toggler, setToggler] = useState(false);

  const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo);

  const onToggle = () => {
    setToggler(!toggler);
  };

  const subTotal = (array) => {
    let result = 0;

    array.map((elm) => {
      result = result + elm.price;
    });

    return formatAmount(result, cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)
  };


  return (
    <div className=" my-3 rounded-3 p-4 cards-shadow ">
      <div className=" d-flex justify-content-between align-items-center">
        <p className=" museo-sans-500 s16 ">{attend?.name}</p>
        {!hideOnPrint && (
          <div
            onClick={onToggle}
            className=" museo-sans-700 pointer d-flex gap-2 align-items-center s14 skyColor"
          >
            <FormattedMessage id="personal.sale.details.products.show" /> ({attend.products && attend.products.length})
            {toggler ? <SVGChevronUp /> : <SVGChevronDown />}
          </div>
        )}
      </div>
      <div>
        {toggler || hideOnPrint  ? (
          <div className=" my-4">
            {attend?.products &&
              attend.products.map((elm) => {
                return (
                  <div className=" my-3 d-flex ">
                    <div
                      style={{ width: "80px", height: "80px" }}
                      className=" rounded-2 border grayBorder position-relative"
                    >
                      <div
                        style={{
                          width: "24px",
                          height: "24px",
                          background: "#8889DB",
                          right: "-12px",
                          top: "-12px",
                        }}
                        className=" rounded-circle dm-sans-medium s12 whiteColor d-flex justify-content-center align-items-center  position-absolute"
                      >
                        x{elm.totalItems}
                      </div>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={elm.img}
                        href={elm.img}
                        alt=""
                      />
                    </div>
                    <div className=" px-4 pe-0 w-100 d-flex justify-content-between ">
                      <div className=" d-flex flex-column">
                        <p className=" museo-sans-500 s14">{elm.title}</p>
                        <p className=" dm-sans-medium s12 grayColor ">
                          {elm.id}
                        </p>
                      </div>
                      <div
                        style={{ minWidth: "100px" }}
                        className=" d-flex align-items-end flex-column"
                      >
                        <p className=" dm-sans-500 s14 ">
                        {formatAmount(elm?.totalPrice,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                        </p>
                        <div className=" grayColor d-flex s12 justify-content-end  ">
                          <p className=" museo-sans-500 px-1"><FormattedMessage id="personal.sale.details.p.u" />: </p>
                          <p className=" dm-sans-500  ">
                          {formatAmount(elm?.price,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : <></>}
      </div>
      <div className=" d-flex justify-content-between grayColor align-items-center">
        <div className=" text-capitalize museo-sans-500 s14 ">
          <FormattedMessage id="personal.sale.details.products.sub.total" />
        </div>
        <div className=" dm-sans-medium s14">
         {attend && subTotal(attend.products)}
        </div>
      </div>
    </div>
  );
}
