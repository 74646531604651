import { useState, useRef } from "react";

import { ReactComponent as SVGFilledRightGrayChev } from "../../../../common/svgs/ic-slide-right-gray.svg";
import { ReactComponent as SVGFilledLeftGrayChev } from "../../../../common/svgs/ic-slide-left-gray.svg";
import { ReactComponent as SVGFilledRightChev } from "../../../../common/svgs/ic-slide-right.svg";
import { ReactComponent as SVGFilledLeftChev } from "../../../../common/svgs/ic-slide-left.svg";
import { useMediaPredicate } from "react-media-hook";

export default function EDBDashboardSlider({
  carouselCards,
  data,
  length,
  solid,
}) {
  const scrollBarRef = useRef(null);
  const [togglerLeftOne, setTogglerLeftOne] = useState(true);
  const [togglerRightOne, setTogglerRightOne] = useState(false);
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");

  const scroll = (scrollOffset, ref) => {
    const cardWidth = ref.current.children[0].children[0].clientWidth;
    let cardsPerView = 4;
    let gap = 16;
    biggerThan1280 ? (gap = 24) : (gap = 16);
    biggerThan1280 ? (cardsPerView = 4) : (cardsPerView = 3);
    
    let scrollAmount = "";
    biggerThan1280 ? (scrollAmount = cardWidth * cardsPerView + gap * 4) : (scrollAmount = cardWidth * cardsPerView + gap * 3);


    let maxScrollLeft =
      scrollBarRef.current.scrollWidth - scrollBarRef.current.clientWidth;
    let currentValue =
      scrollBarRef.current.scrollLeft + scrollOffset * scrollAmount;

    if (currentValue >= maxScrollLeft) {
      currentValue = maxScrollLeft;
    }
    if (currentValue <= 0) {
      currentValue = 0;
    }
    scrollBarRef.current.scrollLeft = currentValue;
    setTogglerRightOne(currentValue >= maxScrollLeft);
    setTogglerLeftOne(currentValue <= 0);
  };
  const control = (length, solid, data) => {
    if (solid) {
      return length !== solid;
    } else {
      return data.length > length;
    }
  };

  return (
    <>
      {control(length, solid, data) && (
        <div className=" w-100 position-absolute">
          <div
            style={{ top: 172 }}
            className=" d-flex justify-content-between align-items-center w-100 position-absolute"
          >
            <div
              style={{ width: "24px", zIndex: 1, left: -30 }}
              className=" svg-force-size position-relative pointer"
              onClick={() => scroll(-1, scrollBarRef)} // Cambiamos el scrollOffset a -1
            >
              {togglerLeftOne ? (
                <SVGFilledLeftGrayChev />
              ) : (
                <SVGFilledLeftChev />
              )}
            </div>
            <div
              style={{ width: "24px", zIndex: 1, right: -30 }}
              className=" svg-force-size position-relative pointer"
              onClick={() => scroll(1, scrollBarRef)} // Cambiamos el scrollOffset a 1
            >
              {togglerRightOne ? (
                <SVGFilledRightGrayChev />
              ) : (
                <SVGFilledRightChev />
              )}
            </div>
          </div>
        </div>
      )}
      <div
        style={
          data.length > length
            ? { overflowX: "hidden", scrollBehavior: "smooth" }
            : { scrollBehavior: "smooth" }
        }
        ref={scrollBarRef}
        className=" edb-scroll-slider d-flex position-relative"
      >
        <div
          style={{ flexWrap: "nowrap", flexShrink: 0 }}
          className=" edb-dashboard--slider-gap d-flex py-4"
        >
          {carouselCards(data, 0, 50)}
        </div>
      </div>
    </>
  );
}
