
export default function getTimeZoneByCountry  (countryCode)  {
  switch (countryCode) {
    case "ar":
      return "America/Argentina/Buenos_Aires";
    case "pe":
      return "America/Lima";
    case "cl":
      return "America/Santiago";
    case "cr":
      return "America/Costa_Rica";
    case "co":
      return "America/Bogota";
    case "mx":
      return "America/Mexico_City";
    case "pa":
      return "America/Panama";
    case "uy":
      return "America/Montevideo";
    default:
      return "UTC"; // Fallback a UTC en caso de país desconocido
  }
};
