
import { ReactComponent as SVGShare } from "../../../common/svgs/ic-share-sm.svg";
import SwissJustIcon from "../../../common/img/dashboardSharePNG/Just-app-icon.png";
import { FormattedMessage } from "react-intl";

export default function DashboardShare({socialArray, user}) {
    
    return (
        <div className="pb-4">
        <h3 className=" m-0 s20 museo-sans-500 ">
          <FormattedMessage id="dashboard.share.title" /> 
        </h3>
        <div className=" mt-4 align-items-center cards-shadow rounded-4 d-flex justify-content-between bg-white p-3">
          <div className=" d-flex ">
            <img className="px-3" src={SwissJustIcon} alt="SwissJustAppIcon" />
            <div className=" d-flex justify-content-center flex-column">
              <p className=" s16  museo-sans-700">
              <FormattedMessage id="dashboard.share.label.title" /> 
              </p>
              <p
                style={{ maxWidth: "356px" }}
                className=" museo-sans-500 s14 grayColor"
              >
                <FormattedMessage id="dashboard.share.label.subtitle" /> 
              </p>
            </div>
          </div>

          <div className="d-flex ">
            <button data-bs-toggle="modal"
            data-bs-target="#modalOffersShare" 
            className=" LinkOpacityHover align-items-center d-flex py-2 px-5 btnCustom bg-white skyBorder skyColor museo-sans-700">
              <p className=" s14 px-1">
              <FormattedMessage id="dashboard.share.button" />
              </p>
              <SVGShare />
            </button>
          </div>
        </div>
      </div>
    )
}