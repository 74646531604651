import { FormattedMessage } from "react-intl";

export default function ShareComponenteVisagra(params) {

  const onVoid = () => {
    return
  };
    
    return(
        <div className="s14 w-100 ">
              <div className="d-flex museo-sans-700 flex-row justify-content-center align-items-center lightVioletBg p-1 rounded-3 lightBlueColor">
                <div
                  id="account-current-button"
                  onClick={ params?.toggler ? onVoid : () =>  params?.onTogglerType(true)}
                  className={` ${params?.toggler ? "bronceBg rounded-3 w-100 px-3 pt-1 pb-1 d-flex justify-content-center align-items-center" : "w-100 hover-pointer rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex justify-content-center align-items-center"} `}
                >
                  <div className="d-flex gap-1 justify-content-center align-items-center">
                    
                    <p>
                      <FormattedMessage id={params?.idMessageOne} />
                    </p>
                  </div>
                </div>
                <div
                  onClick={ !params?.toggler ? onVoid :() =>  params?.onTogglerType(false)}
                  className={` ${!params?.toggler ? "bronceBg rounded-3 w-100 px-3 pt-1 pb-1 d-flex justify-content-center align-items-center" : "w-100 hover-pointer rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex justify-content-center align-items-center"} `}
                >
                  <div className="d-flex gap-1 justify-content-center align-items-center">
                    
                    <p>
                      <FormattedMessage id={params?.idMessageTwo} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
    )
}