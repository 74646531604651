import React from 'react';
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

import warningImg from '../../../../common/img/warning.png'
import './warning.css'
import { FormattedMessage } from 'react-intl';

function createRequiredMessage(obj) {
  // Filtra las claves que tienen type 'required'
  const requiredMessages = Object.keys(obj).filter(key => obj[key].type === 'required').map(key => obj[key].message);
  // Verifica si hay mensajes para procesar
  if (requiredMessages.length === 0) {
    return '';
  }
  // Caso especial para un solo mensaje
  if (requiredMessages.length === 1) {
    return requiredMessages[0];
  }
  // Juntar todos los mensajes excepto el último con comas
  const allButLast = requiredMessages.slice(0, -1).join(', ');
  // Retorna la cadena formateada correctamente
  return `${allButLast} y ${requiredMessages[requiredMessages.length - 1]}`;
}

function removeObjectProperties(obj) {
  const newObj = {};
  Object.keys(obj).forEach(key => {
    if (obj[key].type !== 'required') {
      newObj[key] = obj[key];
    }
  });
  return newObj;
}


function WarningModal({ errors }) {
  const requiredMessage = createRequiredMessage(errors)
  const withoutRequired = removeObjectProperties(errors)

  return (
    <div
      className="modal fade"
      id="modalFormErros"
      aria-hidden="true"
      aria-labelledby="modalFormErros"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "496px" }}
      >
        <div  className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div className=' position-relative' >
              <div className=" position-absolute w-100 h-100 d-flex justify-content-between p-2 ">
                <div className="d-flex align-items-center">
                  
                </div>
                <div
                style={{right: 6, top: 6}}
                  className="pointer position-relative"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <SVGClose />
                </div>
              </div>
              
              <div className="d-flex flex-column align-items-center pt-3 justify-content-center">
                <div className="museo-sans-500 pt-4 px-4 mx-3 text-center">
                  <img className='position-relative' style={{height: "92px", width: "92px", top: -2}} src={warningImg} />
                  <h3 className='mt-2 s24 museo-sans-700'>
                    <FormattedMessage id='client.form.warning.pop.up.title' />
                  </h3>
                  
                    <p className=' grayColor s16 museo-sans-500 w-60'>
                      <FormattedMessage id='client.form.warning.pop.up.content' />
                    </p>
                </div>
                <button
                  className='buttonModal skyBg s14 museo-sans-700 text-white'
                  data-bs-toggle="modal"
                  data-bs-target={`#modalFormErros`}
                >
                  <FormattedMessage id='client.form.warning.pop.up.button' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WarningModal;