import { FormattedMessage } from "react-intl";

export default function DistributionAgreementContentAR(params) {
    return (
        <>
           <p className="my-2 museo-sans-500 s16">
              <FormattedMessage id="distribution.agreement.title.declarations.current.version" />
            </p>
           <p className={` museo-sans-500 s16 my-4`}>
            <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
            <b>
              <FormattedMessage id="distribution.agreement.text.contract.parts.2" />
            </b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.3" />
            <b>
              <FormattedMessage id="distribution.agreement.text.contract.parts.4" />
            </b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.5" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
          </p>
          <p className={` museo-sans-500 s16 my-4`}>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5.1" />
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.5.2" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5.3" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
          </p>
          <p className="my-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
          </p>
        </>
      );
}