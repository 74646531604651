import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getBilledOrders,
  getHistoricPeriods,
  getPendingOrders,
  getSalesByWeek,
  getUnsendedOrders,
} from "../../Framework/redux/performanceSideEffects";

import { Outlet, useLocation } from "react-router-dom";

export default function PersonalSales() {
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const headerData = useSelector((state) => state?.headerData);
  const periodHistory = useSelector(
    (state) => state.performanceData.historicPeriods
  );

  let periodForSearh = headerData?.period?.currentYearPeriod;

  const onStartUp = async () => {
    if (headerData.period?.currentYearPeriod) {
      await dispatch(
        getHistoricPeriods({ consultantId: params?.consultantId || null })
      );

      if (location?.pathname?.indexOf("personalSales/period") == -1) {
        dispatch(
          getUnsendedOrders({
            yearPeriod: periodForSearh,
            consultantId: params?.consultantId || null,
          })
        );
        dispatch(
          getPendingOrders({
            yearPeriod: periodForSearh,
            consultantId: params?.consultantId || null,
          })
        );
      }
     await dispatch(
        getBilledOrders({
          yearPeriod: periodForSearh,
          consultantId: params?.consultantId || null,
        })
      );

      await dispatch(
        getSalesByWeek({
          yearPeriod: headerData.period?.currentYearPeriod,
          consultantId: params?.consultantId || null,
        })
      );
    }
  };

  useEffect(() => {
    onStartUp();
  }, [location, headerData.period?.currentYearPeriod]);

  for (let i = 0; i < periodHistory?.length; i++) {
    if (periodHistory[i].period === parseInt(params?.currentPeriod)) {
      periodForSearh = periodHistory[i].yearPeriod;
    }
  }

  return (
    <div className="d-flex justify-content-between flex-column position-relative ">
      <Outlet />
    </div>
  );
}
