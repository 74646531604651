import { createReducer } from "@reduxjs/toolkit";
import { postClient } from "../../../ClientList/Framework/redux/ClientListSideEffect";
import { activateError, activatePhoneError, deactivateError } from "./EDBFormActions";
import { fetchTopics, getEDBCards } from "./EDBFormSideEffect";

const initialState = {
    topics: {
        value: "",
        loader: false,
    },
    errorTogger: {
        value: false,
        message: ""
    },
    errorPhoneToggler: {
        value: false,
        message: "",
    },
    cards: {
        data: [],
        loader: false,
    }
}

const EDBFormReducer = createReducer(initialState, (builder) => {
    builder

        .addCase(getEDBCards.pending, (state, action) => {
            state.cards.loader = true;
            state.cards.data = [];
        })
        .addCase(getEDBCards.fulfilled, (state, action) => {
            state.cards.loader = false;
            state.cards.data = action.payload;
        })
        .addCase(getEDBCards.rejected, (state, action) => {
            state.cards.loader = false;
            state.cards.data = [];
        })


        .addCase(activateError, (state, action) => {
            state.errorTogger.value = true
        })
        .addCase(activatePhoneError, (state, action) => {
            state.errorPhoneToggler.value = true
        })
        .addCase(deactivateError, (state, action) => {
            state.errorTogger.value = false
            state.errorPhoneToggler.value = false
        })

        .addCase(fetchTopics.pending, (state, action) => {
            state.topics.loader = true
        })

        .addCase(fetchTopics.fulfilled, (state, action) => {
            state.topics.value = action.payload
            state.topics.loader = false
        })
        .addCase(postClient.rejected, (state, action) => {
            state.errorTogger.value = true
        })

        .addCase(fetchTopics.rejected, (state, action) => {
            state.topics.loader = false
        })

});

export default EDBFormReducer