import * as yup from "yup"
import SharePhoneLibrary from "../../../../common/Share/SharePhoneLibrary";

export const uySchema = yup
  .object({
    firstName: yup
      .string()
      .trim()
      .matches(/^[^\d]+$/, 'nombre')
      .required('nombre'),
    lastName: yup
      .string()
      .trim()
      .matches(/^[^\d]+$/, 'apellido')
      .required('apellido'),
    email: yup
      .string()
      .email('email')
      .test(
        'is-valid-email',
        'email',
        value => {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
          if(value === "" || value === null || value === undefined) return true;
          return emailRegex.test(value);
        }
      ),
    cellNumber: yup
      .string("telefono")
      .test('phone-validation', 'teléfono', async (value, context) => {
        if (value.substring(0, 2) != "09") {
          console.log("Phone validation error type: phone starts with 09");
          return false
        }
        let isPhoneFailedValidation = await SharePhoneLibrary(value);
        return !isPhoneFailedValidation;
      })
      .required('teléfono'),
  })
  .required()