import UseAnalyticsEventTracker from "../../../common/services/UseAnalyticsEventTracker";

export default function LoginAnalytics(label) {

    const category = "login"

    const auxLabel = `${category}_${label}`

    if (label != "unknow") {
        UseAnalyticsEventTracker(category , auxLabel )
    }
    
}