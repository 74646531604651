import { FormattedMessage } from "react-intl";

export default function BusinessPlanCardEarningPerLevelContent({
  elm,
  returnNewJSX,
}) {
  return (
    <h3 className=" m-0 d-flex text-uppercase museo-sans-700 s16">
      <>
        {elm?.itemDescription === "POR VENTA PERSONAL" && (
          <>
            <span
              style={{ width: "60px", textAlign: "right" }}
              className="dm-sans-bold"
            >
              {elm?.valueDescription}
            </span>
            <span className="museo-sans-500 s14 px-2 mx-1 invisible"></span>
          </>
        )}
        {elm?.itemDescription === "POR INCORPORADAS PERSONALES" && (
          <>
            <span className="museo-sans-500 s14 px-2 mx-1 invisible"></span>
            <span
              style={{ width: "60px", textAlign: "right" }}
              className="dm-sans-bold"
            >
              {elm?.itemDescription === "POR VENTA PERSONAL" &&
                elm.valueDescription}
            </span>
          </>
        )}
        {elm?.itemDescription === "POR DESCENDENCIA" && (
          <>
            <span className="museo-sans-500 s14 px-2 mx-1 invisible"></span>
            <span
              style={{ width: "60px", textAlign: "right" }}
              className="dm-sans-bold"
            >
              {elm?.itemDescription === "POR VENTA PERSONAL" &&
                elm.valueDescription}
            </span>
          </>
        )}

        {[
          "bono",
          "POR VENTA PERSONAL",
          "POR INCORPORADAS PERSONALES",
          "POR DESCENDENCIA",
        ].some((str) => elm.itemDescription.indexOf(str) !== -1) ? (
          <span className="bp-earning--p-width">
            {elm.itemDescription.indexOf("bono") !== -1 ? (
              <span className="d-flex gap-1">
                <FormattedMessage id="business.plan.earning.bonus" />
                {elm.comparisonPreviousLevel == "new" ? returnNewJSX() : <></>}
              </span>
            ) : (
              <span>{elm.itemDescription}</span>
            )}
          </span>
        ) : (
          <span className="invisible">
            {elm.itemDescription.indexOf("bono") !== -1 ? (
              <FormattedMessage id="business.plan.earning.bonus" />
            ) : (
              elm.itemDescription
            )}
          </span>
        )}
      </>
    </h3>
  );
}
