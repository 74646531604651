import { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";

import UserData from "./UserData";
import DistributionAgreement from "./DistributionAgreement";
import ConfigurationReport from "../components/ConfigurationReport";

import { ReactComponent as UserSVG } from "../../../svgs/user.svg";
import { ReactComponent as DistributionAgreementSVG } from "../../../svgs/distribution-agreement.svg";
import { ReactComponent as TermsAndConditionsSVG } from "../../../svgs/terms-and-conditions.svg";
import { ReactComponent as SVGClose } from "../../../svgs/ic-x-lg.svg";
import { ReactComponent as SVGReport } from "../icons/ReportarProblema.svg"

import ConfigPrivacyPolicy from "./ConfigPrivacyPolicy";
import ConfigTermsAndConditions from "./ConfigTermsAndConditions";
import { ModalClipboardContext } from "../../../routes/routes";
import "../pages/Configuration.css";

export default function Configuration({ keySetter }) {
  const biggerThan992 = useMediaPredicate("(min-width: 992px)");
  const location = localStorage.getItem("country");
  const [value, setValue] = useState(1);
  const { setModalTexts } = useContext(ModalClipboardContext);

  const JSXObject = {
    1: <UserData setModalTexts={setModalTexts} />,
    2: <ConfigurationReport />,
    3: <DistributionAgreement />,
    4: <ConfigPrivacyPolicy />,
    5: <ConfigTermsAndConditions />,
  };

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className={`modal-dialog modal-dialog-centered`}
        style={{ width: "1016px", maxWidth: "unset" }}
      >
        <div className="modal-content modal-radius overflow-hidden config-modal-height">
          <div className="ms-0 me-0 border-bottom-0 bg-light modal-header-styles">
            <h5
              className="modal-title museo-sans-500 s20"
              id="exampleModalLabel"
            >
              <FormattedMessage
                id="nav.label.eight.index"
                defaultMessage="Settings"
              />
            </h5>
            <div
              id="closeModal"
              onClick={() => {
                setValue(1);
                keySetter(Math.random());
              }}
              className="pointer "
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <SVGClose />
            </div>
          </div>
          <div className="modal-body overflow-hidden p-0">
            <div className="container-fluid h-100 p-0">
              <div
                className={`d-flex h-100 ${!biggerThan992 && "flex-column"}`}
              >
                <div
                  className={`col-lg-4 pt-3 ${
                    biggerThan992 && "border-end grayBorder"
                  }`}
                  style={{ width: "304px" }}
                >
                  <aside className="d-flex flex-column h-100">
                    <div className="d-flex flex-column h-100">
                      <ConfigurationRow setValue={setValue} req={true} value={value} valueToSet={1} SVGElement={UserSVG} textId={"settings.data"} />
                      <ConfigurationRow setValue={setValue} req={true} value={value} valueToSet={2} SVGElement={SVGReport} textId={"settings.report.row"} />
                      <ConfigurationRow setValue={setValue} req={location != "pe" } value={value} valueToSet={3} SVGElement={DistributionAgreementSVG} textId={"settings.distribution.agreement"} />
                      <ConfigurationRow setValue={setValue} req={location != "ar"} value={value} valueToSet={5} SVGElement={TermsAndConditionsSVG} textId={"politics.meta.terms"} />

                    </div>
                    <button
                      className="align-self-center mt-auto museo-sans-700 s14 skyColor skyBorder button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding bronceBg config-logout-button"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#logOutModal"
                    >
                      <svg
                        className="me-1"
                        id="sm"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                      >
                        <rect id="bg" width="16" height="16" fill="none" />
                        <path
                          id="vector"
                          d="M5.538,11.85H1.385a.482.482,0,0,1-.462-.5v-9a.482.482,0,0,1,.462-.5H5.538A.481.481,0,0,0,6,1.35a.482.482,0,0,0-.462-.5H1.385A1.448,1.448,0,0,0,0,2.35v9a1.448,1.448,0,0,0,1.385,1.5H5.538a.5.5,0,0,0,0-1Z"
                          transform="translate(2 1.152)"
                          fill="#5aaff1"
                          stroke="#5aaff1"
                          strokeWidth="0.5"
                        />
                        <path
                          id="vector-2"
                          data-name="vector"
                          d="M176.633,110.346l-2.517-3.036a.363.363,0,0,0-.585.005.588.588,0,0,0,0,.715l1.8,2.169h-4.819a.516.516,0,0,0,0,1.012h4.819l-1.8,2.169a.588.588,0,0,0,0,.715.363.363,0,0,0,.585.005l2.517-3.036a.588.588,0,0,0,0-.72Z"
                          transform="translate(-162.756 -102.705)"
                          fill="#5aaff1"
                          stroke="#5aaff1"
                          strokeWidth="0.5"
                        />
                      </svg>
                      <FormattedMessage
                        id="buttons.log.out"
                        defaultMessage="Log out"
                      />
                    </button>
                  </aside>
                </div>
                <div className="col-lg-8 col-12 pt-3 position-relative pb-4 ps-4 ps-lg-0">
                  {JSXObject[value]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function ConfigurationRow({setValue, value, valueToSet, req, SVGElement, textId}) {
  return (
    <div>
      {
        req &&
        <div
          onClick={() => setValue(valueToSet)}
          className={`${
            value === valueToSet && "selected-menu-button"
          } hover-pointer d-flex justify-content-start align-items-center ps-4 config-menu-button`}
        >
          <SVGElement />
          <p className="ms-3 mb-0 museo-sans-500 s16">
            <FormattedMessage id={textId || "unknown"} defaultMessage="My data" />
          </p>
        </div>

      }
    </div>
  );
}
