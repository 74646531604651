import { multiLangJSONParse } from "../../common/Share/MultiLangJSONParse";

/**
 * 
 * @param {number} number 
 * @param {string} uiCulture 
 * @param {string} currencyCode 
 * @returns {string} el monto formateado con el signo $
 */
const formatAmount = (number, uiCulture, currencyCode, toggler, paramDebugger) => {
    let currency = "";
    let strNumber = "";
    try {
        strNumber = number.toFixed(2);
    } catch (e) { }

    currency = currencyCode === "USD"
    ? formatNumber(strNumber, uiCulture, true)
    : formatNumber(strNumber, uiCulture, false, paramDebugger);
    
    
    return toggler ? (+currency) : `${multiLangJSONParse()["currency"]} ` + currency;
};

/**
 * 
 * @param {string} amount 
 * @param {string} uiCulture 
 * @param {boolean} showDecimals 
 * @returns {string} el monto formateado
 */
const formatNumber = (amount, uiCulture, showDecimals = false, paramDebugger) => {
    // Se definen separadores según región
    if (paramDebugger) {
        
    }
    let groupingSeparator = ",";
    let decimalSeparator = ".";
    if (
        uiCulture == "es-AR" ||
        uiCulture == "es-CL" ||
        uiCulture == "es-CR" ||
        uiCulture == "es-UY"
    ) {
        groupingSeparator = ".";
        decimalSeparator = ",";
    }
    // Se separa el monto de los decimales
    const amountParts = amount.split(".", 2);
    let amountNumber = parseInt(amountParts[0]);
    let amountDecimals = amountParts[0];
    if (amountParts.length > 1) amountDecimals = amountParts[1];
    if (isNaN(amountNumber)) { return ""; }
    // Se calcula si es un numero negativo
    let minus = "";
    if (amountNumber < 0) { minus = "-"; }
    amountNumber = Math.abs(amountNumber);
    let strAmountNumber = amountNumber.toString();
    // Se separa los agrupadores de unidades para agregar el separador regional
    let ab = [];
    while (strAmountNumber.length > 3) {
        let centena = strAmountNumber.substr(strAmountNumber.length - 3);
        ab.unshift(centena);
        strAmountNumber = strAmountNumber.substr(0, strAmountNumber.length - 3);
    }
    if (strAmountNumber.length > 0) {
        ab.unshift(strAmountNumber);
    }
    strAmountNumber = ab.join(groupingSeparator);
    // Se agrega la parte decimal de ser necesario y se devuelve el monto formateado
    if (amountDecimals.length < 1 || amountParts.length == 1) {
        amount = strAmountNumber;
    } else {
        if (showDecimals) amount = strAmountNumber + decimalSeparator + amountDecimals;
        else amount = strAmountNumber;
    }
    amount = minus + amount;

    return amount;
};


export {
    formatAmount
}