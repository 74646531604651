import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchCAO   = createAsyncThunk(
    'fetchCAO',
    async (data, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/great-practices/${userId}/cao/goals`)
            return response
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)

export { fetchCAO }