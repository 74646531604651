import { useSelector } from "react-redux";

import { formatAmount } from "../../../../common/Share/FormatCurrency";

import { ReactComponent as SVGMail } from "../../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGCopy } from "../../../../common/svgs/ic-copy-md.svg";
import { ReactComponent as SVGCross } from "../../../../common/svgs/ic-x-lg.svg";
import { FormattedMessage } from "react-intl";
import { multiLangJSONParse } from "../../../../common/Share/MultiLangJSONParse";
import replaceElementsURL from "../../../../common/Share/replaceElementURL";
export default function OfertsModalImageCardSharing({ open, setToggler, modalOutSideClickRef }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const profileData = useSelector((state) => state.userInfo.userInfo);
  const dataSharing = useSelector((state) => state.ofertsData.dataSharing);
  const socialArray = profileData?.socialNetwork;
  
  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const returnValuesDiscountPhone = (boolean) => {

    if (boolean) {
      return `${resolveAmountRequested(
        dataSharing?.data?.discountPrice
      )}* ~${resolveAmountRequested(
        dataSharing?.data?.regularPrice
      )}~%0A%0A`;
      
    } else {
      return ""
    }

  };

  const returnValuesDiscountWeb = (boolean) => {

    if (boolean) {
      return `${resolveAmountRequested(
        dataSharing?.data?.discountPrice
      )}* ~${resolveAmountRequested(
        dataSharing?.data?.regularPrice
      )}~\n\n`;
      
    } else {
      return ""
    }

  };

  const resolveAmountRequested = (value) => {
    let aux = formatAmount(
      +value,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    );

    return aux;
  };

  const replaceUrlProduct = (url, store, code) => {

    if (!url) {
      return ""
    }

    let aux = url;
    
    aux = aux.replace("{tienda_virtual}", store);
    aux = aux.replace("{productCode}", code);

    return aux;
  };

  const selectShareMessage = (request) => {
    if (!dataSharing || !socialArray) {
      return "";
    }

    if (request == "phone") {
      const sharedDataPhoneText = socialArray && dataSharing?.title == "Ofertas y Promos"
          ? `${
              multiLangJSONParse()["share.message.oferts.features.parse.one"]
            }${dataSharing?.data?.type}%0A${
              dataSharing?.data?.badgeType == "Oferta" ||
              dataSharing?.data?.badgeType == "Oferta + Regalo" ||
              dataSharing?.data?.badgeType == "Justcoins" ||
              dataSharing?.data?.badgeType == "Descuento + Justcoins"
                ? `*${
                    dataSharing?.data?.badgeType == "Justcoins"
                      ? dataSharing?.data?.title?.trim()
                      : dataSharing?.data?.productName?.trim()
                  }*%0A*${returnValuesDiscountPhone(
                    dataSharing?.data?.discountPrice
                  )}`
                : ``
            }${
              dataSharing?.data?.badgeType == "Regalo" ||
              dataSharing?.data?.badgeType == "Justcoins" ||
              dataSharing?.data?.badgeType == "Oferta + Regalo" ||
              dataSharing?.data?.badgeType == "Descuento + Justcoins"
                ? `${
                    dataSharing?.data?.badgeType == "Justcoins"
                      ? ""
                      : `*${dataSharing?.data?.title.trim()}*%0A`
                  }*${
                    dataSharing?.data?.badgeType == "Justcoins" ||
                    dataSharing?.data?.badgeType == "Descuento + Justcoins"
                      ? multiLangJSONParse()[
                          "share.message.oferts.features.parse.justcoins"
                        ]
                      : multiLangJSONParse()[
                          "share.message.oferts.features.parse.gift"
                        ]
                  }*%0A${
                    dataSharing?.data?.giftProductName
                      ? dataSharing?.data?.giftProductName
                      : " "
                  }%0A%0A`
                : ""
            }${
              multiLangJSONParse()["share.message.oferts.features.parse.two"]
            }%0A${replaceElementsURL(
              dataSharing?.data?.imageBaseURL,
              dataSharing?.data?.saleShareImage?.id,
              dataSharing?.data?.saleShareImage?.filename_download
            )}${
              socialArray && (socialArray[configExist("tienda_virtual")]?.value && !(dataSharing?.data?.productCode == null || dataSharing?.data?.productCode == "") )
                ? `%0A%0A*${
                    multiLangJSONParse()[
                      "share.message.oferts.features.parse.three"
                    ]
                  }*%0A${
                    replaceUrlProduct(dataSharing?.data?.productURL, socialArray[configExist("tienda_virtual")]?.value, dataSharing?.data?.productCode)}`
                : ""
            } `
          : `${dataSharing?.data?.sharedText?.replaceAll(" ", "%20")}%0A%0A${
              dataSharing?.data?.iPaperLink
            }`;

      return sharedDataPhoneText;
    } else {
      const sharedData =
    dataSharing?.title == "Ofertas y Promos"
      ? `${multiLangJSONParse()["share.message.oferts.features.parse.one"]}${
          dataSharing?.data?.type
        }\n${
          dataSharing?.data?.badgeType == "Oferta" ||
          dataSharing?.data?.badgeType == "Justcoins" ||
          dataSharing?.data?.badgeType == "Oferta + Regalo" ||
          dataSharing?.data?.badgeType == "Descuento + Justcoins"
            ? `*${
                dataSharing?.data?.badgeType == "Justcoins"
                  ? dataSharing?.data?.title?.trim()
                  : dataSharing?.data?.productName?.trim()
              }*\n*${returnValuesDiscountWeb(dataSharing?.data?.discountPrice)}`
            : ``
        }${
          dataSharing?.data?.badgeType == "Regalo" ||
          dataSharing?.data?.badgeType == "Justcoins" ||
          dataSharing?.data?.badgeType == "Oferta + Regalo" ||
          dataSharing?.data?.badgeType == "Descuento + Justcoins"
            ? `*${dataSharing?.data?.title?.trim()}*\n*${
                dataSharing?.data?.badgeType == "Justcoins" ||
                dataSharing?.data?.badgeType == "Descuento + Justcoins"
                  ? multiLangJSONParse()[
                      "share.message.oferts.features.parse.justcoins"
                    ]
                  : multiLangJSONParse()[
                      "share.message.oferts.features.parse.gift"
                    ]
              }*\n${
                dataSharing?.data?.giftProductName
                  ? dataSharing?.data?.giftProductName
                  : " "
              }\n\n`
            : ""
        }${
          multiLangJSONParse()["share.message.oferts.features.parse.two"]
        }\n${replaceElementsURL(
          dataSharing?.data?.imageBaseURL,
          dataSharing?.data?.saleShareImage?.id,
          dataSharing?.data?.saleShareImage?.filename_download
        )}${
          socialArray && (socialArray[configExist("tienda_virtual")]?.value && !(dataSharing?.data?.productCode == null || dataSharing?.data?.productCode == "") )
            ? `\n\n*${
                multiLangJSONParse()[
                  "share.message.oferts.features.parse.three"
                ]
              }*\n${
                replaceUrlProduct(dataSharing?.data?.productURL, socialArray[configExist("tienda_virtual")]?.value, dataSharing?.data?.productCode)
              }`
            : ""
        } `
      : `${dataSharing?.data?.sharedText?.replaceAll(" ", "%20")}\n\n${
          dataSharing?.data?.iPaperLink
        }`;

        return sharedData;

    }
  };

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(selectShareMessage("mail"));
      setToggler();
      open();
    } catch (error) {
      unsecuredCopyToClipboard(selectShareMessage("mail"));
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      // open();
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <div style={{ zIndex: 5 }} className="position-absolute w-100 h-100 ">
      <div
        ref={modalOutSideClickRef}
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "556px" }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex justify-content-between m-3 p-4">
                <div className="d-flex">
                  <h5 className="museo-sans-500 s20 m-0">
                    {dataSharing?.title}
                  </h5>
                </div>
                <div
                  className="pointer"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <SVGCross />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className=" p-4 d-flex  mt-0 gap-3">
                {configExist("email") !== -1 && (
                  <a
                    href={`mailto:${
                      socialArray[configExist("email")].value
                    }?&subject=Te Comparto?&body=${encodeURIComponent(
                      selectShareMessage("mail")
                    )}`}
                    
                    target="_blank"
                    className="ms-2 bronceStroke grayColor cancelBootstrap"
                  >
                    <div
                      style={{ alignItems: "center" }}
                      className=" pointer d-flex flex-column justify-content-center"
                    >
                      <div
                        style={{
                          width: "64px",
                          height: "64px",
                          alignItems: "center",
                        }}
                        className=" bubble-social rounded-circle d-flex justify-content-center"
                      >
                        <SVGMail />
                      </div>
                      <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                        <FormattedMessage id="social.sharedata.link.mail" />
                      </p>
                    </div>
                  </a>
                )}
                {configExist("whatsapp") !== -1 && (
                  <a
                    href={`https://wa.me/?text=${selectShareMessage("phone")}`}
                    target="_blank"
                    className="ms-2 bronceStroke grayColor cancelBootstrap"
                  >
                    <div
                      style={{ alignItems: "center" }}
                      className=" pointer d-flex flex-column justify-content-center"
                    >
                      <div
                        style={{
                          width: "64px",
                          height: "64px",
                          alignItems: "center",
                        }}
                        className=" bubble-social rounded-circle d-flex justify-content-center"
                      >
                        <SVGWhatsap />
                      </div>
                      <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                        <FormattedMessage id="social.sharedata.link.wap" />
                      </p>
                    </div>
                  </a>
                )}
                {configExist("messenger") !== -1 && (
                  <a
                    href={`https://m.me/?text=${selectShareMessage("phone")}`}
                    target="_blank"
                    className="ms-2 bronceStroke bronceFill grayColor cancelBootstrap"
                  >
                    <div
                      style={{ alignItems: "center" }}
                      className=" pointer d-flex flex-column justify-content-center"
                    >
                      <div
                        style={{
                          width: "64px",
                          height: "64px",
                          alignItems: "center",
                        }}
                        className=" bubble-social rounded-circle d-flex justify-content-center"
                      >
                        <SVGMessenger />
                      </div>
                      <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                        <FormattedMessage id="social.sharedata.link.msg" />
                      </p>
                    </div>
                  </a>
                )}
                <></>

                <div
                  onClick={doThis}
                  style={{ alignItems: "center" }}
                  className=" grayColor pointer d-flex flex-column justify-content-center"
                >
                  <div
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{
                      width: "64px",
                      height: "64px",
                      alignItems: "center",
                    }}
                    className=" bubble-social rounded-circle d-flex justify-content-center"
                  >
                    <SVGCopy />
                  </div>
                  <p className=" w-100 museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                    <FormattedMessage id="social.sharedata.link.copydata" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
