import { createAction } from '@reduxjs/toolkit';


const notificationId = createAction('notificationId');
const notificationDetail = createAction('notificationDetail');
const addSlider = createAction('addSlider');
const setCurrentWeek = createAction('setCurrentWeek');
const activeErrorNotification = createAction('activeErrorNotification');
const activeErrorCalendar = createAction('activeErrorCalendar');

export  {notificationId, notificationDetail, addSlider, setCurrentWeek, activeErrorNotification, activeErrorCalendar  } 