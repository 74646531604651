import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchInvitationTable } from "../../Framework/redux/InvitationsSideEffect";
import InvitationsListOrder from "../components/InvitationsListOrder";
import InvitationsListTable from "../components/InvitationsListTable";

export default function InvitationsList() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const tableData = useSelector(
    (state) => state.invitationData.invitationTable
  );

  const [orderBy, setOrderBy] = useState({
    offSet: 0,
    order: "inviteDate",
    dir: "desc",
  }); // inviteDate.desc
  const [list, setList] = useState([]);
  const [isVisible, setIsVisible] = useState();

  const onStartUp = async () => {
    const response = await dispatch(fetchInvitationTable(orderBy));
    onResetList(response.payload.invitations);
  };
  const onNewInvite = () => {
    history("/dashboard/invitations/add");
  };
  const onChangeOrder = async (newOrder) => {
    const response = await dispatch(fetchInvitationTable(newOrder));
    onResetList(response.payload.invitations);
  };
  const onSetNewOffset = (value) => {
    setOrderBy({ ...orderBy, offSet: value });
  };
  const onSetNewOrder = (value) => {
    setOrderBy({ ...orderBy, offSet: 0, order: value });
    const newOrder = { ...orderBy, offSet: 0, order: value };
    onChangeOrder(newOrder);
  };
  const onSetNewDir = (value) => {
    setOrderBy({ ...orderBy, offSet: 0, dir: value });
    const newOrder = { ...orderBy, offSet: 0, dir: value };
    onChangeOrder(newOrder);
  };
  const onResetList = (newList) => {
    setList(newList);
  };
  const onExtraDataList = (newList) => {
    let auxList = [...list];
    newList.forEach((element) => {
      auxList.push(element);
    });
    setList(auxList);
  };
  const setIsVisibleChild = (value) => {
    setIsVisible(value);
  };

  useEffect(() => {
    onStartUp();
  }, []);

  return (
      <div className=" w-100">
        <div
          style={{ marginBottom: "21px" }}
          className="w-100 d-flex justify-content-end mt-4 gap-3 "
        >
          <div></div>
          {tableData?.loader ? (
            <>
              <div
                style={{ width: "204px", height: "40px" }}
                className="lightui1-shimmer rounded-3"
              >
                <p className=" invisible">.</p>
              </div>
            </>
          ) : (
            <>
              {list?.length > 0 && (
                <div
                  onClick={onNewInvite}
                  className=" pointer bg-white position-relative whiteBg rounded-3 gap-2 skyBorder justify-content-center align-items-center d-flex s16 museo-sans-700 s16 skyColor  "
                  style={{ width: "204px", height: "40px" }}
                >
                  <span style={{ top: -2 }} className="s26 position-relative ">
                    +
                  </span>
                  <FormattedMessage id="invitation.pop.up.create.title.msg" />
                </div>
              )}
            </>
          )}
          {list?.length > 0 && (
            <InvitationsListOrder
              onChange={onChangeOrder}
              loading={tableData?.loader}
              order={orderBy.order}
              dir={orderBy.dir}
              onSetNewDir={onSetNewDir}
              onSetNewOrder={onSetNewOrder}
            />
          )}
        </div>
        <div className="w-100">
          <>
            <InvitationsListTable
              isVisible={isVisible}
              setIsVisible={setIsVisibleChild}
              onExtraDataList={onExtraDataList}
              loading={tableData.loader}
              onSetNewOffset={onSetNewOffset}
              orderBy={orderBy}
              offSet={orderBy?.offSet}
              data={list}
            />
          </>
        </div>
      </div>
  );
}
