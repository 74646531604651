import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SVGCalendar } from "../../../common/svgs/ic-new-edb.svg";
import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGClock } from "../../../common/svgs/ic-clock-black-md.svg";
import { ReactComponent as SVGHome } from "../../../common/svgs/ic-edb-presencial-sm.svg";
import { ReactComponent as SVGPC } from "../../../common/svgs/ic-edb-virtual-sm.svg";
import { fetchEDBDetails } from "../../../EDBs/Framework/redux/EDBDetailsSideEffect";

import ShareGetDate from "../../../common/Share/ShareGetDate";
import ShareNameMonthLong from "../../../common/Share/ShareNameMonthLong";

export default function CalendarModalDetails() {
  const eventInfo = useSelector((state) => state.calendarData.events);
  const id = useSelector((state) => state.calendarData.eventId);
  const data = useSelector((state) => state.edbDetailsData.data);

  const dispatch = useDispatch();
  const history = useNavigate();

  const configExist = (auxId) => {
    let resolve = 0;

    if (eventInfo.length > 0) {
      resolve = eventInfo.map((elm) => elm.relatedData).indexOf(auxId);
    }
    return resolve;
  };

  const sortColors = (color) => {
  const colorMap = {
    completed: { color: "#16BE7D" },
    warning: { color: "#DCB632" },
    risk: { color: "#F06E69" },
    normal: undefined,
  };
  
  return colorMap[color];
};

  const onStartUp = async (id) => {
    if (id) {
      await dispatch(fetchEDBDetails(id));
    }
  };

  const onClickNavigate = () => {
    history(
      `/dashboard/edbs/details/${data && data.edbState}/${
        id && eventInfo[configExist(id)]?.relatedData
      }`
    );
  };

  const endTime = (data, addedTime) => {
    let date = new Date(data);
    date.setMinutes(date.getMinutes() + addedTime);

    let auxHours = String(date.getHours()).padStart(2, "0");
    let auxMinutes = String(date.getMinutes()).padStart(2, "0");

    return `${auxHours}:${auxMinutes}`;
};

const setDate = (data) => {
  if (!data) {
    return;
  }

  let date = new Date(data);
  let dayName = date.toLocaleString('default', { weekday: 'long' });
  let auxDay = date.getDate();
  let auxMonth = date.getMonth() + 1;

  return (
    <p className=" museo-sans-500 grayColor s14">
      {dayName} {auxDay} de {ShareNameMonthLong(auxMonth)}, {String(date.getHours()).padStart(2, "0")}:
      {String(date.getMinutes()).padStart(2, "0")} - {endTime(data, eventInfo[configExist(id)]?.durationInMinutes)}hs
    </p>
  );
};


  useEffect(() => {
    let mapResolvedRelateddData = eventInfo.find(elm => elm.relatedData == id)

    id && onStartUp(mapResolvedRelateddData?.relatedData);
  }, [id]);


  return (
    <div>
    <div
      style={{ borderRadius: "15px 15px 0px 0px" }}
      className=" brightGrayBgOpacity20 border-0 d-flex justify-content-between px-5 py-4"
    >
      <div className="d-flex flex-column">
        <div className="d-flex">
          <SVGCalendar />
          <h5 className="museo-sans-500 s20 m-0 px-2">
            <>
              {data ? (
                <>{data.title}</>
              ) : (
                <>
                  {eventInfo[configExist(id)]?.description
                    ? eventInfo[configExist(id)]?.description
                    : "Susana Rodríguez"}
                </>
              )}
            </>
          </h5>
        </div>
        <div className=" d-flex gap-2 py-2">
          <p className=" museo-sans-500 grayColor s12">
            ID {eventInfo[configExist(id)]?.relatedData}
          </p>
          <p className=" museo-sans-500 s12 grayColor">|</p>
          <p className="s12 museo-sans-500 text-uppercase grayColor">
            <>
              {data ? (
                data ? (
                  ShareGetDate(data.date)
                ) : (
                  "21 DE FEBRERO — 14:00HS"
                )
              ) : (
                <>
                  {id
                    ? eventInfo?.length > 0 &&
                      setDate(eventInfo[configExist(id)]?.date)
                    : "21 DE FEBRERO — 14:00HS"}
                </>
              )}
            </>
          </p>
        </div>
      </div>
      <div
        data-bs-dismiss="modal"
        aria-label="Close"
        style={{ alignItems: "center" }}
        className=" pointer d-flex "
      >
        <SVGClose />
      </div>
    </div>
      <div className="px-5 museo-sans-500 ">
        <div
          style={{ marginTop: "33px" }}
          className="d-flex gap-2 s12 "
        >
          <div
            style={{ width: "16px" }}
            className=" svg-force-size blackColor svg-force-path-stroke svg-force-color"
          >
            <SVGClock />
          </div>
          <div className="d-flex gap-1 museo-sans-500 s12 ">
            <p>
              <FormattedMessage id="edb.details.params.title.one" />
            </p>
            <p className=" dm-sans-bold m-0">
              {data ? data.daysToOpen : "-"}
            </p>
            <p className=" text-uppercase  ">
              <FormattedMessage id="edb.details.params.days" />
            </p>
          </div>
        </div>
        <div className="d-flex flex-column gap-2 grayColor my-4">
          <p className="  text-uppercase  s12 ">
            <FormattedMessage id="host" />
          </p>
          <p className=" s16 ">{data ? data.hostName : "-"}</p>
        </div>
        <div className="d-flex flex-column gap-2 grayColor my-4">
          <p className=" s12 text-uppercase ">
            <FormattedMessage id="status.edb.details" />
          </p>
          {data ? (
            data.confirmation === "confirmed" ? (
              data.edbState === "closed" ? (
                <p
                  style={sortColors("risk")}
                  className="  s16 museo-sans-500"
                >
                  {" "}
                  <FormattedMessage id="edb.details.params.state.three" />
                </p>
              ) : (
                <p
                  style={sortColors("completed")}
                  className="  s16 museo-sans-500"
                >
                  {" "}
                  <FormattedMessage id="edb.details.params.state.one" />
                </p>
              )
            ) : (
              <p
                style={sortColors("warning")}
                className="  s16 museo-sans-500"
              >
                {" "}
                <FormattedMessage id="edb.details.params.state.two" />
              </p>
            )
          ) : null}
        </div>
        <div className="d-flex flex-column gap-2 grayColor my-4">
          <p className="s12 text-uppercase">
            <FormattedMessage id="type.edb.details" />
          </p>

          <div className=" s16 d-flex gap-1 ">
            <p className=" text-capitalize blackColor">
              {data?.edbType}
            </p>
            <div>
              <>
                {data ? (
                  <>
                    {data.edbType === "virtual" && (
                      <div
                        style={{ fill: "black" }}
                        className=" svg-force-color blackColor d-flex "
                      >
                        <SVGPC />
                      </div>
                    )}
                    {data.edbType === "presencial" && (
                      <div
                        style={{ fill: "black" }}
                        className=" svg-force-color blackColor d-flex "
                      >
                        <SVGHome />
                      </div>
                    )}
                  </>
                ) : (
                    <div
                      style={{ fill: "black" }}
                      className=" svg-force-color blackColor d-flex "
                    >
                      <SVGHome />
                    </div>
                )}
              </>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center gap-2 my-4 py-3 w-100 ">
          <button
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{
              width: "288px",
              height: "40px",
              borderRadius: "8px",
            }}
            onClick={onClickNavigate}
            className=" museo-sans-700 s14 skyBg text-white border-0 "
          >
            <FormattedMessage id="button.edb.details" />
          </button>
          <button
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{
              width: "288px",
              height: "40px",
              borderRadius: "8px",
            }}
            className=" museo-sans-700 s14 skyBorder bg-white skyColor border-1  "
          >
            <FormattedMessage id="buttons.understood" />
          </button>
        </div>
      </div>
  </div>
  );
}