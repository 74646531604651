import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import { notificationSharingData } from "../../Framework/redux/OfertsActions";
import OfertsDiscountsHolder from "./OfertsDiscountsHolder";
import OfertsImageHolder from "./OfertsImageHolder";
import replaceElementsURL from "../../../common/Share/replaceElementURL";

export default function OfertsCardHolderBody({
  auxiliarSkeleton,
  windowWidth,
  dataArray,
  allOffers,
  acceptedOffers,
  ofertsNoFeatures,
}) {

  const dispatch = useDispatch();
  const history = useNavigate();

  const transformContentIfLongTitle = (str) => {
    if (str?.length > 60) {
      let aux = str?.slice(0, 57);
      aux = aux + "...";
      return aux;
    } else {
      return str;
    }
  };

  const transformContentIfLongTitleShorter = (str) => {
    if (str?.length > 28) {
      let aux = str?.slice(0, 20);
      aux = aux + "...";
      return aux;
    } else {
      return str;
    }
  };

  const dispatchNewInfo = (boolean) => {
    dispatch(notificationSharingData(null));
    if (boolean) {
      const payload = {
        data: dataArray,
        title: `Ofertas y Promos`,
      };
      dispatch(notificationSharingData(payload));
      const id = document.getElementById("pointer-modal-card");
      id.click();
      return;
    }

    const payload = {
      data: dataArray,
      title: `Ofertas y Promos`,
    };
    dispatch(notificationSharingData(payload));
    const id = document.getElementById("button-sharing-modal");
    id.click();
  };

  const countUpperCaseAndLowerCase = (str) => {
    let upperCaseCount = 0;
    let lowerCaseCount = 0;

    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      if (char === char.toUpperCase() && char !== char.toLowerCase()) {
        upperCaseCount++;
      } else if (char === char.toLowerCase() && char !== char.toUpperCase()) {
        lowerCaseCount++;
      }
    }

    return { upperCaseCount, lowerCaseCount };
  };
  const transformContentGift = (str) => {
    if (str?.length > 56) {
      let aux = str?.slice(0, 53);
      aux = aux + "...";
      let auxMay = aux;
      const letterCounts = countUpperCaseAndLowerCase(auxMay);
      if (letterCounts.upperCaseCount > 15) {
        return auxMay.slice(0, 41) + "...";
      } else {
        return auxMay.slice(0, 49) + "...";
      }
    } else {
      return str;
    }
  };
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const goToOfertsDashboard = () => {
    history("weeklydeals");
  };

  const switchPerValue = (type) => {
    if (type == "cliente") {
      return "Clientes";
    }
    if (type == "consultora") {
      return "Consultores";
    }

    return "";
  };

  const toggleDiscountHolder = (badgeType, discountPercentage) => {
    if (badgeType === "Regalo" || badgeType === "Justcoins" ||  badgeType === "EDBs" ||  badgeType === "X cuotas sin intereses" ||  badgeType === "Envío Gratis" || discountPercentage > 0) {
      return true;
    }
    return false;
  }

  return (
      <div
        style={{ zIndex: 2 }}
        className=" pointer position-relative d-flex flex-column museo-sans-500 h-100 "
      >
        <div
          onClick={() => dispatchNewInfo(true)}
          style={{ zIndex: 100, top: 20 }}
          className=" position-absolute h-75 w-100"
        ></div>
        {dataArray == "MORE" ? (
          <MoreOffers
            allOffers={allOffers}
            acceptedOffers={acceptedOffers}
            goTo={goToOfertsDashboard}
            ofertsNoFeatures={ofertsNoFeatures}
          />
        ) : (
          <>
            <div
              className="d-flex justify-content-center position-relative "
              style={{
                height: "141px",
                width: "288px",
                minHeight: "141px",
              }}
            >
              <div
                className=" position-absolute invisible"
                data-bs-toggle="modal"
                data-bs-target="#modalOffer"
                id="pointer-modal-card"
              ></div>
              {!auxiliarSkeleton ? (
                <>
                {
                  toggleDiscountHolder(dataArray?.badgeType, dataArray?.discountPercentage) && (
                    <OfertsDiscountsHolder
                        instalmentsNumber={dataArray?.instalmentsNumber}
                        number={dataArray?.discountPercentage}
                        status={dataArray?.badgeType}
                      />
                  )
                }
                  {dataArray?.badgeType != "Regalo" && (
                    <OfertsImageHolder
                      url={dataArray?.imageBaseURL}
                      filename={dataArray?.productImage?.filename_download}
                      id={dataArray?.productImage?.id}
                      short={true}
                    />
                  )}
                  <img
                    onClick={() => dispatchNewInfo(true)}
                    style={{
                      borderRadius: "16px 16px 0px 0px",
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                      objectPosition: "top",
                    }}
                    className="pointer brightGrayBg lightui1-shimmer"
                    src={
                      dataArray?.saleImage != undefined &&
                      `${replaceElementsURL(
                        dataArray?.imageBaseURL,
                        dataArray?.saleImage?.id,
                        dataArray?.saleImage?.filename_download
                      )}`
                    }
                  />
                  <ApplyToOverImage switchPerValue={switchPerValue} dataArray={dataArray} />
                </>
              ) : (
                <>
                  <div
                    style={{
                      borderRadius: "16px 16px 0px 0px",
                      objectFit: "cover",
                      height: "141px",
                      border: "5px #DDDDDD solid",
                      width: "288px",
                      objectPosition: "top",
                    }}
                    className=" brightGrayBg lightui1-shimmer"
                  ></div>
                </>
              )}
            </div>
            <div
              style={{
                zIndex: 2,
                borderBottomLeftRadius: "16px",
                borderBottomRightRadius: "16px",
                boxShadow: "0px 8px 15px -10px rgba(0, 0, 0, 0.2)",
              }}
              className={` cards-shadow position-relative d-flex h-100 justify-content-between bg-white flex-column ${
                windowWidth <= 1280 ? "px-3 py-4" : "p-4"
              } `}
            >
              <div
                style={{ height: "120px" }}
                className=" d-flex flex-column justify-content-between"
              >
                <div className="  mx-2">
                  <h5
                    className=" grayColor pb-1 museo-sans-500 s12 text-uppercase"
                    style={{
                      marginBottom: "0px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {transformContentIfLongTitle(dataArray?.type)}
                  </h5>
                  <p className=" museo-sans-700 s16">{dataArray?.title}</p>
                </div>
                <div className=" mb-4 d-flex flex-column mx-2 ">
                  {dataArray?.badgeType != "Regalo" ? (
                    <>
                      <p className=" s16 museo-sans-700 ">
                        {transformContentIfLongTitleShorter(
                          dataArray?.productName
                        )}
                      </p>
                    </>
                  ) : (
                    <>
                      <p className=" grayColor s16 museo-sans-500 ">
                        {transformContentGift(dataArray?.description)}
                      </p>
                    </>
                  )}
                  {dataArray?.badgeType != "Regalo" && (
                    <div className=" dm-sans-bold align-items-center  d-flex">
                      <p className=" s20 pe-2 me-1">
                        {dataArray?.discountPrice && (
                          <>
                            {formatAmount(
                              +dataArray?.discountPrice,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </>
                        )}
                      </p>
                      <p className=" s12 grayColor text-decoration-line-through">
                        {dataArray?.regularPrice && (
                          <>
                            {formatAmount(
                              +dataArray?.regularPrice,
                              cultureInfo?.languageCode,
                              cultureInfo?.defaultCurrencyCode
                            )}
                          </>
                        )}
                      </p>
                    </div>
                  )}
                </div>
                <div
                  className=" position-absolute invisible"
                  id="button-sharing-modal"
                  data-bs-toggle="modal"
                  data-bs-target="#modalNotiSharing"
                ></div>
              </div>
              <div className=" mx-2" style={{ height: "40px" }}>
                <>
                  <button
                    style={{ width: "224px", height: "40px" }}
                    className="p-2 skyColor bg-white skyBorder museo-sans-700 s14 rounded-3 w-100 "
                    onClick={() => {
                      dispatchNewInfo();
                    }}
                  >
                    <p>
                      <FormattedMessage id="oferts.share.general.button" />{" "}
                      <span className=" text-capitalize">
                        {switchPerValue(dataArray?.applyTo)}
                      </span>
                    </p>
                  </button>
                </>
              </div>
            </div>{" "}
          </>
        )}
        <UnderCards />
      </div>
  );
}

export function MoreOffers({
  goTo,
  ofertsNoFeatures,
}) {
  return (
    <>
      <div
        style={{ zIndex: 101, borderRadius: "16px" }}
        className=" cards-shadow bg-white w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-2"
      >
        <div className=" pb-2">
          <div
            style={{ width: "48px", height: "48px", borderRadius: "50%" }}
            className=" grayBg d-flex justify-content-center align-items-center gap-1 "
          >
            <div
              style={{ width: "6px", height: "6px", borderRadius: "50%" }}
              className=" bg-white"
            ></div>
            <div
              style={{ width: "6px", height: "6px", borderRadius: "50%" }}
              className=" bg-white"
            ></div>
            <div
              style={{ width: "6px", height: "6px", borderRadius: "50%" }}
              className=" bg-white"
            ></div>
          </div>
        </div>
        <p className=" s16 museo-sans-700">
          {" "}
          <span> +{ofertsNoFeatures?.length} </span>{" "}
          <FormattedMessage id="dashboard.weekly.deals" />
        </p>
        <p onClick={goTo} className=" pointer museo-sans-700 s14 skyColor">
          {" "}
          <FormattedMessage id="view.more" />{" "}
        </p>
      </div>
    </>
  );
}

export function UnderCards(params) {
  return (
    <>
      <div
        style={{
          width: "95%",
          height: "100px",
          zIndex: 1,
          borderRadius: "16px",
          bottom: "-8px",
          left: "2%",
        }}
        className=" bg-white cards-shadow position-absolute"
      ></div>{" "}
      <div
        style={{
          width: "85%",
          height: "100px",
          zIndex: 0,
          borderRadius: "16px",
          bottom: "-18px",
          left: "6%",
        }}
        className=" bg-white cards-shadow position-absolute"
      ></div>
    </>
  );
}

export function ApplyToOverImage({ switchPerValue, dataArray }) {
  const changeBackground = (applyTo) => {
    let object = {
      height: "20px",
      width: "115px",
      borderRadius: "40px",
      top: "0",
    };

    if (applyTo == "consultora") {
      object.background = "#5AAFF1";
      return object;
    }

    object.background = "#456ECE";
    return object;
  };

  return (
    <div
      style={{ top: 130, zIndex: 10 }}
      className=" w-100 d-flex justify-content-center  position-absolute"
    >
      <div
        style={changeBackground(dataArray?.applyTo)}
        className=" position-relative text-white px-2 museo-sans-500 s12 text-center"
      >
        <p style={{ top: 2 }} className=" position-relative ">
          <FormattedMessage id="for" />{" "}
          <span className=" text-capitalize">
            {" "}
            {switchPerValue(dataArray?.applyTo)}{" "}
          </span>
        </p>
      </div>
    </div>
  );
}
