import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { useMediaPredicate } from "react-media-hook";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";

import { ReactComponent as SVGChevArrowDown } from "../../../common/svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGTrash } from "../../../common/svgs/ic-trash-sm.svg";
import { ReactComponent as SVGExternal } from "../../../common/svgs/ic-external-link-sm.svg";
import { ReactComponent as VirtualStoreIcon } from "../../../common/svgs/ic-tienda-virtual-blue-md.svg";
import { ReactComponent as SVGEDB } from "../../../common/svgs/clients/edb.svg";
import { ReactComponent as SVGAnf } from "../../../common/svgs/clients/anfitriona.svg";
import { ReactComponent as SVGOrden } from "../../../common/svgs/clients/ordenes.svg";
import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import { useNavigate } from "react-router-dom";

export default function ClientListDetailsHeader({
  buttonData,
  loading,
  data,
  socialArray,
}) {
  const smallerThan1024 = useMediaPredicate("(min-width: 1100px)");
  const [toggleMoreActions, setToggleMoreActions] = useState(false);

  const isFormalized = localStorage.getItem("invitationToggle");

  const toggleData = () => {
    setToggleMoreActions(!toggleMoreActions);
  };

  return (
    <div className=" w-100 d-flex  justify-content-between ">
      <div className="d-flex position-relative">
        <ClientListDetailsHeaderData
          loading={loading}
          data={data}
          socialArray={socialArray}
          s1={smallerThan1024}
        />
        <div className=" position-absolute invisible">
          {buttonData?.data?.links.map((elm) => {
            return (
              <a
                id={`${elm.id}`}
                target="_blank"
                href={elm.link}
                className=" invisible"
              >invisible content</a>
            );
          })}
        </div>
      </div>
      <div className=" position-relative d-flex gap-4">
        <div
          onClick={toggleData}
          style={{ width: "184px", height: "40px", borderRadius: "8px", zIndex: 2 }}
          className=" position-relative d-flex border-1 gap-2 pointer museo-sans-700 s14 button-hover-light-text skyColor skyBorder align-items-center justify-content-center "
        >
          <p>
            <FormattedMessage id="invitation.details.more.actions" />
          </p>
          <div
            style={{ height: "16px", width: "16px", top: -3 }}
            className=" position-relative svg-force-size"
          >
            <SVGChevArrowDown />
          </div>
        </div>
        {toggleMoreActions && (
          <div
            style={{ top: 60, right: 2, width: "288px", zIndex: 2 }}
            className=" p-3 rounded-16 shadow bg-white position-absolute"
          >
            <ClientAddMore index={1} data={buttonData.data} />
            <hr />
            <ClientAddMore index={3} data={buttonData.data} />
            {
             ( ShareCheckEnvKey("INVITATIONS") && isFormalized == "true") && (
                <>
                  <hr />
                  <ClientAddMore clientData={data} index={5} data={buttonData.data} />
                </>
              )
            }
            
            {data?.contactOriginType != "PERSONAL_SITE" && (
              <>
                <hr />
                <ClientAddMore index={4} data={buttonData.data} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export function SVGInvitation() {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <path d="M12.9976 12.001C13.8506 12.0012 14.6846 11.7484 15.394 11.2746C16.1034 10.8008 16.6563 10.1273 16.9829 9.33924C17.3095 8.55116 17.3951 7.68388 17.2288 6.84718C17.0625 6.01047 16.6518 5.2419 16.0486 4.63862C15.4455 4.03534 14.677 3.62445 13.8403 3.45796C13.0037 3.29146 12.1364 3.37678 11.3483 3.70319C10.5601 4.02961 9.88644 4.58247 9.41248 5.29176C8.93851 6.00105 8.68555 6.83492 8.68555 7.688C8.68555 8.8317 9.1398 9.92862 9.94843 10.7374C10.7571 11.5463 11.8539 12.0007 12.9976 12.001Z" fill="#8889DB"/>
  <path d="M16.1627 12.7207H15.8207C14.9438 13.1544 13.9786 13.3799 13.0002 13.3799C12.0219 13.3799 11.0567 13.1544 10.1797 12.7207H9.83771C8.5796 12.721 7.37314 13.2209 6.48352 14.1105C5.59391 15.0001 5.09402 16.2066 5.09375 17.4647V18.6507C5.09375 18.9103 5.1449 19.1673 5.24426 19.4071C5.34363 19.6469 5.48926 19.8648 5.67285 20.0483C5.85645 20.2318 6.07442 20.3773 6.31427 20.4766C6.55412 20.5758 6.81116 20.6268 7.07074 20.6267H18.9297C19.4541 20.6267 19.9569 20.4184 20.3277 20.0477C20.6985 19.6769 20.9067 19.1741 20.9067 18.6497V17.4637C20.9059 16.2058 20.4058 14.9998 19.5163 14.1104C18.6268 13.2211 17.4206 12.7212 16.1627 12.7207Z" fill="#8889DB"/>
  <path d="M5.5783 10.0244H1.5" stroke="#8889DB" stroke-width="2" stroke-linecap="round"/>
  <path d="M3.54004 12.0981L3.54004 7.9541" stroke="#8889DB" stroke-width="2" stroke-linecap="round"/>
</svg>
  )
}

export function ClientAddMore({ index, buttonData, clientData }) {
  const history = useNavigate();
  
  const configExist = (auxId) => {
    let resolve = 0;
    if (clientData?.socialNetworks) {
      resolve = clientData?.socialNetworks.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const onClick = (index, buttonData) => {
    if (index == 5) {
      localStorage.setItem("clientData", JSON.stringify(clientData));
      localStorage.setItem("clientDataEmail", JSON.stringify(clientData?.socialNetworks[configExist("email")]?.value));
      history(`/dashboard/invitations/add`);
      return "";
    }
    if (index != 1 || buttonData?.length > 1) {
      return "";
    }


    const clickMe = document.getElementById("create_order");
    clickMe.click();
  };

  const data = {
    text: {
      3: "client.details.modal.host.title",
      2: "buttons.invite.edb",
      1: "buttons.post.order",
      4: "notification.modal.delete.title.one",
      5: "Invitar a incorporarse",
    },
    icon: { 1: <SVGOrden />, 2: <SVGEDB />, 3: <SVGAnf />, 4: <SVGTrash />, 5: <SVGInvitation /> },
    externalLink: { 1: true, 2: true, 3: !ShareCheckEnvKey("CREATE_EDB"), 4: false, 5: !ShareCheckEnvKey("INVITACIONES") },
    modalActive: { 1: false, 2: false, 3: true, 4: true, 5: false },
    modalData: {
      id: { 1: "", 2: "", 3: "#modalHosting", 4: "#modalDelete", 5: "" },
    },
    onClickActive: { 1: true, 2: true, 3: false, 4: false, 5: true },
    onClick: () => onClick(index, buttonData),
  };

  return (
    <div
      style={{ height: "40px" }}
      onClick={data.onClickActive[index] ? data.onClick : ""}
      className=" museo-sans-500 position-relative pointer d-flex justify-content-between align-items-center"
    >
      {data.modalActive[index] && (
        <div
          data-bs-toggle="modal"
          data-bs-target={data.modalData.id[index]}
          className=" position-absolute h-100 w-100"
        ></div>
      )}
      <div className="d-flex gap-2 justify-content-center align-items-center">
        <div
          style={{ width: "24px", height: "24px" }}
          className=" svg-force-size"
        >
          {data.icon[index]}
        </div>
        <p className=" s14">
          <FormattedMessage id={data.text[index]} />
        </p>
      </div>
      <div
        style={{ width: "16px", height: "16px" }}
        className=" svg-force-size"
      >
        {data.externalLink[index] ? <SVGExternal /> : ""}
      </div>
    </div>
  );
}

export function ClientListDetailsHeaderData({ loading, data, s1 }) {
  return (
    <>
      <div style={{ top: -80 }} className=" position-absolute">
        <div
          style={{
            width: "160px",
            height: "160px",
            borderRadius: "50%",
            border: "1.5px white solid",
          }}
          className=" d-flex justify-content-center align-items-center "
        >
          {loading ? (
            <ImageCircleHandler
              size={"42px"}
              width={"148px"}
              height={"148px"}
              top={2}
            />
          ) : (
            <ImageCircleHandler
              size={"42px"}
              width={"148px"}
              height={"148px"}
              firstName={data?.firstName}
              lastName={data?.lastName}
              top={2}
            />
          )}
        </div>
      </div>
      <div style={{ width: "160px" }} className=" position-relative"></div>
      <div style={{ marginLeft: "20px" }} className=" d-flex flex-column gap-2">
        {loading ? (
          <p
            style={{ width: "200px" }}
            className=" rounded-5 lightui1-shimmer s30 museo-sans-700 text-captalize "
          >
            <span className="invisible">aaaaaa</span>
          </p>
        ) : (
          <div
            style={{ top: 0, width: !s1 ? "470px" : "680px" }}
            className=" position-absolute"
          >
            <p className=" d-flex flex-wrap s30 museo-sans-700 text-captalize ">
              {data?.firstName} {data?.lastName}
            </p>
            {data?.contactOriginType == "PERSONAL_SITE" && (
              <div
                style={{ width: "370px", height: "32px" }}
                className="d-flex justify-content-center align-items-center rounded-5 blueColor backgroundShallowBlue gap-1  "
              >
                <p className=" s14 museo-sans-500">
                  <FormattedMessage id="client.details.header.data.no.edit.message" />
                </p>
                <p className="s14 museo-sans-500" >
                / <FormattedMessage id="client.details.edb.origin.type.three" />
                </p>
                <div
                  style={{ width: "16px", height: "16px", top: -6 }}
                  className=" position-relative svg-force-size svg-force-color blueColor "
                >
                  <VirtualStoreIcon />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
