import React from 'react'

import './Configuration.css'

import DistributionAgreementContent from '../../../termsAndConditions/DistributionAgreementContent'

export default function DistributionAgreement() {
  return (
    <>
        <div className='position-absolute shadow-effect-config'></div>
        <div className="position-relative h-100 overflow-auto remove-scrollbar config-section-container">
            <DistributionAgreementContent showTitle={true}/>
        </div>
    </>
    
  )
}
