import FirstStepsModalBeta from "../../../FirstSteps/Presentation/components/modals/FirstStepsModalBeta";
import FirstStepsModalCardDetail from "../../../FirstSteps/Presentation/components/modals/FirstStepsModalCardDetail";
import FirstStepsModalGuidelinesA from "../../../FirstSteps/Presentation/components/modals/FirstStepsModalGuidelinesA";
import FirstStepsModalGuidelinesB from "../../../FirstSteps/Presentation/components/modals/FirstStepsModalGuidelinesB";
import FirstStepsModalGuidelinesNoKit from "../../../FirstSteps/Presentation/components/modals/FirstStepsModalGuidelinesNoKit";
import FirstStepsModalHelpOne from "../../../FirstSteps/Presentation/components/modals/FirstStepsModalHelpOne";
import FirstStepsModalHelpTwo from "../../../FirstSteps/Presentation/components/modals/FirstStepsModalHelpTwo";
import DashboardEarningGapModal from "./Modals/DashboardEarningGapModal";
import DashboardFirstStepsModal from "./Modals/DashboardFirstStepsModal";
import DashboardFirstStepsVideo from "./Modals/DashboardFirstStepsVIdeo";
import DashboardKeyModal from "./Modals/DashboardKeyModal";
import DashboardMemberDetailsLeadersModal from "./Modals/DashboardMemberDetailsLeadersModal";
import DashboardMemberDetailsModal from "./Modals/DashboardMemberDetailsModal";
import DashboardPeriodSalesModal from "./Modals/DashboardPeriodSalesModal";
import DashboardQualificactionLiderModal from "./Modals/DashboardQualificactionLiderModal";
import DashboardQualificationCompleted from "./Modals/DashboardQualificationCompleted";
import DashboardQualificationFirstGenModal from "./Modals/DashboardQualificationFirstGenModal";
import DashboardQualificationModal from "./Modals/DashboardQualificationModal";
import DashboardQualificationPotentialLeaderModal from "./Modals/DashboardQualificationPotentialLeaderModal";
import DashboardOffersShare from "../components/DashboardOffersShare";
import DashboardOfferShareNotice from "../components/DashboardOfferShareNoticeModal";
import DashboardOfferShareNoticeModal from "../components/DashboardOfferShareNoticeModal";
import DashboardOfferShareNoticeConsultantModal from "../components/DashboardOfferShareNoticeConsultantModal";
import DashboardOfferShareNoticeClientModal from "../components/DashboardOfferShareNoticeClientModal";
import QualifiedGroupsModal from "../../../Performance/Presentation/components/QualifiedGroupsModal";
import OfertsModalImageCard from "../../../Oferts&Features/Presentation/components/modals/OfertsModalImageCard";
import OfertsModalSharing from "../../../Oferts&Features/Presentation/components/modals/OfertsModalSharing";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";

export default function DashboardModalContainer(params) {
  return (
    <>
      <DashboardQualificationModal />
      <DashboardQualificationFirstGenModal />
      <DashboardMemberDetailsLeadersModal />
      <DashboardMemberDetailsModal />
      <DashboardQualificationCompleted />
      <DashboardPeriodSalesModal />
      <FirstStepsModalCardDetail />
      <ShareModalStructure
        id={"modalFirstStepsGuidelinesA"}
        maxWidth={"807px"}
        maxHeight={"640px"}
      >
        <FirstStepsModalGuidelinesA />
      </ShareModalStructure>
      <FirstStepsModalGuidelinesB />
      <DashboardEarningGapModal />
      <DashboardKeyModal scoreData={params.scoreData?.generalDataArray} />
      <FirstStepsModalGuidelinesNoKit />

      <ShareModalStructure
        id={"modalInfo"}
        maxWidth={"610px"}
        maxHeight={"439px"}
      >
        <DashboardFirstStepsModal />
      </ShareModalStructure>

      <DashboardQualificactionLiderModal />
      <DashboardQualificationPotentialLeaderModal />

      <DashboardFirstStepsVideo activateFirstLogin={params.activateFirstLogin} />

      <FirstStepsModalBeta />

      <FirstStepsModalHelpOne />
      <ShareModalStructure
        id={"modalFirstOne"}
        maxWidth={"608px"}
        maxHeight={"608px"}
      >
        <FirstStepsModalHelpTwo />
      </ShareModalStructure>

      <DashboardOffersShare
        toggle={params.offersModalInfo}
        socialArray={params.profileData.userInfo.socialNetwork}
        user={params.profileData.userInfo}
        open={params.onOpenCopyModal}
      />
      <DashboardOfferShareNotice
        toggle={params.offersModalInfo}
        socialArray={params.profileData.userInfo.socialNetwork}
        user={params.profileData.userInfo}
        open={params.onOpenCopyModal}
        noticeConsultant={params.noticeConsultant}
        noticeClient={params.noticeClient}
        notiLider={params.noticeLider}
      />
      <QualifiedGroupsModal
        cantidadNecesaria={params.qualifiedInfo?.totalNeed}
        rol={params.profileData?.userInfo?.roleDescription}
      />
      <OfertsModalImageCard
        modalRef={params.modalRef}
        onOpenCopyModal={params.onOpenCopyModal}
        element={params.offersInfoModal}
      />
      <OfertsModalSharing open={params.onOpenCopyModal} />
      <DashboardOfferShareNoticeModal
        auxElement={params.shareNoticeInfo}
        socialArray={params.profileData.userInfo.socialNetwork}
        user={params.profileData.userInfo}
      />
      <DashboardOfferShareNoticeConsultantModal
        auxElement={params.shareNoticeInfo}
        socialArray={params.profileData.userInfo.socialNetwork}
        user={params.profileData.userInfo}
      />
      <DashboardOfferShareNoticeClientModal
        auxElement={params.shareNoticeInfo}
        socialArray={params.profileData.userInfo.socialNetwork}
        user={params.profileData.userInfo}
      />
    </>
  );
}
