import React from "react";
import { useMediaPredicate } from "react-media-hook";

export default function ContinueButton({
  continueFunction,
  whereTo,
  disabled,
  text,
}) {
  const biggerThan992 = useMediaPredicate("(min-width: 992px)");
  return (
    <button
      type="submit"
      className={`${
        biggerThan992 && "me-4"
      } btn btn-primary museo-sans-700 s14 ${disabled && "disabled"}`}
      onClick={
        whereTo ? () => continueFunction(whereTo) : () => continueFunction()
      }
    >
      {text}
    </button>
  );
}
