
import { FormattedMessage } from "react-intl";
import {ReactComponent as SVGCheck} from "../../../common/svgs/ic-check-circle.svg"

export default function BusinessPlanCardLevelActual({ onClick, level, actualLevel, array}) {

  const selectedCard = {background: "#ECF5FE", borderRadius: "16px", boxShadow: "3px 3px 15px #A4A4D733", border: " 1px solid #D3EAFF", color: "#5AAFF1"};
  const notSelectedCard = { borderRadius: "16px", color: "#908F9A"};

    return (
        <>
            <div
          style={{ width: "24px" }}
          className="py-1 me-3 d-flex gap-1 flex-column align-items-center"
        >
          <div >
            <SVGCheck />
          </div>
          {
            actualLevel !== "8" &&
            <div className=" h-100">
              <div
                style={{ width: "3px" }}
                className=" h-100 brightGrayBg "
              ></div>
            </div>

          }
        </div>
        <div className=" bp--card-width d-flex flex-column" style={{ justifySelf: "left" }} >
            <div  onClick={() => onClick(actualLevel)} style={level === actualLevel || !level ? selectedCard : notSelectedCard } className={`pointer mb-4 museo-sans-500 hover-on-mouse d-flex flex-column gap-3 bp--card-p`}>
                <p className=" s12 grayColor" > <FormattedMessage id="business.plan.card.actual.title" ></FormattedMessage></p>
                {
                  array.map((level) => {
                    if (level.id == parseInt(actualLevel)) {
                      return <p key={level?.id} className="s14">
                        {level.desc}
                      </p>
                    }
                  })
                }
            </div>
        </div>
        </>
    )
}