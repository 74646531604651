import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { patchPeriodId } from "./earningActions";

import { fetchEarningsCard, fetchHistoryEarning, fetchHistoryPeriods, fetchHistoryEarningByPeriod, fetchEarningsCardPotentialLeader } from "./earningSideEffects";

const initialState = { 
    data: {
        
        potentialEarnings: 689483,
        currencyCode: "MXN",
        byPersonalSales: {
            amount: 78030,
            status: "risk",
            earningPercentage: 40,
            totalSale: 195072,
            instantEarnings: {
            amount: 48768,
            percentage: 25
            },
            personalSaleBond: {
            amount: 9753,
            percentage: 5,
            statistics: {
                totalAmount: 8940,
                status: "risk"
            }
            },
            leaderGroupBond: {
            amount: 19507,
            percentage: 10
            }
        },
        byPersonalIncorporation: {
            amount: 66802,
            totalSale: 195072,
            percentage: 25,
            statistics: {
            incorporatedNeed: 3,
            incorporatedWithSales: 2,
            totalIncorporated: 15,
            incorporatedStatus: "risk"
            }
        },
        byPersonalGroup: {
            amount: 11470,
            status: "risk",
            personalIncorporated: {
            amount: 66802,
            totalSale: 195072,
            percentage: 8
            },
            restOfGroup: {
            amount: 48588,
            totalSale: 195072,
            percentage: 4
            }
        },
        byDescendant: {
            amount: 339893,
            status: "risk",
            firstGeneration: {
            personalIncorporated: {
                amount: 66802,
                totalSale: 195072,
                percentage: 9
            },
            restOfGroup: {
                amount: 48588,
                totalSale: 195072,
                percentage: 5
            }
            },
            secondGeneration: {
            amount: 48588,
            totalSale: 195072,
            percentage: 3
            },
            thirdGeneration: {
            amount: 48588,
            totalSale: 195072,
            percentage: 2
            }
        },
        bondExecutiveDirector: {
            amount: 160000,
            status: "risk"
        }  
    },
    dataPotentialLeader: null,
    periodActual: null,
    historyPeriods: null,
    historyEarning: null,
    isLoading: null
}

const earningReducer = createReducer(initialState, (builder) => {
    builder

        .addCase(fetchEarningsCardPotentialLeader.fulfilled, (state, action) => {
            state.dataPotentialLeader = action.payload;
        })
        
        .addCase(fetchEarningsCard.fulfilled, (state, action) => {
            state.data = action.payload;
            state.isLoading = false;
        })

        .addCase(patchPeriodId, (state, action) => {
            state.periodActual = action.payload;
        })

        .addCase(fetchHistoryEarning.fulfilled, (state, action) => {
            state.historyEarning = action.payload;
            state.isLoading = false;
        })

        .addCase(fetchHistoryEarningByPeriod.fulfilled , ( state, action) => {
            state.historyEarning = action.payload;
            state.isLoading = false;
        })

        .addCase(fetchHistoryPeriods.fulfilled, (state, action) => {
            state.historyPeriods = action.payload.earningsByPeriod;
            state.periodActual = action.payload.earningsByPeriod[0].period;
            state.isLoading = false;
        })

        .addMatcher(
      
            isAnyOf(
              
                fetchEarningsCard.pending,
                fetchHistoryEarning.pending,
                fetchHistoryEarningByPeriod.pending
            ),
            (state, action) => {
              state.isLoading = true;
            }
          )
          .addMatcher(
            isAnyOf(
                fetchEarningsCard.rejected,
                fetchHistoryEarning.rejected,
                fetchHistoryEarningByPeriod.rejected
            ),
            (state, action) => {
              state.isLoading = false;
            }
          );

        
})

export default earningReducer;