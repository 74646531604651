import { FormattedMessage } from "react-intl";

export default function DistributionAgreementContentCO(params) {
    return (
        <>
          <p className={` museo-sans-500 s16 mb-4 ${!params.showTitle && "mt-4"}`}>
            <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
            <u>
              <FormattedMessage id="distribution.agreement.text.contract.parts.2" />
            </u>
            <FormattedMessage id="distribution.agreement.text.contract.parts.3" />
            <u>
              <FormattedMessage id="distribution.agreement.text.contract.parts.4" />
            </u>
            <FormattedMessage id="distribution.agreement.text.contract.parts.5" />
            <u>
              <FormattedMessage id="distribution.agreement.text.contract.parts.6" />
            </u>
            <FormattedMessage id="distribution.agreement.text.contract.parts.7" />
            <u>
              <FormattedMessage id="distribution.agreement.text.contract.parts.8" />
            </u>
            <FormattedMessage id="distribution.agreement.text.contract.parts.9" />
          </p>
          <p className="mb-2 d-flex justify-content-center museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.swiss.1" />
            </b>
          </p>
          <p className=" museo-sans-500 s16">
            <b>1)</b>
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.2" />
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.solicitant.1" />
            </b>
            ”). &nbsp; <b>2)</b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.2" />
            &nbsp; <b>3)</b>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.3" />
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.solicitant.4" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.5" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>4)</b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
          </p>
          <p className="d-flex justify-content-center">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
            </b>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
            </u>

            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.14" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.15" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.16" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.17" />
            <a href="http://www.swissjustamerica.com/co/es/" target="_blank">
              http://www.swissjustamerica.com/co/es
            </a>
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.18" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.19" />
          </p>

          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.20" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.21" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.22" />
            </u>

            <FormattedMessage id="distribution.agreement.text.declarations.clauses.23" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.24" />
            </u>

            <FormattedMessage id="distribution.agreement.text.declarations.clauses.25" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.26" />
            </u>

            <FormattedMessage id="distribution.agreement.text.declarations.clauses.27" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.28" />
            </u>

            <FormattedMessage id="distribution.agreement.text.declarations.clauses.29" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <u>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.30" />
            </u>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.31" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.32" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.33" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.34" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.35" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.36" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.37" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.39" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.40" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.41" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.42" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.43" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.44" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.45" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.46" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.47" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.48" />
          </p>
          <p className="invisible mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="ethics.code.text.immigration.laws.1" />
              </u>
            </b>
            <FormattedMessage id="ethics.code.text.immigration.laws.1" />
          </p>
        </>
      );
}