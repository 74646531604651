import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { activeErrorsNotis, settingDetails } from "./FirstStepActions";
import { getNoticeClients, getNoticeConsultant, getNoticeLiders, getOffersData } from "./dashboardSideEffect";
import { resetOffersAndNotices } from "../../../EDBs/Framework/redux/EDBsActions";

// Change varibales names if need be.
const initialState = {
  groupArrayOne: [],
  groupArrayTwo: [],
  elementDetails: null,
  loader: false,
  offer: {},
  notiLider: {},
  notiClient: {},
  notiConsultant: {},
  notiErrors: false,
  offertsErrors: false,
};

const FirstStepsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(settingDetails, (state, action) => {
      state.elementDetails = action.payload;
      state.loader = false;
    })
    .addCase(getOffersData.fulfilled, (state,action) => {
      state.offer = action.payload;
      state.offertsErrors = false;
    })
    .addCase(getOffersData.rejected, (state,action) => {
      state.offertsErrors = true;
    })
    .addCase(activeErrorsNotis, (state, action) => {
      state.notiErrors = true;
      state.offertsErrors = true;
    } )
    .addCase(getNoticeLiders.fulfilled, (state,action) => {
      state.notiLider = action.payload;
      state.notiErrors = false
    })
    .addCase(getNoticeClients.fulfilled, (state,action) => {
      state.notiClient = action.payload;
      state.notiErrors = false
    })
    .addCase(getNoticeConsultant.fulfilled, (state,action) => {
      state.notiConsultant = action.payload;
      state.notiErrors = false
    })
    .addCase(resetOffersAndNotices,(state,action)=> {
      state.notiClient = {};
      state.notiConsultant = {};
      state.notiLider = {};
      state.offer = {};
    })

    .addMatcher(
      isAnyOf(
        getNoticeLiders.rejected,
        getNoticeClients.rejected,
        getNoticeConsultant.rejected,
      ),
      (state, action) => {
        state.notiErrors = true;
      }
    )
});

export default FirstStepsReducer;
