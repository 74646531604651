import { useState, useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import { FormattedMessage } from 'react-intl';
import { useSelector,useDispatch } from 'react-redux';

import {ReactComponent as HistoryIcon} from '../../../common/svgs/ic-history-sm.svg'

import PersonalSalesRow from './PersonalSalesRow';
import PersonalSalesEmpty from './PersonalSalesEmpty';
import { calendarCurrentPeriod } from '../../../Calendar/Framework/redux/CalendarSideEffects';
import PersonalSalesWeekSalesModal from './PersonalSalesWeekSalesModal';
import PerformanceLoadingMoreRequests from './PerformanceLoadingMoreRequests';
import { useNavigate, useParams } from 'react-router-dom';
import { changePeriodForButton } from '../../Framework/redux/performanceActions';
import ShareKeyGenerator from '../../../common/Share/ShareKeyGenerator';

export default function PersonalSalesRegisters() {

    const dispatch = useDispatch()

    const [loader, setLoader] = useState(true);
    const [totalLength, setTotalLength] = useState(0);
    const [filterRegisters, setFilterRegisters] = useState(1);

    const history = useNavigate();
    
    const smallerThan1280 = useMediaPredicate("(max-width: 1280px)");

    const unsendedArray = useSelector(state => state.performanceData.unsendedOrders)
    const pendingArray = useSelector(state => state.performanceData.pendingOrders)
    const billedArray = useSelector(state => state.performanceData.billedOrders)
    const loadingOrders = useSelector(state => state.performanceData.ordersLoader)
    const weekSalesInfo = useSelector(state => state.performanceData.salesByWeek)
    const currentPeriodInfo = useSelector(state => state.calendarData.currentPeriodInfo)
    const periodHistory = useSelector(state => state.performanceData.historicPeriods)
    const headerData = useSelector((state) => state?.headerData);

    const params = useParams();

    const current = currentPeriodInfo?.periodWeeks.filter((element) => {
        return element.week === currentPeriodInfo?.currentWeek
    })

    let currentWeekForModal
    let unsendedLength = unsendedArray?.length
    let pendingLength = pendingArray?.length
    let billedLength = billedArray?.length

    useEffect(() => {
        dispatch(calendarCurrentPeriod());
    }, [])
    
    useEffect(() => {
        onArrayChanges();
    }, [unsendedLength, pendingLength, billedLength])
    
    if(current !== undefined){
        currentWeekForModal = current[0]?.id
    }

    let periodForSearh = headerData.period?.currentYearPeriod;
 
   for (let i = 0; i < periodHistory?.length; i++) {
     if (periodHistory[i].period === parseInt(params.currentPeriod)) {
         periodForSearh = periodHistory[i].yearPeriod;
       return;
     }
   }
    
    const onArrayChanges = async () => {
        if (unsendedLength == undefined || pendingLength == undefined || billedLength == undefined) {
            return;
        }
        setLoader(true);
        setTotalLength(0) 
        if(unsendedLength != null  || pendingLength != null || billedLength != null){       
            let suma = unsendedLength + pendingLength + billedLength
            setTotalLength(suma) 
        }

        if(!loadingOrders){
            setFilterRegisters(1)
        } else {
            setFilterRegisters(0)
        };

        setLoader(false);

        return "done"
    };
    
    if(periodHistory !== null){ // ?
        dispatch(changePeriodForButton(periodForSearh))
    };
    const togglerValue = () => {      
        history(`period/${currentPeriodInfo?.currentPeriod}`)
    };
    
  return (
    <div className='container--padding--card-holder' >
        <div>
            
        <PersonalSalesWeekSalesModal semanaActual={currentWeekForModal} dataVentasSemanales={weekSalesInfo}/>
        <div className=' w-100 d-flex justify-content-between'>
            <div className="d-flex flex-wrap justify-items-center align-items-center">
                <button onClick={() => setFilterRegisters(1)} className={`${filterRegisters === 1 ? "blueBg bronceColor" : "bronceBg blueColor"} ${totalLength > 0 ? "blueBorder personal-sales-filter-hover" : " pointer-events-none brightGrayBorder brightGrayColor"} rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.all" defaultMessage="Todos" /> ({totalLength ? totalLength : 0})</button>
                <button onClick={() => setFilterRegisters(2)} className={`${filterRegisters === 2 ? "blueBg bronceColor" : "bronceBg blueColor"} ${billedLength > 0 ? "blueBorder personal-sales-filter-hover" : " pointer-events-none brightGrayBorder bronceBg brightGrayColor"}  rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.bill" defaultMessage="Facturados" /> ({billedLength ? billedLength : 0})</button>
                <button onClick={() => setFilterRegisters(3)} className={`${filterRegisters === 3 ? "blueBg bronceColor" : "bronceBg blueColor"} ${pendingLength > 0 ? "blueBorder personal-sales-filter-hover" : " pointer-events-none brightGrayBorder bronceBg brightGrayColor"}  rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.pending" defaultMessage="A facturar" /> ({pendingLength ? pendingLength : 0})</button>
                <button onClick={() => setFilterRegisters(4)} className={`${filterRegisters === 4 ? "blueBg bronceColor" : "bronceBg blueColor"} ${unsendedLength > 0 ? "blueBorder personal-sales-filter-hover" : " pointer-events-none brightGrayBorder bronceBg brightGrayColor"}  rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.request" defaultMessage="Sin enviar" /> ({unsendedLength ? unsendedLength : 0})</button>
            </div>
            <div className='d-flex align-items-center'>
                <button data-bs-toggle="modal"
                                data-bs-target="#weekSalesModal"
                    className={`${smallerThan1280 ? "ps-2 pe-2 me-3" : "ps-4 pe-4 me-4"} museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}>
                        <FormattedMessage id="performance.week.detail.button" defaultMessage="Detalle semana" />
                </button>
                <button 
                    onClick={togglerValue}
                    className={`${smallerThan1280 ? "ps-2 pe-2 me-3" : "ps-4 pe-4 me-4"} d-flex justify-content-evenly align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}>
                        <HistoryIcon />
                        <p className='ms-1'><FormattedMessage id="personal.sales.history" defaultMessage="Ver historia" /></p>
                </button>
            </div>
        </div>
        <div className=" w-100 d-flex s12 museo-sans-500 grayColor justify-content-between align-items-center row-height mb-2">
            <div className="personal-sales-order">
                <FormattedMessage id="personal.sales.header.row.order" defaultMessage="ORDEN/PEDIDO NRO." />
            </div>
            <div className="personal-sales-state">
                <FormattedMessage id="personal.sales.header.row.state" defaultMessage="ESTADO" />
            </div>
            <div className="personal-sales-date">
                <FormattedMessage id="personal.sales.header.row.date" defaultMessage="FECHA" />
            </div>
            <div className="personal-sales-type">
                <FormattedMessage id="personal.sales.header.row.type" defaultMessage="TIPO" />
            </div>
            <div className="personal-sales-total d-flex justify-content-end">
                <FormattedMessage id="personal.sales.header.row.total" defaultMessage="TOTAL" />
            </div>
            <div className="personal-sales-chevron"></div>
        </div>
        {
            loader ?
            <>  <div className=' w-100 position-relative' >

                <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
            </div>
            </>
            :
            <>
                <div className=' w-100 position-relative'>
                    {
                        filterRegisters === 0 ?
                            loader ?
                                <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                            :
                                null
                        : filterRegisters === 1 ?
                            loader ? 
                                <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                            :
                                unsendedLength > 0 || pendingLength > 0 || billedLength > 0 ?
                                    <>
                                        {unsendedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={1} />})}
                                        {pendingArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={2} />})}
                                        {billedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={3} />})}
                                    </>
                                :
                                    <div className="d-flex justify-content-center align-items-center mt-5">
                                        <PersonalSalesEmpty
                                            title="Sin pedidos ni órdenes"
                                            description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                                        ></PersonalSalesEmpty>
                                    </div>
                        : filterRegisters === 2 ?
                            loader ?
                                <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                            :
                            billedLength > 0 ?
                                <>
                                    {billedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={3} />})}
                                </>
                            :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <PersonalSalesEmpty
                                        title="Sin pedidos ni órdenes"
                                        description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                                    ></PersonalSalesEmpty>
                                </div>
                        : filterRegisters === 3 ?
                            loader ?
                                <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                            :
                            pendingLength > 0 ?
                                <>
                                    {pendingArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={2} />})}
                                </>
                            :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <PersonalSalesEmpty
                                        title="Sin pedidos ni órdenes"
                                        description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                                    ></PersonalSalesEmpty>
                                </div> 
                        : filterRegisters === 4 ?
                            loader ?
                                <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                            :
                            unsendedLength > 0 ?
                                <>
                                    {unsendedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={1} />})}
                                </>
                            :
                                <div className="d-flex justify-content-center align-items-center mt-5">
                                    <PersonalSalesEmpty
                                        title="Sin pedidos ni órdenes"
                                        description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                                    ></PersonalSalesEmpty>
                                </div>
                        : null
                    }
                </div>
            </>
        }
        </div>
    </div>
    
  )
}
