import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Red } from "../../../common/svgs/ic-alert-circle-red.svg";

import { ReactComponent as Yellow } from "../../../common/svgs/ic-alert-circle-yellow.svg";

import { ReactComponent as Green } from "../../../common/svgs/ic-check-circle-sm.svg";

import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";

import { ReactComponent as Grey } from "../../../common/svgs/ic-alert-circle-grey.svg";

import PerformanceSocialIcons from "./PerformanceSocialIcons";
import {
  changePersonalGroupName,
  clearInsideArray,
  pushInsideArray,
} from "../../../Descendants/Framework/redux/DescendantsAction";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export default function GroupIncorpDescendantUserTooltip({
  toggler,
  userInfo,
  top,
  tooltipRef,
  tooltipPosition
}) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [perfData, setPerfData] = useState(undefined);

  const onProfileDsc = ({ id, name }) => {
    dispatch(changePersonalGroupName({ name: name, clientId: id }));

    history(`/dashboard/descendants/profile/${id}`);
  };

  const onProfileAsc = ({ id, name, uplineId, uplineName }) => {
    dispatch(changePersonalGroupName({ name: name, clientId: id }));
    dispatch(pushInsideArray({ clientId: uplineId, name: uplineName }));

    history(`/dashboard/descendants/profile/asc/${uplineId}`);
  };

  dispatch(clearInsideArray());

  async function getCheckMarkStatus() {
    if (userInfo) {
      setPerfData("normal");
      return;
    } else {
    }
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getCheckMarkStatus();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);


  function displayIcons() {
    if (userInfo?.profileResume?.socialNetwork) {
      return (
        <PerformanceSocialIcons
          key={ShareKeyGenerator()}
          socialArray={userInfo?.profileResume?.socialNetwork}
        />
      );
    } else {
      return;
    }
  }

  return (
    <div style={{top: top || "70px"}} ref={tooltipRef} className="user-tooltip">
      <div className="rounded-4 overflow-hidden bronceBg user-tooltip-content">
        <div className="d-flex justify-content-center position-relative blueTwoBg user-tooltip-header">
          <ImageCircleHandler
            width={"72px"}
            height={"72px"}
            size={"24px"}
            url={userInfo?.profileResume.image}
            firstName={userInfo?.profileResume?.firstName}
            name={userInfo?.name}
            styles={{ position: "absolute" }}
            lastName={userInfo?.profileResume?.lastName}
            top={32}
          />
        </div>
        <div className="ps-4 pe-4 bronceBg user-tooltip-body">
          <div>
            <div className="text-center">
              <p className="museo-sans-700 s16 me-2 performance-toltip-inline-container blackColor">
                {userInfo?.profileResume?.lastName +
                  " " +
                  userInfo?.profileResume?.firstName}
              </p>
              <div className="performance-svg-size performance-toltip-inline-container">
                {perfData?.consultantStatus?.checkMarkStatus === "reduced" && (
                  <Purple />
                )}
                {perfData?.consultantStatus?.checkMarkStatus === "risk" && (
                  <Red />
                )}
                {perfData?.consultantStatus?.checkMarkStatus ===
                  "completed" && <Green />}
                {perfData?.consultantStatus?.checkMarkStatus === "gap" && (
                  <Grey />
                )}
                {perfData?.consultantStatus?.checkMarkStatus === "warning" && (
                  <Yellow />
                )}
              </div>
            </div>
            <p className="museo-sans-500 s14 grayColor text-center mt-1">
              <ReformatLevelByGender
                gender={userInfo?.profileResume?.gender}
                level={userInfo?.profileResume?.level}
                roleDescriptionDefault={
                  userInfo?.profileResume?.roleDescription
                }
              />
            </p>
            <div
              onClick={() =>
                onProfileAsc({
                  id: userInfo?.clientId,
                  name: userInfo?.profileResume?.name,
                  uplineId: userInfo?.profileResume?.uplineId,
                  uplineName: userInfo?.profileResume?.uplineName,
                })
              }
              className=" pointer mt-3 text-center"
            >
              <p className="museo-sans-500 s14 grayColor performance-toltip-inline-container">
                <FormattedMessage
                  id="performance.user.tooltip.ascendant"
                  defaultMessage="Ascendente: "
                />
              </p>
              <p className="museo-sans-700 s14 skyColor performance-toltip-inline-container">
                {userInfo?.profileResume?.uplineName}
              </p>
            </div>
          </div>
          <hr></hr>
          <div className="d-flex flex-column align-items-center">
            <div className="d-flex justify-content-evenly user-tooltip-social-container">
              {displayIcons()}
            </div>
            <button
              onClick={() =>
                onProfileDsc({
                  id: userInfo?.clientId,
                  name: userInfo?.profileResume?.name,
                })
              }
              className="w-100 mb-3 mt-4 museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-bootstrap-radius button-padding"
            >
              <FormattedMessage
                id="performance.user.tooltip.view.profile"
                defaultMessage="Ver perfil"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
