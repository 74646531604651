import { useState, useEffect, useRef } from "react";

import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

import Loader from "../../../common/Share/Loader";
import { useOnClickOutside } from "../../../common/Share/ShareFunctionUseOnClickOutside";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

import {ReactComponent as NoOrdersIcon} from '../../../common/svgs/Empty-Pedidos-Ordenes.svg'
import PersonalSalesOtherConsultantPeriod from "./PersonalSalesOtherConsultantPeriod";
import PersonalSalesPeriod from "./PersonalSalesPeriod";

export default function PersonalSalesPeriodPopUp({ toggle, toggler, valueInButton, other }) {
  
  const refNot = useRef();

  const [controller , setController] = useState(0);

  const historyPeriods = useSelector((state) => state.performanceData.historicPeriods);

  const loading = useSelector((state) => state.headerData.loader);

  useOnClickOutside(refNot, () => toggler(false));

  const togglerAfterClick = () => {
    toggler(false);
  }

  const changeController = (int) => {
    setController(int);
  }

  useEffect(() => {
    historyPeriods && changeController(historyPeriods[0].id);
  }, [])

  return toggle ? (
    <div className=" bg-white personal-sales-popup remove-scrollbar" style={{overflowY: "scroll"}}>
            <ul
                id="header--notfication-scroll"
                style={{maxHeight: "460px"}}
                className=" m-0 list-unstyled h-100 w-100"
            >
        
                <Loader loading={loading} />
                {/*<div className=" d-flex position-absolute shadow-effect" style={{left: "0px"}}></div>*/}
                
                {historyPeriods?.length > 0 ? (
                historyPeriods.map((x) => {
                    return (
                    <>
                        {" "}
                        {
                          other ?
                          <>
                            <PersonalSalesOtherConsultantPeriod key={ShareKeyGenerator()}  data={x} valueInButton={valueInButton} toggler={togglerAfterClick} controller={controller} changeController={changeController} />
                          </>
                          :
                          <>
                            <PersonalSalesPeriod key={ShareKeyGenerator()} data={x} valueInButton={valueInButton} toggler={togglerAfterClick} />{" "}

                          </>
                        }
                    </>
                    );
                })
                ) : (
                <div
                    style={{ alignItems: "center" }}
                    className=" h-100 d-flex justify-content-center"
                >
                    <div
                    style={{ alignItems: "center" }}
                    className=" pointer d-flex justify-content-center flex-column gap-2 "
                    >
                    <div
                        style={{ width: "92px", height: "92px", alignItems: "center" }}
                        className="  d-flex brightBlueBg rounded-circle justify-content-center  "
                    >
                        <NoOrdersIcon />
                    </div>
                    <p className=" p-0 museo-sans-500 s20 ">
                        <FormattedMessage id="personal.sales.period.pop.up.no.periods" defaultMessage="Todavia no hay períodos disponibles" />
                    </p>
                    </div>
                </div>
                )}
            </ul>
        
            
        
      
    </div>
  ) : null;

}