import { useState, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";
import { FormattedMessage } from "react-intl";
import { useSelector,useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ReactComponent as ArrowLeftIcon } from "../../../common/svgs/arrow-left-sm.svg";
import { ReactComponent as ChevronDown } from "../../../common/svgs/ic-chevron-down-sm.svg";
import PersonalSalesRow from "./PersonalSalesRow";
import PersonalSalesEmpty from "./PersonalSalesEmpty";
import PersonalSalesPeriodPopUp from "./PersonalSalesPeriodPopUp";
import {
  changePeriodForButton,
  resetOrders,
} from "../../Framework/redux/performanceActions";

import {
  getUnsendedOrders,
  getPendingOrders,
  getBilledOrders,
  getHistoricPeriods,
} from "../../Framework/redux/performanceSideEffects";

import PerformanceLoadingMoreRequests from "./PerformanceLoadingMoreRequests";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export default function PersonalSalesHistory() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = useParams();
  let { consultantId } = useParams();
  let { currentPeriod } = useParams();
  const [togglePeriods, setTogglePeriods] = useState(false);
  const [loader , setLoader ] = useState(true)

  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const headerData = useSelector((state) => state?.headerData);

  const billedArray = useSelector(
    (state) => state.performanceData.billedOrders
  );
  const periodHistory = useSelector(
    (state) => state.performanceData.historicPeriods
  );
  const valueInPeriodButton = useSelector(
    (state) => state.performanceData.currentOrSelectedPeriod
  );

  const onStartUp = async () => {
    let auxiliarValue = null
    let result = await dispatch(getHistoricPeriods({consultantId: consultantId || null }));

    if ((currentPeriod && result )) {
    
      auxiliarValue = result.payload.salesByPeriod.find((element) => element.period == currentPeriod);

      await dispatch(getUnsendedOrders({yearPeriod: auxiliarValue.yearPeriod, consultantId: consultantId || null }))
      await dispatch(getPendingOrders({yearPeriod: auxiliarValue.yearPeriod, consultantId: consultantId || null }))
      await dispatch(getBilledOrders({yearPeriod: auxiliarValue.yearPeriod, consultantId: consultantId || null }))
    }

    setLoader(false);

  };

  let periodForSearh = headerData.period?.currentYearPeriod;

  useEffect(() => {
    if (periodHistory !== null) {
      params?.currentPeriod && dispatch(changePeriodForButton(params.currentPeriod));
    }
  }, [dispatch]);

  useEffect(() => {
    onStartUp();
  }, [])

   for (let i = 0; i < periodHistory?.length; i++) {
     if (periodHistory[i].period === parseInt(params.currentPeriod)) {
         periodForSearh = periodHistory[i].yearPeriod;
     }
   }
  
  const resetPersonalSales = () => {
    dispatch(resetOrders());
    dispatch(
      getUnsendedOrders({
        yearPeriod: headerData.period?.currentYearPeriod,
        consultantId: consultantId ? consultantId : null,
      })
    );
    dispatch(
      getPendingOrders({
        yearPeriod: headerData.period?.currentYearPeriod,
        consultantId: consultantId ? consultantId : null,
      })
    );
    dispatch(
      getBilledOrders({
        yearPeriod: headerData.period?.currentYearPeriod,
        consultantId: consultantId ? consultantId : null,
      })
    );
    history("/dashboard/performance/personalSales");
  };

  return (
    <div className="container--padding--card-holder">
      <div>
        <div className=" w-100 d-flex justify-content-between align-items-center">
          <div>
            <button
              className="skyColor bronceBg button-hover-svg-light-stroke button-hover-light-text museo-sans-700 s14 button-border-none d-flex align-items-center"
              onClick={resetPersonalSales}
            >
              <ArrowLeftIcon />
              <p className="ms-3">
                <FormattedMessage
                  id="personal.sales.back.button"
                  defaultMessage="Volver a todos los pedidos"
                />
              </p>
            </button>
          </div>
          <div className="d-flex position-relative">
            <button
              onClick={() => setTogglePeriods(!togglePeriods)}
              className={`${
                !biggerThan1280 ? "ps-2 pe-2 me-2" : "ps-4 pe-4 me-4"
              } d-flex justify-content-evenly align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding position-relative perf-history-week`}
            >
              <p className="ms-1">
                <FormattedMessage
                  id="personal.sales.period.button.label"
                  defaultMessage="Período"
                />{" "}
                {params?.currentPeriod}
              </p>
              <ChevronDown />
            </button>
            <PersonalSalesPeriodPopUp
              toggle={togglePeriods}
              toggler={setTogglePeriods}
              valueInButton={valueInPeriodButton}
            />
          </div>
        </div>
        <div className=" w-100 d-flex s12 museo-sans-500 grayColor justify-content-between align-items-center row-height mb-2">
          <div className="personal-sales-order">
            <FormattedMessage
              id="personal.sales.header.row.order"
              defaultMessage="ORDEN/PEDIDO NRO."
            />
          </div>
          <div className="personal-sales-state">
            <FormattedMessage
              id="personal.sales.header.row.state"
              defaultMessage="ESTADO"
            />
          </div>
          <div className="personal-sales-date">
            <FormattedMessage
              id="personal.sales.header.row.date"
              defaultMessage="FECHA"
            />
          </div>
          <div className="personal-sales-type">
            <FormattedMessage
              id="personal.sales.header.row.type"
              defaultMessage="TIPO"
            />
          </div>
          <div className="personal-sales-total d-flex justify-content-end">
            <FormattedMessage
              id="personal.sales.header.row.total"
              defaultMessage="TOTAL"
            />
          </div>
          <div className="personal-sales-chevron"></div>
        </div>
        {loader ? (
          <PerformanceLoadingMoreRequests
            text={
              <FormattedMessage
                id="personal.sales.loading.requests"
                defaultMessage="Cargando pedidos"
              />
            }
            small={false}
          />
        ) : billedArray?.length > 0 ? (
          <>
            {billedArray?.map((element) => {
              return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={3} />;
            })}
          </>
        ) : (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <PersonalSalesEmpty
              title="Sin pedidos ni órdenes"
              description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
            ></PersonalSalesEmpty>
          </div>
        )}
      </div>
    </div>
  );
}
