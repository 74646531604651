export default function SkeletonEDBDashboard({type}) {
    
    return <>
    {
        type ?
        <>  
            {
                type == "steps" ?
                    <div className="edb-card-dimensions edb-card-box-shadow edb-card-radius d-flex flex-column bronceBg" >
                            <div style={{height: "193px", borderRadius: "16px 16px 0px 0px" }} className="lightui1-shimmer w-100 brightGrayBg  " >

                            </div>
                            <div className="p-3" >
                                <div style={{height: "8px"}} className=" w-25  mt-3 rounded-3 brightGrayBg  lightui1-shimmer" >

                                </div>
                                <div style={{height: "12px"}} className=" w-100 mt-3 rounded-3 brightGrayBg  lightui1-shimmer" >

                                </div>
                                <div style={{height: "12px"}} className=" w-25 mt-3 rounded-3 brightGrayBg  lightui1-shimmer" >

                                </div>
                                <div className=" mt-4 pt-3 d-flex flex-column gap-2" >
                                    <div style={{height: "8px"}} className=" w-100 rounded-3 brightGrayBg  lightui1-shimmer" >

                                    </div>
                                    <div style={{height: "8px"}} className=" w-100 rounded-3 brightGrayBg  lightui1-shimmer" >

                                    </div>
                                    <div style={{height: "8px"}} className=" w-50   rounded-3 brightGrayBg lightui1-shimmer " >

                                    </div>
                                </div>
                                <div className=" mt-4 d-flex flex-column gap-2" >
                                    <div style={{height: "8px"}} className=" w-100 rounded-3 brightGrayBg lightui1-shimmer " >

                                    </div>
                                    <div style={{height: "8px"}} className=" w-50   rounded-3 brightGrayBg  lightui1-shimmer" >

                                    </div>
                                </div>
                            </div>
                    </div>
                :
                    <div className="edb-card-dimensions edb-card-box-shadow edb-card-radius p-4 d-flex flex-column bronceBg" >
                            <div style={{height: "8px"}} className=" rounded-3 brightGrayBg  lightui1-shimmer" >

                            </div>
                            <div style={{marginTop: "31px"}} className="d-flex" >
                                <div style={{height: "38px", width: "38px"}} className=" rounded-circle brightGrayBg  lightui1-shimmer" >

                                </div>
                                <div className="d-flex flex-column justify-content-center gap-2 px-2 " >
                                    <div style={{height: "8px", width: "120px"}} className=" rounded-3 brightGrayBg  lightui1-shimmer" >

                                    </div>
                                    <div style={{height: "8px", width: "120px"}} className=" rounded-3 brightGrayBg  lightui1-shimmer" >

                                    </div>
                                </div>

                            </div>
                            <hr></hr>
                            <div style={{height: "8px"}} className="lightui1-shimmer w-50 mt-3 rounded-3 brightGrayBg  " >

                            </div>
                            <div style={{height: "16px"}} className="lightui1-shimmer mt-3 rounded-3 brightGrayBg  " >

                            </div>
                            <div style={{height: "16px"}} className="lightui1-shimmer w-75 mt-3 rounded-3 brightGrayBg  " >

                            </div>
                            <div style={{height: "8px"}} className="lightui1-shimmer w-75 mt-3 rounded-3 brightGrayBg  " >

                            </div>
                            <div style={{height: "40px"}} className="lightui1-shimmer mt-3 rounded-3 brightGrayBg  " >

                            </div>
                    </div>
            }
        </>
        :
            <div className="edb-card-dimensions edb-card-box-shadow edb-card-radius p-4 d-flex flex-column bronceBg" >
                    <div style={{height: "8px"}} className="lightui1-shimmer rounded-3 brightGrayBg  " >

                    </div>
                    <div style={{height: "24px", marginTop: "31px"}} className="lightui1-shimmer w-75 rounded-3 brightGrayBg  " >

                    </div>
                    <div style={{height: "8px"}} className="lightui1-shimmer me-4 mt-3 rounded-3 brightGrayBg  " >

                    </div>
                    <hr></hr>
                    <div style={{height: "8px"}} className="lightui1-shimmer w-50 mt-3 rounded-3 brightGrayBg  " >

                    </div>
                    <div style={{height: "16px"}} className="lightui1-shimmer mt-3 rounded-3 brightGrayBg  " >

                    </div>
                    <div style={{height: "16px"}} className="lightui1-shimmer w-75 mt-3 rounded-3 brightGrayBg  " >

                    </div>
                    <div style={{height: "8px"}} className="lightui1-shimmer w-75 mt-3 rounded-3 brightGrayBg  " >

                    </div>
                    <div style={{height: "40px"}} className="lightui1-shimmer mt-3 rounded-3 brightGrayBg  " >

                    </div>
            </div>
    }
    </>
}