import { FormattedMessage } from "react-intl";

export default function ClientListTerritoryPerCountry(addressInfo, showAddressInfoToggler) {

    const country = localStorage.getItem("country");

    const switchValuesOnCountry = (addressInfo) => {
        if(!addressInfo) {
            return ""
        };

        switch (country) {
            case "mx":
                return [addressInfo?.street, addressInfo?.externalNumber, addressInfo?.internalNumber, addressInfo?.locality, `CP ${addressInfo?.postalCode}`, addressInfo?.municipality, addressInfo?.state, addressInfo?.reference]
            case "ar":
                return [addressInfo?.street, addressInfo?.locality,  addressInfo?.province, `CP ${addressInfo?.postalCode}`  ]
            case "cl":
                return [addressInfo?.street, addressInfo?.region, addressInfo?.comune,  addressInfo?.province]
            case "co":
                return [addressInfo?.street, addressInfo?.neighborhood,  addressInfo?.city, addressInfo?.department]
            case "cr":
                return [addressInfo?.province, addressInfo?.canton, addressInfo?.district, addressInfo?.signs, addressInfo?.street]
            case "pa":
                return [addressInfo?.street, addressInfo?.signs, addressInfo?.reference, addressInfo?.district, addressInfo?.province,  addressInfo?.township]
            case "pe":
                return [addressInfo?.street, addressInfo?.district, addressInfo?.city,  addressInfo?.department, addressInfo?.signs]
        
        
            default:
                return [addressInfo?.street, addressInfo?.reference, addressInfo?.department, addressInfo?.locality, addressInfo?.neighborhood]
        };
    };

    const showAddressInfo = (addressInfo) => {

        return(
            <>
            {
                addressInfo?.length > 0 && (
                
                <div className=" museo-sans-500">
              <p
                className=" text-uppercase grayColor s12"
                style={{ letterSpacing: "0.72px" }}
              >
                <FormattedMessage id="DIRECCIÓN" />
              </p>
              <div id="" className="d-flex flex-column gap-1">
                {country == "mx" && (
                  <>
                    <p className=" s16">
                      {addressInfo[0]?.street}{" "}
                      {addressInfo[0]?.internalNumber}{" "}
                      {addressInfo[0]?.externalNumber}
                    </p>
                    <p className=" s16">
                      {addressInfo[0]?.locality}
                      {addressInfo[0]?.postalCode
                        ? " CP " + addressInfo[0].postalCode
                        : ""}
                    </p>
                    <p className=" s16">
                      {addressInfo[0]?.municipality}{" "}
                      {addressInfo[0]?.state}
                    </p>
                    <p className=" s16">{addressInfo[0]?.reference}</p>
                  </>
                )}
                {country == "cl" && (
                  <>
                    <p className=" s16">{addressInfo[0]?.street} </p>
                    <p className=" s16">{addressInfo[0]?.region}</p>
                    <p className=" s16">{addressInfo[0]?.commune}</p>
                    <p className=" s16">{addressInfo[0]?.province}</p>
                  </>
                )}
                {country == "pe" && (
                  <>
                    <p className=" s16">{addressInfo[0]?.street} </p>
                    <p className=" s16">
                      {addressInfo[0]?.district},{" "}
                      {addressInfo[0]?.city}
                    </p>
                    <p className=" s16">{addressInfo[0]?.department}</p>
                    <p className=" s16">{addressInfo[0]?.reference}</p>
                  </>
                )}
                {country == "co" && (
                  <>
                    <p className=" s16">{addressInfo[0]?.street} </p>
                    <p className=" s16">
                      {addressInfo[0]?.neighborhood},{" "}
                      {addressInfo[0]?.city}
                    </p>
                    <p className=" s16">{addressInfo[0]?.department}</p>
                  </>
                )}
                {country == "uy" && (
                  <>
                    <p className=" s16">{addressInfo[0]?.street} </p>
                    <p className=" s16">{addressInfo[0]?.signs}</p>
                    <p className=" s16">{addressInfo[0]?.department}</p>
                    <p className=" s16">{addressInfo[0]?.locality}</p>
                    <p className=" s16">{addressInfo[0]?.neighborhood}</p>
                  </>
                )}
                {country == "cr" && (
                  <>
                    <p className=" s16">
                      {addressInfo[0]?.province},{" "}
                      {addressInfo[0]?.canton},{" "}
                      {addressInfo[0]?.district}
                    </p>
                    <p className=" s16">
                      {addressInfo[0]?.street}{" "}
                      {addressInfo[0]?.signs}
                    </p>
                  </>
                )}
                {country == "pa" && (
                  <>
                    <p className=" s16">
                      {addressInfo[0]?.street}{" "}
                      {addressInfo[0]?.signs ? `${addressInfo[0]?.signs},` : ""  }{" "}
                      {addressInfo[0]?.reference ? `${addressInfo[0]?.reference},` : ""  }{" "}
                      {addressInfo[0]?.township}
                    </p>
                    <p className=" s16">
                      {addressInfo[0]?.district},{" "}
                      {addressInfo[0]?.province}
                    </p>
                  </>
                )}
                {country == "ar" && (
                  <>
                    <p className=" s16">{addressInfo[0]?.street}</p>
                    <p className=" s16">
                      {addressInfo[0]?.province},{" "}
                      {addressInfo[0]?.locality}
                    </p>
                    <p className=" s16">
                      {addressInfo[0]?.postalCode
                        ? "CP " + addressInfo[0].postalCode
                        : ""}
                    </p>
                  </>
                )}
              </div>
            </div>
                    )
            }
            </>
        )

    };
    
    return showAddressInfoToggler ? showAddressInfo(addressInfo) : switchValuesOnCountry(addressInfo[0])
}