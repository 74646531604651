import "../pages/EDBs.css";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { useMediaPredicate } from "react-media-hook";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ShareGetDate from "../../../common/Share/ShareGetDate";
import { ReactComponent as SVGTriangle } from "../../../common/svgs/ic-triangle-warning-md.svg";

export default function EDBCard({
  id,
  edbState,
  daysToClose,
  daysToOpen,
  edbStatus,
  accumulatedAmount,
  amountStatus,
  date,
  title,
  hostName,
  totalRegistered,
  registeredStatus,
}) {
  const smallerThanS2 = useMediaPredicate("(min-width: 1380px)");

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const history = useNavigate();

  function viewDetail() {
    history(`details/${edbState}/${id}`);
  }

  function edbStatusColor(status) {
    switch (status) {
      case "normal":
        return "blackColor";
      case "warning":
        return "darkYellowColor";
      case "completed":
        return "lightGreenColor";
      case "risk":
        return "redColor";
    }
  }

  return (
    <div className="edb-card-dimensions edb-card-box-shadow edb-card-radius p-4 d-flex flex-column bronceBg">
      <div className="  pb-3 border-bottom">
        {edbState === "open" ? (
          <>
            {smallerThanS2 && (
              <div
                className={`d-flex s12 align-items-center mb-2 ${edbStatusColor(
                  edbStatus
                )}`}
              >
                <i className="bi bi-clock-fill"></i>
                <p className="museo-sans-500  ms-2">
                  <FormattedMessage
                    id="edb.card.closing.in"
                    defaultMessage="El encuentro cierra en "
                  />{" "}
                  {daysToClose}{" "}
                  <FormattedMessage id="edb.card.days" defaultMessage=" días" />
                </p>
              </div>
            )}
            {!smallerThanS2 && (
              <div
                className={`d-flex s10 align-items-center mb-2 ${edbStatusColor(
                  edbStatus
                )}`}
              >
                <i className="bi bi-clock-fill"></i>
                <p className="museo-sans-500  ms-2">
                  <FormattedMessage
                    id="edb.card.closing.in"
                    defaultMessage="El encuentro cierra en "
                  />{" "}
                  {daysToClose}{" "}
                  <FormattedMessage id="edb.card.days" defaultMessage=" días" />
                </p>
              </div>
            )}
          </>
        ) : edbState ===
          "dated" /*Corregir y cambiar a dated. Closed es para probar */ ? (
          <div
            className={`d-flex s12 align-items-center mb-2 ${edbStatusColor(
              edbStatus
            )}`}
          >
            <FontAwesomeIcon icon={faCalendarDays} />
            <p className="museo-sans-500  ms-2">
              <FormattedMessage
                id="edb.card.is.in"
                defaultMessage="El encuentro es en "
              />{" "}
              {daysToOpen}{" "}
              <FormattedMessage id="edb.card.days" defaultMessage=" días" />
            </p>
          </div>
        ) : null}
        {edbState === "open" ? (
          <>
            {smallerThanS2 && (
              <>
                <div className=" d-flex align-items-center gap-2">
                  <p
                    className={`dm-sans-bold s34 ${edbStatusColor(
                      amountStatus
                    )}`}
                  >
                    {formatAmount(
                      accumulatedAmount,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}{" "}
                  </p>
                  {amountStatus === "warning" && (
                    <div>
                      <SVGTriangle />
                    </div>
                  )}
                </div>
                <p className="museo-sans-500 s14">
                  <FormattedMessage
                    id="edb.card.accumulated.sale"
                    defaultMessage="Venta acumulada"
                  />{" "}
                </p>
              </>
            )}
            {!smallerThanS2 && (
              <>
                <div>
                  <p
                    className={`dm-sans-bold s34 ${edbStatusColor(
                      amountStatus
                    )}`}
                  >
                    {" "}
                    {formatAmount(
                      accumulatedAmount,
                      cultureInfo?.languageCode,
                      cultureInfo?.defaultCurrencyCode
                    )}
                  </p>
                  {amountStatus === "warning" && (
                    <div>
                      <SVGTriangle />
                    </div>
                  )}
                </div>
                <p className="museo-sans-500 s13">
                  <FormattedMessage
                    id="edb.card.accumulated.sale"
                    defaultMessage="Venta acumulada"
                  />{" "}
                </p>
              </>
            )}
          </>
        ) : edbState === "dated" ? (
          <>
            <div
              className={`d-flex justify-content-start align-items-center ${edbStatusColor(
                registeredStatus
              )}`}
            >
              <p className="dm-sans-bold s34">{totalRegistered}</p>
              <div className="ms-2">
                <p className="museo-sans-500 s12 blackColor">
                  <FormattedMessage
                    id="edb.card.accumulated.registered"
                    defaultMessage="registradas"
                  />
                </p>
                <p className="museo-sans-500 s12 blackColor">
                  <FormattedMessage
                    id="edb.card.accumulated.totals"
                    defaultMessage="totales"
                  />
                </p>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="d-flex flex-column justify-content-between pt-4">
        <div>
          <p className="museo-sans-500 s12 grayColor">
            {date ? ShareGetDate(date) : "21 DE FEBRERO — 14:00HS"}
          </p>
          <p className="museo-sans-500 s20">{title}</p>
          <p className="museo-sans-500 s14">
            {" "}
            <FormattedMessage id="edb.card.host.label" />: {hostName}
          </p>
        </div>
      </div>
      <button
        style={{ height: "40px" }}
        id={id}
        onClick={viewDetail}
        className="mt-auto mb-2 museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-bootstrap-radius button-padding"
      >
        <FormattedMessage
          id="edb.card.details.button"
          defaultMessage="ver detalles"
        />
      </button>
    </div>
  );
}
