const body = {
    "date": "",
    "time": "",
    "edbType": "",
    "topicId": null,
    "public": true,
    "clientPhone": "",
    "hostData": {
        "hostId": 0,
        "hostName": "",
        "hostEmail": "",
        "phone": {
            "type": null,
            "area": "",
            "phone": "",
        }
    },
    "addressInfo": {
        "street": "",
        "internalNumber": "",
        "externalNumber": "",
        "municipality": "",
        "district": "",
        "department": "",
        "region": "",
        "locality": "",
        "neighborhood": "",
        "city": "",
        "commune": "",
        "state": "",
        "postalCode": "",
        "province": "",
        "canton": "",
        "township": "",
        "signs": "",
        "reference": "",
    },
    "contactEdbPhone": {
        "type": null,
        "area": "",
        "phone": "",
    },
    "conference": {
        "url": "",
        "password": "",
    },
    "streaming": [
        {
            "url": "",
        }
    ]
}

export { body }