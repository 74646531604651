import { FormattedMessage } from "react-intl"
import { ReactComponent as SVGClose} from "../svgs/ic-x-sm.svg"

export default function ModalWarning ({show, close, data}) {

    return (
    show &&
    <div style={{backgroundColor: "#FEF0F0", left: "5%", bottom: "5%",  color: "#62221F"}} className=" shadow m-3 position-absolute py-3 " id="modal--clipboard">
        <i style={{justifySelf: "center"}} className="  bi bi-x-circle"></i>
        <div>
            <p className=" museo-sans-700 s14 ">
                {
                    data &&
                        <FormattedMessage id="login.warning.pop.up.title.one" />
                }
                {
                    !data &&
                        <FormattedMessage id="login.warning.pop.up.title.two" />
                }
            </p>
            <p style={{maxWidth: "296px"}} className=" museo-sans-500 s14 ">
            {
                    data &&
                       <FormattedMessage id="login.warning.pop.up.content.one" />
                }
                {
                    !data &&
                        <FormattedMessage id="login.warning.pop.up.content.two" />
                }
            </p>
        </div>
        <div onClick={close} style={{justifySelf: "center "}} className="pointer" >
            <SVGClose />
        </div>
    </div>)
}
