import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";
import { ReactComponent as SVGVideo } from "../../../common/svgs/ic-video-sm.svg";
import EDBDetailsIconLock from "./EDBDetailsIconLock";

export default function EDBDetailsCardPlan({
  data,
  onClick,
  loading,
  cultureInfo,
}) {
  return (
    <div
      style={{ borderRadius: "15px" }}
      className="p-4 d-flex flex-column justify-content-between edb-details--cards f9GrayBorder edb-details-card-shadow "
    >
      {loading ? (
        <SkeletonEDBDetailsPlan />
      ) : (
        <>
          <div>
            <div className=" align-items-center gap-2 d-flex justify-content-between">
              <div className=" d-flex align-items-center gap-2 ">
                <p className=" s20 museo-sans-500">
                  <FormattedMessage id="edb.details.plan.title" />
                </p>
                <div
                  className="d-flex pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#modalInfoPlan"
                >
                  <HelpIconNoTarget size={"sm"} />
                </div>
              </div>
              <p className=" s20 museo-sans-500">{data ? data.hostLevel : 3}</p>
            </div>
            <div className="py-3">
              <EDBDetailsPlanHostData data={data} cultureInfo={cultureInfo} />
            </div>
            <div
              className={` ${
                data?.edbState === "closed" ? "invisible" : ""
              } d-flex flex-column gap-2 py-2  `}
            >
              <EDBDetailsPlanData data={data} cultureInfo={cultureInfo} />
              <EDBDetailsPlanProgressHolder
                data={data}
                cultureInfo={cultureInfo}
              />
            </div>
          </div>
          <div id="edb-card-links" className=" gap-2 py-1 d-flex flex-column ">
            <p
              onClick={() =>
                onClick(
                  "658693857",
                  "c5b977e224",
                  "Como utilizar las cartas para volver a venderle a un cliente"
                )
              }
              className=" museo-sans-700 pointer s14 skyColor"
            >
              Como utilizar las cartas para volver a venderle a un cliente{" "}
              <SVGVideo />
            </p>
            <p
              onClick={() =>
                onClick(
                  "659087082",
                  "c1bab1fc19",
                  "La importancia del premio de Anfitrión/a"
                )
              }
              className=" museo-sans-700 pointer s14 skyColor"
            >
              La importancia del premio de Anfitrión/a <SVGVideo />
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export function EDBDetailsPlanHostData({ data, cultureInfo }) {
  const reduceNumber = (number, percent) => {
    let value = number * (percent / 100);
    return formatAmount(
      value,
      cultureInfo?.languageCode,
      cultureInfo?.defaultCurrencyCode
    );
  };

  return (
    <>
      <div className=" d-flex">
        <p className=" s16 museo-sans-500">
          <FormattedMessage id="edb.details.params.host" />
        </p>
        <p className=" invisible">a</p>
        <p className="s16 museo-sans-700">
          {data ? data.hostName : "Graciela Márquez"}
        </p>
      </div>
      <p
        className={` ${
          data?.edbState === "closed" ? "invisible" : ""
        } grayColor s16 museo-sans-500 `}
      >
        {data ? (
          data.accumulatedAmount <
          data.hostProgressLevel[0].hostTargetAmount ? (
            <p className=" s16 darkYellowColor museo-sans-500">
              <FormattedMessage id="edb.details.params.gifts.empty" />
            </p>
          ) : data.accumulatedAmount >=
              data.hostProgressLevel[0].hostTargetAmount &&
            data.accumulatedAmount <
              data.hostProgressLevel[1].hostTargetAmount ? (
            <p className=" s16 grayColor museo-sans-500">
              <FormattedMessage id="edb.details.params.gifts" />
              {reduceNumber(
                data.accumulatedAmount,
                data.hostProgressLevel[0].percentage
              )}{" "}
            </p>
          ) : data.accumulatedAmount >=
              data.hostProgressLevel[1].hostTargetAmount &&
            data.accumulatedAmount <
              data.hostProgressLevel[2].hostTargetAmount ? (
            <p className=" s16 grayColor museo-sans-500">
              <FormattedMessage id="edb.details.params.gifts" />
              {reduceNumber(
                data.accumulatedAmount,
                data.hostProgressLevel[1].percentage
              )}{" "}
            </p>
          ) : (
            <p className=" s16  grayColor museo-sans-500">
              <FormattedMessage id="edb.details.params.gifts" />
              {reduceNumber(
                data.accumulatedAmount,
                data.hostProgressLevel[2].percentage
              )}{" "}
            </p>
          )
        ) : null}
      </p>
    </>
  );
}

export function EDBDetailsPlanData({ data, cultureInfo }) {
  const switchHostLevelData = (level, data) => {
    let targetLimitSelectorByGoni = (level, data) => {
      switch (level) {
        case 0:
          return data.hostProgressLevel[0].hostTargetAmount - 1;
        case 1:
          return data.hostProgressLevel[0].hostLevelLimitAmount; // 7999
        case 2:
          return data.hostProgressLevel[1].hostLevelLimitAmount; // 9999
        case 3:
          return data.hostProgressLevel[1].hostLevelLimitAmount + 1;
      }
    };

    return (
      <p className="dm-sans-medium grayColor">
        {formatAmount(
          targetLimitSelectorByGoni(level, data),
          cultureInfo?.languageCode,
          cultureInfo?.defaultCurrencyCode
        )}
      </p>
    );
  };

  return (
    <div className=" d-flex justify-content-between">
      <p className=" museo-sans-500 s14">
        {" "}
        {data
          ? data.hostLevel === 3
            ? null
            : "Progreso hasta Nivel " + (data.hostLevel + 1)
          : "Progreso hasta Nivel 3"}{" "}
      </p>
      <div className=" gap-2 s14 d-flex dm-sans-medium ">
        <p className=" dm-sans-medium ">
          {formatAmount(
            data?.accumulatedAmount,
            cultureInfo?.languageCode,
            cultureInfo?.defaultCurrencyCode
          )}
        </p>
        <p className=" grayColor">/</p>
        {switchHostLevelData(data?.hostLevel, data)}
      </div>
    </div>
  );
}

export function EDBDetailsPlanProgressHolder({ data, cultureInfo }) {
  const switchHostLevelProgress = (level, data) => {
    switch (level) {
      case 0:
        return (
          <>
            <ProgressBarParams
              completed={
                (data.accumulatedAmount /
                  data.hostProgressLevel[0].hostTargetAmount) *
                100
              }
              incompleted={true}
            />
            <ProgressBarParams completed={0} incompleted={false} />
            <ProgressBarParams completed={0} incompleted={false} />
          </>
        );

      case 1:
        return (
          <>
            <ProgressBarParams completed={100} incompleted={false} />
            <ProgressBarParams
              completed={
                ((data.accumulatedAmount -
                  data.hostProgressLevel[0].hostTargetAmount) /
                  (data.hostProgressLevel[1].hostTargetAmount -
                    data.hostProgressLevel[0].hostTargetAmount)) *
                100
              }
              incompleted={false}
            />
            <ProgressBarParams completed={0} incompleted={false} />
          </>
        );

      case 2:
        return (
          <>
            <ProgressBarParams completed={100} incompleted={false} />
            <ProgressBarParams completed={100} incompleted={false} />
            <ProgressBarParams
              completed={
                ((data.accumulatedAmount -
                  data.hostProgressLevel[1].hostTargetAmount) /
                  (data.hostProgressLevel[2].hostTargetAmount -
                    data.hostProgressLevel[1].hostTargetAmount)) *
                100
              }
              incompleted={false}
            />
          </>
        );

      case 3:
        return (
          <>
            <ProgressBarParams completed={100} incompleted={false} />
            <ProgressBarParams completed={100} incompleted={false} />
            <ProgressBarParams completed={100} incompleted={false} />
          </>
        );
    }
  };

  return (
    <>
      <div style={{ alignItems: "center" }} className="d-flex gap-1">
        {data ? (
          data?.accumulatedAmount <
          data?.hostProgressLevel[0]?.hostTargetAmount ? (
            <div
              style={{ width: "12px", height: "12px" }}
              className=" rounded-circle goldBg "
            ></div>
          ) : (
            <div
              style={{ width: "12px", height: "12px" }}
              className=" rounded-circle greenBg "
            ></div>
          )
        ) : null}

        <div className=" position-relative w-100 ">
          <EDBDetailsIconLock data={data} />
          <div className=" d-flex ">
            {data && <>{switchHostLevelProgress(data.hostLevel, data)}</>}
          </div>
        </div>
      </div>
    </>
  );
}

export function SkeletonEDBDetailsPlan() {
  return (
    <>
      <div className=" mt-3 d-flex flex-column ">
        <div className="d-flex flex-column gap-3 ">
          <div
            style={{ height: "16px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer invisible w-75 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-50 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "12px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
        </div>
        <div className="lightui1-shimmer mt-4 pt-3 d-flex flex-column gap-3 ">
          <div className=" d-flex ">
            <div
              style={{ height: "12px" }}
              className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
            ></div>
            <div
              style={{ height: "12px" }}
              className="lightui1-shimmer invisible w-75 rounded-3 brightGrayBg"
            ></div>
            <div
              style={{ height: "12px" }}
              className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
            ></div>
            <div style={{ width: "6px" }}></div>
            <div
              style={{ height: "12px" }}
              className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
            ></div>
          </div>
          <div className=" d-flex gap-2 ">
            <div
              className="lightui1-shimmer brightGrayBg rounded-circle "
              style={{ width: "12px", height: "12px" }}
            ></div>
            <div className=" position-relative w-100  ">
              <div
                style={{ height: "8px" }}
                className="lightui1-shimmer rounded-3 w-100 brightGrayBg "
              ></div>
              <SkeletonEDBDetailsPlanCircles />
            </div>
          </div>
        </div>
        <div className=" mt-3 d-flex flex-column justify-content-end gap-4 ">
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer invisible w-75 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
          <div
            style={{ height: "8px" }}
            className="lightui1-shimmer w-75 rounded-3 brightGrayBg"
          ></div>
        </div>
      </div>
    </>
  );
}

export function SkeletonEDBDetailsPlanCircles() {
  return (
    <>
      <div
        style={{ right: "0px", top: "-10px" }}
        className=" position-absolute d-flex flex-column gap-2  "
      >
        <div
          className="lightui1-shimmer brightGrayBg rounded-circle "
          style={{ width: "24px", height: "24px" }}
        ></div>
        <div
          style={{ width: "24px", height: "8px" }}
          className="lightui1-shimmer brightGrayBg rounded-3  "
        ></div>
      </div>
      <div
        style={{ right: "30%", top: "-10px" }}
        className=" position-absolute d-flex flex-column gap-2  "
      >
        <div
          className="lightui1-shimmer brightGrayBg rounded-circle "
          style={{ width: "24px", height: "24px" }}
        ></div>
        <div
          style={{ width: "24px", height: "8px" }}
          className="lightui1-shimmer brightGrayBg rounded-3  "
        ></div>
      </div>
      <div
        style={{ right: "60%", top: "-10px" }}
        className=" position-absolute d-flex flex-column gap-2  "
      >
        <div
          className="lightui1-shimmer brightGrayBg rounded-circle "
          style={{ width: "24px", height: "24px" }}
        ></div>
        <div
          style={{ width: "24px", height: "8px" }}
          className="lightui1-shimmer brightGrayBg rounded-3  "
        ></div>
      </div>
    </>
  );
}
