import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import { getUnsendedOrders, getPendingOrders, getBilledOrders, getHistoricPeriods, getSalesByWeek, getWeeklyGroupSales, groupSalesList, extraGroupSalesList, getWeeklyIncorporationsSales, incorporationsSalesList, extraIncorporationsSalesList, getWeeklyDescendantSales, descendantSalesList, extraDescendantSalesList, getQualifiedGroups, getPersonalSaleDetails, getPersonalSaleDetailsBills, getPersonalSaleDetailsPendings, getPersonalSaleDetailsOrders, getPersonalSaleDetailsOrdersEDB, getPersonalSaleDetailsRequest, getOtherConsultantData, getPotentialLeaders, fetchTrackingOrderId } from "./performanceSideEffects";
import { changePeriodForButton, resetOrders, upgradeGroupSalesOffset, resetGroupSalesOffset, upgradeIncorporatedSalesOffset, resetIncorporatedSalesOffset, upgradeDescendantSalesOffset, resetDescendantSalesOffset, resetFilters, changeFilters, defineFiltersApplied, resetOtherConsultantData, changeCreditNotes  } from "./performanceActions";


const initialState = {
  unsendedOrders: null,
  pendingOrders: null,
  billedOrders: null,
  ordersLoader: false,
  historicPeriods: null,
  currentOrSelectedPeriod: null,
  salesByWeek: null,

  groupSales: null,
  extraGroupSales: [],
  groupSalesLoader: false,
  extraGroupSalesLoader: false,
  groupSalesOffset: 0,

  incorporationsSales: null,
  extraIncorporationsSales:[],
  incorporationsSalesLoader: false,
  extraIncorporationsSalesLoader: false,
  incorporationsSalesOffset: 0,
  
  descendantSales: null,
  extraDescendantSales:[],
  descendantSalesLoader: false,
  extraDescendantSalesLoader: false,
  descendantSalesOffset: 0,
  
  qualifiedGroups: null,
  qualifiedGroupsLoader: false,

  filters: {
    filterType: "",
    orders: "",
    sortBy: "billed.desc",
    weeks: "",
    yearPeriod: 0
  },
  filtersApplied: false,
  orderDetailsData: null,
  orderDetailsModalData: null,
  orderDetailsHasShipment: null,


  loader: false,

  creditNotes: null,

  otherConsultantData: null,
  potentialLeadersError: false,
  potentialLeaders: null
};

const EDBDetailsReducer = createReducer(initialState, (builder) => {
  builder

  .addCase(fetchTrackingOrderId.fulfilled, (state, action) => {
    state.orderDetailsHasShipment = action.payload;
    
  })

  .addCase(changeCreditNotes, (state, action) => {
    state.creditNotes = action.payload
  })

  .addCase(changePeriodForButton, (state, action) => {
    state.currentOrSelectedPeriod = action.payload
  })

  .addCase(getPotentialLeaders.fulfilled, (state, action) => {
    state.potentialLeaders = action.payload;
    state.potentialLeadersError = false;
    state.loader = false;
  })
  
  .addCase(getPotentialLeaders.rejected, (state, action) => {
    state.potentialLeadersError = true;
    state.loader = false;
  })

  .addCase(getPersonalSaleDetailsOrders.fulfilled, (state, action) => {
    state.orderDetailsModalData = action.payload;
    state.loader = false;
  })

  .addCase(getPersonalSaleDetailsOrdersEDB.fulfilled, (state, action) => {
    state.orderDetailsModalData = action.payload;
    state.loader = false;
  })

  .addCase(getPersonalSaleDetailsBills.fulfilled, (state, action) => {
    state.orderDetailsData = action.payload;
    state.loader = false;
  })

  .addCase(getPersonalSaleDetailsRequest.fulfilled, (state, action) => {
    state.orderDetailsData = action.payload;
    state.loader = false;
  })

  .addCase(getPersonalSaleDetailsPendings.fulfilled, (state, action) => {
    state.orderDetailsData = action.payload;
    state.loader = false;
  })

  .addCase(resetOrders, (state, action) => {
    state.unsendedOrders = null
    state.pendingOrders = null
    state.billedOrders = null
  })

  .addCase(resetFilters, (state, action) => {
    state.filters = {
        filterType: "",
        orders: "",
        sortBy: "billed.desc",
        weeks: "",
        yearPeriod: 0
    }
  })

  .addCase(changeFilters, (state, action) => {
    state.filters = action.payload
  })

  .addCase(defineFiltersApplied, (state,action) => {
    state.filtersApplied = action.payload
  })

  .addCase(resetOtherConsultantData, (state, action) => {
    state.otherConsultantData = null
  })

  .addCase(getUnsendedOrders.fulfilled, (state, action) => {
    state.unsendedOrders = action.payload.requests;
    state.ordersLoader = false
  })

  .addCase(getPendingOrders.fulfilled, (state, action) => {
    state.pendingOrders = action.payload.pendings;
    state.ordersLoader = false
  })

  .addCase(getBilledOrders.fulfilled, (state, action) => {
    state.billedOrders = action.payload.bills;
    state.ordersLoader = false
  })

  .addCase(getHistoricPeriods.fulfilled, (state, action) => {
    state.historicPeriods = action.payload.salesByPeriod
  })

  .addCase(getSalesByWeek.fulfilled, (state, action) => {
    state.salesByWeek = action.payload
  })

  

  /*Ventas Grupales*/

  .addCase(upgradeGroupSalesOffset, (state, action) => {
    state.groupSalesOffset = state.groupSalesOffset + 10
  })

  .addCase(resetGroupSalesOffset, (state, action) => {
    state.groupSalesOffset = 0
  })

  .addCase(getWeeklyGroupSales.fulfilled, (state, action) => {
    state.salesByWeek = action.payload
  })

  .addCase(groupSalesList.fulfilled, (state, action) => {
    state.groupSales = action.payload.groupSales
    state.groupSalesLoader = false
  })
  .addCase(groupSalesList.pending, (state, action) => {
    state.groupSalesLoader = true
  })
  .addCase(groupSalesList.rejected, (state, action) => {
    state.groupSalesLoader = false
  })
  

  .addCase(extraGroupSalesList.fulfilled, (state, action) => {
    state.extraGroupSales = action.payload.groupSales
    state.extraGroupSalesLoader = false
    state.groupSales = [...state.groupSales, ...state.extraGroupSales]
  })
  .addCase(extraGroupSalesList.pending, (state, action) => {
    state.extraGroupSalesLoader = true
  })
  .addCase(extraGroupSalesList.rejected, (state, action) => {
    state.extraGroupSalesLoader = false
  })

  /*Ventas Incorporadas */

  .addCase(upgradeIncorporatedSalesOffset, (state, action) => {
    state.incorporationsSalesOffset = state.incorporationsSalesOffset + 10
  })

  .addCase(resetIncorporatedSalesOffset, (state, action) => {
    state.incorporationsSalesOffset = 0
  })

  .addCase(getWeeklyIncorporationsSales.fulfilled, (state, action) => {
    state.salesByWeek = action.payload
  })

  .addCase(incorporationsSalesList.fulfilled, (state, action) => {
    state.incorporationsSales = action.payload.personalIncorporationsSales
    state.incorporationsSalesLoader = false
  })
  .addCase(incorporationsSalesList.pending, (state, action) => {
    state.incorporationsSalesLoader = true
  })
  .addCase(incorporationsSalesList.rejected, (state, action) => {
    state.incorporationsSalesLoader = false
  })

  .addCase(extraIncorporationsSalesList.fulfilled, (state, action) => {
    state.extraIncorporationsSales = action.payload.personalIncorporationsSales
    state.extraIncorporationsSalesLoader = false
    state.incorporationsSales = [...state.incorporationsSales, ...state.extraIncorporationsSales]
  })
  .addCase(extraIncorporationsSalesList.pending, (state, action) => {
    state.extraIncorporationsSalesLoader = true
  })
  .addCase(extraIncorporationsSalesList.rejected, (state, action) => {
    state.extraIncorporationsSalesLoader = false
  })

  /*Ventas descendientes */

  .addCase(upgradeDescendantSalesOffset, (state, action) => {
    state.descendantSalesOffset = state.descendantSalesOffset + 10
  })

  .addCase(resetDescendantSalesOffset, (state, action) => {
    state.descendantSalesOffset = 0
  })

  .addCase(getWeeklyDescendantSales.fulfilled, (state, action) => {
    state.salesByWeek = action.payload
  })

  .addCase(descendantSalesList.fulfilled, (state, action) => {
    state.descendantSales = action.payload.descendantSales
    state.descendantSalesLoader = false
  })
  .addCase(descendantSalesList.pending, (state, action) => {
    state.descendantSalesLoader = true
  })
  .addCase(descendantSalesList.rejected, (state, action) => {
    state.descendantSalesLoader = false
  })

  .addCase(extraDescendantSalesList.fulfilled, (state, action) => {
    state.extraDescendantSales = action.payload.descendantSales
    state.extraDescendantSalesLoader = false
    state.descendantSales = [...state.descendantSales, ...state.extraDescendantSales]
  })
  .addCase(extraDescendantSalesList.pending, (state, action) => {
    state.extraDescendantSalesLoader = true
  })
  .addCase(extraDescendantSalesList.rejected, (state, action) => {
    state.extraDescendantSalesLoader = false
  })
  
  /*Grupos calificados*/

  .addCase(getQualifiedGroups.fulfilled, (state, action) => {
    state.qualifiedGroups = action.payload
    state.qualifiedGroupsLoader = false
  })
  .addCase(getQualifiedGroups.pending, (state, action) => {
    state.qualifiedGroupsLoader = true
  })
  .addCase(getQualifiedGroups.rejected, (state, action) => {
    state.qualifiedGroupsLoader = false
  })

  .addCase(getOtherConsultantData.fulfilled, (state, action) => {
    state.otherConsultantData = action.payload
  })
  .addCase(getOtherConsultantData.pending, (state, action) => {
    
  })
  .addCase(getOtherConsultantData.rejected, (state, action) => {
    
  })


  .addMatcher(
    isAnyOf(
        getUnsendedOrders.pending,
        getPendingOrders.pending,
        getPersonalSaleDetailsPendings.pending,
        getPersonalSaleDetailsBills.pending,
        getBilledOrders.pending,
        getPersonalSaleDetailsOrders.pending,
        getPersonalSaleDetailsOrdersEDB.pending,
        getPersonalSaleDetailsRequest.pending
    ),
    (state, action) => {
        state.ordersLoader = true
    }
  )

  .addMatcher(
    isAnyOf(
        getUnsendedOrders.rejected,
        getPendingOrders.rejected,
        getPersonalSaleDetailsPendings.rejected,
        getPersonalSaleDetailsBills.rejected,
        getBilledOrders.rejected,
        getPersonalSaleDetailsOrders.rejected,
        getPersonalSaleDetailsOrdersEDB.rejected,
        getPersonalSaleDetailsRequest.rejected
    ),
    (state, action) => {
        state.ordersLoader = false
    }
  )

  .addMatcher(
    isAnyOf(
        getHistoricPeriods.pending,
        getSalesByWeek.pending,
        getWeeklyGroupSales.pending,
        getWeeklyIncorporationsSales.pending,
    ),
    (state, action) => {
    }
  )
  .addMatcher(
    isAnyOf(
        getHistoricPeriods.rejected,
        getSalesByWeek.rejected,
        getWeeklyGroupSales.rejected,
        getWeeklyIncorporationsSales.rejected,
    ),
    (state, action) => {
    }
  );

});

export default EDBDetailsReducer;