//ClientForm Address Select styles 
 export const clientSelectAddressStyles = {
    control: (styles) => ({ ...styles,
        cursor:'pointer',
        fontFamily: "MuseoSans500"
    }),
    placeholder:(styles) => ({ ...styles,
        padding:"0 0 0 13px"
    }),
    valueContainer:(styles) => ({ ...styles,
        padding:"0 0 0 13px"
    }),
    input: (styles) => ({ ...styles,
    
    }),
    indicatorsContainer:(styles) => ({ ...styles,
        display:"flex",
        gap:"12.05px",
        width:'48.2px'
    }),
    indicatorSeparator:(styles) => ({ ...styles,
       borderLeft:"1px solid #CED2FF",
    }),

    menu:(styles) => ({ ...styles,
        backgroundColor:"white",
        border:'1px solid #CED2FF',
        borderRadius:'0.375rem',
        margin:'2px 0 0 0',
        cursor:'pointer',
    }),
    menuList:(styles) => ({ ...styles,
        cursor:'pointer',
    }),

    option:(styles) => ({ ...styles,
        padding:'8px',
        cursor:'pointer',
        fontFamily: "MuseoSans500"
    }),
    noOptionsMessage:(styles) => ({ ...styles,
        color:'#908f9a',
        fontFamily: "MuseoSans500",
    }),
    singleValue: (styles, { data }) => ({ ...styles })
  };
