import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGMail } from "../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGCopy } from "../../common/svgs/ic-copy-md.svg";
import { ReactComponent as SVGCross } from "../../common/svgs/ic-x-lg.svg";

export default function ShareModalShareOffertsBody({
  socialArray,
  open,
  auxElement,
  title,
}) {
  const configExist = (auxId, message) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };
  const sharedDataPhoneText =
    socialArray && `${auxElement?.sharedText} ${auxElement?.iPaperLink} `;

  const sharedData =
    socialArray && `${auxElement?.sharedText} ${auxElement?.iPaperLink} `;

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(sharedData);
      open();
    } catch (error) {
      unsecuredCopyToClipboard(sharedData);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
      open();
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    document.body.removeChild(textArea);
  };

  return (
    <>
      {socialArray && auxElement && (
        <>
          <div>
            <div className="d-flex justify-content-between m-3 p-4">
              <div className="d-flex">
                <h5 className="museo-sans-500 s20 m-0">
                  <FormattedMessage id={title ?? "none"} />
                </h5>
              </div>
              <div
                className="pointer"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <SVGCross />
              </div>
            </div>
            <hr className="m-0"></hr>
            <div className=" p-4 d-flex  mt-0 gap-3">
              {configExist("email") !== -1 && (
                <>
                  {auxElement?.sharedText ? (
                    <a
                      href={`mailto:${
                        socialArray[configExist("email")].value
                      }?&subject=${
                        auxElement?.sharedText
                      }&body=${encodeURIComponent(auxElement?.iPaperLink)}`}
                      target="_blank"
                      className="ms-2 bronceStroke grayColor cancelBootstrap"
                    >
                      <div
                        style={{ alignItems: "center" }}
                        className=" pointer d-flex flex-column justify-content-center"
                      >
                        <div
                          style={{
                            width: "64px",
                            height: "64px",
                            alignItems: "center",
                          }}
                          className=" bubble-social rounded-circle d-flex justify-content-center"
                        >
                          <SVGMail />
                        </div>
                        <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                          <FormattedMessage id="social.sharedata.link.mail" />
                        </p>
                      </div>
                    </a>
                  ) : (
                    <a
                      href={`mailto:${
                        socialArray[configExist("email")].value
                      }?&subject=&body=${encodeURIComponent(
                        auxElement?.iPaperLink
                      )}`}
                      target="_blank"
                      className="ms-2 bronceStroke grayColor cancelBootstrap"
                    >
                      <div
                        style={{ alignItems: "center" }}
                        className=" pointer d-flex flex-column justify-content-center"
                      >
                        <div
                          style={{
                            width: "64px",
                            height: "64px",
                            alignItems: "center",
                          }}
                          className=" bubble-social rounded-circle d-flex justify-content-center"
                        >
                          <SVGMail />
                        </div>
                        <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                          <FormattedMessage id="social.sharedata.link.mail" />
                        </p>
                      </div>
                    </a>
                  )}
                </>
              )}
              {configExist("whatsapp") !== -1 && (
                <>
                  {auxElement?.sharedText ? (
                    <a
                      href={`https://wa.me/?text=${auxElement?.sharedText}%20${auxElement?.iPaperLink}`}
                      target="_blank"
                      className="ms-2 bronceStroke grayColor cancelBootstrap"
                    >
                      <div
                        style={{ alignItems: "center" }}
                        className=" pointer d-flex flex-column justify-content-center"
                      >
                        <div
                          style={{
                            width: "64px",
                            height: "64px",
                            alignItems: "center",
                          }}
                          className=" bubble-social rounded-circle d-flex justify-content-center"
                        >
                          <SVGWhatsap />
                        </div>
                        <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                          <FormattedMessage id="social.sharedata.link.wap" />
                        </p>
                      </div>
                    </a>
                  ) : (
                    <a
                      href={`https://wa.me/?text=%20${auxElement?.iPaperLink}`}
                      target="_blank"
                      className="ms-2 bronceStroke grayColor cancelBootstrap"
                    >
                      <div
                        style={{ alignItems: "center" }}
                        className=" pointer d-flex flex-column justify-content-center"
                      >
                        <div
                          style={{
                            width: "64px",
                            height: "64px",
                            alignItems: "center",
                          }}
                          className=" bubble-social rounded-circle d-flex justify-content-center"
                        >
                          <SVGWhatsap />
                        </div>
                        <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                          <FormattedMessage id="social.sharedata.link.wap" />
                        </p>
                      </div>
                    </a>
                  )}
                </>
              )}
              {configExist("messenger") !== -1 && (
                <a
                  href={`https://m.me/?text=${sharedDataPhoneText}`}
                  target="_blank"
                  className="ms-2 bronceStroke bronceFill grayColor cancelBootstrap"
                >
                  <div
                    style={{ alignItems: "center" }}
                    className=" pointer d-flex flex-column justify-content-center"
                  >
                    <div
                      style={{
                        width: "64px",
                        height: "64px",
                        alignItems: "center",
                      }}
                      className=" bubble-social rounded-circle d-flex justify-content-center"
                    >
                      <SVGMessenger />
                    </div>
                    <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                      <FormattedMessage id="social.sharedata.link.msg" />
                    </p>
                  </div>
                </a>
              )}
            </div>
          </div>
          <div className=" pt-3 mb-4 pb-3 px-3 mx-4 ">
            <h3 className=" m-0 museo-sans-500 s16">
              <FormattedMessage id="invitation.add.modal.link.text" />
            </h3>
            <button
              data-bs-dismiss="modal"
              onClick={doThis}
              style={{ borderRadius: "8px" }}
              className=" mt-3 w-100 bg-transparent d-flex align-items-center justify-content-between blueBorder grayColor "
            >
              <p className=" px-3 py-2 my-1  museo-sans-500 s14">
                {auxElement?.iPaperLink}
              </p>

              <div className="mx-1 me-2 mb-1" style={{ height: "24px" }}>
                <SVGCopy />
              </div>
            </button>
          </div>
        </>
      )}
    </>
  );
}
