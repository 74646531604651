import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";

export default function GoodPracticeNextLevel({ scoreData, profileData, scoreQualifiedGroups, toggler }) {

  const history = useNavigate();
  const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)
  let status = scoreData?.descentSales?.status;

  
  const addProgressBar = (actual, total, status) => {

    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxTotal <= auxActual) {
         aux.push(<ProgressBarParams completed={100} incompleted={false} status={status} />);
      } else if (index >= auxActual || auxActual == 0) {
        aux.push(<ProgressBarParams completed={0} incompleted={true} status={status} />);
      } else {
        aux.push(<ProgressBarParams completed={100} incompleted={true}  status={status}/>);
      }
    }

    return(aux);

  };

  const onClick = () => {
    history("/dashboard/businessplan");
  };

  const switchOnLevel = (level) => {


    switch (level) {
      case "8":
        return (
          toggler ? 
            <>
            <p className="s12 museo-sans-700 mb-3">
              <FormattedMessage id="plan.business.p.end" />
            </p>
            <div className="d-flex justify-content-between">
              <div>
                <p className=" text-uppercase grayColor s12 px-2">
                  <FormattedMessage id="plan.actual.level" />
                </p>
                <h4 className="s20 museo-sans-700 m-0">
                <ReformatLevelByGender gender={profileData?.gender} level={profileData?.level} roleDescriptionDefault={profileData?.roleDescription} />
                </h4>
              </div>
              <div
              style={{display: "grid", alignItems: "center"}}
                className="skyColor s20 px-3"
              >
              </div>
            </div>
          </> 
            : 
            <>
            <p className="s12 museo-sans-700 p-2 mb-3">
              <FormattedMessage id="plan.business.p.end" />
            </p>
            <div className="d-flex justify-content-between">
              <div>
                <p className=" text-uppercase grayColor s12 px-2">
                <FormattedMessage id="plan.actual.level" />
                </p>
                <h4 className="s20 museo-sans-700 p-2 m-0">
                  <ReformatLevelByGender gender={profileData?.gender} level={profileData?.level} roleDescriptionDefault={profileData?.roleDescription} />
                </h4>
              </div>
              <div
              onClick={onClick}
              style={{display: "grid", alignItems: "center"}}
                className="skyColor s20 px-3"
              >
                <i className="bi bi-chevron-compact-right"></i>
              </div>
            </div>
          </>
          
          
        );

      case "0":
      case "1":
        return (
          <>
            <p className=" museo-sans-500 text-uppercase grayColor s12 py-1">
              <FormattedMessage id="plan.next.level" />
            </p>
            {
              !toggler && <h4 className="s20 museo-sans-700 py-2">
                <FormattedMessage id="plan.business.title.lider" />
              </h4>
            }
            {
              toggler && <h4 className="s16 museo-sans-700 py-2">
                <FormattedMessage id="plan.business.title.lider" />
              </h4>
            }
            {
              !toggler &&  <hr className="mx-0 my-1"></hr>
            }
            
            <div className="py-1">
              <div className="s12 d-flex justify-content-between py-2">
                <p className=" museo-sans-500 s14">
                  <FormattedMessage id="personal.sales" />
                </p>          
              </div>
              <div className="py-1">
                <ProgressBarParams
                status={scoreData?.personalSales?.status}
                  incompleted={scoreData?.personalSales?.actualAmount < scoreData?.personalSales?.totalAmount}
                  completed={
                    (scoreData?.personalSales?.actualAmount /
                      scoreData?.personalSales?.totalAmount) *
                    100
                  }
                />
              </div>
              { !toggler &&
                  <div className="d-flex justify-content-between dm-sans-medium s14">
                  <p>
                  {formatAmount(scoreData?.personalSales?.actualAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className=" invisible"> a </p>
                  <p className="grayColor">
                    {" "}
                    /  {formatAmount(scoreData?.personalSales?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
                }
              <div>
                { toggler &&
                  <div className="d-flex justify-content-between dm-sans-medium s14">
                  <p style={{minWidth: "fit-content"}} >
                  {formatAmount(scoreData?.personalSales?.actualAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className=" invisible"> a </p>
                  <p style={{minWidth: "fit-content"}}  className="grayColor">
                    {" "}
                    /{formatAmount(scoreData?.personalSales?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
                }
              
              </div>
            </div>
            <div className="py-1">
              <div className="s12 d-flex justify-content-between py-2">
                <p className=" s14  museo-sans-500">
                  <FormattedMessage id="incorpotated.personal.sales" />
                </p>

                
              </div>
              <div className="py-1 d-flex gap-1">
                  {addProgressBar(scoreData?.incorporatedSales.actualActives , scoreData?.incorporatedSales.totalNeed, scoreData?.incorporatedSales?.status).map((JSX) => {
                    return JSX;
                  })}
              </div>
              { !toggler &&
                  <div style={{minWidth: "fit-content"}} className="d-flex dm-sans-medium ">
                    <p className=" s14 ">
                      {scoreData?.incorporatedSales?.actualActives} 

                    </p>
                    <p className=" s14 grayColor">
                      {" "}
                      /
                    </p>
                    <p className=" s14 grayColor">
                    
                      {scoreData?.incorporatedSales?.totalNeed}
                      </p> 
                  </div>
                
                }
              { toggler &&
                  <div style={{minWidth: "fit-content"}} className="d-flex justify-content-between dm-sans-medium">
                    <p className=" s14 ">
                      

                    </p>
                    <p className=" s14 grayColor">
                      {" "}
                      
                    </p>
                    <p className=" s14 grayColor">
                    
                    {scoreData?.incorporatedSales?.actualActives}  / {scoreData?.incorporatedSales?.totalNeed}
                      </p> 
                  </div>
                }
            </div>
            <div className="py-1">
              <div className="s12 d-flex justify-content-between py-2">
                <p className=" museo-sans-500 s14 text-capitalize">
                  <FormattedMessage id="nav.label.one.c" />
                </p>
                
              </div>
              <div className="py-1">
                <ProgressBarParams
                 status={scoreData?.groupSales?.status}
                  incompleted={ scoreData?.groupSales?.actualAmount < scoreData?.groupSales?.totalAmount}
                  completed={
                    (scoreData?.groupSales?.actualAmount /
                      scoreData?.groupSales?.totalAmount) *
                    100
                  }
                />
              </div>
              { !toggler && 
                  <div style={{minWidth: "fit-content"}} className="d-flex justify-content-between dm-sans-medium s14">
                  <p>
                 
                  {formatAmount(scoreData?.groupSales?.actualAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                  </p>
                  <p className=" invisible"> a </p>
                  <p className="grayColor">
                    {" "}
                    / {formatAmount(scoreData?.groupSales?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
                }
              { toggler && 
                  <div style={{minWidth: "fit-content"}} className="d-flex justify-content-between dm-sans-medium s14">
                  <p>
                  {formatAmount(scoreData?.groupSales?.actualAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className=" invisible"> a </p>
                  <p className="grayColor">
                    {" "}
                    / {formatAmount(scoreData?.groupSales?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
                }
            </div>
          </>
        );

      case "2":
        return (
          <>
          <div >
            <p className=" museo-sans-500 text-uppercase grayColor s12 py-1">
              <FormattedMessage id="plan.next.level" />
            </p>
            {
              !toggler && <h4 className="s20 museo-sans-700 py-2">
                <FormattedMessage id="level.three" />
              </h4>
            }
            {
              toggler && <h4 className="s16 museo-sans-700 py-2">
                <FormattedMessage id="level.three" />
              </h4>
            }
            {
              !toggler &&  <hr className="mx-0 my-1"></hr>
            }
          </div>
          <div className=" d-flex flex-column justify-content-between h-100 py-2">
              <div>

              
              <p className="s14 grayColor museo-sans-500">
                <FormattedMessage id="dashboard.modal.groups.qualified.title" />
              </p>
              <div
                style={{ alignItems: "center" }}
                className="d-flex dm-sans-bold s26"
              >
                <h4 className="m-0 py-3">
                  {scoreQualifiedGroups?.actualQualified ? scoreQualifiedGroups?.actualQualified : 0 }
                  <span className="grayColor">
                    /{scoreQualifiedGroups?.totalNeed ? scoreQualifiedGroups?.totalNeed : 0 }
                  </span>
                </h4>
              </div>
              </div>
              {!toggler &&
              <p onClick={onClick} className=" d-flex gap-1 align-items-center skyColor museo-sans-700 s14 py-2">
                <FormattedMessage id="plan.business.more.link" />
                <SVGRightArrow />
              </p>
              }
          </div>
          </>
        );

      case "3":
        return (
          <>
          <div>
            <p className=" museo-sans-500 text-uppercase grayColor s12 py-1">
              <FormattedMessage id="plan.next.level" />
            </p>
            {
              !toggler && <h4 className="s20 museo-sans-700 py-2">
                <FormattedMessage id="level.four" />
              </h4>
            }
            {
              toggler && <h4 className="s16 museo-sans-700 py-2">
                <FormattedMessage id="level.four" />
              </h4>
            }
            
            {
              !toggler &&  <hr className="mx-0 my-1"></hr>
            }
            </div>
            <div className="d-flex flex-column justify-content-between h-100 py-2">
              <div>
                <p className="s14 grayColor museo-sans-500">
                <FormattedMessage id="nav.label.one.e" />
                </p>
                <div
                  style={{ alignItems: "center" }}
                  className="d-flex dm-sans-bold s26"
                >
                  <h4 className="m-0 py-3">
                    {scoreQualifiedGroups?.actualQualified ?? 0 }
                    <span className="grayColor">
                      /{scoreQualifiedGroups?.totalNeed ?? 0 }
                    </span>
                  </h4>
                </div>
              </div>
              {!toggler &&
              <p onClick={onClick} className=" d-flex gap-1 align-items-center skyColor museo-sans-700 s14 py-2">
                <FormattedMessage id="plan.business.more.link" />
                <SVGRightArrow />
              </p>
              }
            </div>
          </>
        );

      case "4":
        return (
          <>
            <p className=" museo-sans-500 text-uppercase grayColor s12 py-1">
              <FormattedMessage id="plan.next.level" />
            </p>
            {
              !toggler && <h4 className="s20 museo-sans-700 py-2">
                <FormattedMessage id="level.five" />
              </h4>
            }
            {
              toggler && <h4 className="s16 museo-sans-700 py-2">
                <FormattedMessage id="level.five" />
              </h4>
            }
            
            {
              !toggler &&  <hr className="mx-0 my-1"></hr>
            }
            <div className="py-2">
              <p className="s14 grayColor museo-sans-500">
                <FormattedMessage id="nav.label.one.e" />
              </p>
              <div
                style={{ alignItems: "center" }}
                className="d-flex dm-sans-bold s26"
              >
                <h4 className="m-0 py-3">
                  {scoreQualifiedGroups?.actualQualified ?? 0 }
                  <span className="grayColor">
                    /{scoreQualifiedGroups?.totalNeed ?? 0 }
                  </span>
                </h4>
              </div>
              {!toggler &&
              <p onClick={onClick} className=" d-flex gap-1 align-items-center skyColor museo-sans-700 s14 py-2">
                <FormattedMessage id="plan.business.more.link" />
                <SVGRightArrow />
              </p>
              }
            </div>
          </>
        );

      case "5":
        return (
          <>
            <p className=" museo-sans-500 text-uppercase grayColor s12 py-1">
              <FormattedMessage id="plan.next.level" />
            </p>
            {
              !toggler && <h4 className="s20 museo-sans-700 py-2">
                <FormattedMessage id="level.six" />
              </h4>
            }
            {
              toggler && <h4 className="s16 museo-sans-700 py-2">
                <FormattedMessage id="level.six" />
              </h4>
            }
            {
              !toggler &&  <hr className="mx-0 my-1"></hr>
            }
            <div className="s12 d-flex justify-content-between py-2">
                <p className=" s14 museo-sans-500 text-capitalize" >
                  <FormattedMessage id="nav.label.one.d" />
                </p>

              
              </div>
              {
                scoreData?.descentSales?.status &&
                 <div className="py-1">
                <ProgressBarParams
                 status={scoreData.descentSales.status}
                  incompleted={ scoreData.descentSales.actualAmount < scoreData.descentSales.totalAmount }
                  completed={
                    (scoreData.descentSales.actualAmount /
                    scoreData.descentSales.totalAmount) *
                    100
                  }
                />
              </div>
              }
              <div className="d-flex dm-sans-medium s14 justify-content-between">
                  <p>
                  {formatAmount(scoreData?.descentSales?.actualAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className=" invisible"> a </p>
                  <p className="grayColor">
                    {" "}
                    / {formatAmount(scoreData?.descentSales?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
            <div className="py-2">
              <p className="s14 grayColor museo-sans-500">
              <FormattedMessage id="nav.label.one.e" />
              </p>
              <div
                style={{ alignItems: "center" }}
                className="d-flex dm-sans-bold s26"
              >
                <h4 className="m-0 py-3">
                  {scoreQualifiedGroups?.actualQualified ? scoreQualifiedGroups?.actualQualified : 0 }
                  <span className="grayColor">
                    /{scoreQualifiedGroups?.totalNeed ? scoreQualifiedGroups?.totalNeed : 0 }
                  </span>
                </h4>
              </div>
              {!toggler &&
              <p onClick={onClick} className=" d-flex gap-1 align-items-center skyColor museo-sans-700 s14 py-2">
                <FormattedMessage id="plan.business.more.link" />
                <SVGRightArrow />
              </p>
              }
            </div>
          </>
        );

      case "6":
        return (
          <>
            <p className=" museo-sans-500 text-uppercase grayColor s12 py-1">
              <FormattedMessage id="plan.next.level" />
            </p>
            {
              !toggler && <h4 className="s20 museo-sans-700 py-2">
                <FormattedMessage id="level.seven" />
              </h4>
            }
            {
              toggler && <h4 className="s16 museo-sans-700 py-2">
                <FormattedMessage id="level.seven" />
              </h4>
            }
            {
              !toggler &&  <hr className="mx-0 my-1"></hr>
            }
            <div className="py-1">
              <div className="s12 d-flex justify-content-between py-2">
                <p className=" s14 museo-sans-500  text-capitalize" >
                  <FormattedMessage id="nav.label.one.d" />
                </p>

              
              </div>
              {
                scoreData?.descentSales?.status &&
                 <div className="py-1">
                <ProgressBarParams
                 status={scoreData.descentSales.status}
                  incompleted={ scoreData.descentSales.actualAmount < scoreData.descentSales.totalAmount  }
                  completed={
                    (scoreData.descentSales.actualAmount /
                    scoreData.descentSales.totalAmount) *
                    100
                  }
                />
              </div>
              }
              <div style={{minWidth: "fit-content"}} className="d-flex s14 dm-sans-medium justify-content-between">
                  <p>
                  {formatAmount(scoreData?.descentSales?.actualAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className=" invisible"> a </p>
                  <p className="grayColor">
                    {" "}
                    / {formatAmount(scoreData?.descentSales?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
            </div>
            <div className="py-2">
              <p className="s14 grayColor museo-sans-500">
                <FormattedMessage id="nav.label.one.e" />
              </p>
              <div
                style={{ alignItems: "center" }}
                className="d-flex dm-sans-bold s26"
              >
                <h4 className="m-0 py-3">
                  {scoreQualifiedGroups?.actualQualified ?? 0 }
                  <span className="grayColor">
                    /{scoreQualifiedGroups?.totalNeed ?? 0 }
                  </span>
                </h4>
              </div>
              {!toggler &&
              <p onClick={onClick} className=" d-flex gap-1 align-items-center skyColor museo-sans-700 s14 py-2">
                <FormattedMessage id="plan.business.more.link" />
                <SVGRightArrow />
              </p>
              }
            </div>
          </>
        );

      case "7":
        return (
          <>
            <p className=" museo-sans-500 text-uppercase grayColor s12 py-1">
              <FormattedMessage id="plan.next.level" />
            </p>
            
            {
              !toggler && <h4 className="s20 museo-sans-700 py-2">
                <FormattedMessage id="level.eight" />
              </h4>
            }
            {
              toggler && <h4 className="s16 museo-sans-700 py-2">
                <FormattedMessage id="level.eight" />
              </h4>
            }
            
            {
              !toggler &&  <hr className="mx-0 my-1"></hr>
            }
            <div className="py-1">
              <div className="s12 d-flex justify-content-between py-2">
                <p className=" s14 museo-sans-500 text-capitalize ">
                <FormattedMessage id="nav.label.one.d" />
                </p>
              </div>
              {
                scoreData?.descentSales?.status &&
                 <div className="py-1">
                <ProgressBarParams
                 status={scoreData.descentSales.status}
                  incompleted={ scoreData.descentSales.actualAmount < scoreData.descentSales.totalAmount  }
                  completed={
                    (scoreData.descentSales.actualAmount /
                    scoreData.descentSales.totalAmount) *
                    100
                  }
                />
              </div>
              }
              
              <div className="d-flex dm-sans-medium s14 justify-content-between">
                  <p>
                  {formatAmount(scoreData?.descentSales?.actualAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                  <p className=" invisible"> a </p>
                  <p className="grayColor">
                    {" "}
                    / {formatAmount(scoreData?.descentSales?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                  </p>
                </div>
            </div>
            <div className="py-2">
              <p className="s14 grayColor museo-sans-500">
              <FormattedMessage id="nav.label.one.e" />
              </p>
              <div
                style={{ alignItems: "center" }}
                className="d-flex dm-sans-bold s26"
              >
                <h4 className="m-0 py-3">
                  {scoreQualifiedGroups?.actualQualified ? scoreQualifiedGroups?.actualQualified : 0 }
                  <span className="grayColor">
                    /{scoreQualifiedGroups?.totalNeed ? scoreQualifiedGroups?.totalNeed : 0 }
                  </span>
                </h4>
              </div>
              {!toggler &&
              <p onClick={onClick} className="skyColor museo-sans-700 s14 py-2">
                <FormattedMessage id="plan.business.more.link" />
                {" "}
              </p>
              }
            </div>
          </>
        );

      default:
        return (
            <p className=" museo-sans-500 s14">
              <FormattedMessage id="plan.nothing.to.see" />
              </p>
        );
    }
  };

  return profileData ? (
    switchOnLevel(profileData.level)
  ) : (
    <p className=" museo-sans-500 s14">
      <FormattedMessage id="plan.nothing.to.see" />
      </p>
  );
}
