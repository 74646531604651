import { FormattedMessage } from "react-intl"
import { ReactComponent as TriangleWarning } from "../../../common/svgs/ic-red-triangle-warning.svg"

export default function ClientListEmptyData() {
  return(
    <div className="bg-white rounded-3 w-100 d-flex flex-column justify-content-center align-items-center" style={{ height: 500 }}>
      <TriangleWarning width={37} height={32} style={{ marginBottom: 18 }} />
      <h3 className="mb-4 museo-sans-700 s-24">
        <FormattedMessage id="client.empty.message.title" />
      </h3>
      <div style={{ maxWidth: '75%', textAlign: 'center' }}>
        <p className=" museo-sans-500" >
          <FormattedMessage id="client.empty.message.message" />
        </p>
      </div>
    </div>
  )
}