import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import { useParams, useNavigate } from "react-router-dom";

import {
  ClosedEDBMeetings,
  OpenEDBMeetings,
  DatedEDBMeetings,
} from "../../Framework/redux/EDBsSideEffects";
import {
  FilteredClosedEDBMeetings,
  FilteredDatedEDBMeetings,
  FilteredOpenEDBMeetings,
} from "../../Framework/redux/EDBsSideEffects";

import { ReactComponent as FilterSVG } from "../../../common/svgs/filter-sm.svg";

import EDBDatedList from "../components/EDBDatedList";
import EDBOpenList from "../components/EDBOpenList";
import EDBClosedList from "../components/EDBClosedList";
import EDBModalFilter from "../components/EDBModalFilter";
import EDBModal from "../components/EDBModal";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import "../pages/EDBs.css";

export default function EDBsListing() {
  const history = useNavigate();
  const dispatch = useDispatch();

  const filterInformation = useSelector((state) => state.edbData.filters);
  const rawFiltersState = useSelector((state) => state.edbData.rawFilters);

  const [toggleOrganizer, setTogglerOrganizer] = useState(false);
  const [date, setDate] = useState({ origin: "", target: "" });
  const [dataWasFiltered, setDataWasFiltered] = useState(false);
  const [closedFilterOffsetState, setClosedFilterOffsetState] = useState(10);
  const [openFilterOffsetState, setOpenFilterOffsetState] = useState(10);
  const [datedFilterOffsetState, setDatedFilterOffsetState] = useState(10);
  const [closedFilterWasUpdated, setClosedFilterWasUpdated] = useState(null);
  const [order, setOrder] = useState({
    highLow: true,
    type: { sale: true, guest: false, level: false },
  });
  const [edbType, setEdbType] = useState({ virtual: false, onSite: false });
  const [prizeLevel, setPrizeLevel] = useState({
    noAward: false,
    oneAward: false,
    twoAward: false,
  });
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (filterInformation.wasFiltered) {
      setDataWasFiltered(true);
      setOrder(rawFiltersState.sortBy);
      setEdbType(rawFiltersState.edbType);
      setPrizeLevel(rawFiltersState.hostAward);
      setDate(rawFiltersState.date);
      dispatch(
        FilteredClosedEDBMeetings({
          offset: 0,
          limit: 10,
          sortBy: filterInformation.sortBy,
          edbType: filterInformation.edbType,
          hostAward: filterInformation.hostAward,
          date: filterInformation.date,
        })
      );
      dispatch(
        FilteredOpenEDBMeetings({
          offset: 0,
          limit: 10,
          sortBy: filterInformation.sortBy,
          edbType: filterInformation.edbType,
          hostAward: filterInformation.hostAward,
          date: filterInformation.date,
        })
      );
      dispatch(
        FilteredDatedEDBMeetings({
          offset: 0,
          limit: 10,
          sortBy: filterInformation.sortBy,
          edbType: filterInformation.edbType,
          hostAward: filterInformation.hostAward,
          date: filterInformation.date,
        })
      );
    } else {
      dispatch(ClosedEDBMeetings(10));
      dispatch(OpenEDBMeetings(10));
      dispatch(DatedEDBMeetings(10));
    }
  }, [dispatch]);

  let { state } = useParams();

  useEffect(() => {
    let elemento = document.getElementById(state);
    elemento.click();
  }, [state]);

  function switchConfig(param) {
    switch (param) {
      case 1:
        return (
          <EDBDatedList
            dataWasFiltered={dataWasFiltered}
            order={order}
            closedFilterWasUpdated={closedFilterWasUpdated}
            datedFilterOffsetState={datedFilterOffsetState}
            setDatedFilterOffsetState={setDatedFilterOffsetState}
            edbType={edbType}
            prizeLevel={prizeLevel}
            date={date}
          />
        );
      case 2:
        return (
          <EDBOpenList
            dataWasFiltered={dataWasFiltered}
            order={order}
            closedFilterWasUpdated={closedFilterWasUpdated}
            openFilterOffsetState={openFilterOffsetState}
            setOpenFilterOffsetState={setOpenFilterOffsetState}
            edbType={edbType}
            prizeLevel={prizeLevel}
            date={date}
          />
        );
      case 3:
        return (
          <EDBClosedList
            key={closedFilterWasUpdated}
            dataWasFiltered={dataWasFiltered}
            order={order}
            closedFilterWasUpdated={closedFilterWasUpdated}
            closedFilterOffsetState={closedFilterOffsetState}
            setClosedFilterOffsetState={setClosedFilterOffsetState}
            edbType={edbType}
            prizeLevel={prizeLevel}
            date={date}
          />
        );
    }
  }

  function flapAndUrl(value, route) {
    setValue(value);

    if (state !== route) {
      history(`/dashboard/edbs/list/${route}`);
    }
  }

  return (
    <div className="   pt-5">
      <EDBModalFilter
        edbStateRecieved={state}
        order={order}
        setOrder={setOrder}
        edbType={edbType}
        setEdbType={setEdbType}
        prizeLevel={prizeLevel}
        setPrizeLevel={setPrizeLevel}
        toggleOrganizer={toggleOrganizer}
        setTogglerOrganizer={setTogglerOrganizer}
        date={date}
        setDate={setDate}
        setDataWasFiltered={setDataWasFiltered}
        dataWasFiltered={dataWasFiltered}
        setClosedFilterWasUpdated={setClosedFilterWasUpdated}
        setClosedFilterOffsetState={setClosedFilterOffsetState}
      />
      <ShareModalStructure id={"modalInfo"} maxWidth={"600px"}>
        <EDBModal />
      </ShareModalStructure>

      <div className="container--padding--card-holder">
        <div className="">
          <div className=" w-100 container--padding container--no-padding d-flex justify-content-end">
            <button
              data-bs-toggle="modal"
              data-bs-target="#modalEDBFilter"
              className="ps-5 pe-5 me-5 museo-sans-700 s14 skyColor skyBorder bg-transparent d-flex gap-2 align-items-center button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative red-dot-hover"
            >
              {filterInformation.wasFiltered ? (
                <>
                  <FilterSVG />
                  <div
                    className="position-absolute rounded-circle"
                    style={{
                      height: "8px",
                      width: "8px",
                      backgroundColor: "#F06E69",
                      top: "8px",
                      left: "56px",
                    }}
                  ></div>
                </>
              ) : (
                <FilterSVG />
              )}
              <FormattedMessage id="edb.filter.button" />
            </button>
          </div>
        </div>
      </div>
      <div className="container--padding--card-holder">
        <div>
          <div className=" w-100 container--padding container--no-padding d-flex align-items-end">
            <button
              onClick={() => flapAndUrl(1, "dated")}
              id="dated"
              type="button"
              className={`p-1 ps-4 pe-4 s14 flap-button museo-sans-500 s14 ${
                value === 1 ? "bronceBg blackColor" : "lightGreyBg grayColor"
              }`}
            >
              <FormattedMessage id="edb.list.table.col.dated" />
            </button>
            <button
              onClick={() => flapAndUrl(2, "open")}
              id="open"
              type="button"
              className={`p-1 ps-4 pe-4 s14 flap-button museo-sans-500 s14 ${
                value === 2 ? "bronceBg blackColor" : "lightGreyBg grayColor"
              }`}
            >
              <FormattedMessage id="edb.list.table.col.openned" />
            </button>
            <button
              onClick={() => flapAndUrl(3, "closed")}
              id="closed"
              type="button"
              className={`p-1 ps-4 pe-4 s14 flap-button museo-sans-500 s14 ${
                value === 3 ? "bronceBg blackColor" : "lightGreyBg grayColor"
              }`}
            >
              <FormattedMessage id="edb.list.table.col.closed" />
            </button>
          </div>
        </div>
      </div>
      <div className="container--padding--card-holder">
        <div className="w-100 ">
          <div
            className=" w-100 container--padding pt-3 pb-4 position-relative bronceBg"
            style={{ minHeight: "65vh" }}
          >
            <div className=" w-100  container--padding--card-holder">
              <div>{switchConfig(value)}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
