import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGFailed } from "../svgs/error/circle-alert.svg";

export default function ShareErrorHandlerServices({ service, small, navbar }) {
  return (
    <>
      {small ? (
          <div id="error-handler-server"  className=" w-100 position-relative d-flex flex-row gap-2 d-flex align-items-center justify-content-center ">
            <div
              style={{ wdith: "42px", height: "42px" }}
              className=" svg-force-size"
            ></div>
            <div id="error-handler-server-content" className=" flex-column gap-1 align-items-center text-center">
              <p className=" museo-sans-500 s12">
              <FormattedMessage id="error.server.hanlder.content.one" />
              </p>
              <p className=" museo-sans-500 s12">
                <FormattedMessage id="error.server.hanlder.content.two" />
              </p>
            </div>
            {navbar ? (
                <div id="navbar-error-handler" className=" gap-1 d-flex flex-column  justify-content-center align-items-center">
                  <div
                    id="navbar-error-handler-svg"
                    style={{ wdith: "32px", height: "32px" }}
                    className=" svg-force-size bg-white rounded-circle "
                  >
                    <SVGFailed />
                  </div>
                  <p
                    id="error-handler-server-button"
                    onClick={() => service()}
                    style={{ border: "1px white solid" }}
                    className=" pointer museo-sans-700 rounded-3 text-white border-1 p-1 s14"
                  >
                    <FormattedMessage id="buttons.restart" />
                  </p>
                </div>
            ) : (
              <div className=" d-flex flex-column  justify-content-center align-items-center">
                <div
                  style={{ wdith: "32px", height: "32px" }}
                  className=" svg-force-size bg-white rounded-circle "
                >
                  <SVGFailed />
                </div>
                <p
                  onClick={() => service()}
                  className=" pointer museo-sans-700 skyColor s14"
                >
                  <FormattedMessage id="buttons.restart" />
                </p>
              </div>
            )}
          </div>
      ) : (
          <div className=" w-100 position-relative d-flex flex-column gap-2 d-flex align-items-center justify-content-center ">
            <div
              style={{ wdith: "42px", height: "42px" }}
              className=" svg-force-size"
            >
              <SVGFailed />
            </div>
            <div className=" d-flex flex-column gap-1 align-items-center text-center">
              <p className=" museo-sans-500 s16">
                <FormattedMessage id="error.server.hanlder.content.one" />
              </p>
              <p className=" museo-sans-500 s16">
              <FormattedMessage id="error.server.hanlder.content.two" />
              </p>
            </div>
            <p
              onClick={() => service()}
              className=" pointer museo-sans-700 skyColor s14"
            >
              <FormattedMessage id="buttons.restart" />
            </p>
          </div>
      )}
    </>
  );
}
