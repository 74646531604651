import React from "react";

import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

export default function GoodPracticeModalInfo() {
  return (
    <div>
      <div className="d-flex justify-content-between m-3 p-4 align-items-center">
        <div className="d-flex">
          <HelpIconNoTarget />
          <h5 className="museo-sans-500 px-2 s20 m-0">
            <FormattedMessage
              id="good.practice"
              defaultMessage="Buenas Prácticas"
            />
          </h5>
        </div>
        <div className=" pointer " data-bs-dismiss="modal" aria-label="Close">
          <SVGClose />
        </div>
      </div>
      <hr className="m-0"></hr>
      <div className="d-flex flex-column align-items-center">
        <div className=" museo-sans-500 mt-4 mx-3 px-4">
          <p className="s16 py-2">
            <FormattedMessage
              id="good.practice.parr.one"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2">
            <FormattedMessage
              id="good.practice.parr.two"
              defaultMessage="Buenas Prácticas"
            />
          </p>
          <p className="s16 py-2">
            <FormattedMessage
              id="good.practice.parr.three"
              defaultMessage="Buenas Prácticas"
            />
          </p>
        </div>
        <div className="py-4">
          <button
            type="button"
            className=" btnLoginWidth my-3 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <FormattedMessage
              id="buttons.understood"
              defaultMessage="Entendido"
            />
          </button>
        </div>
      </div>
    </div>
  );
}
