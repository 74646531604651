import React from 'react';
import { multiLangGetCountry } from '../../../common/Share/MultiLangGetCountry';

export default function CalendarEmptyDay({currentPeriod, dateObject, toggler}) {

    function periodDayHighlight() {
        let fechaInicial;
        let fechaFinal;
    
        if (currentPeriod !== undefined) {
          fechaInicial = new Date(currentPeriod[0]?.startDate);
          fechaInicial.setHours(0, 0, 0, 0);
          fechaFinal = new Date(currentPeriod[0]?.endDate);
          fechaFinal.setHours(0, 0, 0, 0);
          if (
            currentPeriod?.length > 0 &&
            dateObject >= fechaInicial &&
            dateObject <= fechaFinal
          ) {
            return "calendar-period-day-highlight";
          }
        }
        return "";
      }

    function blueBorder(){
        if(dateObject.toLocaleDateString(multiLangGetCountry(), {weekday: "long"}) === "jueves"){
            return "calendar-blue-left-border"
        } else {
            return ""
        }
    }

  return (
    <div className={`calendar-borders ${blueBorder()} ${periodDayHighlight()} ps-2 pe-2 calendar-day`}>
        <div className={`calendar-number-container mt-2 dm-sans-medium s14 grayColor`}>
            {dateObject?.getDate()}  
        </div>
    </div>
  )
}

