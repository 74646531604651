import { createReducer } from "@reduxjs/toolkit";
import { notificationSharingData } from "./OfertsActions";


const initialState = {
    dataSharing: {}
}

const ofertsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(notificationSharingData, (state, action) => {
            state.dataSharing = action.payload
        })
})

export default ofertsReducer;