import { FormattedMessage } from "react-intl";

import { useSelector } from "react-redux";
import React from "react";
import BusinessPlanCardearningPerLevel from "./BusinessPlanCardEarningPerLevel";

export default function BusinessPlanCardEarning({ data, newToggle, level, loading }) {
  const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);
  return (
    <div className="cards-shadow position-relative f9GrayBorder  p-4 w-100 rounded-3">
      <div className="d-flex align-items-center ">
        <h2 className=" s16 text-capitalize museo-sans-500 m-0 ">
          <FormattedMessage id="earnings" defaultMessage="ganancias" />
        </h2>
      </div>
      <hr></hr>
      {!loading ? (
          <BusinessPlanCardearningPerLevel
            data={data}
            newToggle={newToggle}
            level={parseInt(level)}
            cultureInfo={cultureInfo}
          />
      ) : (
          <Skeleton />
      )}
    </div>
  );
}

export function Skeleton() {
  return (
    <div className=" d-flex  flex-column gap-4">
      <SkeletonRow header={true} />
      <SkeletonRow short={true}/>
      <SkeletonRow short={true}/>
      <SkeletonRow short={true}/>
      <SkeletonRow header={true} />
      <SkeletonRow long={true} />
      <SkeletonRow long={true} />
    </div>
  );
}

export function SkeletonRow({ header, long, short }) {
  const height = short ? "6px" : "12px";

  return (
    <>
      {(header || short || long) && (
        <div className="w-100 d-flex gap-3">
          <div style={{ height }} className="d-flex rounded-3 w-25 lightui1-shimmer">
            <div style={{ height }} className="rounded-3 w-25 lightui1-shimmer"></div>
            <div style={{ height }} className="rounded-3 w-50 brightGrayBg lightui1-shimmer"></div>
          </div>
          {(header || short) && (
            <div style={{ height }} className="rounded-3 w-50 brightGrayBg lightui1-shimmer"></div>
          )}
          {long && (
            <div className="w-75 gap-2 d-flex flex-column">
              <div style={{ height }} className="rounded-3 w-100 brightGrayBg lightui1-shimmer"></div>
              <div style={{ height }} className="rounded-3 w-75 brightGrayBg lightui1-shimmer"></div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
