import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput"
import ClientListFormSelect from "../elements/ClientListFormSelect"


export default function Panama({ watch, ...props }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')
  
  return (
    <>
      <ClientListFormOneColumnInput
        placeholder={"Ej: Las pirámides Torre 300"}
        label={"Dirección o Señas (opcional)"}
        name="street"
        {...props}
      />
      <div className=" px-3 pe-0">
        <ClientListFormSelect
          placeholder="Seleccionar una Provincia"
          label={"Provincia (opcional)"}
          name="province"
          isUpdate={isUpdate}
          father={true}
          watch={watch}
          {...props}
          level={1}
        />

      </div>
      <ClientListFormSelect
        placeholder="Seleccionar un Distrito"
        label={"Distrito (opcional)"}
        name="district"
        isUpdate={isUpdate}
        father={watch('province')}
        watch={watch}
        {...props}
        level={2}
      />
      <div className=" px-3 pe-0">

      <ClientListFormSelect
        placeholder="Seleccionar un Corregimiento"
        label={"Corregimiento (opcional)"}
        name="township"
        isUpdate={isUpdate}
        father={watch('district')}
        watch={watch}
        {...props}
        level={3}
        last
      />
      </div>

    </>
  )
}