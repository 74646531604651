import React, { useRef } from "react";

import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ModalWhiteShadow from "../../../common/Share/ModalWhiteShadow";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

export default function GoodPracticeModalPlan() {

  const scrollbarRef = useRef(null)

  return (
    <div>
    <div className="d-flex justify-content-between m-3 p-4 align-items-center">
      <div className="d-flex">
        <HelpIconNoTarget />
        <h5 className="museo-sans-500 px-2 s20 m-0">
          <FormattedMessage
            id="good.practice.modal.plan.title"
            defaultMessage="Buenas Prácticas"
          /> 
        </h5>
      </div>
      <div className=" pointer " data-bs-dismiss="modal"
        aria-label="Close">
          <SVGClose />
      </div>
    </div>
    <hr className="m-0"></hr>
    <div className="d-flex flex-column align-items-center">
      <div ref={scrollbarRef} className="pt-4 mx-3 px-4 help-modal-info-container remove-scrollbar">
        <p className="s16 py-2 museo-sans-500">
        <FormattedMessage
            id="good.practice.modal.plan.p.one"
            defaultMessage="Buenas Prácticas"
          />
        </p>
        <p className="s16 py-2 museo-sans-500">
        <FormattedMessage
            id="good.practice.modal.plan.p.two"
            defaultMessage="Buenas Prácticas"
          />
        </p>
        <p className="s16 py-2 museo-sans-700">
        <FormattedMessage
            id="good.practice.modal.plan.ul.title"
            defaultMessage="Buenas Prácticas"
          />
        </p>
        <p className="s16 py-2 museo-sans-500">
        <FormattedMessage
            id="good.practice.modal.plan.li.one"
            defaultMessage="Buenas Prácticas"
          />
        </p>
        <p className="s16 py-2 museo-sans-500">
        <FormattedMessage
            id="good.practice.modal.plan.li.two"
            defaultMessage="Buenas Prácticas"
          />
        </p>
        <p className="s16 py-2 museo-sans-500">
        <FormattedMessage
            id="good.practice.modal.plan.li.three"
            defaultMessage="Buenas Prácticas"
          />
        </p>
        <p className="s16 py-2 museo-sans-500">
        <FormattedMessage
            id="good.practice.modal.plan.li.four"
            defaultMessage="Buenas Prácticas"
          />
        </p>
      </div>
      <ModalWhiteShadow left={"5%"} width={"90%"} scrollbarRef={scrollbarRef} />
      <button
        type="button"
        className=" btnLoginWidth my-5 museo-sans-700 s14 skyBg skyBorder whiteColor button-hover-light-text button-bootstrap-radius button-padding"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <FormattedMessage
          id="buttons.understood"
          defaultMessage="Entendido"
        />
      </button>
    </div>
  </div>
  );
}
