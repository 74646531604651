import OfertsCardHolderBody from "./OfertsCardHolderBody";
import { useEffect, useState } from "react";

import { ReactComponent as SVGFilledRightChev } from "../../../common/svgs/ic-slide-right.svg";
import { ReactComponent as SVGFilledRightChevGray } from "../../../common/svgs/ic-slide-right-gray.svg";
import { ReactComponent as SVGFilledLeftChev } from "../../../common/svgs/ic-slide-left.svg";
import OfertsCardHolderBodyNotis from "./OfertsCardHolderBodyNotis";
import { FormattedMessage } from "react-intl";
import ShareErrorHandlerServices from "../../../common/Share/ShareErrorHandlerServices";
import { getNoticeClients, getNoticeConsultant, getNoticeLiders, getOffersData } from "../../../Dashboard/Framework/redux/dashboardSideEffect";
import { useDispatch } from "react-redux";

export function OfertsCardHolderHeader({
    historyControl,
    offerData,
    setOffersInfoModal,
    notis,
    applyToAux,
    controllerModalOfertsToTop,
    allOffers,
    ofertsNoFeatures,
    errorControl,
    toggleSkeleton
  }) {

    
    let i = 0;
    let max = notis ?  offerData?.length : offerData?.data?.length;
    
    const [auxiliarSkeleton , setAuxiliarSkeleton] = useState(false);
    const [valorDeI, setValorDeI] = useState(i);
  
    const dispatch = useDispatch();
  
    const goTo = () => {
      historyControl("weeklydeals");
    };
    
    const lessOffer = () => {
      if (valorDeI >= 1) {
        let valor = valorDeI;
        valor--;
        setValorDeI(valor);
      }
      fetchNewImageSkeleton();
    };
  
    const plusOffer = () => {
      if (valorDeI < max - 1) {
        let valor = valorDeI;
        valor++;
        setValorDeI(valor);
      }
      fetchNewImageSkeleton();
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const fetchNewImageSkeleton = () => {
      setAuxiliarSkeleton(true);

      setTimeout(() => {
        setAuxiliarSkeleton(false);
      }, 200);
      
    }

    const callServicesOffers = async () => {
      toggleSkeleton()
      if (notis) {
        await dispatch(getNoticeLiders());
        await dispatch(getNoticeClients());
        await dispatch(getNoticeConsultant());
        
      } else {
        await dispatch(getOffersData());

      }
    };
  
    return (
        <div
          style={{
            height: "460px",
            width: "288px"
          }}
        >
          <div className="d-flex justify-content-between align-items-center museo-sans-500 ">
            <div className="d-flex ">
              <h4 className="m-0 s20 pe-2 ">
                {
                  notis ?
                    <FormattedMessage id="notis" />
                  :
                    <FormattedMessage id="dashboard.weekly.deals.captalize" />
                }
                </h4>
              <div className="d-flex gap-2 ">
                <div
                  style={{ transform: "rotate(90deg)", width: "16px" }}
                  className=" pointer svg-force-size"
                >
                  {valorDeI == 0 ? (
                    <SVGFilledRightChevGray />
                  ) : (
                    <SVGFilledRightChev onClick={lessOffer} />
                  )}
                </div>
                <div
                  style={{ transform: "rotate(90deg)", width: "16px" }}
                  className=" pointer svg-force-size"
                >
                  {valorDeI == max - 1 ? (
                    <SVGFilledRightChevGray />
                  ) : (
                    <SVGFilledLeftChev onClick={plusOffer} />
                  )}
                </div>
              </div>
            </div>
            <div>
              {
                !notis &&
                <p onClick={goTo} className=" museo-sans-700 pointer skyColor s14">
                  <FormattedMessage id="view.more" />
                </p>
              }
            </div>
          </div>
          <div
            style={{ height: "361px", borderRadius: "16px", zIndex: 2 }}
            className=" position-relative cards-shadow bg-white mt-4  "
          >
            <>
              {
                errorControl ?
                <div className=" w-100 h-100 d-flex justify-content-center align-items-center" >
                  <div className=" " >
                    <ShareErrorHandlerServices service={callServicesOffers} />
                  </div>
                </div>
                :
                <>
                  {
                    notis ?
                      <OfertsCardHolderBodyNotis
                        auxiliarSkeleton={auxiliarSkeleton}
                        dataArray={offerData[valorDeI]}
                        setOffersInfoModal={setOffersInfoModal}
                        windowWidth={windowWidth}
                        applyToAux={applyToAux}
                      />
                    :
                      <OfertsCardHolderBody
                        auxiliarSkeleton={auxiliarSkeleton}
                        dataArray={offerData?.data[valorDeI]}
                        setOffersInfoModal={setOffersInfoModal}
                        windowWidth={windowWidth}
                        allOffers={allOffers}
                        acceptedOffers={offerData?.data}
                        goTo={goTo}
                        controllerModalOfertsToTop={controllerModalOfertsToTop}
                        ofertsNoFeatures={ofertsNoFeatures}
                      />
                  }
                
                </>
              }
            </>
          </div>
        </div>
    );
  }