import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import { getCountriesTimeZone } from "../../../../common/Share/getCountriesTimeZone";
import { setCurrentWeek } from "../../Framework/HeaderAction";

import ProgressBarParams from "../../../Share/ProgressBarParams";

import moment from "moment/moment";

export default function HeaderProgress({user}) {
  const headerData = useSelector((state) => state?.headerData);
  const [periodState, setPeriodState] = useState({ week: 1, index: 0 });

  const dispatch = useDispatch();

  const compareDate = (weeks) => {
    // ENSURE WEEKS HAS VALUE
    if (!weeks) {
      return;
    }
    const countryCode = localStorage.getItem("country");
    
    const actualDate = new Date();

    const resolve = actualDate.getTime();

    let index = 0;
    let weekIndex = 1;

    // TAKE WEEKLY DATA AND INIT VALUES.
    weeks.forEach((week) => {

      let auxNewStartDate = moment.tz(moment(week.startDate), getCountriesTimeZone(countryCode)).format("YYYY-MM-DD");
      let auxEndStartDate = moment.tz(moment(week.endDate), getCountriesTimeZone(countryCode)).format("YYYY-MM-DD");


      let startDate = new Date(auxNewStartDate);
      let endDate = new Date(auxEndStartDate);

      if (resolve <= startDate.getTime()) {
      } else if (resolve == startDate.getTime()) {
        weekIndex = week.id;
        setPeriodState({ week: week.id, index: index });
      } else {
        if (resolve > endDate.getTime()) {
          weekIndex = week.id;
          setPeriodState({ week: weekIndex, index: 3 });
          if (weekIndex == 4) {
            setPeriodState({ week: weekIndex, index: 3 });
          }
        } else if (resolve == endDate.getTime()) {
          weekIndex = week.id;
          index = 3;
          setPeriodState({ week: weekIndex, index: index });
        } else {
          weekIndex = week.id;
          index = actualDate.getDay();
          setPeriodState({ week: weekIndex, index: index });
        }
      }
      if (weekIndex === 5) {
        setPeriodState({ week: weekIndex - 1, index: 3 });
      }

      dispatch(setCurrentWeek(weekIndex))
    });
  };

  const constructHeaderProgressBar = () => {
    if (periodState?.week) {


    switch (periodState.week) {
      case 1:
        return (
          <>
            <ProgressBarParams header={true} completed={constructIncompletedProgress()} height={4} />
            <ProgressBarParams header={true} completed={0} height={4} />
            <ProgressBarParams header={true} completed={0} height={4} />
            <ProgressBarParams header={true} completed={0} height={4} />
          </>
        );
      case 2:
        return (
          <>
            <ProgressBarParams header={true} completed={100} height={4} />
            <ProgressBarParams header={true} completed={constructIncompletedProgress()} height={4} />
            <ProgressBarParams header={true} completed={0} height={4} />
            <ProgressBarParams header={true} completed={0} height={4} />
          </>
        );
      case 3:
        return (
          <>
            <ProgressBarParams header={true} completed={100} height={4} />
            <ProgressBarParams header={true} completed={100} height={4} />
            <ProgressBarParams header={true} completed={constructIncompletedProgress()} height={4} />
            <ProgressBarParams header={true} completed={0} height={4} />
          </>
        );
      case 4:
        return (
          <>
            <ProgressBarParams header={true} completed={100} height={4} />
            <ProgressBarParams header={true} completed={100} height={4} />
            <ProgressBarParams header={true} completed={100} height={4} />
            <ProgressBarParams header={true} completed={constructIncompletedProgress()} height={4} />
          </>
        );
    }
  }
  };

  const constructIncompletedProgress = () => {
    const progressMap = {
      1: 80,
      2: 90,
      3: 100,
      4: 10,
      5: 25,
      6: 45,
      7: 65,
    };
  
    return periodState?.index ? progressMap[periodState.index] : undefined;
  };
  
  useEffect(() => {
    compareDate(headerData?.period?.periodWeeks);
  }, [user]);

  return (
    <div id="header--progressbar">
      {periodState.week !== 0 ? (
        <>{constructHeaderProgressBar()}</>
      ) : (
        <>
          <ProgressBarParams header={true} completed={0} height={4} />
          <ProgressBarParams header={true} completed={0} height={4} />
          <ProgressBarParams header={true} completed={0} height={4} />
          <ProgressBarParams header={true} completed={0} height={4} />
        </>
      )}
    </div>
  );
}
