import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { changeLevel } from "./BusinessAction";

import {
  getScoreComparison,
  getQualifiedGroup,
  getBussinessPlan,
} from "./BusinessSideEffect";

const initialState = {
  data: null,
  qualifiedGroups: null,
  earningData: null,
  level: null,
  loader: false,
};

const businessReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getScoreComparison.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loader = false;
    })

    .addCase(getBussinessPlan.fulfilled, (state, action) => {
      state.earningData = action.payload;
      state.loader = false;
    })

    .addCase(getQualifiedGroup.fulfilled, (state, action) => {
      state.qualifiedGroups = action.payload;
      state.loader = false;
    })

    .addCase(changeLevel, (state, action) => {
      state.level = action.payload;
    })

    .addMatcher(
      isAnyOf(getScoreComparison.pending, getQualifiedGroup.pending, getBussinessPlan.pending),
      (state, action) => {
        state.loader = true;
      }
    )
    .addMatcher(
      isAnyOf(getScoreComparison.rejected, getQualifiedGroup.rejected, getBussinessPlan.rejected),
      (state, action) => {
        state.loader = false;
      }
    );
});

export default businessReducer;
