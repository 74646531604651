import { useDispatch } from "react-redux";
import InvitationsDashboardModalDetails from "./modals/InvitationsDashboardModalDetails";
import { sendDataDetails } from "../../Framework/redux/InvitationsAction";
import { FormattedMessage } from "react-intl";
import { capitalizeString } from "../../../common/utils/stringUtilities";
import { colorByState } from "../../constants";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import InvitationsModalIncorporatedPopUp from "./modals/InvitationsModalIncorporatedPopUp";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";


export default function InvitationsDashboardShowCaseRow({ data }) {
  const dispatch = useDispatch();
  const lastStage = data?.stages.findLast(estage => estage.stageDetail.state === "DISABLED" || estage.stageDetail.state === "COMPLETED"); //Get the last stage element with status COMPLETED or DISABLED.
  const hasDisabledState = data?.stages[2].stageDetail.state === "DISABLED"
  const isLastCompleted = data?.stages[3].stageDetail.state === "COMPLETED"

  const controlLastCompleted = () => {
    return (
      <div className=" s14 grayColor d-flex flex-column">
        <p style={{lineHeight:'19px',minHeight:'19px'}}>{lastStage?.stageDescription}</p>
        <div className="d-flex flex-row align-items-center justify-content-between" style={{lineHeight:'19px',marginTop:'4px'}}>
          <p style={{lineHeight:'19px',minHeight:'19px'}} className={`${lastStage?.stageDetail.status === "risk" && "lightRedColor"} museo-sans-500 w400`}>
            {lastStage?.stageDetail.startDate || ''}
          </p>
          {data?.inviteeInfo?.clientProfile?.isReincorporated && (
            <p style={{lineHeight:'14px',width:'103px',paddingTop:'1px'}} className="text-uppercase blueBubbleColors museo-sans-00 s9 rounded-16 text-center">
              <FormattedMessage id="invitation.reincoporated" />
            </p>
          )}
        </div>
      </div>
    );
  };

  const onClickRegister = () => {
    const clickMe = document.getElementById(`click-my-details-${data?.id}`);
    dispatch(sendDataDetails(data));
    clickMe.click();
  };

  return (

    <>
    {
      data &&
      <>
        <InvitationsDashboardModalDetails id={data?.id} />
        <ShareModalStructure id={`incorporated`} idNumber={data?.id}   >
          <InvitationsModalIncorporatedPopUp body={data?.stages[2]?.stageDetail?.tooltipMessage} />
        </ShareModalStructure >
        <div
          id={`click-my-details-${data?.id}`}
          data-bs-toggle="modal"
          data-bs-target={`#modal-invitations-details-${data?.id}`}
          className="position-absolute"
        />
        <div style={{minWidth: '200px' ,width:'200px', height:'101px',overflow:'hidden'}} onClick={() => onClickRegister()} className="pointer museo-sans-700 d-flex flex-column w600">
          <p style={{lineHeight:'22px',textOverflow:'ellipsis',maxWidth:'16ch',overflow:'hidden',whiteSpace:'nowrap',paddingBottom:'12px'}}>
            {capitalizeString(`${data?.inviteeInfo?.firstName} ${data?.inviteeInfo?.lastName}`)}
          </p>
          <div style={{marginBottom:'12px'}} className="w-100 d-flex position-relative">
            <div style={{ height: "4px", background: "#ced2ff", width: "90%" ,top:'7px',left:'12px'}} className="rounded-3 position-absolute">
              <InvitationListRowProgressBar hasDisabledState={hasDisabledState}  lastStageIndex={lastStage?.stageId-1} isLastCompleted={isLastCompleted}/>
            </div>
            {data?.stages.map((stage, index) => 
              <InvitationListRowProgressBall
                key={ShareKeyGenerator()}
                lastData={lastStage}
                bgColor={colorByState[`${stage.stageDetail.state}`]}
                data={stage}
                index={stage.stageId-1}
                isLastCompleted={isLastCompleted}
                hasDisabledState={hasDisabledState} 
              />)}
          </div>
          {controlLastCompleted()}
        </div>
        <div>
          <div className="h-100 brightGrayBg position-relative" style={{width: "1px",margin: "0px 10px"}}>
            <p className="invisible">.</p>
          </div>
        </div>

      </>
    }
    </>
  )}

export function InvitationListRowProgressBar({ hasDisabledState,lastStageIndex,isLastCompleted}) {
  const progressBarStyle = [
    { width: "0%", background: `${colorByState['COMPLETED']}` },
    { width: "33%", background: `${colorByState['COMPLETED']}` },
    { width: "66%", background: `${colorByState['COMPLETED']}` },
    { width: "100%", background: `${colorByState['FINISHED']}` }
  ]
  
  //Choice of colour for the progress bar on the function of the last stage completed and whether it has any disabled state or not.
  const colorBar = hasDisabledState && !isLastCompleted? progressBarStyle[lastStageIndex-1]: progressBarStyle[lastStageIndex]
    
  return (
    <div style={colorBar} className="h-100">
      <p className="invisible ">.</p>
    </div>
  );
}

export function InvitationListRowProgressBall({bgColor, data, index, lastData, isLastCompleted,hasDisabledState }) {
  //Props for the different presentations of balls: sm refers to small balls and md to medium balls.
  const mdSvg = {width:'22px', height:"22px", viewBox:"0 84 1 51.8",mr:'42.7px'}
  const smSvg = {width:'16px', height:"16px", viewBox:"0 90 1 41.8", mr:'44px'}
  const mdCircle = {r:"21.187",fill:`${bgColor}`,stroke:'white',strokeWidth:'6px'}
  const smCircle = {r:"15.68",fill:`${bgColor}`,stroke:'#CED2FF',strokeWidth:'7px'}
  //Define the type of ball to be rendered depending on whether the last stage is completed or disabled.
  let svgStyles = lastData.stageId === data.stageId && data.stageDetail.state !== 'DISABLED'? { ...mdSvg } : { ...smSvg };
  let circleStyles = lastData.stageId === data.stageId && data.stageDetail.state !== 'DISABLED' ? { ...mdCircle } : { ...smCircle };
  //Evaluate if the previous ball is the blocked ball
  if(hasDisabledState && (data.stageId === (lastData.stageId - 1 )) && !isLastCompleted){
    svgStyles={ ...mdSvg }
    circleStyles={ ...mdCircle }
  } 
  //Redefine the colours for the balls depending on the state.
  if(data.stageDetail.state === 'COMPLETED'){
      circleStyles.stroke = 'white';
    if(isLastCompleted) circleStyles.fill = colorByState['FINISHED']
  }else if(data.stageDetail.state === 'DISABLED'){
    circleStyles.stroke = 'transparent';
    circleStyles.fill = 'transparent'
  }
  
  return (
    <div style={{ height: "18px",marginRight: index !== 3 ?`${svgStyles?.mr}`:'0px'}} className="d-flex align-items-center position-relative">
      <div className="w-100 d-flex position-relative">
          <svg xmlns="http://www.w3.org/2000/svg"
            width={svgStyles?.width}
            height={svgStyles?.height}
            viewBox={svgStyles?.viewBox}
            fill="none">
            <circle
              cx="-4px"
              cy="110"
              r={circleStyles?.r}
              fill={circleStyles.fill}
              stroke={circleStyles.stroke}
              strokeWidth={circleStyles.strokeWidth}/>
          </svg> { /* Se cambio stroke-width a strokeWidth - El Sonar lo detecto como error. */ }
      </div>
    </div>
  );
}