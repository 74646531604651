import { createReducer } from "@reduxjs/toolkit";
import { fetchCAO } from "./OriginRoadSideEffect";

const initialState = {
    cao: {
        data: null,
        error: false,
        loader: false,
    }
}

const CAOReducer = createReducer( initialState, (builder) => {
    builder
    .addCase(
        fetchCAO.fulfilled, (state, action) => {
            state.cao.data = action.payload
            state.cao.error = false
            state.cao.loader = false
        }
    )
    .addCase(
        fetchCAO.pending, (state, action) => {
            state.cao.data = null
            state.cao.error = false
            state.cao.loader = true
        }
    )
    .addCase(
        fetchCAO.rejected, (state, action) => {
            state.cao.data = null
            state.cao.error = true
            state.cao.loader = false
        }
    )

})

export default CAOReducer;