import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";
import FirstStepsFirstGenerationRow from "./FirstStepsFirstGenerationRow";

export default function FirstStepsFirstGenerationInfiniteList({
  list,
  callbackExtras,
  biggerThan1000,
  isVisible,
  extraRef,
  loader,
  aidHide,
}) {
  const scrollbarRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const callExtraData = useSelector(
    (state) => state.firstStepsAllReducer.firstGenerationExtrasCall
  );

  const handleScroll = useCallback(() => {
    let maxScrollHeight =
      scrollbarRef.current.scrollHeight - scrollbarRef.current.clientHeight;

    let currentPosition = scrollbarRef.current.scrollTop;

    if (maxScrollHeight <= currentPosition + (aidHide ? aidHide : 0)) {
      callExtraData && callbackExtras();
    }
  }, []);

  useEffect(() => {
    const div = scrollbarRef?.current;
    div?.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <div
      ref={scrollbarRef}
      style={{
        paddingBottom: "73px",
        maxHeight: "1000px",
        minHeight: biggerThan1000
          ? "980px"
          : !windowWidth == 1280
          ? "350px"
          : "390px",
      }}
      className=" mt-2 hidden-y-scroll"
    >
      {list.map((elm) => {
        return (
          <div className="pb-4 mb-3 ">
            <FirstStepsFirstGenerationRow key={ShareKeyGenerator()} elm={elm} />
          </div>
        );
      })}
      <>
        {callExtraData && (
          <div
            ref={extraRef}
            className="mt-5 position-relative "
            style={{ height: "30px", zIndex: 5 }}
          >
            {isVisible && loader && (
              <div
                style={{ top: "-30px" }}
                className=" position-relative d-flex flex-column justify-content-center align-items-center"
              >
                <div className="spinner-border blueColor" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="museo-sans-500 s14 mt-2">
                  <FormattedMessage
                    id="group.incorporations.descendant.loading.more"
                    defaultMessage="Cargando más consultoras"
                  />
                </p>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
}
