import { useRef } from "react";

import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { updateProfilePicture } from "../../../common/redux/commonSideEffects";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import LoginCamera from "../components/LoginCamera";
import Loader from "../../../common/Share/Loader";
import LoginProfileModal from "../components/LoginProfileModal";
import LoginProfileCameraModal from "../components/LoginProfileCameraModal";
import RegisterAnalytics from "../../Framework/ga/RegisterAnalytics";

export default function LoginProfile() {
  const [uploadedImage, setUploadedImage] = useState({
    myFile: "",
  });
  const [capture, setCapture] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [btnActivated, setBtnActivated] = useState(false);
  const [componentKey, setComponentKey] = useState(Math.random());
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [firstTimePick, setFirstTimePick] = useState(false);
  const [tookPicture, setTookPicture] = useState(false);
  const [switchModal , setSwitchModal] = useState(false);

  const [modalRun, setModalRun] = useState(false);

  const loading = useSelector((state) => state.common.loading);

  const dispatch = useDispatch();
  const history = useNavigate();

  const profilePictureRef = useRef();
  const secondImageInputRef = useRef();

  const handleModal = (evt) => {
    evt.preventDefault();
    setModalRun(true);
  };

  const handleCapture = (screenshot) => {
    setCapture(screenshot);
    if (screenshot !== null) {
      setShowCamera(false);
      setComponentKey(Math.random());
    }
    closeCamera();
  };

  const activateButton = () => {
    setBtnActivated(true);
  };

  const handleUpload = async () => {
    const PP = profilePictureRef.current;

    const imageAsDataURL = PP.getImageAsDataUrl();

    await dispatch(updateProfilePicture(imageAsDataURL));
    history("/ready");
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (evt) => {
    evt.preventDefault();
    RegisterAnalytics("foto-upload")
    const file = evt.target.files[0];
    const base64 = await convertToBase64(file);
    setUploadedImage({ ...uploadedImage, myFile: base64 });
    setCapture(null);
    setShowCamera(false);
    setComponentKey(Math.random());
  };

  const handleShowCamControl = (boolean) => {
    setShowCamera(boolean);
  };

  const onOpenCamera = (evt) => {
    evt.preventDefault();
    setCapture(null);
    setShowCamera(false);
    setSwitchModal(true);
    setTookPicture(true);
    RegisterAnalytics("foto-selfie")
  };

  const closeCamera = () => {
    setSwitchModal(false)
  };

  const controlElementFirstButton = () => {
    secondImageInputRef.current.click();
    setFirstTimePick(true);
  };

  const controlElementSecondButton = () => {
    setFirstTimePick(true);
    setTookPicture(true);
  };

  const openCameraModal = () => {
    setModalRun(true);
    setSwitchModal(true);
    setShowCamera(false);
    setTookPicture(true);
    setCapture(null);
    
  }

  return (
    <div id="login-layout" className=" container--padding py-5">
      <LoginProfileModal
        handleModal={handleModal}
        onCameraControl={onOpenCamera}
        openCameraModal={openCameraModal}
        
      />
      {
        switchModal &&
        <LoginProfileCameraModal closeCamera={closeCamera}  imagenState={uploadedImage} selectedForCapture={handleCapture} />
      }
      <div className="position-relative">
        <h1 className="mt-3 grayColor s12 museo-sans-500 museo-sans-500   text-uppercase">
          <FormattedMessage
            id="login.profile.page.title"
            defaultMessage="1.Profile settings"
          />
        </h1>
        <hr></hr>
        {!isButtonDisabled ? (
          <>
            <h2 className=" museo-sans-700 s24 mt-4">
              <FormattedMessage
                id="login.profile.page.subtitle"
                defaultMessage="Profile picture"
              />
            </h2>
          </>
        ) : (
          <>
            <h2 className=" museo-sans-700 s24 mt-4">
              <FormattedMessage
                id="login.profile.page.subtitle.two"
                defaultMessage="Profile picture"
              />
            </h2>
          </>
        )}
        {isButtonDisabled ? (
          <>
            <p className="s14 museo-sans-500 ">
              <FormattedMessage
                id="login.profile.comment.two"
                defaultMessage="Before starting the website, please upload a profile picture that shows your face."
              />
            </p>
          </>
        ) : (
          <>
            <p className="s14 museo-sans-500 ">
              <FormattedMessage
                id="login.profile.comment"
                defaultMessage="Before starting the website, please upload a profile picture that shows your face."
              />
            </p>
          </>
        )}
        <>
          {(uploadedImage?.myFile != "" || (capture != null || showCamera  )) && (
            <>
              {!showCamera ? (
                <div
                  id="login--profile-container"
                  onClick={activateButton}
                  className="d-grid position-relative w-50 my-3 "
                >
                  <div
                    className="profile-picture-updater-padding-none profile-picture-updater-svg-fill upload-profile-picture-disable-trash-can upload-profile-picture-zoom-input-padding"
                    id="login--profile--canvas"
                  >
                    <ProfilePicture
                      frameSize={195}
                      minImageSize={150}
                      cropSize={255}
                      
                      key={componentKey}
                      ref={profilePictureRef}
                      useHelper={true}
                      image={
                        capture
                          ? capture
                          : uploadedImage.myFile
                          ? uploadedImage.myFile
                          : null
                      }
                      debug={true}
                      onImageLoaded={() => setIsButtonDisabled(true)}
                      className="blueColor"
                      frameFormat={"circle"}
                      messages={{
                        DEFAULT: (
                          <FormattedMessage
                            id="profile.editor.default"
                            defaultMessage="Click here to upload your image."
                          />
                        ),
                        DRAGOVER: (
                          <FormattedMessage
                            id="profile.editor.dragover"
                            defaultMessage="Drop the file."
                          />
                        ),
                        INVALID_FILE_TYPE: (
                          <FormattedMessage
                            id="profile.editor.type"
                            defaultMessage="Image type file allowed only."
                          />
                        ),
                        INVALID_IMAGE_SIZE: (
                          <FormattedMessage
                            id="profile.editor.image"
                            defaultMessage="The file size must be above 350px."
                          />
                        ),
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div style={{ maxWidth: "160px" }} className="my-2">
                  <LoginCamera
                    capture={capture}
                    handleCapture={handleCapture}
                    handleShowCamera={handleShowCamControl}
                    styles={{
                      videoContainerWidth: "w-100",
                      screenshotContainerWidth: "w-100",
                      inConfig: true,
                    }}
                  />
                </div>
              )}
            </>
          )}
        </>

        <div className="position-relative my-3 d-flex align-items-center">
          {showCamera ? (
            <>
              <button
                onClick={() => secondImageInputRef.current.click()}
                style={{ width: "184px", height: "40px" }}
                className="btnBlueBorders skyColor skyBorder s14 museo-sans-500  bg-transparent  py-2"
              >
                {" "}
                <input
                  className="d-none "
                  ref={secondImageInputRef}
                  type="file"
                  label="Image"
                  name="myFile"
                  accept=".jpeg, .png, .jpg"
                  onChange={(e) => handleFileUpload(e)}
                />
                <FormattedMessage
                  id="buttons.upload.photo"
                  defaultMessage="Upload photo"
                />{" "}
                <i className="py-1 bi bi-upload"></i>
              </button>
              <button
                style={{ width: "184px", height: "40px" }}
                className="btnBlueBorders button-disabled-border skyColor skyBorder disabledCursor opacity-25 museo-sans-500 bg-transparent s14 mx-3 py-2"
              >
                
                <FormattedMessage
                  id="buttons.select.photo"
                  defaultMessage="Sacar foto"
                />{" "}
                <i className="bi bi-camera my-1"></i>
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => controlElementFirstButton()}
                style={{ width: "184px", height: "40px" }}
                className="btnBlueBorders skyColor skyBorder s14 museo-sans-500  bg-transparent py-2"
              >
                {" "}
                <input
                  className="d-none"
                  ref={secondImageInputRef}
                  type="file"
                  label="Image"
                  name="myFile"
                  accept=".jpeg, .png, .jpg"
                  onChange={(e) => handleFileUpload(e)}
                />
                <FormattedMessage
                  id="buttons.upload.photo"
                  defaultMessage="Upload photo"
                />{" "}
                <i className="bi bi-upload my-1"></i>
              </button>
              {modalRun ? (
                <button
                  onClick={onOpenCamera}
                  style={{ width: "184px", height: "40px" }}
                  className="btnBlueBorders skyColor skyBorder museo-sans-500  bg-transparent s14 mx-3 py-2"
                >
                  {
                    tookPicture ?
                    <>
                      <FormattedMessage
                      id="buttons.select.photo.2"
                      defaultMessage="Sacar foto"
                    />{" "}
                    </>
                    :
                      <>
                      <FormattedMessage
                    id="buttons.select.photo"
                    defaultMessage="Select photo"
                  />{" "}
                      </>
                  }
                  
                  <i className="my-1 bi bi-camera"></i>
                </button>
              ) : (
                <button
                  onClick={controlElementSecondButton}
                  style={{ width: "184px", height: "40px" }}
                  className="btnBlueBorders skyColor skyBorder museo-sans-500  bg-transparent  s14 mx-3 py-2"
                  data-bs-toggle="modal"
                  data-bs-target="#profileModalLabel"
                >
                  <FormattedMessage
                    id="buttons.select.photo"
                    defaultMessage="Sacar foto"
                  />{" "}
                  <i className="my-1 bi bi-camera"></i>
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className=" position-relative align-self-end">
        {isButtonDisabled && !showCamera ? (
          <button
            className=" position-relative s14  museo-sans-500  py-2 text-white btnCustom skyBg btnLoginWidth"
            onClick={handleUpload}
          >
            <Loader loading={loading} />
            <p className=" my-1">
              <FormattedMessage
                id="buttons.continue"
                defaultMessage="Continue"
              />
            </p>
          </button>
        ) : (
          <button className=" disabledCursor disabled opacity-25 position-relative s14  museo-sans-500  py-2 text-white btnCustom skyBg btnLoginWidth">
            <Loader loading={loading} />
            <p className=" my-1">
              <FormattedMessage
                id="buttons.continue"
                defaultMessage="Continue"
              />
            </p>
          </button>
        )}
      </div>
    </div>
  );
}
