import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import ImageCircleHandler from "../../../../common/Share/ImageCircleHandler";
import InvitationsDetailsButtonMessage from "../InvitationsDetailsButtonMessage";
import InvitationsDetailsErrorMessage from "../InvitationsDetailsErrorMessage";
import InvitationsListTableRowProgress from "../InvitationsListTableRowProgress";
import InvitationsModalShareLink from "./InvitationsModalShareLink";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGRightArrow } from "../../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as SVGMail } from "../../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../../common/svgs/social/ic-whatsapp-md.svg";
import { newInvitationCopy } from "../../../Framework/redux/InvitationsAction";
import InvitationsListTableModal from "./InvitationsListTableModal";
import { changePersonalGroupName, pushInsideArray } from "../../../../Descendants/Framework/redux/DescendantsAction";
import { useNavigate } from "react-router-dom";
import { capitalizeString } from "../../../../common/utils/stringUtilities";
import capFirstLetter from "../../../../common/Share/ShareCapFirstLetter";


export default function InvitationsDashboardModalDetails({ id }) {
  const dataDetails = useSelector(
    (state) => state.invitationData?.invitationDetail?.data
  );

  const dispatch = useDispatch();
  const history = useNavigate();

  const onShowModalCopy = () => {
    dispatch(newInvitationCopy());
  };

  const configExist = (auxId) => {
    let resolve = -1;
    if (dataDetails?.inviteeInfo?.contactInfo) {
      resolve = dataDetails?.inviteeInfo?.contactInfo.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const onProfileAsc = ({ id, name, uplineId, uplineName }) => {
    dispatch(changePersonalGroupName({name: name, clientId: id }))
    dispatch(pushInsideArray({clientId: uplineId, name: uplineName }))

    history(`/dashboard/descendants/profile/asc/${uplineId}`);
  };

  return (
    <>
      {dataDetails?.inviteeInfo && (
        <>
        <InvitationsModalShareLink firstName={dataDetails?.inviteeInfo?.firstName} lastName={dataDetails?.inviteeInfo?.lastName} reInvite={true} open={onShowModalCopy} id={dataDetails?.id} data={dataDetails?.inviteeInfo?.invitationLink} socialArray={dataDetails?.inviteeInfo?.contactInfo} />
        <InvitationsListTableModal id={dataDetails?.id} />
        <div
          className="modal fade"
          id={`modal-invitations-details-${id}`}
          aria-hidden="true"
          aria-labelledby={`modal-invitations-details-${id}`}
          tabIndex="-1"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "592px" }}
          >
            <div className="modal-content modal-radius">
              <div className="modal-body p-0">
                <div className="">
                  <div
                    style={{
                      paddingLeft: "42px",
                      paddingRight: "42px",
                      borderRadius: "16px 16px 0px 0px",
                    }}
                    className="bgGray20  pt-4 py-4"
                  >
                    <div className="d-flex  justify-content-between  ">
                      <div className="d-flex w-100 align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-3">
                          <div>
                            <ImageCircleHandler
                              width={"60px"}
                              size={24}
                              firstName={dataDetails?.inviteeInfo?.firstName}
                              lastName={dataDetails?.inviteeInfo?.lastName}
                            />
                          </div>
                          <div>
                            <h5 className="museo-sans-700 s20 m-0">
                            {capitalizeString(`${dataDetails?.inviteeInfo?.firstName} ${dataDetails?.inviteeInfo?.lastName}`)}
                            </h5>
                            <>
                              {
                                dataDetails?.inviteeInfo?.clientProfile && 
                                <>
                                  <div className=" museo-sans-500 d-flex flex-column">
                                    <hr className="my-1" />
                                    <div 
                                     className="d-flex gap-4 ">
                                      <p className=" grayColor"><FormattedMessage id="invitation.modal.details.incorp" />: </p>
                                      <div 
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      onClick={() =>{
                                      onProfileAsc({ id: dataDetails?.id, name:dataDetails?.inviteeInfo?.ImageCircleHandlerclientProfile?.name, uplineId: dataDetails?.inviteeInfo?.clientProfile?.uplineId, uplineName: dataDetails?.inviteeInfo?.clientProfile?.uplineName})}}
                                       className="pointer museo-sans-700 d-flex gap-1 skyColor ">
                                      <p>{dataDetails?.inviteeInfo?.clientProfile?.uplineName}</p>
                                        <div>
                                          <SVGRightArrow />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                              </>
                              <p className=" grayColor museo-sans-500 s14 " >
                                {
                                  capFirstLetter(dataDetails?.inviteeInfo?.reference)
                                }
                              </p>
                          </div>
                        </div>
                        <div
                          className="pointer position-relative svg-force-size "
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          style={{ width: "24px", height: "24px", top: -2 }}
                        >
                          <SVGClose />
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="m-0"></hr>
                  <div
                    style={{ marginLeft: "42px", marginRight: "42px" }}
                    className="d-flex flex-column gap-2 align-items-center s16 museo-sans-500  "
                  >
                    <div className=" d-flex flex-column gap-2 mt-3 pt-1 w-100 museo-sans-500 ">
                        <div className="pb-3" >
                            <InvitationsListTableRowProgress
                              restOfData={dataDetails}
                              data={dataDetails?.stages}
                            />
                        </div>
                      <InvitationsDetailsErrorMessage
                        stages={dataDetails?.stages}
                        details={true}
                      />
                      <div className="my-4 d-flex justify-content-between">
                        <div className="d-flex museo-sans-500 gap-4 " >
                        {configExist("whatsapp") !== -1 && (
                            <a
                              href={`https://wa.me/${dataDetails?.inviteeInfo?.contactInfo[configExist("whatsapp")].value}?text=`}
                              target="_blank"
                              className="ms-2 bronceStroke grayColor cancelBootstrap"
                            >
                              <div
                                style={{ alignItems: "center" }}
                                className=" pointer d-flex flex-column justify-content-center"
                              >
                                <div
                                  style={{
                                    width: "64px",
                                    height: "64px",
                                    alignItems: "center",
                                  }}
                                  className=" bubble-social rounded-circle d-flex justify-content-center"
                                >
                                  <SVGWhatsap />
                                </div>
                                <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                                  <FormattedMessage id="social.sharedata.link.wap" />
                                </p>
                              </div>
                            </a>
                          )}
                          {configExist("email") !== -1 && (
                            <a
                              href={`mailto:${
                                dataDetails?.inviteeInfo?.contactInfo[configExist("email")].value
                              }?&subject=""&body=`}
                              
                              target="_blank"
                              className="ms-2 bronceStroke grayColor cancelBootstrap"
                            >
                              <div
                                style={{ alignItems: "center" }}
                                className=" pointer d-flex flex-column justify-content-center"
                              >
                                <div
                                  style={{
                                    width: "64px",
                                    height: "64px",
                                    alignItems: "center",
                                  }}
                                  className=" bubble-social rounded-circle d-flex justify-content-center"
                                >
                                  <SVGMail />
                                </div>
                                <p className=" museo-sans-500 s14 p-1 d-flex justify-content-center text-center text-capitalize">
                                  <FormattedMessage id="social.sharedata.link.mail" />
                                </p>
                              </div>
                            </a>
                          )}
                          
                        </div>
                        <InvitationsDetailsButtonMessage
                          stages={dataDetails?.stages}
                          id={dataDetails?.id}
                          data={dataDetails}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
}
