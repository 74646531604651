import React from 'react'
import { ReactComponent as ChevronRightSVG } from "../../../common/svgs/ic-chevron-right-md.svg";

export default function PerformanceLoadingMoreRequests({text, small, type}) {

    const switchSkeleton = (type, text, small) => {
        switch (type) {
            case "personal":
                return <SkeletonPersonalSaleList/>
            case "inc":
                return <SkeletonIncList/>
            case "group":
                return <SkeletonQualifiedGroups/>
            default:
                return <PreviousFunction text={text} small={small} />
        }
    }

  return (
    switchSkeleton(type, text, small)
  )
}

export function PreviousFunction({text, small}) {
    return <div className="d-flex flex-column justify-content-center align-items-center">
        <div className={`spinner-border ${small ? "spinner-border-sm" : ""} blueColor`} role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        {
            text ?
                <p className="museo-sans-500 s14 mt-2">
                    {text}
                </p>
            :
                null
        }
        
    </div>
}

export function SkeletonPersonalSaleList() {

    const rowListed = () => {
        return <div className="d-flex gap-5 align-items-center justify-content-between " >
        <div style={{height: "12px", width: "68px"}} className="lightui1-shimmer rounded-3 brightGrayBg " >

        </div>
        <div style={{height: "12px", width: "68px"}} className="lightui1-shimmer rounded-3 brightGrayBg " >

        </div>
        <div style={{height: "12px", width: "68px"}} className="lightui1-shimmer rounded-3 brightGrayBg " >

        </div>
        <div style={{height: "12px", width: "68px"}} className="lightui1-shimmer rounded-3 brightGrayBg " >

        </div>
        <div style={{height: "12px", width: "68px"}} className="lightui1-shimmer rounded-3 brightGrayBg " >

        </div>
        <div className="personal-sales-chevron skyColor button-hover-svg-light-stroke">
            <ChevronRightSVG />
        </div>
</div>
    }

    return <div className=' mt-3 d-flex flex-column gap-3' >
        <SkeletonPersonalSaleRow/>
        <hr></hr>
        <SkeletonPersonalSaleRow/>
        <hr></hr>
        <SkeletonPersonalSaleRow/>
        <hr></hr>
        <SkeletonPersonalSaleRow/>
    </div>
}

export function SkeletonPersonalSaleRow() {
    return <div className="d-flex gap-5 align-items-center justify-content-between " >
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg  lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg  lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg  lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

    </div>
    <div className="personal-sales-chevron skyColor button-hover-svg-light-stroke">
        <ChevronRightSVG />
    </div>
</div>
}

export function SkeletonIncList() {

    return <div className=' mt-3 d-flex flex-column gap-3' >
        <SkeletonIncRow/>
        <hr></hr>
        <SkeletonIncRow/>
        <hr></hr>
        <SkeletonIncRow/>
        <hr></hr>
        <SkeletonIncRow/>
    </div>
}

export function SkeletonIncRow() {
    return <div className="d-flex gap-5 align-items-center justify-content-between " >
        <div className='d-flex gap-3' >
            <div style={{width: "33px", height: "33px"}} className=' rounded-circle brightGrayBg lightui1-shimmer' >

            </div>
            <div className='d-flex flex-column justify-content-center gap-2' >
                <div style={{height: "12px", width: "105px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

                </div>
                <div style={{height: "8px", width: "74px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

                </div>
            </div>
        </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

    </div>
    <div style={{height: "12px", width: "68px"}} className=" rounded-3 brightGrayBg lightui1-shimmer" >

    </div>
</div>
}

export function SkeletonQualifiedGroups() {
    return <div className='d-flex wrapper flex-wrap gap-3' >
        <SkeletonQualifiedGroupsCard />
        <SkeletonQualifiedGroupsCard />
        <SkeletonQualifiedGroupsCard />
        <SkeletonQualifiedGroupsCard />
        <SkeletonQualifiedGroupsCard />
        <SkeletonQualifiedGroupsCard />
    </div>
}

export function SkeletonQualifiedGroupsCard() {
    return <div className='qualified-card  ' >
        <div  className='d-flex  gap-3 ' >
            <div style={{width: "72px", height: "72px"}} className=" lightui1-shimmer rounded-circle brightGrayBg"  >

            </div>
            <div className=' mx-1 d-flex flex-column' >
                <div className='d-flex gap-2' >
                    <div style={{width: "180px", height: "12px"}} className='lightui1-shimmer rounded-3 brightGrayBg ' >

                    </div>
                    <div style={{width: "16px", height: "16px"}} className="lightui1-shimmer rounded-circle brightGrayBg" >

                    </div>
                </div>
                <div>
                    <div style={{width: "130px", height: "12px"}} className='lightui1-shimmer rounded-3 brightGrayBg ' >

                    </div>
                </div>
                <div className='d-flex gap-3' >
                    <div style={{width: "23px", height: "23px"}} className="lightui1-shimmer rounded-circle brightGrayBg" >

                    </div>
                    <div style={{width: "23px", height: "23px"}} className="lightui1-shimmer rounded-circle brightGrayBg" >

                    </div>
                    <div style={{width: "23px", height: "23px"}} className="lightui1-shimmer rounded-circle brightGrayBg" >

                    </div>
                    <div style={{width: "23px", height: "23px"}} className="lightui1-shimmer rounded-circle brightGrayBg" >

                    </div>
                </div>
            </div>

        </div>
        <hr className=' my-4' ></hr>
        <div className='d-flex flex-column gap-4 mb-4 ' >
                <SkeletonQualifiedGroupsCardLowerRow />
                <SkeletonQualifiedGroupsCardLowerRow />
                <SkeletonQualifiedGroupsCardLowerRow />
        </div>
        <div>
            <div style={{height: "8px"}} className='lightui1-shimmer rounded-3 w-25 brightGrayBg' >

            </div>
        </div>
    </div>
}

export function SkeletonQualifiedGroupsCardLowerRow() {
    return <>
        <div className=' d-flex flex-column gap-2 ' >
            <div className=' d-flex justify-content-between gap-2 ' >
                <div className='w-100' >
                    <div style={{height: "12px"}} className='lightui1-shimmer rounded-3 w-100 brightGrayBg' >

                    </div>
                </div>
                <div className='w-100 d-flex gap-1 justify-content-center ' >
                    <div style={{height: "8px"}} className='lightui1-shimmer rounded-3 w-100 brightGrayBg' >

                    </div>
                    <div style={{height: "8px"}} className='lightui1-shimmer rounded-3 w-100 brightGrayBg' >

                    </div>
                </div>
            </div>
            <div className=' d-flex justify-content-between ' >
                <div style={{height: "12px"}} className='lightui1-shimmer rounded-3 w-100 brightGrayBg' >

                </div>
            </div>
        </div>
    </>
}