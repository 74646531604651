import ReformatLevelByGender from "../../../../../common/Share/ReformatLevelByGender";

import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as Red } from "../../../../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as Gray } from "../../../../../common/svgs/ic-alert-circle-grey.svg";
import { ReactComponent as Yellow } from "../../../../../common/svgs/ic-alert-circle-yellow.svg";
import { ReactComponent as Green } from "../../../../../common/svgs/ic-check-circle.svg";
import { ReactComponent as Purple } from "../../../../../common/svgs/ic-req-red.svg";

import { formatAmount } from "../../../../Share/FormatCurrency";

export default function HeaderModalStatus({ status, level }) {

    const periodGap = useSelector((state) => state.headerData.period.periodProcessGap);
    const profileData = useSelector((state) => state.userInfo);
    const scoreData = useSelector((state) => state.scoreData);
    const cultureInfo = useSelector((state) => state.userInfo.userInfo.cultureInfo);

let actualAmount = scoreData?.generalDataArray?.triperiodicSales?.actualAmount
let totalAmount = scoreData?.generalDataArray?.triperiodicSales?.totalAmount
 
  const switchContent = (status, type, level, gap) => {
    const statusComponents = {
        reduced: {
            status: <Purple />,
            content: <FormattedMessage id="dashboard.modal.status.reduced" />,
            title: <FormattedMessage id="dashboard.modal.status.reduced.title" />
        },
        warning: {
            status: <Yellow />,
            content: level < 2 ? (
                    <p>
                        <FormattedMessage id="dashboard.modal.warning.level.one" />{" "}
                        {formatAmount((totalAmount - actualAmount), cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}{" "}
                        <FormattedMessage id="dashboard.modal.warning.level.two" />
                    </p>
            ) : (
                <p>
                    <FormattedMessage id="dashboard.modal.status.risk.one" />
                    <ReformatLevelByGender gender={profileData.userInfo.gender} level={profileData.userInfo.level} roleDescriptionDefault={profileData.userInfo.roleDescription} />
                    <FormattedMessage id="dashboard.modal.status.risk.two" />
                </p>
            ),
            title: <FormattedMessage id="dashboard.modal.status.warning.title.one" />
        },
        risk: {
            status: <Red />,
            content: (
                    <p>
                        <FormattedMessage id="dashboard.modal.status.risk.one" />
                        <ReformatLevelByGender gender={profileData.userInfo.gender} level={profileData.userInfo.level} roleDescriptionDefault={profileData.userInfo.roleDescription} />
                        <FormattedMessage id="dashboard.modal.status.risk.two" />
                    </p>
            ),
            title: <FormattedMessage id="dashboard.modal.status.risk.title.two" />
        },
        completed: {
            status: <Green />,
            content: level > 1 ? (
                <p>
                    <FormattedMessage id="dashboard.modal.status.completed.one" />
                    <ReformatLevelByGender gender={profileData.userInfo.gender} level={profileData.userInfo.level} roleDescriptionDefault={profileData.userInfo.roleDescription} />
                    <FormattedMessage id="dashboard.modal.status.completed.two" />
                </p>
            ) : (
                <p>
                    <FormattedMessage id="dashboard.modal.status.completed.consultora.one" />
                </p>
            ),
            title: level > 1 ? (
                <FormattedMessage id="dashboard.modal.status.completed.title" />
            ) : (
                <FormattedMessage id="dashboard.modal.status.completed.title.consultant" />
            )
        }
    };

    if (gap) {
      return type === 'status' ? <Gray /> : type === 'title'  ?  <FormattedMessage id="dashboard.modal.status.gap.title" /> : <FormattedMessage id="dashboard.modal.status.gap.content" />
    };

    return statusComponents[status]?.[type] ?? <FormattedMessage id="dashboard.modal.status.completed.title" />
};


  return (
    <div
      className="modal fade"
      id="modalStatus"
      aria-hidden="true"
      aria-labelledby="modalStatus"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "608px", zIndex: 20000 }}
      >
        <div className="modal-content modal-radius">
          <div className="modal-body p-0">
            <div>
              <div className="d-flex justify-content-between m-3 p-4 align-items-center">
                <div className="d-flex">
                  <div style={{width: "24px", height: "24px"}} >
                    {
                      switchContent(status, "status", level, periodGap)
                    }
                  </div>
                  <h5 className="museo-sans-500 px-2 s20 m-0">
                    {
                      switchContent(status, "title", level, periodGap)
                    }
                  </h5>
                </div>
                <div
                  className=" pointer "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  
                  <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className=" mx-3 px-4">
                <div className=" pt-3 mt-3">
                  <p className=" museo-sans-500 s16">
                    {
                      switchContent(status, "content", level, periodGap)
                    }
                  </p>
                </div>
                <div className=" my-3 py-4 d-flex flex-column align-items-center">
                  <button
                    type="button"
                    className=" btnLoginWidth mb-3 museo-sans-700 s14 skyBg whiteColor skyBorder button-hover-light button-border-none button-bootstrap-radius button-padding"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <FormattedMessage
                      id="buttons.understood"
                      defaultMessage="si"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}