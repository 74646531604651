import { createReducer, isAnyOf } from "@reduxjs/toolkit";

import { getEventDates, getEventsForSpecificDate, calendarCurrentPeriod, currentYearPeriods, pastYearPeriods, nextYearPeriods, thisYearPeriodData } from "./CalendarSideEffects";
import { changeEventSectionTitle, modifyNav, putEventId, resetNav, setDetailsModal } from "./CalendarActions";

const initialState = {
  dates: [],
  events: [],
  loadingEvents: false,
  currentDayTitle: "Tus eventos de hoy",
  nav: 0,
  eventId: null,
  currentPeriodInfo: null,
  thisYearPeriods: null,
  pastYearPeriods: null,
  nextYearPeriods: null,
  detailsModal: null,
  datesData: null,
  skeletonLoader: true,
};

const calendarReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setDetailsModal, (state, action) => {
      state.detailsModal = action.payload;
    })

    .addCase(putEventId, (state, action) => {
      state.eventId = action.payload;
    })

    .addCase(changeEventSectionTitle, (state, action) => {
        state.currentDayTitle = action.payload;
      })

    .addCase(modifyNav, (state, action) => {
        if(action.payload === true){
            state.nav = state.nav + 1;
        } else {
            state.nav = state.nav - 1;
        }
    })

    .addCase(resetNav, (state, action) => {
        state.nav = 0
    })

    .addCase(thisYearPeriodData.fulfilled, (state, action) => {
      state.datesData = action.payload;
      state.skeletonLoader = false;
    })
    .addCase(thisYearPeriodData.pending, (state, action) => {
      state.skeletonLoader = true;
    })
    .addCase(thisYearPeriodData.rejected, (state, action) => {
      state.skeletonLoader = false;
    })

    .addCase(getEventDates.fulfilled, (state, action) => {
      state.dates = action.payload.dates;
    })
    .addCase(getEventsForSpecificDate.fulfilled, (state, action) => {
        state.events = [...action.payload.events]
        state.loadingEvents = false
    })
    .addCase(getEventsForSpecificDate.pending, (state, action) => {
        state.loadingEvents = true
    })
    .addCase(getEventsForSpecificDate.rejected, (state, action) => {
        state.loadingEvents = false
    })
    .addCase(calendarCurrentPeriod.fulfilled, (state, action) => {
        state.currentPeriodInfo = action.payload
    })
    .addCase(currentYearPeriods.fulfilled, (state, action) => {
        state.thisYearPeriods = action.payload
    })
    .addCase(pastYearPeriods.fulfilled, (state, action) => {
        state.pastYearPeriods = action.payload
    })
    .addCase(nextYearPeriods.fulfilled, (state, action) => {
        state.nextYearPeriods = action.payload
    })

    .addMatcher(
      
      isAnyOf(
        getEventDates.pending,
        calendarCurrentPeriod.pending,
        currentYearPeriods.pending,
        pastYearPeriods.pending,
        nextYearPeriods.pending,
      ),
      (state, action) => {
      }
    )
    .addMatcher(
      isAnyOf(
        getEventDates.rejected,
        calendarCurrentPeriod.rejected,
        currentYearPeriods.rejected,
        pastYearPeriods.rejected,
        nextYearPeriods.rejected,
      ),
      (state, action) => {
      }
    );
});

export default calendarReducer;