import { useParams } from "react-router-dom";
import ClientListFormOneColumnInput from "../elements/ClientListFormOneColumnInput"
import ClientListFormSelect from "../elements/ClientListFormSelect"

export default function Peru({ watch, ...props }) {
  const params = useParams()
  const isUpdate = !!(params?.operation == 'update')
  

  return (
    <>
      <ClientListFormOneColumnInput
        placeholder={"Ej.: Calle y Número"}
        label={"Dirección (opcional)"}
        name="street"
        {...props}
      />
      <div className=" px-3 pe-0">
      <ClientListFormSelect
        placeholder={"Seleccionar un Departamento"}
        label={"Departamento (opcional)"}
        name="department"
        father={true}
        isUpdate={isUpdate}
        watch={watch}
        {...props}
        level={1}
        />

      </div>
        <ClientListFormSelect
          placeholder={"Seleccionar un provincia"}
          label={"Provincia (opcional)"}
          father={watch('department')}
          name="district"
          isUpdate={isUpdate}
          watch={watch}
          {...props}
          level={2}
        />
      <div className=" px-3 pe-0">

        <ClientListFormSelect
          placeholder={"Seleccionar una distrito"}
          label={"Distrito (opcional)"}
          father={watch('district')}
          name="city"
          isUpdate={isUpdate}
          watch={watch}
          {...props}
          level={3}
          last
        />
      </div>
        <ClientListFormOneColumnInput
          placeholder={"Ej.: Referencia"}
          label={"Referencia (opcional)"}
          name="reference"
          type="number"
          maxLength={15}
          {...props}
        />
    </>
  )
}