import React from 'react'


import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaPredicate } from 'react-media-hook';
import { FormattedMessage } from 'react-intl';

import PersonalSalesWeekSalesModal from '../components/PersonalSalesWeekSalesModal';

import { extraIncorporationsSalesList, getWeeklyIncorporationsSales, incorporationsSalesList } from '../../Framework/redux/performanceSideEffects';
import { calendarCurrentPeriod } from '../../../Calendar/Framework/redux/CalendarSideEffects';

import {ReactComponent as FilterIcon} from '../../../common/svgs/filter-sm.svg'
import {ReactComponent as DollarIcon} from '../../../common/svgs/ic-dollar-sm.svg'
import {ReactComponent as RequestsIcon} from '../../../common/svgs/ic-pedidos-sm.svg'
import {ReactComponent as RedWarningIcon} from '../../../common/svgs/ic-red-triangle-warning-with-circle.svg'


import GroupSalesWeekRow from '../components/GroupSalesWeekRow';
import GroupSalesRequestRow from '../components/GroupSalesRequestRow';
import PerformanceLoadingMoreRequests from '../components/PerformanceLoadingMoreRequests';
import PerformanceGroupIncorpDescendantModal from '../components/PerformanceGroupIncorpDescendantModal';
import GroupSalesFilter from '../components/GroupSalesFilter';
import NoRegistersMessage from './../../../common/Share/NoRegistersMessage';
import ShareKeyGenerator from '../../../common/Share/ShareKeyGenerator';

export default function IncorporationsSales({consultantId}) {

    const [order, setOrder] = useState({
        highLow: true,
        sale: true ,
        checkFilter: 1, 
        selectedWeeks:[{id: 1, value: true},{id: 2, value: false},{id: 3, value: false},{id: 4, value: false},{id: 5, value: false}],
        selectedStatusOptions:[{id: 1, value: false},{id: 2, value: false},{id: 3, value: false},{id: 4, value: false}]
    });
    const [toggleOrganizer, setTogglerOrganizer] = useState(false);

    const headerData = useSelector((state) => state?.headerData);

    const [isVisible, setIsVisible] = useState()

    const smallerThan1280 = useMediaPredicate("(max-width: 1280px)");

    const dispatch = useDispatch()

    const extraGroupLoaderRef = useRef()

    const [switchButtons, setSwitchButtons] = useState(true)

    const onStartUp = async () => {
        if(headerData.period?.currentYearPeriod){
            dispatch(getWeeklyIncorporationsSales({yearPeriod: headerData.period?.currentYearPeriod, consultantId: consultantId}))
            dispatch(calendarCurrentPeriod())
            dispatch(incorporationsSalesList({yearPeriod: headerData.period?.currentYearPeriod, sortBy: null, filterType: null, orders: null, weeks: null, consultantId: consultantId}))
        }
    }

    useEffect(() => {
      onStartUp()
    }, [headerData.period])

    const currentPeriodInfo = useSelector(state => state.calendarData.currentPeriodInfo)

    const weekSalesInfo = useSelector(state => state.performanceData.salesByWeek)

    const incorporationsSalesInfo = useSelector(state => state.performanceData.incorporationsSales)
    const extraIncorporationsSales = useSelector(state => state.performanceData.extraIncorporationsSales)
    const incorporationsSalesLoader = useSelector(state => state.performanceData.incorporationsSalesLoader)
    const extraIncorporationsSalesLoader = useSelector(state => state.performanceData.extraIncorporationsSalesLoader)
    const filtersApplied = useSelector(state => state.performanceData.filtersApplied)
    const filters = useSelector(state => state.performanceData.filters)
    

    useEffect(() => {
        if(extraGroupLoaderRef.current){
            const observer = new IntersectionObserver((entries) => {
                const entry = entries[0]
                setIsVisible(entry.isIntersecting)
            })
            observer.observe(extraGroupLoaderRef.current)
        }

        if(isVisible){
            if(filtersApplied){
                let tempProps = JSON.parse(JSON.stringify(filters));
                tempProps.consultantId = consultantId;
                dispatch(extraIncorporationsSalesList(tempProps))
            } else {
               dispatch(extraIncorporationsSalesList({yearPeriod: headerData.period?.currentYearPeriod, sortBy: null, filterType: null, orders: null, weeks: null, consultantId: consultantId})) 
            }
        }

    }, [isVisible, extraGroupLoaderRef.current, incorporationsSalesLoader])
    
    const current = currentPeriodInfo?.periodWeeks.filter((element) => {
        return element.week === currentPeriodInfo?.currentWeek
    })

    let currentWeekForModal
    if(current !== undefined){
        currentWeekForModal = current[0]?.id
    }
    
    

  return (
    <div className='d-flex justify-content-between flex-column'>
        <PerformanceGroupIncorpDescendantModal 
            titulo={<FormattedMessage id="incorporations.sales.help.modal.title" defaultMessage="Venta incorp"/>} 
            primerParrafo={<FormattedMessage id="incorporations.sales.help.modal.first.paragraph" defaultMessage="Primer parrafo venta incorp"/>}
        />
        <PersonalSalesWeekSalesModal semanaActual={currentWeekForModal} dataVentasSemanales={weekSalesInfo}/>
        <div className='container--padding--card-holder' >
            <div>
                <div className=' w-100 d-flex justify-content-between'>
                    <div className="d-flex flex-wrap justify-items-center align-items-center lightVioletBg p-1 rounded-3">
                        <div onClick={() => setSwitchButtons(true)} className={`${switchButtons ? "bronceBg" : "hover-pointer"} rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center`}>
                            <DollarIcon />
                            <p className='ms-2 museo-sans-700 s14 lightBlueColor user-select-none'><FormattedMessage id="group.incorporations.descendant.switch.week.sales" defaultMessage="Venta semanal" /></p>
                        </div>
                        <div onClick={() => setSwitchButtons(false)} className={`${!switchButtons ? "bronceBg" : "hover-pointer"} rounded-3 ps-4 pe-4 pt-1 pb-1 d-flex align-items-center`}>
                            <RequestsIcon />
                            <p className='ms-2 museo-sans-700 s14 lightBlueColor user-select-none'><FormattedMessage id="group.incorporations.descendant.switch.request.status" defaultMessage="Status pedidos" /></p>
                        </div>
                    </div>
                    <div className='d-flex align-items-center'>
                        <button 
                            data-bs-toggle="modal"
                            data-bs-target="#weekSalesModal"
                            className={`${smallerThan1280 ? "ps-2 pe-2 me-2" : "ps-4 pe-4 me-4"} museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}
                        >
                            <FormattedMessage id="performance.week.detail.button" defaultMessage="Detalle semana" />
                        </button>
                        <button 
                            data-bs-toggle="modal"
                            data-bs-target="#modalEDBFilter"
                            className={`${smallerThan1280 ? "ps-2 pe-2 me-2" : "ps-4 pe-4 me-4"} d-flex align-items-center justify-content-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}
                        >
                            <div className='position-relative'>
                                <FilterIcon />
                                {
                                    filtersApplied ?
                                        <div className='rounded-circle position-absolute' style={{width: "8px", height: "8px", backgroundColor: "#F06E69", top: "1px", left:"11px"}}></div>
                                    :
                                        null
                                }
                            </div>
                            <p className='ms-1'><FormattedMessage id="performance.week.filter.button" defaultMessage="Filtrar" /></p>
                        </button>
                    </div>
                </div>
                {
                        switchButtons === true ? 
                            <div className="w-100 d-flex s12 museo-sans-500 grayColor justify-content-between align-items-center row-height mb-2">
                                <div className="d-flex performance-name-week-sales-width">
                                    <FormattedMessage id="group.incorporations.descendant.name" defaultMessage="NOMBRE" />
                                </div>
                                <div  className="d-flex justify-content-end performance-week-width">
                                    <FormattedMessage id="group.incorporations.descendant.week.1" defaultMessage="SEMANA 1" />
                                </div>
                                {
                                    headerData.currentWeek < 2 ?
                                    <div  className="d-flex brightGrayColor justify-content-end performance-week-width">
                                        <FormattedMessage id="group.incorporations.descendant.week.2" defaultMessage="SEMANA 2" />
                                    </div>
                                :
                                    <div  className="d-flex justify-content-end performance-week-width">
                                        <FormattedMessage id="group.incorporations.descendant.week.2" defaultMessage="SEMANA 2" />
                                    </div>
                                }
                                {
                                    headerData.currentWeek < 3 ?
                                    <div  className="d-flex brightGrayColor justify-content-end performance-week-width">
                                        <FormattedMessage id="group.incorporations.descendant.week.3" defaultMessage="SEMANA 3" />
                                    </div>
                                :
                                    <div  className="d-flex justify-content-end performance-week-width">
                                        <FormattedMessage id="group.incorporations.descendant.week.3" defaultMessage="SEMANA 3" />
                                    </div>
                                }
                                {
                                    headerData.currentWeek < 4 ?
                                    <div  className="d-flex brightGrayColor justify-content-end performance-week-width">
                                        <FormattedMessage id="group.incorporations.descendant.week.4" defaultMessage="SEMANA 4" />
                                    </div>
                                :
                                    <div  className="d-flex justify-content-end performance-week-width">
                                        <FormattedMessage id="group.incorporations.descendant.week.4" defaultMessage="SEMANA 4" />
                                    </div>
                                }
                                <div className="d-flex justify-content-end performance-billed-width">
                                    <FormattedMessage id="personal.sales.header.row.total.new" defaultMessage="TOTAL" /> 
                                </div>
                                <div className="d-flex justify-content-end performance-balance-width">
                                    <FormattedMessage id="group.incorporations.descendant.balance" defaultMessage="SALDO" />
                                </div>
                            </div>
                        :
                            <div className=" w-100 d-flex s12 museo-sans-500 grayColor align-items-center justify-content-between row-height mb-2">
                                <div className='d-flex performance-name-request-status-width' >
                                    <FormattedMessage id="group.incorporations.descendant.name" defaultMessage="NOMBRE" />
                                </div>
                                <div className="d-flex justify-content-center performance-not-sent-width">
                                    <FormattedMessage id="group.incorporations.descendant.not.sent" defaultMessage="SIN ENVIAR" /> 
                                </div>
                                <div className="d-flex justify-content-center performance-to-invoice-width">
                                    <FormattedMessage id="group.incorporations.descendant.to.invoice" defaultMessage="A FACTURAR" /> 
                                </div>
                                <div className="d-flex justify-content-center performance-billed-width">
                                    <FormattedMessage id="personal.sales.header.row.total.new" defaultMessage="TOTAL" /> 
                                </div>
                                <div className="d-flex justify-content-center performance-balance-width">
                                    <FormattedMessage id="group.incorporations.descendant.balance" defaultMessage="SALDO" />
                                </div>
                            </div> 
                    }
                <div className=' w-100' >
                    {
                        incorporationsSalesLoader ?
                        <PerformanceLoadingMoreRequests type={"inc"} text={<FormattedMessage id="group.incorporations.descendant.loading" defaultMessage="Cargando consultoras"/>} small={false}/>
                            :
                            incorporationsSalesInfo?.length < 1 ?
                                <div className='d-flex justify-content-center'>
                                    <NoRegistersMessage icon={<RedWarningIcon/>} title={<FormattedMessage id="performance.no.results.title" defaultMessage="Sin resultados" />} description={<FormattedMessage id="performance.no.results.description" defaultMessage="No se encontraron resultados" />}/>
                                </div>
                            :
                                switchButtons === true ?
                                incorporationsSalesInfo?.map((element) => {
                                    return <GroupSalesWeekRow key={ShareKeyGenerator()} data={element}/>
                                })
                                :
                                incorporationsSalesInfo?.map((element) => {
                                    return <GroupSalesRequestRow key={ShareKeyGenerator()} data={element}/>
                                })
                    }

                </div>
                    {!incorporationsSalesLoader && incorporationsSalesInfo?.length >= 10 ? (
                            <div
                            ref={extraGroupLoaderRef}
                            className="mt-4"
                            style={{ height: "30px" }}
                            >
                            {isVisible ? (
                                extraIncorporationsSalesLoader ? (
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <div className="spinner-border blueColor" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p className="museo-sans-500 s14 mt-2">
                                    <FormattedMessage id="group.incorporations.descendant.loading.more" defaultMessage="Cargando más consultoras"/>
                                    </p>
                                    
                                </div>
                                ) : extraIncorporationsSales.length < 1 ? (
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <p className="museo-sans-500 s14">
                                    <FormattedMessage id="group.incorporations.descendant.no.more.results" defaultMessage="No hay mas consultoras"/>
                                    </p>
                                </div>
                                ) : (
                                ""
                                )
                            ) : (
                                ""
                            )}
                            </div>
                        ) : null}

            </div>
        </div>
        <GroupSalesFilter
            groupSalesInfo={incorporationsSalesInfo}
            order={order}
            setOrder={setOrder}
            toggleOrganizer={toggleOrganizer}
            setTogglerOrganizer={setTogglerOrganizer}
            yearPeriod={headerData.period?.currentYearPeriod}
            semanaActual={currentWeekForModal}
            dataVentasSemanales={weekSalesInfo}
            filtrosAplicados={filtersApplied}
        />
    </div>
  )
}