import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import LoginBackImage from "../../Login/Presentation/components/LoginBackImage/LoginbackImage";

import { reSetMultiLanguage } from "../redux/commonActions";

import "./layout.css";

export default function PublicLayout() {

  const dispatch = useDispatch();
  const country = localStorage.getItem("country");

  const onPublicStart = async () => {
    country ?  await dispatch(reSetMultiLanguage(country)) : await dispatch(reSetMultiLanguage("mx"))
  };

  useEffect(() => {
      onPublicStart();
  }, [dispatch]);
  
  return ( 
    <main id="container--public-layout"> 
       <LoginBackImage/>
        <Outlet/> 
    </main>
  )
}