import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
  isValidNumber,
} from "libphonenumber-js";
import
{ PhoneNumberUtil }
from
"google-libphonenumber";

export default function SharePhoneLibrary(phone) {

  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52",
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const callbackLibrary = async () => {
    let country = localStorage.getItem("country");
    if (phone.length == 0) {
      return true;
    }
    if (phone.length < 2) {
      return true;
    }
    if (!onlyNumbers(numbers[country]) || !onlyNumbers(phone)) {
      return true;
    }
    let associate = numbers[country].concat(phone);
    localStorage.setItem("tel", associate);
    if (associate.length < 4) {
      console.log('Phone validation error type: associate length');
      return true;
    }
 
    const phoneNumber = parsePhoneNumber(associate);
    
    if (validatePhoneNumberLength(phoneNumber.number, phoneNumber.country) === "TOO_SHORT") {
      console.log('Phone validation error type: phone too short');
      return true;
    }
    
    if (validatePhoneNumberLength(phoneNumber.number, phoneNumber.country) === "TOO_LONG") {
      console.log('Phone validation error type: phone too long');
      return true;
    }
 
    if (!isPossiblePhoneNumber(phoneNumber.number, phoneNumber.country)) {
      console.log('Phone validation error type: not possible phone number');
      return true;
    }
 
    if (!isValidPhoneNumber(phoneNumber.number, phoneNumber.country)) {
      console.log('Phone validation error type: invalid phone number');
      return true;
    }
 
    if (!isValidNumber(phoneNumber.number)) {
      console.log('Phone validation error type: invalid number');
      return true;
    }
 

    const phoneUtil = PhoneNumberUtil.getInstance();
    let number = phoneUtil.parseAndKeepRawInput(phoneNumber.number, country);
    if (
      phoneUtil.getNumberType(number) !== 1 &&
      phoneUtil.getNumberType(number) !== 2
    ) {
      console.log('Phone validation error type: invalid number type');
      return true;
    }

    return false;
  };

  const onlyNumbers = (string) => {
    return /^[0-9+]+$/.test(string);
  };

  return callbackLibrary();
}
