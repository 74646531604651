import React from "react";

import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

import { ReactComponent as SVGTriangle } from "../../../../common/svgs/ic-triangle-warning-md.svg";


import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ProgressBarParams from "../../../../common/Share/ProgressBarParams";

export default function DashboardEarningGapModal() {
    
  return (
    <div
      className="modal fade"
      id="modalEarningGap"
      aria-hidden="true"
      aria-labelledby="modalEarningGap"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "610px" }}
      >
        <div className="modal-content modal-radius">
          <div className=" modal-body p-0">
            <div>
              <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
                <div className="d-flex">
                  <SVGTriangle />
                  <h5 className="museo-sans-700 px-2 s20 m-0">
                    <FormattedMessage id="dashboard.modal.earning.gap.title" />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div className=" d-flex flex-column align-items-center">
                <div className="mt-4 px-5">
                  <p className=" pb-3 mb-3 s16 py-2 museo-sans-500">
                    <FormattedMessage id="dashboard.modal.earning.gap.p" />
                  </p>
                  <div className="gap-2 d-flex flex-column" style={{marginBottom: "33px"}}   >
                    <div className=" gap-2 d-flex justify-content-between" >
                        <p className=" s14 museo-sans-500" >
                            <FormattedMessage id="dashboard.qualification.ul.personal.sale" />
                        </p>
                        <div className=" d-flex gap-1 dm-sans-medium s14 " >
                            <p>
                                $ XX.XXX
                            </p>
                            <p className=" grayColor " >
                                / $ XX.XXX
                            </p>
                        </div>
                    </div>
                    <div>
                        <ProgressBarParams height={12} gap={"gap"} status={"gap"} completed={35} />
                    </div>
                  </div>
                  <div className="gap-2 d-flex flex-column" style={{marginBottom: "33px"}} >
                    <div className=" gap-2 d-flex justify-content-between" >
                        <p className=" s14 museo-sans-500" >
                            <FormattedMessage id="dashboard.qualification.ul.incorporated" />
                        </p>
                        <div className=" d-flex gap-1 dm-sans-medium s14 " >
                            <p>
                                X
                            </p>
                            <p className=" grayColor " >
                                / X
                            </p>
                        </div>
                    </div>
                    <div className=" d-flex gap-2" >
                        <ProgressBarParams height={12} gap={"gap"} status={"gap"} completed={100} />
                        <ProgressBarParams height={12} gap={"gap"} status={"gap"} completed={100} />
                        <ProgressBarParams height={12} gap={"gap"} status={"gap"} completed={100} />
                        <ProgressBarParams height={12} gap={"gap"} status={"gap"} completed={100} />
                    </div>
                  </div>
                  <div className="gap-2 d-flex flex-column" style={{marginBottom: "33px"}} >
                    <div className=" gap-2 d-flex justify-content-between" >
                        <p className=" s14 museo-sans-500" >
                            <FormattedMessage id="dashboard.qualification.ul.group.sale" />
                        </p>
                        <div className=" d-flex gap-1 dm-sans-medium s14 " >
                            <p>
                                $ XX.XXX
                            </p>
                            <p className=" grayColor " >
                                / $ XX.XXX
                            </p>
                        </div>
                    </div>
                    <div>
                        <ProgressBarParams height={12} gap={"gap"}  status={"gap"} completed={30} />
                    </div>
                  </div>
                  <div className="gap-2 d-flex flex-column" style={{marginBottom: "33px"}} >
                    <div className=" gap-2 d-flex justify-content-between" >
                        <p className=" s14 museo-sans-500" >
                            <FormattedMessage id="dashboard.qualification.ul.sale.desc" />
                        </p>
                        <div className=" d-flex gap-1 dm-sans-medium s14 " >
                            <p>
                                $ XX.XXX
                            </p>
                            <p className=" grayColor " >
                                / $ XX.XXX
                            </p>
                        </div>
                    </div>
                    <div>
                        <ProgressBarParams height={12} gap={"gap"}  status={"gap"} completed={100} />
                    </div>
                  </div>          
                </div>
              </div>
              <div className=" w-100 d-flex justify-content-center">
                <button
                  type="button"
                  className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
