import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShareCenterControlPage from "../../../common/Share/ShareCenterControlPage";

import emailPng24 from "../img/emailPng24.png";
import { ReactComponent as WhatSapp } from "../img/whatsapp24.svg";

export default function EDBDetailsAddInvitation() {

  const history = useNavigate();
  const data = useSelector((state) => state.edbDetailsData.data);

  const onStartUp = () => {

    if (!data?.shareText || !data?.invitationLink) {
      history(-1);
    }
  };

  useEffect(() => {
    onStartUp()
  }, [])

  return (
    <ShareCenterControlPage>
      <div className=" d-flex flex-column align-items-center mt-5 pt-5 gap-3 ">
        <div
          style={{ width: "735px" }}
          className="d-flex gap-4 justify-content-center "
        >
          <div className="d-flex">
            <QRCodeSVG
              value={data?.invitationLink}
              size={198}
              fgColor="#000000"
              bgColor="#ffffff"
              level="H"
            />
          </div>
          <div className="d-flex museo-sans-500 flex-column justify-content-between">
            <div className="d-flex flex-column gap-2">
              <p className=" blueColor s26 museo-sans-700">
                {" "}
                <FormattedMessage id="edb.details.invitation.title" />{" "}
              </p>
              <p className=" grayColor s14 ">
                {" "}
                <FormattedMessage id="edb.details.invitation.subtitle" />
              </p>
            </div>
            <div className="d-flex gap-5 s16 ">
              <ShareBallInfo
                icon={WhatSapp}
                iconType={"svg"}
                messageId={"social.sharedata.link.wap"}
              />
              <ShareBallInfo
                icon={emailPng24}
                iconType={"img"}
                messageId={"social.sharedata.link.mail"}
              />
            </div>
          </div>
        </div>
        <ShareLinkCopyButton
          link={data?.invitationLink}
        />
        <a
          id="mail-touch"
          href={`mailto:?&subject=?&body=${encodeURIComponent(data?.shareText)}`}
          target="_blank"
          className="ms-2 invisible bronceStroke grayColor cancelBootstrap"
        >
          <p>Invisible!</p>
        </a>
        <a
          id="what-touch"
          href={`https://wa.me/?text=${encodeURIComponent(data?.shareText)}`}
          target="_blank"
          className="ms-2 invisible bronceStroke grayColor cancelBootstrap"
        >
          <p>Invisible!</p>
        </a>
      </div>
    </ShareCenterControlPage>
  );
}

export function ShareBallInfo(params) {

  const onClickBall = (type) => {
    const pointerToClick = document.getElementById(
      type == "svg" ? "what-touch" : "mail-touch"
    );
    pointerToClick.click();
  };

  return (
    <div
      onClick={() => onClickBall(params.iconType)}
      className={`${params.isColumn ? "flex-column gap-2 pb-1" : "gap-3"} pointer d-flex align-items-center justify-content-between `}
    >
      <div
        className=" d-flex justify-content-center align-items-center "
        style={{
          width: params.width || "60px",
          height: params.height || "60px",
          background: "#5AAFF11A",
          borderRadius: "100px",
          padding: "10px",
        }}
      >
        {params.iconType === "svg" ? (
          <params.icon />
        ) : (
          <img src={params.icon} alt="icon" />
        )}
      </div>
      <p className=" grayColor museo-sans-500">
        <FormattedMessage id={params.messageId} />
      </p>
    </div>
  );
}

export function ShareLinkCopyButton({ link, width }) {

  const [copyValue, setCopyValue] = useState(false);

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setCopyValue(true);
    } catch (error) {
      //unsecuredCopyToClipboard(link);
    }
  };

  const splitIfTooLong = () => {
    if (link?.length > 70) {
      return `${link?.slice(0, 70)}...`;
    }
    return link;
  }

  useEffect(() => {
    setTimeout(() => {
      copyValue && setCopyValue(false);
    }, 1500);
  }, [copyValue]);

  return (
    <div
      style={{ width: width || "735px" }}
      className="d-flex flex-column align-items-center gap-2 "
    >
      <div className="w-100">
        <p className=" museo-sans-700 s14">
          <FormattedMessage id="invitation.add.modal.link.text" />
        </p>
      </div>
      <div className=" position-relative d-flex justify-content-between w-100 input-border rounded-4 px-2 py-3 bg-white ">
        <p className=" museo-sans-500 s14 px-2  ">{splitIfTooLong(link)}</p>
        <p
          onClick={doThis}
          className=" s16 pointer pe-2 museo-sans-700 skyColor"
        >
          <FormattedMessage id="copy" />
        </p>
        {copyValue && (
          <div
            style={{
              height: "38px",
              right: 6,
              bottom: 6,
              width: "120px",
            }}
            className=" justify-content-center greenModalBg s16 d-flex align-items-center gap-2 position-absolute"
          >
            <i
              style={{ justifySelf: "center" }}
              className=" greenColor bi bi-check-circle"
            ></i>
            <p className=" museo-sans-700 greenColor">
              <FormattedMessage id="buttons.copied" />
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
