import React from 'react'

export default function EthicsCodeCollapseContainer({collapseId, children}) {
  return (
    <div className="collapse" id={collapseId}>
        <div className='pb-3 p-tag-margin-bottom p-tag-gray-text-color' >
            {children}
        </div>
    </div>
  )
}
