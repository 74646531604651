export default function BusinessPlanCardEarningPerLevelDescription({elm , returnNewJSX, formatAmount, cultureInfo}) {
  
    const greenNewColor = { color: "#16BE7D" };

  return (
    <>
      {elm?.details?.map((subElm) => (
        <div
          key={subElm.id}
          className="d-flex flex-column gap-1 grayColor s14 museo-sans-500"
          style={{
            color: subElm.comparisonPreviousLevel === "new" ? "#16BE7D" : "",
          }}
        >
          <p className="d-flex">
            <span
              style={{ width: "60px", textAlign: "right" }}
              className="dm-sans-bold"
            >
              {subElm?.valueDescription}
            </span>
            <span className="invisible px-2 mx-1"></span>
            <span className="bp-earning--p-width">
              {subElm?.itemDescription}
              {subElm?.comparisonPreviousLevel === "new" && returnNewJSX()}
            </span>
          </p>
        </div>
      ))}

      {elm.itemDescription.indexOf("bono") !== -1 && (
        <div className=" d-flex flex-column gap-1 grayColor s14 museo-sans-500 ">
          <p
            style={elm.comparisonPreviousLevel == "new" ? greenNewColor : null}
          >
            <>
              <spam className="dm-sans-bold">
                {formatAmount(
                  elm?.value,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </spam>
              &nbsp;
              {elm?.itemDescription}
            </>
          </p>
        </div>
      )}
    </>
  );
}
