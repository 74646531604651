import { useEffect, useRef } from "react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDescFirstGenExtras,
  fetchDescIncorpExtra,
} from "../../Framework/redux/DescendantsSideEffect";
import DescendantsListRow from "./DescendantsListRow";

export default function DescendantsList({
  groups,
  gen,
  isVisible,
  setIsVisible,
  setOffSet,
  offSet,
  profileData,
}) {
  const [removeSpaceOnToggle, setRemoveSpaceOnToggle] = useState(true);
  const dispatch = useDispatch();
  const extraRef = useRef(null);

  const [returnedEmpty, setReturnEmpty] = useState(false);
  const [loader, setLoader] = useState(false);

  const extraGroups = useSelector(
    (state) => state.descendantData.dataFirstGenExtra
  );

  const extraGroupsIncorp = useSelector(
    (state) => state.descendantData.dataIncorpExtra
  );

  const changeStatus = () => {
    setRemoveSpaceOnToggle(!removeSpaceOnToggle);
  };

  useEffect(() => {
    if (extraRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      });
      observer.observe(extraRef.current);
    }

    if (isVisible) {
      let result = callbackExtras();
    }
  }, [isVisible, extraRef.current]);

  const callbackExtras = async () => {
    setLoader(true);
    let root = localStorage.getItem("clientId");
    let result = null;
    if (profileData.level < 3) {
      result = await dispatch(
        fetchDescIncorpExtra({ root: root, offSet: 10 + offSet })
      );
      if (result.payload.incorporated.length == 0) {
        setReturnEmpty(true);
      }
    } else {
      result = await dispatch(
        fetchDescFirstGenExtras({ root: root, offSet: 10 + offSet })
      );
      if (result.payload.firstGenerationGroup.length == 0) {
        setReturnEmpty(true);
      }
    }
    setOffSet(offSet + 10);
    setLoader(false);
    return result;
  };

  return (
    <div
      style={{ overflowY: "scroll", maxHeight: "600px" }}
      className=" pe-3 hidden-y-scroll pt-3 mt-3 d-flex flex-column"
    >
      {" "}
      {groups &&
        groups.map((elm) => {
          return (
            <>
              {" "}
              <DescendantsListRow
                hrToggler={changeStatus}
                gen={gen}
                user={elm}
              />{" "}
            </>
          );
        })}
      {extraGroups &&
        profileData.level > 2 &&
        extraGroups.map((elm) => {
          return (
            <>
              {" "}
              <DescendantsListRow
                hrToggler={changeStatus}
                gen={gen}
                user={elm}
              />{" "}
            </>
          );
        })}
      {extraGroupsIncorp &&
        profileData.level < 3 &&
        extraGroupsIncorp.map((elm) => {
          return (
            <>
              {" "}
              <DescendantsListRow
                hrToggler={changeStatus}
                gen={gen}
                user={elm}
              />{" "}
            </>
          );
        })}
      {!returnedEmpty && (
          <div ref={extraRef} className="mt-4" style={{ height: "30px" }}>
            {isVisible ? (
              loader ? (
                <div style={{top: "-60px"}} className=" position-relative d-flex flex-column justify-content-center align-items-center">
                  <div className="spinner-border blueColor" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <p className="museo-sans-500 s14 mt-2">
                    <FormattedMessage
                      id="group.incorporations.descendant.loading.more"
                      defaultMessage="Cargando más consultoras"
                    />
                  </p>
                </div>
              ) : (
                <></>
              )
            ) : (
              ""
            )}
          </div>
      )}
    </div>
  );
}
