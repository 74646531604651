import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const postPersonalDataPassword = createAsyncThunk(
  "postPersonalDataPassword",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      let body = {
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
      };
      const result = await customNetServAxios("application/json;charset=UTF-8", null, [400, 401], true).post(`${location}/profile/${clientId}/personal-data/password`, body);
      return result;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const postPersonalDataValidationEmail = createAsyncThunk(
  "postPersonalDataValidationEmail",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      return await customNetServAxios("application/json;charset=UTF-8", null, [400, 401]).post(
        `${location}/profile/${clientId}/personal-data/validation/email`,
        data
      );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const postPersonalDataValidationEmailCode = createAsyncThunk(
  "postPersonalDataValidationEmailCode",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      let body = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        userOtpCode: data.userOtpCode,
        token: data.token,
      };
      return await customNetServAxios("application/json;charset=UTF-8", null, [400, 401], true).post(
        `${location}/profile/${clientId}/personal-data/validation/email/code`,
        body
      );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const postPersonalDataValidationPhoneNumber = createAsyncThunk(
  "postPersonalDataValidationPhoneNumber",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      let body = {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        recoveryType: "phone",
      };
      return await customNetServAxios("application/json;charset=UTF-8", null, [400, 401]).post(
        `${location}/profile/${clientId}/personal-data/validation/phone`,
        body
      );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const postPersonalDataValidationPhoneNumberCode = createAsyncThunk(
  "postPersonalDataValidationPhoneNumberCode",
  async (data, { rejectWithValue }) => {
    try {
      let location = localStorage.getItem("country");
      let clientId = localStorage.getItem("clientId");
      let body = {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        userOtpCode: data.userOtpCode,
        token: data.token,
      };
      return await customNetServAxios("application/json;charset=UTF-8", null, [400, 401], true).post(
        `${location}/profile/${clientId}/personal-data/validation/phone/code`,
        body
      );
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export {
  postPersonalDataPassword,
  postPersonalDataValidationEmail,
  postPersonalDataValidationEmailCode,
  postPersonalDataValidationPhoneNumber,
  postPersonalDataValidationPhoneNumberCode,
};
