import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { fetchEDBDetails } from "../../Framework/redux/EDBDetailsSideEffect.js";

import EDBDetailsCardGuest from "../components/EDBDetailsCardGuest";
import EDBDetailsCardParams from "../components/EDBDetailsCardParams";
import EDBDetailsCardPlan from "../components/EDBDetailsCardPlan";
import EDBDetailsTableList from "../components/EDBDetailsTableList";
import EDBDetailsModalPlan from "../components/EDBDetailsModalPlan";
import EDBModal from "../components/EDBModal";
import EDBMenu from "../components/EDBMenu";
import EDBModalDelete from "../components/modals/EDBModalDelete";
import ShareGetDate from "../../../common/Share/ShareGetDate";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import {
  clearArrayVimeo,
  openVimeo,
  setArrayVimeo,
} from "../../../common/redux/commonActions";
import { ReactComponent as SVGTriangle } from "../../../common/svgs/ic-triangle-warning-md.svg";

import { ModalClipboardContext } from "../../../common/routes/routes.js";
import {
  deleteEdb,
  deleteInvitee,
} from "../../Framework/redux/EDBFormSideEffect.js";
import { clearClientDetailsForm } from "../../Framework/redux/EDBsActions.js";

export default function EDBDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useNavigate();
  const directorId = localStorage.getItem("directorId");
  let { id } = useParams();
  const { setModalTexts } = useContext(ModalClipboardContext);
  const [type, setType] = useState(
    location.pathname.indexOf("/dated") !== -1
      ? "fechado"
      : location.pathname.indexOf("/open") !== -1
      ? "abierto"
      : "cerrado"
  );
  const [inviteeInfo, setInviteeInfo] = useState({ id: "", name: "" });
  const data = useSelector((state) => state.edbDetailsData.data);
  const loading = useSelector((state) => state.edbDetailsData.loader);
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const arrayVimeoTwo = [
    { id: "658693857", key: "c5b977e224" },
    { id: "660663242", key: "7bea6dd12e" },
  ];

  const addInvitation = () => {
    history("invitation");
  };

  const onStartUp = async () => {
    await dispatch(fetchEDBDetails(id));
    dispatch(clearClientDetailsForm())
  };

  const onChangeInvitee = (inviteeId) => {
    setInviteeInfo(inviteeId);
  };

  const onClearInvitee = () => {
    setInviteeInfo(null);
  };

  const onClick = (id, key, title) => {
    dispatch(openVimeo({ id: id, key: key, title: title }));
  };

  const onNewInvitee = () => {
    history(`client/list`);
  };

  const onDeleteEDB = async () => {
    const modalData = {
      title: <FormattedMessage id="edb.form.modal.pop.up.message.delete.edb.title" />,
      text: <FormattedMessage id="edb.form.modal.pop.up.message.delete.edb.p" />,
      status: "confirm",
      show: false,
      bottom: 0,
    };
    setModalTexts(modalData);
    await dispatch(deleteEdb(id));
    history(-1);
  };

  const onDeleteInvitee = async (inviteeId) => {
    const modalData = {
      title: <FormattedMessage id="edb.form.modal.pop.up.message.delete.assistant.title" />,
      text: <FormattedMessage id="edb.form.modal.pop.up.message.delete.assistant.p" />,
      status: "confirm",
      show: false,
      bottom: 0,
    };
    await dispatch(deleteInvitee({ edbId: id, inviteeId: inviteeId }));
    await dispatch(fetchEDBDetails(id));
    setModalTexts(modalData);
  };

  useEffect(() => {
    onStartUp();
    dispatch(clearArrayVimeo());
    dispatch(setArrayVimeo(arrayVimeoTwo));
    localStorage.removeItem("formBody");
  }, []);
  
  return (
    <div
      id="edb-details--page"
      style={{ borderRadius: "1rem" }}
      className="  py-4  bg-white d-flex flex-column "
    >
      <ShareModalStructure id={"modalInfoPlan"} maxWidth={"608px"}>
        <EDBDetailsModalPlan data={data} cultureInfo={cultureInfo} />
      </ShareModalStructure>
      <ShareModalStructure id={"modalInfo"} maxWidth={"600px"}>
        <EDBModal />
      </ShareModalStructure>
      <ShareModalStructure id={"modalDelete"} maxWidth={"496px"}>
        <EDBModalDelete data={data} onConfirm={onDeleteEDB} />
      </ShareModalStructure>
      <ShareModalStructure id={"modalDeleteInvitee"} maxWidth={"496px"}>
        <EDBModalDelete inviteeInfo={inviteeInfo} onConfirm={onDeleteInvitee} />
      </ShareModalStructure>
      <div className="container--padding--card-holder">
        <div>
          <div className=" w-100  pt-3 container--padding container--no-padding d-flex justify-content-between">
            <div>
              <p className="s12 museo-sans-500 text-uppercase grayColor">
                {data ? ShareGetDate(data.date) : "21 DE FEBRERO — 14:00HS"}
              </p>
              <div className=" museo-sans-700 s24 align-items-center d-flex">
                <h2 className=" m-0 s24 py-3">
                  {data?.title ?? "Introducción a una vida saludable"}
                </h2>
                <p className=" invisible">a</p>
                <span className="grayColor s24 py-3"> | ID {data?.idFechado} </span>
              </div>
            </div>
            {((type !== "cerrado" || !data?._metadata) && !directorId) && (
              <EDBMenu onNewInvitee={onNewInvitee} addInvitation={addInvitation} type={type} onClearInvitee={onClearInvitee} data={data} />
            )}
          </div>
          <div className=" w-100 edb-details--cards-gap container--padding container--no-padding flex-wrap py-3 d-flex">
            <EDBDetailsCardParams
              SVGTriangle={SVGTriangle}
              data={data}
              loading={loading}
              type={type}
              cultureInfo={cultureInfo}
            />
            {type !== "fechado" && (
              <EDBDetailsCardPlan
                onClick={onClick}
                loading={loading}
                data={data}
                cultureInfo={cultureInfo}
              />
            )}
            <EDBDetailsCardGuest
              onClick={onClick}
              addInvitation={addInvitation}
              loading={loading}
              type={type}
              data={data}
              cultureInfo={cultureInfo}
              directorId={directorId}
            />
          </div>
          <EDBDetailsTableList
            setInviteeInfo={onChangeInvitee}
            onDeleteInvitee={onDeleteInvitee}
            addInvitation={onNewInvitee}
            data={data}
            type={type}
            loading={loading}
            cultureInfo={cultureInfo}
            directorId={directorId}
          />
        </div>
      </div>
    </div>
  );
}
