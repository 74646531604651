import { ReactComponent as SVGFilledRightChev } from "../../../common/svgs/ic-slide-right.svg";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { useState } from "react";
import DashboardFirstStepsRow from "../../../Dashboard/Presentation/components/DashboardFirstStepsRow";
import DescendantLine from "../../../Descendants/Presentation/components/DescendantLine";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";

const FirstStepsFirstGenerationRow = (elm) => {
  const [toggler, setToggler] = useState(false);
  let auxiliarRest = 0;
  let auxiliarIncorporated = 0;

  const biggerThan1000 = useMediaPredicate("(min-width: 1025px)");

  for (
    let index = 0;
    index < elm?.elm?.personalGroup?.consultantsGroup?.length;
    index++
  ) {
    if (
      elm?.elm?.personalGroup?.consultantsGroup[index].isPersonalIncorporated ==
      true
    ) {
      auxiliarIncorporated = auxiliarIncorporated + 1;
    }
    if (
      elm?.elm?.personalGroup?.consultantsGroup[index].isPersonalIncorporated ==
      false
    ) {
      auxiliarRest = auxiliarRest + 1;
    }
  }
  const changeToggler = () => {
    setToggler(!toggler);
  };



  let elementosProcesados = 0;
  let valor = 0;
  return (
    <>
      <div
        className=" w-100 pointer"
        style={{ paddingBottom: "0px", paddingLeft: "0px" }}
      >
        <div
          className="d-flex flex-row align-items-center"
          style={{ gap: "4px" }}
          onClick={changeToggler}
        >
          {toggler ? (
            <div className="svg-force-size " style={{ width: "16px" }}>
              <SVGFilledRightChev />
            </div>
          ) : (
            <div
              className="svg-force-size"
              style={{
                width: "16px",
                paddingBottom: "5px",
                paddingLeft: "2px",
                transform: "rotate(90deg)",
              }}
            >
              <SVGFilledRightChev />
            </div>
          )}

          <div className="d-flex align-items-center" style={{ gap: "16px" }}>
            <ImageCircleHandler
              size={"14px"}
              width={"32px"}
              height={"32px"}
              url={elm?.elm?.profileResume?.image}
              firstName={elm?.elm?.profileResume?.firstName}
              lastName={elm?.elm?.profileResume?.lastName}
              name={elm?.elm?.profileResume?.name}
              top={2}
            />
            <p style={{ color: "#171A22" }} className="museo-sans-700 s14">
              {elm?.elm?.profileResume?.name}
            </p>
          </div>
        </div>
      </div>
      <div>
        {!toggler && (
          <>
            {auxiliarIncorporated > 0 && (
              <>
                <div
                  className=" pointer No--padding--table--header--1eraGenList px-0 align-items-center museo-sans-500 s14"
                  style={{
                    borderRadius: "16px",
                    marginLeft: "20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  <p
                    style={{
                      background: "#EFEFF8",
                      width: "184px",
                      color: "#908F9A",
                      borderRadius: "16px",
                    }}
                    className="d-flex justify-content-center"
                  >
                    <FormattedMessage id="graph.balance.inc" />
                  </p>
                </div>
                {elm?.elm?.personalGroup?.consultantsGroup?.map((element,index, array) => {
                  elementosProcesados++;
                  return (
                    <>
                      {element?.isPersonalIncorporated == true && (
                        <div
                          className="d-flex flex-row"
                        >
                          <div className=" desc--grid-collapse">
                            {valor == auxiliarIncorporated ? (
                              <>
                                <DescendantLine
                                  gen={1}
                                  index={auxiliarIncorporated}
                                  end={true}
                                  short={true}
                                />
                               
                              </>
                            ) : (
                              <>
                                <DescendantLine
                                  gen={1}
                                  index={auxiliarIncorporated}
                                  end={false}
                                  short={true}
                                />
                                {
                                  auxiliarRest <= 0 && elementosProcesados === array.length &&
                                  <div className="position-relative">
                                  <div
                                    style={{
                                      height: "20px",
                                      width: " 10px",
                                      background: "white",
                                      position: "absolute",
                                      top: "47px",
                                      right: "7px",
                                      color: "white",
                                    }}
                                  >
                                    .
                                  </div>
                                </div>
                                }
                              </>
                            )}
                          </div>
                          <DashboardFirstStepsRow elm={element} short={true} incorp={"false"}/>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            )}
            {auxiliarRest > 0 && (
              <>
                <div
                  className=" pointer No--padding--table--header--1eraGenList px-0 align-items-center museo-sans-500 s14"
                  style={{
                    borderRadius: "16px",
                    marginLeft: "20px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  <p
                    style={{
                      background: "#EFEFF8",
                      width: "184px",
                      color: "#908F9A",
                      borderRadius: "16px",
                    }}
                    className="d-flex justify-content-center"
                  >
                    <FormattedMessage id="dashboard.graph.dropmenu.sublevel.five" />
                  </p>
                </div>
                {elm?.elm?.personalGroup?.consultantsGroup?.map((element) => {
                  valor = valor + 1;
                  return (
                    <>
                      {element?.isPersonalIncorporated == false && (
                        <div
                          className="d-flex flex-row"
                        >
                          <div className=" desc--grid-collapse">
                            {valor == auxiliarRest ? (
                              <>
                                <DescendantLine
                                  gen={1}
                                  index={auxiliarIncorporated}
                                  end={true}
                                  short={true}
                                />
                                <div className="position-relative">
                                  <div
                                    style={{
                                      height: "10px",
                                      width: " 10px",
                                      background: "white",
                                      position: "absolute",
                                      top: "47px",
                                      right: "6px",
                                      color: "white",
                                    }}
                                  ></div>
                                </div>
                              </>
                            ) : (
                              <>
                                <DescendantLine
                                  gen={1}
                                  index={auxiliarIncorporated}
                                  end={false}
                                  short={true}
                                />
                              </>
                            )}
                          </div>
                          <DashboardFirstStepsRow biggerThan1000={biggerThan1000} elm={element} short={true} incorp={"false"}/>
                        </div>
                      )}
                    </>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default FirstStepsFirstGenerationRow;
