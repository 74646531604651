import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import ImageCircleHandler from "../../../../common/Share/ImageCircleHandler.jsx";

import { ReactComponent as SVGMail } from "../../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGInstagram } from "../../../../common/svgs/ic-instagram-md.svg";
import { ReactComponent as SVGFacebook } from "../../../../common/svgs/ic-facebook-md.svg";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

export default function CalendarModalBirthday() {
  const data = useSelector((state) => state.calendarData.detailsModal);

  const configExist = (auxId, array) => {
    let resolve = -1;
    if (array) {
      resolve = array.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  return (
    <>
      {data && (
        <div className=" position-relative" >
        <>
          <div className=" position-absolute w-100 d-flex align-items-center justify-content-between p-3 ">
            <div className="d-flex">
              <h5 className=" invisible museo-sans-500 s20 m-0">
                <FormattedMessage id="social.sharedata.title" />
              </h5>
            </div>
            <div
              className=" pointer "
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <SVGClose />
            </div>
          </div>
          <div style={{paddingTop: "32px"}} className=" d-flex  align-items-center flex-column">
            
              <ImageCircleHandler
                url={data.image}
                firstName={data.firstName}
                lastName={data.lastName}
                name={data?.name}
                width={"92px"}
                size={30}
                styles={{background: "#CED2FF", color: "#456ECE"}}
              />
            <div className=" s24 pt-3  museo-sans-700 d-flex gap-1">
              <p>{data.firstName}</p>
              <p>{data.lastName}</p>
            </div>
            <div className=" pt-2 s16 grayColor museo-sans-500 ">
              <p>
                <FormattedMessage id="client" />
              </p>
            </div>
          </div>
          <div className=" px-4 pt-3 d-flex justify-content-center  mt-0 gap-3">
            {configExist("email", data.socialNetwork) !== -1 && (
              <a
                href={`mailto:${
                  data.socialNetwork[
                    configExist("email", data.socialNetwork)
                  ]?.value
                }?&body=`}
                
                target="_blank"
              >
                <SVGMail />
              </a>
            )}
            {configExist("whatsapp", data.socialNetwork) !== -1 && (
              <a
                href={`https://wa.me/${
                  data.socialNetwork[
                    configExist("whatsapp", data.socialNetwork)
                  ].value
                }?text=`}
                target="_blank"
              >
                <SVGWhatsap />
              </a>
            )}
            {configExist("messenger", data.socialNetwork) !== -1 && (
              <a
                href={`https://m.me/${
                  data.socialNetwork[
                    configExist("messenger", data.socialNetwork)
                  ].value
                }?text=`}
                target="_blank"
              >
                <SVGMessenger />
              </a>
            )}
            {configExist("facebook", data.socialNetwork) !== -1 && (
              <a
                href={`${
                  data.socialNetwork[
                    configExist("facebook", data.socialNetwork)
                  ].value
                }`}
                target="_blank"
              >
                <SVGFacebook />
              </a>
            )}
            {configExist("instagram", data.socialNetwork) !== -1 && (
              <a
                href={`https://www.instagram.com/${
                  data.socialNetwork[
                    configExist("instagram", data.socialNetwork)
                  ].value
                }`}
                target="_blank"
              >
                <SVGInstagram />
              </a>
            )}
          </div>
          <div className=" d-flex justify-content-center py-3 mt-3 my-4">
            <button
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                width: "288px",
                height: "40px",
                borderRadius: "8px",
              }}
              className=" skyBg whiteColor  museo-sans-700 s14 border-0  "
            >
              Entendido
            </button>
          </div>
        </>
      </div>
      )}
    </>
  );
}
