import ShareModalShareOffertsBody from "../../../common/Share/ShareModalShareOffertsBody";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";

export default function DashboardOfferShareNoticeConsultantModal({
  socialArray,
  user,
  open,
  auxElement
}) {

  return (
    <>
      {(socialArray && auxElement) && (
        
        <ShareModalStructure maxWidth={"556px"} id="dashboardOfferShareNoticeClientModal" >
          <ShareModalShareOffertsBody auxElement={auxElement} open={open} socialArray={socialArray} title={"share.message.noti.consult"} />
        </ShareModalStructure>
        
      )}
    </>
  );
}
