import StyledMultiLang from "../../../common/Share/StyledMultiLang";

export default function DashboardTitle({profileData, profileLoader, biggerThan1025 }) {
    
    return(
        <>
            {biggerThan1025 && (
                <>
                  {profileLoader ? (
                      <div
                        style={{ width: "fit" }}
                        className=" brightGrayBg rounded-3 h-100  "
                      >
                        <h2 className=" invisible d-flex m-0 museo-sans-700 s24">
                          <StyledMultiLang
                            loading={profileLoader}
                            id="dashboard.main.title.part.one"
                            def={"¡Hola consultora"}
                          />{" "}
                          {profileData.userInfo.firstName}!
                          <span className=" museo-sans-500 px-1 grayColor ">
                            {profileData.userInfo.gender == "M" ? (
                                <StyledMultiLang
                                  loading={profileLoader}
                                  id="dashboard.main.title.part.two.m"
                                  def={"Bienvenida a tu Oficina Virtual."}
                                />
                            ) : (
                                <StyledMultiLang
                                  loading={profileLoader}
                                  id="dashboard.main.title.part.two"
                                  def={"Bienvenida a tu Oficina Virtual."}
                                />
                            )}
                          </span>
                        </h2>
                      </div>
                  ) : (
                      <h2 className=" d-flex m-0 museo-sans-700 s24">
                        <StyledMultiLang
                          loading={profileLoader}
                          id="dashboard.main.title.part.one"
                          def={"¡Hola consultora"}
                        />{" "}
                        {profileData.userInfo.firstName}!
                        <p className=" museo-sans-500 px-1 grayColor ">
                          {profileData.userInfo.gender == "M" ? (
                            
                              <StyledMultiLang
                                loading={profileLoader}
                                id="dashboard.main.title.part.two.m"
                                def={"Bienvenida a tu Oficina Virtual."}
                              />
                          ) : (
                            
                              <StyledMultiLang
                                loading={profileLoader}
                                id="dashboard.main.title.part.two"
                                def={"Bienvenida a tu Oficina Virtual."}
                              />
                          )}
                        </p>
                      </h2>
                  )}
                </>
              )}
              {!biggerThan1025 && (
                <>
                  {profileLoader ? (
                      <div className=" brightGrayBg rounded-3 d-flex flex-column">
                        <h2 className=" invisible d-flex m-0 museo-sans-700 s24">
                          <StyledMultiLang
                            loading={profileLoader}
                            id="dashboard.main.title.part.one"
                            def={"¡Hola consultora"}
                          />{" "}
                          {profileData.userInfo.firstName}!
                        </h2>
                        <p className=" invisible s20 museo-sans-500 px-1 grayColor ">
                          <StyledMultiLang
                            loading={profileLoader}
                            id="dashboard.main.title.part.two"
                            def={"Bienvenida a tu Oficina Virtual."}
                          />
                        </p>
                      </div>
                  ) : (
                      <div className=" d-flex flex-column">
                        <h2 className=" d-flex m-0 museo-sans-700 s24">
                          <StyledMultiLang
                            loading={profileLoader}
                            id="dashboard.main.title.part.one"
                            def={"¡Hola consultora"}
                          />{" "}
                          {profileData.userInfo.firstName}!
                        </h2>
                        <p className=" s20 museo-sans-500 px-1 grayColor ">
                          <StyledMultiLang
                            loading={profileLoader}
                            id="dashboard.main.title.part.two"
                            def={"Bienvenida a tu Oficina Virtual."}
                          />
                        </p>
                      </div>
                  )}
                </>
              )}
        </>
    )
}