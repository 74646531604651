import ShareCheckEnvKey from "../../../common/Share/ShareCheckEnvKey";
import DashboardOffertsNews from "./DashboardOffertsNews";

export default function DashboardOffersContainer({changeModalConsult,changeModalClient, noticeLider, togglerLider, noticeConsultant, noticeClient, offerData, setOffersInfoModal, shareNotice, onOpenCopyModal, controllerModalOfertsToTop }) {
    
    return(
        <>
            {
              ShareCheckEnvKey("CMS") && (
                <div className="d-flex w-100 gap-3 " >
                  <DashboardOffertsNews
                    togglerConsult={changeModalConsult}
                    togglerClient={changeModalClient}
                    noticeLider={noticeLider}
                    togglerLider={togglerLider}
                    noticeConsultant={noticeConsultant}
                    noticeClient={noticeClient}
                    offerData={offerData}
                    setOffersInfoModal={setOffersInfoModal}
                    setShareNoticeInfo={shareNotice}
                    onCopyOpen={onOpenCopyModal}
                    controllerModalOfertsToTop={controllerModalOfertsToTop}
                  />
                  <div className="w-100" >

                  </div>
                  <div className=" w-100" >

                  </div>
                </div>
              )
            }
        </>
    )
}