import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGClose } from "../../../common/svgs/ic-x-lg.svg";

import switchImage from '../../../common/img/performanceImages/tooltip-toggle.png'
import tableImage from '../../../common/img/performanceImages/tooltip-tabla.png'
import HelpIconNoTarget from '../../../common/Share/HelpIconNoTarget';

import ModalWhiteShadow from '../../../common/Share/ModalWhiteShadow';
import { useRef } from 'react';

export default function PerformanceGroupIncorpDescendantModal({titulo, primerParrafo}) {

  const scrollbarRef = useRef(null); 

  return (
    <div
      className="modal fade"
      id="performanceHelpModal"
      aria-hidden="true"
      aria-labelledby="modalInfo"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered help-modal-content"
      >
        <div style={{width: "auto"}} className="modal-content modal-radius">
          <div className="modal-body p-0 help-modal-content">
            <div className='h-100'>

              <div className="d-flex justify-content-between align-items-center ps-5 pe-5 help-modal-header-footer">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0 blackColor">
                    {titulo}
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>

              <hr className="m-0"></hr>

              <div ref={scrollbarRef} className="d-flex flex-column align-items-center remove-scrollbar help-modal-info-container">
                <div className="mt-4 px-5">
                  <p className="museo-sans-500 s16 py-2 blackColor">
                    {primerParrafo}
                  </p>
                  <p className="museo-sans-500 s16 py-2 blackColor">
                    <FormattedMessage
                      id="group.incorporations.descendant.help.modal.second.paragraph"
                      defaultMessage="edbs"
                    />
                  </p>

                    <img src={switchImage} alt="Imagen del selector" className='mt-2 mb-3'/>

                  <p className="museo-sans-500 s16 py-2 blackColor">
                    <FormattedMessage
                      id="group.incorporations.descendant.help.modal.third.paragraph"
                      defaultMessage="edbs"
                    />
                  </p>

                  <img src={tableImage} alt="Imagen de tooltip" className='mt-2 mb-2 w-100'/>
                </div>
                <ModalWhiteShadow bottom={"75px"} scrollbarRef={scrollbarRef} />
                
              </div>
              <div className='d-flex justify-content-center align-items-center help-modal-header-footer'>
                    <button
                        type="button"
                        className=" btnLoginWidth my-5 museo-sans-700 s14 skyBg whiteColor button-border-none button-hover-light button-bootstrap-radius button-padding help-modal-ok-button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                    <FormattedMessage
                        id="buttons.understood"
                        defaultMessage="Entendido"
                    />
                    </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
