import { FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useMediaPredicate } from "react-media-hook";

import { defineStepNumber } from "../../../../Framework/redux/dashboardAction";

import { ReactComponent as CloseIcon } from "../../../../../common/svgs/ic-x-sm.svg";
import { ReactComponent as ArrowRight } from "../../../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as SVGChevron } from "../../../../../common/svgs/c-chevron-right-sm.svg";
import ProgressBarParams from "../../../../../common/Share/ProgressBarParams";

export default function ExtraStepConsult({ removeBlur }) {
  const dispatch = useDispatch();
  const biggerThan1920 = useMediaPredicate("(min-width: 1800px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const scoreData = useSelector((state) => state.scoreData);

  const onCancelBlur = () => {
    removeBlur();
    localStorage.removeItem("firstLogin");
    dispatch(defineStepNumber(20));
  };

  const onCancelOnlyBlur = () => {
    removeBlur();
    dispatch(defineStepNumber(20));
  };

  const getStyles = (left, topPotential, topNonPotential) => ({
    left: `${left}px`,
    top: scoreData?.consultantStatus?.potentialLeader
      ? `${topPotential}px`
      : `${topNonPotential}px`,
    width: "355px",
    height: "205px",
  });

  const getModalStyles = () => {
    if (biggerThan1920) {
      return s3Styles;
    } else if (biggerThan1280) {
      return s2Styles;
    } else {
      return s1Styles;
    }
  };

  const s3Styles = getStyles(1140, 400, 490);
  const s2Styles = getStyles(700, 400, 140);
  const s1Styles = getStyles(565, 400, 180);

  return (
    <div
      className="modal fade"
      id="extraStepModal"
      data-bs-backdrop="static"
      aria-hidden="true"
      aria-labelledby="extraStepModal"
      tabIndex="-1"
    >
      <Doppelganger />
      <div className="modal-dialog m-0 position-fixed" style={getModalStyles()}>
        <div
          className="modal-content h-100"
          style={{ padding: "25px", borderRadius: "15px" }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="museo-sans-500 s12 grayColor">
                {profileData.level < 2 ? "PASO 3 DE 4" : "PASO 4 DE 5"}
              </p>
              <div
                className=" pointer "
                data-bs-dismiss="modal"
                onClick={() => onCancelOnlyBlur()}
                aria-label="Close"
              >
                <CloseIcon />
              </div>
            </div>
            <p className="museo-sans-700 s20 blackColor">
              <FormattedMessage id="on.boarding.third.title.one" />
            </p>
            <div className="mt-2" style={{ lineHeight: "18px" }}>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ width: "272px" }}
              >
                <FormattedMessage id="on.boarding.extra.p.one" />
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-auto">
            <a
              onClick={() => onCancelBlur()}
              data-bs-dismiss="modal"
              aria-label="Close"
              role="button"
              className="museo-sans-700 s14 skyColor button-hover-light-text"
            >
              <FormattedMessage id="buttons.not.show.ever" />
            </a>

            <div
              className="d-flex hover-pointer align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
              data-bs-target="#fifthStepModal"
              data-bs-toggle="modal"
            >
              <p className=" text-capitalize museo-sans-700 s14 skyColor button-hover-light-text">
                <FormattedMessage id="buttons.follow" />
              </p>
              <ArrowRight />
            </div>
          </div>
        </div>
        <div style={{ left: "-27px" }} className=" modal-arrow "></div>
      </div>
    </div>
  );
}

export function Doppelganger(params) {
  const biggerThan1920 = useMediaPredicate("(min-width: 1799px)");
  const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");

  const scoreData = useSelector((state) => state.scoreData);
  const profileData = useSelector((state) => state.userInfo);

  const s3Styles = scoreData?.consultantStatus?.potentialLeader
    ? { top: "420px", left: "25%", width: "550px", height: "60px" }
    : { top: "510px", left: "25%", width: "550px", height: "60px" };

  const s2Styles = scoreData?.consultantStatus?.potentialLeader
    ? {
        top: "425px",
        left: "100px",
        width: "480px",
        height: "60px",
      }
    : {
        top: "150px",
        left: "100px",
        width: "480px",
        height: "60px",
      };
  const s1Styles = scoreData?.consultantStatus?.potentialLeader
    ? {
        top: "425px",
        left: "90px",
        width: "380px",
        height: "60px",
      }
    : {
        top: "200px",
        left: "90px",
        width: "380px",
        height: "60px",
      };

  return (
    <div className=" h-100 w-100 position-absolute container--padding ">
      <div className=" position-relative">
        {biggerThan1920 && (
          <div
            style={s3Styles}
            className=" position-absolute whiteBg rounded-3 p-1  "
          >
            <div
              style={{ top: "0px", left: "0px" }}
              className=" position-absolute w-100 h-100 whiteBg pulsing  "
            ></div>
            <div className=" position-relative rounded-3 gap-2 px-2 pe-0 flex-column  d-flex ">
              <div className=" w-100 m-0 d-flex justify-content-between align-items-center ">
                <div className=" d-flex">
                  <p className=" text-capitalize s14 blackColor museo-sans-500">
                    <FormattedMessage id="dashboard.period.subtitle.one" />
                  </p>
                </div>
                <div className="   d-flex dm-sans-medium gap-1">
                  <p>
                    {" "}
                    <FormattedMessage id="currency" />{" "}
                    {scoreData?.generalDataArray?.triperiodicSales?.actualAmount?.toLocaleString()}
                  </p>
                  <p className=" grayColor">
                    / <FormattedMessage id="currency" />{" "}
                    {scoreData?.generalDataArray?.triperiodicSales?.totalAmount?.toLocaleString()}
                    <SVGChevron />
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams
                height={12}
                  status={scoreData.consultantStatus.checkMarkStatus}
                  incompleted={
                    scoreData?.generalDataArray?.triperiodicSales
                      ?.actualAmount <
                    scoreData?.generalDataArray?.triperiodicSales?.totalAmount
                  }
                  gap={false}
                  completed={
                    (scoreData?.generalDataArray?.triperiodicSales
                      ?.actualAmount *
                      100) /
                    scoreData?.generalDataArray?.triperiodicSales?.totalAmount
                  }
                  level={profileData.userInfo.level}
                />
              </div>
            </div>
          </div>
        )}
        {!biggerThan1920 && biggerThan1280 && (
          <div
            style={s2Styles}
            className=" position-absolute whiteBg rounded-3 p-1  "
          >
            <div
              style={{ top: "0px", left: "0px" }}
              className=" position-absolute w-100 h-100 whiteBg pulsing  "
            ></div>
            <div className=" position-relative rounded-3 gap-2 px-2 pe-0 flex-column  d-flex ">
              <div className=" w-100 m-0 d-flex justify-content-between align-items-center ">
                <div className=" d-flex">
                  <p className=" text-capitalize s14 blackColor museo-sans-500">
                    <FormattedMessage id="dashboard.period.subtitle.one" />
                  </p>
                </div>
                <div className="   d-flex dm-sans-medium gap-1">
                  <p>
                    <FormattedMessage id="currency" />{" "}
                    {scoreData?.generalDataArray?.triperiodicSales?.actualAmount?.toLocaleString()}
                  </p>
                  <p className=" grayColor">
                    / <FormattedMessage id="currency" />{" "}
                    {scoreData?.generalDataArray?.triperiodicSales?.totalAmount?.toLocaleString()}
                    <SVGChevron />
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams
                height={12}
                  status={scoreData.consultantStatus.checkMarkStatus}
                  incompleted={
                    scoreData?.generalDataArray?.triperiodicSales
                      ?.actualAmount <
                    scoreData?.generalDataArray?.triperiodicSales?.totalAmount
                  }
                  gap={false}
                  completed={
                    (scoreData?.generalDataArray?.triperiodicSales
                      ?.actualAmount *
                      100) /
                    scoreData?.generalDataArray?.triperiodicSales?.totalAmount
                  }
                  level={profileData.userInfo.level}
                />
              </div>
            </div>
          </div>
        )}
        {!biggerThan1920 && !biggerThan1280 && (
          <div
            style={s1Styles}
            className=" position-absolute whiteBg rounded-3 p-1  "
          >
            <div
              style={{ top: "0px", left: "0px" }}
              className=" position-absolute w-100 h-100 whiteBg pulsing  "
            ></div>
            <div className=" position-relative rounded-3 gap-2 px-2 pe-0 flex-column  d-flex ">
              <div className=" w-100 m-0 d-flex justify-content-between align-items-center ">
                <div className=" d-flex">
                  <p className=" text-capitalize s14 blackColor museo-sans-500">
                    <FormattedMessage id="dashboard.period.subtitle.one" />
                  </p>
                </div>
                <div className="   d-flex dm-sans-medium gap-1">
                  <p>
                    <FormattedMessage id="currency" />{" "}
                    {scoreData?.generalDataArray?.triperiodicSales?.actualAmount?.toLocaleString()}
                  </p>
                  <p className=" grayColor">
                    / <FormattedMessage id="currency" />{" "}
                    {scoreData?.generalDataArray?.triperiodicSales?.totalAmount?.toLocaleString()}
                    <SVGChevron />
                  </p>
                </div>
              </div>
              <div>
                <ProgressBarParams 
                height={12}
                  status={scoreData.consultantStatus.checkMarkStatus}
                  incompleted={
                    scoreData?.generalDataArray?.triperiodicSales
                      ?.actualAmount <
                    scoreData?.generalDataArray?.triperiodicSales?.totalAmount
                  }
                  gap={false}
                  completed={
                    (scoreData?.generalDataArray?.triperiodicSales
                      ?.actualAmount *
                      100) /
                    scoreData?.generalDataArray?.triperiodicSales?.totalAmount
                  }
                  level={profileData.userInfo.level}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
