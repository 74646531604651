import React from 'react'

export default function Radius({status}) {
  return (
    
    status ? 
        <div style={{height: "22px", width: "22px"}} className="rounded-circle border border-2 skyBorder bronceBg d-flex justify-content-center align-items-center">
            <div style={{width: "12px", height: "12px"}} className="rounded-circle skyBg"></div>
        </div>
    :
        <div style={{height: "22px", width: "22px", backgroundColor: "#DDDDDD33"}} className="rounded-circle border border-2 brightGrayBorder d-flex justify-content-center align-items-center">
            {/*<div style={{width: "12px", height: "12px"}} className="rounded-circle skyBg"></div>*/}
        </div>
  )
}
