import { useMediaPredicate } from "react-media-hook";

export default function ButtonSubmit({ children, styleOverride }) {

  const smallerThan1281 = useMediaPredicate("(min-width: 1439px)");

  return(
    <button
      id="on-submit-confirm"
      style={{ width: styleOverride ? !smallerThan1281 ? "126px" : "186px" : "288px", height: "40px", outline: "none", border: "0px" }}
      className={`"d-flex justify-content-center align-items-center museo-sans-700 s14 rounded-3 text-white skyBg ${styleOverride ? "" : "mb-5"} no-border border-0"`}
      type="submit"
    >
      {children}
    </button>
  )
}