import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getQualifiedGroup,
  getScoreComparison,
} from "../../../BusinessPlan/Framework/redux/BusinessSideEffect";
import {
  clearArrayVimeo,
  setArrayVimeo,
} from "../../../common/redux/commonActions";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import SkeletonGoodPracticesEcommerce from "../../../common/Share/SkeletonGoodPracticesEcommerce";
import SkeletonGoodPracticesImportant from "../../../common/Share/SkeletonGoodPracticesImportant";
import { fetchUserProfile } from "../../../Login/Framework/redux/loginSideEffects";
import {
  fetchEcommerce,
  fetchImportant,
} from "../../Framework/redux/GoodPracticeSideEffect";
import GoodPracticesECommerceCard from "../components/GoodPracticeECommerceCard";
import GoodPracticeModalInfo from "../components/GoodPracticeModalInfo";
import GoodPracticeModalPlan from "../components/GoodPracticeModalPlan";
import GoodPracticeNextLevel from "../components/GoodPracticeNextLevel";
import GoodPracticesImportantCard from "../components/GoodPracticesImportantCard";

import "./GoodPractices.css";

export default function GoodPractices() {
  const dispatch = useDispatch();
  const important = useSelector((state) => state.goodPracticeData.important);
  const ecommerce = useSelector((state) => state.goodPracticeData.ecommerce);
  const loading = useSelector((state) => state.goodPracticeData.isLoading);
  const comparingData = useSelector((state) => state.businessData.data);

  const qualifiedGroups = useSelector(
    (state) => state.businessData.qualifiedGroups
  );
  const profileData = useSelector((state) => state.userInfo.userInfo);
  const profileLevel = useSelector((state) => state.userInfo.userInfo.level);

  const comparisonByLevel = (level) => {
    const levelMap = {
      0: 2,
      1: 2,
      2: 3,
      3: 4,
      4: 5,
      5: 6,
      6: 7,
      7: 8,
      8: 8,
    };
    return levelMap[level] || 8;
  };

  const startUp = async () => {
    const val1 = await dispatch(fetchImportant());
    const val2 = await dispatch(fetchEcommerce());
    await dispatch(fetchUserProfile());
    let array = [];

    if (important) {
      val1.payload?.importantCards?.map((cards) => {
        cards.links.map((link) => {
          if (link.type != "deep_link") {
            let object = { id: link.id, key: link.key };
            array.push(object);
          }
        });
      });
    }

    if (ecommerce) {
      val2.payload?.ecommerceCards?.map((cards) => {
        cards.links.map((link) => {
          if (link.type != "deep_link") {
            let object = { id: link.id, key: link.key };
            array.push(object);
          }
        });
      });
    }

    dispatch(clearArrayVimeo());

    if (array.length > 1) {
      dispatch(setArrayVimeo(array));
    }
  };

  const startUpNextLevel = async () => {
    await dispatch(
      getScoreComparison(
        profileLevel != 0
          ? { level: comparisonByLevel(profileLevel) }
          : { level: 2 }
      )
    );
    await dispatch(
      getQualifiedGroup(
        profileLevel != 0
          ? { level: comparisonByLevel(profileLevel) }
          : { level: 2 }
      )
    );
  };

  useEffect(() => {
    startUp();
  }, []);

  useEffect(() => {
    startUpNextLevel();
  }, []);

  return (
    <div className=" container--padding container--padding--card-holder ">
      <div className=" py-4">
        <ShareModalStructure id={"modalInfo"} maxWidth={"600px"}>
          <GoodPracticeModalInfo />
        </ShareModalStructure>
        <ShareModalStructure id={"modalInfoPlan"} maxWidth={"600px"}>
          <GoodPracticeModalPlan />
        </ShareModalStructure>
        <div className=" w-100 py-2">
          {!loading ? (
            <h3 className=" s20 museo-sans-500 m-0">
              <FormattedMessage
                id="good.practice.title.one"
                defaultMessage="Importante"
              />
            </h3>
          ) : (
            <h3
              style={{ width: "150px", height: "20px" }}
              className=" rounded-3 brightGrayBg "
            ></h3>
          )}
          <div className=" gg-cards--gaps d-flex flex-wrap py-3">
            {!loading ? (
              important?.importantCards?.map((card, index) => {
                return (
                  <GoodPracticesImportantCard
                    profileLevel={profileLevel}
                    key={ShareKeyGenerator()}
                    cardData={card}
                  />
                );
              })
            ) : (
              <>
                <SkeletonGoodPracticesImportant />
                <SkeletonGoodPracticesImportant />
                <SkeletonGoodPracticesImportant />
                <SkeletonGoodPracticesImportant />
                <SkeletonGoodPracticesImportant />
                <SkeletonGoodPracticesImportant />
                <SkeletonGoodPracticesImportant />
              </>
            )}
          </div>
        </div>
        <div className=" w-100 py-2">
          {ecommerce?.ecommerceCards?.length <= 0 ? null : (
            <>
              {!loading ? (
                <h3 className=" museo-sans-500 s20">
                  <FormattedMessage
                    id="good.practice.title.two"
                    defaultMessage="Tienda Virtual Just"
                  />
                </h3>
              ) : (
                <h3
                  style={{ width: "150px", height: "20px" }}
                  className=" rounded-3 brightGrayBg "
                ></h3>
              )}
              <div className=" gg-cards--gaps gap-3 d-flex flex-wrap py-3">
                {!loading ? (
                  ecommerce?.ecommerceCards?.map((card) => {
                    return (
                      <GoodPracticesECommerceCard
                        key={ShareKeyGenerator()}
                        cardData={card}
                      />
                    );
                  })
                ) : (
                  <div className="gg-cards--gaps gap-3 d-flex flex-wrap py-3 ">
                    <SkeletonGoodPracticesEcommerce />
                    <SkeletonGoodPracticesEcommerce />
                    <SkeletonGoodPracticesEcommerce />
                    <SkeletonGoodPracticesEcommerce />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {!loading && (
          <div className=" w-100 py-2">
            {!loading ? (
              <h3 className="s20 museo-sans-500">
                <FormattedMessage
                  id="good.practice.title.three"
                  defaultMessage="Tu plan de Negocio"
                />{" "}
                <i
                  className="pointer"
                  data-bs-toggle="modal"
                  data-bs-target="#modalInfoPlan"
                >
                  <HelpIconNoTarget />
                </i>
              </h3>
            ) : (
              <h3
                style={{ width: "150px", height: "20px" }}
                className=" rounded-3 brightGrayBg "
              ></h3>
            )}

            <div className="py-3">
              <div
                style={{ borderRadius: "1rem" }}
                className=" d-flex flex-column  gg-card--course-plan edb-details-card-shadow f9GrayBorder  bg-white p-3"
              >
                {comparingData && (
                  <GoodPracticeNextLevel
                    scoreQualifiedGroups={qualifiedGroups}
                    scoreData={comparingData}
                    profileData={profileData}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className=" w-100 py-2">
            <h3
              style={{ width: "150px", height: "20px" }}
              className=" rounded-3 brightGrayBg "
            ></h3>
            <div className="py-3">
              <div
                style={{ borderRadius: "1rem" }}
                className=" d-flex flex-column  gg-card--course-plan edb-details-card-shadow f9GrayBorder  bg-white p-3"
              >
                <>
                  <div
                    style={{ height: "8px" }}
                    className=" w-25 mb-3 rounded-3 brightGrayBg  "
                  ></div>
                  <div
                    style={{ height: "16px" }}
                    className=" w-50 mb-3 rounded-3 brightGrayBg  "
                  ></div>
                  <div className=" d-flex flex-column gap-2 my-3">
                    <div
                      style={{ height: "12px" }}
                      className=" w-100 rounded-3 brightGrayBg  "
                    ></div>
                  </div>
                  <div className=" d-flex justify-content-between">
                    <div
                      style={{ height: "12px" }}
                      className=" w-50 rounded-3 brightGrayBg  "
                    ></div>
                    <div
                      style={{ height: "12px" }}
                      className=" w-50 rounded-3   "
                    ></div>
                    <div
                      style={{ height: "12px" }}
                      className=" w-50 rounded-3 mb-3 d-flex gap-2  "
                    >
                      <div
                        style={{ height: "8px" }}
                        className=" w-100 rounded-3 brightGrayBg  "
                      ></div>
                      <div
                        style={{ height: "8px" }}
                        className=" w-100 rounded-3 brightGrayBg  "
                      ></div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{ height: "12px" }}
                      className=" w-25 rounded-3 brightGrayBg  "
                    ></div>
                  </div>
                  <div
                    style={{ width: "38px", height: "38px" }}
                    className=" mt-3 rounded-circle brightGrayBg  "
                  ></div>
                  <div
                    style={{ height: "8px" }}
                    className=" mt-4 w-25 rounded-3 brightGrayBg  "
                  ></div>
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
