import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGChevLeft } from "../../../../common/svgs/ic-chevron-left-md.svg";

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FirstStepsHelpMessage } from "../../../../FirstSteps/Presentation/components/FirstStepsHelpMessage";

import { ReactComponent as SVGExternal } from "../../../../common/svgs/ic-external-link-white-md.svg";
import FirstStepsCardContainerProgress from "../FirstStepsCardContainerProgress";
import { fetchCMSIfStepIdExist } from "../FirstStepFetchCMSDataById";
import FirstStepsMeasureOutdated from "../FirstStepsMeasureOutdated";
import FirstStepsTagHolder from "../FirstStepsTagHolder";
import image1 from "../../../../common/img/enviar-pedido.png";
import imagenBotonMenu from "../../../../common/img/boton-menu.png";
import imagenMenuLogro from "../../../../common/img/menu-logros-opciones.png";
import ImagenAceites from "../../../../common/img/aceites.png";
import ImagenAviso from "../../../../common/img/screen.png";
import { getCMSDataOne } from "../../../Framework/redux/FirstStepsAllSideEffect";

export default function FirstStepsModalCardDetail(params) {
  const [toggle, setToggle] = useState(true);

  const [CMSData2, setAuxCMData2] = useState();

  const CMSDataArray = useSelector(
    (state) => state.firstStepsAllReducer.CMSOne?.data
  );

  const CMSData = useSelector(
    (state) => state.firstStepsAllReducer.CMSOne?.data
  );

  const link = useSelector(
    (state) => state.firstStepsAllReducer?.firstSteps?._metadata?.checkInOrder
  );

  const dataRedeem = useSelector(
    (state) => state.firstStepsAllReducer?.firstSteps?.redeemEndDate
  );

  const scrollbarRef = useRef(null);

  const data = useSelector(
    (state) => state.firstStepsAllReducer.firstStepsCardDetail?.data
  );

  const img = useSelector(
    (state) => state.firstStepsAllReducer.firstStepsCardDetail?.img
  );

  const extraData = useSelector(
    (state) => state.firstStepsAllReducer.firstStepsCardDetail?.extraData
  );

  const ofersData = useSelector((state) => state.firstStepsAllReducer);

  const dispatch = useDispatch();

  const activateCMSExternal = async (clientId, planId) => {
    const clickModal = document.getElementById(`guidelines-${clientId}`);
    await dispatch(getCMSDataOne(planId));
    clickModal.click();
  };

  const changeToggler = () => {
    setToggle(!toggle);
  };

  const resetToggler = () => {
    setToggle(true);
  };
  const modalRef = useRef(null);

  useEffect(() => {
    resetToggler();
    if (modalRef.current) {
      modalRef.current.addEventListener("click", (event) => {
        if (event.target === modalRef.current) {
          resetToggler();
        }
      });
    }
  }, [data]);

  useEffect(() => {
    let auxIndex = null;

    for (let index = 0; index < CMSDataArray?.stepId?.length; index++) {
      if (CMSDataArray?.stepId[index]?.stepId == data?.stepId) {
        auxIndex = index;
      }
    }
    if (auxIndex != null) {
      setAuxCMData2(CMSDataArray?.stepId[auxIndex]);
    }
  }, [data]);

  return (
    <>
      <div
        className="modal fade"
        id="modalFirstStepsCardDetail"
        ref={modalRef}
        aria-hidden="true"
        aria-labelledby="modalFirstStepsCardDetail"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered "
          style={{ maxWidth: "807px" }}
        >
          <div
            className="modal-content modal-radius"
            style={{ maxHeight: "640px" }}
          >
            <div
              className="d-flex "
              style={{
                padding: "32px 56px 32px 32px",
                height: "640px",
                gap: "32px",
              }}
            >
              <div
                style={{
                  borderRadius: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  width: "359px",
                  overflow: "hidden",
                  minWidth: "359px",
                }}
                className="position-relative"
              >
                <img
                  style={{
                    borderRadius: "15px",
                  }}
                  src={img}
                />
                {CMSData2 && (
                  <FirstStepsTagHolder
                    numberType={CMSData2.stepId}
                    size={"other"}
                    type={CMSData2.benefit}
                    amount={CMSData2.amount}
                  />
                )}
              </div>
              <div
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-all-modal"
                className=" position-absolute invisible"
              >
                close
              </div>
              <div className="w-100 d-flex flex-column position-relative">
                {!toggle && (
                  <div
                    onClick={changeToggler}
                    style={{ left: "0px", top: "10px" }}
                    className=" pointer d-flex museo-sans-500 position-absolute"
                  >
                    <SVGChevLeft />
                    <p className=" px-1 pe-0 skyColor museo-sans-700 s16 ">
                      <FormattedMessage id="buttons.back" />
                    </p>
                  </div>
                )}
                <div
                  // onClick={closeModalAll}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  style={{ right: "-16px", top: "8px" }}
                  className="pointer position-absolute"
                  onClick={resetToggler}
                >
                  <SVGClose />
                </div>
                {toggle ? (
                  <>
                    <div className=" d-flex flex-column justify-content-between h-100 w-100 mt-2">
                      <div
                        ref={scrollbarRef}
                        className=" hidden-y-scroll"
                        style={{ height: "440px" }}
                      >
                        <p className=" mt-3 d-flex align-items-center gap-2 text-uppercase museo-sans-500 s12">
                          <FirstStepsMeasureOutdated data={data} />
                        </p>
                        <div
                          style={{ marginTop: "15px" }}
                          className="d-flex flex-column gap-2"
                        >
                          <p className=" museo-sans-700 s20 grayColor ">
                            {data?.stepDescription}
                          </p>
                          <p
                            className=" museo-sans-700 s20"
                            style={{ lineHeight: "103%" }}
                          >
                            {data &&
                              fetchCMSIfStepIdExist(CMSData, data?.stepId)
                                ?.title}
                          </p>
                          <div className=" mt-2">
                            <p className=" museo-sans-500 s16 ">
                              {data &&
                                fetchCMSIfStepIdExist(CMSData, data?.stepId)
                                  ?.description}
                            </p>
                          </div>
                        </div>
                        <div className=" mt-3 pt-1">
                          <FirstStepsCardContainerProgress
                            extraData={extraData}
                            data={data}
                            index={data?.stepId}
                          />
                        </div>
                        <div
                          style={{ borderRadius: "8px" }}
                          className={` s14  museo-sans-500 `}
                        >
                          {data?.stepDetail?.state != "FAILED" &&
                            data?.stepDetail?.reachedDate !=
                              "0000-00-00 00:00:00" && (
                              <div className=" h-100 bg-transparent d-flex justify-content-end align-items-end ">
                                <FirstStepsHelpMessage
                                  data={data}
                                  index={data?.stepId}
                                  redeemEndDate={dataRedeem}
                                  amount={CMSData && CMSData.benefit === 'DISCOUNT_PCT' && CMSData.amount}
                                />
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="" style={{ marginBottom: "18px" }}>
                        {data?.stepDetail?.state != "FAILED" && (
                          <>
                            {data?.stepDetail?.state == "REACHED" ? (
                              <div
                                className=" mt-3 d-flex w-100 justify-content-center"
                                onClick={changeToggler}
                              >
                                <a
                                  style={{ height: "40px" }}
                                  className=" pointer w-100 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
                                >
                                  <>
                                    <div className=" d-flex justify-content-center gap-2">
                                      <p className="">
                                        {CMSData2?.benefit == "GIFT" ? (
                                          <></>
                                        ) : (
                                          <>
                                            <FormattedMessage id="first.steps.details.discount.p.use.discount" />
                                          </>
                                        )}
                                      </p>
                                      <div
                                        style={{ width: "16px" }}
                                        className=" d-flex gap-2 svg-force-size  "
                                      >
                                        <SVGExternal />
                                      </div>
                                    </div>
                                  </>
                                </a>
                              </div>
                            ) : (
                              <div className=" mt-3 d-flex w-100 justify-content-center">
                                <a
                                  style={{ height: "40px" }}
                                  target="_blank"
                                  href={link}
                                  className=" pointer w-100 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
                                >
                                  {data?.stepId != 3 ? (
                                    <>
                                      <div className=" d-flex justify-content-center gap-2">
                                        <p className="">
                                          <FormattedMessage id="first.steps.details.discount.p.upload.request" />
                                        </p>
                                        <div
                                          style={{ width: "16px" }}
                                          className=" d-flex gap-2 svg-force-size  "
                                        >
                                          <SVGExternal />
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className=" d-flex justify-content-center align-items-center gap-2">
                                        <p
                                          style={{ top: 2 }}
                                          className=" position-relative"
                                        >
                                          Enviar pedido de EDB
                                        </p>
                                        <div
                                          style={{ width: "16px" }}
                                          className=" d-flex gap-2 svg-force-size  "
                                        >
                                          <SVGExternal />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </a>
                              </div>
                            )}
                          </>
                        )}
                        <div className=" mt-3 d-flex w-100 justify-content-center">
                          <button
                            onClick={() => {
                              activateCMSExternal(
                                "",
                                ofersData.firstSteps?.planId
                              );
                            }}
                            type="button"
                            className=" w-100 museo-sans-700 s14 skyColor skyBorder bg-white button-hover-light-text button-bootstrap-radius button-padding"
                            data-bs-toggle="modal"
                            data-bs-target="#modalFirstStepsGuidelinesB"
                            style={{ height: "40px" }}
                          >
                            <FormattedMessage id="first.steps.guidelines.program" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className=" d-flex flex-column justify-content-between h-100 w-100"
                      style={{ marginTop: "52px" }}
                    >
                      <p
                        style={{ color: "#000000" }}
                        className="museo-sans-700 s20"
                      >
                        ¿Cómo compro los productos con descuento de los Logros
                        obtenidos?
                      </p>
                      <div
                        ref={scrollbarRef}
                        className=" hidden-y-scroll"
                        style={{ height: "400px" }}
                      >
                        <div>
                          <div style={{ marginTop: "15px" }}>
                            <p
                              style={{ color: "#171A22" }}
                              className="museo-sans-500 s16"
                            >
                              Para poder comprar los productos con el descuento
                              correspondiente del Logo obtenido, tienes que
                              seguir los siguientes pasos:
                            </p>
                          </div>
                        </div>
                        <div style={{ marginTop: "24px", marginBottom: "8px" }}>
                          <p
                            className="museo-sans-700 s16"
                            style={{ color: "#171A22" }}
                          >
                            Consolidar pedido:
                          </p>
                        </div>
                        <div
                          className="museo-sans-500 s16 d-flex flex-column"
                          style={{ gap: "16px" }}
                        >
                          <p>
                            Al hacer click en el botón de “Consolidar Pedido”
                            serás redirigido/a a “Mis Órdenes”.
                          </p>
                          <p>
                            Si ya creaste todas las órdenes que quieras enviar a
                            Just, tendrás que hacer click en “Enviar Pedido”
                            para poder seleccionar los productos con descuento
                            del programa de Primeros Pasos:
                          </p>
                        </div>
                        <div>
                          <img src={image1}></img>
                        </div>
                        <div style={{ marginTop: "28px" }}>
                          <p
                            className="museo-sans-700 s16"
                            style={{ color: "#171A22" }}
                          >
                            Enviar Pedido
                          </p>
                        </div>
                        <div style={{ marginTop: "16px" }}>
                          <p className="museo-sans-500 s16 d-flex flex-column">
                            Una vez que avanzas en el proceso de consolidación
                            de tu pedido, tienes que hacer click en el ícono de
                            "menú":
                          </p>
                        </div>
                        <div>
                          <img src={imagenBotonMenu}></img>
                        </div>
                        <div
                          style={{ marginTop: "16px", marginBottom: "16px" }}
                        >
                          <p className="museo-sans-500 s16">
                            y hacer click en la opción del menú del Logro que
                            has alcanzado para poder seleccionar los productos a
                            comprar con descuento
                          </p>
                        </div>
                        <div>
                          <img src={imagenMenuLogro}></img>
                        </div>
                        <div style={{ marginTop: "34px", marginBottom: "8px" }}>
                          <p
                            className="museo-sans-700 s16"
                            style={{ color: "#171A22" }}
                          >
                            Selección de productos con descuento
                          </p>
                        </div>
                        <div>
                          <p className="museo-sans-500 s16">
                            Los productos presentados ya tienen aplicado el
                            descuento correspondiente al Logro obtenido, y
                            tendrás que ir agregándolos calculando el monto
                            total del premio
                          </p>
                        </div>
                        <div style={{ marginBottom: "20px" }}>
                          <img src={ImagenAceites}></img>
                        </div>
                        <div>
                          {/* <div>
                            <p>
                              Ten en cuenta que el sistema no te permitirá agregar productos de un Logro que no has obtenido:
                            </p>
                            </div> */}
                          <img
                            style={{ width: "349px" }}
                            src={ImagenAviso}
                          ></img>
                        </div>
                        <div style={{ marginTop: "28px" }}>
                          <p
                            className="museo-sans-700 s18"
                            style={{ color: "#171A22" }}
                          >
                            Logro 3
                          </p>
                        </div>
                        <div
                          style={{ marginTop: "10px" }}
                          className="museo-sans-500 s18"
                        >
                          <p>
                            Los productos presentados ya tienen aplicado el
                            descuento correspondiente al Logro obtenido, y
                            tendrás que ir agregándolos calculando el monto
                            total del premio
                          </p>
                        </div>
                        <div
                          style={{ marginTop: "10px", height: "15px" }}
                          className="museo-sans-500 s18 invisible"
                        ></div>
                        <div
                          style={{
                            right: "14px",
                            bottom: "51px",
                            height: "12px",
                          }}
                          className=" d-flex position-absolute shadow-effect-two
                  "
                        ></div>
                      </div>
                      <div className=" mt-3 mb-3  d-flex w-100 justify-content-center">
                        <a
                          style={{ height: "40px" }}
                          target="_blank"
                          href={link}
                          className=" pointer w-100 museo-sans-700 s14 whiteColor skyBorder skyBg button-hover-light-text button-bootstrap-radius button-padding"
                        >
                          <div className=" d-flex justify-content-center gap-2">
                            <p className="">Cargar orden de compra</p>
                            <div
                              style={{ width: "16px" }}
                              className=" d-flex gap-2 svg-force-size  "
                            >
                              <SVGExternal />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
