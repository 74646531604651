import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGMail } from "../../../common/svgs/social/ic-mail-md.svg";
import { ReactComponent as SVGMessenger } from "../../../common/svgs/social/ic-messenger-md.svg";
import { ReactComponent as SVGWhatsap } from "../../../common/svgs/social/ic-whatsapp-md.svg";
import { ReactComponent as SVGInstagram } from "../../../common/svgs/ic-instagram-md.svg";
import { ReactComponent as SVGFacebook } from "../../../common/svgs/ic-facebook-md.svg";
import { ReactComponent as SVGEcommerce } from "../../../common/svgs/ic-tienda-virtual-md.svg";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";

export default function DescendantProfileHeader({ profileData, loading, root }) {
 const invertedName = profileData?.name.split(" ")
  return ( 
        <div id="account-header" className="">
          <div className=" position-relative w-100">
            <div
              style={{
                top: "-60px",
                left: "0px",
                width: "160px",
                height: "160px",
                alignItems: "center",
              }}
              className=" outline-profile-picture position-absolute border-1 rounded-circle bg-transparent d-flex justify-content-center "
            >
              {
                !loading ?
                <>
                  <ImageCircleHandler size={"42px"} width={"150px"} height={"150px"} url={profileData?.image} firstName={profileData?.firstName}  lastName={profileData?.lastName} top={32} name={profileData?.name} />
                </>
                :
                <>
                  <div style={{width: "150px", top: 32, height: "150px"}} className="brightGrayBg rounded-circle"  >

                  </div>
                </>
              }
              
            </div>
            <div
              style={{ top: "-0px", left: "176px" }}
              className="position-relative mt-4 "
            >
              {
                !loading ?
                <>
                  <p
                    id="account-header--title"
                    className=" d-flex flex-wrap s30 museo-sans-700 "
                  >
                    {
                      profileData?.lastName == "" && profileData?.firstName == "" ?
                      <>
                      {profileData?.name}
                      </>
                      :
                      <>
                      {profileData?.lastName} {profileData?.firstName} 
                      </>
                    }
                  </p>
                </>
                :
                <div style={{height: "24px", width: "210px"}} className=" mb-2 rounded-3 brightGrayBg ">
                
                </div>
              }
              {
                !loading ?
                <>
                  <div className="s14 museo-sans-500 d-flex">
                <p>ID {root ? root : localStorage.getItem("clientId")} |</p>
                <p className=" invisible">a</p>
                <p className=" text-capitalize ">
                  {" "}
                  <FormattedMessage id="account.header.region" />{" "}
                  {profileData?.region}
                </p>
              </div>
                </>
                :
                <div style={{height: "16px", width: "140px"}} className=" mb-2 rounded-3 brightGrayBg ">
                
                </div>
              }
              
              {
                !loading ?
                <div className="mt-3 d-flex gap-3" >
                  {
                    profileData?.socialNetwork &&
                    <MapSocialNetwork socialArray={profileData?.socialNetwork} />
                  }
                </div>
                :
                <div className="d-flex gap-3" >
                    <div style={{height: "24px", width: "24px"}} className=" rounded-circle brightGrayBg ">
                    
                    </div>
                    <div style={{height: "24px", width: "24px"}} className=" rounded-circle brightGrayBg ">
                    
                    </div>
                    <div style={{height: "24px", width: "24px"}} className=" rounded-circle brightGrayBg ">
                    
                    </div>
                    <div style={{height: "24px", width: "24px"}} className=" rounded-circle brightGrayBg ">
                    
                    </div>

                </div>
              }
              
            </div>
          </div>
        </div>
  );
}

export function MapSocialNetwork({ socialArray }) {

  const sharedDataPhoneText = ""
    
  const sharedData = ""

  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  return (
    <>
      {configExist("email") !== -1 && (
        <a
          href={`mailto:${
            socialArray[configExist("email")].value
          }?&body=`}
          
          target="_blank"
          className=" grayColor cancelBootstrap"
        >

              <SVGMail />
        </a>
      )}
      {configExist("whatsapp") !== -1 && (
        <a
          href={`https://wa.me/${
            socialArray[configExist("whatsapp")].value
          }?text=`}
          target="_blank"
          className=" bronceStroke grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGWhatsap />
          </div>
        </a>
      )}
      {configExist("messenger") !== -1 && (
        <a
          href={`https://m.me/${
            socialArray[configExist("messenger")].value
          }?text=`}
          target="_blank"
          className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGMessenger />
          </div>
        </a>
      )}
      {configExist("facebook") !== -1 && (
        <a
          href={`${socialArray[configExist("facebook")].value}`}
          target="_blank"
          className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGFacebook />
          </div>
        </a>
      )}
      {configExist("instagram") !== -1 && (
        <a
          href={`https://www.instagram.com/${
            socialArray[configExist("instagram")].value
          }`}
          target="_blank"
          className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGInstagram />
          </div>
        </a>
      )}
      {configExist("tienda_virtual") !== -1 && (
        <a
        href={`${socialArray[configExist("tienda_virtual")].value}`}
        target="_blank"
        className=" bronceStroke bronceFill grayColor cancelBootstrap"
        >
          <div
            style={{ alignItems: "center" }}
            className=" pointer d-flex flex-column justify-content-center"
          >
              <SVGEcommerce />
          </div>
        </a>
      )}
    </>
  );
}
