import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";

export default function InvitationsModalIncorporatedPopUp(params) {
  return (
    <>
      <div className=" museo-sans-500 ">
        <div className="d-flex mx-3 mt-3 p-4 align-items-center  justify-content-between">
          <div className="d-flex s20 align-items-center gap-1">
            <HelpIconNoTarget />
            <p>
              <FormattedMessage id="noKit" />
            </p>
          </div>
          <div
            className="pointer position-relative svg-force-size "
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{ width: "24px", height: "24px", top: -2 }}
          >
            <SVGClose />
          </div>
        </div>
        <hr className=" m-0" />
        <div className="m-3 p-4 s16 ">
          <p>{params?.body ? params?.body : ""}</p>
        </div>
        <div className="d-flex justify-content-center">
          <button
            type="button"
            className=" btnLoginWidth mb-4 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <FormattedMessage
              id="buttons.understood"
              defaultMessage="Entendido"
            />
          </button>
        </div>
      </div>
    </>
  );
}
