import { createReducer } from "@reduxjs/toolkit";
import { activeBlur, cancelBlur } from "./onBoardingActions";

const initialState = {
    blurred: false
};

const onBoardingReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(activeBlur, (state, action) => {
          state.blurred = true;
        })
        .addCase(cancelBlur, (state, action) => {
          state.blurred = false;
        })
})


export default onBoardingReducer;