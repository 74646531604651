import { createAsyncThunk } from "@reduxjs/toolkit";
import customNetServAxios from "../../../common/services/NetSrv";

const fetchEDBDetails   = createAsyncThunk(
    'fetchEDBDetails',
    async (edbId, {rejectWithValue}) => {
        try {
            let location = localStorage.getItem("country");
            let userId = localStorage.getItem("clientId");
            const response = await customNetServAxios().get(`/${location}/edbs/${userId}/edb-details/${edbId}`);
            return response;
        } catch(error) {
            return rejectWithValue(error.message);
        }
    }
)

export {fetchEDBDetails}