import { useState, useContext, useRef } from "react";

import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import ShareModalShareBody from "../../../common/Share/ShareModalShareBody";
import VimeoControllerAlone from "../../../common/VimeoController/VimeoControllerAlone";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import Image from "../SVGs/dashboardCatalogImg.png";

import { ReactComponent as SVGVideo } from "../../../common/svgs/ic-video-sm.svg";
import { ReactComponent as SVGExternal } from "../../../common/svgs/ic-external-link-md.svg";
import { ReactComponent as SVGCross } from "../../../common/svgs/ic-x-lg.svg";
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import { FormattedMessage } from "react-intl";
import { ModalClipboardContext } from "../../../common/routes/routes";
import ModalWhiteShadow from "../../../common/Share/ModalWhiteShadow";
import { useMediaPredicate } from "react-media-hook";

export default function DashboardCatalog({ socialArray, phone }) {
  const country = localStorage.getItem("country");
  const biggerThan1000 = useMediaPredicate("(min-width: 1281px)");

  const [vimeo, setVimeo] = useState({
    show: false,
    id: "658693857",
    key: "c5b977e224",
  });

  const URLAccordingToCountry = {
    ar: `http://catalogo.justargentina.com/?p=${phone}`,
    cl: `http://catalogo.justchile.com/?p=${phone}`,
    co: `http://catalogo.justcolombia.com/?p=${phone}`,
    cr: `http://catalogo.justcostarica.com/?p=${phone}`,
    mx: `http://catalogo.justmexico.com/?p=${phone}`,
    py: `http://catalogo.justparaguay.com/?p=${phone}`,
    pa: `http://catalogo.justpanama.com/?p=${phone}`,
    pe: `http://catalogo.justperu.com/?p=${phone}`,
    uy: `http://catalogo.justuruguay.com/?p=${phone}`,
  };

  const onCloseVimeo = () => {
    setVimeo({ ...vimeo, show: false });
  };

  const onVideoClick = () => {
    setVimeo({ ...vimeo, show: true });
  };

  const onShowCatalog = () => {
    const idCatalog = document.getElementById("show-catalog");
    idCatalog.click();
  };

  return (
    <div className="d-flex flex-column w-100 museo-sans-500">
      <DashboardCatalogModalInfo />
      <DashboardCatalogModalShare
        socialArray={socialArray}
        URLAccordingToCountry={URLAccordingToCountry[country]}
      />
      <VimeoControllerAlone
        close={onCloseVimeo}
        vimeo={vimeo}
        show={vimeo.show}
      />
      <div className=" d-flex gap-2 mb-4">
        <p className="s20">
          <FormattedMessage id="dashboard.catalog.title" />
        </p>
        <div
          data-bs-toggle="modal"
          data-bs-target="#modal-share-catalog-info"
          style={{ top: "1px" }}
          className=" position-relative pointer"
        >
          <HelpIconNoTarget />
        </div>
      </div>
      <div
        style={{ height: "196px" }}
        className=" rounded-4 d-flex w-100 tabs-shadow rounded-3 overflow-hidden "
      >
        <div
          style={{ minWidth: "167px" }}
          className=" position-relative lightVioletBg  "
        >
          <div
            style={{ top: "8px", left: "20px" }}
            className=" position-absolute"
          >
            <img style={{ width: "168px" }} src={Image} />
          </div>
        </div>
        <div
          className={` d-flex flex-column ${
            biggerThan1000
              ? "px-5 pe-0 mx-2 me-0 py-4 my-2 gap-4"
              : "px-4 pe-0 mx-4 me-0 py-4 my-0 gap-3"
          } `}
        >
          <div
            style={{ paddingRight: "48px" }}
            className="d-flex flex-column gap-2 "
          >
            <p className="  s20 pb-1 ">
              <FormattedMessage id="dashboard.catalog.subtitle" />
            </p>
            <div
              onClick={onVideoClick}
              className=" invisible s14 d-flex gap-1 pointer"
            >
              <p className=" museo-sans-700 skyColor s14">
                <FormattedMessage id="dashboard.catalog.vid" />
              </p>
              <div
                style={{ width: "16px", top: "-4px" }}
                className=" position-relative svg-force-size"
              >
                <SVGVideo />
              </div>
            </div>
          </div>
          <div
            className={` museo-sans-700 s14 d-flex  ${
              biggerThan1000 ? "gap-4" : "gap-3"
            } `}
          >
            <div
              data-bs-toggle="modal"
              data-bs-target="#modal-share-catalog"
              className=" d-flex justify-content-center align-items-center skyBg text-white rounded-3 btnLoginWidth pointer"
            >
              <FormattedMessage id="dashboard.catalog.button" />
            </div>
            <div
              onClick={onShowCatalog}
              className=" d-flex justify-content-center align-items-center skyColor border-1 skyBorder rounded-3 btnLoginWidth pointer gap-1 "
            >
              <p className=" museo-sans-700">
                <FormattedMessage id="dashboard.catalog.button.2" />
              </p>
              <div
                style={{ width: "16px", height: "16px", top: " -3px" }}
                className=" position-relative svg-force-size"
              >
                <SVGExternal />
              </div>
            </div>
            <a
              id="show-catalog"
              target="_blank"
              href={URLAccordingToCountry[country]}
              className=" invisible"
            ></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DashboardCatalogModalShare({
  socialArray,
  URLAccordingToCountry,
}) {
  const { setModalTexts } = useContext(ModalClipboardContext);

  const configExist = (auxId, message) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const sharedDataPhoneText =
    socialArray &&
    `*${
      multiLangJSONParse()["dashboard.catalog.share.message"]
    }* \n*${URLAccordingToCountry}*`;

  const sharedData =
    socialArray &&
    `${
      multiLangJSONParse()["dashboard.catalog.share.message"]
    } \n${URLAccordingToCountry} `;

  const doThis = async () => {
    try {
      await navigator.clipboard.writeText(sharedData);
      const modalData = {
        title: <FormattedMessage id="dashboard.catalog.clipboard.title" />,
        text: <FormattedMessage id="dashboard.catalog.clipboard.message" />,
        status: "confirm",
        show: false,
        bottom: 0,
      };
      setModalTexts(modalData);
      //open();
    } catch (error) {
      unsecuredCopyToClipboard(sharedData);
    }
  };

  const unsecuredCopyToClipboard = async (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      document.execCommand("copy");
    } catch (err) {
      console.error("Unable to copy to clipboard", err);
    }
    const modalData = {
      title: <FormattedMessage id="dashboard.catalog.clipboard.title" />,
      text: <FormattedMessage id="dashboard.catalog.clipboard.message" />,
      status: "confirm",
      show: false,
      bottom: 0,
    };
    setModalTexts(modalData);
    document.body.removeChild(textArea);
  };

  return (
    <ShareModalStructure id={"modal-share-catalog"}>
      {socialArray && (
        <ShareModalShareBody
          modalTitle={"dashboard.catalog.modal.share.title"}
          sharedDataPhoneText={sharedData}
          sharedData={sharedData}
          socialArray={socialArray}
          configExist={configExist}
          doThis={doThis}
        />
      )}
    </ShareModalStructure>
  );
}

export function DashboardCatalogModalInfo() {
  const containerRef = useRef(null);

  return (
    <ShareModalStructure
      maxWidth={"608px"}
      minHeight={"451px"}
      id={"modal-share-catalog-info"}
    >
      <div className="d-flex flex-column justify-content-between h-100 museo-sans-500 ">
        <div className="h-100">
          <div className="d-flex justify-content-between mt-4 pt-3 mx-4 ">
            <div className="d-flex gap-2">
              <HelpIconNoTarget />
              <p className="px-1 s20">
                <FormattedMessage id="dashboard.catalog.modal.info.title" />
              </p>
            </div>
            <div
              style={{ width: "28px" }}
              className="pointer svg-force-size "
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <SVGCross />
            </div>
          </div>
          <div className="py-3">
            <hr className="my-4 " />
          </div>
          <div
            ref={containerRef}
            style={{ height: "176px" }}
            className=" px-3 mx-3 hidden-y-scroll "
          >
            <p className="mb-3" >
              <FormattedMessage id="dashboard.catalog.modal.info.body.p.one" />
            </p>
            <p className="mb-3" >
            <FormattedMessage id="dashboard.catalog.modal.info.body.p.two" />
            </p>
            <p>
            <FormattedMessage id="dashboard.catalog.modal.info.body.p.three" />
            </p>
            <ModalWhiteShadow scrollbarRef={containerRef} />
          </div>
        </div>
        <div className="d-flex justify-content-center align-items-center my-4 py-3 ">
          <div
            data-bs-dismiss="modal"
            aria-label="Close"
            className="d-flex justify-content-center align-items-center btnLoginWidth skyBg text-white rounded-3 pointer "
          >
            <FormattedMessage id="buttons.understood" />
          </div>
        </div>
      </div>
    </ShareModalStructure>
  );
}
