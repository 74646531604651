import UseAnalyticsEventTracker from "../../../common/services/UseAnalyticsEventTracker";

export default function RegisterAnalytics(label) {

    const category = "reg"

    const auxLabel = `${category}_${label}`

    if (label != "unknow") {
        UseAnalyticsEventTracker(category , auxLabel )
    }
    
}