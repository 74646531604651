import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./clientList.css";

import {
  getClientDetails,
  getClientDetailsLinks,
  getClientList,
} from "../../Framework/redux/ClientListSideEffect";
import ClienteListTableBody from "../components/ClientListTableBody";
import ClientListTableHeader from "../components/ClientListTableHeader";
import ClientListRowSkeleton from "../components/skeletons/ClientListRowSkeleton";
import ClientListHeaderSkeleton from "../components/skeletons/ClientListHeaderSkeleton";
import ClientListEmptyData from "../components/ClientListEmpyData";
import { resetSucessToast } from "../../Framework/redux/ClientListAction";
import { FormattedMessage } from "react-intl";
import { ClientListDetailsContentCard } from "../components/ClientListDetailsContent";
import { useMediaPredicate } from "react-media-hook";
import ModalWhiteShadow from "../../../common/Share/ModalWhiteShadow";

function searchByNameOrLastname(array, search) {
  return array.filter(
    (person) =>
      person?.firstName
        ?.split(" ")[0]
        ?.toLowerCase()
        ?.startsWith(search?.toLowerCase()) ||
      person?.firstName
        ?.split(" ")[1]
        ?.toLowerCase()
        ?.startsWith(search?.toLowerCase()) ||
      person?.lastName
        ?.split(" ")[0]
        ?.toLowerCase()
        ?.startsWith(search?.toLowerCase()) ||
      person?.lastName
        ?.split(" ")[1]
        ?.toLowerCase()
        ?.startsWith(search.toLowerCase()) ||
      person?.firstName?.toLowerCase()?.startsWith(search?.toLowerCase()) ||
      person?.lastName?.toLowerCase()?.startsWith(search?.toLowerCase())
  );
}

function sortContactsByLastname(list) {
  return [...list].sort((a, b) => {
    let lastNameA = a?.lastName?.trim()?.toUpperCase();
    let lastNameB = b?.lastName?.trim()?.toUpperCase();

    if (lastNameA < lastNameB) {
      return -1;
    }
    if (lastNameA > lastNameB) {
      return 1;
    }
    return 0;
  });
}

function groupByFirstLetter(list) {
  const auxList = [...list]?.reduce((accumulator, current) => {
    let firstLetter = current?.lastName?.trim()?.charAt(0)?.toLowerCase();

    if (!accumulator[firstLetter]) {
      accumulator[firstLetter] = [];
    }

    accumulator[firstLetter]?.push(current);
    return accumulator;
  }, {});

  return auxList;
}

export default function ClientList() {
  const [search, setSearch] = useState("");
  const [maxHeight, setMaxHeight] = useState(window.innerHeight);
  const [contactCard, setContactCard] = useState(false);
  const [widthSon, setWidthSon] = useState(0);
  const whiteModalScrollShadowController = useRef(null);
  const scrollRef = useRef(null);
  const containerBodyWidth = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    clientTable,
    clientDetails: { success },
  } = useSelector((state) => state.clientListData);

  const clientDetails = useSelector(
    (state) => state.clientListData.clientDetails
  );

  const biggerThan1280 = useMediaPredicate("(min-width: 1400px)");

  const onStartUp = async () => {
    await dispatch(getClientList());
  };

  const detectMoveToRow = (hostId) => {
    if (hostId) {
      const element = document.getElementById(`row-${hostId}`);
      if (element) {
        element.scrollIntoView({ block: "center" });
        element.click();
      }
    }
  };

  const clientListStatus = () => {
    if (clientTable.loader) {
      return (
        <>
          <ClientListHeaderSkeleton />
          {[...Array(6).keys()].map((i) => (
            <ClientListRowSkeleton key={i} />
          ))}
        </>
      );
    } else if (!contacts.length) {
      return (
        <>
          <div
            style={{ width: `${widthSon}px`, zIndex: "1" }}
            className=" bg-white position-fixed"
          >
            <div
              style={{ height: "80px", top: "-40px" , width: "105%"}}
              className=" position-absolute bg-white  "
            ></div>
            <div
              style={{ top: "20px", zIndex: "5" }}
              className=" bg-white pb-3 position-relative"
            >
              <ClientListTableHeader
                search={search}
                setSearch={setSearch}
                navigate={navigate}
              />
            </div>
          </div>
          <div style={{zIndex: 3}} className=" position-relative w-100 invisible">
            <ClientListTableHeader
              search={search}
              setSearch={setSearch}
              navigate={navigate}
            />
          </div>
          <ClientListEmptyData />
        </>
      );
    } else {
      return (
        <>
          <div
            style={{ width: `${widthSon}px`, zIndex: "2" }}
            className=" bg-white position-fixed bg-white"
          >
            <div
              style={{ height: "130px", top: "-40px" , width: "105%", left: "-18px" }}
              className=" position-absolute bg-white "
            ></div>
            <div
              style={{ top: "20px" }}
              className=" bg-white pb-3 position-relative"
            >
              <ClientListTableHeader
                search={search}
                setSearch={setSearch}
                navigate={navigate}
              />
              <ModalWhiteShadow
                left={"-12px"}
                bottom={-5}
                width={"105%"}
                height={"10px"}
                scrollbarRef={whiteModalScrollShadowController}
                reverseModal={true}
                zIndex={1}
              />
            </div>
          </div>
          <div style={{zIndex: 3}} className=" position-relative w-100 invisible">
            <ClientListTableHeader
              search={search}
              setSearch={setSearch}
              navigate={navigate}
            />
          </div>
          <div className="d-flex w-100 gap-4 mt-3 ">
            <div
              ref={whiteModalScrollShadowController}
              className={` mt-2 ${
                contactCard && !biggerThan1280 ? "w-50" : "w-100"
              } `}
            >
              {Array.isArray(contactsResult) ? (
                <>
                  <div className=" invisible " >
                    <p>
                    invisible content
                    </p>
                    <p>
                    invisible content
                    </p>
                    <p className=" s14" >
                    invisible content
                    </p>
                  </div>
                  <ClienteListTableBody
                    onCardClick={onClickActivateCard}
                    contactsResult={contactsResult}
                    navigate={navigate}
                    clientDetails={clientDetails?.data}
                    withLastStyle
                  />
                </>
              ) : (
                <>
                <div className=" invisible " >
                    <p className=" s12  " >
                    invisible content
                    </p>
                  </div> 
                {
                  Object.keys(contactsResult).map((letter) => (
                    <>
                    
                      <p
                        className="museo-sans-500 s12 mt-5"
                        style={{ color: "#908F9A", alignSelf: "start" }}
                      >
                        {letter.toUpperCase() ?? ""}
                      </p>
                      <ClienteListTableBody
                        onCardClick={onClickActivateCard}
                        contactsResult={contactsResult[letter]}
                        clientDetails={clientDetails?.data}
                        navigate={navigate}
                      />
                    </>
                  ))
                }
                </>
              )}
            </div>
            {contactCard && (
              <div className=" w-50 position-relative d-flex flex-column mt-5 pt-4 ">
                <div className=" position-fixed ">
                  <div className="d-flex align-items-center gap-2 museo-sans-500 ">
                    <>
                      <p className=" s20 museo-sans-500">
                        <FormattedMessage id="client.list.card.title" />
                      </p>
                      {clientDetails.data?.contactOriginType !=
                        "PERSONAL_SITE" && (
                        <>
                          <p
                            onClick={() => onEditValuesEDB(clientDetails?.data)}
                            className=" pointer s14 button-hover-light-text museo-sans-700 skyColor px-1 "
                          >
                            <FormattedMessage id="buttons.edit.data" />
                          </p>
                        </>
                      )}
                    </>
                  </div>

                  <ClientListDetailsContentCard
                    data={clientDetails?.data}
                    loading={clientDetails?.loader}
                    chooseHost={true}
                    socialArray={clientDetails?.data?.socialNetworks}
                    maxHeight={maxHeight}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      );
    }
  };

  const onEditValuesEDB = (data) => {
    navigate(`/dashboard/edbs/form/client/update/${data?.contactId}`);
  };

  const onClickActivateCard = async (id) => {
    setContactCard(true);
    await dispatch(getClientDetails(id));
    await dispatch(getClientDetailsLinks(id));
  };

  const adjustSonWidth = () => {
    if (containerBodyWidth?.current) {
      setWidthSon(containerBodyWidth.current.offsetWidth);
    }
  };

  useEffect(() => {
    adjustSonWidth();
    window.addEventListener("resize", adjustSonWidth);
    document.addEventListener("fullscreenchange", adjustSonWidth);

    return () => {
      window.removeEventListener("resize", adjustSonWidth);
      document.removeEventListener("fullscreenchange", adjustSonWidth);
    };
  }, []);

  const contacts = clientTable.data
    ? sortContactsByLastname(clientTable.data.contacts)
    : [];
  const contactsResult =
    search.length > 2
      ? searchByNameOrLastname(contacts, search)
      : groupByFirstLetter(contacts);

  useEffect(() => {
    if (success?.code) {
      setTimeout(() => {
        dispatch(resetSucessToast());
      }, 3000);
    }
  }, [success]);

  useEffect(() => {
    const myDiv = document.getElementById("container--private-holder");
    myDiv.scrollTop = 0;
    onStartUp();

    const handleResize = () => {
      setMaxHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const auxFormBody = JSON.parse(localStorage.getItem("formBody"));
    const getHostData = auxFormBody?.hostData;
    const getHostId = getHostData?.hostId;
    detectMoveToRow(getHostId);
  }, [clientTable.data]);

  return (
    <div className="container--padding bg-white " ref={scrollRef}>
      <div className=" container--padding--card-holder ">
        <div
          ref={containerBodyWidth}
          style={{ minWidth: "880px" }}
          className="pt-4"
        >
          {clientListStatus()}
        </div>
      </div>
    </div>
  );
}
