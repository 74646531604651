import { useRef, useState, useLayoutEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { useSelector } from "react-redux";

import { ReactComponent as SVGEmptyChart } from "../../../common/svgs/ic-chart-md.svg";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import SkeletonAccountGraph from "../../../common/Share/SkeletonAccountGraph";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import LineChart from "../../../common/ChartSVG/ChartSVG";

export default function AccountGraph({
  profileData,
  onClickToggle,
  graphToggler,
  periodReducer,
  onClickDate,
  onDesc,
  loading,
}) {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [adjust, setAdjust] = useState(false);
  const biggerThan1799 = useMediaPredicate("(min-width: 1799px)");

  useLayoutEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
      if (onDesc) {
        if (biggerThan1799) {
          setHeight(310 - 70);
        } else {
          setHeight(ref.current.offsetHeight - 70);
        }
      } else {
        setHeight(ref.current.offsetHeight - 70);
      }

      if (!adjust && !loading) {
        setTimeout(() => {
          setAdjust(true);
        }, 1000);
      }
    }
  }, [ref.current, loading, adjust]);

  const isGraphEmpty = () => {
    return periodReducer?.metrics?.metrics?.length > 0;
  };

  const renderLineChart = (toggle, data, background, type) => {
    if (!toggle) return null;

    return (
       <LineChart
        width={width}
        height={height}
        data={data}
        horizontalGuides={3}
        precision={2}
        verticalGuides={1}
        onClickDate={onClickDate}
        graphId={periodReducer?.reMetricGraph?.metricId}
        background={background}
        type={type}
      />
    );
  };

  return (
    <div id="account--graph-container">
      <AccountGraphTitle onDesc={onDesc} />

      <div
        className=" f9GrayBorder position-relative d-flex f9GrayBorder flex-column mt-3 account--card-shadow bg-white rounded-account-cards gap-3 "
        style={{ height: "650px" }}
      >
        {loading ? (
          <SkeletonAccountGraph />
        ) : (
          <>
            <div
              className=" position-relative "
              style={{ minHeight: "310px" }}
              ref={ref}
            >
              <>
                {isGraphEmpty() && (
                  <div className=" p-4 d-flex s14">
                    <p className=" museo-sans-500">
                      <FormattedMessage id="graph.balance.subtitle" />
                    </p>
                    <p className=" invisible">a</p>
                    <p className=" museo-sans-700">
                      <ReformatLevelByGender
                        level={profileData.level}
                        gender={profileData.gender}
                        roleDescriptionDefault={profileData?.roleDescription}
                      />
                    </p>
                  </div>
                )}

                <div
                  style={{ maxHeight: "290px" }}
                  className=" position-relative "
                >
                  {isGraphEmpty() ? (
                    <>
                      {renderLineChart(
                        graphToggler.toggleOne,
                        periodReducer.reMetricGraph.metricPersonal,
                        "#8889DB",
                        1
                      )}
                      {renderLineChart(
                        graphToggler.toggleTwo,
                        periodReducer.reMetricGraph.metricInc,
                        "#D18DEF",
                        2
                      )}
                      {renderLineChart(
                        graphToggler.toggleThree,
                        periodReducer.reMetricGraph.metricGroup,
                        "#456ECE",
                        3
                      )}
                      {renderLineChart(
                        graphToggler.toggleFour,
                        periodReducer.reMetricGraph.metricDesc,
                        "#CED2FF",
                        4
                      )}
                    </>
                  ) : (
                    <div
                      style={{ height: height, top: "120px" }}
                      className=" position-relative text-center d-flex w-100 flex-column justify-content-end align-items-center "
                    >
                      <div
                        style={{ width: "62px", height: "62px" }}
                        className=" position-relative rounded-circle mb-2 d-flex justify-content-center align-items-center "
                      >
                        <div className=" rounded-circle  brightBlueBg opacity-25 w-100 h-100 position-absolute"></div>
                        <SVGEmptyChart />
                      </div>
                      <p
                        style={{ width: "330px" }}
                        className=" s14 museo-sans-500 "
                      >
                        <FormattedMessage id="graph.empty.p" />
                      </p>
                    </div>
                  )}
                </div>
              </>
            </div>

            {isGraphEmpty() && (
              <GraphList
                level={profileData?.level}
                graphToggler={graphToggler}
                periodReducer={periodReducer}
                onClickToggle={onClickToggle}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

export function AccountGraphTitle({ onDesc }) {
  return (
    <div className="d-flex museo-sans-500 s20">
      <p>
        <FormattedMessage id="graph.balance.title" />
      </p>
      {!onDesc && (
        <i
          data-bs-toggle="modal"
          data-bs-target="#modalHistory"
          className=" d-flex px-2 pointer"
        >
          <HelpIconNoTarget />
        </i>
      )}
      <div></div>
    </div>
  );
}

export function GraphList({
  level,
  graphToggler,
  periodReducer,
  onClickToggle,
}) {
  return level === "1" ? (
    <div className="px-4" style={{ minHeight: "138px" }}>
      <List
        graphToggler={graphToggler.toggleOne}
        text={"VENTA PERSONAL"}
        amount={
          periodReducer.metrics &&
          periodReducer.reMetricGraph.metricPersonal[
            periodReducer?.reMetricGraph?.metricId
          ].y
        }
        onClickHandler={onClickToggle}
        value={1}
      />
      <List
        graphToggler={graphToggler.toggleTwo}
        text={"VENTA POR INCORPORADAS"}
        amount={
          periodReducer.metrics &&
          periodReducer.reMetricGraph.metricInc[
            periodReducer?.reMetricGraph?.metricId
          ].y
        }
        onClickHandler={onClickToggle}
        value={2}
      />
      <hr className=" px-2 my-2"></hr>
      <div className=" px-3 d-flex justify-content-between">
        <p className=" museo-sans-500 s14">
          <FormattedMessage id="graph.balance.inc" />
        </p>
        <p className=" dm-sans-medium">
          {periodReducer.metrics &&
            periodReducer?.metrics?.metrics[
              periodReducer?.reMetricGraph?.metricId
            ]?.personalIncorporated}
        </p>
      </div>
    </div>
  ) : (
    <div className="px-4" style={{ minHeight: "285px" }}>
      <List
        graphToggler={graphToggler.toggleOne}
        text={"VENTA PERSONAL"}
        amount={
          periodReducer.metrics &&
          periodReducer.reMetricGraph.metricPersonal[
            periodReducer?.reMetricGraph?.metricId
          ]?.y
        }
        onClickHandler={onClickToggle}
        value={1}
      />
      <List
        graphToggler={graphToggler.toggleTwo}
        text={"VENTA POR INCORPORADAS"}
        amount={
          periodReducer.metrics &&
          periodReducer.reMetricGraph.metricInc[
            periodReducer?.reMetricGraph?.metricId
          ]?.y
        }
        onClickHandler={onClickToggle}
        value={2}
      />
      <List
        graphToggler={graphToggler.toggleThree}
        text={"VENTA GRUPAL"}
        amount={
          periodReducer.metrics &&
          periodReducer.reMetricGraph.metricGroup[
            periodReducer?.reMetricGraph?.metricId
          ]?.y
        }
        onClickHandler={onClickToggle}
        value={3}
      />
      <List
        graphToggler={graphToggler.toggleFour}
        text={"VENTA DESCENDENCIA"}
        amount={
          periodReducer.metrics &&
          periodReducer.reMetricGraph.metricDesc[
            periodReducer?.reMetricGraph?.metricId
          ]?.y
        }
        onClickHandler={onClickToggle}
        value={4}
      />
      <hr className=" my-3"></hr>
      <div className=" s14 museo-sans-500 px-3 d-flex justify-content-between">
        <p>
          <FormattedMessage id="graph.balance.lideres" />
        </p>
        <p>
          {periodReducer?.metrics?.metrics ? (
            <>
              {periodReducer.metrics &&
                periodReducer.metrics.metrics[
                  periodReducer?.reMetricGraph?.metricId
                ]?.qualifiedLeaders}
            </>
          ) : (
            null
          )}
        </p>
      </div>
      <hr className=" my-3" />
      <div className=" s14 museo-sans-500 px-3 d-flex justify-content-between">
        <p>
          <FormattedMessage id="graph.balance.inc" />
        </p>
        <p>
          {periodReducer?.metrics?.metrics ? (
            <>
              {periodReducer.metrics &&
                periodReducer.metrics.metrics[
                  periodReducer?.reMetricGraph?.metricId
                ]?.personalIncorporated}
            </>
          ) : (
            null
          )}
        </p>
      </div>
    </div>
  );
}

export function List({ graphToggler, text, amount, onClickHandler, value }) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  const switchBalls = () => {
    const classNames = [
      "labelOneBg rounded-circle",
      "labelTwoBg rounded-circle",
      "labelThreeBg rounded-circle",
      "labelFourBg rounded-circle",
    ];

    return (
      <p
        style={{ width: "10px", height: "10px" }}
        className={classNames[value - 1]}
      ></p>
    );
  };

  const restOfText = () => {
    return (
      <>
        {switchBalls()}
        <p className=" text-uppercase museo-sans-700 s12">{text}</p>
        <p className=" dm-sans-bold s14 " style={{ justifySelf: "flex-end" }}>
          {formatAmount(
            amount,
            cultureInfo?.languageCode,
            cultureInfo?.defaultCurrencyCode
          )}
        </p>
      </>
    );
  };

  return (
    <div
      onClick={graphToggler ? undefined : () => onClickHandler(value)}
      className={`pointer p-2 px-3 account-listing gap-3 ${
        graphToggler ? "account-listing-selected" : ""
      }`}
    >
      {restOfText()}
    </div>
  );
}
