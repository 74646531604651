import { createReducer } from "@reduxjs/toolkit";
import { fetchImportant, fetchEcommerce } from "./GoodPracticeSideEffect";


const initialState = {
    important: {},
    ecommerce: {},
    other: {},
    isLoading: false
}

const goodPracticeReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchImportant.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(fetchImportant.fulfilled, (state, action) => {
            state.important = action.payload;
            state.isLoading = false;
        })
        .addCase(fetchImportant.rejected, (state, action) => {
            state.isLoading = false;
        })

        .addCase(fetchEcommerce.pending, (state, action) => {
            state.isLoading = true;
        })
        .addCase(fetchEcommerce.fulfilled, (state, action) => {
            state.ecommerce = action.payload;
            state.isLoading = false;
        })
        .addCase(fetchEcommerce.rejected, (state, action) => {
            state.isLoading = false;
        })

        
})

export default goodPracticeReducer;