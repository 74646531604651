import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";

import { fetchAuthLogin } from "../../Framework/redux/loginSideEffects";

import {ReactComponent as EyeOpen} from "../../../common/svgs/password-eye-open.svg";
import {ReactComponent as EyeClosed} from "../../../common/svgs/password-eye-closed.svg";

import Loader from "../../../common/Share/Loader";

export function LoginPin() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const [number, setNumber] = useState({ value: "", hasError: false });
  const [password, setPassword] = useState({ value: "", hasError: false });
  const [typePassword, setTypePassword] = useState(true);
  const loading = useSelector((state) => state.userInfo.loader);
  const intl = useIntl();

  const onLogin = () => {
    if (number.value && number.value.length !== 4) {
      return true;
    } else {
      setNumber({ ...number, 
        hasError: number.value.trim() === "" ||  number.value.length !== 4 });
      return false;
    }
  };

  const resolveLogin = async (evt) => {
    evt.preventDefault();
    const success = onLogin();
    if (success) {
      const result = await dispatch(
        fetchAuthLogin({ number: number.value, password: password.value })
      );
      if (typeof result.payload === "object") {
        history("/phone");
      }
    }
  };

  const changeNumber = (evt) => {
    setNumber({
      value: evt.target.value,
      hasError: evt.target.value.trim() === "",
    });
  };

  const toggleType = () => {
    setTypePassword(!typePassword);
  };

  return (
    <div id="login-start" className=" container--padding py-5" >
      <div className=" text-center">
        <h1 className=" s40 museo-sans-700">
          <FormattedMessage id="login.welcome" defaultMessage="Welcome!" />
        </h1>
        <h2 className="s16 museo-sans-500">
          <FormattedMessage
            id="login.comment"
            defaultMessage="Sign in to your Just account"
          />
        </h2>
      </div>
      <form id="login-form" className="p-3">
        <div className="form-group">
            
          <label className="w600 s14">
            <FormattedMessage
              id="login.label.three"
              defaultMessage="PIN"
            />
          </label>
          {typePassword ? (
            <div className="input-container">
              <input
            onChange={changeNumber}
            type="password"
            id="account"
            maxLength={4}
            className="form-control my-2"
            placeholder={intl.formatMessage({
              id: "login.placeholder.label.pin.one",
              defaultMessage: "PIN",
            })}
          ></input>
              <i onClick={toggleType} className="">
                <EyeOpen/>
              </i>
            </div>
          ) : (
            <div className="input-container">
              <input
            onChange={changeNumber}
            type="text"
            maxLength={4}
            id="account"
            className="form-control my-2"
            placeholder={intl.formatMessage({
              id: "login.placeholder.label.pin.one",
              defaultMessage: "PIN",
            })}
          ></input>
              <i onClick={toggleType} className="">
                <EyeClosed/>
              </i>
            </div>
          )}
          {!number.hasError ? (
            <small className="text-danger invisible ">
              {" "}
              <FormattedMessage
                id="login.error.one"
                defaultMessage="This input can't be empty"
              />{" "}
            </small>
          ) : (
            <small className="text-danger ">
              {" "}
              <FormattedMessage
                id="login.error.one"
                defaultMessage="This input can't be empty"
              />{" "}
            </small>
          )}
        </div>
        <div id="login--btn-holder" className="text-center">
          <button
            id="login--continue"
            className="position-relative mb-3 w-100 p-2 text-white btnCustom skyBg"
            onClick={resolveLogin}
          >
            <Loader loading={loading} />{" "}
            <FormattedMessage id="login.button" defaultMessage="Login" />{" "}
          </button>
          <Link className="w600" to={"/pin/recovery"}>
            <FormattedMessage
              id="login.pin.recovery"
              defaultMessage="Forgot your password?"
            />
          </Link>
        </div>
      </form>
    </div>
  );
}
