import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGCross } from "../../../../svgs/ic-x-lg.svg";

import { ReactComponent as SVGTriangle } from "../../../../svgs/ic-triangle-warning-lg.svg";


export default function HeaderModalPrevEDB({ link }) {

  
  return (
        <div
          className="modal fade"
          id="modalEDB"
          aria-hidden="true"
          aria-labelledby="modalEDB"
          tabIndex="-1"
        >
         
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ maxWidth: "456px" }}
          >
            <div className="modal-content modal-radius">
              <div className="modal-body p-0">
                <div>
                  <div className="d-flex justify-content-between m-3 pb-4">
                    <div className="d-flex">
                      <h5 className=" invisible museo-sans-500 s20 m-0">
                        <FormattedMessage id="social.sharedata.title" />
                      </h5>
                    </div>
                    <div className="pointer" data-bs-dismiss="modal" aria-label="Close">
                        <SVGCross />
                    </div>
                  </div>
                  <div className=" d-flex flex-column align-items-center " >
                    <div style={{width: "92px", height: "92px"}}  className=" d-flex justify-content-center align-items-center goldBg20 rounded-circle py-4  " >
                        <div style={{width: "42px"}} className=" svg-force-size" >
                            <SVGTriangle/>
                        </div>
                    </div>
                    <p className=" mt-3 museo-sans-700 s24 " > 
                        <FormattedMessage id="good.practice.title.one" />
                    </p>
                    <p className=" mt-2 grayColor text-center w-75 museo-sans-500 s14" >
                        <FormattedMessage id="header.modal.edb.p" />
                    </p>
                  </div>
                  <div className=" mt-2 py-4 museo-sans-500 s14 d-flex flex-column align-items-center gap-2 " >
                    <a target="_blank" className=" " style={{justifySelf: "center"}} href={link}>
                    <button style={{width: "288px", height: "40px", borderRadius: "8px"}} className=" border-0 skyBg whiteColor " data-bs-dismiss="modal" aria-label="Close"  >
                        <FormattedMessage id="buttons.understood"/>
                    </button>
                    </a>
                    <button data-bs-dismiss="modal" aria-label="Close" style={{width: "288px", height: "40px", borderRadius: "8px"}} className=" border-1 skyBorder bg-transparent skyColor "  >
                        <FormattedMessage id="buttons.cancel"/>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
  );
}
