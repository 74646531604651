import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";

import ReturnToAndTitle from "../../../Share/ReturnToAndTitle";
import ShareComponentInput from "../../../Share/ShareComponentInput";
import { postPersonalDataValidationPhoneNumber } from "../../Framework/ConfigurationSideEffect";
import SharePhoneLibrary from "../../../Share/SharePhoneLibrary";
import { ReactComponent as SVGRedCross } from "../../../../EDBs/Presentation/img/redcross.svg";
import SharePlaceholderPhone from "../../../Share/SharePlaceholderPhone";
import SharePhoneMaxLengthByCountry from "../../../Share/SharePhoneMaxLengthByCountry";

export default function UserDataChangePhoneNumber({ userData, setSavedValues, travelFunction }) {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState({ value: "", valid: true, errorMessage: "" });

  const country = localStorage.getItem("country");
  
  const numbers = {
    ar: "+549",
    cl: "+56",
    co: "+57",
    cr: "+506",
    mx: "+52", // +52 Mex
    pa: "+507",
    pe: "+51",
    uy: "+598",
  };

  const onChange = (name, falseValue, value) => {
    setPhone({ value: value, valid: true, errorMessage: "" });
  };

  const onErrorValid = (errorMessage) => {
    let aux = { ...phone };
    aux.valid = false;
    aux.errorMessage = errorMessage;
    setPhone(aux);
  };

  const onContinue = async () => {
    if (phone.value.length === 0) {
      return;
    };

    let isFailedValidation = ""

    if (phone.value.length > 10) {
      isFailedValidation = true;
    } else {
      isFailedValidation = await SharePhoneLibrary(phone.value);
    }

    if (!isFailedValidation) {
      let auxObject = {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        phone: `${numbers[country]}${phone.value}`,
      };
      let result = await dispatch(postPersonalDataValidationPhoneNumber(auxObject))
      if (result?.payload?.code == 923 || result?.payload?.authenticatorToken == null) {
        onErrorValid(result?.payload?.message);
        return;
      }
      setSavedValues({ ...auxObject, token: result?.payload?.authenticatorToken });
      travelFunction(1.5);
      
    } else {
      setPhone({ value: phone.value, valid: false });
      onErrorValid(<FormattedMessage id="client.form.validation.phone" />)
    }
    
  };

  return (
    <div className=" d-flex flex-column gap-4">
      <ReturnToAndTitle
        travelFunction={travelFunction}
        returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
      />
      <div className="d-flex flex-column gap-2 museo-sans-700 ">
        <p
          style={{ letterSpacing: "0.24px" }}
          className=" museo-sans-500 s12 text-uppercase grayColor"
        >
          <FormattedMessage id="settings.title.change.phone" />
        </p>
        <p className=" s24">
          <FormattedMessage id="settings.title.change.phone.subtitle.one" />
        </p>
      </div>
      <div style={{ width: "308px" }}>
        <ShareComponentInput
          name={"contactEdbPhone"}
          label={<FormattedMessage id="edb.form.contact.phone.optional" />}
          ignoreTitle={true}
          type={"number"}
          maxLength={SharePhoneMaxLengthByCountry()}
          value={phone?.value}
          placeholder={SharePlaceholderPhone()}
          errorToggler={false}
          onChange={onChange}
          doNotDisplayTitle={true}
        />
        {!phone.valid && (
          <div className=" mt-1 d-flex align-items-center gap-1">
            {" "}
            <div style={{width: "12px"}} className=" svg-force-size" >
            <SVGRedCross />{" "}
              </div>
            <p style={{top: 2}} className=" position-relative museo-sans-500 s12 lightRedColor ">
              {" "}
              {
                phone?.errorMessage || <FormattedMessage id="settings.error.format" />
              }{" "}
            </p>{" "}
          </div>
        )}
      </div>
      <button
        onClick={onContinue}
        style={{ width: "308px", height: "40px" }}
        className={` mt-4 museo-sans-700 border-0 ${
          phone.value ? "skyBg" : "brightGrayBg"
        } rounded-2 text-white`}
      >
        <FormattedMessage id="buttons.continue" />
      </button>
    </div>
  );
}
