import { FormattedMessage } from "react-intl";

export default function DistributionAgreementContentCR(params) {
    return (
        <>
          <p className={` museo-sans-500 s16 mb-4 ${!params.showTitle && "mt-4"}`}>
            <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.contract.parts.2" />
          </p>
          <div>
            <p className="mb-2 museo-sans-700 s16">
              <b>
                <FormattedMessage id="distribution.agreement.text.contract.parts.3" />
              </b>
            </p>
          </div>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.1" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.2" />
          </p>

          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.solicitant.1" />
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.2" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.solicitant.3" />
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.4" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.5" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
          </p>
          <p className=" mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
            </b>
          </p>
          <p className="museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
          </p>
          <p className="museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.14" />
            </b>
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.15" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.16" />
            </b>
          </p>
          <p className="museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.17" />
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.18" />
            <a href="https://www.swissjustcostarica.net/new/clients_mobile/sjm_login.asp">
              https://www.swissjustcostarica.net/new/clients_mobile/sjm_login.asp
            </a>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.19" />
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.20" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.21" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.22" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.agreement.text.declarations.clauses.23" />
            </b>
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.24" />
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.25" />
          </p>
          <p className=" museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.26" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            Una vez facturado el pedido no existen cancelaciones parciales ni totales.
          </p>
          <p className="invisible mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="ethics.code.text.immigration.laws.1" />
              </u>
            </b>
            <FormattedMessage id="ethics.code.text.immigration.laws.1" />
          </p>
        </>
      );
}