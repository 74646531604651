import Select,{ components, createFilter } from 'react-select';
import { ReactComponent as SVGChevArrowDown } from "../svgs/ic-chevron-down-md.svg";
import { ReactComponent as SVGChevArrowUp } from "../svgs/ic-chevron-up-md.svg";
import {clientSelectAddressStyles} from './CustomSelectStyles'

const DropdownIndicator = ({ selectProps,...props }) => { 
  return (
    <components.DropdownIndicator {...props}>
      {selectProps.menuIsOpen?<SVGChevArrowUp /> : <SVGChevArrowDown />}
    </components.DropdownIndicator>
  );
};

export default function CustomSelect({customStyles,width,height,...field}) {
  const globalStyles = customStyles || clientSelectAddressStyles;
  const filterConfig = {
    ignoreCase:true,
    ignoreAccents:true,
    trim:true,
    matchFrom: 'start'
  };

  return (
    <Select
      unstyled
      isSearchable
      {...field}
      filterOption={createFilter(filterConfig)}
      noOptionsMessage={()=>"No hay resultados"}
      components={{ DropdownIndicator }}
      styles={{...globalStyles,
        container: (style) => ({
          ...style,
          width: width || 'max-content',
          height: height || '40px'
        }),
      }}
      className='s14 rounded-2 input-border bg-white'
      classNames={{
          control: (state) =>
            state.isDisabled && 'brightGrayBgOpacity20 custom-select-placeholder s14',
          option: (state) => 
            state.isFocused && 'text-onhover-bold museo-sans-700',
          dropdownIndicator:(state) => 
              state.isDisabled && 'svg-force-path-stroke-grey s14',
          menuList:() => 'remove-scrollbar'
        }
      }
    />
  )    
}