import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EyeOpen } from "../../../common/svgs/password-eye-open.svg";
import { ReactComponent as EyeClosed } from "../../../common/svgs/password-eye-closed.svg";
import Loader from "../../../common/Share/Loader";
import {
  resolveChangePassword,
} from "../../Framework/redux/loginSideEffects";
import swal from 'sweetalert2';
import { multiLangJSONParse } from "../../../common/Share/MultiLangJSONParse";
import SharePasswordIsError from "../../../common/Share/SharePasswordValidation";

export default function LoginPassRecNew() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const loading = useSelector((state) => state.userInfo.loader);
  const [password, setPassword] = useState({ value: "", hasError: false });
  const [comparePass, setComparePass] = useState({
    value: "",
    hasError: false,
  });
  const [typePassword, setTypePassword] = useState(true);
  const [wrongParams, setWrongParams] = useState(false);
  const [passwordError, setPasswordError] = useState({ isError: false, messageId: ""});

  const onSubmit = (evt) => {
    evt.preventDefault();

    let validatePasswordOne = SharePasswordIsError(password.value, "", 1);
    let validatePasswordThree = SharePasswordIsError(password.value, "", 2);

    setPassword({
      value: password.value,
      hasError: password.value.trim() === "",
    });

    if(validatePasswordOne.isError){
      setPasswordError({ isError: true, messageId: validatePasswordOne.messageId });
      return;
    };

    if (validatePasswordThree.isError) {
      setPasswordError({ isError: true, messageId: validatePasswordThree.messageId });
      return;
      
    };

    setPassword({
      value: "",
      hasError: password.value.trim() === "",
    });

    
    comparePass.value === ""
    ? setComparePass({ value: password.value, hasError: false })
    : comparePass.value === password.value
    ? resolveSubmit()
    : setComparePass({ value: "", hasError: true });
  };
  
  const resolveSubmit = async () => {
    const result = await dispatch(resolveChangePassword(comparePass.value));
    if (!result?.type?.includes("fulfilled")) {
      if (result?.payload.indexOf("400") !== -1 || result?.payload.indexOf("401") !== -1) {
        swal.fire({
          title: multiLangJSONParse()["swin.error.not.handled.by.service.title"],
        icon: 'error',
        text: multiLangJSONParse()["swin.error.not.handled.by.service.text"],
          allowOutsideClick: false,
          confirmButtonText: multiLangJSONParse()["buttons.continue"]
        }).then(
          () => {
            window.location = '/recovery/pin';
          }
        );
      }
      return;
    }
    
    localStorage.removeItem("token");
    history("/");
  };

  const onReturn = (evt) => {
    evt.preventDefault();
    history("/recovery/pin");
  };

  const changePassword = (evt) => {
    evt.preventDefault();
    setPasswordError({ isError: false, messageId: "" });
    if (password.value === "") {
      setComparePass({ ...comparePass, hasError: false });
    }
    setPassword({
      value: evt.target.value,
      hasError: evt.target.value.trim() === "",
    });
  };

  const toggleType = () => {
    setTypePassword(!typePassword);
  };

  const controlErrors = () => {
    
    if (passwordError?.isError) {
      return(
        <p className="s12 museo-sans-500 text-danger d-flex gap-1 mt-1" >
          <i className="text-danger bi bi-x-circle-fill"></i>
          <FormattedMessage id={passwordError?.messageId} />
        </p>
      )
    }

    return(
          <div>
            {!comparePass.hasError ? (
              <p className=" text-danger invisible ">
                {" "}
                <FormattedMessage
                  id="login.recovery.page.error.three"
                  defaultMessage="This input can't be empty or shorter than four digits"
                />{" "}
              </p>
            ) : (
              <div className=" d-flex align-items-center gap-1 " >
                <i className="text-danger bi bi-x-circle-fill"></i>
                <p className=" s12 museo-sans-500 text-danger ">
                  {" "}
                  <FormattedMessage
                    id="login.recovery.page.error.three"
                    defaultMessage="This input can't be empty or shorter than four digits"
                  />{" "}
                </p>
              </div>
            )}
          </div>
    )
  };

  return (
    <div id="login-layout" className=" container--padding py-5">
      <div className="">
        <h1 className="mt-3 grayColor s12 museo-sans-500  text-uppercase">
          <FormattedMessage
            id="login.recovery.page.title.two"
            defaultMessage="1. Validate identity"
          />
        </h1>
        <hr></hr>
        {comparePass.value === "" ? (
          <>
            <h2 className="s24 museo-sans-700">
              <FormattedMessage
                id="login.recovery.page.subtitle.two"
                defaultMessage="Forgot password?"
              />
            </h2>
            <p className="s16  museo-sans-500 w-75">
              <FormattedMessage
                id="login.recovery.page.desc.two"
                defaultMessage="Estás por comenzar el proceso de recuperación de tu contraseña. Te enviaremos un código de verificación al email que tenemos registrado en tus datos del sistema actual. "
              />
            </p>
          </>
        ) : (
          <>
            <h2 className=" museo-sans-700 s24">
              <FormattedMessage
                id="login.recovery.page.subtitle.three"
                defaultMessage="Forgot password?"
              />
            </h2>
            <p className="s16 museo-sans-500 w-75">
              <FormattedMessage
                id="login.recovery.page.desc.three"
                defaultMessage="Estás por comenzar el proceso de recuperación de tu contraseña. Te enviaremos un código de verificación al email que tenemos registrado en tus datos del sistema actual. "
              />
            </p>
          </>
        )}

        <div className="form-group mt-4 s14 museo-sans-500 "> 
        <div style={{ width: "393px", height: "40px" }} className="input-container">
              <input
                onChange={changePassword}
                className="form-control input-borders my-1 "
                id="password"
                placeholder={intl.formatMessage({
                  id: "login.placeholder.label.two",
                  defaultMessage: "Example: 24536",
                })}
                value={password.value}
                type={typePassword ? "password" : "text" }
                maxLength={10}
              ></input>
              <i onClick={toggleType} className="">
                {
                  typePassword ? <EyeOpen /> : <EyeClosed />
                }
              </i>
            </div>
          {
            controlErrors()
          }
        </div>
      </div>
      <div className="align-self-end">
        {!wrongParams ? (
          <>
          {
            <>
              {
                password.value.trim() === "" ?
                <button
              className=" disabledCursor brightGrayBg position-relative btnLoginWidth s14 museo-sans-700 py-2 text-white btnCustom "
            >
              <Loader loading={loading} />
              <FormattedMessage
                id="buttons.continue"
                defaultMessage="Recover password"
              />
            </button>
            :
            <button
              className=" skyBg position-relative btnLoginWidth s14 museo-sans-700 py-2 text-white btnCustom "
              onClick={onSubmit}
            >
              <Loader loading={loading} />
              <FormattedMessage
                id="buttons.continue"
                defaultMessage="Recover password"
              />
            </button>
              }
            </>
          }
          
          </>
        ) : (
          <button
            className=" position-relative btnLoginWidth s14 museo-sans-700 py-2 text-white btnCustom skyBg"
            onClick={onReturn}
          >
            <FormattedMessage
              id="buttons.back"
              defaultMessage="Recover password"
            />
          </button>
        )}
      </div>
    </div>
  );
}
