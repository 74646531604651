import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";


import {
  fetchAccountStatusDocumentSummaryById,
  fetchAccountStatusDocumentSummaryDescById,
} from "../../Framework/redux/StatusAccountSideEffect";
import StatusAccountDetailsClientInfo from "../components/StatusAccountDetailsClientInfo";
import StatusAccountDetailsBillInfo from "../components/StatusAccountDetailsBillInfo";

export default function StatusAccountDetails() {
 
  const dataSummaryByIdData = useSelector(
    (state) => state?.statusAccountData.dataSummaryById
  );
  const dispatch = useDispatch();
  const params = useParams();

  const callService = () => {
    if (params.descId != 0) {
      dispatch(fetchAccountStatusDocumentSummaryDescById(params));
    } else {
      dispatch(fetchAccountStatusDocumentSummaryById(params?.id));
    }
  };

  useEffect(() => {
    callService();
  }, []);



  return (
      <div className=" container--padding  bg-white">
        <div className="container--padding--card-holder  ">
          <div className="d-flex flex-column justify-content-center  " >
            <StatusAccountDetailsBillInfo  dataSummaryByIdData={dataSummaryByIdData} />   
            <StatusAccountDetailsClientInfo dataSummaryByIdData={dataSummaryByIdData} />
          </div>
        </div>
      </div>
  );
}
