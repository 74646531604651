import { FormattedMessage } from "react-intl";

export default function ConfigurationReport(params) {

    const onClick = () => {
        const clickMe = document.getElementById("emailTarget");
        clickMe.click();
    };

    let country = localStorage.getItem("countryLong");
    country = country[0].toUpperCase() + country.slice(1);
    
    return(
        <div className="d-flex flex-column px-4 mx-3 pt-4 gap-4 " >
            <p className=" museo-sans-700 s24" >
                <FormattedMessage id="settings.report.page.title" />
            </p>
            <div className="d-flex flex-column gap-4 museo-sans-500 s14" >
                <div className="" >
                    <p>
                        <FormattedMessage id="settings.report.page.p.sub.one" />
                    <spam onClick={onClick} className=" text-decoration-underline pointer skyColor" >
                        oficinavirtual@swissjust.com
                    </spam>
                    <FormattedMessage id="settings.report.page.p.sub.two" />
                    </p>
                    <p>
                    </p>
                </div>
                <div>
                    <p>
                        <FormattedMessage id="settings.report.page.p.two" />
                    </p>
                    <p>
                        <FormattedMessage id="settings.report.page.p.three" />
                    </p>
                </div>
            </div>
            <div onClick={onClick} style={{width: "288px", height: "40px"}} className=" d-flex justify-content-center align-items-center rounded-3 pointer museo-sans-500 s14 text-white skyBg" >  
                <FormattedMessage id="settings.report.button" />
            </div>
            <a id="emailTarget" href={`mailto:oficinavirtual@swissjust.com?subject=Consulta&body=Clave%20de%20Consultor%3A%20${localStorage.getItem("clientId")}%0A%0APa%C3%ADs%3A%20${country}`} target={"_blank"} className=" invisible" >
                values
            </a>
        </div>
    )
}