import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { boolean } from "yup";
import Radius from "../../../common/Share/Radius";

export default function InvitationsListOrder({
  onSetNewDir,
  onSetNewOrder,
  onChange,
  loading,
}) {
  const [toggler, setToggler] = useState(false);

  const [dir, setDir] = useState(true);
  const [type, setType] = useState(true);

  const onChangeDir = (boolean) => {
    setDir(boolean);
    onSetNewDir(boolean ? "desc" : "asc");
  };

  const onChangeType = (boolean) => {
    setType(boolean);
    onSetNewOrder(boolean ? "inviteDate" : "incProgress");
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{ width: "204px", height: "40px" }}
            className="lightui1-shimmer rounded-3"
          >
            <p className=" invisible">.</p>
          </div>
        </>
      ) : (
        <>
          <div
            onClick={() => setToggler(!toggler)}
            className=" pointer bg-white position-relative whiteBg rounded-3 gap-2 skyBorder justify-content-center align-items-center d-flex s16 museo-sans-500  "
            style={{ width: "204px", height: "40px" }}
          >
            <p className="skyColor"><FormattedMessage id="qualified.groups.order.by" /></p>
            <div
              style={{ width: "16px", height: "16px", top: -4 }}
              className=" position-relative svg-force-size"
            >
              {toggler ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.86787 5.7741C8.00049 5.7741 8.12768 5.82678 8.22145 5.92056L12.6944 10.3939C12.8896 10.5892 12.8896 10.9058 12.6944 11.101C12.4991 11.2963 12.1825 11.2963 11.9872 11.101L7.86788 6.98124L3.73266 11.117C3.53741 11.3122 3.22083 11.3123 3.02555 11.117C2.83028 10.9218 2.83026 10.6052 3.02551 10.4099L7.5143 5.92056C7.60807 5.82678 7.73525 5.7741 7.86787 5.7741Z"
                      fill="#171A22"
                      stroke="#5AAFF1"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.73564 11.2259C8.60303 11.2259 8.47584 11.1732 8.38207 11.0794L3.90913 6.60609C3.71387 6.41082 3.71389 6.09424 3.90916 5.89899C4.10443 5.70373 4.42101 5.70375 4.61627 5.89902L8.73563 10.0188L12.8709 5.88304C13.0661 5.68777 13.3827 5.68775 13.578 5.883C13.7732 6.07825 13.7733 6.39483 13.578 6.5901L9.08921 11.0794C8.99544 11.1732 8.86826 11.2259 8.73564 11.2259Z"
                      fill="#171A22"
                      stroke="#5AAFF1"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                </>
              )}
              {toggler && (
                <div
                  style={{
                    borderRadius: "16px",
                    width: "437px",
                    height: "277px",
                    right: -44,
                    top: 40,
                    zIndex: 10,
                  }}
                  className="  py-2 border-3 bg-white position-absolute tabs-shadow "
                >
                  <div className="my-2 px-3">
                    <p className=" "><FormattedMessage id="qualified.groups.order.by" />:</p>
                    <div
                      onClick={() => onChangeType(false)}
                      className=" px-3 py-2 my-1 d-flex pointer gap-2"
                    >
                      <Radius status={!type} />
                      <span className={`${!type ? " s14" : "grayColor s14"}`}>
                      <FormattedMessage id="invitation.list.order.type.one" />
                      </span>
                    </div>
                    <div
                      onClick={() => onChangeType(true)}
                      className=" px-3 py-2 my-1 d-flex pointer gap-2"
                    >
                      <Radius status={type} />
                      <span className={`${type ? " s14" : "grayColor s14"}`}>
                        <FormattedMessage id="invitation.list.order.type.two" />
                      </span>
                    </div>
                  </div>

                  <hr className=" m-0" />
                  <div className="my-2 px-3">
                    <p><FormattedMessage id="invitation.list.order.order.by" />:</p>
                    <div
                      onClick={() => onChangeDir(false)}
                      className=" px-3 py-2 my-1 d-flex pointer gap-2"
                    >
                      <Radius status={!dir} />
                      <span className={`${!dir ? " s14" : "grayColor s14"}`}>
                        <FormattedMessage id="invitation.list.order.asc" />
                      </span>
                    </div>
                    <div
                      onClick={() => onChangeDir(true)}
                      className=" px-3 py-2 my-1 d-flex pointer gap-2"
                    >
                      <Radius status={dir} />
                      <span className={`${dir ? " s14" : "grayColor s14"}`}>
                      <FormattedMessage id="invitation.list.order.desc" />
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
