import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";
import ModalWhiteShadow from "../../../../common/Share/ModalWhiteShadow";

export default function DashboardPeriodSalesModal() {

  const scrollbarRef = useRef(null);
    
  return (
    <div
      className="modal fade"
      id="modalPeriodSales"
      aria-hidden="true"
      aria-labelledby="modalPeriodSales"
      tabIndex="-1"
    >
      <div
        className="modal-dialog modal-dialog-centered"
        style={{ maxWidth: "610px" }}
      >
        <div className="modal-content modal-radius">
          <div className=" modal-body p-0">
            <div>
              <div className="d-flex align-items-center justify-content-between my-3 py-4 px-5">
                <div className="d-flex">
                  <HelpIconNoTarget />
                  <h5 className="museo-sans-500 px-2 s20 m-0">
                    <FormattedMessage
                      id="dashboard.modal.period.title"
                      defaultMessage="Buenas Prácticas"
                    />
                  </h5>
                </div>
                <div className=" pointer " data-bs-dismiss="modal"
                  aria-label="Close">
                    <SVGClose />
                </div>
              </div>
              <hr className="m-0"></hr>
              <div ref={scrollbarRef} style={{ maxHeight: "372px" }} className=" hidden-y-scroll d-flex flex-column align-items-center">
                <div className="mt-4 px-5">
                  <p className="s16 py-2 museo-sans-500">
                    <span className=" museo-sans-700"> <FormattedMessage id="dashboard.modal.important" /> :</span> { " " }
                    <FormattedMessage id="dashboard.modal.period.p.one" />
                  </p>
                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage id="dashboard.modal.period.p.two" />
                  </p>
                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage id="dashboard.modal.period.p.three" />
                  </p>
                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage id="dashboard.modal.period.p.four" />
                  </p>

                  <p className="s16 py-2 museo-sans-500">
                  <FormattedMessage id="dashboard.modal.period.p.five" />
                  </p>
                </div>
              </div>
              <ModalWhiteShadow left={"5%"} bottom={"95px"} width={"90%"} scrollbarRef={scrollbarRef} />
              <div style={{zIndex: 2}} className=" position-relative w-100 d-flex justify-content-center">
                <button
                  type="button"
                  className=" btnLoginWidth mt-4 my-5 museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <FormattedMessage
                    id="buttons.understood"
                    defaultMessage="Entendido"
                  />
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
