export const getCountriesTimeZone = (countryCode) => {

    switch (countryCode) {

        case 'ar':

            return 'America/Argentina/Buenos_Aires';

        case 'cl':

            return 'America/Santiago';

        case 'co':

            return 'America/Bogota';

        case 'cr':

            return 'America/Costa_Rica';

        case 'mx':

            return 'America/Mexico_City';

        case 'pa':

            return 'America/Panama';

        case 'pe':

            return 'America/Lima';

        case 'uy':

            return 'America/Montevideo';

        default:

            return '';

    }

};