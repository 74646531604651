import axios from "axios";
import swal from "sweetalert2";
import { multiLangJSONParse } from "../Share/MultiLangJSONParse";
import NetSrvRefresh from "./NetSrvRefresh";

const customNetServAxios = (
  contentType,
  ignoreToken,
  arrayStatus,
  avoid401,
  noScreen,
  fromLocationX
) => {
  const NetSrv = axios.create({
    baseURL: ignoreToken
      ? process.env.REACT_APP_API_URL_CMS
      : process.env.REACT_APP_API_URL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      "Content-Type": contentType
        ? contentType
        : "application/x-www-form-urlencoded",
    },
  });

  let isRefreshing = false;

  const onErrorHandler = () => {
    if (
      window.location.pathname == "/dashboard" ||
      window.location.pathname == "/directora/logging"
    ) {
      return false;
    } else {
      return true;
    }
  };

  const foundStatusError = (status) => {
    if (!arrayStatus) {
      return false;
    }
    let found = arrayStatus.find((item) => item === status);
    if (found) {
      return true;
    }
    return false;
  };

  const returnText = (text) => {
    const country = localStorage.getItem("country");

    let atencionCliente = "";

    if (country == "ar") {
      atencionCliente = `<div style="display: flex; flex-direction: column; gap: 4px; margin: 8px 0px 0px 0px;" > <p><a style="color: #5AAFF1;" target="_blank" href="mailto:atencionalcliente@justargentina.com" > atencionalcliente@justargentina.com </a> </div> </div>`;
    }
    if (country == "pe") {
      atencionCliente = `<div style="display: flex; flex-direction: column; gap: 4px; margin: 8px 0px 0px 0px;" > <p> Tel: 905470243</p> <p><a style="color: #5AAFF1;" target="_blank" href="mailto:servicioalcliente@justperu.com" > servicioalcliente@justperu.com </a>  </div> </div>`;
    }
    if (country == "uy") {
      atencionCliente = `<div style="display: flex; flex-direction: column; gap: 4px; margin: 8px 0px 0px 0px;" > <p> Tel: 094540734  </p> <p><a style="color: #5AAFF1;" target="_blank" href="mailto:atencionalcliente@justuruguay.com" > atencionalcliente@justuruguay.com </a> </div> </div>`;
    }
    if (country == "cl") {
      atencionCliente = `<div style="display: flex; flex-direction: column; gap: 4px; margin: 8px 0px 0px 0px;" > <p>Chat online con Asistente Virtual Just: <a style="color: #5AAFF1;" target="_blank" href="https://www.swissjustchile.cl/herramientas/" > https://www.swissjustchile.cl/herramientas/ </a> </div> </div>`;
    }

    let htmlMessage = `<div className="d-flex flex-column gap-1" ><p>${text}</p> ${atencionCliente} </div>`;

    return htmlMessage;
  };

  NetSrv.interceptors.request.use(
    async (config) => {
      if (ignoreToken) {
        return config;
      }

      if (!localStorage.getItem("token")) {
        return config;
      }
      const token = localStorage.getItem("token");
      config.headers.Authorization = token;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  NetSrv.interceptors.response.use(
    (response) => {
      if (response.info) {
        response = undefined;
      } else {
        response = response.data;
      }
      return response;
    },
    async (error) => {
      if (!localStorage.getItem("token")) {
        return error.response.data.code;
      }
      const {
        response: { status },
      } = error;

      if (!ignoreToken) {
        if (error.response.data?.code == "505") {
          return "error";
        }

        if (foundStatusError(status)) {
          if (noScreen) {
            return error.response;
          }
          if (
            window.location.pathname == "/dashboard" ||
            window.location.pathname == "/" ||
            window.location.pathname == "/login"
          ) {

            if (error?.response?.data?.code == 918) {
              swal
              .fire({
                title: multiLangJSONParse()["netserver.error.title"],
                icon: "error",
                html: `${returnText(error?.response.data.message)}`,
                allowOutsideClick: false,
                confirmButtonText: multiLangJSONParse()["buttons.continue"],
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // window.history.back();
                } else {
                  // window.location.reload();
                }
              });
            return error?.response;
            } else {
              swal
              .fire({
                title: multiLangJSONParse()["netserver.error.title"],
                icon: "error",
                text: error?.response.data.message,
                allowOutsideClick: false,
                confirmButtonText: multiLangJSONParse()["buttons.continue"],
              })
              .then((result) => {
                if (result.isConfirmed) {
                  // window.history.back();
                } else {
                  // window.location.reload();
                }
              });
            return error?.response;
            }
            
          } else {
            swal
              .fire({
                title: multiLangJSONParse()["netserver.error.title"],
                icon: "error",
                text: error?.response.data.message,
                allowOutsideClick: false,
                showCancelButton: true,
                cancelButtonText: multiLangJSONParse()["buttons.continue"],
                confirmButtonText: multiLangJSONParse()["buttons.back"],
              })
              .then((result) => {
                if (result.isConfirmed) {
                  window.history.back();
                } else {
                  // window.location.reload();
                }
              });
            return error?.response;
          }
        }

        if (!avoid401 && status === 401 && localStorage.getItem("refresh")) {
          if (error.response.data.code == "102") {
            return;
          }

          if (!isRefreshing) {
            isRefreshing = true;

            const location = localStorage.getItem("country");
            let urlencoded = new URLSearchParams();
            urlencoded.append("type", "refresh");
            urlencoded.append("notificationId", "ddffgg");
            try {
              const response = await NetSrvRefresh.post(
                `${location}/security/auth/token`,
                urlencoded
              );

              localStorage.setItem("token", `Bearer ${response.accessToken}`);
              localStorage.setItem(
                "refresh",
                `Bearer ${response.refreshToken}`
              );
              localStorage.setItem(
                "token_time",
                new Date().getTime() + response.accessTokenExpires + 3000
              );
              window.location.reload();
              return "";
            } catch (error) {
              console.log("Error: ", error);
            }
          } else {
            return "";
          }
        }

        if (error.isAxiosError) {
          if (
            error.message === "timeout of 10000ms exceeded" ||
            error.message === "Network Error" ||
            error.response ===
              undefined /*|| error.response.status !== 499 || error.response.status !== 444*/
          ) {
            if (onErrorHandler() && !ignoreToken) {
              swal
                .fire({
                  title: multiLangJSONParse()["netserver.time.title"],
                  text: multiLangJSONParse()["netserver.26&19.errMsg"],
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: multiLangJSONParse()["buttons.restart.net"],

                  confirmButtonText: multiLangJSONParse()["buttons.back"],
                })
                .then((result) => {
                  if (result.isConfirmed) {
                    window.history.back();
                  } else {
                    window.location.reload();
                  }
                });
            }
          } else {
            let errMsg;
            if (
              error.response &&
              error.response.data.code != 909 &&
              error.response.data.code != 910
            ) {
              if (
                error.response.status === 500 ||
                error.response.status === 400 ||
                error.response.status === 401 ||
                error.response.status === 404 ||
                error.response.status === 408 ||
                error.response.status === 26 ||
                error.response.status === 19
              ) {
                if (error.response.status === 0) {
                  errMsg = multiLangJSONParse()["netserver.0&403.errMsg"];
                }
                if (
                  error.response.status === 26 ||
                  error.response.status === 19
                ) {
                  errMsg = `${multiLangJSONParse()["netserver.26&19.errMsg"]}`;
                }
                if (error.response.status === 401) {
                  errMsg = `${multiLangJSONParse()["netserver.401.errMsg"]}`;
                }
                if (error.response.status === 404) {
                  errMsg = `${multiLangJSONParse()["netserver.404.errMsg"]}`;
                }
                if (error.response.status === 408) {
                  errMsg = `${multiLangJSONParse()["netserver.408.errMsg"]} `;
                }
                if (error.response.status === 400) {
                  errMsg = `${multiLangJSONParse()["netserver.400.errMsg"]} `;
                }
                if (error.response.status === 500) {
                  errMsg = `${multiLangJSONParse()["netserver.0&403.errMsg"]} `;
                }
              } else {
                errMsg = `${multiLangJSONParse()["netserver.0&403.errMsg"]} `;
              }
            }

            if (onErrorHandler()) {
              if (
                window.location.pathname == "/dashboard" ||
                window.location.pathname == "/" ||
                window.location.pathname == "/login"
              ) {
                swal
                  .fire({
                    title: multiLangJSONParse()["netserver.error.title"],
                    icon: "error",
                    text: multiLangJSONParse()["netserver.26&19.errMsg"],
                    allowOutsideClick: false,
                    confirmButtonText: multiLangJSONParse()["buttons.continue"],
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      // window.history.back();
                    } else {
                      // window.location.reload();
                    }
                  });
              } else {
                swal
                  .fire({
                    title: multiLangJSONParse()["netserver.error.title"],
                    icon: "error",
                    text: multiLangJSONParse()["netserver.26&19.errMsg"],
                    allowOutsideClick: false,
                    showCancelButton: true,
                    cancelButtonText:
                      multiLangJSONParse()["buttons.restart.net"],

                    confirmButtonText: multiLangJSONParse()["buttons.back"],
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      window.history.back();
                    } else {
                      window.location.reload();
                    }
                  });
              }
            }
          }
        }
      }

      return Promise.reject(error);
    }
  );

  return NetSrv;
};

export default customNetServAxios;
