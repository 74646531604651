import { FormattedMessage } from "react-intl";

export default function OfertsModalImageCardContentGift({data, replaceElementsURL}) {
    
    return <>
        <div style={{height: "82px", border: "2px", borderRadius: "8px", borderColor: "#908F9A"}} className=" mt-3 w-100 grayBorder w-100 d-flex position-relative gap-1 " >
            <div style={{top:"-8px", right: "37%", color: "#B57ACF", borderRadius:"50px", backgroundColor: "#FCEFFD", width: "88px"}} className=" d-flex justify-content-center text-uppercase  museo-sans-500 s12 position-absolute" >
                ¡<FormattedMessage id="oferts.gift.text" />!
            </div>
            <div style={{border: "1px solid #DDDDDD"}} className=" px-1 gap-2 py-2 d-flex h-100 w-100 rounded-3 " >
            <div  style={{width: "68px", height: "65px",minWidth: "68px",minHeight: "65px"}} className="d-flex justify-content-center"  >
                    <img style={{maxWidth: "68px", maxHeight: "65px"}} src={`${replaceElementsURL(data?.imageBaseURL, data?.giftImage?.id, data?.giftImage?.filename_download)}`} ></img>
                </div>
                <div className=" w-100 h-100 " >
                    <p className=" h-100 w-100 museo-sans-700 s16 d-flex justify-content-center flex-column" style={{ lineHeight: '1.1' }}>
                        {data?.giftProductName}
                    </p>
                </div>

            </div>
        </div>
    </>
}