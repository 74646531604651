import { FormattedMessage } from "react-intl";
import HelpIconNoTarget from "../../../../common/Share/HelpIconNoTarget";

import imgZoom from "../../img/modals/zoomTwo.png";
import { ReactComponent as imgIcon } from "../../img/modals/zoomOne.svg";

import facebookOne from "../../img/modals/facebookOne.png";
import facebookTwo from "../../img/modals/facebookTwo.png";

import whatsappOne from "../../img/modals/whatsAppOne.png";
import whatsappTwo from "../../img/modals/whatsAppTwo.png";

import meetOne from "../../img/modals/meetOne.png";
import skypeOne from "../../img/modals/skypeOneA.png";

export default function EDBModalVirtual(params) {
  return (
    <div className=" s14 museo-sans-500">
      <div className=" p-3 mx-1 my-3 d-flex flex-column gap-4">
        <div className="d-flex gap-3  ">
          <div>
            <HelpIconNoTarget />
          </div>
          <p className=" s20 museo-sans-700">
            <FormattedMessage id="edb.form.virtual.modal.title" />
          </p>
        </div>
        <div
          style={{ gap: "28px", maxHeight: "400px" }}
          className="d-flex flex-column hidden-y-scroll "
        >
          <p>
            <FormattedMessage id="edb.form.virtual.modal.subtitle" />
          </p>
          <EDBModalVirtualRow
            subtitle={"edb.form.virtual.zoom.subtitle"}
            title={"edb.form.virtual.zoom.title"}
            icon={imgIcon}
            iconType={"svg"}
            img={imgZoom}
          />
          <EDBModalVirtualRow
            subtitle={"edb.form.virtual.facebook.subtitle"}
            title={"edb.form.virtual.facebook.title"}
            icon={facebookTwo}
            iconType={"img"}
            img={facebookOne}
          />
          <EDBModalVirtualRow
            subtitle={"edb.form.virtual.whatsapp.subtitle"}
            title={"edb.form.virtual.whatsapp.title"}
            icon={imgIcon}
            img={""}
            imgOne={whatsappOne}
            imgTwo={whatsappTwo}
          />
          <EDBModalVirtualRow
            subtitle={"edb.form.virtual.meet.subtitle"}
            title={"edb.form.virtual.meet.title"}
            icon={imgIcon}
            img={meetOne}
          />
          <EDBModalVirtualRow
            subtitle={"edb.form.virtual.skype.subtitle"}
            title={"edb.form.virtual.skype.title"}
            icon={imgIcon}
            img={skypeOne}
          />
        </div>
      </div>
      <hr className=" m-0" />
      <div
        data-bs-dismiss="modal"
        aria-label="Close"
        style={{ width: "122px" }}
        className="d-flex justify-content-center w-100 align-items-center"
      >
        <p className=" pointer s16 my-3 skyColor">
          <FormattedMessage id="buttons.understood" />
        </p>
      </div>
    </div>
  );
}

export function EDBModalVirtualRow(params) {
  return (
    <div className=" d-flex flex-column gap-2 museo-sans-500 s14">
      <div className="d-flex flex-column gap-1">
        <div className="d-flex gap-1">
          <div>
            {params.iconType === "img" ? (
              <img style={{width: "24px", height: "24px"}} src={params.icon} />
            ) : (
              <params.icon />
            )}
          </div>
          <p className=" museo-sans-700 ">
            <FormattedMessage id={params?.title} />
          </p>
        </div>
        <p>
          <FormattedMessage id={params?.subtitle} />
        </p>
      </div>
      <div className="mt-1">
        {
            params?.img ?
            <img style={{width: "440px"}} src={params?.img} />
            :
            <div style={{gap: "10px"}} className="d-flex px-2" >
                <img style={{width: "215px", height: "450px"}} src={params?.imgOne} />
                <img style={{width: "215px", height: "450px"}} src={params?.imgTwo} />
            </div>
        }
      </div>
    </div>
  );
}
