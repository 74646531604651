import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openVimeo } from "../../../common/redux/commonActions";
import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import { ReactComponent as SVGVideo } from "../../../common/svgs/ic-video-sm.svg";

export default function GoodPracticesImportantCard({ cardData, profileLevel }) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const sortColors = (color) => {
    if (color === "completed") {
      return { color: "#16BE7D" };
    }
    if (color === "warning") {
      return { color: "#DCB632" };
    }
    if (color === "risk") {
      return { color: "#F06E69" };
    }
    if (color === "normal") {
      return { color: "#DDDDDD" };
    }
  };

  const onClick = (index) => {
    if (cardData.links[index]?.type === "deep_link") {
      const arrayLinks = {
        EDBRealizadosAll: "/dashboard/edbs/list/dated",
        Descendants: "/dashboard/descendants/dashboard",
        BondPersonalGroup: "/dashboard/goodpractice/bonds",
      }
      history(`${arrayLinks[cardData.links[index]?.id]}`);
      return;
      
    }
    let values = cardData.links[0];
    dispatch(openVimeo({ id: values.id, key: values.key }));
    return
  };

  const isPrimary = ( (profileLevel > 1) && (cardData?.category == "PRODUCTIVITY" || cardData?.category == "INCORPORATIONS"))

  return (
    <div
      style={{
        borderRadius: "1rem",
      }}
      className={` ${ (isPrimary) ? "gg-primary--importance" : `gg-cards--importance${profileLevel < 2 ? "-consult" : "" }` } f9GrayBorder bg-white desc-cards-shadow d-flex flex-column justify-content-between p-3 `} 
    >
      <div className=" d-flex flex-column gap-2">
        <p
          style={{ paddingBottom: "14px" }}
          className=" pt-2 grayColor museo-sans-500 s12 text-uppercase "
        >
          {cardData.header}
        </p>
        <div
          style={{ alignItems: "center", height: "31px" }}
          className="d-flex"
        >
          {cardData?.title?.type === "ratio" ? (
            <>
              <h4
                style={sortColors(cardData.title.items[0].status)}
                className="m-0 s26 dm-sans-bold  "
              >
                {cardData.title.items[0].value}
              </h4>
              <span
                style={sortColors(cardData.title.items[1].status)}
                className="m-0 s26 dm-sans-bold "
              >
                /{cardData.title.items[1].value}
              </span>
            </>
          ) : (
            <h4
              style={sortColors(cardData.title.items[0].status)}
              className="m-0 s26 dm-sans-bold "
            >
              {cardData.title.items[0].value}
            </h4>
          )}
        </div>
        <p className=" s14 museo-sans-500 ">{cardData.description}</p>
      </div>
      <div className="d-flex justify-content-between align-items-end gap-2">
        {cardData?.links?.map((link, index) => {
          return (
            <div
              key={index}
              onClick={() => onClick(index)}
              className={` ${index == 1 && "justify-content-end" } skyColor d-flex w-100 museo-sans-700 s14 `} 
            >
              <p className=" pointer">
                {link.title}
                <i className="px-2 pointer">
                  {link?.type === "deep_link" ? (
                    <SVGRightArrow />
                  ) : (
                    <SVGVideo />
                  )}
                </i>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
