import { useState } from "react";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import ReturnToAndTitle from "../../../Share/ReturnToAndTitle";
import { postPersonalDataValidationEmail } from "../../Framework/ConfigurationSideEffect";
import { ReactComponent as SVGRedCross } from "../../../../EDBs/Presentation/img/redcross.svg";

export default function UserDataChangeEmail({
  userData,
  travelFunction,
  setSavedValues,
}) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({ value: "", valid: true, errorMessage: "" });

  const onChangeEmailValue = (evt) => {
    setEmail({ value: evt.target.value, valid: true, errorMessage: "" });
  };

  const verifyEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email.value)) {
      onContinue();
      return;
    }
    let aux = { ...email };
    aux.valid = false;
    setEmail(aux);
  };

  const onErrorValid = (errorMessage) => {
    let aux = { ...email };
    aux.valid = false;
    aux.errorMessage = errorMessage;
    setEmail(aux);
  };

  const onContinue = async () => {
    if (email.value.length === 0) {
      return;
    }
    const result = await dispatch(
      postPersonalDataValidationEmail({
        requestType: "email",
        email: email.value,
        firstName: userData?.firstName,
        lastName: userData?.lastName,
      })
    );
    if (result?.payload?.code == 924) {
      onErrorValid(result?.payload?.message);
      return;
    }
    setSavedValues({
      requestType: "email",
      email: email.value,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      token: result?.payload?.authenticatorToken,
    });
    travelFunction(1.3);
  };

  return (
    <div className=" d-flex flex-column gap-4">
      <ReturnToAndTitle
        travelFunction={travelFunction}
        returnTo={{ value: 1.1, whereTo: "buttons.returnToMyData" }}
      />
      <div className="d-flex flex-column gap-2 museo-sans-700 ">
        <p
          style={{ letterSpacing: "0.24px" }}
          className=" museo-sans-500 s12 text-uppercase grayColor"
        >
          <FormattedMessage id="settings.title.change.email" />
        </p>
        <p className=" s24">
          <FormattedMessage id="settings.title.change.email.subtitle.one" />
        </p>
      </div>
      <div>
        <input
          onChange={(evt) => onChangeEmailValue(evt)}
          style={{ width: "308px", height: "40px" }}
          className={` museo-sans-500 s14 ${
            email.valid ? "input-border" : "input-error-border"
          } px-3 py-2`}
          placeholder="Ejemplo: email@swissjust.net"
        />
        {!email.valid && (
          <div className=" mt-1 d-flex gap-1">
            {" "}
            <SVGRedCross />{" "}
            <p className=" museo-sans-500 s12 lightRedColor">
              {" "}
              {
                email?.errorMessage || <FormattedMessage id="settings.error.format" />
              }
              
            {" "}
            </p>
          </div>
        )}
      </div>
      <button
        onClick={verifyEmail}
        style={{ width: "308px", height: "40px" }}
        className={` mt-4 museo-sans-700 border-0 ${
          email.value ? "skyBg" : "brightGrayBg"
        } rounded-2 text-white`}
      >
        <FormattedMessage id="buttons.continue" />
      </button>
    </div>
  );
}
