import { ReactComponent as SVGRightChev } from "../svgs/ic-chevron-right-md.svg";


export default function SkeletonDescendentRow({type}) {
    return(
      <div className=" desc--grid-tree gap-4 pe-3">
      <div className="  ">
        <div className=" d-flex gap-2 align-items-center ">
          <div
            style={{ width: "13px", height: "13px" }}
            className=" svg-force-size  d-flex  "
          >
          </div>
          <div
            style={{ height: "40px", width: "40px" }}
            className="lightui1-shimmer rounded-circle brightGrayBg"
          ></div>
          <div className="d-flex flex-column gap-2">
            <div
              style={{ width: "100px", height: "12px" }}
              className="lightui1-shimmer rounded-2  brightGrayBg"
            ></div>
            <div
              style={{ width: "100px", height: "8px" }}
              className="lightui1-shimmer rounded-2  brightGrayBg"
            ></div>
          </div>
        </div>
        <div></div>
      </div>
      <div className=" d-flex gap-2 justify-content-center align-items-center">
        {
          type ?
            <div
          style={{ width: "45px", height: "12px" }}
          className="lightui1-shimmer invisible brightGrayBg rounded-2 "
        ></div>
          :
            <div
              style={{ width: "45px", height: "12px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
        }
      </div>
      <div className="d-flex justify-content-center align-items-center ">
        <div
          style={{ width: "45px", height: "12px" }}
          className="lightui1-shimmer brightGrayBg rounded-2 "
        ></div>
        <SVGRightChev />
      </div>
      <div className="d-flex gap-2 justify-content-center ">
        <div className=" d-flex flex-column gap-2">
          <div
            style={{ width: "38px", height: "6px" }}
            className="lightui1-shimmer brightGrayBg rounded-2 "
          ></div>
          <div className="d-flex" >
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
          </div>
          <div
            style={{ width: "38px", height: "6px" }}
            className="lightui1-shimmer brightGrayBg rounded-2 "
          ></div>
        </div>
        <SVGRightChev />
      </div>
    </div>
    )
}