import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { useMediaPredicate } from 'react-media-hook';

import { getOtherConsultantData } from '../../Framework/redux/performanceSideEffects';
import { resetOtherConsultantData } from '../../Framework/redux/performanceActions';
import { ReactComponent as ArrowLeftIcon } from "../../../common/svgs/arrow-left-sm.svg";
import { ReactComponent as ChevronDown } from "../../../common/svgs/ic-chevron-down-sm.svg";
import { getBilledOrders, getHistoricPeriods, getPendingOrders, getSalesByWeek, getUnsendedOrders } from '../../Framework/redux/performanceSideEffects';
import PersonalSalesWeekSalesModal from './PersonalSalesWeekSalesModal';

import PersonalSalesRow from "./PersonalSalesRow";
import PersonalSalesEmpty from "./PersonalSalesEmpty";
import PersonalSalesPeriodPopUp from "./PersonalSalesPeriodPopUp";
import PerformanceLoadingMoreRequests from "./PerformanceLoadingMoreRequests";
import IncorporationsSales from '../pages/IncorporationsSales';
import GroupSales from '../pages/GroupSales';
import DescendantSales from '../pages/DescendantSales';
import ShareKeyGenerator from '../../../common/Share/ShareKeyGenerator';

import {
changePeriodForButton,
  resetOrders,
} from "../../Framework/redux/performanceActions";
import { calendarCurrentPeriod } from '../../../Calendar/Framework/redux/CalendarSideEffects';
import {ReactComponent as HistoryIcon} from '../../../common/svgs/ic-history-sm.svg'

export default function OtherUserPersonalSales() {

    let { consultantId, listType } = useParams();

    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(resetOtherConsultantData())
      dispatch(getOtherConsultantData(consultantId))
    }, [])
    
  return (
    <div className="container--padding pt-3 pb-4 pt-5 position-relative bronceBg">
        {
            listType == "personalSales" &&
            <PersonalSalesOtherConsultant />
        }
        {
            listType == "personalIncorporated" &&
            <IncorporationsSales consultantId={consultantId} />
        }
        {
            listType == "groupSales" &&
            <GroupSales consultantId={consultantId} />
        }
        {
            listType == "descendantSales" &&
            <DescendantSales consultantId={consultantId} />
        }
    </div>
  )
}

// Personal Sales for Other Consultant
export function PersonalSalesOtherConsultant() {

  let { consultantId } = useParams();

  const headerData = useSelector((state) => state?.headerData);

  const dispatch = useDispatch();

  const [changeValue, setChangeValue] = useState(1);

  const onStartUp = async () => {
          dispatch(getBilledOrders({yearPeriod: headerData.persiod?.currentYearPeriod, consultantId: consultantId ? consultantId : null }))
          dispatch(getHistoricPeriods({consultantId: consultantId ? consultantId : null }))
          dispatch(getSalesByWeek({yearPeriod: headerData.period?.currentYearPeriod, consultantId: consultantId ? consultantId : null }))
  }

  useEffect(() => {
      onStartUp()
  }, [])
  


  return  <>
    {
      changeValue === 1 ? 
      <PersonalSalesOtherConsultantRegister yearPeriod={headerData.period?.currentYearPeriod}  consultantId={consultantId} valueSetter={setChangeValue}/>
    :
      <PersonalSalesOtherConsultantHistory valueSetter={setChangeValue}/>
    }
  </>
}

// Register for Other Consultants
export function PersonalSalesOtherConsultantRegister({valueSetter, consultantId, yearPeriod}) {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(calendarCurrentPeriod());
        onStartUp()
    }, [])

    const onStartUp = () => {
        if(yearPeriod){
            dispatch(getUnsendedOrders({yearPeriod: yearPeriod, consultantId: consultantId ? consultantId : null }))
            dispatch(getPendingOrders({yearPeriod: yearPeriod, consultantId: consultantId ? consultantId : null }))
        }
    }
    
    const smallerThan1280 = useMediaPredicate("(max-width: 1280px)");

    const unsendedArray = useSelector(state => state.performanceData.unsendedOrders)
    const pendingArray = useSelector(state => state.performanceData.pendingOrders)
    const billedArray = useSelector(state => state.performanceData.billedOrders)
    const loadingOrders = useSelector(state => state.performanceData.ordersLoader)
    const weekSalesInfo = useSelector(state => state.performanceData.salesByWeek)
    const currentPeriodInfo = useSelector(state => state.calendarData.currentPeriodInfo)

    
    const current = currentPeriodInfo?.periodWeeks.filter((element) => {
        return element.week === currentPeriodInfo?.currentWeek
    })

    let currentWeekForModal
    if(current !== undefined){
        currentWeekForModal = current[0]?.id
    }
    let unsendedLength = unsendedArray?.length
    let pendingLength = pendingArray?.length
    let billedLength = billedArray?.length

    const [totalLength, setTotalLength] = useState(0)
    const [filterRegisters, setFilterRegisters] = useState(1)

    useEffect(() => {
        setTotalLength(0) 
        if(unsendedLength !== null  || pendingLength !== null || billedLength !== null){
            let suma = unsendedLength + pendingLength + billedLength
            setTotalLength(suma) 
        }

        if(!loadingOrders){
            setFilterRegisters(1)
        } else {
            setFilterRegisters(0)
        }
      
    }, [unsendedLength, pendingLength, billedLength, loadingOrders])
    
    
  return (
    <div className='container--padding--card-holder' >
        <div>
        <PersonalSalesWeekSalesModal semanaActual={currentWeekForModal} dataVentasSemanales={weekSalesInfo}/>
        <div className=' w-100 d-flex justify-content-between'>
            <div className="d-flex flex-wrap justify-items-center align-items-center">
                <button onClick={() => setFilterRegisters(1)} className={`${filterRegisters === 1 ? "blueBg bronceColor" : "bronceBg blueColor"} ${totalLength > 0 ? "blueBorder personal-sales-filter-hover" : "opacity80 pointer-events-none brightGrayBorder brightGrayColor"} rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.all" defaultMessage="Todos" /> ({totalLength ? totalLength : 0})</button>
                <button onClick={() => setFilterRegisters(2)} className={`${filterRegisters === 2 ? "blueBg bronceColor" : "bronceBg blueColor"} ${billedLength > 0 ? "blueBorder personal-sales-filter-hover" : "opacity80 pointer-events-none brightGrayBorder bronceBg brightGrayColor"}  rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.bill" defaultMessage="Facturados" /> ({billedLength ? billedLength : 0})</button>
                <button onClick={() => setFilterRegisters(3)} className={`${filterRegisters === 3 ? "blueBg bronceColor" : "bronceBg blueColor"} ${pendingLength > 0 ? "blueBorder personal-sales-filter-hover" : "opacity80 pointer-events-none brightGrayBorder bronceBg brightGrayColor"}  rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.pending" defaultMessage="A facturar" /> ({pendingLength ? pendingLength : 0})</button>
                <button onClick={() => setFilterRegisters(4)} className={`${filterRegisters === 4 ? "blueBg bronceColor" : "bronceBg blueColor"} ${unsendedLength > 0 ? "blueBorder personal-sales-filter-hover" : "opacity80 pointer-events-none brightGrayBorder bronceBg brightGrayColor"}  rounded-pill ps-3 pe-3 me-3 museo-sans-700 s14 performance-filter-button`}><FormattedMessage id="personal.sales.request" defaultMessage="Sin enviar" /> ({unsendedLength ? unsendedLength : 0})</button>
            </div>
            <div className='d-flex align-items-center'>
                <button data-bs-toggle="modal"
                                data-bs-target="#weekSalesModal"
                    className={`${smallerThan1280 ? "ps-2 pe-2 me-3" : "ps-4 pe-4 me-4"} museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}>
                        <FormattedMessage id="performance.week.detail.button" defaultMessage="Detalle semana" />
                </button>
                <button 
                    onClick={() => valueSetter(2)}
                    className={`${smallerThan1280 ? "ps-2 pe-2 me-3" : "ps-4 pe-4 me-4"} d-flex justify-content-evenly align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-fill button-bootstrap-radius button-padding position-relative perf-history-week`}>
                        <HistoryIcon />
                        <p className='ms-1'><FormattedMessage id="personal.sales.history" defaultMessage="Ver historia" /></p>
                </button>
            </div>
        </div>
        <div className=" w-100 d-flex s12 museo-sans-500 grayColor justify-content-between align-items-center row-height mb-2">
            <div className="personal-sales-order">
                <FormattedMessage id="personal.sales.header.row.order" defaultMessage="ORDEN/PEDIDO NRO." />
            </div>
            <div className="personal-sales-state">
                <FormattedMessage id="personal.sales.header.row.state" defaultMessage="ESTADO" />
            </div>
            <div className="personal-sales-date">
                <FormattedMessage id="personal.sales.header.row.date" defaultMessage="FECHA" />
            </div>
            <div className="personal-sales-type">
                <FormattedMessage id="personal.sales.header.row.type" defaultMessage="TIPO" />
            </div>
            <div className="personal-sales-total">
                <FormattedMessage id="personal.sales.header.row.total" defaultMessage="TOTAL" />
            </div>
            <div className="personal-sales-chevron"></div>
        </div>
        <div className=' w-100 position-relative'>
            {
                filterRegisters === 0 ?
                    loadingOrders ?
                        <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                    :
                        null
                : filterRegisters === 1 ?
                    loadingOrders ? 
                        <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                    :
                        unsendedLength > 0 || pendingLength > 0 || billedLength > 0 ?
                            <>
                                {unsendedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={1} />})}
                                {pendingArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={2} />})}
                                {billedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={3} />})}
                            </>
                        :
                            <div className="d-flex justify-content-center align-items-center mt-5">
                                <PersonalSalesEmpty
                                    title="Sin pedidos ni órdenes"
                                    description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                                ></PersonalSalesEmpty>
                            </div>
                : filterRegisters === 2 ?
                    loadingOrders ?
                        <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                    :
                    billedLength > 0 ?
                        <>
                            {billedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={3} />})}
                        </>
                    :
                        <div className="d-flex justify-content-center align-items-center mt-5">
                            <PersonalSalesEmpty
                                title="Sin pedidos ni órdenes"
                                description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                            ></PersonalSalesEmpty>
                        </div>
                : filterRegisters === 3 ?
                    loadingOrders ?
                        <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                    :
                    pendingLength > 0 ?
                        <>
                            {pendingArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={2} />})}
                        </>
                    :
                        <div className="d-flex justify-content-center align-items-center mt-5">
                            <PersonalSalesEmpty
                                title="Sin pedidos ni órdenes"
                                description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                            ></PersonalSalesEmpty>
                        </div> 
                : filterRegisters === 4 ?
                    loadingOrders ?
                        <PerformanceLoadingMoreRequests type={"personal"} text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
                    :
                    unsendedLength > 0 ?
                        <>
                            {unsendedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={1} />})}
                        </>
                    :
                        <div className="d-flex justify-content-center align-items-center mt-5">
                            <PersonalSalesEmpty
                                title="Sin pedidos ni órdenes"
                                description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                            ></PersonalSalesEmpty>
                        </div>
                : null
            }
        </div>
        </div>
    </div>
    
  )
}

// History for Other Consultants
export function PersonalSalesOtherConsultantHistory({valueSetter}) {
  const dispatch = useDispatch()

    const biggerThan1280 = useMediaPredicate("(min-width: 1280px)");

    let { consultantId } = useParams();

    const headerData = useSelector((state) => state?.headerData);

    const unsendedArray = useSelector(state => state.performanceData.unsendedOrders)
    const pendingArray = useSelector(state => state.performanceData.pendingOrders)
    const billedArray = useSelector(state => state.performanceData.billedOrders)
    const loadingOrders = useSelector(state => state.performanceData.ordersLoader)

    const periodHistory = useSelector(state => state.performanceData.historicPeriods)

    const valueInPeriodButton = useSelector(state => state.performanceData.currentOrSelectedPeriod)

    let unsendedLength = unsendedArray?.length
    let pendingLength = pendingArray?.length
    let billedLength = billedArray?.length

    const [togglePeriods, setTogglePeriods] = useState(false)

    function resetPersonalSales(){
        dispatch(resetOrders())
        dispatch(getUnsendedOrders({yearPeriod: headerData.period?.currentYearPeriod, consultantId: consultantId ? consultantId : null }))
        dispatch(getPendingOrders({yearPeriod: headerData.period?.currentYearPeriod, consultantId: consultantId ? consultantId : null }))
        dispatch(getBilledOrders({yearPeriod: headerData.period?.currentYearPeriod, consultantId: consultantId ? consultantId : null }))
        valueSetter(1)
    }

    useEffect(() => {
        dispatch(getBilledOrders({yearPeriod: headerData.period?.currentYearPeriod, consultantId: consultantId ? consultantId : null }))
    
        
        if(periodHistory !== null){
            dispatch(changePeriodForButton(periodHistory[0]?.period))
        }
      
    }, [])


    

  return ( <div className='container--padding--card-holder' >
        <div>

        
        <div className=' w-100 d-flex justify-content-between align-items-center'>
            <div>
                <button 
                    className='skyColor bronceBg button-hover-svg-light-stroke button-hover-light-text museo-sans-700 s14 button-border-none d-flex align-items-center'
                    onClick={resetPersonalSales}
                >
                    <ArrowLeftIcon/> 
                    <p className='ms-3'><FormattedMessage id= "personal.sales.back.button" defaultMessage="Volver a todos los pedidos" /></p>
                </button>
            </div>
            <div className='d-flex position-relative'>
                <button
                    onClick={() => setTogglePeriods(!togglePeriods)}
                    className={`${!biggerThan1280 ? "ps-2 pe-2 me-2" : "ps-4 pe-4 me-4"} d-flex justify-content-evenly align-items-center museo-sans-700 s14 skyColor skyBorder bronceBg button-hover-light-text button-hover-svg-light-stroke button-bootstrap-radius button-padding position-relative perf-history-week`}
                >
                    <p className='ms-1'><FormattedMessage id= "personal.sales.period.button.label" defaultMessage="Período" /> {valueInPeriodButton}</p>
                    <ChevronDown />
                </button>
                <PersonalSalesPeriodPopUp 
                    toggle={togglePeriods}
                    toggler={setTogglePeriods}
                    other={true}
                />
            </div>
        </div>
        <div className=" w-100 d-flex s12 museo-sans-500 grayColor justify-content-between align-items-center row-height mb-2">
            <div className="personal-sales-order">
                <FormattedMessage id="personal.sales.header.row.order" defaultMessage="ORDEN/PEDIDO NRO." />
            </div>
            <div className="personal-sales-state">
                <FormattedMessage id="personal.sales.header.row.state" defaultMessage="ESTADO" />
            </div>
            <div className="personal-sales-date">
                <FormattedMessage id="personal.sales.header.row.date" defaultMessage="FECHA" />
            </div>
            <div className="personal-sales-type">
                <FormattedMessage id="personal.sales.header.row.type" defaultMessage="TIPO" />
            </div>
            <div className="personal-sales-total">
                <FormattedMessage id="personal.sales.header.row.total" defaultMessage="TOTAL" />
            </div>
            <div className="personal-sales-chevron"></div>
        </div>
        {
            loadingOrders ? 
                <PerformanceLoadingMoreRequests text={<FormattedMessage id="personal.sales.loading.requests" defaultMessage="Cargando pedidos"/>} small={false}/>
            :
            unsendedLength > 0 || pendingLength > 0 || billedLength > 0 ?
                <>
                    {billedArray?.map((element) => {return <PersonalSalesRow key={ShareKeyGenerator()} data={element} status={3} />})}
                </>
            :
                <div className="d-flex justify-content-center align-items-center mt-5">
                    <PersonalSalesEmpty
                        title="Sin pedidos ni órdenes"
                        description="¡En este momento no tienes ningún pedido enviado ni órdenes sin enviar!"
                    ></PersonalSalesEmpty>
                </div>
        }
        </div>
    </div>)
}