import { FormattedMessage } from "react-intl"

import { ReactComponent as SVGRightChev } from "../svgs/ic-chevron-right-md.svg";

export default function SkeletonDescendentHeader({type}) {

    return <> 
        <div>
        <div className=" d-flex h-100 gap-2 align-items-center ">
          <div
            style={{ height: "58px", width: "58px" }}
            className="lightui1-shimmer rounded-circle brightGrayBg"
          ></div>
          <div className="d-flex flex-column gap-2">
            <div
              style={{ width: "150px", height: "12px" }}
              className="lightui1-shimmer rounded-2  brightGrayBg"
            ></div>
            <div
              style={{ width: "100px", height: "8px" }}
              className="lightui1-shimmer rounded-2  brightGrayBg"
            ></div>
          </div>
        </div>
        </div>
        <div>
        <div className=" position-relative">
          {
            type ?
              <button
                style={{
                  width: "184px",
                  height: "40px",
                  right: "16px",
                  top: "10px",
                }}
                className="lightui1-shimmer invisible d-flex position-absolute justify-content-center align-items-center s14 museo-sans-700 skyColor brightGrayBg border-0 rounded "
              >
                <p className=" invisible ">
                  <FormattedMessage id="view.personal.group" />
                </p>
              </button>
            :
              <button
                style={{
                  width: "184px",
                  height: "40px",
                  right: "16px",
                  top: "20px",
                }}
                className="d-flex position-absolute justify-content-center align-items-center s14 museo-sans-700 skyColor brightGrayBg border-0 rounded lightui1-shimmer"
              >
                <p className=" invisible ">
                  <FormattedMessage id="view.personal.group" />
                </p>
              </button>
          }
            </div>
        </div>
        <div className="d-flex justify-content-center align-items-center " >
            <div className="d-flex flex-column gap-2 " >
                <div
                style={{ width: "65px", height: "8px" }}
                className="lightui1-shimmer brightGrayBg rounded-2 "
                ></div>
                <div
                style={{ width: "65px", height: "12px" }}
                className="lightui1-shimmer brightGrayBg rounded-2 "
                ></div>
            </div>
            <SVGRightChev />
        </div>
        <div className="d-flex gap-2 align-items-center justify-content-center ">
        <div className=" d-flex flex-column gap-2">
          <div
            style={{ width: "38px", height: "6px" }}
            className="lightui1-shimmer brightGrayBg rounded-2 "
          ></div>
          <div className="d-flex" >
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
            <div
              style={{ width: "100%", height: "6px" }}
              className="lightui1-shimmer brightGrayBg rounded-2 "
            ></div>
          </div>
          <div
            style={{ width: "38px", height: "6px" }}
            className="lightui1-shimmer brightGrayBg rounded-2 "
          ></div>
        </div>
        <SVGRightChev />
      </div>
    </>
    
}