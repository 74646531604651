import { FormattedMessage } from "react-intl";

export default function BusinessPlanCardLevelFuture({onClick, level, actualLevel, array}) {

  const selectedCard = {background: "#ECF5FE", borderRadius: "16px", boxShadow: "3px 3px 15px #A4A4D733", border: " 1px solid #D3EAFF", color: "#5AAFF1"};
  const notSelectedCard = { borderRadius: "16px", color: "#908F9A"};

  const selectedP = {color: "#5AAFF1"};
  const notSelectedP = {color: "#908F9A"};


    return (
        <>
           <div
          style={{ width: "24px" }}
          className=" me-3 position-relative py-1 d-flex gap-1 flex-column align-items-center"
        >
          <div  >
            <div
              style={{ width: "16px", height: "16px" }}
              className=" grayBorder border-3 border rounded-circle"
            ></div>
          </div>
          <div className=" h-100">
          </div>
        </div>
        <div className=" bp--card-width d-flex flex-column" style={{ justifySelf: "left" }} >
        <div  style={parseInt(actualLevel) +1 < level ? selectedCard : notSelectedCard } className={` hover-on-mouse pointer mb-4 museo-sans-500 d-flex flex-column gap-3 bp--card-p`}>
                <p className=" s12 grayColor" > <FormattedMessage id="business.plan.card.future.title" /> ({7 - actualLevel})</p>
                {
                  array.map((elem) => {
                    if (elem.id > parseInt(actualLevel) +1) {
                      return <p key={`${elem.id}-03`} onClick={() => onClick(elem.id)} style={parseInt(level) === parseInt(elem.id) ? selectedP : notSelectedP } className="s14">
                        {elem.desc}
                      </p>
                    }
                  })
                }
            </div>
        </div>
        </>
    )
}