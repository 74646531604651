import CalendarEventSection from "./CalendarEventSection";

export default function CalendarBody({spawnWeekDay, displayDays, spawnWeekNumber, slicedDateObjects, smallerThan1280, daysInMonth, stringFirstDayOfMonth}){
  return (
    <div className={`d-flex calendar-and-events-container`}>
                <div className="overflow-scroll remove-scrollbar me-3 calendar-container">
                  <div className="w-100 d-flex flex-column">
                    <div className="mb-3 d-flex">
                      <div className="weekday-blank-space"></div>
                      <div className="d-flex weekday-container">
                        {spawnWeekDay("LUN")}
                        {spawnWeekDay("MAR")}
                        {spawnWeekDay("MIE")}
                        {spawnWeekDay("JUE")}
                        {spawnWeekDay("VIE")}
                        {spawnWeekDay("SAB")}
                        {spawnWeekDay("DOM")}
                      </div>
                    </div>

                    <div className={`d-flex ${smallerThan1280 && "mb-3"}`}>
                      <div className="d-flex flex-column weekday-blank-space">
                        {daysInMonth === 28 &&
                        stringFirstDayOfMonth === "lunes" ? (
                          <>
                            {spawnWeekNumber(slicedDateObjects[0])}
                            {spawnWeekNumber(slicedDateObjects[1])}
                            {spawnWeekNumber(slicedDateObjects[2])}
                            {spawnWeekNumber(slicedDateObjects[3])}
                          </>
                        ) : (daysInMonth === 28 &&
                            stringFirstDayOfMonth !== "lunes") ||
                          daysInMonth === 29 ||
                          (daysInMonth === 31 &&
                            stringFirstDayOfMonth !== "sábado" &&
                            stringFirstDayOfMonth !== "domingo") ||
                          (daysInMonth === 30 &&
                            stringFirstDayOfMonth !== "domingo") ? (
                          <>
                            {spawnWeekNumber(slicedDateObjects[0])}
                            {spawnWeekNumber(slicedDateObjects[1])}
                            {spawnWeekNumber(slicedDateObjects[2])}
                            {spawnWeekNumber(slicedDateObjects[3])}
                            {spawnWeekNumber(slicedDateObjects[4])}
                          </>
                        ) : (
                          <>
                            {spawnWeekNumber(slicedDateObjects[0])}
                            {spawnWeekNumber(slicedDateObjects[1])}
                            {spawnWeekNumber(slicedDateObjects[2])}
                            {spawnWeekNumber(slicedDateObjects[3])}
                            {spawnWeekNumber(slicedDateObjects[4])}
                            {spawnWeekNumber(slicedDateObjects[5])}
                          </>
                        )}
                      </div>

                      <div className="d-flex flex-wrap calendar-day-container">
                        {displayDays()}
                      </div>
                    </div>
                  </div>
                </div>
                {<CalendarEventSection />}
              </div>
  );
};