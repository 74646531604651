export default function ClientListDetailsBanner({divRefJumpTop, loading}) {
    
    return(
        <>
            {loading ?
                <div ref={divRefJumpTop} style={{height: "124px", opacity: 0.65}} className=" account--card-shadow lightui1-shimmer w-100 " >

                </div>
            :
                <div ref={divRefJumpTop} style={{height: "124px", opacity: 0.65}} className=" account--card-shadow labelOneBg w-100 " >

                </div>
        }
        </>
    )
}