import moment from "moment-timezone";
import { FormattedMessage } from "react-intl";

export function OfertCardDates(
  iniDate,
  endDate,
  compareTo,
  modal,
  allowYear,
  recentNotis,
  firstSteps
) {
  const today = new Date();
  const momentActualDate = moment(today).format("YYYY-MM-DD");

  const momentIniDate = moment(iniDate).format("YYYY-MM-DD");

  const momentIniDateMonth = moment(iniDate).format("MM");
  const momentIniDateDay = moment(iniDate).format("DD");
  const momentIniDateYear = moment(iniDate).format("YYYY");
  let monthIni = +momentIniDateMonth;

  if (recentNotis) {
    const momentInitAuxiliar = moment(
      recentNotis?.dateStartOfPublication
    ).format("YYYY-MM-DD");

    if (momentInitAuxiliar <= momentIniDate) {
      return true;
    }

    if (momentInitAuxiliar > momentIniDate) {
      return false;
    }
  }

  const momentEndDate = moment(endDate).format("YYYY-MM-DD");
  const momentEndDateMonth = moment(endDate).format("MM");
  const momentEndDateDay = moment(endDate).format("DD");
  const momentEndDateYear = moment(endDate).format("YYYY");

  let monthEnd = momentEndDateMonth ? +momentEndDateMonth : 0;

  if (compareTo) {
    if (
      momentActualDate >= momentIniDate &&
      momentActualDate <= momentEndDate
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {firstSteps ? (
        <span>
          <span className="text-capitalize">
            <FormattedMessage id="del" />{" "}
          </span>
          <span> </span>
          <>{momentIniDateDay}</>
          <>
            {" "}
            <FormattedMessage id={`month.of.${monthIni}`} />{" "}
          </>
          <span className="">
            <FormattedMessage id="del" />{" "}
          </span>
          <>{momentIniDateYear}</>
          {endDate && (
            <>
              {" "}al{" "}
              <span>{momentEndDateDay}</span>
              <>
                    {" "}
                    <FormattedMessage id={`month.of.${monthEnd}`} />{" "}
             
              </>
              <>
                    <FormattedMessage id="del" /> {momentEndDateYear}
              </>
            </>
          )}
        </span>
      ) : (
        <span className=" ">
          {modal ? (
            <>
              <span className="">
                <FormattedMessage id="del" />{" "}
              </span>
            </>
          ) : (
            <>
              <span className="text-capitalize">
                <FormattedMessage id="del" />{" "}
              </span>
            </>
          )}
          {momentIniDateDay}{" "}
          {monthIni != monthEnd && (
            <>
              <FormattedMessage id={`month.of.${monthIni}`} />{" "}
            </>
          )}
          {momentEndDateYear != momentIniDateYear && allowYear && (
            <>
              <FormattedMessage id="del" /> {momentIniDateYear}{" "}
            </>
          )}
          <FormattedMessage id="al" /> {momentEndDateDay}{" "}
          <FormattedMessage id={`month.of.${monthEnd}`} />{" "}
          {allowYear && (
            <>
              <FormattedMessage id="del" /> {momentEndDateYear}
            </>
          )}
        </span>
      )}
    </>
  );
}
