import LangPa from "../../common/lang/es-PA.json";
import LangArg from "../../common/lang/es-AR.json";
import LangMx from "../../common/lang/es-MX.json";
import LangCr from "../../common/lang/es-CR.json";
import LangPe from "../../common/lang/es-PE.json";
import LangCl from "../../common/lang/es-CL.json";
import LangUy from "../../common/lang/es-UY.json";
import LangCo from "../../common/lang/es-CO.json";

const languages = {
  ar: LangArg,
  pa: LangPa,
  mx: LangMx,
  cr: LangCr,
  pe: LangPe,
  cl: LangCl,
  uy: LangUy,
  co: LangCo
};

export const multiLang = (lang) => {
  const folder = languages[lang] || LangMx;
  return { code: lang, folder };
};
