import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearClientDetailsForm } from "../../../../ClientList/Framework/redux/ClientListAction";
import { ReactComponent as SVGRedTriangle } from "../../img/triangleFillRed.svg";

export default function EDBModalReturn() {
  const history = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const onReturn = () => {
    dispatch(clearClientDetailsForm())
    const path = localStorage.getItem("path");
    if (path) {
      history(path);
      localStorage.removeItem("formBody");
      localStorage.removeItem("formBodyType");
      localStorage.removeItem("path");
      if ((location?.pathname?.includes("/type/present/") || location?.pathname?.includes("/type/virtual/")) && (path?.includes("/form/type/"))) {
        window.location.reload();
        return
      }

      return
    }
    history(-1);
    localStorage.removeItem("formBody");
    localStorage.removeItem("formBodyType");
  };

  return (
    <div className="museo-sans-500 text-center">
      <div className=" d-flex gap-4 mb-4 pb-2 flex-column align-items-center ">
        <div
          className="lightRedBgOpacity20 mt-4 rounded-circle d-flex justify-content-center align-items-center "
          style={{ width: "100px", height: "100px" }}
        >
          <div className="  svg-force-size">
            <SVGRedTriangle />
          </div>
        </div>
        <div style={{ gap: "10px" }} className="d-flex flex-column">
          <p className=" s24 museo-sans-700">
            <FormattedMessage id="edb.form.modal.return.title" />
          </p>
          <p style={{ width: "410px" }} className=" s16 grayColor ">
            <FormattedMessage id="edb.form.modal.return.p" />
          </p>
        </div>
        <div className="d-flex flex-column w-100 ">
          <div
            style={{ height: "56px" }}
            className=" pointer d-flex align-items-center justify-content-center   "
          >
            <p
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{ width: "277px", height: "40px" }}
              className=" s14 d-flex justify-content-center museo-sans-500 align-items-center skyBg text-white rounded-3 "
            >
              <FormattedMessage id="edb.form.modal.return.button.one" />
            </p>
          </div>
          <div
            style={{ height: "56px" }}
            className=" pointer d-flex align-items-center justify-content-center   "
          >
            <p
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onReturn}
              style={{ width: "277px", height: "40px" }}
              className=" s14 d-flex justify-content-center museo-sans-500 align-items-center skyColor skyBorder rounded-3 "
            >
              <FormattedMessage id="buttons.back" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
