export default function HandleSwissDates ({date}) {
    
    if (!date) {
        return;
    }
    let longStringArray = date.split("T");
    let longStringDate = longStringArray[0];
    let shotStringArray = longStringDate.split("-");
    let year = shotStringArray[0];
    let month = shotStringArray[1];
    let day = shotStringArray[2];

    return `${day}-${month}-${year.slice(2,4)}`
}