import { FormattedMessage } from "react-intl";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useRef } from "react";

import { ReactComponent as SVGShare } from "../../../common/svgs/ic-share-sm.svg";
import { ReactComponent as WhatsappIcon } from "../../../common/svgs/ic-whatsapp-md.svg";
import { ReactComponent as EmailIcon } from "../../../common/svgs/ic-mail-md.svg";
import { clientDetailsForm } from "../../../EDBs/Framework/redux/EDBsActions";
import { addInvitee } from "../../../EDBs/Framework/redux/EDBFormSideEffect";
import ClientListTerritoryPerCountry from "./ClientListTerritoryPerCountry";
import ClientListDetailsContainerCards, {
  ClientListDetailsContainerCardsElement, ClientListDetailsContainerCardsEmpty,
} from "./ClientListDetailsCards";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import ShareModalStructure from "../../../common/Share/ShareModalStructure";
import ClientListDetailsModalOrigen from "./modals/ClientListDetailsModalOrigen";
import ModalWhiteShadow from "../../../common/Share/ModalWhiteShadow";

export default function ClientListDetailsContent({
  loading,
  data,
  socialArray,
  open,
  isEmpty,
  edbData,
  contactCards,
  contactCardsLoader,
}) {
  const history = useNavigate();
  const location = useLocation();
  const params = useParams();

  const isEDB = !location.pathname.includes("dashboard/clientlist/details/");

  const searchByIdValue = (searchFor) => {
    let searchById = data?.contactId;
    let arrayList = edbData?.attendees;
    let result = arrayList?.find(
      (element) => element?.attendeeId === searchById
    );
    if (searchFor == "registerOrigin") {
      return result?.registerOrigin || "";
    }
    return result?.registeredDate || "";
  };

  const onEditValues = () => {
    const numbers = {
      ar: "549",
      cl: "56",
      co: "57",
      cr: "506",
      mx: "52",
      pa: "507",
      pe: "51",
      uy: "598",
    };
    let phoneArray = data?.phoneNumbers;
    let phone = phoneArray[0]?.number;
    phone = phone.replace(numbers[localStorage.getItem("country")], "");
    localStorage.setItem("clientForm", JSON.stringify({cellNumber: phone, firstName: data?.firstName, lastName: data?.lastName}) );
    history(`/dashboard/clientlist/form/update/${data?.contactId}`);
  };

  const isEdb = location.pathname.includes("/edbs");

  return (
    <div style={{ marginTop: "120px", gap: "25px" }} className="d-flex ">
      <div className=" ">
        <div className=" d-flex align-items-center gap-2 ">
          {loading ? (
            <>
              <p className=" lightui1-shimmer rounded-4 s16">
                <span className="invisible">11232412412411221321421</span>
              </p>
              <p className=" lightui1-shimmer rounded-4 s14">
                <span className="invisible">1123241241241</span>
              </p>
            </>
          ) : (
            <>
              <p className=" s20 museo-sans-500">
                <FormattedMessage id="client.details.card.title" />
              </p>
              {data?.contactOriginType != "PERSONAL_SITE" && (
                <p
                  onClick={onEditValues}
                  className=" pointer s14 button-hover-light-text museo-sans-700 skyColor px-1 "
                >
                  <FormattedMessage id="buttons.edit.data" />
                </p>
              )}
            </>
          )}
        </div>
        <div className=" d-flex">
          <ClientListDetailsContentCard
            loading={loading}
            data={data}
            socialArray={socialArray}
            edbData={edbData}
            searchByIdValue={searchByIdValue}
            isEDB={isEDB}
          />
        </div>
      </div>
      {
        // HERE
      }
      <div>
      <p className=" museo-sans-500 s20">
              <FormattedMessage id="client.details.edb.cards.title" />
            </p>
      {params.clientId && edbData && isEdb && (
        <ClientListDetailsContainerCards
          isEmpty={isEmpty}
          edbData={edbData}
          data={data}
          open={open}
          searchByIdValue={searchByIdValue}
          isEDB={isEDB}
        />
      )}
      {( !isEdb && contactCards?.cards && !contactCardsLoader) && (
        <div>
          <div className="">

          </div>
          {
            contactCards?.cards.length === 0 ?
              <ClientListDetailsContainerCardsEmpty data={data} hasURL={data?.invitationLinkCards} firstName={data?.firstName} />
            :
            <>
              <div className="d-flex my-4 gap-4 grayColor ">
                <div className="">
                  <p className=" museo-sans-500 s12">
                    <FormattedMessage id="client.details.edb.cards.register" />:{" "}
                    {contactCards?.registeredDate}
                  </p>
                  <p className=" museo-sans-500 s14">
                    <FormattedMessage id="client.details.edb.cards.subtitle.one" />{" "}
                    {data?.firstName}{" "}
                    <FormattedMessage id="client.details.edb.cards.subtitle.two" />
                  </p>
                </div>
              </div>
              <div
                style={{ gap: "23px" }}
                className="s14 museo-sans-700 skyColor flex-wrap d-flex "
              >
                {contactCards?.cards?.map((card) => {
                  return (
                    <ClientListDetailsContainerCardsElement
                      data={card}
                      open={open}
                    />
                  );
                })}
              </div>
            
            </>
          }
        </div>
      )}

      </div>
    </div>
  );
}

export function ClientListDetailsContentCard({
  loading,
  data,
  socialArray,
  chooseHost,
  edbData,
  searchByIdValue,
  maxHeight,
  isEDB,
}) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const scrollbarRef = useRef();
  const { id } = useParams();

  const configExist = (auxId) => {
    let resolve = 0;
    if (socialArray) {
      resolve = socialArray.map((elm) => elm.id).indexOf(auxId);
    }
    return resolve;
  };

  const birthdayControl = (stringBirthday) => {
    if (!stringBirthday) {
      return "";
    }
    let splitValues = stringBirthday.split("-");

    let daySplitted = splitValues[0];
    let monthSplitted = splitValues[1];

    daySplitted = +daySplitted;
    monthSplitted = +monthSplitted;

    return (
      <>
        {" "}
        {monthSplitted} <FormattedMessage id="of" />{" "}
        <FormattedMessage id={`month.${daySplitted}`} />{" "}
      </>
    );
  };

  const onHostSelect = async () => {
    if (
      !location?.pathname.includes("/dashboard/edbs/form/client/list") &&
      location.pathname.includes("/client/list")
    ) {
      await dispatch(addInvitee({ attendeeId: data?.contactId, edbId: id }));
      history(-1);
    } else {
      dispatch(clientDetailsForm(data));
      history(-1);
    }
  };

  const checkIfValueExist = (value, index, idSubtitle) => {
    if (value?.length > index && value[index]?.number) {
      const numbers = {
        ar: "549",
        cl: "56",
        co: "57",
        cr: "506",
        mx: "52",
        pa: "507",
        pe: "51",
        uy: "598",
      };

      const resolvePhoneData = (phoneNumber) => {
        let code = localStorage.getItem("country");

        if (phoneNumber.startsWith(numbers[code])) {
          const localNumber = phoneNumber.slice(numbers[code].length);
          return `${numbers[code]} ${localNumber}`;
        }
        return phoneNumber;
      };

      return (
        <div className=" museo-sans-500">
          <p
            className=" text-uppercase grayColor s12"
            style={{ letterSpacing: "0.72px" }}
          >
            <FormattedMessage id={`${idSubtitle}`} />
          </p>
          <div className="d-flex justify-content-between">
            <p className=" s16">{resolvePhoneData(value[index]?.number)}</p>
            <a href={`https://wa.me/${value[index]?.number}`} target="_blank">
              <WhatsappIcon />
            </a>
          </div>
        </div>
      );
    }
    return "";
  };

  const controlOriginValue = () => {

    if (data?.contactOriginType == "PERSONAL_SITE") {
      return (
        <>
          <FormattedMessage id="client.details.edb.origin.type.two" /> /{" "}
          <FormattedMessage id="client.details.edb.origin.type.three" />
        </>
      );
    }

    return <FormattedMessage id="client.details.edb.origin.type.one" />;
  };

  const ifHost = () => {
    if (maxHeight > 840) {
      return "100%";
    }
    if (maxHeight > 740) {
      return "420px";
    }
    return "320px";
  };

  const clientCardHeaderBlueJSX = (
    <>
      {data?.contactOriginType == "PERSONAL_SITE" && (
        <div className=" museo-sans-500 w-100  s12 p-3 rounded-3 backgroundShallowBlue blueColor   ">
          <FormattedMessage id="client.details.card.no.edit.message" />
        </div>
      )}
    </>
  );

  return (
    <div
      style={{
        width: "392px",
        borderRadius: "16px",
        border: "1px solid #CED2FF62",
        maxHeight: `${chooseHost ? ifHost() : "auto"}`,
      }}
      className={` account--card-shadow mt-4 bg-white `}
    >
      <ShareModalStructure id="modal-origen-reg" maxWidth={"496px"}>
        <ClientListDetailsModalOrigen />
      </ShareModalStructure>
      {loading ? (
        <ClientListDetailsContentCardSkeleton />
      ) : (
        <>
          {chooseHost && (
            <div
              style={{ width: "390px", height: "62px" }}
              className=" position-fixed"
            >
              <div
                style={{
                  padding: "24px 24px 16px 24px",
                  borderRadius: "16px 16px 0px 0px",
                  boxShadow: "5px 6px 25px 0px rgba(164, 164, 215, 0.10)",
                }}
                className=" position-relative w-100 bg-white "
              >
                {chooseHost && (
                  <p className=" museo-sans-700 s16">
                    {data?.firstName} {data?.lastName}
                  </p>
                )}
              </div>
            </div>
          )}
          <div
            ref={scrollbarRef}
            style={{
              padding: "32px",
              maxHeight: `${chooseHost ? ifHost() : "auto"}`,
            }}
            className={` ${
              chooseHost ? "hidden-y-scroll" : ""
            } d-flex flex-column gap-4 `}
          >
            {chooseHost && (
              <p className=" invisible museo-sans-700 s16">
                {data?.firstName} {data?.lastName}
              </p>
            )}
            {clientCardHeaderBlueJSX}
            {checkIfValueExist(data?.phoneNumbers, 0, "TELÉFONO")}
            {checkIfValueExist(data?.phoneNumbers, 1, "phone.share.altenative")}
            {socialArray && (
              <>
                {socialArray[configExist("email")]?.value && (
                  <div className=" museo-sans-500">
                    <p
                      className=" text-uppercase grayColor s12"
                      style={{ letterSpacing: "0.72px" }}
                    >
                      <FormattedMessage id="settings.user.data.email" />
                    </p>
                    <div className="d-flex justify-content-between">
                      <p className=" text-lowercase s16">
                        {configExist("email") !== -1 && (
                          <>{socialArray[configExist("email")]?.value}</>
                        )}
                      </p>
                      <a
                        href={`mailto:${
                          socialArray[configExist("email")]?.value
                        }`}
                        target="_blank"
                      >
                        <EmailIcon />
                      </a>
                    </div>
                  </div>
                )}
              </>
            )}
            {ClientListTerritoryPerCountry(data?.addressInfo, true)}
            {data?.birthday && (
              <div className=" museo-sans-500">
                <p
                  className=" text-uppercase grayColor s12"
                  style={{ letterSpacing: "0.72px" }}
                >
                  <FormattedMessage id="birthday" />
                </p>
                <p className=" s16">{birthdayControl(data?.birthday)}</p>
              </div>
            )}
            {isEDB && (
              <>
                {edbData && searchByIdValue("registerOrigin") && (
                  <div className=" museo-sans-500">
                    <p
                      className="d-flex gap-1 text-uppercase grayColor s12"
                      style={{ letterSpacing: "0.72px" }}
                    >
                      <FormattedMessage id="client.details.edb.origen.p" />
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#modal-origen-reg"
                        style={{ width: "16px", height: "16px", top: "-2px" }}
                        className=" position-relative svg-force-size pointer"
                      >
                        <HelpIconNoTarget />
                      </div>
                    </p>
                    <p className=" s16">
                      {controlOriginValue(searchByIdValue("registerOrigin"))}
                    </p>
                  </div>
                )}
              </>
            )}
            {chooseHost ? (
              <div
                onClick={onHostSelect}
                style={{
                  borderRadius: "8px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                }}
                className=" invisible svg-force-hover-opacity d-flex gap-2 pointer justify-content-center align-items-center mt-4  w-100 skyBg text-white button-hover-light-text"
              >
                {!location?.pathname.includes(
                  "/dashboard/edbs/form/client/list"
                ) && location.pathname.includes("/client/list") ? (
                  <p className=" s14 museo-sans-700">
                    <FormattedMessage id="edb.details.select.new.invitee" />
                  </p>
                ) : (
                  <p className=" s14 museo-sans-700">
                    <FormattedMessage id="edb.form.card.select.host" />
                  </p>
                )}
              </div>
            ) : (
              <div
                data-bs-toggle="modal"
                data-bs-target="#modalShare"
                style={{ height: "40px", borderRadius: "8px" }}
                className=" svg-force-hover-opacity d-flex gap-2 pointer justify-content-center align-items-center mt-4  w-100 skyBorder skyColor button-hover-light-text"
              >
                <SVGShare />
                <p className=" s14 museo-sans-700">
                  <FormattedMessage id="buttons.client.share.data" />
                </p>
              </div>
            )}
            {chooseHost && (
              <ModalWhiteShadow
                left={"8%"}
                width={"80%"}
                bottom={"5%"}
                aidHide={40}
                scrollbarRef={scrollbarRef}
              />
            )}
          </div>
          {chooseHost && (
            <div
              style={{
                width: "390px",
                height: "62px",
                padding: "24px",
                zIndex: 5242,
              }}
              className=" position-fixed "
            >
              <div
                style={{ top: "-84px", height: "62px" }}
                className=" position-relative w-100 bg-white "
              >
                <div
                  onClick={onHostSelect}
                  style={{
                    borderRadius: "8px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  className=" position-relative  svg-force-hover-opacity d-flex gap-2 pointer justify-content-center align-items-center  w-100 skyBg text-white button-hover-light-text"
                >
                  {!location?.pathname.includes(
                    "/dashboard/edbs/form/client/list"
                  ) && location.pathname.includes("/client/list") ? (
                    <p className=" s14 museo-sans-700">
                      <FormattedMessage id="edb.details.select.new.invitee" />
                    </p>
                  ) : (
                    <p className=" s14 museo-sans-700">
                      <FormattedMessage id="edb.form.card.select.host" />
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export function ClientListDetailsContentCardSkeleton() {
  const onSubtitle = (idMessage) => {
    return (
      <p
        className=" text-uppercase grayColor s12"
        style={{ letterSpacing: "0.72px" }}
      >
        <FormattedMessage id={idMessage} />
      </p>
    );
  };

  const rowJSX = (
    <p className=" lightui1-shimmer rounded-4 s16">
      <span className="invisible">1123241241241</span>
    </p>
  );

  return (
    <div style={{ padding: "32px" }} className="d-flex flex-column gap-4">
      <div className="  museo-sans-500">
        {onSubtitle("TELÉFONO")}
        {rowJSX}
      </div>
      <div className=" museo-sans-500">
        {onSubtitle("settings.user.data.email")}
        {rowJSX}
      </div>
      <div className=" museo-sans-500">
        {onSubtitle("DIRECCIÓN")}
        <div id="" className="d-flex flex-wrap flex-column gap-1">
          {rowJSX}
          {rowJSX}
          {rowJSX}
        </div>
      </div>
      <div className=" museo-sans-500">
        {onSubtitle("birthday")}
        {rowJSX}
      </div>
      <div
        style={{ height: "40px", borderRadius: "8px" }}
        className=" d-flex gap-2 justify-content-center align-items-center mt-4  w-100 lightui1-shimmer"
      >
        <span className="invisible">
          <SVGShare />
          <p className=" s14 museo-sans-700">
            <FormattedMessage id="buttons.client.share.data" />
          </p>
        </span>
      </div>
    </div>
  );
}
