import { useRef, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import { useDispatch } from "react-redux";
import { fetchInvitationTableExtras } from "../../Framework/redux/InvitationsSideEffect";
import InvitationsListTableRow from "./InvitationsListTableRow";
import image6 from "../components/img/image6.png";
import ShareKeyGenerator from "../../../common/Share/ShareKeyGenerator";

export default function InvitationsListTable({
  data,
  orderBy,
  offSet,
  onSetNewOffset,
  loading,
  isVisible,
  setIsVisible,
  onExtraDataList,
}) {
  const extraRef = useRef();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [returnedEmpty, setReturnedEmpty] = useState();
  const [onlyOnce, setOnlyOnce] = useState(true);
  const biggerThan1260 = useMediaPredicate("(min-width: 1300px)");
  const biggerThan1024 = useMediaPredicate("(min-width: 1030px)");

  const callbackExtras = async () => {
    setLoader(true);
    const data = {
      offSet: offSet + 10,
      order: orderBy.order,
      dir: orderBy.dir,
    };
    const response = await dispatch(fetchInvitationTableExtras(data));
    onSetNewOffset(offSet + 10);
    onExtraDataList(response.payload.invitations);
    setLoader(false);
    if (response.payload.invitations?.length !== 10) {
      setReturnedEmpty(true);
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (extraRef.current) {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];
        setIsVisible(entry.isIntersecting);
      });
      observer.observe(extraRef.current);
    }

    if (isVisible && !onlyOnce) {
      let result = callbackExtras();
    }

    setTimeout(() => {
      setOnlyOnce(false);
    }, 1000);
  }, [isVisible, extraRef.current]);

  useEffect(() => {
    setReturnedEmpty(false);
  }, [orderBy?.order, orderBy?.dir]);

  return (
    <>
      <div className=" grayColor text-uppercase museo-sans-500 w-100 d-flex">
        {loading ? (
          <>
            <div
              className={` rounded-3 s14 px-3 ${
                !biggerThan1024 ? "w-50" : biggerThan1260 ? "w-100" : "w-75"
              } `}
            >
              <p className=" w-50  rounded-3 lightui1-shimmer">
                <span className="invisible">
                  <FormattedMessage id="edb.details.list.name" />
                </span>
              </p>
            </div>
            <div
              style={{ left: 56 }}
              className=" px-3 position-relative w-100 s14"
            >
              <p className=" w-50 lightui1-shimmer rounded-3">
                <span className="invisible">
                  <FormattedMessage id="first.steps.page.table.header.progress" />
                </span>
              </p>
            </div>
          </>
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                <p
                  className={` s14 px-3 ${
                    !biggerThan1024 ? "w-50" : biggerThan1260 ? "w-100" : "w-75"
                  } `}
                >
                  <FormattedMessage id="edb.details.list.name" />
                </p>
                <p
                  style={{ left: 56 }}
                  className=" px-3 position-relative w-100 s14"
                >
                  <FormattedMessage id="first.steps.page.table.header.progress" />
                </p>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      {data?.length > 0 && <hr />}
      {loading ? (
        <div className="w-100 d-flex flex-column ">
          <InvitationsListTableRowSkeleton />
          <InvitationsListTableRowSkeleton />
          <InvitationsListTableRowSkeleton />
          <InvitationsListTableRowSkeleton />
          <InvitationsListTableRowSkeleton />
          <InvitationsListTableRowSkeleton />
          <InvitationsListTableRowSkeleton />
        </div>
      ) : (
        <div className="w-100 d-flex flex-column ">
          {data?.length > 0 ? (
            <>
              {data.map((elm, index) => {
                return (
                  <InvitationsListTableRow
                    key={ShareKeyGenerator()}
                    lastData={data?.length}
                    auxIndex={index}
                    data={elm}
                  />
                );
              })}
            </>
          ) : (
            <div
              style={{ marginTop: "180px" }}
              className="d-flex justify-content-between align-items-center w-100 h-100"
            >
              <div className=" d-flex flex-column gap-2 w-100 h-100 justify-content-center align-items-center museo-sans-500 ">
                <div>
                  <img src={image6}></img>
                </div>
                <p className=" museo-sans-700 s16 ">
                  <FormattedMessage id="invitation.message.empty.state.title" />
                </p>
                <p
                  style={{ width: "434px" }}
                  className="text-center  s14 grayColor "
                >
                  <FormattedMessage id="invitation.message.empty.state" />
                </p>
              </div>
            </div>
          )}
        </div>
      )}
      <div>
        {!loading && (
          <>
            {!returnedEmpty && (
              <div ref={extraRef} className="mt-4" style={{ height: "30px" }}>
                {isVisible ? (
                  loader ? (
                    <div
                      style={{ top: "-10px" }}
                      className=" position-relative d-flex flex-column justify-content-center align-items-center"
                    >
                      <div className="spinner-border blueColor" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                      <p className="museo-sans-500 s14 mt-2">
                        <FormattedMessage id="invitation.loading.msg" />
                      </p>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  ""
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export function InvitationsListTableRowSkeleton(params) {
  return (
    <>
      <div
        className=" d-flex w-100 align-items-center justify-content-between "
        style={{ height: "100px" }}
      >
        <div className="d-flex flex-column gap-3">
          <div className="lightui1-shimmer rounded-3">
            <p className=" invisible">Maria Suarez</p>
          </div>
          <div className="d-flex gap-3">
            <div
              style={{ height: "24px", width: "24px" }}
              className="lightui1-shimmer rounded-circle "
            ></div>
            <div
              style={{ height: "24px", width: "24px" }}
              className="lightui1-shimmer rounded-circle "
            ></div>
            <div className="lightui1-shimmer rounded-3">
              <p className=" invisible">Maria Suarez</p>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column mx-4">
          <div
            style={{ gap: "80px" }}
            className="d-flex justify-content-between position-relative "
          >
            <div
              style={{ height: "4px", top: 8 }}
              className=" lightui1-shimmer w-100 position-absolute "
            ></div>
            <div
              style={{ height: "24px", width: "24px" }}
              className="lightui1-shimmer rounded-circle "
            ></div>
            <div
              style={{ height: "24px", width: "24px" }}
              className="lightui1-shimmer rounded-circle "
            ></div>
            <div
              style={{ height: "24px", width: "24px" }}
              className="lightui1-shimmer rounded-circle "
            ></div>
            <div
              style={{ height: "24px", width: "24px" }}
              className="lightui1-shimmer rounded-circle "
            ></div>
          </div>
          <div className="d-flex mt-3 gap-5 justify-content-between ">
            <div style={{ left: -20 }} className=" position-relative">
              <div className="lightui1-shimmer rounded-3">
                <p className=" s12 text-center invisible">Maria Maria</p>
              </div>
            </div>
            <div style={{ left: -5 }} className=" position-relative">
              <div className="lightui1-shimmer rounded-3">
                <p className=" s12 text-center invisible">Maria Maria</p>
              </div>
            </div>
            <div style={{ right: -5 }} className=" position-relative">
              <div className="lightui1-shimmer rounded-3">
                <p className=" s12 text-center invisible">Maria Maria</p>
              </div>
            </div>
            <div style={{ right: -20 }} className=" position-relative">
              <div className="lightui1-shimmer rounded-3">
                <p className=" s12 text-center invisible">Maria Maria</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
    </>
  );
}
