import { ReactComponent as SVGUser } from "../../../common/svgs/user-sm.svg";
import { ReactComponent as SVGShare } from "../../../common/svgs/ic-share-sm.svg";
import { FormattedMessage } from "react-intl";
import { useMediaPredicate } from "react-media-hook";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";

export default function AccountHeader({ profileData, loading }) {
  const smallerThanS1 = useMediaPredicate("(min-width: 1025px)");
  const directorId = localStorage.getItem("directorId");

  return (
    <div id="account-header" className="container--padding container--no-padding">
      <div className="position-relative w-100">
        <div
          style={{
            top: smallerThanS1 ? "-60px" : "-45px",
            left: "0px",
            width: smallerThanS1 ? "160px" : "140px",
            height: smallerThanS1 ? "160px" : "140px",
            alignItems: "center",
          }}
          className="outline-profile-picture position-absolute border-1 rounded-circle bg-transparent d-flex justify-content-center"
        >
          <ImageCircleHandler
            size={"42px"}
            width={smallerThanS1 ? "150px" : "130px"}
            height={smallerThanS1 ? "150px" : "130px"}
            url={!loading ? profileData.image : null}
            firstName={!loading ? profileData?.firstName : " "}
            lastName={!loading ? profileData?.lastName : " "}
            top={2}
          />
        </div>

        <div style={{ top: "-0px", left: "176px" }} className="position-relative mt-4">
          {loading ? (
            <p style={{ height: "16px", width: "355px" }} id="account-header--title" className="d-flex brightGrayBg brightGrayColor flex-wrap s30 museo-sans-700 rounded-2"></p>
          ) : (
            <p id="account-header--title" className="d-flex flex-wrap s30 museo-sans-700">{profileData?.lastName} {profileData?.firstName}</p>
          )}

          {loading && <div className="my-3"></div>}

          {loading ? (
            <div style={{ height: "12px", width: "270px" }} className="rounded-2 s14 museo-sans-500 d-flex brightGrayBg brightGrayColor">
              <p className="invisible">ID {localStorage.getItem("clientId")} |</p>
              <p className="invisible">a</p>
              <p className="invisible text-capitalize"><FormattedMessage id="account.header.region" /> {profileData.region}</p>
            </div>
          ) : (
            <div className="s14 museo-sans-500 d-flex">
              <p>ID {localStorage.getItem("clientId")} |</p>
              <p className="invisible">a</p>
              <p className="text-capitalize"><FormattedMessage id="account.header.region" /> {profileData.region}</p>
            </div>
          )}
        </div>
      </div>

      <div id="account-header--btn-holder" className="mt-2 gap-3 d-flex justify-content-center">
        {loading ? (
          <button
            data-bs-toggle="modal"
            data-bs-target="#modalShare"
            style={{ height: "40px" }}
            className="d-flex s14 justify-content-center museo-sans-700 skyColor brightGrayBg border-0 rounded"
          >
            <div className="invisible">
              <SVGShare />
              <p className="px-1"><FormattedMessage id="social.sharedata.title" /></p>
            </div>
          </button>
        ) : !directorId && (
          <button
            data-bs-toggle="modal"
            data-bs-target="#modalShare"
            style={{ height: "40px" }}
            className="d-flex s14 justify-content-center museo-sans-700 skyColor bg-transparent skyBorder rounded"
          >
            <SVGShare />
            <p className="px-1"><FormattedMessage id="social.sharedata.title" /></p>
          </button>
        )}

        {loading ? (
          <button
            data-bs-toggle="modal"
            data-bs-target="#modalShare"
            style={{ height: "40px" }}
            className="d-flex s14 justify-content-center museo-sans-700 skyColor brightGrayBg border-0 rounded"
          >
            <div className="invisible">
              <SVGShare />
              <p className="px-1"><FormattedMessage id="social.sharedata.title" /></p>
            </div>
          </button>
        ) : !directorId && (
          <button
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            style={{ height: "40px" }}
            className="d-flex s14 justify-content-center museo-sans-700 skyColor bg-transparent skyBorder rounded"
          >
            <SVGUser />
            <p className="px-1"><FormattedMessage id="social.viewdata" /></p>
          </button>
        )}
      </div>
    </div>
  );
}