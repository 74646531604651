import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as CloseIcon } from "../../../../../common/svgs/ic-x-sm.svg";
import { ReactComponent as ArrowRight } from "../../../../../common/svgs/ic-arrow-right-sm.svg";
import { defineStepNumber } from "../../../../Framework/redux/dashboardAction";
import { ReactComponent as SVGChevron } from "../../../../../common/svgs/c-chevron-right-sm.svg";
import { FormattedMessage } from "react-intl";
import ProgressBarParams from "../../../../../common/Share/ProgressBarParams";

export default function ThirdStepModal({ removeBlur }) {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.userInfo.userInfo);

  const onCancelBlur = () => {
    removeBlur();
    localStorage.removeItem("firstLogin");
    dispatch(defineStepNumber(20));
  };

  const onCancelOnlyBlur = () => {
    removeBlur();
    dispatch(defineStepNumber(20));
  };

  return (
    <div
      className="modal fade vh-100 vw-100"
      id="thirdStepModal"
      data-bs-backdrop="static"
      aria-hidden="true"
      aria-labelledby="thirdStepModal"
      tabIndex="-1"
    >
      <DoppelgangerPersonalSales />
      <div id="module-three--modal" className="modal-dialog m-0 position-fixed">
        <div
          className="modal-content h-100"
          style={{ padding: "25px", borderRadius: "15px" }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="museo-sans-500 s12 grayColor">
                {profileData.level < 2 ? "PASO 2 DE 4" : "PASO 3 DE 5"}
              </p>
              <div
                className=" pointer "
                data-bs-dismiss="modal"
                onClick={() => onCancelOnlyBlur()}
                aria-label="Close"
              >
                <CloseIcon />
              </div>
            </div>
            <p className="museo-sans-700 s20 blackColor">
              <FormattedMessage id="on.boarding.third.title.one" />
            </p>
            <div className="mt-2" style={{ lineHeight: "18px" }}>
              <p
                className="s14 museo-sans-500 blackColor"
                style={{ width: "272px" }}
              >
                <FormattedMessage id="on.boarding.third.p.one" />
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between mt-auto">
            <a
              onClick={() => onCancelBlur()}
              data-bs-dismiss="modal"
              aria-label="Close"
              role="button"
              className="museo-sans-700 s14 skyColor button-hover-light-text"
            >
              <FormattedMessage id="buttons.not.show.ever" />
            </a>

            <div
              className="d-flex hover-pointer align-items-center gap-2 button-hover-light-text button-hover-svg-light-stroke"
              data-bs-target="#fourthStepModal"
              data-bs-toggle="modal"
            >
              <p className=" text-capitalize museo-sans-700 s14 skyColor button-hover-light-text">
                <FormattedMessage id="buttons.follow" />
              </p>
              <ArrowRight />
            </div>
          </div>
        </div>
        <div style={{ left: "-25px" }} className="modal-arrow "></div>
      </div>
    </div>
  );
}

export function DoppelgangerPersonalSales(params) {
  const scoreData = useSelector((state) => state.scoreData);
  const profileData = useSelector((state) => state.userInfo);

  return (
    <div className=" h-100 w-100 position-absolute container--padding ">
      <div className=" position-relative">
        <div
          id="module-three--personal-sale"
          className=" whiteBg rounded-3 p-1  "
        >
          <div
            style={{ top: "0px", left: "0px" }}
            className=" position-absolute w-100 h-100 whiteBg pulsing  "
          ></div>
          <div className=" position-relative rounded-3 gap-2 px-2 pe-0 flex-column  d-flex ">
            <div className=" w-100 m-0 d-flex justify-content-between align-items-center ">
              <div className=" d-flex">
                <p className=" s14 blackColor museo-sans-500">
                  <FormattedMessage id="nav.label.one.a" />
                </p>
              </div>
              <div className=" s14 d-flex dm-sans-medium gap-1">
                <p className=" ">
                  <FormattedMessage id="currency" />
                  {scoreData?.personalSales?.actualAmount?.toLocaleString()}
                </p>
                <p className=" grayColor">/</p>
                <p className=" grayColor">
                  <FormattedMessage id="currency" />{" "}
                  {scoreData?.personalSales?.totalAmount?.toLocaleString()}
                </p>
                <p className=" pointer ps-1">
                  <SVGChevron />
                </p>
              </div>
            </div>
            <div>
              <ProgressBarParams
              height={12}
                status={scoreData.consultantStatus.checkMarkStatus}
                incompleted={
                  scoreData?.personalSales?.actualAmount <
                  scoreData?.personalSales?.totalAmount
                }
                gap={false}
                completed={
                  (scoreData?.personalSales?.actualAmount * 100) /
                  scoreData?.personalSales?.totalAmount
                }
                level={profileData.userInfo.level}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
