import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import { useRef, useCallback, useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { formatAmount } from "../../../../common/Share/FormatCurrency";
import ReactToPrint from "react-to-print";

import { ReactComponent as SVGClose } from "../../../../common/svgs/ic-x-lg.svg";
import { ReactComponent as SVGShare } from "../../../../common/svgs/ic-share-md.svg";
import { ReactComponent as SVGPrint } from "../../../../common/svgs/ic-print-md.svg";
import { ReactComponent as SVGEDB } from "../../../../common/svgs/ic-pedido-edb-sm.svg";
import HandleSwissDates from "../../../../common/Share/HandleSwissDate";
import PersonalSalesModalOrderEDBRow from "./PersonalSalesModalOrderEDBRow";
import PersonalSalesModalShareOrderEDB from "./PersonalSalesModalShareOrderEDB";


export default function PersonalSalesModalOrderEDB({ open, socialArray }) {
  const componentRef = useRef();

  const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)
  
  const [hideOnPrint, setHideOnPrint] = useState(false);
  const [onShare, setOnShare] = useState(false);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const data = useSelector(
    (state) => state.performanceData.orderDetailsModalData
  );
  useEffect(() => {
    onClosure();
  }, [data]);

  const onClickShare = () => {
    setOnShare(true);
  };

  const onClosure = () => {
    setOnShare(false);
  };

  const onPrint = async () => {
    await setHideOnPrint(true);
  };

  const afterOnPrint = () => {
    setHideOnPrint(false);
  };

  return (
    <>
      <div
        className="modal fade"
        id="modalDetailsOrderEDB"
        aria-hidden="true"
        aria-labelledby="modalDetailsOrderEDB"
        tabIndex="-1"
      >
        <div
          className="modal-dialog modal-dialog-centered"
          style={{ maxWidth: "610px" }}
        >
          <div
            className={`modal-content ${
              onShare && "bg-transparent border-0 "
            } modal-radius`}
          >
            <div className=" modal-body p-0">
              <div>
                {onShare && (
                  <PersonalSalesModalShareOrderEDB
                    closeSubModal={onClosure}
                    open={open}
                    socialArray={socialArray}
                    data={data}
                  />
                )}
                <div
                  className={`d-flex align-items-center ${
                    onShare && "opacity-0"
                  } justify-content-between py-4 mx-3 px-4`}
                >
                  <div className="d-flex flex-column">
                    <div className=" s12 grayColor museo-sans-500 align-items-center d-flex gap-2">
                      <p>
                        <FormattedMessage id="edb" />
                      </p>
                      <SVGEDB />
                    </div>
                    <h5 className="museo-sans-500 s20 m-0">
                      <FormattedMessage id="edb.details.params.host" />{" "}
                      {data ? data.hostName : "Martina Duarte"}
                    </h5>
                    <div className=" dm-sans-medium s12 py-2 d-flex gap-1">
                      <p>
                        {data ? (
                          <HandleSwissDates date={data?.date} />
                        ) : (
                          "dd-mm-yy"
                        )}
                      </p>
                      <p>|</p>
                      <p>
                        {" "}
                        <FormattedMessage id="personal.sale.details.orden.id" />{" "}
                        <span>{data ? data.edbId : "XXXXX"}</span>
                      </p>
                    </div>
                  </div>
                  <div className=" align-items-center d-flex gap-3 pointer ">
                    <div onClick={onClickShare}>
                      <SVGShare />
                    </div>
                    <ReactToPrint
                      content={reactToPrintContent}
                      removeAfterPrint
                      onBeforeGetContent={onPrint}
                      onAfterPrint={afterOnPrint}
                      trigger={() => {
                        return (
                          <div>
                            <SVGPrint />
                          </div>
                        );
                      }}
                      documentTitle="Details"
                      pageStyle="print"
                    />

                    <div data-bs-dismiss="modal" aria-label="Close">
                      <SVGClose />
                    </div>
                  </div>
                </div>
                <hr className="m-0 "></hr>
                <div
                  style={{ maxHeight: "372px" }}
                  className={` ${
                    onShare && "opacity-0"
                  } hidden-y-scroll d-flex flex-column align-items-center px-3 `}
                >
                  <div className="my-4 w-100 px-4">
                    <div className="d-flex justify-content-between">
                      <h3 className="m-0 museo-sans-700 s16">
                        <FormattedMessage id="personal.sale.modal.products.count" />
                        :{" "}
                        <span className="dm-sans-bold">
                          {" "}
                          {data && data.totalProducts}{" "}
                        </span>
                      </h3>
                    </div>
                    <>
                      {data?.attendants?.map((attend, index) => {
                        return (
                          <PersonalSalesModalOrderEDBRow
                            key={`${attend.name}-${index}`}
                            hideOnPrint={hideOnPrint}
                            attend={attend}
                          />
                        );
                      })}
                    </>
                    <div className=" my-4 pe-4 grayColor s14  museo-sans-500 d-flex justify-content-between">
                      <p>
                        <FormattedMessage id="personal.sale.details.products.sub.total" />
                      </p>
                      <p className=" dm-sans-medium">
                      {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                      </p>
                    </div>

                    <hr className="" />
                    <div className=" pe-4">
                      <div className=" museo-sans-700 d-flex justify-content-between">
                        <h2 className=" s16">
                          <FormattedMessage id="personal.sale.details.taxes" />
                        </h2>
                      </div>
                      {data &&
                        data.totalAmountDetail.map((detail) => {
                          return (
                            <div className=" my-3 grayColor s14  museo-sans-500 d-flex justify-content-between">
                              <p>{detail.description}</p>
                              <p>{formatAmount(detail?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}</p>
                            </div>
                          );
                        })}
                    </div>
                    <hr className=" " />
                    <div className=" pe-4 museo-sans-700 ">
                      <div className=" blackColor s16 my-2 d-flex justify-content-between">
                        <p>
                          <FormattedMessage id="personal.sale.details.sale.total" />
                        </p>
                        <p className=" dm-sans-bold ">
                        {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                          
                        </p>
                      </div>
                      <div className=" s14 my-2 d-flex justify-content-between grayColor">
                        <p>
                          <FormattedMessage id="personal.sale.details.sale.total.pvp" />
                        </p>
                        <p className=" dm-sans-medium">
                        {data && formatAmount(data?.totalPVPAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hideOnPrint && (
        <div ref={componentRef}>
        <PersonalSalesModalOrderEDBTargetOnPrint onShare={onShare} onClosure={onClosure} open={open} socialArray={socialArray} onClickShare={onClickShare} afterOnPrint={afterOnPrint} onPrint={onPrint} reactToPrintContent={reactToPrintContent} data={data} hideOnPrint={hideOnPrint} />
      </div>
      )}
    </>
  );
}

export function PersonalSalesModalOrderEDBTargetOnPrint({onShare, onClosure, open, socialArray, data, hideOnPrint, reactToPrintContent, onPrint, afterOnPrint, onClickShare}) {
  const cultureInfo = useSelector((state)=> state.userInfo.userInfo.cultureInfo)
  return(
    <>
        {onShare && (
          <PersonalSalesModalShareOrderEDB
            closeSubModal={onClosure}
            open={open}
            socialArray={socialArray}
            data={data}
          />
        )}
        <div
          className={`d-flex align-items-center ${
            onShare && "opacity-0"
          } justify-content-between py-4 mx-3 px-4`}
        >
          <div className="d-flex flex-column">
            <div className=" s12 grayColor museo-sans-500 align-items-center d-flex gap-2">
              <p>EDB</p>
              <SVGEDB />
            </div>
            <h5 className="museo-sans-500 s20 m-0">
              <FormattedMessage id="edb.details.params.host" />{" "}
              {data ? data.hostName : "Martina Duarte"}
            </h5>
            <div className=" dm-sans-medium s12 py-2 d-flex gap-1">
              <p>
                {data ? (
                  <HandleSwissDates date={data?.date} />
                ) : (
                  "dd-mm-yy"
                )}
              </p>
              <p>|</p>
              <p>
                {" "}
                <FormattedMessage id="personal.sale.details.orden.id" />{" "}
                <span>{data ? data.edbId : "XXXXX"}</span>
              </p>
            </div>
          </div>
          {
            !hideOnPrint &&
            <div className=" align-items-center d-flex gap-3 pointer ">
              <div onClick={onClickShare}>
                <SVGShare />
              </div>
              <ReactToPrint
                content={reactToPrintContent}
                removeAfterPrint
                onBeforeGetContent={onPrint}
                onAfterPrint={afterOnPrint}
                trigger={() => {
                  return (
                    <div>
                      <SVGPrint />
                    </div>
                  );
                }}
                documentTitle="Details"
                pageStyle="print"
              />

              <div data-bs-dismiss="modal" aria-label="Close">
                <SVGClose />
              </div>
            </div>

          }
        </div>
        <hr className="   m-0"></hr>
        <div
          className={` ${
            onShare && "opacity-0"
          } hidden-y-scroll d-flex flex-column align-items-center px-3 `}
        >
          <div className="my-4 w-100 px-4">
            <div className="d-flex justify-content-between">
              <h3 className="m-0 museo-sans-700 s16">
                <FormattedMessage id="personal.sale.modal.products.count" />
                :{" "}
                <span className="dm-sans-bold">
                  {" "}
                  {data && data.totalProducts}{" "}
                </span>
              </h3>
            </div>
            <>
              {data?.attendants?.map((attend, index) => {
                return (
                  <PersonalSalesModalOrderEDBRow
                    key={`${attend.name}-${index}`}
                    hideOnPrint={hideOnPrint}
                    attend={attend}
                  />
                );
              })}
            </>
            <div className=" my-4 pe-4 grayColor s14  museo-sans-500 d-flex justify-content-between">
              <p>
                <FormattedMessage id="personal.sale.details.products.sub.total" />
              </p>
              <p className=" dm-sans-medium">
              {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)}  
              </p>
            </div>

            <hr className=" " />
            <div className=" pe-4">
              <div className=" museo-sans-700 d-flex justify-content-between">
                <h2 className=" s16">
                  <FormattedMessage id="personal.sale.details.taxes" />
                </h2>
              </div>
              {data &&
                data.totalAmountDetail.map((detail) => {
                  return (
                    <div className=" my-3 grayColor s14  museo-sans-500 d-flex justify-content-between">
                      <p>{detail.description}</p>
                      <p>
                      {data && formatAmount(detail?.amount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                      </p>
                    </div>
                  );
                })}
            </div>
            <hr className=" " />
            <div className=" pe-4 museo-sans-700 ">
              <div className=" blackColor s16 my-2 d-flex justify-content-between">
                <p>
                  <FormattedMessage id="personal.sale.details.sale.total" />
                </p>
                <p className=" dm-sans-bold ">
                {data && formatAmount(data?.totalAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                </p>
              </div>
              <div className=" s14 my-2 d-flex justify-content-between grayColor">
                <p>
                  <FormattedMessage id="personal.sale.details.sale.total.pvp" />
                </p>
                <p className=" dm-sans-medium">
                {data && formatAmount(data?.totalPVPAmount,cultureInfo?.languageCode, cultureInfo?.defaultCurrencyCode)} 
                </p>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

PersonalSalesModalOrderEDB.propTypes = {
  open: PropTypes.bool.isRequired,
  socialArray: PropTypes.array.isRequired,
};