import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import { useNavigate } from "react-router-dom";
import { fetchAssociateSms } from "../../Framework/redux/loginSideEffects";
import Loader from "../../../common/Share/Loader";
import {
  parsePhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
  isValidNumber,
} from "libphonenumber-js";
import { PhoneNumberUtil } from "google-libphonenumber";
import RegisterAnalytics from "../../Framework/ga/RegisterAnalytics";
import SharePhoneMaxLengthByCountry from "../../../common/Share/SharePhoneMaxLengthByCountry";

export default function LoginNumber() {
  const dispatch = useDispatch();
  const history = useNavigate();

  const loading = useSelector((state) => state.userInfo.loader);

  const [codeRegion, setCodeRegion] = useState("");
  const [phone, setPhone] = useState("");
  const [errorHandler, setErrorHandler] = useState(false);
  const [errorValue, setErrorValue] = useState("");

  const numbers = {
    AR: "+549",
    CL: "+56",
    CO: "+57",
    CR: "+506",
    MX: "+52", 
    PA: "+507",
    PE: "+51",
    UY: "+598",
  };

  const onSent = (associate) => {
    const response = dispatch(fetchAssociateSms(associate));
    return response;
  };

  const resolveSent = async (evt) => {
    evt.preventDefault();
    let country = localStorage.getItem("country");

    if (phone.length == 0) {
      setErrorHandler(true);
      setErrorValue("wrong");
      return;

    }
    if (phone.length < 2) {
        setErrorHandler(true);
        setErrorValue("wrong");
        return;
      
    }
    if (!onlyNumbers(codeRegion) || !onlyNumbers(phone)) {
      setErrorHandler(true);
      setErrorValue("wrong");
      return;
    }
    let associate = codeRegion.concat(phone);
    localStorage.setItem("tel", associate);
    
    
    if (associate.length < 4) {
      setErrorHandler(true);
      setErrorValue("wrong");
      return;
    }

    const phoneNumber = parsePhoneNumber(associate);

    if (
      validatePhoneNumberLength(phoneNumber.number, phoneNumber.country) ==
      "TOO_SHORT"
    ) {
      setErrorHandler(true);
      setErrorValue("wrong");
      return;
    }

    if (!isPossiblePhoneNumber(phoneNumber.number, phoneNumber.country)) {
      setErrorHandler(true);
      setErrorValue("wrong");
      return;
    }

    if (!isValidPhoneNumber(phoneNumber.number, phoneNumber.country)) {
      setErrorHandler(true);
      setErrorValue("wrong");
      return;
    }

    if (!isValidNumber(phoneNumber.number)) {
      setErrorHandler(true);
      setErrorValue("wrong");
      return;
    }

    RegisterAnalytics("send-code")

    const phoneUtil= PhoneNumberUtil.getInstance();
    let number = phoneUtil.parseAndKeepRawInput(phoneNumber.number, country);
    if(phoneUtil.getNumberType(number)!= 1 && phoneUtil.getNumberType(number)!= 2 ){
    setErrorHandler(true);
    setErrorValue("wrong");
    return;
   }
    const resolve = await onSent(associate);
    if (typeof resolve.payload === "object") {
      localStorage.setItem("authenticatorCode", resolve.payload.authenticatorCode);
      history("/confirm");
    }
  };

  const onlyNumbers = (string) => {
    return /^[0-9+]+$/.test(string);
  };

  const onChangePhone = (evt) => {
    setPhone(evt.target.value);
    setErrorHandler(false);
  };

  const switchError = () => {
    const errorMessages = {
      wrong: "number.format.error",
      valid: "login.error.phone.possible.phone",
      short: "login.error.phone.too.short",
      empty: "login.error.phone.empty",
      default: "login.error.phone",
    };
    
    const errorMessageId = errorMessages[errorValue] || errorMessages.default;
    
    return (
      <small className="text-danger">
        {" "}
        <FormattedMessage
          id={errorMessageId}
          defaultMessage="Solo se admiten caracteres numéricos"
        />{" "}
      </small>
    );
    
  };

  const handleKeyDown = (e) => {
    // Bloquear la entrada de 'e', 'E', '+', y '-'
      if (["e", "E", "+", "-"].includes(e.key)) {
        e.preventDefault();
      }
  };

  useEffect(() => {
    let country = localStorage.getItem("country");

    const countryCodes = {
      ar: numbers.AR,
      cl: numbers.CL,
      cr: numbers.CR,
      co: numbers.CO,
      mx: numbers.MX,
      pa: numbers.PA,
      pe: numbers.PE,
      uy: numbers.UY,
    };
    
    if (countryCodes.hasOwnProperty(country)) {
      setCodeRegion(countryCodes[country]);
    }
  }, []);

  return (
    <div id="login-layout" className=" container--padding py-5">
      <div className="">
        <h1 className="mt-3 s12 museo-sans-500 grayColor text-uppercase">
          <FormattedMessage
            id="login.phone.page.title"
            defaultMessage="1. Profile settings"
          />
        </h1>
        <hr></hr>
        <h2 className="s24 museo-sans-700 mt-4">
          <FormattedMessage
            id="login.phone.page.subtitle"
            defaultMessage="Profile picture"
          />
        </h2>
        <p className="s16  museo-sans-500">
          <FormattedMessage
            id="login.phone.page.comment"
            defaultMessage="Before starting the website, please upload a profile picture that shows your face."
          />
        </p>

        <div
          id="login--phone-container"
          className=" s14 dm-sans-bold blackColor position-relative mt-4 d-flex"
        >
          <div className="position-absolute h-100  " style={{width: "80px"}}></div>
          <input
            id="login--phone-zone"
            value={codeRegion}
            placeholder="XXXX"
            style={{ borderColor: "#8889DB", borderRadius: "6px"  }}
            className=" input-borders input-disabled d-flex justify-content-center px-3 s14  "
            maxLength={4}
            type="text"
          />

          <input
            onChange={onChangePhone}
            onKeyDown={handleKeyDown}
            style={{ borderColor: "#8889DB" }}
            id="login--phone-number"
            placeholder={"XXX" + " " + "XX" + " " + "XX"}
            maxLength={SharePhoneMaxLengthByCountry()}
            className="form-control blackColor mx-4 s14 "
            type="number"
          />
        </div>
        <div className="mt-3"></div>
        {errorHandler && (
          <>
            <i className="text-danger bi bi-x-circle-fill"></i>
            <small className="text-danger">
              {" "}
              {switchError()}
              {" "}
            </small>
          </>
        )}
      </div>
      <div className="align-self-end btnLoginWidth justify-items-center position-relative">
          <button
            onClick={resolveSent}
            className=" btnLoginWidth position-relative s14 w600 px-4 py-2 text-white btnCustom skyBg"
          >
            <Loader loading={loading} />
            <FormattedMessage
              id="login.phone.submit"
              defaultMessage="Continue"
            />
          </button>
      </div>
    </div>
  );
}
