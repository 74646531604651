import { createAction } from '@reduxjs/toolkit';

const changePersonalGroupName = createAction('changePersonalGroupName') 

const removeXAmountArray = createAction('removeXAmountArray')
const pushInsideArray = createAction('pushInsideArray')
const clearInsideArray = createAction('clearInsideArray')

const cleanMyData = createAction('cleanMyData')

const defineDescendantModalUserInfo = createAction('defineDescendantModalUserInfo')

const defineModalUserSalesTab = createAction('defineModalUserSalesTab')

const clearExtras = createAction('clearExtras');

export  {removeXAmountArray, clearExtras, cleanMyData, changePersonalGroupName, defineDescendantModalUserInfo, defineModalUserSalesTab, clearInsideArray,  pushInsideArray} 