export default function DescendantLine({ index, end, gen, desc,short }) {
  return (
    <>
      {" "}
      {end ? (
        <>
          {" "}
          <div style={{maxHeight: "90px"}} className="desc--grid-container-line position-relative d-flex justify-content-center">
            {" "}
            {end ? (
              <>
                {" "}
                {gen > 0 && (
                  <div
                    style={{
                      height: "30px",
                      width: "1px",
                      top: "-20px",
                      left: "49%",
                    }}
                    className=" position-absolute skyBg"
                  >
                    {" "}
                    <p className=" invisible">.</p>{" "}
                  </div>
                )}
                <div
                  style={{ width: "1px",height: short ? "89%" : null }}
                  className=" desc--grid-collapse-line-end skyBg"
                >
                  {" "}
                  <p className=" invisible">.</p>{" "}
                </div>{" "}
              </>
            ) : (
              <>
                {" "}
                {gen > 0 ? (
                  <>
                    {" "}
                    <div
                      style={{ width: "1px", left: "8px", height: "59px" }}
                      className=" position-absolute desc--grid-collapse-line skyBg"
                    >
                      {" "}
                      <p className=" invisible">.</p>{" "}
                    </div>{" "}
                  </>
                ) : (
                  <> </>
                )}
                <div
                  style={{ width: "1px" }}
                  className=" desc--grid-collapse-line skyBg"
                >
                  {" "}
                  <p className=" invisible">.</p>{" "}
                </div>{" "}
              </>
            )}
            <>
                  {
                    desc ?
                    <>
                      <div
              style={{ width: "40%", height: "1px", top: "46px", left: "50%" }}
              className=" position-absolute skyBg"
            >
              {" "}
              <p className=" invisible">.</p>{" "}
            </div>
                    </>
                    :
                    <>
                      <div
              style={{  width: "125%", height: "1px", top: "46px", left: "50%" }}
              className=" position-absolute skyBg"
            >
              {" "}
              <p className=" invisible">.</p>{" "}
            </div>
                    </>
                  }
            </>
          </div>{" "}
        </>
      ) : (
        <>
          {" "}
          <div className="desc--grid-container-line  position-relative d-flex justify-content-center ">
            {" "}
            {end ? (
              <div
                style={{ width: "1px" }}
                className=" desc--grid-collapse-line-end skyBg"
              >
                {" "}
                <p className=" invisible">.</p>{" "}
              </div>
            ) : (
              <>
                {" "}
                <div
                  style={{ width: "1px" }}
                  className=" desc--grid-collapse-line skyBg"
                >
                  {" "}
                  <p className=" invisible">.</p>{" "}
                </div>{" "}
              </>
            )}
            {index == 1 ? (
              <>
                {" "}
                {gen > 0 && (
                  <div
                    style={{
                      height: "30px",
                      width: "1px",
                      top: "-20px",
                      left: "49%",
                    }}
                    className=" position-absolute skyBg"
                  >
                    {" "}
                    <p className=" invisible">.</p>{" "}
                  </div>
                )}
                <>
                  {
                    desc ?
                    <>
                      <div
              style={{ width: "40%", height: "1px", top: "46px", left: "50%" }}
              className=" position-absolute skyBg"
            >
              {" "}
              <p className=" invisible">.</p>{" "}
            </div>
                    </>
                    :
                    <>
                      <div
              style={{ width: "125%",  height: "1px", top: "46px", left: "50%" }}
              className=" position-absolute skyBg"
            >
              {" "}
              <p className=" invisible">.</p>{" "}
            </div>
                    </>
                  }
            </>
              </>
            ) : (
              <>
                  {
                    desc ?
                    <>
                      <div
              style={{ width: "40%", height: "1px", top: "46px", left: "50%" }}
              className=" position-absolute skyBg"
            >
              {" "}
              <p className=" invisible">.</p>{" "}
            </div>
                    </>
                    :
                    <>
                      <div
              style={{ width: "125%", height: "1px", top: "46px", left: "50%" }}
              className=" position-absolute skyBg"
            >
              {" "}
              <p className=" invisible">.</p>{" "}
            </div>
                    </>
                  }
            </>
            )}
          </div>{" "}
        </>
      )}
    </>
  );
}
