import { FormattedMessage } from "react-intl";

import { ReactComponent as SVGRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import AlertModalSlide from "./AlertModalSlide";

export default function AlertModalSwitchButtonHolder({
  type,
  onAccept,
  slidesData,
  slides,
  setSlides,
}) {
  const onContinue = () => {
    setSlides(slides + 1);
  };

  const caseFourNThree = (type) => {
    if (type == 3) {
      return (
        <div
          onClick={onAccept}
          data-bs-dismiss="modal"
          aria-label="Close"
          className=" s14 pointer justify-content-end w-100 align-items-center d-flex gap-2 museo-sans-700 skyColor"
        >
          <FormattedMessage id="buttons.accept" defaultMessage="Entendido" />
        </div>
      );
    }

    return (
      <div className=" w-100">
        <div
          onClick={() => onAccept("/dashboard")}
          data-bs-dismiss="modal"
          aria-label="Close"
          style={{ right: "47px" }}
          className=" position-absolute s14 pointer justify-content-end align-items-center d-flex gap-2 museo-sans-700 skyColor"
        >
          <FormattedMessage
            id="buttons.alert.new.dashboard"
            defaultMessage="Entendido"
          />
        </div>
      </div>
    );
  };

  const switchButtonHolder = (type) => {
    switch (type) {
      case 29:
      case 20:
      case 19:
        return (
          <div className=" align-items-center d-flex flex-column gap-3 my-5 ">
            <button
              type="button"
              className=" btnLoginWidth museo-sans-700 s14 skyBg whiteColor skyBorder  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.understood"
                defaultMessage="Entendido"
              />
            </button>
          </div>
        );
      case 17:
        return (
          <div className=" my-5 ">
            <button
              type="button"
              className=" btnLoginWidth museo-sans-700 s14 whiteColor skyBorder skyBg  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.continue"
                defaultMessage="Entendido"
              />
            </button>
          </div>
        );
      case 6:
      case 7:
        return (
          <div className=" align-items-center d-flex flex-column gap-3 my-5 ">
            <button
              type="button"
              onClick={() => onAccept("/dashboard/businessplan")}
              className=" btnLoginWidth museo-sans-700 s14 skyBg whiteColor skyBorder  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.bussiness.plan"
                defaultMessage="Entendido"
              />
            </button>
            <button
              type="button"
              className=" btnLoginWidth museo-sans-700 s14 bg-white skyColor skyBorder  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.understood"
                defaultMessage="Entendido"
              />
            </button>
          </div>
        );
      case 48:
        return (
          <div className=" align-items-center d-flex flex-column gap-3 my-5 ">
            <button
              type="button"
              onClick={() => onAccept("/dashboard/earning/period")}
              className=" btnLoginWidth museo-sans-700 s14 skyBg whiteColor skyBorder  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.earnings"
                defaultMessage="Entendido"
              />
            </button>
            <button
              type="button"
              className=" btnLoginWidth museo-sans-700 s14 bg-white skyColor skyBorder  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.understood"
                defaultMessage="Entendido"
              />
            </button>
          </div>
        );
      case 5:
        return (
          <div className=" d-flex align-items-center flex-column gap-3 my-5 ">
            <button
              type="button"
              onClick={() => onAccept("/dashboard/businessplan")}
              className=" btnLoginWidth museo-sans-700 s14 skyBg whiteColor skyBorder  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.bussiness.plan"
                defaultMessage="Entendido"
              />
            </button>
            <button
              type="button"
              className=" btnLoginWidth museo-sans-700 s14 bg-white skyColor skyBorder  button-hover-light-text button-bootstrap-radius button-padding"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <FormattedMessage
                id="buttons.understood"
                defaultMessage="Entendido"
              />
            </button>
          </div>
        );
      case 4:
      case 3:
        return (
          <div className=" px-5 p-3 w-100 d-flex justify-content-between">
            <div className=" w-100  invisible">.</div>
            <AlertModalSlide data={slidesData} slides={slides} />
            {slidesData.slidesData.slide2_1 ? (
              slides !== 3 ? (
                <div
                  onClick={onContinue}
                  className=" s14 pointer justify-content-end w-100 align-items-center d-flex gap-2 museo-sans-700 skyColor"
                >
                  <FormattedMessage
                    id="buttons.continue"
                    defaultMessage="Entendido"
                  />
                  <SVGRightArrow />
                </div>
              ) : (
                caseFourNThree(type)
              )
            ) : slides !== 2 ? (
              <div
                onClick={onContinue}
                className=" s14 pointer justify-content-end w-100 align-items-center d-flex gap-2 museo-sans-700 skyColor"
              >
                <FormattedMessage
                  id="buttons.continue"
                  defaultMessage="Entendido"
                />
                <SVGRightArrow />
              </div>
            ) : (
              <div
                onClick={onAccept}
                data-bs-dismiss="modal"
                aria-label="Close"
                className=" s14 pointer justify-content-end w-100 align-items-center d-flex gap-2 museo-sans-700 skyColor"
              >
                <FormattedMessage
                  id="buttons.accept"
                  defaultMessage="Entendido"
                />
              </div>
            )}
          </div>
        );
    }
  };

  return switchButtonHolder(type);
}
