import { ReactComponent as SVGTailRightArrow } from "../../../common/svgs/ic-arrow-right-sm.svg";
import GoodPracticeNextLevel from "../../../GoodPractice/Presentation/components/GoodPracticeNextLevel";

import { ReactComponent as Red } from "../../../common/svgs/ic-alert-circle-red.svg";

import { ReactComponent as Yellow } from "../../../common/svgs/ic-alert-circle-yellow.svg";

import { ReactComponent as Green } from "../../../common/svgs/ic-check-circle.svg";

import { ReactComponent as Purple } from "../../../common/svgs/ic-req-red.svg";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import HelpIconNoTarget from "../../../common/Share/HelpIconNoTarget";
import SkeletonAccountPlan from "../../../common/Share/SkeletonAccountPlan";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";

export default function AccountPlan({ 
  scoreData,
  profileData,
  scoreQualifiedGroups,
  account,
  status,
  directorId,
  loading
}) {

  const history = useNavigate();

  const onClickNav = () => {
    history("/dashboard/businessplan");
  }

  return (
    <div id="account-plan--container" >
      <div className="d-flex museo-sans-500 s20">
        <p>
          <FormattedMessage id="plan.business.title" />
          </p>
        <i
          data-bs-toggle="modal"
          data-bs-target="#modalInfoPlan"
          className=" pointer d-flex px-2 "
        >
          <HelpIconNoTarget />
        </i>
      </div>
      <div
        id="account-plan-grid"
        className=" p-4 mt-3 account--card-shadow bg-white f9GrayBorder rounded-account-cards"
        style={{ height: "650px" }}
      >
        {
          loading ?
            <SkeletonAccountPlan />
          :
          <>
            <div>
          {profileData && profileData.level === "8" ? (
            <>
              <div className=" d-flex ">
                <h4 className="s20 museo-sans-700 m-0">
                   <ReformatLevelByGender gender={profileData?.gender} level={profileData?.level} roleDescriptionDefault={profileData?.roleDescription} />
                </h4>
                <p className=" invisible">a</p>
                <div style={{ width: "16px", height: "16px" }}>
                  {status === "reduced" && <Purple />}
                  {(status === "risk") && <Red />}
                  {status === "completed" && <Green />}
                  {(status === "warning") && <Yellow />}
                </div>
              </div>
              <p className="mt-2 s14 museo-sans-500">
                <FormattedMessage id="plan.business.p.end" />
              </p>
            </>
          ) : (
            <>
              <div>
                <div className=" museo-sans-700 s20 d-flex">
                  <p style={{ maxWidth: "170px"}} >
                   <ReformatLevelByGender gender={profileData?.gender} level={profileData?.level} roleDescriptionDefault={profileData?.roleDescription} />
                  </p>
                  <p className=" invisible">a</p>
                  <div style={{ width: "16px", height: "16px" }}>
                  {status === "reduced" && <Purple />}
                  {(status === "risk") && <Red />}
                  {status === "completed" && <Green />}
                  {status === "warning" && <Yellow />}
                </div>
                </div>
                {
                  profileData.level < 2 ?
                  <p className="s14 mt-2 museo-sans-500">
                  <FormattedMessage id="plan.business.p.continue.b" />
                </p>
                  :
                    <p className="s14 mt-2 museo-sans-500">
                      <FormattedMessage id="plan.business.p.continue" />
                    </p>
                }
              </div>
              <hr></hr>
              <div>
                <GoodPracticeNextLevel
                  scoreData={scoreData}
                  scoreQualifiedGroups={scoreQualifiedGroups}
                  profileData={profileData}
                  toggler={account}
                />
              </div>
            </>
          )}
        </div>
        {
          !directorId &&
          <div
            onClick={onClickNav}
            style={{ alignSelf: "center", alignItems: "center" }}
            className=" pointer d-flex museo-sans-700 skyColor s14"
          >
            <p>
              <FormattedMessage id="plan.business.more.link" />
              <span className=" px-1" >
                <SVGTailRightArrow />
              </span>
            </p>
          </div>
        }
          </>
        }
      </div>
    </div>
  );
}
