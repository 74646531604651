import { createAction } from '@reduxjs/toolkit';

const changePeriodForButton = createAction('changePeriodForButton')

const resetOrders = createAction('resetOrders')

const upgradeGroupSalesOffset = createAction('upgradeGroupSalesOffset')
const resetGroupSalesOffset = createAction('resetGroupSalesOffset')

const upgradeIncorporatedSalesOffset = createAction('upgradeIncorporatedSalesOffset')
const resetIncorporatedSalesOffset = createAction('resetIncorporatedSalesOffset')

const upgradeDescendantSalesOffset = createAction('upgradeDescendantSalesOffset')
const resetDescendantSalesOffset = createAction('resetDescendantSalesOffset')

const resetFilters = createAction('resetFilters')
const changeFilters = createAction('changeFilters')
const defineFiltersApplied = createAction('defineFiltersApplied')

const resetOtherConsultantData = createAction('resetOtherConsultantData')

const numerito = createAction('numerito');

const changeCreditNotes = createAction("changeCreditNotes")


export {changeCreditNotes , changePeriodForButton, upgradeGroupSalesOffset, resetGroupSalesOffset,numerito, upgradeIncorporatedSalesOffset, resetIncorporatedSalesOffset, upgradeDescendantSalesOffset, resetDescendantSalesOffset, resetOrders, resetFilters, changeFilters, defineFiltersApplied, resetOtherConsultantData}