import React from 'react'

export default function NoRegistersMessage({icon, title, description}) {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center no-edbs-width mt-5'>
            {
                icon ?
                    <div className='rounded-circle'>
                        {icon}
                    </div>
                :
                    null
            }
            <p className='museo-sans-500 s24'>{title}</p>
            <p className='museo-sans-500 s14 grayColor text-center performance-no-results-text'>{description}</p>
        </div>
    )
}
