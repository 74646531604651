export default function SharePlaceholderPhone() {
    
    let country = localStorage.getItem("country");

    
    const placeHoldeCountry = {
        ar: "Ej.: 1123456789",
        cr: "Ej.: 61234567",
        pa: "Ej.: 61234567",
        mx: "Ej.: 5512349876",
        cl: "Ej.: 912347890",
        co: "Ej.: 3161234567",
        pe: "Ej.: 950123123",
        uy: "Ej.: 94123123",
      };

    return placeHoldeCountry[country] || placeHoldeCountry["cr"];
}