import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { emptyMyAccountTableAction, emptyMyAccountTableDescAction } from "./StatusAccountAction";
import { fetchAccountStatusDataBalance, fetchAccountStatusDataTableMyAccount, fetchAccountStatusDescData, fetchAccountStatusDescDataBalance, fetchAccountStatusDescendentData, fetchAccountStatusDocumentSummaryById } from "./StatusAccountSideEffect";

const initialState = {
  data: null,
  dataTable: null,
  dataDesc: null,
  dataDescBalance: null,
  dataTableDesc: null,
  dataSummaryById: null,
  denyEntryDoppleganger: null,
  loader: false,
};

const statusAccountReducer = createReducer(initialState, (builder) => {
  builder

    .addCase(emptyMyAccountTableDescAction, (state, action) => {
      state.dataTableDesc = null
    })

    .addCase(emptyMyAccountTableAction, (state, action) => {
      state.dataTable = null;
      state.denyEntryDoppleganger = null;
    })

    .addCase(fetchAccountStatusDescDataBalance.fulfilled , (state, action) => {
      state.dataDescBalance = action.payload;
    })

    .addCase(fetchAccountStatusDescData.fulfilled , (state, action) => {
      state.dataDesc = action.payload;
    } )

    .addCase(fetchAccountStatusDataTableMyAccount.fulfilled, (state, action) => {
      
      if (state.denyEntryDoppleganger != null) {
        action.payload?.summary?.map((elm) => {
          state.dataTable?.summary.push(elm);
        })
      } else {
        state.dataTable = action.payload;
      }
      state.denyEntryDoppleganger = "not null";
      state.loader = false;
    })

    .addCase(fetchAccountStatusDocumentSummaryById.fulfilled, (state, action) => {
      state.dataSummaryById = action.payload;
      state.loader = false;
    })

    .addCase(fetchAccountStatusDataBalance.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loader = false;
    })

    .addCase(fetchAccountStatusDescendentData.fulfilled, (state, action) => {

        if (state.dataTableDesc?.summary?.length > 0) {
          action.payload?.summary?.map((elm) => {
            state.dataTableDesc?.summary.push(elm);
          })
        } else {
          state.dataTableDesc = action.payload;
  
        }
  
        state.loader = false;
      })

    .addMatcher(
      isAnyOf(
        fetchAccountStatusDataBalance.pending,
          fetchAccountStatusDescendentData.pending,
          fetchAccountStatusDocumentSummaryById.pending
        ),
        (state, action) => {
          state.loader = true;
        }     
    )
    .addMatcher(
      isAnyOf(
        fetchAccountStatusDataBalance.rejected,
        fetchAccountStatusDescendentData.rejected,
        fetchAccountStatusDocumentSummaryById.rejected
      ),
      (state, action) => {
        state.loader = true;
      }
    )
});

export default statusAccountReducer;
