import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { clearInsideArray, changePersonalGroupName, pushInsideArray, defineDescendantModalUserInfo, defineModalUserSalesTab, removeXAmountArray, clearExtras } from "./DescendantsAction";
import { fetchDesc, fetchDescPersonalGroup, fetchDescFirstGen, fetchDesProfile, fetchDescIncorp, fetchDesScore, fetchDesGroup, fetchDescSalesBills, fetchDescFirstGenExtras, fetchDescIncorpExtra, fetchDescPersonalGroupExtra } from "./DescendantsSideEffect";

const initialState = {
  data: null,
  dataFirstGen: null,
  dataFirstGenExtra: [],
  dataPersonalGroup: null,
  dataPersonalGroupExtra: [],
  dataPersonalGroupName: null, // Group Name!! 
  dataArrayAsc: [],
  dataIncorp: null,
  dataIncorpExtra: [],
  descendantProfilaData: null,
  descendantScoreData: null,
  descendantGroupData: null,
  descendantSalesWeeklyBill: null,
  loader: true,
  modalUserInfo: {
    id: null,
    name: null,
    picture: null,
    level: null,
    roleDescription: null,
    uplineName: null,
    uplineId: null,
    socialNetwork: [],
    currencyCode: null,
    personalSalesActual: null,
    personalSalesTotal: null,
    personalSalesStatus: null,
    incorporatedSalesActual: null,
    incorporatedSalesTotal: null,
    incorporatedSalesStatus: null,
    groupSalesActual: null,
    groupSalesTotal: null,
    groupSalesStatus: null,
    triperiodicSalesActual: null,
    triperiodicSalesTotal: null,
    triperiodicSalesStatus: null,
    triperiodicSalesPeriods: [],
    billedAmount: null,
    billedStatus: null,
    balanceAmount: null,
    balanceStatus: null,
    toInvoiceAmount: null,
    toInvoiceStatus: null,
    notSentAmount: null,
    notSentStatus: null
  },
  modalUserSalesTab: false
};

const descendantReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchDesc.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loader = false;
    })
    .addCase(clearExtras, (state, action) => {
      state.dataFirstGenExtra = []
      state.dataPersonalGroupExtra = []
      state.dataIncorpExtra = []
    })
    .addCase(fetchDescFirstGenExtras.fulfilled, (state, action) => {
      if(state.dataFirstGenExtra.length == 0) {
        state.dataFirstGenExtra = action.payload.firstGenerationGroup;
      } else {
        if (action.payload.firstGenerationGroup.length == 0) {
          
        } else {
          action.payload.firstGenerationGroup.map((elm) => {
            state.dataFirstGenExtra.push(elm)
          })
        }
      }
    })
    .addCase(fetchDescIncorpExtra.fulfilled, (state, action) => {
      if(state.dataIncorpExtra.length == 0) {
        state.dataIncorpExtra = action.payload.incorporated;
      } else {
        if (action.payload.incorporated.length == 0) {
          
        } else {
          action.payload.incorporated.map((elm) => {
            state.dataIncorpExtra.push(elm)
          })
        }
      }
    })
    .addCase(fetchDescPersonalGroupExtra.fulfilled, (state, action) => {
      if(state.dataPersonalGroupExtra.length == 0) {
        state.dataPersonalGroupExtra = action.payload.personalGroup;
      } else {
        if (action.payload.personalGroup.length == 0) {
          
        } else {
          action.payload.personalGroup.map((elm) => {
            state.dataPersonalGroupExtra.push(elm)
          })
        }
      }
    })
    .addCase(removeXAmountArray, (state, action) => {
      for (let index = 0; index < action.payload; index++) {
        state.dataArrayAsc.pop();
      }
    })
    .addCase(clearInsideArray, (state, action) => {
      state.dataArrayAsc = [];
    })
    .addCase(pushInsideArray, (state, action) => {
      state.dataArrayAsc.push(action.payload);
    })
    .addCase(fetchDescSalesBills.fulfilled, (state, action) => {
      state.descendantSalesWeeklyBill = action.payload;
      state.loader = false;
    })
    .addCase(fetchDesGroup.fulfilled, (state, action) => {
      state.descendantGroupData = action.payload;
      state.loader = false;
    })
    .addCase(fetchDesScore.fulfilled, (state, action) => {
      state.descendantScoreData = action.payload;
      state.loader = false;
    })
    .addCase(fetchDesProfile.fulfilled, (state, action) => {
      state.descendantProfilaData = action.payload;
      state.loader = false;
    })
    .addCase(changePersonalGroupName, (state, action) => {
      state.dataPersonalGroupName = action.payload;
    })
    .addCase(defineDescendantModalUserInfo, (state, action) => {
        state.modalUserInfo = action.payload;
    })
    .addCase(defineModalUserSalesTab, (state, action) => {
        state.modalUserSalesTab = action.payload;
    })
    .addCase(fetchDescPersonalGroup.fulfilled, (state, action) => {
      state.dataPersonalGroup = action.payload;
      state.loader = false;
    })
    .addCase(fetchDescFirstGen.fulfilled, (state, action) => {
      state.dataFirstGen = action.payload;
      state.loader = false;
    })
    .addCase(fetchDescIncorp.fulfilled, (state, action) => {
      state.dataIncorp = action.payload;
      state.loader = false;
    } )

    .addMatcher(
      
      isAnyOf(
        fetchDesc.pending,
        fetchDescPersonalGroup.pending,
        fetchDescFirstGen.pending,
        fetchDescIncorp.pending,
        fetchDesProfile.pending,
        fetchDesScore.pending,
        fetchDescSalesBills.pending
      ),
      (state, action) => {
        state.loader = true;
      }
    )
    .addMatcher(
      isAnyOf(
        fetchDesc.rejected,
        fetchDescPersonalGroup.rejected,
        fetchDescFirstGen.rejected,
        fetchDescIncorp.rejected,
        fetchDesProfile.rejected,
        fetchDesScore.rejected,
        fetchDescSalesBills.rejected
      ),
      (state, action) => {
        state.loader = false;
      }
    );
});

export default descendantReducer;
