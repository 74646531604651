import { FormattedMessage } from "react-intl";

export default function BusinessPlanCardLevelNext({ onClick, level, actualLevel, array, toggleNextLevel}) {

  const selectedCard = {background: "#ECF5FE", borderRadius: "16px", boxShadow: "3px 3px 15px #A4A4D733", border: " 1px solid #D3EAFF", color: "#5AAFF1"};
  const notSelectedCard = { borderRadius: "16px", color: "#908F9A"};

  const updatingValues = (value) => {
    onClick(value);
    toggleNextLevel(true);
  }

    return (
        <>
           <div
          style={{ width: "24px" }}
          className=" me-3 py-1 d-flex gap-1 flex-column align-items-center"
        >
          <div>
            <div
              style={{ width: "16px", height: "16px" }}
              className=" grayBorder border-3 border rounded-circle"
            ></div>
          </div>
          {
            actualLevel < "7" &&
            <div className=" h-100">
              <div
                style={{ width: "3px" }}
                className=" h-100 brightGrayBg "
              ></div>
            </div>
          }
        </div>
        <div className=" bp--card-width d-flex flex-column" style={{ justifySelf: "left" }} >
        <div onClick={() => updatingValues(parseInt(actualLevel) +1)} style={parseInt(actualLevel) +1 === level ? selectedCard : notSelectedCard } className=" pointer mb-4  hover-on-mouse museo-sans-500 d-flex  gap-3 flex-column bp--card-p ">
                <p className=" s12 grayColor" >
                  <FormattedMessage id="business.plan.card.next.title" />
                </p>
                {
                  array.map((level) => {
                    if (level.id == parseInt(actualLevel) +1) {
                      return <p key={`${level.id}-04`} className="s14">
                        {level.desc}
                      </p>
                    }
                  })
                }
            </div>
        </div>
        </>
    )
}