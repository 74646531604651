export default function SkeletonAccountPlan() {
    return(
      <div>
          <div className=" d-flex flex-column gap-4" >
          <div style={{height: "16px"}} className=" w-50 brightGrayBg rounded-2 lightui1-shimmer" >

          </div>
          <div className=" d-flex gap-2 flex-column " >
            <div style={{height: "8px"}} className=" w-75 brightGrayBg rounded-2 lightui1-shimmer" >

            </div>
            <div style={{height: "8px"}} className=" w-75 brightGrayBg rounded-2 lightui1-shimmer" >

            </div>
            <div style={{height: "8px"}} className=" w-50 brightGrayBg rounded-2 lightui1-shimmer" >

            </div>
          </div>
          </div>
          <hr className=" mt-5 mb-4 " >
          </hr>
          <div className=" mb-4 pb-3 d-flex flex-column" >
            <div className=" d-flex flex-column gap-2 " >
              <div style={{height: "8px"}} className=" w-50 brightGrayBg rounded-2 lightui1-shimmer" >

              </div>
              <div style={{height: "12px"}} className=" w-50 brightGrayBg rounded-2 lightui1-shimmer " >

              </div>
            </div>
          </div>
            <SkeletonRowList />
            <SkeletonRowList />
            <SkeletonRowList />
            <div className=" d-flex flex-column gap-2" >
                <div style={{height: "8px"}} className=" w-100 brightGrayBg rounded-2 lightui1-shimmer" >

                </div>
                <div style={{height: "8px"}} className=" w-75 brightGrayBg rounded-2 lightui1-shimmer" >

                </div>
                <div style={{width: "38px", height: "38px"}} className=" mb-3 brightGrayBg rounded-circle lightui1-shimmer" >

                </div>
                <div style={{height: "8px"}} className=" w-100 brightGrayBg rounded-2 lightui1-shimmer" >

                </div>
            </div>
          </div>
    )
}

export function SkeletonRowList(params) {
    return(
      <div className=" mb-4 d-flex flex-column gap-2 " >
            <div className=" d-flex flex-column gap-2 " >
              <div style={{height: "12px"}} className=" w-50 brightGrayBg rounded-2 lightui1-shimmer" >

              </div>
              <div style={{height: "12px"}} className=" w-100 brightGrayBg rounded-2 lightui1-shimmer" >

              </div>
            </div>
            <div className=" d-flex justify-content-between" >
                <div style={{height: "8px"}} className=" w-25 brightGrayBg rounded-2  lightui1-shimmer" >

                </div>
                <div style={{height: "8px"}} className=" w-25 brightGrayBg rounded-2 lightui1-shimmer " >

                </div>
            </div>
          </div>
    )
}