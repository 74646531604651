import { FormattedMessage } from "react-intl";
import { useState, useEffect } from "react";
import { useMediaPredicate } from "react-media-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Red } from "../../../common/svgs/ic-alert-circle-red.svg";
import { ReactComponent as Yellow } from "../../../common/svgs/ic-alert-circle-yellow.svg";
import { ReactComponent as Green } from "../../../common/svgs/ic-check-circle-sm.svg";
import { ReactComponent as Grey } from "../../../common/svgs/ic-alert-circle-grey.svg";
import { ReactComponent as SVGStarReduce } from "../../../common/svgs/ic-req-red.svg";

import PerformanceSocialIcons from "./PerformanceSocialIcons";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import { changePersonalGroupName } from "../../../Descendants/Framework/redux/DescendantsAction";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import checkReduceRequirement from "../../../common/Share/checkReduceRequirement";
import ProgressBarParams from "../../../common/Share/ProgressBarParams";

export default function QualifiedGroupsCard({ data }) {
  const smallerThan1024 = useMediaPredicate("(max-width: 1024px)");
  const smallerThan1920 = useMediaPredicate("(max-width: 1900px)");
  const smallerThan1280 = useMediaPredicate("(max-width: 1280px)");

  const history = useNavigate();
  const dispatch = useDispatch();
  const [perfData, setPerfData] = useState(undefined);

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  async function getCheckMarkStatus() {
    if (data) {
      setPerfData("normal");
      return;
    } else {
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getCheckMarkStatus();
    }, 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  function defineLength() {
    if (smallerThan1024) {
      return 26;
    } else if (!smallerThan1024 && smallerThan1280) {
      return 60;
    } else if (!smallerThan1280 && smallerThan1920) {
      return 26;
    } else {
      return 24;
    }
  };

  const splitString = (str) => {
    if (str.length > defineLength()) {
      let aux = str.slice(0, defineLength());
      aux += "...";
      return aux;
    } else {
      return str;
    }
  };

  function displayIcons() {
    if (data?.profileResume?.socialNetwork) {
      return (
        <PerformanceSocialIcons
          socialArray={data?.profileResume?.socialNetwork}
        />
      );
    }
  };

  const addProgressBar = (actual, total, level, status) => {
    let aux = [];

    let auxActual = parseInt(actual);
    let auxTotal = parseInt(total);

    for (let index = 0; index < auxTotal; index++) {
      if (auxActual >= auxTotal) {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={100}
            incompleted={false}
            level={level}
            status={status}
            gap={data?.personalSales?.status === "gap"}
          />
        );
      } else if (index + 1 > auxActual || auxActual == 0) {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={0}
            incompleted={true}
            level={level}
            status={status}
            gap={data?.personalSales?.status === "gap"}
          />
        );
      } else {
        aux.push(
          <ProgressBarParams
          height={12}
            completed={100}
            incompleted={true}
            level={level}
            status={status}
            gap={data?.personalSales?.status === "gap"}
          />
        );
      }
    }

    return aux;
  };

  const onShowProfile = (id, data) => {
    dispatch(
      changePersonalGroupName({ name: data.profileResume.name, clientId: id })
    );
    history(`/dashboard/descendants/profile/${id}`);
  };

  return (
    <div className={`qualified-card`}>
      <div className="d-flex w-100 align-items-center">
        <div style={{ width: "72px" }}>
          <ImageCircleHandler
            width={"72px"}
            size={"24px"}
            url={data?.profileResume.image}
            firstName={data?.profileResume?.firstName}
            name={data?.profileResume?.name}
            lastName={data?.profileResume?.lastName}
            top={0}
          />
        </div>
        <div
          className="d-flex flex-column w-100 "
          style={{ marginLeft: "20px" }}
        >
          <div className="d-flex align-items-baseline">
            <p className="museo-sans-700 s16 me-2 performance-toltip-inline-container blackColor">
              {splitString(
                data?.profileResume?.lastName +
                  " " +
                  data?.profileResume?.firstName
              )}
            </p>
            <div className="performance-svg-size performance-toltip-inline-container">
              {checkReduceRequirement(
                data.consultantStatus?.reducedRequirements
              ) ? (
                
                  <SVGStarReduce />
                
              ) : (
                <>
                  {perfData === "risk" && <Red />}
                  {perfData === "completed" && <Green />}
                  {perfData === "gap" && <Grey />}
                  {perfData === "warning" && <Yellow />}
                </>
              )}
            </div>
          </div>
          <p className="museo-sans-500 s14 grayColor">
            <ReformatLevelByGender
              gender={data?.profileResume?.gender}
              level={data?.profileResume?.level}
              roleDescriptionDefault={data?.profileResume?.roleDescription}
            />
          </p>
          <div
            className={`${smallerThan1280 ? "" : "mt-3"} qualified-card-social`}
          >
            {displayIcons()}
          </div>
        </div>
      </div>
      <hr></hr>
      <div
        className={`d-flex flex-column`}
        style={{ gap: smallerThan1280 ? "10px" : "1rem" }}
      >
        <div className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-between align-items-baseline">
            <p className="museo-sans-500 s14 blackColor">
              <FormattedMessage
                id="dashboard.qualification.ul.personal.sale"
                defaultMessage="Venta personal"
              />
            </p>
            <div className="d-flex gap-1 align-items-baseline">
              <p className="dm-sans-medium s14 blackColor">
                {formatAmount(
                  data?.personalSales?.actualAmount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
              <p className="dm-sans-medium s14 grayColor">/</p>
              <p className={`dm-sans-medium s14 grayColor`}>
                {formatAmount(
                  data?.personalSales?.totalAmount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
            </div>
          </div>
          <ProgressBarParams
          height={12}
            completed={
              (data?.personalSales?.actualAmount * 100) /
              data?.personalSales?.totalAmount
            }
            incompleted={
              data?.personalSales?.actualAmount <
              data?.personalSales?.totalAmount
            }
            level={data?.profileResume?.level}
            status={data?.personalSales?.status}
            gap={data?.personalSales?.status === "gap" ? true : false}
          />
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-between align-items-baseline">
            <p className="museo-sans-500 s14 blackColor">
              <FormattedMessage
                id="dashboard.qualification.ul.incorporated"
                defaultMessage="Incorporados Personales con ventas"
              />
            </p>
            <div className="d-flex gap-1 align-items-baseline">
              <p className="dm-sans-medium s14 blackColor">
                {data?.incorporatedSales?.actualActives}
              </p>
              <p className="dm-sans-medium s14 grayColor">/</p>
              <p
                className={`dm-sans-medium s14 ${
                  checkReduceRequirement(
                    data.consultantStatus?.reducedRequirements
                  )
                    ? "reducedColor"
                    : "grayColor"
                }`}
              >
                {data?.incorporatedSales?.totalNeed}
              </p>
              <div>
                {checkReduceRequirement(
                  data.consultantStatus?.reducedRequirements
                ) ? (
                  
                    <div className="qualified-svg-size">
                      <SVGStarReduce />
                    </div>
                  
                ) : (
                  
                    <div className=" invisible qualified-svg-size">
                      <SVGStarReduce />
                    </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex gap-1">
            {addProgressBar(
              data?.incorporatedSales?.actualActives,
              data?.incorporatedSales?.totalNeed,
              data?.profileResume?.level,
              data?.incorporatedSales?.status
            )}
          </div>
        </div>

        <div className="d-flex flex-column gap-2">
          <div className="d-flex justify-content-between align-items-baseline">
            <p className="museo-sans-500 s14 blackColor">
              <FormattedMessage
                id="dashboard.qualification.ul.group.sale"
                defaultMessage="Venta grupal"
              />
            </p>
            <div className="d-flex gap-1 align-items-baseline">
              <p className="dm-sans-medium s14 blackColor">
                {formatAmount(
                  data?.groupSales?.actualAmount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
              <p className="dm-sans-medium s14 grayColor">/</p>
              <p
                className={`dm-sans-medium s14 ${
                  checkReduceRequirement(
                    data.consultantStatus?.reducedRequirements
                  )
                    ? "reducedColor"
                    : "grayColor"
                }`}
              >
                {formatAmount(
                  data?.groupSales?.totalAmount,
                  cultureInfo?.languageCode,
                  cultureInfo?.defaultCurrencyCode
                )}
              </p>
              <div>
                {checkReduceRequirement(
                  data.consultantStatus?.reducedRequirements
                ) ? (
                  
                    <div className="qualified-svg-size">
                      <SVGStarReduce />
                    </div>
                  
                ) : (
                  
                    <div className=" invisible qualified-svg-size">
                      <SVGStarReduce />
                    </div>
                  
                )}
              </div>
            </div>
          </div>
          <ProgressBarParams
          height={12}
            completed={
              (data?.groupSales?.actualAmount * 100) /
              data?.groupSales?.totalAmount
            }
            incompleted={
              data?.groupSales?.actualAmount < data?.groupSales?.totalAmount
            }
            level={data?.profileResume?.level}
            status={data?.groupSales?.status}
            gap={data?.groupSales?.status === "gap" }
          />
        </div>

        <p
          onClick={() => onShowProfile(data?.clientId, data)}
          className="museo-sans-700 s14 skyColor button-hover-light-text hover-pointer"
        >
          <FormattedMessage
            id="performance.user.tooltip.view.profile"
            defaultMessage="Ver perfil"
          />
        </p>
      </div>
    </div>
  );
}
