import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ImageCircleHandler from "../../../common/Share/ImageCircleHandler";
import ReformatLevelByGender from "../../../common/Share/ReformatLevelByGender";
import GroupIncorpDescendantUserTooltip from "../../../Performance/Presentation/components/GroupIncorpDescendantUserTooltip";

export default function GoodPracticesListBondRow(params) {
  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );
  const [tooltipOffset, setTooltipOffset] = useState(0);
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  const handleMouseEnter = () => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const tooltipHeight = tooltipRef.current.offsetHeight;
    const containerHeight = containerRect.height;

    const spaceBelow = window.innerHeight - containerRect.bottom;
    const spaceAbove = containerRect.top;

    if (spaceBelow >= tooltipHeight) {
      setTooltipOffset(containerHeight);
    } else if (spaceAbove >= tooltipHeight) {
      setTooltipOffset(-tooltipHeight);
    }
  };

  return (
    <>
      {params?.data && (
        <div
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
          id={params?.data?.clientId}
          className=" position-relative tooltip-container d-flex justify-content-between w-100  "
        >
          <GroupIncorpDescendantUserTooltip tooltipRef={tooltipRef} top={tooltipOffset} userInfo={params?.data} />
          <div
          className=" position-relative d-flex flex-column gap-2">
            <div className="d-flex justify-content-between museo-sans-500 gap-3">
              <div>
                <ImageCircleHandler
                  width={"38px"}
                  height={"38px"}
                  url={params?.data?.profileResume?.image}
                  firstName={params?.data?.profileResume?.firstName}
                  lastName={params?.data?.profileResume?.lastName}
                  top={0}
                />
              </div>
              <div className="d-flex flex-column">
                <p className=" s14 museo-sans-700">
                  {params?.data?.profileResume?.name}
                </p>
                <p className=" s14 grayColor">
                  <ReformatLevelByGender
                    level={params?.data?.profileResume?.level}
                    gender={params?.data?.profileResume?.gender}
                    roleDescriptionDefault={
                      params?.data?.profileResume?.roleDescription
                    }
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column" >
            <p className=" s16 dm-sans-bold mx-4 ">
              {formatAmount(
                params?.data?.billedAmount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </p>
          </div>
        </div>
      )}
    </>
  );
}
