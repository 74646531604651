import { useEffect, useState } from "react";
import { ReactComponent as SVGArrow } from "../../../svgs/ic-arrow-right-md.svg";
import { ReactComponent as SVGTrashRed } from "../../../svgs/ic-trash-outline-md.svg";

import SVGType3 from "../../../svgs/notifications/ic-notif-subiste-de-nivel.png";

import SVGType4 from "../../../svgs/notifications/ic-notif-precalificando.png";

import SVGType6 from "../../../svgs/notifications/ic-notif-perdiste-nivel.png";

import SVGType17 from "../../../svgs/notifications/ic-notif-req-red.png";

import SVGType29 from "../../../svgs/notifications/ic-notif-edb-confirmado.png";

import SVGType48 from "../../../svgs/notifications/ic-notif-gan-period-ant.png";

import SVGType20 from "../../../svgs/notifications/ic-notif-estas-por-perder-la-clave.png";

import SVGType19 from "../../../svgs/notifications/ic-notif-no-alcanzaste-tus-calificadores.png";

import SVGType18 from "../../../svgs/notifications/ic-notif-sigue-intentandolo.png";

import SVGType47 from "../../../svgs/notifications/47/icon.svg";
import SVGTypeFondo47 from "../../../svgs/notifications/47/fondo.svg";

import { ReactComponent as SVGType49 } from "../../../svgs/notifications/ic-incorp.svg";

import SVGGeneral from "../../../svgs/notifications/generico/icon.svg";

import { useDispatch } from "react-redux";
import {
  notificationId,
  notificationDetail,
} from "../../Framework/HeaderAction";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

export default function HeaderNotificationList({ data, closingModal }) {
  const auxIds = [3, 4, 5, 6, 7, 17, 29, 48, 20, 19, 18, 47, 49];
  const [currentDate, setCurrentDate] = useState();
  const dispatch = useDispatch();
  const history = useNavigate();

  const tranformIntoDateObject = (str) => {
    const date = new Date(str); // La fecha que recibes incluye zona horaria, así que esto la interpreta correctamente
    const currentDate = new Date();
    const diff = currentDate - date;
    const dayDifference = diff / (1000 * 60 * 60 * 24);
  
    if (dayDifference < 1) {
      const hourDifference = dayDifference * 24;
  
      if (hourDifference < 1) {
        const minuteDifference = hourDifference * 60;
        return minuteDifference < 1 ? (
          <FormattedMessage id="now" defaultMessage="Ahora" />
        ) : (
          <TimeDisplay value={parseInt(minuteDifference)} unit="min" />
        );
      }
  
      return <TimeDisplay value={parseInt(hourDifference)} unit="hour" />;
    }
  
    if (parseInt(dayDifference) === 1) {
      return (
        <div className="d-flex">
          <FormattedMessage id="yesterday" />
          <p className="invisible">a</p>{" "}
          {String(date.getHours()).padStart(2, "0")}:
          {String(date.getMinutes()).padStart(2, "0")}
        </div>
      );
    }
  
    const [years, month, days] = str.split("T")[0].split("-");
    return `${days}-${month}-${years.slice(2, 4)}`;
  };
  
  const TimeDisplay = ({ value, unit }) => (
    <div className="d-flex">
      <FormattedMessage id="since" />
      <p className="invisible">a</p> {value}{" "}
      <p className="text-lowercase">
        <FormattedMessage id={unit} />
      </p>
    </div>
  );

  const opacityByView = (boolean) => {
    if (boolean) {
      return {
        opacity: 0.8,
        height: "115px",
        maxHeight: "92px",
        alignItems: "start",
      };
    } else {
      return { height: "115px", maxHeight: "92px", alignItems: "start" };
    }
  };

  const transformContentIfLong = (str) => {
    if (str.length > 90) {
      let aux = str.slice(0, 75);
      aux = aux + "...";
      return aux;
    } else {
      return str;
    }
  };

  const changeId = () => {
    dispatch(notificationId(data.key));
  };

  const changeType = () => {
    dispatch(notificationDetail(data.key));
    dispatch(notificationId(data.key));
  };

  const noModalShow = () => {
    if (data.type !== 18 && data.type !== 47 && data.type !== 49) {
      return true;
    } else {
      return false;
    }
  };

  const removeArrow = () => {
    if (data.type === 18 || data.type === 47 || data.type === 49) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfGeneralArray = (type) => {
    let found = true;
    auxIds.forEach((element) => {
      if (element === type) {
        found = false;
        return found;
      }
    });

    return found;
  };

  const onDeepLink = (link) => {
    closingModal();
    const parsedExtras = JSON.parse(data?.extras);
    const clientId = parsedExtras.clientId;
    history(`${link}${clientId}`);
  };

  useEffect(() => {
    let date = new Date();
    setCurrentDate(date);
  }, []);

  return (
    <>
      <div
        style={opacityByView(data.viewed)}
        className={` ${noModalShow() ? "pointer" : ""} w-100  d-flex gap-1 `}
      >
        <HeaderNotificationListIcon
          data={data}
          checkIfGeneralArray={checkIfGeneralArray}
          noModalShow={noModalShow}
          changeType={changeType}
        />
        <HeaderNotificationListContent
          noModalShow={noModalShow}
          changeType={changeType}
          data={data}
          currentDate={currentDate}
          tranformIntoDateObject={tranformIntoDateObject}
          transformContentIfLong={transformContentIfLong}
        />
        <div className={`${noModalShow() ? "pointer" : ""} h-100 d-flex gap-4`}>
          <div
            data-bs-toggle="modal"
            data-bs-target="#modalNotifDelete"
            style={{ alignItems: "center" }}
            className="pointer d-flex"
          >
            <div onClick={changeId} style={{ width: "24px", height: "24px" }}>
              <SVGTrashRed />
            </div>
          </div>

          {removeArrow() ? (
            data?.type === 49 ? (
              <div
                onClick={() => onDeepLink("/dashboard/descendants/profile/")}
                style={{ alignItems: "center" }}
                className="pointer d-flex"
              >
                <SVGArrow />
              </div>
            ) : (
              <div
                style={{ alignItems: "center" }}
                className="invisible d-flex"
              >
                <SVGArrow />
              </div>
            )
          ) : (
            <div
              onClick={changeType}
              data-bs-toggle="modal"
              data-bs-target="#alertDetails"
              style={{ alignItems: "center" }}
              className="pointer d-flex"
            >
              <SVGArrow />
            </div>
          )}
        </div>
      </div>
      <hr></hr>
    </>
  );
}

export function HeaderNotificationListContent({
  noModalShow,
  changeType,
  data,
  currentDate,
  tranformIntoDateObject,
  transformContentIfLong,
}) {
  return (
    <div
      onClick={noModalShow() && changeType}
      data-bs-toggle={`${noModalShow() && "modal"}`}
      data-bs-target={`${noModalShow() && "#alertDetails"}`}
      className=" px-1 w-100"
    >
      <div
        style={{ alignItems: "center" }}
        className=" d-flex justify-content-between"
      >
        <p className=" s14 museo-sans-700">{data.title}</p>
      </div>
      <div className=" s14 d-flex justify-content-between">
        <p className=" s14 museo-sans-500">
          {transformContentIfLong(data.content)}
        </p>
      </div>
      <div>
        <p className="  grayColor museo-sans-500 s12 ">
          {currentDate && tranformIntoDateObject(data.time)}
        </p>
      </div>
    </div>
  );
}

export function HeaderNotificationListIcon({
  data,
  checkIfGeneralArray,
  noModalShow,
  changeType,
}) {
  return (
    <div
      onClick={noModalShow() && changeType}
      data-bs-toggle={`${noModalShow() && "modal"}`}
      data-bs-target={`${noModalShow() && "#alertDetails"}`}
      style={{ alignItems: "center" }}
      className=" gap-1 d-flex"
    >
      {data.viewed ? (
        <div
          className="  rounded-circle "
          style={{ width: "8px", height: "8px" }}
        ></div>
      ) : (
        <div
          className=" lightGreenBg rounded-circle "
          style={{ width: "8px", height: "8px" }}
        ></div>
      )}
      {checkIfGeneralArray(data.type) && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGGeneral} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle bg-white  position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 49 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <div style={{ top: -2 }} className=" position-relative">
            <SVGType49 />
          </div>
          <div
            style={{ width: "32px", height: "32px", background: "#CED2FF" }}
            className=" rounded-circle  position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 47 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType47} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle  position-absolute opacity20 "
          >
            <img src={SVGTypeFondo47} alt="" />
          </div>
        </div>
      )}
      {data.type === 3 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType3} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle lightGreenBg position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 4 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType4} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle lightBlueBg position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 5 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType19} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle goldBg position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 6 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType6} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle lightRedBg position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 7 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType6} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle lightRedBg position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 17 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType17} alt="" />
          <div
            style={{ width: "32px", height: "32px", background: "#DBDBF4" }}
            className=" rounded-circle  position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 29 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType29} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle lightGreenBg  position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 48 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType48} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle lightGreenBg  position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 20 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType20} alt="" />
          <div
            style={{ width: "32px", height: "32px" }}
            className=" rounded-circle lightRedBg  position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 19 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType18} alt="" />
          <div
            style={{ width: "32px", height: "32px", background: "" }}
            className=" rounded-circle goldBg position-absolute opacity20 "
          ></div>
        </div>
      )}
      {data.type === 18 && (
        <div
          style={{
            alignItems: "center",
            top: "0px",
            width: "32px",
            height: "32px",
          }}
          className=" d-flex justify-content-center position-relative"
        >
          <img src={SVGType4} alt="" />
          <div
            style={{ width: "32px", height: "32px", background: "" }}
            className=" rounded-circle lightBlueBg position-absolute opacity20 "
          ></div>
        </div>
      )}
    </div>
  );
}
