import { createAction } from '@reduxjs/toolkit';

const changeEventSectionTitle = createAction('changeClosedEDBsOffset');
const putEventId = createAction('putEventId');

const modifyNav = createAction('modifyNav');
const resetNav = createAction('resetNav');
const setDetailsModal = createAction('setDetailsModal');

const activeSkeleton = createAction('activeSkeleton');
const deactiveSkeleton = createAction('deactiveSkeleton');

export  {changeEventSectionTitle, modifyNav, resetNav, putEventId, setDetailsModal, deactiveSkeleton, activeSkeleton } 

