import { FormattedMessage } from "react-intl";

export default function PersonalSalesDetailsOrders({
  orders,
  fetchOrderDetails,
  fetchOrderDetailsEDB,
  hideOnPrint,
  biggerThan1280,
  cultureInfo,
  formatAmount,
  HandleSwissDates,
  SVGEmpty,
  SVGEDB,
  SVGNotEDB,
  SVGChevronRight,
}) {
  return (
    <>
      {orders?.length < 0 ? (
        <div
          style={{ height: "40vh" }}
          className=" gap-2 d-flex flex-column align-items-center justify-content-center w-100"
        >
          <SVGEmpty />
          <p className=" museo-sans-500 s24 ">
            <FormattedMessage id="personal.sale.details.empty.orders" />
          </p>
        </div>
      ) : (
        <>
          {orders.map((elm, index) => {
            return (
              <>
                {biggerThan1280 ? (
                  <>
                    {elm.category === "edb" ? (
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#modalDetailsOrderEDB"
                        onClick={() => fetchOrderDetailsEDB(elm.id)}
                        style={{ height: "48px" }}
                        className=" pointer align-items-center museo-sans-500 s14 grayColor grid--order-details "
                      >
                        <p className=" blackColor">
                          <FormattedMessage id="personal.sale.details.orden.id" />{" "}
                          {elm.id}
                        </p>
                        <p>{elm.name} </p>
                        <p className=" dm-sans-medium">
                          <HandleSwissDates date={elm.date} />
                        </p>
                        <div className="d-flex align-items-center gap-1 ">
                          <p className=" s12 text-uppercase">{elm.category} </p>
                          <div
                            className="control--svg-filler-gray"
                            style={{ width: "24px", height: "24px" }}
                          >
                            {elm.category === "edb" ? (
                              <SVGEDB />
                            ) : (
                              <SVGNotEDB />
                            )}
                          </div>
                        </div>
                        <p className=" dm-sans-medium s14  blackColor">
                          {formatAmount(
                            elm?.amount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        {!hideOnPrint && (
                          <>
                            {elm.category === "edb" ? (
                              <div
                                style={{
                                  width: "fit-content",
                                  justifySelf: "flex-end",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#modalDetailsOrderEDB"
                                className=" pointer d-flex justify-content-end"
                              >
                                <SVGChevronRight />
                              </div>
                            ) : (
                              <div
                                onClick={() => fetchOrderDetails(elm.id)}
                                style={{
                                  width: "fit-content",
                                  justifySelf: "flex-end",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#modalDetailsOrder"
                                className=" pointer d-flex justify-content-end"
                              >
                                <SVGChevronRight />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#modalDetailsOrder"
                        onClick={() => fetchOrderDetails(elm.id)}
                        style={{ height: "48px" }}
                        className=" pointer align-items-center museo-sans-500 s14 grayColor grid--order-details "
                      >
                        <p className=" blackColor">
                          <FormattedMessage id="personal.sale.details.orden.id" />{" "}
                          {elm.id}
                        </p>
                        <p>{elm.name} </p>
                        <p className=" dm-sans-medium">
                          <HandleSwissDates date={elm.date} />
                        </p>
                        <div className="d-flex align-items-center gap-1 ">
                          <p className=" s12 text-uppercase">{elm.category} </p>
                          <div
                            className="control--svg-filler-gray"
                            style={{ width: "24px", height: "24px" }}
                          >
                            {elm.category === "edb" ? (
                              <SVGEDB />
                            ) : (
                              <SVGNotEDB />
                            )}
                          </div>
                        </div>
                        <p className=" dm-sans-medium s14  blackColor">
                          {formatAmount(
                            elm?.amount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        {!hideOnPrint && (
                          <>
                            {elm.category === "edb" ? (
                              <div
                                style={{
                                  width: "fit-content",
                                  justifySelf: "flex-end",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#modalDetailsOrderEDB"
                                className=" pointer d-flex justify-content-end"
                              >
                                <SVGChevronRight />
                              </div>
                            ) : (
                              <div
                                onClick={() => fetchOrderDetails(elm.id)}
                                style={{
                                  width: "fit-content",
                                  justifySelf: "flex-end",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#modalDetailsOrder"
                                className=" pointer d-flex justify-content-end"
                              >
                                <SVGChevronRight />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {elm.category === "edb" ? (
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#modalDetailsOrderEDB"
                        onClick={() => fetchOrderDetailsEDB(elm.id)}
                        style={{ height: "48px" }}
                        className=" pointer align-items-center museo-sans-500 s14 grayColor grid--order-details--smaller "
                      >
                        <p className=" blackColor">
                          <FormattedMessage id="personal.sale.details.orden.id" />{" "}
                          {elm.id}
                        </p>
                        <p>{elm.name} </p>
                        <p className=" dm-sans-medium">
                          <HandleSwissDates date={elm.date} />
                        </p>
                        <div className="d-flex align-items-center gap-1 ">
                          <p className=" s12 text-uppercase">{elm.category} </p>
                          <div
                            className="control--svg-filler-gray"
                            style={{ width: "24px", height: "24px" }}
                          >
                            {elm.category === "edb" ? (
                              <SVGEDB />
                            ) : (
                              <SVGNotEDB />
                            )}
                          </div>
                        </div>
                        <p className=" dm-sans-medium s14 blackColor">
                          {formatAmount(
                            elm?.amount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        {!hideOnPrint && (
                            <div
                              onClick={() =>
                                elm.category === "edb"
                                  ? fetchOrderDetailsEDB(elm.id)
                                  : fetchOrderDetails(elm.id)
                              }
                              style={{
                                width: "fit-content",
                                justifySelf: "flex-end",
                              }}
                              data-bs-toggle="modal"
                              data-bs-target={`${
                                elm.category === "edb"
                                  ? "#modalDetailsOrderEDB"
                                  : "#modalDetailsOrder"
                              }`}
                              className=" pointer d-flex justify-content-end"
                            >
                              <SVGChevronRight />
                            </div>
                        )}
                      </div>
                    ) : (
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#modalDetailsOrder"
                        onClick={() => fetchOrderDetails(elm.id)}
                        style={{ height: "48px" }}
                        className=" pointer align-items-center museo-sans-500 s14 grayColor grid--order-details--smaller "
                      >
                        <p className=" blackColor">
                          <FormattedMessage id="personal.sale.details.orden.id" />{" "}
                          {elm.id}
                        </p>
                        <p>{elm.name} </p>
                        <p className=" dm-sans-medium">
                          <HandleSwissDates date={elm.date} />
                        </p>
                        <div className="d-flex align-items-center gap-1 ">
                          <p className=" s12 text-uppercase">{elm.category} </p>
                          <div
                            className="control--svg-filler-gray"
                            style={{ width: "24px", height: "24px" }}
                          >
                            {elm.category === "edb" ? (
                              <SVGEDB />
                            ) : (
                              <SVGNotEDB />
                            )}
                          </div>
                        </div>
                        <p className=" dm-sans-medium s14 blackColor">
                          {formatAmount(
                            elm?.amount,
                            cultureInfo?.languageCode,
                            cultureInfo?.defaultCurrencyCode
                          )}
                        </p>
                        {!hideOnPrint && (
                          <>
                            {elm.category === "edb" ? (
                              <div
                                onClick={() => fetchOrderDetailsEDB(elm.id)}
                                style={{
                                  width: "fit-content",
                                  justifySelf: "flex-end",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#modalDetailsOrderEDB"
                                className=" pointer d-flex justify-content-end"
                              >
                                <SVGChevronRight />
                              </div>
                            ) : (
                              <div
                                onClick={() => fetchOrderDetails(elm.id)}
                                style={{
                                  width: "fit-content",
                                  justifySelf: "flex-end",
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#modalDetailsOrder"
                                className=" pointer d-flex justify-content-end"
                              >
                                <SVGChevronRight />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}

                {index === orders.length ? <></> : <hr />}
              </>
            );
          })}
        </>
      )}
    </>
  );
}
