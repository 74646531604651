import { createReducer } from "@reduxjs/toolkit";
import { initArrays, putArrId } from "./AccountAction";
import { fetchBalance, fetchMetrics } from "./AccountSideEffect";

const initialState = {
  data: null,
  metrics: null,
  reMetricGraph: {
    metricId: null,
    metricPersonal: null,
    metricInc: null,
    metricGroup: null,
    metricDesc: null,
  },
  loading: false,
};

const periodReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchBalance.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchBalance.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    })
    .addCase(fetchBalance.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(fetchMetrics.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(fetchMetrics.fulfilled, (state, action) => {
      state.metrics = action.payload;
      state.loading = false;
    })
    .addCase(fetchMetrics.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(initArrays, (state, action) => {
      
      let auxArr = [];
      let auxTime = [];
      let auxStringOne = "string";
      let auxStringTwo = "string";
      state.metrics.metrics.forEach((element) => {
        auxStringOne = element.period.toString();

        auxStringTwo = element.year.toString().slice(2, 4);
        auxTime.push(auxStringOne + "/" + auxStringTwo);
      });

      
      for (let index = 0; index < state.metrics.metrics.length; index++) {
        auxArr.push({
          y: state.metrics.metrics[index].amounts.personalSales,
          x: index,
          label: auxTime[index],
        });
      }
      
      state.reMetricGraph.metricPersonal = auxArr;
      state.reMetricGraph.metricId = auxArr.length - 1;
      auxArr = [];
      
      
      for (let index = 0; index < state.metrics.metrics.length; index++) {
        auxArr.push({
          y: state.metrics.metrics[index].amounts.groupSales,
          x: index,
          label: auxTime[index],
        });
      }
      
      state.reMetricGraph.metricGroup = auxArr;
      auxArr = [];
       
      for (let index = 0; index < state.metrics.metrics.length; index++) {
        auxArr.push({
          y: state.metrics.metrics[index].amounts.incorporatedSales,
          x: index,
          label: auxTime[index],
        });
      }
      
      state.reMetricGraph.metricInc = auxArr;
      auxArr = [];
      
      for (let index = 0; index < state.metrics.metrics.length; index++) {
        auxArr.push({
          y: state.metrics.metrics[index].amounts.descentSales,
          x: index,
          label: auxTime[index],
        });
      }
      
      state.reMetricGraph.metricDesc = auxArr;
    })
    .addCase(putArrId, (state, action) => {
        state.reMetricGraph.metricId = action.payload;
    })
});

export default periodReducer;
