export const multiLangGetCountry = (lang) => {

    let country = localStorage.getItem("country");
    const languageCodes = {
      ar: "es-AR",
      mx: "es-MX",
      default: "es-MX"
    };
  
    return languageCodes[country] || languageCodes.default;
    
};