import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { getTerritoryByLevel } from "../../../../Framework/redux/ClientListSideEffect";
import CustomSelect from "../../../../../common/Share/CustomSelect";
import { levels } from '../../../constants';

export default function ClientListFormSelect({
  label,
  father,
  level,
  name,
  last,
  placeholder,
  setValue,
  watch,
  control,
  isUpdate,
  ifDepartment,
  ...props
}) {

  // Initialitation ----------------------------------------
  const [locations, setLocations] = useState([]);
  const [isValueEmpty, setIsValueEmpty] = useState(false);
  const [isLastLevelLoaded,setIsLastLevelLoaded] = useState(false);
  const dispatch = useDispatch();
  const clientFormTerritory = useSelector(state => state.clientListData.clientFormTerritory);
  const defaultValue = watch(name)?.length > 0 ? {value:watch(name),label:watch(name)} : {value:'',label:placeholder};
  let clientFormLocations = clientFormTerritory.data[levels[level]]?.locations;

  // Methods -----------------------------------------------
  const fetchPerTerritory = async (level, father) => {
    await dispatch(getTerritoryByLevel({ level, value: father}))
  };

  const transformLocations = async()=> {
    let transformedlocations = []
    if(clientFormLocations) transformedlocations = await clientFormLocations?.map((location)=> ({value:location.value,label:location.value}))
    transformedlocations.unshift({value:'',label:placeholder})
    setLocations( await transformedlocations)

    if(isUpdate && !isLastLevelLoaded && watch(name !== '')) {
      getLocations()
      setIsLastLevelLoaded(true)
    }
  }
 
  const getLocations = async ()=> {
     const selectedLocation = await clientFormLocations?.find((location) => location.value === watch(name));
     if(selectedLocation && !last) fetchPerTerritory(level+1, selectedLocation.id) 
  }

  const resetSelectField = ()=> {
      setValue(name,'')
      clientFormLocations = []
  }

  const handleSelectChange = (target)=>{
    if(ifDepartment){
      ifDepartment(target.value)
    }
      setValue(name,target.value)
      getLocations()
  }

  // Effects --------------------------------------------
  useEffect(() => {
    if(isUpdate && level > 1 && isLastLevelLoaded){
      resetSelectField() //Case update form with values
    }else if(isUpdate && level > 1 && isValueEmpty){
      resetSelectField() //Case update form empty values
    }else if(!isUpdate && level > 1){
      resetSelectField() //Case new form
    }
    if(level > 1 && father !== '') setIsValueEmpty(true)//Flag to indicate which you are updating a form but the select input dosn't have a previous value
  },[father])

  useEffect(() => {
    if (name == "neighborhood") {
      
    }
    if(clientFormLocations?.length > 0 ) transformLocations();
  },[clientFormLocations])

  return (
    <fieldset className="d-flex flex-column gap-2"> 
      <label className="museo-sans-500 s14">{label}</label>
        <Controller key={father+watch(name)} control={control} name={name}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <CustomSelect onChange={handleSelectChange}  options={locations} defaultValue={defaultValue} isDisabled={(father == "" || locations?.length < 1) } width={'360px'} />
          )}
        />
    </fieldset>
  );
}