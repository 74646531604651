import ProgressBarParams from "./ProgressBarParams";

export default function GroupTaskImg({ completed }) {
  return (
    <div className="px-3">
    <div className="my-1">
      <ProgressBarParams incompleted={false} completed={100} />
    </div>
    <div className="d-flex">
      <ProgressBarParams incompleted={false} completed={100} />
      <ProgressBarParams incompleted={false} completed={100} />
      <ProgressBarParams incompleted={false} completed={100} />
      <ProgressBarParams incompleted={false} completed={100} />
    </div>
    <div className="my-1">
      <ProgressBarParams incompleted={!completed} completed={50} />
    </div>
  </div>
  );
}
