import { useState } from "react";
import { FormattedMessage } from "react-intl";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";

import { AutoTabProvider } from "react-auto-tab";
import { useDispatch } from "react-redux";
import { fetchPassRecovery } from "../../Framework/redux/loginSideEffects";

export default function LoginPassRecPin() {
  const history = useNavigate();
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });
  
  const [errorHandler, setErrorHandler] = useState(false);
  const [toggler, setToggler] = useState(false);
  const [noTime, setNoTime] = useState(false);

  const onReSend = async () => {
    await dispatch(fetchPassRecovery(localStorage.getItem("clientId")));
    window.location.reload();
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setNoTime(true);
    } else {
      // Render a countdown
      return (
        <span>
          {minutes < 10 && "0" + minutes}:{seconds < 10 && "0" + seconds}
          {seconds >= 10 && seconds}
        </span>
      );
    }
  };

  const onChange = (evt) => {
    evt.preventDefault();
    let aux = inputs;
    let name = evt.target.name;
    let value = evt.target.value;
    setToggler(false);

    if (name === "one") {
      aux.one = value;
      setInputs(aux);
    }
    if (name === "two") {
      aux.two = value;
      setInputs(aux);
    }
    if (name === "three") {
      aux.three = value;
      setInputs(aux);
    }
    if (name === "four") {
      aux.four = value;
      setInputs(aux);
    }
    if (
      inputs.one !== "" &&
      inputs.two !== "" &&
      inputs.three !== "" &&
      inputs.four !== ""
    ) {
      setToggler(true);
    }
  };

  const onContinue = (evt) => {
    evt.preventDefault();
    let pin = inputs.one.concat(
      inputs.two.concat(inputs.three.concat(inputs.four))
    );
    localStorage.setItem("pin", pin);
    history("/recovery/password");
  };


  return (
    <div id="login-layout" className=" container--padding py-5">
      <div className="">
        <h1 className="mt-3 grayColor s12 museo-sans-500 text-uppercase">
          <FormattedMessage
            id="login.phone.page.title"
            defaultMessage="1. Profile settings"
          />
        </h1>
        <hr></hr>
        <h2 className="  museo-sans-700 s24 mt-4">
          <FormattedMessage
            id="login.recovery.page.subtitle.one"
            defaultMessage="Profile picture"
          />
        </h2>
        <AutoTabProvider
          id="login--confirm-sms"
          className="position-relative mt-5 d-flex"
        >
          <input
            onChange={onChange} 
            name="one"
            size={1}
            id="one"
            tabIndex={1}
            key={1}
            placeholder="X"
            className="form-control input-borders s26 dm-sans-bold blackColor "
            maxLength={1}
            type="text"
            tabbable
          />
          <input
            onChange={onChange}
            name="two"
            size={1}
            id="two"
            tabIndex={2}
            key={2}
            placeholder="X"
            className="form-control input-borders s26 dm-sans-bold blackColor  mx-3"
            maxLength={1}
            type="text"
            tabbable
          />
          <input
            onChange={onChange}
            name="three"
            size={1}
            id="three"
            tabIndex={3}
            key={3}
            placeholder="X"
            className="form-control input-borders s26 dm-sans-bold blackColor  "
            maxLength={1}
            type="text"
            tabbable
          />
          <input
            onChange={onChange}
            name="four"
            id="four"
            size={1}
            tabIndex={4}
            key={4}
            placeholder="X"
            className="form-control input-borders s26 dm-sans-bold blackColor  mx-3"
            maxLength={1}
            type="text"
            tabbable
          />
        </AutoTabProvider>
        {!errorHandler ? (
          <small className=" text-danger invisible ">
            {" "}
            <FormattedMessage
              id="login.error.two"
              defaultMessage="This input can't be empty or shorter than four digits"
            />{" "}
          </small>
        ) : (
          <p className=" museo-sans-500 s12 text-danger ">
            {" "}
            <FormattedMessage
              id="login.error.two"
              defaultMessage="This input can't be empty or shorter than four digits"
            />{" "}
          </p>
        )}
      </div>
      <div className="align-self-end">
        {toggler ? (
          <button
            onClick={onContinue}
            className=" position-relative btnLoginWidth  s14 museo-sans-700  py-2 text-white btnCustom skyBg"
          >
            <FormattedMessage id="buttons.continue" defaultMessage="Continue" />
          </button>
        ) : !noTime ? (
          <button className="disabled btnLoginWidth  disabledCursor position-relative s14 museo-sans-700 px-4 py-2 text-white btnCustom brightGrayBg ">
            <FormattedMessage
              id="login.phone.two.submit"
              defaultMessage="Redirect: "
            />
            <Countdown date={Date.now() + 60000} renderer={renderer} />
          </button>
        ) : (
          <button onClick={onReSend} className="position-relative btnLoginWidth s14 museo-sans-700 px-4 py-2 text-white btnCustom skyBg">

            <FormattedMessage
              id="login.phone.two.resend"
              defaultMessage="Redirect: "
              
            />
          </button>
        )}
      </div>
    </div>
  );
}
