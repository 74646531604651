import React from "react";
import { FormattedMessage } from "react-intl";

import { ReactComponent as ChevronRightSVG } from "../../../common/svgs/ic-chevron-right-md.svg";
import { ReactComponent as PersonalIcon } from "../../../common/svgs/ic-pedido-personal-sm.svg";
import { ReactComponent as TiendaIcon } from "../../../common/svgs/ic-pedido-tienda-sm.svg";
import { ReactComponent as PedidoEdbIcon } from "../../../common/svgs/ic-pedido-edb-sm.svg";
import { ReactComponent as ClienteIcon } from "../../../common/svgs/ic-pedido-cliente-sm.svg";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SVGTriangle } from "../../../common/svgs/ic-triangle-warning-md.svg";
import PersonalSaleModalCredit from "./Modals/PersonalSalesModalCredit";
import { changeCreditNotes } from "../../Framework/redux/performanceActions";

export default function PersonalSalesRow({ data, status }) {
  const history = useNavigate();
  const dispatch = useDispatch();

  const cultureInfo = useSelector(
    (state) => state.userInfo.userInfo.cultureInfo
  );

  function defineStatus(value) {
    switch (value) {
      case 1:
        return (
          <FormattedMessage
            id="personal.sales.request.uppercase"
            defaultMessage="SIN ENVIAR"
          />
        );
      case 2:
        return (
          <FormattedMessage
            id="personal.sales.pending.uppercase"
            defaultMessage="A FACTURAR"
          />
        );
      case 3:
        return (
          <FormattedMessage
            id="personal.sales.bill.uppercase"
            defaultMessage="FACTURADO"
          />
        );
      default:
        return "SIN DEFINIR";
    }
  }

  function defineDateInFormat(dateString) {
    const fecha = new Date(dateString);
    const fechaEnFormatoInicial = fecha.toLocaleDateString();
    const fragmentosFecha = fechaEnFormatoInicial.split("/");
    const day = fragmentosFecha[0]?.padStart(2, "0");
    const month = fragmentosFecha[1]?.padStart(2, "0");
    const year = fragmentosFecha[2]?.slice(2);
    const fechaEnFormatoSolicitado = day.concat("-", month, "-", year);
    return fechaEnFormatoSolicitado;
  };

  const changeCreditOrientation = (credit, data) => {
    dispatch(changeCreditNotes(credit))
    const activateModal = document.getElementById("credit-modal-activate");
    activateModal.click();
  }

  function defineType(type, orType, totalOrders) {
    if (type === "personal") {
      return (
        <>
          <p className="me-2 s12">
            <FormattedMessage
              id="personal.sales.row.type.store"
              defaultMessage="TIENDA VIRTUAL"
            />
          </p>
          <div className="mb-1">
            <TiendaIcon />
          </div>
        </>
      );
    } else if (type === "orden" && orType === null) {
      return (
        <>
          <p className="me-2 s12">
            {totalOrders}{" "}
            {totalOrders > 1 ? (
              <FormattedMessage
                id="personal.sales.row.type.orders"
                defaultMessage="ORDENES"
              />
            ) : (
              <FormattedMessage
                id="personal.sales.row.type.order"
                defaultMessage="ORDEN"
              />
            )}
          </p>
        </>
      );
    } else if (type === "orden" && orType === "personal") {
      return (
        <>
          <p className="me-2 s12">
            {totalOrders}{" "}
            {totalOrders > 1 ? (
              <FormattedMessage
                id="personal.sales.row.type.personal.orders"
                defaultMessage="ORDENES PERSONALES"
              />
            ) : (
              <FormattedMessage
                id="personal.sales.row.type.personal.order"
                defaultMessage="ORDEN PERSONAL"
              />
            )}
          </p>
          <div className="mb-1">
            <PersonalIcon />
          </div>
        </>
      );
    } else if (type === "orden" && orType === "cliente") {
      return (
        <>
          <p className="me-2 s12">
            {totalOrders}{" "}
            {totalOrders > 1 ? (
              <FormattedMessage
                id="personal.sales.row.type.client.orders"
                defaultMessage="ORDENES CLIENTE"
              />
            ) : (
              <FormattedMessage
                id="personal.sales.row.type.client.order"
                defaultMessage="ORDEN CLIENTE"
              />
            )}
          </p>
          <div className="mb-1">
            <ClienteIcon />
          </div>
        </>
      );
    } else if (type === "orden" && orType === "edb") {
      return (
        <>
          <p className="me-2 s12">
            {totalOrders}{" "}
            {totalOrders > 1 ? (
              <FormattedMessage
                id="personal.sales.row.type.edb.orders"
                defaultMessage="ORDENES EDB"
              />
            ) : (
              <FormattedMessage
                id="personal.sales.row.type.edb.order"
                defaultMessage="ORDEN EDB"
              />
            )}
          </p>
          <div className="mb-1">
            <PedidoEdbIcon />
          </div>
        </>
      );
    }
  };

  const onClick = () => {
    history(`../detailsSales/${data.id}/${status}`);
  };

  return (
    <>
      <PersonalSaleModalCredit  />
      <div className=" w-100 pointer d-flex s12 museo-sans-500 border-bottom border-1 justify-content-between align-items-center row-height hover-pointer personal-sales-row-height">
        <div
          onClick={onClick}
          className="personal-sales-order museo-sans-500 s14 blackColor"
        >
          {status == 1 ? (
            <>
              <FormattedMessage
                id="personal.sales.row.order.number"
                defaultMessage="Orden n°"
              />
            </>
          ) : (
            <>
              <FormattedMessage
                id="personal.sales.row.request.number"
                defaultMessage="Pedido n°"
              />
            </>
          )}{" "}
          {data?.id}
        </div>
        <div
          onClick={onClick}
          className={`personal-sales-state museo-sans-500 s12 ${
            status === 3 ? "lightGreenColor" : "darkYellowColor"
          }`}
        >
          {defineStatus(status)}
        </div>
        <div
          onClick={onClick}
          className="personal-sales-date grayColor dm-sans-medium s14"
        >
          {defineDateInFormat(data?.date)}
        </div>
        <div
          onClick={onClick}
          className="personal-sales-type text-capitalize grayColor museo-sans-500 s12 d-flex align-items-center"
        >
          {defineType(data?.type, data?.orderType, data?.totalOrders)}
        </div>
        
        {data.creditNotes != null ? (
          <>
          <div id="credit-modal-activate" className=" position-absolute invisible"
           data-bs-toggle="modal" data-bs-target="#modalInfoCredit" >

          </div>

            <div
              onClick={() => changeCreditOrientation(data.creditNotes, data)}
              className={`personal-sales-total align-items-center dm-sans-medium s14 
              darkYellowColor
              d-flex justify-content-end`}
            >
              {formatAmount(
                data?.amount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
              &nbsp;
              <div
                className="svg-force-size d-flex justify-content-center"
                style={{ width: "16px", height: "14px" }}
              >
                <SVGTriangle />
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={`personal-sales-total align-items-center dm-sans-medium s14 blackColor
               d-flex justify-content-end`}
            >
              {formatAmount(
                data?.amount,
                cultureInfo?.languageCode,
                cultureInfo?.defaultCurrencyCode
              )}
            </div>
          </>
        )}
        <div
          onClick={onClick}
          className="personal-sales-chevron skyColor button-hover-svg-light-stroke"
        >
          <ChevronRightSVG />
        </div>
      </div>
    </>
  );
}
