import { FormattedMessage } from "react-intl";

export default function DistributionAgreementContentMX(params) {
    return (
        <>
          <p className={` museo-sans-500 s16 mb-4 ${!params.showTitle && "mt-4"}`}>
            <FormattedMessage id="distribution.agreement.text.contract.parts.1" />
            <b>
              <FormattedMessage id="distribution.agreement.text.contract.parts.2" />
            </b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.3" />
            <b>
              <FormattedMessage id="distribution.agreement.text.contract.parts.4" />
            </b>
            <FormattedMessage id="distribution.agreement.text.contract.parts.5" />
          </p>
          <div className="d-flex justify-content-center">
            <p className="mb-2 museo-sans-700 s16">
              <FormattedMessage id="distribution.agreement.title.declarations" />
            </p>
          </div>

          <p className="mb-2 museo-sans-700 s16">
            <FormattedMessage id="distribution.agreement.title.declarations.swiss" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.1" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.swiss.2" />
          </p>

          <p className="mb-2 museo-sans-700 s16">
            <FormattedMessage id="distribution.agreement.title.declarations.solicitant" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.2" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.3" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.4" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <FormattedMessage id="distribution.agreement.text.declarations.solicitant.5" />
          </p>
          <div className="d-flex justify-content-center">
            <p className="mb-2 museo-sans-700 s16">
              <FormattedMessage id="distribution.agreement.title.declarations.clauses" />
            </p>
          </div>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.1" />
            </b>

            <FormattedMessage id="distribution.agreement.text.declarations.clauses.1" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.2" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.2" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.3" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.3" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.4" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.4" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.5" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.5" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.6" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.6" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.7" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.7" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.8" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.8" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.9" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.9" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.10" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.10" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.11" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.11" />
          </p>
          <p className="mb-2 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.12" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.12" />
          </p>
          <p className="mb-4 museo-sans-500 s16">
            <b>
              <FormattedMessage id="distribution.clauses.13" />
            </b>
            <FormattedMessage id="distribution.agreement.text.declarations.clauses.13" />
          </p>
          <p className="invisible mb-4 museo-sans-500 s16">
            <b>
              <u>
                <FormattedMessage id="ethics.code.text.immigration.laws.1" />
              </u>
            </b>
            <FormattedMessage id="ethics.code.text.immigration.laws.1" />
          </p>
        </>
      );
}