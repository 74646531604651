import { useRef } from "react";
import { formatAmount } from "../../../common/Share/FormatCurrency";
import ModalWhiteShadow from "../../../common/Share/ModalWhiteShadow";
import { ReactComponent as SVGCross } from "../../../common/svgs/ic-x-lg.svg";

import { ReactComponent as Red } from "../../../common/svgs/cross/red.svg";
import { ReactComponent as Grey } from "../../../common/svgs/cross/grey.svg";
// #908E9A
import { ReactComponent as Green } from "../../../common/svgs/ic-check-circle.svg";
import { FormattedMessage } from "react-intl";

export default function OriginRoadModalOthers({
  cultureInfo,
  roleDescription,
  data,
  level,
}) {
  const scrollbarRef = useRef(null);

  return (
    <div className=" museo-sans-500">
      <div className=" m-4 px-3 pt-3">
        <div className="d-flex justify-content-between">
          <p className=" s20">
            <FormattedMessage id="origin.road.reached" />
          </p>
          <div
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{ height: "32px", width: "32px" }}
            className=" svg-force-size pointer"
          >
            <SVGCross />
          </div>
        </div>
        <div className="d-flex gap-1 dm-sans-medium s16 ">
          <p>{data?.processedGoals}</p>
          <p className=" grayColor">/ {data?.totalGoals}</p>
        </div>
      </div>
      <hr className="m-0" />
      <div
        ref={scrollbarRef}
        style={{ height: "436px" }}
        className=" position-relative hidden-y-scroll m-4 px-3 pb-3 "
      >
        {data?.goalProgress?.map((goal, index) => {
          return (
            <ModalRow
              roleDescription={roleDescription}
              level={level}
              goalInfo={goal}
              cultureInfo={cultureInfo}
              isLast={(data?.goalProgress?.length -1) == index }
            />
          );
        })}
      </div>
      <ModalWhiteShadow
        bottom={10}
        left={"5%"}
        width={"90%"}
        scrollbarRef={scrollbarRef}
      />
    </div>
  );
}

export function ModalRow({ cultureInfo, roleDescription, goalInfo, level, isLast }) {
  function formatDateRange(startDate, endDate) {
    const options = { month: "short", day: "numeric", year: "numeric" };
    const start = new Date(startDate)
      .toLocaleDateString("es-AR", options)
      .toUpperCase();
    const end = new Date(endDate)
      .toLocaleDateString("es-AR", options)
      .toUpperCase();

    return `${start.split(" ")[0]} ${start.split(" ")[2]} / ${
      end.split(" ")[0]
    } ${end.split(" ")[2]} ${end.split(" ")[4]}`;
  }

  return (
    <>
      <div className=" museo-sans-500 s14 my-3 ">
        <p className=" s14 grayColor">
          {formatDateRange(goalInfo?.startDate, goalInfo?.endDate)}
        </p>
        <div className="d-flex justify-content-between gap-1">
          <p className=" s16">
            <FormattedMessage id="select.period" /> {goalInfo?.period}
          </p>
          <div className="d-flex gap-1 text-uppercase">
            <p>
              <FormattedMessage id={`origin.road.state.${goalInfo?.state == "REACHED" ? "REACHED" : "FAILED" }`} />
            </p>
            <div
              style={{ height: "14px", top: -2 }}
              className=" position-relative svg-force-size"
            >
              {goalInfo?.state == "REACHED" ? (
                <Green />
              ) : (
                <>
                  {goalInfo?.state == "FAILED" ? (
                      <Red />
                  ) : (
                      <Grey />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className=" d-flex justify-content-between s14">
          <p className=" grayColor">
            {level < 2 ? (
              <FormattedMessage id="nav.label.one.a" />
            ) : (
              <FormattedMessage id="nav.label.one.c" />
            )}
          </p>
          <p>
            {formatAmount(
              goalInfo?.incrementalSale,
              cultureInfo?.languageCode,
              cultureInfo?.defaultCurrencyCode
            )}
          </p>
        </div>
        <div className=" d-flex justify-content-between s14">
          <p className=" grayColor ">
            {level < 2 ? (
              <FormattedMessage id="origin.road.task.two.b" />
            ) : (
              <FormattedMessage id="origin.road.task.two.a" />
            )}
          </p>
          <p>{goalInfo?.incrementalConsultans}</p>
        </div>
      </div>
      {goalInfo?.state === "GAP" && (
        <div
          style={{ background: "#FEF0F0", color: "#4D4D4D" }}
          className=" mb-3 rounded-2 p-2 s12 museo-sans-500"
        >
          <p className="mx-1">
            <FormattedMessage id="origin.message.gap.modal" />
          </p>
        </div>
      )}
      {
        !isLast &&
        <hr className="m-0" />

      }
    </>
  );
}
